import { Component } from 'preact'
import cx from 'classnames'

import JSON_HEADERS from 'jsonHeaders'
import { typograf } from 'lib/textHelper'
import { birmanize, shortenMonths } from 'lib/dateHelper'
import { utmSource } from 'lib/helpers/linksHelper'
import deepCamelize from 'lib/deepCamelize'

import { getStoredEmail, subscribeUser } from '../subscriptionForm/emailUtils'

import { TextNode } from '../textNodes'
import { Cols } from '../cols'
import EmailForm from '../emailForm/emailForm'
import LinkWithArrow from '../linkWithArrow'
import DoubleHover from '../../assets/scripts/doubleHover'

const EVENT_ID = 1

const getDiscountMessage = (closestEvent) => {
  const date = birmanize(closestEvent.priceEndsAt, { format: 'dateOnly' })

  return `<nobr>🕑Сэкономьте ${closestEvent.discount}% до ${date}</nobr> `
}

const CourseDiscountMessage = (props) => {
  return (
    <span
      class="educenterCoursesSpacer-discount bureausans"
      dangerouslySetInnerHTML={ { __html: getDiscountMessage(props) }} />
  )
}

const CourseLink = (props) => {
  return (
    <span>
      { !props.noFeedback &&
        <LinkWithArrow
          href={ props.href }
          className="educenterCoursesSpacer-linkTextLong"
          text="Программа и отзывы"
          isDoubleHover />
      }
      <LinkWithArrow
        href={ props.href }
        className="educenterCoursesSpacer-linkTextShort"
        text="Программа"
        isDoubleHover />
    </span>
  )
}

const RegisterButton = (props) => {
  return (
    <div className="educenterCoursesSpacer-buttonWrapper">
      <a href={ props.href } className="button educenterCoursesSpacer-button">
        Записаться
      </a>
    </div>
  )
}

class EducenterCoursesSpacer extends Component {
  constructor(props) {
    super(props)

    const { email, isSubscribed } = getStoredEmail(props.eventType)

    this.state = { courses: [], pickedCourse: {}, email, isSubscribed }

    this.pickCourseToShow = this.pickCourseToShow.bind(this)
    this.subscribe = this.subscribe.bind(this)
  }

  componentDidMount() {
    this.fetchCourses()
  }

  fetchCourses() {
    fetch('/products/courses', { headers: JSON_HEADERS })
      .then(res => res.json())
      .then(res => res.map(deepCamelize))
      .then(courses => this.setState({ courses }, this.pickCourseToShow))
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  componentDidUpdate() {
    $('.doubleHover').each(function() { new DoubleHover($(this)) })
  }

  subscribe({ email }) {
    return subscribeUser({ email, eventType: this.props.eventType, eventId: EVENT_ID })
      .then(isSubscribed => this.setState({ isSubscribed }))
  }

  pickCourseToShow() {
    const { courseKey } = this.props
    const { courses } = this.state
    if (!courses.length) return

    if (courseKey) {
      const pickedCourse = courses.find(c => c.key === courseKey)
      this.setState({ pickedCourse })
    } else {
      const liveCourses = courses.filter(c => !!c.closestEvent)
      const pickedCourse = liveCourses[0] || courses[0]
      this.setState({ pickedCourse })
    }
  }

  laptopTitle(title) {
    if (this.props.laptopTitle) return this.props.laptopTitle

    const maxTitleLaptopLength = 42
    if (title.length < maxTitleLaptopLength) return title

    return title.split(/\sи\s/)[0]
  }

  render(props) {
    let { pickedCourse } = this.state
    if (!pickedCourse.key) return null

    const { closestEvent } = pickedCourse

    const isOpen = (closestEvent?.status === 'open')
    const isHalfSize = props.size === 'half'
    const isFullSize = props.size === 'full'

    const source = utmSource({
      platform: 'Сайт бюро',
      postType: 'Банер',
      productType: 'Курсы',
      product: pickedCourse.key.replace('-online', ''),
      postDate: new Date(),
    })

    const courseLink = `/courses/${pickedCourse.key}/?utm_source=${source}`
    const registerLink = `/courses/${pickedCourse.key}/?self&utm_source=${source}`

    const classes = cx(
      'educenterCoursesSpacer',
      {
        is__open: isOpen,
        is__closed: !isOpen,
        is__half: isHalfSize,
        is__full: isFullSize,
        has__noFeedback: props.noFeedback,
      }
    )

    return (
      <div className={ classes }>
        <div className="educenterCoursesSpacer-titleWrapper">
          <div className="educenterCoursesSpacer-title">
            <a href={ courseLink } class="device laptop doubleHover is__noUnderline">
              { this.laptopTitle(pickedCourse.title) }
            </a>
            <a href={ courseLink } class="device mobile desktop doubleHover is__noUnderline">
              { this.props.title || pickedCourse.title }
            </a>
          </div>
        </div>
        <div className="educenterCoursesSpacer-text">
          { isOpen && isFullSize &&
            <div className="device laptop desktop">
              <div className="educenterCoursesSpacer-cols">
                <div className="educenterCoursesSpacer-left">
                  <TextNode>
                    <a href={ courseLink } className="doubleHover is__noUnderline">
                      { typograf(pickedCourse.openDescription) + ' ' }
                      <nobr>{ shortenMonths(closestEvent.dates) }.</nobr>
                      { ' ' }
                      { !!closestEvent.discount && <CourseDiscountMessage { ...closestEvent } /> }
                      <br className="educenterCoursesSpacer-linkBreak" />
                      <CourseLink href={ courseLink } noFeedback={ props.noFeedback } />
                    </a>
                  </TextNode>
                </div>
                <RegisterButton href={ registerLink } />
              </div>
            </div>
          }

          { isOpen && isHalfSize &&
            <div className="device laptop desktop">
              <TextNode>
                <a href={ courseLink } className="doubleHover is__noUnderline">
                  { typograf(pickedCourse.openDescription) + ' ' }
                  <nobr>{ shortenMonths(closestEvent.dates) }.</nobr>
                  { ' ' }
                  { !!closestEvent.discount && <CourseDiscountMessage { ...closestEvent } /> }
                </a>
              </TextNode>
              <div className="educenterCoursesSpacer-cols">
                <div className="educenterCoursesSpacer-left">
                  <TextNode>
                    <CourseLink href={ courseLink } noFeedback={ props.noFeedback } />
                  </TextNode>
                </div>
                <RegisterButton href={ registerLink } />
              </div>
            </div>
          }

          { isOpen &&
            <div className="device mobile">
              <TextNode>
                <a href={ courseLink } className="doubleHover is__noUnderline">
                  { typograf(pickedCourse.openDescription) + ' ' }
                  <nobr>{ shortenMonths(closestEvent.dates) }.</nobr>
                  { ' ' }
                  { !!closestEvent.discount && <CourseDiscountMessage { ...closestEvent } /> }
                </a>
              </TextNode>
              <div className="educenterCoursesSpacer-cols">
                <div className="educenterCoursesSpacer-left">
                  <TextNode>
                    <CourseLink href={ courseLink } noFeedback={ props.noFeedback } />
                  </TextNode>
                </div>
                <RegisterButton href={ registerLink } />
              </div>
            </div>
          }

          { !isOpen && isHalfSize &&
            <TextNode>
              <a href={ courseLink } className="doubleHover is__noUnderline">
                { typograf(`${pickedCourse.shortDescription}. Мы напишем вам, когда откроется запись. `) }
                <CourseLink href={ courseLink } noFeedback={ props.noFeedback } />
              </a>
            </TextNode>
          }

          { !isOpen && isFullSize &&
            <Cols className="device device__laptop device__desktop">
              <div>
                <TextNode>
                  <a href={ courseLink } className="doubleHover is__noUnderline">
                    { typograf(`${pickedCourse.shortDescription}. Мы напишем вам, когда откроется запись. `) }
                    <br className="educenterCoursesSpacer-linkBreak" />
                    <CourseLink href={ courseLink } noFeedback={ props.noFeedback } />
                  </a>
                </TextNode>
              </div>
              <EmailForm
                email={ this.state.email }
                autoComplete={ null }
                onSubmit={ this.subscribe }
                className="educenterCoursesSpacer-form"
                isSubscribed={ this.state.isSubscribed }
                thinButton
              />
            </Cols>
          }

          { !isOpen && isFullSize &&
            <div className="device mobile">
              <TextNode>
                <a href={ courseLink } className="doubleHover is__noUnderline">
                  { typograf(`${pickedCourse.shortDescription}. Мы напишем вам, когда откроется запись. `) }
                  <CourseLink href={ courseLink } noFeedback={ props.noFeedback } />
                </a>
              </TextNode>
            </div>
          }
        </div>

        { !isOpen && isHalfSize &&
          <EmailForm
            email={ this.state.email }
            autoComplete={ null }
            onSubmit={ this.subscribe }
            className="educenterCoursesSpacer-form"
            isSubscribed={ this.state.isSubscribed }
            thinButton
          />
        }

        { !isOpen && isFullSize &&
          <div className="device mobile">
            <EmailForm
              email={ this.state.email }
              autoComplete={ null }
              onSubmit={ this.subscribe }
              className="educenterCoursesSpacer-form"
              isSubscribed={ this.state.isSubscribed }
              thinButton
            />
          </div>
        }
      </div>
    )
  }
}

export default EducenterCoursesSpacer
