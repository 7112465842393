import { Component } from 'preact'

class File extends Component {
  render({ file, onDelete }) {
    return <div className="uploadFile">
      <a href={ file.url } className="uploadFile-name">
        { file.title }
      </a>
      <button class="uploadFile-remove" onClick={ onDelete }></button>
    </div>
  }
}

module.exports = File
