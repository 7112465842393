class DnD {
  constructor() {
    this.$document = $(document)

    this.level = 0

    this._bindEvents()
  }

  _bindEvents() {
    this.$document.on('dragenter dragover', e => {
      e.stopPropagation()
      e.preventDefault()
    })

    this.$document.on('dragenter', () => {
      this.level += 1

      this.$document.trigger('appDragStart')
    })

    this.$document.on('dragleave', () => {
      this.level -= 1
      if (this.level === 0) this.$document.trigger('appDragEnd')
    })

    this.$document.on('drop', e => {
      e.stopPropagation()
      e.preventDefault()

      this.$document.trigger('appDragEnd')

      if (!this._isDropEventApplicable(e)) return

      this.$document.trigger('appDrop', {
        files: Array.from(e.originalEvent.dataTransfer.files),
      })
    })
  }

  _isDropEventApplicable(e) {
    const ev = e.originalEvent

    return ev.dataTransfer &&
      ev.dataTransfer.files &&
      ev.dataTransfer.files.length
  }
}

module.exports = DnD
