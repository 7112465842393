import { Component } from 'preact'

import { Heading2 } from '../textNodes'

class NewPaybarTitle extends Component {
  get titleText() {
    const productTitle = this.props.productTitle || ''

    return I18n.t(`newPaybar.title.${this.props.product}.${this.props.type}`, { productTitle })
  }

  render(props) {
    return (<Heading2 { ...props } html={ this.titleText } />)
  }
}

export default NewPaybarTitle
