import cx from 'classnames'

const renderChildren = (props) => {
  return props.children.map((child, index) => {
    const className = child.props && child.props.className ? child.props.className : ''
    let proportion = 1
    let colStyle = {}
    const wrapClasses = className.split(' ').map(cl => `${cl}Wrapper`)
    const classnames = cx(
      'col',
      'module',
      {
        has__noRib: props.ribMask && !props.ribMask[index],
        has__noMobileRib: props.mobileRibMask && !props.mobileRibMask[index],
      },
      wrapClasses
    )

    if (props.divisionProportions) {
      proportion = props.divisionProportions.split(':')[index]
      Object.assign(colStyle, { 'flex-grow': proportion })
    }

    return (
      <div className={ classnames } style={ colStyle }>
        <div className="col-in module">
          { child }
        </div>
      </div>
    )
  })
}

const Cols = (props) => {
  const className = props.className || ''
  let classnames = cx(
    'cols',
    'module',
    'grid__column',
    {
      is__transposeOnMobile: props.transposeOnMobile,
      is__ribbed: props.isRibbed || props.ribMask,
      is__ribbedOnMobile: props.isRibbed || props.mobileRibMask,
    },
    className.split(' ')
  )

  return (
    <div className={ classnames }>
      { renderChildren(props) }
    </div>
  )
}

export default Cols
