import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarPrepPricing from './newPaybarPrepPricing'
import NewPaybarTitle from '../newPaybarTitle'
import NewPaybarPayer from '../newPaybarPayer'
import NewPaybarPersonalForm from '../newPaybarPersonalForm'
import NewPaybarOverlay from '../newPaybarOverlay'
import NewPaybarTabs from '../newPaybarTabs'
import expandScreenToFlags from '../expandScreenToFlags'
import NewPaybarLaunchpad from '../newPaybarLaunchpad'
import NewPaybarSpecialPriceLabel from '../newPaybarSpecialPriceLabel'
import NewPaybarSchoolRequirements from '../school/newPaybarSchoolRequirements'
import NewPaybarSchoolCaption from '../school/newPaybarSchoolCaption'

class NewPaybarPrep extends Component {
  render(props) {
    const shouldDisplayPricing = props.isPurchaseScreen || props.isInitialScreen
    const shouldDisplayCaption = props.isPurchaseScreen && props.isUserAuthenticated
    const shouldDisplayRequirements = props.isSuccessScreen || props.isSubscriptionScreen

    return (
      <div className="newPaybar-infoIn module">
        { !props.isSuccessScreen &&
          <NewPaybarTabs
            device="mobile"
            type={ props.type }
            product={ props.product } />
        }

        { !props.isSubscriptionScreen &&
          <NewPaybarTitle
            className="newPaybarTitle module device device__mobile"
            type={ props.type }
            screen={ props.screen }
            product={ props.product } />
        }

        { props.isSuccessScreen &&
          <NewPaybarOverlay className="device device__mobile" />
        }

        <div className="newPaybarPrepPricingAndInfo">
          { shouldDisplayPricing &&
            <NewPaybarPrepPricing type={ props.type } prices={ props.prices } />
          }
          { shouldDisplayCaption &&
            <NewPaybarSchoolCaption caption={ I18n.t('newPaybar.prep.caption') } />
          }
          { shouldDisplayRequirements &&
            <NewPaybarSchoolRequirements product={ props.product } />
          }
        </div>

        { props.isSubscriptionScreen &&
          <div className="newPaybarLaunchpadMobileWrapper module device device__mobile">
            <NewPaybarLaunchpad />

            { props.isUserAuthenticated &&
              <NewPaybarSchoolRequirements product={ props.product }/>
            }
          </div>
        }

        { props.isPurchaseScreen &&
          <NewPaybarPayer payerRef={ props.payerRef } />
        }

        { !props.isSuccessScreen &&
          <NewPaybarPersonalForm
            subscriptionUrl={ props.subscriptionUrl }
            scrollableRef={ props.scrollableRef } />
        }

        { this.props.children }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    screen: newPaybar.screen,
    prices: newPaybar.prices,
    books: newPaybar.shelfBooks,
    smallUnits: newPaybar.smallUnits,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarPrep)
