import { Component } from 'preact'
import { connect } from 'preact-redux'

import { setSoviets } from './sovietCompilationActions'

import FETCH_HEADERS from 'authJsonHeaders'

import DoubleHover from '../../assets/scripts/doubleHover'
import SovietCompilationLayout from './sovietCompilationLayout'

class SovietCompilationAutoLayout extends Component {
  constructor(props) {
    super(props)

    this.state = { rows: [], soviets: [] }
  }

  componentDidMount() {
    if (this.props.links.length) this._getRows(this.props.links)
  }

  componentDidUpdate() {
    $('.doubleHover').each(function() { new DoubleHover($(this)) })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.links.join('') !== this.props.links.join('')) {
      this._getRows(nextProps.links)
    }
  }

  _getRows(links) {
    if (this.props.isPublished && this.props.isReadOnly) return

    fetch('/soviet/selected/build/', {
      method: 'POST',
      credentials: 'include',
      headers: FETCH_HEADERS,
      body: JSON.stringify({ links: links }),
    })
      .then((res) => {
        if (res.ok) return res.json()

        return Promise.resolve({ rows: [], soviets: [] })
      })
      .then((json) => {
        const soviets = [].concat.apply([], json.rows)

        this.setState({ rows: json.rows, soviets: soviets })
        this.props.setSoviets(soviets)
      })
  }

  render(props) {
    const rows = props.rows && props.rows.length ? props.rows : this.state.rows

    props.setEmptyIf(rows.length === 0)

    return <SovietCompilationLayout {...props } rows={ rows } />
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    links: sovietCompilation.links,
    rows: sovietCompilation.rows,
    isPublished: sovietCompilation.isPublished,
    isReadOnly: sovietCompilation.isReadOnly,
    mainColumn: sovietCompilation.mainColumn,
  }
}

const mapDispatchToProps = {
  setSoviets,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationAutoLayout)
