{
  const $document = $(document)
  const $subscribeButton = $('.js__openDaySpacer-button')
  const subscriptionEventId = $('.js__openDaySpacer-form').attr('data-course-id')
  let justSubscribed = false

  $document.on('appSetUser', function(_, user) {
    if (!user) return

    if (user.name) $subscribeButton.prop('disabled', false)
    if (user.email) $('.js__openDaySpacerSubscribeEmail').val(user.email)
  })

  $document.on(`submitSubscription.${subscriptionEventId}`, () => {
    $subscribeButton.prop('disabled', true)
    justSubscribed = true
  })

  $document.on(`subscriptionChanged.${subscriptionEventId}`, function(_, isSubscribed) {
    const $subscriptionControl = $('.js__openDaySpacerSubscriptionControl')

    $subscribeButton.prop('disabled', isSubscribed)
    if (justSubscribed) {
      setTimeout(() => $subscriptionControl.toggleClass('has__subscribed', isSubscribed), 400)
    } else {
      $subscriptionControl.toggleClass('has__subscribed', isSubscribed)
    }
  })
}
