import { convert } from 'css-color-function'

const TAB_BACKGROUND_VAR = '--paybarFormBackgroundColor'
const KEY_COLOR_VAR = '--keyColor'

const toTabBackground = color => convert(`color(${color} blend(#fff 88%))`)

export default class NewPaybarStyles {
  static install() {
    const { documentElement } = document
    const styles = getComputedStyle(documentElement)
    const isStyleAlreadySet = !!styles.getPropertyValue(TAB_BACKGROUND_VAR)
    const keyColor = styles.getPropertyValue(KEY_COLOR_VAR)

    if (!isStyleAlreadySet && keyColor) {
      documentElement.style.setProperty(TAB_BACKGROUND_VAR, toTabBackground(keyColor))
    }
  }
}
