import cx from 'classnames'
import { LaptopDesktopWideIcon, LaptopDesktopIcon } from '../../icons'
import NewPaybarPlusPhone from './newPaybarPlusPhone'

export default function NewPaybarDevicesIcons(props) {
  const className = cx('newPaybarDevicesIcons', props.className, { has__mobileVersion: props.hasMobileVersion })

  if (props.hasMobileVersion) {
    return (
      <div className={ className }>
        <LaptopDesktopIcon />
        <NewPaybarPlusPhone />
      </div>
    )
  }

  return (
    <div className={ className }>
      <LaptopDesktopWideIcon />
    </div>
  )
}
