import { connect } from 'preact-redux'
import cx from 'classnames'

import SchoolRatingConnectedStudentItem from './schoolRatingConnectedStudentItem'
import { End, StepHover, EndHover, WeeksPath } from './schoolRatingGraphItems'
import Week from './schoolRatingGraphOverlayWeek'
import { Total } from './schoolRatingGraphOverlayItems'

import {
  pinStudent,
  setActiveStudent,
  unpinStudent,
  unsetActiveStudent,
} from './schoolRatingActions'
import { getWeekStartCoords } from './graphHelpers'

const CHANGEABLE_PROPS = ['activeStudentUid', 'pinnedStudentUid']

class SchoolRatingGraph extends SchoolRatingConnectedStudentItem {
  constructor(props) {
    super(props)

    this.onStepMouseEnter = this.onStepMouseEnter.bind(this)
    this.onStepMouseLeave = this.onStepMouseLeave.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    const currentChartParams = this.props.chartParams
    const { chartParams } = nextProps

    return Object.keys(chartParams).some(key => chartParams[key] !== currentChartParams[key]) ||
      CHANGEABLE_PROPS.some(key => nextProps[key] !== this.props[key])
  }

  get items() {
    const { name, weeks, chartParams, enrollmentStatus } = this.props

    const result = {
      graph: [],
      hovers: [],
      overlay: [],
    }

    const lastWeekNumber = weeks.length

    if (!lastWeekNumber) return result

    const stepCoords = []
    let prevCoords = null


    weeks.forEach((week, index) => {
      const number = index + 1
      const numberedWeek = { ...week, number }

      const isLastStudentWeek = number === lastWeekNumber
      const isFinalEnrollmentWeek = number === this.props.weeksCount
      const shouldDisplayTotal = isLastStudentWeek && !isFinalEnrollmentWeek

      const weekCoords = getWeekStartCoords(chartParams, numberedWeek)

      stepCoords.push(weekCoords)

      result.overlay.push(
        <Week { ...numberedWeek }
          coords={ weekCoords }
          preventIntersectWith={ chartParams.$el }
          isFinal={ isFinalEnrollmentWeek }/>
      )

      if (shouldDisplayTotal) {
        const lastWeek = weeks.slice(-1)[0]

        result.overlay.push(
          <Total
            name={ name }
            lastWeekNumber={ lastWeekNumber }
            score={ lastWeek.score }
            coords={ weekCoords }
            preventIntersectWith={ chartParams.$el }
            onMouseEnter={ this.onStepMouseEnter }
            onMouseLeave={ this.onStepMouseLeave }/>
        )
      }

      result.hovers.push(
        <StepHover
          chartParams={ chartParams }
          coords={ weekCoords }
          prevCoords={ prevCoords }
          weekNumber={ number }
          onMouseEnter={ this.onStepMouseEnter }
          onMouseLeave={ this.onStepMouseLeave }/>
      )

      prevCoords = weekCoords
    })

    const lastStepCoords = stepCoords.slice(-1)[0]

    const endCoords = {
      ...lastStepCoords,
      x: lastStepCoords.x + chartParams.colWidth,
    }

    result.graph.push(<WeeksPath chartParams={ chartParams } stepCoords={ stepCoords }/>)
    result.graph.push(<End status={ enrollmentStatus } coords={ endCoords }/>)

    result.hovers.push(
      <StepHover
        isExtended
        chartParams={ chartParams }
        coords={ endCoords }
        weekNumber={ lastWeekNumber }
        onMouseEnter={ this.onStepMouseEnter }
        onMouseLeave={ this.onStepMouseLeave }/>
    )

    return result
  }

  onStepMouseEnter(weekNumber) {
    $(this.$graph)
      .find(`.js__schoolRatingOverlayWeek[data-week=${ weekNumber }]`)
      .addClass('is__active')
  }

  onStepMouseLeave() {
    $(this.$graph)
      .find('.js__schoolRatingOverlayWeek.is__active')
      .removeClass('is__active')
  }

  render() {
    const { graph, hovers, overlay } = this.items

    const classNames = cx(
      'schoolRatingGraph schoolRatingConnectedStudentItem',
      `is__${ this.color }`,
      {
        is__active: this.isPinned || this.isActive,
        is__faded: this.isFaded,
      },
    )

    const shouldDisplayOverlay = this.isPinned || this.isActive

    return (
      <div className={ classNames }
        ref={ el => this.$graph = el }
        onMouseEnter={ this.onMouseEnter }
        onMouseLeave={ this.onMouseLeave }
        onClick={ this.onClick }>

        <svg className="schoolRatingGraph-graphWrap">
          <g className="schoolRatingGraph-graph">
            { [...graph, ...hovers].map(item => item) }
          </g>
        </svg>

        { shouldDisplayOverlay &&
          <div className="schoolRatingGraphOverlay">
            { overlay.map(item => item) }
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ schoolRating }) => {
  return {
    activeStudentUid: schoolRating.activeStudentUid,
    pinnedStudentUid: schoolRating.pinnedStudentUid,
    chartParams: schoolRating.chartParams,
    weeksCount: schoolRating.weeksCount,
  }
}

const mapDispatchToProps = {
  setActiveStudent,
  unsetActiveStudent,
  pinStudent,
  unpinStudent,
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolRatingGraph)
