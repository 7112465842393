import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { setHeaderTemplate } from './sovietActions'
import headerTemplates from './headerTemplateConfig'
import icons from './sovietHeaderTemplateSelectorIcons'

import ScrollingSelector from '../scrollingSelector/scrollingSelector'
import { IconSelector, IconSelectorItem } from '../iconSelector'

class SovietHeaderTemplateSelector extends Component {
  constructor(props) {
    super(props)
  }

  selectorItems() {
    return Object.keys(headerTemplates).map((headerTemplate) => {
      const headerTemplateProps = {
        selectedHeaderTemplate: headerTemplate,
        ...headerTemplates[headerTemplate],
      }
      const HeaderTemplateIcon = icons[headerTemplate]

      return (
        <IconSelectorItem
          className={ cx({ is__active: this.props.selectedHeaderTemplate === headerTemplate }) }
          onClick={ () => this.props.setHeaderTemplate(headerTemplateProps) }
        >
          <HeaderTemplateIcon />
        </IconSelectorItem>
      )
    })
  }

  render() {
    return (
      <ScrollingSelector className="sovietHeaderTemplateSelector">
        <IconSelector>
          { this.selectorItems() }
        </IconSelector>
      </ScrollingSelector>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    selectedHeaderTemplate: soviet.selectedHeaderTemplate,
    headerTemplate: soviet.headerTemplate,
    isTitleHidden: soviet.isTitleHidden,
    titleSize: soviet.titleSize,
    isQuestionHidden: soviet.isQuestionHidden,
    questionStyle: soviet.questionStyle,
    hasQuestionCols: soviet.hasQuestionCols,
  }
}

const mapDispatchToProps = {
  setHeaderTemplate,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietHeaderTemplateSelector)
