export const isStatusDisabled = (status) => ['failed', 'cancelled', 'black_marked'].includes(status)

export const isStatusPaused = (status) => ['paused'].includes(status)

export const isBlackMarked = (status) => ['black_marked'].includes(status)

const sortWeeksDesc = (a, b) => b.number - a.number

const sortPausedFirst = (studentA) => {
  if (isStatusPaused(studentA.enrollmentStatus)) return 1

  return -1
}

const sortByScore = (studentA, studentB) => {
  if (!studentA.weeks.length || !studentB.weeks.length) return 0

  const scoreA = studentA.weeks.slice(-1)[0].score
  const scoreB = studentB.weeks.slice(-1)[0].score

  return scoreB - scoreA
}

const sortByCurrentRank = (studentA, studentB) => {
  const lastWeekA = studentA.weeks.slice(-1)[0]
  const lastWeekB = studentB.weeks.slice(-1)[0]

  if (!lastWeekA && !lastWeekB) return 0
  if (!lastWeekA) return -1
  if (!lastWeekB) return 1

  return lastWeekA.rank - lastWeekB.rank
}

const sortByAbc = (studentA, studentB) => {
  const lastnameA = studentA.name.split(' ')[1]
  const lastnameB = studentB.name.split(' ')[1]

  if (lastnameA > lastnameB) return 1
  if (lastnameB > lastnameA) return -1

  return 0
}

const sortDisabledToBottom = (studentA, studentB) => {
  if (isStatusDisabled(studentA.enrollmentStatus)) return 1
  if (isStatusDisabled(studentB.enrollmentStatus)) return -1

  return 0
}

const sortPausedToBottom = (studentA, studentB) => {
  if (isStatusPaused(studentA.enrollmentStatus)) return 1
  if (isStatusPaused(studentB.enrollmentStatus)) return -1

  return 0
}

export const splitName = (fullName) => {
  const splitted = fullName.split(' ', 2)

  return { first: splitted[0], last: splitted[1] }
}

export const getLastWeek = (weeks) => {
  return weeks.slice(-1)[0] || null
}

export const getPrevWeek = (weeks) => {
  return weeks.slice(-2)[0] || null
}

export const getStudentsWeekNumbers = (students) => {
  const weeks = new Set()

  students.forEach(student => weeks.add(student.weeks.length))

  return Array.from(weeks).sort()
}

export const getWeeksWithStudents = (students) => {
  const result = []
  const active = []
  const disabled = []

  const allWeekNumbers = getStudentsWeekNumbers(students)

  students.forEach(student => {
    const { enrollmentStatus: status, weeks } = student

    const isDisabledOrPaused = isStatusDisabled(status) || isStatusPaused(status)
    const isOnLastWeek = weeks.length === Math.max(...allWeekNumbers)
    const shouldSendToBottom = isDisabledOrPaused && !isOnLastWeek
    const target = shouldSendToBottom ? disabled : active

    target.push(student)
  })

  allWeekNumbers.forEach(number => {
    result.push({
      number,
      students: active.filter(student => student.weeks.length === number),
    })
  })

  result.sort(sortWeeksDesc)

  disabled.sort(sortPausedFirst)

  result.push({
    number: null,
    students: disabled,
  })

  return result.filter(week => week.students.length > 0)
}

export const getSortedWeekStudents = (students) => {
  return students
    .sort(sortByCurrentRank)
    .sort(sortDisabledToBottom)
}

export const getLastWeekStudentsWithFairRank = (students) => {
  let rankCounter = 1
  let sortedStudents = students
    .sort(sortByCurrentRank)
  let resultStudents = []

  sortedStudents.forEach(student => {
    const shouldDisplayRank = !isStatusDisabled(student.enrollmentStatus)

    resultStudents.push({
      ...student,
      fairRank: shouldDisplayRank ? rankCounter : undefined,
    })

    if (shouldDisplayRank) rankCounter += 1
  })

  return resultStudents
}

export const getStudentsForFaces = (students) => {
  return students
    .sort(sortByScore)
    .sort(sortPausedToBottom)
    .sort(sortDisabledToBottom)
}

export const getStudentsForFacesLargeOnly = (students) => {
  return students
    .sort(sortByAbc)
    .sort(sortPausedToBottom)
    .sort(sortDisabledToBottom)
}

export const getGrantStudentsForFaces = (students) => {
  return students
    .filter(({ enrollmentGranted }) => enrollmentGranted)
    .filter(({ enrollmentStatus }) => !isStatusDisabled(enrollmentStatus))
    .filter(({ enrollmentStatus }) => !isStatusPaused(enrollmentStatus))
}

export const reduceWeekDisciplinesScore = (total, discipline) => total + discipline.score

export const isIntersectContainer = (container, el) => {
  const containerCoords = container.getBoundingClientRect()
  const elCoords = el.getBoundingClientRect()
  return elCoords.right > containerCoords.right
}
