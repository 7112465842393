const RULES = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ы: 'y',
  э: 'e',
  ю: 'yu',
  я: 'ya',
  ь: '',
  ъ: '',
}

module.exports = str => {
  str = str.toLowerCase()
  str = str.replace(/\s/ig, '-').replace(/[^\wа-яё-]/ig, '').replace(/-{2,}/g, '-')
  let transliterated = ''

  for (let i = 0; i < str.length; i++) {
    if (str[i] in RULES) {
      transliterated += RULES[str[i]]
    } else {
      transliterated += str[i]
    }
  }

  return transliterated
}
