const { readState, persistRawState: persistState } = require('./reduxStorage')

const DEFAULT_PLAYBACK_RATE = 1
const DEFAULT_START_TIME = 0

class VideoPlayerState {
  constructor({ id }) {
    this.id = id
    this.scope = 'videoPlayersState'
  }

  get currentState() {
    return readState(this.scope) || {}
  }

  get progress() {
    const data = this.currentState[this.id]

    return Math.floor(data?.seconds || data?.progress || DEFAULT_START_TIME)
  }

  saveProgress({ seconds, duration }) {
    const state = Object.assign(this.currentState, { [this.id]: { seconds, duration } })

    persistState(this.scope, state)

    this.triggerProgressEvent({ seconds, duration })
  }

  get playbackRate() {
    return this.currentState.playbackRate || DEFAULT_PLAYBACK_RATE
  }

  savePlaybackRate(playbackRate) {
    const state = Object.assign(this.currentState, { playbackRate })

    persistState(this.scope, state)
  }

  triggerProgressEvent({ seconds, duration }) {
    const event = new CustomEvent('videoProgressUpdate', { detail: { seconds, duration, id: this.id } })

    document.dispatchEvent(event)
  }
}

module.exports = VideoPlayerState
