import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import headerTemplates from './sovietHeaderTemplates'

import SovietHeaderImagePlaceholder from './sovietHeaderImagePlaceholder'
import SovietHeaderImageUploader from './sovietHeaderImageUploader'
import SovietTitleQuestionMeta from './sovietTitleQuestionMeta'
import { SovietSplashColumn } from './sovietSplashBits'
import SovietHeaderTemplateSelector from './sovietHeaderTemplateSelector'

class SovietSplash extends Component {
  constructor(props) {
    super(props)

    this.$header = $('.js__header')
  }

  updateHeaderColorScheme() {
    this.$header.toggleClass('is__inversed', this.props.headerTextColor === 'white')
  }

  componentDidMount() {
    this.updateHeaderColorScheme()
  }

  componentDidUpdate() {
    this.updateHeaderColorScheme()
  }

  get headerImageStyles() {
    if (this.props.headerImageUrl && !this.props.headerTemplateRequiresImage) {
      return { background: `url(${this.props.headerImageUrl}) no-repeat 50% 50% / cover` }
    }

    return {}
  }

  render(props) {
    const splashClasses = cx(
      'sovietSplash',
      'module',
      {
        [`is__${props.headerTemplate}`]: props.headerTemplate,
        [`is__title-${props.titleSize}`]: props.titleSize,
        [`is__question-${props.questionStyle}`]: props.questionStyle,
        with__questionCols: props.hasQuestionCols,
        is__titleHidden: props.isTitleHidden,
        is__questionHidden: props.isQuestionHidden,
        [`is__${props.headerTextColor}`]: props.headerTextColor,
        is__requiresImage: props.headerTemplateRequiresImage,
        is__hangingTitle: props.isHangingTitle,
      }
    )

    const HeaderTemplate = headerTemplates[props.headerTemplate] || headerTemplates.default

    return (
      <div
        className={ splashClasses }
        style={ this.headerImageStyles }>
        <div className="device laptop desktop">
          <HeaderTemplate { ...props } />
        </div>

        <div className="device mobile">
          <SovietSplashColumn column={ props.column } />
          <SovietHeaderImagePlaceholder />
          <SovietTitleQuestionMeta isFakeHeadingForSeo />
        </div>

        { props.isEditor &&
          <SovietHeaderTemplateSelector />
        }

        { props.isEditor &&
          <SovietHeaderImageUploader />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    isEditor: soviet.isEditor,
    headerTemplate: soviet.headerTemplate,
    column: soviet.column,
    isTitleHidden: soviet.isTitleHidden,
    titleSize: soviet.titleSize,
    isHangingTitle: soviet.isHangingTitle,
    isQuestionHidden: soviet.isQuestionHidden,
    questionStyle: soviet.questionStyle,
    hasQuestionCols: soviet.hasQuestionCols,
    headerTextColor: soviet.headerTextColor,
    headerTemplateRequiresImage: soviet.headerTemplateRequiresImage,
    headerImageUrl: soviet.headerImageUrl,
    questionMinimumLines: soviet.questionMinimumLines,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SovietSplash)
