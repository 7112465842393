import FormInput from './formInput'

$('.js__formInput').each(function() {
  const $input = $(this).find('input').remove()
  const text = $input.val() || false
  const name = $input.attr('name') || false
  const id = $input.attr('id') || false
  const type = $input.attr('type') || false
  const required = $input.attr('required') || false
  const className = $input.attr('class')

  $(this).reactify(FormInput, { text, name, id, type, className, required })
  $(this).children().unwrap()
})
