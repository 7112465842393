import cx from 'classnames'

import CrossButton from '../crossButton/crossButton'
import LoginForm from './loginForm'
import MenuItemGroup from './menuItemGroup'
import renderMenuItem from './renderMenuItem'

import { myMenuItems, otherMenuItems } from './menuItems'

const { user } = window.application || {}
const isAuthenticated = Boolean(user)


const Popup = ({ isOpen, closePopup }) => (
  <div className="userPopup">
    <div className={ cx('dropdown', { is__open: isOpen }) }>
      <CrossButton device="mobile" onClick={ closePopup } />

      {
        isAuthenticated &&
        <div className="userPopup-menuWrapper">
          {
            user.name
              ? <MenuItemGroup>
                <b>{ user.name }</b>
              </MenuItemGroup>
              : <MenuItemGroup />
          }

          { user.email &&
            <MenuItemGroup><b>{ user.email }</b></MenuItemGroup>
          }

          <ul>
            { myMenuItems.map(renderMenuItem(isAuthenticated, isOpen)) }
          </ul>
          { otherMenuItems.length > 0 &&
            <MenuItemGroup />
          }
          { otherMenuItems.length > 0 &&
            <ul>
              { otherMenuItems.map(renderMenuItem(isAuthenticated, isOpen)) }
            </ul>
          }
        </div>
      }

      { !isAuthenticated && <LoginForm /> }
    </div>
  </div>
)

export default Popup
