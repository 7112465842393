import { birmanize } from 'lib/textHelper'
import cx from 'classnames'

import { Caption } from '../../textNodes'

import NewPaybarLaunchpadItem from '../newPaybarLaunchpadItem'
import NewPaybarLaunchpadLinks from '../newPaybarLaunchpadLinks'

export default class NewPaybarLaunchpadItemSchool extends NewPaybarLaunchpadItem {
  get buttonText() {
    return I18n.t(`newPaybar.school.launchpad.button${this.props.isStudying ? 'Studying' : ''}`)
  }

  get deadlineText() {
    const { nextDeadline } = this.props

    if (!nextDeadline) return null

    return I18n.t('newPaybar.school.launchpad.captionStudying', { deadline: birmanize(nextDeadline, { format: 'weekDayFullAndDate' }) })
  }

  get captionText() {
    if (this.props.isStudying) return this.deadlineText

    const suffix = this.props.isTermsRequired ? 'Terms' : ''

    return I18n.t(`newPaybar.school.launchpad.caption${suffix}`)
  }

  get startUrl() {
    if (!this.props.canAccess || this.isLocked) return null

    return this.isPrep ? '/school/prep/start/' : `/school/${ this.props.schoolKey }/start/`
  }

  get src() {
    return `/assets/images/school/paybar-${this.isPrep ? 'prep' : this.props.schoolKey}.png`
  }

  get isPrep() {
    return this.props.product === 'prep'
  }

  get classNames() {
    return cx(
      'newPaybarLaunchpadItemSchool',
      `is__${this.props.schoolKey}`,
      {
        is__locked: this.isLocked,
        is__loaded: this.state.isLoaded,
      }
    )
  }

  render() {
    return (
      <div className={ this.classNames }>
        <a href={ this.startUrl } className="newPaybarLaunchpadItemSchool-imgWrap no-u doubleHover">
          <img className={ `newPaybarLaunchpadItemSchool-img is__${this.props.schoolKey}` }
            src={ this.src }
            alt={ I18n.t(`newPaybar.${this.props.product}.title`) }
            onLoad={ this.setLoaded }
            ref={ el => this.$image = el }/>
        </a>

        { !this.isLocked && this.props.canAccess &&
          <a href={ this.startUrl } className="newPaybarLaunchpadItemSchool-button no-u doubleHover">
            <button type="button" className="newPaybarAccessButton button module device laptop desktop">
              { this.buttonText }
            </button>
            <button type="button" className="button is__thin is__enlargedOnMobiles module device mobile">
              { this.buttonText }
            </button>
          </a>
        }

        { !this.isLocked && this.props.canAccess &&
          <div className="newPaybarLaunchpadItemSchool-caption no-u">
            <Caption html={ this.captionText }/>

            { this.props.isTermsRequired && <NewPaybarLaunchpadLinks /> }

          </div>
        }
      </div>
    )
  }
}
