import { Component } from 'preact'

import { TextNode } from '../textNodes'
import { FormInput } from '../form'


export default class NewPaybarTelegramInput extends Component {
  render() {
    return (
      <label className="newPaybarPersonalFieldset-row is__compact">
        <TextNode className="newPaybarPersonalFieldset-label">
          { I18n.t('newPaybar.school.telegram') }
        </TextNode>
        <div className="newPaybarPersonalFieldset-inputWithCaption">
          <FormInput className="newPaybarPersonalFieldset-input"
            type="text"
            autoComplete="telegram"
            { ...this.props } />
        </div>
      </label>
    )
  }
}
