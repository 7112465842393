import sovietAuthorsByEmail from 'lib/current/sovietAuthorsByEmail'
import isValidName from 'lib/users/isValidName'
import cx from 'classnames'

import UserAvatar from '../userAvatar/userAvatar'

const SovietCommentFormAuthorRow = ({ commentId, name, email, isAuthenticated, avatarUrl }) => {
  const sovietAuthor = sovietAuthorsByEmail[email] || {}
  const isAuthenticatedAndHasName = isAuthenticated && isValidName(name)
  const classes = cx(
    'sovietCommentForm-authorRow',
    {
      is__authenticated: isAuthenticatedAndHasName,
      not__authenticated: !isAuthenticatedAndHasName,
    }
  )

  return (
    <div className={ classes }>
      <UserAvatar
        name={ name }
        isActive={ isAuthenticatedAndHasName }
        src={ sovietAuthor.authorAvatarUrl || avatarUrl }
      />

      { isAuthenticatedAndHasName &&
        <div className="sovietCommentForm-author">
          { name }
          <span className="sovietCommentForm-authorEmail">
            , <a className="is__masked" href={ `mailto:${email}` }>{ email }</a>
          </span>
        </div>
      }

      { !isAuthenticatedAndHasName &&
        <div className="sovietCommentForm-author">
          <label htmlFor={ `${commentId}-name` }>Имя и фамилия</label>
        </div>
      }
    </div>
  )
}

export default SovietCommentFormAuthorRow
