import { Component } from 'preact'

import { AssetsNavigation } from '../assetsNavigation'
import LectureFollowingLecturesNavigation from './lectureFollowingLecturesNavigation'
import LectureFollowingCoursesNavigation from './lectureFollowingCoursesNavigation'
import OtherLectures from '../otherLectures/otherLectures'

export default class LectureNavigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      followingLectures: [],
      followingCourses: [],
      otherCourses: [],
    }

    this.pageId = $('meta[name="pageId"]').attr('content')
    this.navigationId = $('meta[name="navigationId"]').attr('content')
  }

  get navigationApi() {
    const pageId = this.navigationId || this.pageId

    return (pageId + '/navigation.json').replace('//', '/')
  }

  componentDidMount() {
    this
      .fetchNavigation()
      .then(({ assets, lectures = {}, courses = {} }) => this.setState({
        assets,
        followingLectures: lectures.following || [],
        followingCourses: courses.following || [],
        otherCourses: courses.other || [],
      }))
  }

  fetchNavigation() {
    return fetch(this.navigationApi)
      .then((res) => {
        if (!res.ok) throw Error(res.statusText)

        return res.json()
      })
  }

  render() {
    const { assets, followingLectures, followingCourses, otherCourses } = this.state

    return (
      <div className="lectureNavigation">
        { assets &&
          <AssetsNavigation { ...assets } />
        }

        { !!followingLectures.length &&
          <LectureFollowingLecturesNavigation lectures={ followingLectures } />
        }

        { !followingLectures.length && !followingCourses.length &&
          <OtherLectures courses={ otherCourses } />
        }

        { !followingLectures.length && !!followingCourses.length &&
          <LectureFollowingCoursesNavigation courses={ followingCourses } />
        }
      </div>
    )
  }
}
