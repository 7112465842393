import { Component } from 'preact'
import { connect } from 'preact-redux'

import { birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../calendar/timezoneUtils'

import NewPaybarFieldsetHeading from './newPaybarFieldsetHeading'

class NewPaybarCompanyHowToInfo extends Component {
  get howToCaption() {
    const { companyPaymentType, participantType } = this.props
    const notEnoughTime = this.props.productDetails.missed_invoice_payment_deadline

    let invoiceSuffix = ''
    if (companyPaymentType === 'invoice') {
      invoiceSuffix = notEnoughTime ? 'BeforeDeadline' : 'OnWorkdays'
    }

    return `newPaybar.companyHowTo.${this.productType}.caption.${participantType}.${companyPaymentType}${invoiceSuffix}`
  }

  get invoiceLastDate() {
    const date = moscowifyLocal(new Date(this.props.productDetails.starts_at))

    return birmanize(date, { format: 'fullMonthDateOnly' })
  }

  get productType() {
    return this.props.productDetails.type
  }

  render() {
    return (
      <NewPaybarFieldsetHeading
        className="is__howTo"
        text={ I18n.t(`newPaybar.companyHowTo.${this.productType}.title`) }
        caption={ I18n.t(this.howToCaption, { date: this.invoiceLastDate }) } />
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    productDetails: newPaybar.productDetails,
    companyPaymentType: newPaybar.companyPaymentType,
    type: newPaybar.type,
    participantType: newPaybar.participantType,
  }
}

export default connect(mapStateToProps)(NewPaybarCompanyHowToInfo)
