import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { generateMonths, markEngaged, omitSelectedDate } from './dateUtils'
import CalendarDay from './calendarDay'

const STANDARD_MARGIN = 18
const EMBED_MONTH_NAME_DAYS_THRESHOLD = 5
const DAYS_IN_WEEK = 7

const keepValueInRange = (from, to, value) => Math.max(Math.min(to, value), from)

const canEmbedMonthName = firstWeek => firstWeek.length <= EMBED_MONTH_NAME_DAYS_THRESHOLD

const getCalendarHeaderStyles = scrollTop => ({
  paddingRight: keepValueInRange(0, STANDARD_MARGIN, scrollTop),
  paddingLeft: keepValueInRange(0, STANDARD_MARGIN, scrollTop),
  marginRight: STANDARD_MARGIN - keepValueInRange(0, STANDARD_MARGIN, scrollTop),
  marginLeft: STANDARD_MARGIN - keepValueInRange(0, STANDARD_MARGIN, scrollTop),
})

const renderJanuaryDate = () =>
  new Date().getUTCFullYear() + Number(new Date().getUTCMonth() > 6)

const renderDayListItem = day => (
  <li className="calendar-headerDayItem">{ day }</li>
)

const renderCalendarWeek = week => {
  const firstDayOfTheWeek = week[0].date.getDay() || 7 // HACK: Sunday returns 0
  const beforeFillers = [...Array(firstDayOfTheWeek - 1)]
  const afterFillters = [...Array(DAYS_IN_WEEK - week.length - beforeFillers.length)]

  return (
    <div className="calendar-week">
      { beforeFillers.map(() => (<div className="calendar-dayStrut is__before"></div>)) }
      { week.map(date => date && <CalendarDay date={ date } />) }
      { afterFillters.map(() => (<div className="calendar-dayStrut is__after"></div>)) }
    </div>
  )
}

const renderCalendarMonth = ({ name, weeks, isJanuary }) => (
  <div className="calendar-month">
    <div className={ cx('calendar-monthName', { is__embed: weeks.length > 1 && canEmbedMonthName(weeks[0]) }) }>
      { name } { isJanuary && renderJanuaryDate() }
    </div>
    <div className="calendar-weekList">
      { weeks.map(renderCalendarWeek) }
    </div>
  </div>
)

class CalendarBody extends Component {
  render() {
    const { months, getRef } = this.props

    return (
      <div ref={ getRef } className="calendar-body">
        { months.map(renderCalendarMonth) }
      </div>
    )
  }
}

const Calendar = ({ heading, scrollTop, months, getCalendarBodyRef, ...props }) => {
  return (
    <div className="calendar" { ...props }>
      <div
        className="calendar-headingText"
        dangerouslySetInnerHTML={ { __html: heading || 'Дата публикации' } } />

      <ul className="calendar-header" style={ getCalendarHeaderStyles(scrollTop) }>
        { ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map(renderDayListItem) }
      </ul>

      <CalendarBody getRef={ getCalendarBodyRef } months={ months } />
    </div>
  )
}

class CalendarWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrollTop: 0,
    }

    this.onCalendarScroll = this.onCalendarScroll.bind(this)

    this.months = markEngaged(
      generateMonths(new Date()), omitSelectedDate(props.engagedDates, props.id)
    )
  }

  componentDidMount() {
    this.calendarBody.addEventListener('scroll', this.onCalendarScroll)
  }

  componentWillUnmount() {
    this.calendarBody.removeEventListener('scroll', this.onCalendarScroll)
  }

  onCalendarScroll() {
    const { scrollTop } = this.calendarBody
    this.setState({ scrollTop })
  }

  render() {
    return (
      <Calendar
        heading={ this.props.heading }
        scrollTop={ this.state.scrollTop }
        months={ this.months }
        getCalendarBodyRef={ ref => this.calendarBody = ref }
      />
    )
  }
}

const mapStateToProps = ({ soviet: { id, engagedDates, publishAt } }) => ({
  engagedDates,
  publishAt,
  id,
})

export default connect(mapStateToProps)(CalendarWrapper)
