import { Component } from 'preact'
import cx from 'classnames'

const WEEK_OFFSET = 45

export default class SchoolRatingStudentsListHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isPinned: false,
      weekNumber: null,
    }

    this.checkState = this.checkState.bind(this)
  }

  componentDidMount() {
    if (!this.props.isSticky) return

    this.checkState()

    $(document).on('scroll', this.checkState)
  }

  componentWillUnmount() {
    $(document).off('scroll', this.checkState)
  }

  checkState() {
    this.setPinned()

    if (this.state.isPinned) this.setWeekNumber()
  }

  setPinned() {
    if (this.shouldPin !== this.state.isPinned) {
      this.setState({ isPinned: this.shouldPin })
      this.setWeekNumber()
    }
  }

  setWeekNumber() {
    $('.js__schoolRatingWeekAnchor').each((index, week) => {
      const $week = $(week)
      const shouldDisplayInHeader = $week[0].getBoundingClientRect().top < WEEK_OFFSET

      if (shouldDisplayInHeader) {
        const weekNumber = $week.attr('data-week') || ''
        if (weekNumber !== this.state.weekNumber) this.setState({ weekNumber })
      }
    })
  }

  get shouldPin() {
    const $container = this.$el.parentElement

    return !!$container && $container.getBoundingClientRect().top < 0
  }

  get text() {
    return I18n.t('schoolRating.weekTitle', { number: this.state.weekNumber })
  }

  render() {
    const classNames = cx(
      'schoolRatingStudentsListHeader',
      {
        is__sticky: this.props.isSticky,
        is__pinned: this.state.isPinned,
        is__empty: !this.state.weekNumber || (this.state.weekNumber < 1),
      },
    )

    return (
      <div className={ classNames } ref={ el => this.$el = el }>
        <div className="schoolRatingStudentsListHeader-inner">
          { this.text }
        </div>
      </div>
    )
  }
}
