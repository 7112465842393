const expandScreenToFlags = (screen) => {
  switch (screen) {
    case 'initial':
      return {
        isInitialScreen: true,
        isPurchaseScreen: false,
        isSuccessScreen: false,
        isSubscriptionScreen: false,
      }
    case 'purchase':
      return {
        isInitialScreen: false,
        isPurchaseScreen: true,
        isSuccessScreen: false,
        isSubscriptionScreen: false,
      }
    case 'success':
      return {
        isInitialScreen: false,
        isPurchaseScreen: false,
        isSuccessScreen: true,
        isSubscriptionScreen: false,
      }
    case 'subscription':
      return {
        isInitialScreen: false,
        isPurchaseScreen: false,
        isSuccessScreen: false,
        isSubscriptionScreen: true,
      }
    default:
      return {
        isInitialScreen: false,
        isPurchaseScreen: false,
        isSuccessScreen: false,
        isSubscriptionScreen: false,
      }
  }
}

export default expandScreenToFlags
