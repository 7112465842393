import { Caption } from '../textNodes'
import { CollectureSpacerLink } from './collectureSpacerUtils'

import { capitalize } from 'lib/textHelper'

const SpacerTitle = (props) => {
  const suffix = props.device ? capitalize(props.device) : ''

  return (
    <div className={ props.className }>
      <b className="heading">{ I18n.t(`collectures.spacer.${props.collectureId}.title${suffix}`) }</b>
      <br />
      <span>{ I18n.t(`collectures.spacer.${props.collectureId}.description${suffix}`) + ' ' }</span>
      <CollectureSpacerLink href={ props.href } isInline />
    </div>
  )
}

const CollectureSpacerTitle = (props) => {
  const collectureId = props.id.replace(/(collecture)-/, '')

  return (
    <div className="collectureSpacer-title">
      <a href={ props.href } className="doubleHover no-u">
        <SpacerTitle
          className="device desktop"
          collectureId={ collectureId } />

        <SpacerTitle
          className="device laptop"
          collectureId={ collectureId }
          device="laptop" />

        <div className="device mobile">
          <Caption>
            <b>{ I18n.t(`collectures.spacer.${collectureId}.title`) }</b>
          </Caption>
        </div>
      </a>
    </div>
  )
}

export default CollectureSpacerTitle
