import { Component } from 'preact'

import { Caption } from '../../textNodes'

export default class NewPaybarFooterCaptionBookshelf extends Component {
  get purchaseCaptionText() {
    const suffix = this.props.isPreorder ? 'WithPreorder' : ''

    return I18n.t(`newPaybar.submitButtonCaption.${this.props.product}.${this.props.type}${suffix}`)
  }

  get subscriptionCaptionText() {
    let suffix = ''

    if (this.props.isPreorder) {
      suffix = this.props.cannotRead ? 'WithPreorderCannotRead' : 'WithPreorder'
    }

    return I18n.t(`newPaybar.successCaption.${this.props.product}${suffix}`)
  }

  get captionText() {
    const { isPurchaseScreen, isForcedSubscription } = this.props

    if (!isPurchaseScreen || isForcedSubscription) return this.subscriptionCaptionText

    return this.purchaseCaptionText
  }

  render() {
    return (
      <Caption className="newPaybarFooterCaption" html={ this.captionText } />
    )
  }
}
