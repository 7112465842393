{
  const I_KEYCODE = 73
  const HIDE_IMAGES_CLASS = 'is__hidingImageResults'

  class TaskResults {
    constructor() {
      this.body = document.body

      this.bindEvents()
    }

    bindEvents() {
      this.body.addEventListener('keydown', this.toggleImageResults.bind(this))
    }

    toggleImageResults(event) {
      if (event.ctrlKey && event.keyCode === I_KEYCODE) {
        this.body.classList.toggle(HIDE_IMAGES_CLASS)
      }
    }
  }

  $('.js__taskResults').each(() => new TaskResults())
}
