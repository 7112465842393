import { Component } from 'preact'
import TextareaAutosize from 'react-textarea-autosize'
import cx from 'classnames'
import Cookies from 'js-cookie'

import AUTH_HEADERS from 'authHeaders'

import { Form } from '../form'
import { Cols } from '../cols'
import { Caption } from '../textNodes'
import { AttachmentIconWithHover } from '../icons'
import DeleteButton from '../deleteButton/deleteButton'

import { readState, mergeState, persistRawState } from 'lib/reduxStorage'

import SovietCommentFormSuccessFrame from './sovietCommentFormSuccessFrame'
import { SovietCommentFormAuthorName, SovietCommentFormAuthorEmail } from './sovietCommentFormAuthorMeta'
import SovietCommentFormAuthorRow from './sovietCommentFormAuthorRow'
import SovietCommentFormAuthorFieldset from '../sovietCommentAtoms/sovietCommentFormAuthorFieldset'
import SovietCommentFormCaption from './sovietCommentFormCaption'

const INITIAL_FORM_STATE = {
  name: (Cookies.get('name') || '').replace(/\+/g, ' ') || null,
  email: Cookies.get('email') || null,
  text: '',
  attachmentUrl: '',
  isValid: false,
  isLoading: false,
  isSent: false,
  hasError: false,
}

export default class SovietCommentForm extends Component {
  constructor(props) {
    super(props)

    this.user = window.application.user || {}
    this.scope = window.application.pageId + '/comment/draft'
    this.sovietId = this.scope.match(/[\d]+/i)[0]
    this.isAuthenticated = !!this.user.id

    this.state = mergeState(INITIAL_FORM_STATE, readState(this.scope))

    this.validityStates = this.isAuthenticated ? {} : { name: false, email: false }

    this.handleInput = this.handleInput.bind(this)
    this.handleAuthorFieldsetEdit = this.handleAuthorFieldsetEdit.bind(this)
    this.displayAttachment = this.displayAttachment.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.submit = this.submit.bind(this)
    this.reset = this.reset.bind(this)
    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.handleFetchError = this.handleFetchError.bind(this)
  }

  get isValid() {
    return this.state.text &&
      this.state.text.trim() &&
      Object.keys(this.validityStates).every(field => this.validityStates[field])
  }

  get isDisabled() {
    return !this.isValid || this.state.isLoading
  }

  componentDidUpdate() {
    persistRawState(this.scope, { text: this.state.text })
  }

  handleInput(e) {
    this.setState({
      text: e.target.value,
      isValid: this.isValid,
      hasError: false,
    })
  }

  handleAuthorFieldsetEdit({ name = this.state.name, email = this.state.email }) {
    this.setState({ name, email })
  }

  displayAttachment() {
    if (!this.fileInput || !this.fileInput.files) return

    const { files } = this.fileInput

    const rawFile = files[0]

    this.readAttachment(rawFile)
      .then(attachmentUrl => this.setState({ attachmentUrl }))
  }

  deleteAttachment() {
    this.fileInput.value = ''
    this.setState({ attachmentUrl: '' })
  }

  readAttachment(rawFile) {
    if (!rawFile) return Promise.resolve('')

    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(e.target.result)
      reader.readAsDataURL(rawFile)
    })
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity

    this.setState({ isValid: this.isValid, hasError: false })
  }

  submit(data) {
    if (!this.isValid) return

    this.setState({ isLoading: true, hasError: false })

    const body = this.toFormData(data)
    fetch(`/soviet/advices/${this.sovietId}/comments`, {
      method: 'POST',
      headers: {
        ...AUTH_HEADERS,
        Accept: 'application/json',
      },
      body: body,
    })
      .then(res => {
        if (!res.ok) throw Error(res.statusText)

        this.handleSentComment()
      })
      .catch(this.handleFetchError)
  }

  toFormData(data) {
    let formData = new FormData()

    Object.keys(data).forEach(param => formData.append(param, data[param]))

    if (this.fileInput && this.fileInput.files && this.fileInput.files.length) {
      formData.append('image', this.fileInput.files[0])
    }

    return formData
  }

  handleSentComment() {
    if (this.state.name) Cookies.set('name', this.state.name)
    if (this.state.email) Cookies.set('email', this.state.email)

    $(document).trigger('sovietCommentSent')

    this.setState({ isSent: true, text: '' })
  }

  handleFetchError() {
    this.setState({ hasError: true, isLoading: false })
  }

  reset() {
    this.setState({
      ...INITIAL_FORM_STATE,
      name: this.state.name,
      email: this.state.email,
    })
  }

  render() {
    const submitAttrs = { disabled: this.isDisabled ? 'disabled' : '' }
    const className = cx('sovietCommentForm', { is__loading: this.state.isLoading })

    if (this.state.isSent) return (<SovietCommentFormSuccessFrame onClick={ this.reset } />)

    return (
      <Cols className="sovietCommentFormFloor is__transposeOnMobile" divisionProportions="11:1:4">
        <Form className={ className } onSubmit={ this.submit }>
          <SovietCommentFormAuthorRow
            name={ this.user.name }
            email={ this.user.email }
            avatarUrl={ this.user.avatarUrl }
            isAuthenticated={ this.isAuthenticated }
          />

          { this.isAuthenticated &&
            <SovietCommentFormAuthorEmail email={ this.user.email } />
          }

          { this.isAuthenticated && this.user.name &&
            <SovietCommentFormAuthorName name={ this.user.name } />
          }

          { (!this.isAuthenticated || !this.user.name) &&
            <SovietCommentFormAuthorFieldset
              name={ this.state.name }
              email={ this.state.email }
              onEdit={ this.handleAuthorFieldsetEdit }
              onValidityChange={ this.onInputValidityChange }
              askNameOnly={ this.isAuthenticated && !this.user.name }
            />
          }

          <div className="sovietCommentForm-textWrapper">
            <TextareaAutosize
              name="text"
              className="sovietCommentForm-text"
              placeholder="Ваши соображения"
              value={ this.state.text }
              onInput={ this.handleInput } />
          </div>

          { this.state.attachmentUrl &&
            <div
              className="sovietCommentForm-attachment is__position"
              style={ { backgroundImage: `url(${this.state.attachmentUrl})` } }>

              <DeleteButton onClick={ this.deleteAttachment }/>
            </div>
          }

          <footer className="sovietCommentForm-footer">
            <span className="sovietCommentForm-submitWrapper">
              <button
                className="button is__enlargedOnMobiles"
                type="submit"
                { ...submitAttrs }>
                Отправить
              </button>
            </span>

            { this.state.hasError &&
              <div className="sovietCommentForm-error">
                <Caption className="error">Ошибка отправки. Попробуйте ещё раз</Caption>
              </div>
            }

            <div className="sovietCommentForm-attach attach">
              <AttachmentIconWithHover />
              <input
                ref={ el => this.fileInput = el }
                type="file"
                name="attachment"
                onChange={ this.displayAttachment }
                accept="image/jpeg,image/png,image/gif" />
            </div>
          </footer>
        </Form>

        <div className="module device device__desktop device__laptop"></div>

        <SovietCommentFormCaption />
      </Cols>
    )
  }
}
