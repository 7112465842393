import Caption from '../textNodes/caption'

export default function GetGiftReadInstructions({ product, email }) {
  const readInstructions = I18n.t(`getGift.emailCaption.${product}Break`, { email })

  return (
    <div className="getGiftPresentOverlay is__long">
      <div className="getGiftPresentOverlay-access">
        <span className="getGiftPresentOverlay-lock">🔓</span>
        <div className="textNode">
          <p dangerouslySetInnerHTML={{ __html: readInstructions }} />
        </div>
        <div className="getGiftPresentOverlay-support textNode">
          <Caption html={ I18n.t('getGift.supportCaption') } />
        </div>
      </div>
    </div>
  )
}
