import { Component } from 'preact'
import Cookies from 'js-cookie'
import cx from 'classnames'
import fetchAskForm from 'lib/soviets/fetchAskForm'

import { Form } from '../form'
import { Caption } from '../textNodes'
import { AttachmentIconWithHover } from '../icons'
import TextareaAutosize from 'react-textarea-autosize'
import DeleteButton from '../deleteButton/deleteButton'

import { readState, mergeState, persistRawState } from '../../assets/scripts/lib/reduxStorage'
import { readAttachment } from 'lib/formHelper'

import SovietAskFormAuthorFieldset from './sovietAskFormAuthorFieldset'
import SovietAskFormSpamBotDetector from './sovietAskFormSpamBotDetector'
import SovietAskFormUploadImage from './sovietAskFormUploadImage'

const isValidUpload = files => files.length && /^image/.test(files[0].type)
const getUserNameFromCookies = () => (Cookies.get('name') || '').replace(/\+/g, ' ') || null
const getUserEmailFromCookies = () => Cookies.get('email') || null

const INITIAL_FORM_STATE = {
  question: '',
  attachmentUrl: '',
  isValid: false,
  isLoading: false,
  hasError: false,
}

export default class SovietAskForm extends Component {
  constructor(props) {
    super(props)

    this.user = window.application.user || {}
    this.isAuthenticated = !!this.user.id
    this.scope = 'sovietAskFormDraft'
    this.dropAttachmentFile = []

    this.userData = {
      name: this.isAuthenticated ? this.user.name : getUserNameFromCookies(),
      email: this.isAuthenticated ? this.user.email : getUserEmailFromCookies(),
    }

    const initialState = mergeState(INITIAL_FORM_STATE, this.userData || {})
    this.state = mergeState(initialState, readState(this.scope))

    this.validityStates = {
      name: this.isAuthenticated,
      email: this.isAuthenticated,
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleAuthorFieldsetEdit = this.handleAuthorFieldsetEdit.bind(this)
    this.displayDropAttachment = this.displayDropAttachment.bind(this)
    this.displayAttachment = this.displayAttachment.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.submit = this.submit.bind(this)
    this.reset = this.reset.bind(this)
    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.handleFetchError = this.handleFetchError.bind(this)
  }

  get isFieldsValid() {
    return Object.keys(this.validityStates).every(field => this.validityStates[field])
  }

  get isValid() {
    return this.state.question &&
      this.state.question.trim() &&
      this.isFieldsValid
  }

  get isDisabled() {
    return !this.isValid || this.state.isLoading
  }

  componentDidMount() {
    const { name, email } = this.state

    if (name && email) setTimeout(() => this.textarea.focus(), 500)
  }

  componentDidUpdate() {
    const { name, email, question } = this.state

    const scopeValue = {
      name: name && name.trim() ? name : undefined,
      email: email && email.trim() ? email : undefined,
      question,
    }

    persistRawState(this.scope, scopeValue)
  }

  clearRememberedQuestion() {
    try {
      window.localStorage.removeItem(this.scope)
    } catch (e) {
      console.warn('Failed to clear remembered scope: ', e)
    }
  }

  handleInput(e) {
    this.setState({
      question: e.target.value,
      isValid: this.isValid,
      hasError: false,
    })
  }

  handleAuthorFieldsetEdit({ name = this.state.name, email = this.state.email }) {
    this.setState({ name, email })
  }

  displayAttachment() {
    if (!this.fileInput || !this.fileInput.files) return

    const { files } = this.fileInput

    const rawFile = files[0]

    readAttachment(rawFile)
      .then(dataUrl => this.setState({ attachmentUrl: dataUrl }))

    if (this.dropAttachmentFile.length) this.deleteDropAttachment()
  }

  displayDropAttachment(files) {
    if (!isValidUpload(files)) return

    const rawFile = files[0]

    readAttachment(rawFile)
      .then(dataUrl => this.setState({ attachmentUrl: dataUrl }))

    this.dropAttachmentFile = []
    this.dropAttachmentFile.push(rawFile)

    if (this.fileInput.files.length) this.deleteInputAttachment()
  }

  deleteInputAttachment() {
    this.fileInput.value = ''
    this.setState({ attachmentUrl: '' })
  }

  deleteDropAttachment() {
    this.dropAttachmentFile = []
    this.setState({ attachmentUrl: '' })
  }

  deleteAttachment() {
    if (this.fileInput && this.fileInput.files && this.fileInput.files.length) {
      this.deleteInputAttachment()
    }

    if (this.dropAttachmentFile.length) {
      this.deleteDropAttachment()
    }
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity

    this.setState({ isValid: this.isValid, hasError: false })
  }

  submit(data) {
    if (!this.isValid) return

    this.setState({ isLoading: true, hasError: false })

    const body = this.toFormData(data)
    fetchAskForm(body)
      .then(res => {
        if (!res.ok) throw Error(res.statusText)

        this.handleSentAsk()
      })
      .then(() => this.reset())
      .catch(this.handleFetchError)
  }

  toFormData(data) {
    let formData = new FormData()

    Object.keys(data).forEach(param => formData.append(param, data[param]))

    if (this.fileInput && this.fileInput.files && this.fileInput.files.length) {
      formData.append('image', this.fileInput.files[0])
    }

    if (this.dropAttachmentFile.length) {
      formData.set('_file_image', this.dropAttachmentFile[0].name)
      formData.append('image', this.dropAttachmentFile[0])
    }

    return formData
  }

  handleSentAsk() {
    if (this.state.name) Cookies.set('name', this.state.name)
    if (this.state.email) Cookies.set('email', this.state.email)

    $(document).trigger('sovietAskSent')

    this.setState({ question: '' })
    this.clearRememberedQuestion()

    this.props.onFormSubmit()
  }

  handleFetchError() {
    this.setState({ hasError: true, isLoading: false })
  }

  reset() {
    this.setState({
      ...INITIAL_FORM_STATE,
      name: this.state.name,
      email: this.state.email,
    })
  }

  render() {
    const className = cx('sovietAskForm', { is__loading: this.state.isLoading })

    return (
      <Form className={ className } onSubmit={ this.submit }>
        <SovietAskFormAuthorFieldset
          name={ this.state.name }
          email={ this.state.email }
          onEdit={ this.handleAuthorFieldsetEdit }
          onValidityChange={ this.onInputValidityChange } />

        <div className="formTextarea-wrapper">
          <TextareaAutosize
            name="question"
            inputRef={ textarea => this.textarea = textarea }
            className="formTextarea sovietAskForm-text"
            placeholder={ I18n.t('soviet.askForm.question') }
            value={ this.state.question }
            onInput={ this.handleInput } />
        </div>

        { this.state.attachmentUrl &&
          <div
            className="sovietAskForm-attachment is__position"
            style={ { backgroundImage: `url(${this.state.attachmentUrl})` } }>

            <DeleteButton onClick={ this.deleteAttachment }/>
          </div>
        }

        <footer class="sovietAskForm-footer">
          <div className="sovietAskForm-submitWrapper">
            <button
              className="button is__enlargedOnMobiles"
              type="submit"
              disabled={ this.isDisabled }>
              { I18n.t('soviet.askForm.send') }
            </button>
          </div>

          { this.state.hasError &&
            <Caption className="sovietAskForm-error error">{ I18n.t('soviet.askForm.error') }</Caption>
          }

          <div className="formAttach attach">
            <AttachmentIconWithHover />
            <input
              ref={ el => this.fileInput = el }
              type="file"
              name="_file_image"
              onChange={ this.displayAttachment }
              accept="image/jpeg,image/png,image/gif" />
          </div>
        </footer>
        <SovietAskFormUploadImage onFileUpload={ this.displayDropAttachment } />
        <SovietAskFormSpamBotDetector />
      </Form>
    )
  }
}
