const BaseModule = require('_base/base')

const CLASS_ACTIVE = 'is__active'
const CLASS_ACTIVE_RIGHT_NOW = 'is__activeRightNow'

class Fader extends BaseModule {
  postInit() {
    this.switchOn = this.$el.data('switch-on')
    this.isActive = false
    this.$closestSpread = this.$el.closest('.spread')

    this.$closestSpread.on('anchor', (e, data) => requestAnimationFrame(() => this.draw(data)))
  }

  disable() {
    if (!this.isActive) return

    this.isActive = false
    this.$el.removeClass(CLASS_ACTIVE)
  }

  enable() {
    if (this.isActive) return

    this.isActive = true
    this.$el.addClass(CLASS_ACTIVE)
  }

  draw(data) {
    if (data.id >= this.switchOn) {
      this.enable()
    } else {
      this.disable()
    }

    this.$el.toggleClass(CLASS_ACTIVE_RIGHT_NOW, String(data.id) === String(this.switchOn))
  }
}

$('.js__fffader').each(function() {
  new Fader($(this))
})
