const RESIZE_THROTTLE = 400
const MOBILE_RESIZE_THRESHOLD = 5
const LOGGED_IN_CLASS = 'is__loggedIn'
const HAS_NAME_CLASS = 'has__name'
const READY_CLASS = 'is__ready'
const SCROLLABLE_CLASS = 'is__scrollable'
const IS_HONORABLE_CLASS = 'is__userHonorable'
const IS_MODERATOR_CLASS = 'is__userModerator'
const IS_DEBUGGED_CLASS = 'is__debugged'
const HIGHLIGHT_CLASS = 'is__highlightMark'
const HIGHLIGHT_VISIBLE_CLASS = 'has__activeHighlight'

const HIGHLIGHT_TOGGLE_EVENTS = 'wheel.highlight touchmove.highlight'
const HIGHLIGHT_TIMEOUT = 6000

const LAST_FRAME_TIMING = 0.999

const DEBUGGER_USER_EMAILS = ['vasily@polovnyov.ru', 'sergeyfrolov@artgorbunov.ru']

const findAnchor = ({ spreadId, anchorId }) => window.application.anchorsBySpreadId[spreadId]
  .anchors
  .find(anchor => String(anchor.id) === String(anchorId))

class Webpage {
  constructor($el) {
    this.$el = $el
    this.$document = $(document)
    this.$body = $('body')

    this.affectingModules = []
    this.dependentModules = []
    this.reactiveModules = []
    this.finalModules = []
    this.animatedModules = []
    this.triggers = { hold: this.$el.offset().top }
    this._book = { cellHeight: window.innerHeight / 12 }
    this.documentHeight = this.$document.height()

    this.$el.on('moduleInit', (_, module) => {
      if (this.isAffectingHeight) {
        this.affectingModules.push(module)
      } else if (this.isFinal) {
        this.finalModules.push(module)
      } else {
        this.dependentModules.push(module)
      }
    })

    this.$el.on('animatedModuleInit', (e, subModule) => {
      e.stopPropagation()
      this.animatedModules.push(subModule)
    })

    this.$el.on('appHighlight', this.highlight.bind(this))

    $(window).on('scroll', throttle(50, () => {
      this.$document.trigger('appScroll', this.currentScrollPosition)
    }))

    this.animateChildren = this.animateChildren.bind(this)

    try {
      requestAnimationFrame(this.animateChildren)
    } catch (e) {
      console.log(e)
    }

    this.$document.on('appResize', this.init.bind(this))
    this.$document.on('appSetUser', this._setUser.bind(this))
    this._bindResizeListeners()

    this.$el.trigger('spreadCreated', this)
    this.init()
    this.$body.addClass(READY_CLASS)

    this.$document.trigger('appReady')
    this.$el
      .trigger('webpageReady')
      .trigger('spreadLoaded')
      .trigger('spreadVisualized')

    this._checkDocumentHeightChange = this._checkDocumentHeightChange.bind(this)
    setInterval(this._checkDocumentHeightChange, 1500)
  }

  init() {
    if (this.wasRendered) {
      this.reset()
    }
    this.calculate()
    this.render()
  }

  reset() {
    Webpage.resetModules(this.affectingModules)
    Webpage.resetModules(this.dependentModules)
    Webpage.resetModules(this.finalModules)
  }

  calculate() {
    this.calculateViewDimensions()

    Webpage.calculateModules(this.affectingModules)
    Webpage.renderModules(this.affectingModules)
    Webpage.calculateModules(this.dependentModules)
  }

  render() {
    Webpage.renderModules(this.dependentModules)
    Webpage.calculateModules(this.finalModules)
    Webpage.renderModules(this.finalModules)

    this.calculateRelativeAnimatedModules()
    this.calculateAnchorAnimatedModules()

    this.wasRendered = true
  }

  get currentScrollPosition() {
    return window.application.currentScrollPosition
  }

  highlight() {
    this.unhighlight()

    this.$el.one(HIGHLIGHT_TOGGLE_EVENTS, this.toggleHighlightOff.bind(this))
  }

  unhighlight() {
    clearTimeout(this.highlightTimeout)

    this.$el
      .off(HIGHLIGHT_TOGGLE_EVENTS)
      .removeClass(HIGHLIGHT_VISIBLE_CLASS)
  }

  removeHighlights() {
    this.$el.find(`.${HIGHLIGHT_CLASS}`).removeClass(HIGHLIGHT_CLASS)
  }

  toggleHighlightOff() {
    this.$el.addClass(HIGHLIGHT_VISIBLE_CLASS)

    setTimeout(() => {
      this.removeHighlights()
      this.unhighlight()
    }, HIGHLIGHT_TIMEOUT)
  }

  _bindResizeListeners() {
    const triggerAppResize = () => this.$document.trigger('appResize')
    let resizeTimer

    const handleResize = () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(triggerAppResize, RESIZE_THROTTLE)
    }

    const afterOrientationChange = () => {
      handleResize()
      window.removeEventListener('resize', afterOrientationChange)
    }

    const handleOrientationChange = () => window.addEventListener('resize', afterOrientationChange)

    if (window.application.isOnMobileDevice) {
      window.addEventListener('orientationchange', handleOrientationChange)

      window.addEventListener('resize', function() {
        const widthDiff = Math.abs(this.viewWidth - window.innerWidth)
        if (widthDiff > MOBILE_RESIZE_THRESHOLD) handleResize()
      })
    } else {
      window.addEventListener('resize', handleResize)
    }
  }

  animateChildren() {
    if (!this.animatedModules.length) return

    const scrollTop = this.currentScrollPosition
    if (scrollTop !== this.previouslyAnimatedAt) {
      this.animateChildrenAt(scrollTop)
    }

    this.previouslyAnimatedAt = scrollTop

    requestAnimationFrame(this.animateChildren)
  }

  animateChildrenAt(scrollTop) {
    this.animatedModules.forEach(module => {
      const { tillPosition } = module.animation
      let position = scrollTop - (module.relativeParentPosition || 0)
      const animationCap = tillPosition * LAST_FRAME_TIMING

      // HACK: otherwise Safari resets animation
      if (position >= animationCap) position = animationCap

      module.animate(position)
    })
  }

  _setUser(_, user) {
    const $body = $('body')

    if (user) $body.addClass(LOGGED_IN_CLASS)
    if (user && user.name) $body.addClass(HAS_NAME_CLASS)
    if (user && user.honorable) $body.addClass(IS_HONORABLE_CLASS)
    if (user && user.isModerator) $body.addClass(IS_MODERATOR_CLASS)
    if (user && DEBUGGER_USER_EMAILS.includes(user.email)) $body.addClass(IS_DEBUGGED_CLASS)
  }

  _checkDocumentHeightChange() {
    if (this.documentHeight != this.$document.height()) {
      this.documentHeight = this.$document.height()
      this.$document.trigger('appHeightChanged')
    }
  }

  calculateViewDimensions() {
    window.application.viewHeight = this.viewHeight = window.innerHeight
    window.application.viewWidth = this.viewWidth = window.innerWidth
    document.documentElement.style.setProperty('--viewHeight', this.viewHeight + 'px')

    document.body.classList.remove(SCROLLABLE_CLASS)
    const isScrollable = document.documentElement.scrollHeight > window.innerHeight
    document.body.classList.toggle(SCROLLABLE_CLASS, isScrollable)
  }

  calculateRelativeAnimatedModules() {
    this.animatedModules.forEach(module => {
      const $animationBase = module.$el.closest('.is__animationBase')
      module.isRelativelyAnimated = !!$animationBase.length

      if (module.isRelativelyAnimated) {
        module.relativeParentPosition = $animationBase.offset().top
        module.relativeParentHeight = $animationBase.outerHeight()

        if (module.animation.fromposition.includes('%')) {
          module.animation.fromPosition = this.percentagePositionOf(module, 'fromposition')
          module.animation.tillPosition = this.percentagePositionOf(module, 'tillposition')
        }
      }
    })
  }

  calculateAnchorAnimatedModules() {
    this.animatedModules
      .filter(module => !!module.animation.fromanchor && !!module.animation.tillanchor)
      .forEach(module => {
        const { fromAnchorId, tillAnchorId } = module.animation
        const spreadId = module.$el.closest('.spread').attr('data-spread-id')
        module.animation.fromPosition = this.findFromPositionForAnchor({ spreadId, fromAnchorId })
        module.animation.tillPosition = this.findTillPositionForAnchor({ spreadId, tillAnchorId })
      })
  }

  percentagePositionOf(module, position) {
    const percentage = parseFloat(module.animation[position]) / 100

    return module.relativeParentHeight * percentage
  }

  findFromPositionForAnchor({ spreadId, fromAnchorId }) {
    return findAnchor({ spreadId, anchorId: fromAnchorId }).trigger
  }

  findTillPositionForAnchor({ spreadId, tillAnchorId }) {
    return findAnchor({ spreadId, anchorId: tillAnchorId }).trigger - 1
  }

  static resetModules(modules) {
    modules.forEach(module => { module.reset() })
  }

  static calculateModules(modules) {
    modules.forEach(module => { module.calculate() })
  }

  static renderModules(modules) {
    modules.forEach(module => { module.render() })
  }
}

module.exports = Webpage
