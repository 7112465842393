const FIGURE_SPACE = '\u2007'

const replaceSingleZero = str => str.replace(/^0{1}$/, FIGURE_SPACE)

export function padCount(count) {
  return FIGURE_SPACE + replaceSingleZero(count.toString())
}

export function recalculateCountPadding(count, nextCount) {
  const paddedCountLength = padCount(count).length
  nextCount = nextCount.toString()
  const nextCountLength = nextCount.length

  if (paddedCountLength < nextCountLength) return nextCount
  if (paddedCountLength > nextCountLength) {
    return replaceSingleZero(nextCount).padStart(paddedCountLength, FIGURE_SPACE)
  }

  return replaceSingleZero(nextCount)
}
