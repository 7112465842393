import cx from 'classnames'

import { HalfCell, TagsCell } from './sovietCompilationCells'
import { Cols } from '../cols'
import hasValidCover from './hasValidCover'
import { sovietCommentsCount } from 'lib/textHelper'

const SPECIAL_CELLS = {
  tags: TagsCell,
}

const getSpecialCellSlug = (url) => {
  const slug = url.match(/soviet\/(?![\d])([\w]+)/i)

  return slug && slug[1] || ''
}

const getSpecialCell = (props) => {
  const slug = getSpecialCellSlug(props.soviet.url)

  return SPECIAL_CELLS[slug] || null
}

const SovietHalfCell = (props) => {
  const SpecialCell = getSpecialCell(props)

  if (SpecialCell) {
    return <SpecialCell />
  }

  return (
    <HalfCell
      mainColumn={ props.soviet.mainColumn }
      skipColumn={ props.soviet.skipColumn }
      column={ props.soviet.column }
      title={ props.soviet.hyphenatedCompiledTitle || props.soviet.hyphenatedTitle }
      linkText={ `Совет ${props.soviet.authorGenitiveName}` }
      linkSuffix={ sovietCommentsCount(props.soviet.commentsCount, props.soviet.newCommentsCount) }
      href={ props.soviet.url }
      coverUrl={ props.soviet.coverUrl }
      coverSize={ props.soviet.coverSize }
      isReversed={ props.soviet.isReversed }
      { ...props }
      className={ props.className || '' }
    />
  )
}

const SingleRow = (soviet) => {
  return (
    <Cols
      divisionProportions={ soviet.doubleDivisionProportions || '11:5' }
      className="compilationRow"
      ribMask={ [false] }
    >
      <SovietHalfCell soviet={ soviet } />
    </Cols>
  )
}

const DoubleRow = (soviets) => {
  const isLeftCellWithCover = hasValidCover(soviets[0])
  const isRightCellWithCover = hasValidCover(soviets[1])
  const isTextOnly = !isLeftCellWithCover && !isRightCellWithCover
  const isMixedRow = !isLeftCellWithCover && isRightCellWithCover || isLeftCellWithCover && !isRightCellWithCover
  const classNames = cx(
    'compilationRow',
    'is__double',
    'js__equalize',
    {
      is__textOnly: isTextOnly,
    }
  )

  let proportions = isTextOnly ? '11:5' : '1:1'

  if (isTextOnly) {
    proportions = soviets[0].doubleDivisionProportions || proportions
  } else {
    proportions = soviets[0].doubleImageDivisionProportions || proportions
  }

  const reversedProportions = proportions.split(':').reverse().join(':')
  const divisionProportions = soviets[0].isReversed ? reversedProportions : proportions

  if (isTextOnly) {
    return (
      <Cols
        divisionProportions={ divisionProportions }
        className={ classNames }
        isRibbed="true"
        transposeOnMobile="true"
      >
        <SovietHalfCell
          soviet={ soviets[0] }
          size={ !soviets[0].isReversed ? 'large' : '' }
        />
        <SovietHalfCell
          soviet={ soviets[1] }
          size={ soviets[0].isReversed ? 'large' : '' }
        />
      </Cols>
    )
  }

  const splitProportions = divisionProportions.split(':')
  const isLeftCellCentered = !isLeftCellWithCover && splitProportions[0] === splitProportions[1]
  const isRightCellCentered = !isRightCellWithCover && splitProportions[1] === splitProportions[0]

  return (
    <Cols
      divisionProportions={ divisionProportions }
      className={ classNames }
      ribMask={ isMixedRow || isTextOnly ? [!isLeftCellCentered, !isRightCellCentered] : [false, false] }
      mobileRibMask={ [!isLeftCellWithCover, !isRightCellWithCover] }
      transposeOnMobile="true"
    >
      <SovietHalfCell
        soviet={ soviets[0] }
        isEqualized={ true }
        size={ splitProportions[0] < splitProportions[1] ? '' : 'heading' }
        align={ splitProportions[0] === splitProportions[1] ? 'center' : '' }
        className={ isLeftCellCentered ? 'is__center' : 'is__left' }
      />
      <SovietHalfCell
        soviet={ soviets[1] }
        isEqualized={ true }
        size={ splitProportions[1] < splitProportions[0] ? '' : 'heading' }
        align={ splitProportions[0] === splitProportions[1] ? 'center' : '' }
        className={ isRightCellCentered ? 'is__center' : 'is__left' }
      />
    </Cols>
  )
}

const TripleRow = (soviets) => {
  const isLeftCellWithCover = hasValidCover(soviets[0])
  const isMiddleCellWithCover = hasValidCover(soviets[1])
  const isRightCellWithCover = hasValidCover(soviets[2])
  const hasThreeCovers = isLeftCellWithCover && isMiddleCellWithCover && isRightCellWithCover

  const classNames = cx('compilationRow', 'is__triple', 'js__equalize')

  return (
    <Cols
      divisionProportions={ soviets[0].isReversed ? '2:1:1' : '1:1:2' }
      className={ classNames }
      ribMask={ hasThreeCovers ? [false, false, false] : [true, true, true] }
      mobileRibMask={ [!isLeftCellWithCover, !isMiddleCellWithCover, !isRightCellWithCover] }
      transposeOnMobile="true"
    >
      <SovietHalfCell
        soviet={ soviets[0] }
        isEqualized={ true }
        size={ soviets[0].isReversed ? 'large' : '' }
      />
      <SovietHalfCell
        soviet={ soviets[1] }
        isEqualized={ true }
        className="is__secondary"
      />
      <SovietHalfCell
        soviet={ soviets[2] }
        isEqualized={ true }
        size={ !soviets[0].isReversed ? 'large' : '' }
      />
    </Cols>
  )
}

const renderRows = (rows, additionalProps) => {
  return rows.map((row) => {
    const decoratedRow = row.map(soviet => { return { ...soviet, ...additionalProps } })

    switch (row.length) {
      case 1:
        return <SingleRow {...decoratedRow[0]} />

      case 2:
        return <DoubleRow {...decoratedRow} />

      case 3:
        return <TripleRow {...decoratedRow} />

      default:
        return null
    }
  })
}

export default (props) => {
  const classNames = cx('compilationRows', props.className)

  return (
    <div className={ classNames }>
      { renderRows(props.rows, props) }
    </div>
  )
}
