import { Component } from 'preact'

import Price from '../price/price'
import Pricing from '../price/pricing'

import { formatPrice } from 'lib/textHelper'

export default class PurchasePricing extends Component {
  // TODO: дублируется в ценах, вынести в отдельный класс?
  get hasLeftUnits() {
    return this.props.prices.currency === 'USD'
  }

  get leftUnits() {
    return this.hasLeftUnits ? this.defaultUnits : null
  }

  get defaultUnits() {
    return I18n.t('subscription.unitsOneTime')
  }

  render() {
    const { prices, largePrices, smallUnits, label } = this.props

    let defaultPriceLabelSuffix = ''

    if (prices.isApplicableForDiscount && prices.previousOneTime !== prices.oneTime) {
      defaultPriceLabelSuffix = ` <s>${prices.previousOneTime}</s>`
    }

    return (
      <Pricing currency={ prices.currency }>
        <Price
          label={ label + defaultPriceLabelSuffix }
          amount={ formatPrice(prices.oneTime) }
          className={ prices.isApplicableForDiscount ? 'is__applicableForDiscount' : '' }
          smallUnits={ smallUnits }
          large={ largePrices }
          leftUnits={ this.leftUnits }
          units={ this.hasLeftUnits ? null : this.defaultUnits }
        />
      </Pricing>
    )
  }
}
