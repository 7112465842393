import { Component } from 'preact'

import { getFullWeeksPath, getStepPath } from './graphHelpers'
import { CrossBoldIcon, CrossIcon, PauseBoldIcon, PauseIcon } from '../icons'
import { isStatusDisabled, isStatusPaused } from './helpers'

const END_ICON_COMPENSATOR = 3.5

export class WeeksPath extends Component {
  get d() {
    const { chartParams, stepCoords } = this.props

    return getFullWeeksPath(chartParams, stepCoords)
  }

  render() {
    return (
      <path className="schoolRatingWeeksPath" d={ this.d }/>
    )
  }
}

export class StepHover extends Component {
  constructor(props) {
    super(props)

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  get d() {
    const { chartParams, coords, prevCoords, isExtended } = this.props
    return getStepPath(chartParams, coords, prevCoords, isExtended)
  }

  onMouseEnter() {
    this.props.onMouseEnter(this.props.weekNumber)
  }

  onMouseLeave() {
    this.props.onMouseLeave()
  }

  render() {
    return (
      <path
        className="schoolRatingSvgHover"
        d={ this.d }
        onMouseEnter={ this.onMouseEnter }
        onMouseLeave={ this.onMouseLeave }/>
    )
  }
}

export const End = ({ status, coords }) => {
  if (isStatusPaused(status)) return <Pause coords={ coords }/>
  if (isStatusDisabled(status)) return <Cross coords={ coords }/>

  return <Dot coords={ coords }/>
}

const Dot = ({ coords: { x, y } }) => (
  <circle className="schoolRatingGraphDot" cx={ x } cy={ y }/>
)

class Cross extends Component {
  render() {
    const { coords: { x, y } } = this.props
    const style = `transform: translate(${x - END_ICON_COMPENSATOR}px, ${y}px)`

    return (
      <g className="schoolRatingGraphCross" style={ style }>
        <CrossIcon/>
        <CrossBoldIcon/>
      </g>
    )
  }
}

class Pause extends Component {
  render() {
    const { coords: { x, y } } = this.props
    const style = `transform: translate(${x - END_ICON_COMPENSATOR}px, ${y}px)`

    return (
      <g className="schoolRatingGraphPause" style={ style }>
        <PauseIcon/>
        <PauseBoldIcon/>
      </g>
    )
  }
}
