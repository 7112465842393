import SubscriptionPricingWithFootnote from '../subscriptionPricing/subscriptionPricingWithFootnote'

const LectureSpacerPricing = ({ prices }) => (
  <div className="lectureSpacer-pricing">
    <div className="device desktop">
      <SubscriptionPricingWithFootnote
        prices={ prices }
        size="pageTitle"
        unitsSize="medium"
      />
    </div>

    <div className="device laptop">
      <SubscriptionPricingWithFootnote
        prices={ prices }
        unitsSize="medium"
        size="title"
        hasCompactUnitsView />
    </div>
  </div>
)

export default LectureSpacerPricing
