const CLASS_ACTIVE = 'is__active'
const CLASS_EXACT = 'is__exact'
const CLASS_SCROLLABLE = 'is__scrollable'
const SCROLL_WIDTH_THRESHOLD = 5

class Menu {
  constructor($el) {
    this.$el = $el
    this.$document = $(document)
    this.$menuHolder = this.$el.parent()

    this.checkActiveElement()
    this.bindEvents()
    this.toggleScrollable()
  }

  get isScrollable() {
    const holder = this.$menuHolder[0]

    return holder.scrollWidth > holder.offsetWidth + SCROLL_WIDTH_THRESHOLD
  }

  bindEvents() {
    this.$document.on('appResize', this.scrollToActiveItem.bind(this))
    this.$document.on('appResize', this.toggleScrollable.bind(this))
  }

  checkActiveElement() {
    const pathname = window.location.pathname

    this.$activeElement = this.$el.find('a').filter(function() {
      const href = $(this).attr('href')
      const aliases = ($(this).attr('data-aliases') || '').split(',')

      return aliases
        .concat(href)
        .some(scope => scope.length && pathname.indexOf(scope) === 0)
    })

    this.$activeElement.addClass(CLASS_ACTIVE)

    if (this.$activeElement.attr('href') === pathname) {
      this.$activeElement.addClass(CLASS_EXACT)
    }

    this.scrollToActiveItem()
  }

  toggleScrollable() {
    this.$menuHolder
      .closest('.header-menu')
      .toggleClass(CLASS_SCROLLABLE, this.isScrollable)
  }

  scrollToActiveItem() {
    const menuHolderWidth = this.$menuHolder.width()
    const activeElWidth = this.$activeElement.width()
    const prevItems = this.$activeElement.closest('.menu-item').prevAll().get()
    const activeElLeftPosition = prevItems.reduce((sum, el) => sum + $(el).outerWidth(true), 0)

    if (menuHolderWidth < (activeElLeftPosition + activeElWidth)) {
      this.$menuHolder.scrollLeft(activeElLeftPosition - menuHolderWidth / 2 + activeElWidth / 2)
    } else {
      this.$menuHolder.scrollLeft(0)
    }
  }
}

module.exports = Menu
