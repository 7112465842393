const DEFAULT_PARAMS = {
  headerTemplate: 'default',
  isTitleHidden: false,
  titleSize: '',
  isQuestionHidden: false,
  questionStyle: 'default',
  hasQuestionCols: false,
  headerTemplateRequiresImage: false,
  questionMinimumLines: null,
  isHangingTitle: false,
}

module.exports = {
  headerTemplate1: {
    ...DEFAULT_PARAMS,
    titleSize: 'large',
    isQuestionHidden: true,
  },
  headerTemplate21: {
    ...DEFAULT_PARAMS,
    titleSize: 'large',
  },
  headerTemplate2: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'center',
    titleSize: 'largest',
    isQuestionHidden: true,
  },
  headerTemplate3: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'center',
    titleSize: 'largest',
    questionStyle: 'sans',
  },
  headerTemplate4: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'center',
    titleSize: 'largest',
    hasQuestionCols: true,
    questionMinimumLines: 'five',
  },
  headerTemplate5: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftWide',
    hasQuestionCols: true,
    questionMinimumLines: 'five',
  },
  headerTemplate6: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftNarrow',
    questionMinimumLines: 'three',
  },
  headerTemplate7: {
    ...DEFAULT_PARAMS,
    questionMinimumLines: 'three',
  },
  headerTemplate8: {
    ...DEFAULT_PARAMS,
    titleSize: 'large',
    questionStyle: 'large',
    questionMinimumLines: 'two',
  },
  headerTemplate9: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftHalfPage',
    titleSize: 'large',
    questionMinimumLines: 'five',
    isHangingTitle: true,
  },
  headerTemplate10: {
    ...DEFAULT_PARAMS,
    isTitleHidden: true,
    questionStyle: 'large',
    questionMinimumLines: 'three',
  },
  headerTemplate11: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftWide',
    isTitleHidden: true,
    hasQuestionCols: true,
    questionMinimumLines: 'five',
  },
  headerTemplate12: {
    ...DEFAULT_PARAMS,
    titleSize: 'large',
    isQuestionHidden: true,
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'three',
  },
  headerTemplate13: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftWide',
    headerTemplateRequiresImage: true,
    titleSize: 'large',
    questionMinimumLines: 'three',
  },
  headerTemplate19: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftHalfPage',
    headerTemplateRequiresImage: true,
    titleSize: 'large',
    questionMinimumLines: 'five',
    isHangingTitle: true,
  },
  headerTemplate20: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftNarrowWithTitleTemplate',
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'five',
    hasQuestionCols: true,
    isHangingTitle: true,
  },
  headerTemplate17: {
    ...DEFAULT_PARAMS,
    isTitleHidden: true,
    questionStyle: 'sans',
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'three',
  },
  headerTemplate18: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'leftWide',
    isTitleHidden: true,
    hasQuestionCols: true,
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'five',
  },
  headerTemplate22: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageLeft',
    headerTemplateRequiresImage: true,
    titleSize: 'largest',
    isQuestionHidden: true,
  },
  headerTemplate15: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageLeft',
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'three',
  },
  headerTemplate23: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageLeft',
    headerTemplateRequiresImage: true,
    isTitleHidden: true,
    questionStyle: 'heading',
    questionMinimumLines: 'five',
  },
  headerTemplate26: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageLeft',
    headerTemplateRequiresImage: true,
    isTitleHidden: true,
    questionMinimumLines: 'five',
  },
  headerTemplate24: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageRight',
    headerTemplateRequiresImage: true,
    titleSize: 'largest',
    isQuestionHidden: true,
  },
  headerTemplate14: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageRight',
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'three',
  },
  headerTemplate25: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageRight',
    headerTemplateRequiresImage: true,
    isTitleHidden: true,
    questionStyle: 'heading',
    questionMinimumLines: 'five',
  },
  headerTemplate27: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageRight',
    headerTemplateRequiresImage: true,
    isTitleHidden: true,
    questionMinimumLines: 'five',
  },
  headerTemplate28: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageLeftNarrow',
    titleSize: 'largest',
    isQuestionHidden: true,
    headerTemplateRequiresImage: true,
  },
  headerTemplate29: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageLeftNarrow',
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'five',
  },
  headerTemplate30: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageRightNarrowWithTitle',
    titleSize: 'largest',
    isQuestionHidden: true,
    headerTemplateRequiresImage: true,
  },
  headerTemplate16: {
    ...DEFAULT_PARAMS,
    headerTemplate: 'imageRightNarrow',
    headerTemplateRequiresImage: true,
    questionMinimumLines: 'three',
  },
}
