export function setTitle(title) {
  return {
    type: 'SET_TITLE',
    title,
  }
}

export function setSummary(summary) {
  return {
    type: 'SET_SUMMARY',
    summary,
  }
}

export function setDescription(description) {
  return {
    type: 'SET_DESCRIPTION',
    description,
  }
}

export function setLinks(links) {
  return {
    type: 'SET_LINKS',
    links,
  }
}

export function setSoviets(soviets) {
  return {
    type: 'SET_SOVIETS',
    soviets,
  }
}

export function setCategories(categories) {
  return {
    type: 'SET_CATEGORIES',
    categories,
  }
}

export function setLayout(layout) {
  return {
    type: 'SET_LAYOUT',
    layout,
  }
}

export function setCustomThemeLayout(layout) {
  return {
    type: 'SET_CUSTOM_THEME_LAYOUT',
    layout,
  }
}

export function setTextColor(textColor) {
  return {
    type: 'SET_TEXT_COLOR',
    textColor,
  }
}

export function setCustomThemeTextColor(textColor) {
  return {
    type: 'SET_CUSTOM_THEME_TEXT_COLOR',
    textColor,
  }
}

export function setCover({ url, id }) {
  return {
    type: 'SET_COVER',
    customCoverUrl: url,
    url,
    id,
  }
}

export function setTheme({ theme, layout, textColor, coverUrl }) {
  return {
    type: 'SET_THEME',
    theme,
    layout,
    textColor,
    coverUrl,
  }
}

export function setCoverUploadError() {
  return {
    type: 'SET_COVER_UPLOAD_ERROR',
  }
}

export function setCanPublish(canPublish) {
  return {
    type: 'SET_CAN_PUBLISH',
    canPublish,
  }
}

export function setIsPublishing() {
  return {
    type: 'SET_IS_PUBLISHING',
  }
}

export function setHasPublishError() {
  return {
    type: 'SET_HAS_PUBLISH_ERROR',
  }
}
