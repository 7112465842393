import { Component } from 'preact'
import JSON_HEADERS from 'jsonHeaders'
import AUTH_HEADERS from 'authHeaders'

import TaskResultMarkPart from './taskResultMarkPart'
import TaskResultMarkScores from './taskResultMarkScores'
import HorizontalScrollableBox from '../horizontalScrollableBox/horizontalScrollableBox'

class TaskResultMark extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userMarks: {},
      userParts: props.userParts,
      score: props.score,
      average: props.average,
    }


    this.buildUserMarks = this.buildUserMarks.bind(this)
    this.setCorrection = this.setCorrection.bind(this)
  }

  componentWillMount() {
    if (!this.state.userParts) return

    this.buildUserMarks()
    this.setCorrection()
  }

  buildUserMarks() {
    const userMarks = this.state.userParts.reduce((hash, part) => {
      hash[part.column] = {
        score: part.score,
        reviewerId: part.reviewer_id,
      }

      return hash
    }, {})

    this.setState({ userMarks })
  }

  setCorrection() {
    const correctionPart = this.state.userParts.filter(part => part.column === 'correction')[0]

    if (correctionPart) {
      this.setState({ correction: correctionPart.score })
    }
  }

  updateMarks({ user_parts, score }) {
    this.setState({ userParts: user_parts, score })

    this.triggerBoxGeometryChange()
  }

  updateScore({ score, average }) {
    this.setState({ score, average })

    this.triggerBoxGeometryChange()
  }

  triggerBoxGeometryChange() {
    setTimeout(() => $(document).trigger('boxGeometryChange'))
  }

  fetchMarksWhenOnline() {
    const $window = $(window)
    const onlineEvent = `online.testResultMark${this.props.userId}`

    $window.on(onlineEvent, () => {
      $window.off(onlineEvent)
      this.fetchMarks()
    })
  }

  fetchMarks() {
    if (this.isFetchingMarks) return

    this.isFetchingMarks = true

    fetch(this.props.apiUrl, { headers: { ...AUTH_HEADERS, ...JSON_HEADERS } })
      .then(res => res.json())
      .then(res => {
        this.isFetchingMarks = false

        this.updateMarks(res)
        this.buildUserMarks()
      })
  }

  getUserMarksPart(part) {
    return this.state.userMarks[part] || {}
  }

  get canDisplayScores() {
    return this.props.abilities.can_see_score &&
      this.state.score !== undefined &&
      this.state.score !== null
  }

  get scorableParts() {
    return this.props.scorableMarkParts
  }

  get bonuses() {
    return (this.state.userParts || [])
      .filter(part => part.score)
      .filter(part => this.props.bonusMarkParts.includes(part.column))
  }

  render() {
    return (
      <div className="taskResultMark">
        <div className="taskResultMark-parts">
          <HorizontalScrollableBox>
              { this.props.shouldScore &&
                this.scorableParts.map(part => (
                  <TaskResultMarkPart
                      part={ part }
                      score={ this.getUserMarksPart(part).score }
                      reviewerId={ this.getUserMarksPart(part).reviewerId }
                      apiUrl={ this.props.apiUrl }
                      onReceiveScore={ this.updateScore.bind(this) }
                      onSendError={ this.fetchMarksWhenOnline.bind(this) }
                      disabled={ this.props.lockedMarkParts.includes(part) }
                    />
                ))
              }
          </HorizontalScrollableBox>
        </div>

        { this.canDisplayScores &&
          <TaskResultMarkScores
              userIsStaff={ this.props.userIsStaff }
              bonuses={ this.bonuses }
              correction={ this.state.correction }
              average={ this.state.average }
              score={ this.state.score }
            />
        }
      </div>
    )
  }
}

module.exports = TaskResultMark
