import { Component } from 'preact'
import Popup from './userPopup'
import INoBounce from 'inobounce'

const query = document.querySelector.bind(document)

const ESC_KEY = 27
const USER_ICON_SELECTOR = '.header-user'
const DROPDOWN_SELECTOR = '.userPopup .dropdown'

const { isOnMobile } = window.application || {}

const toggleBodyMobileScroll = isOpen =>
  INoBounce[isOpen ? 'enable' : 'disable']()

const disableINoBounce = () => {
  try {
    INoBounce.disable()
  } catch (e) {
    setTimeout(disableINoBounce, 50)
  }
}

class PopupContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.togglePopup = this.togglePopup.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleUserIconClick = this.handleUserIconClick.bind(this)
    this.handleEscapeKeyPress = this.handleEscapeKeyPress.bind(this)

    disableINoBounce() // HACK: iNoBounce by default disables all gestures and scrolling
  }

  togglePopup(isOpen) {
    if (this.state.isOpen === isOpen) return

    if (isOnMobile) {
      toggleBodyMobileScroll(isOpen)
    }

    this.setState({ isOpen })
  }

  closePopup() {
    this.togglePopup(false)
  }

  handleUserIconClick(e) {
    e.preventDefault()
    this.togglePopup(!this.state.isOpen)
  }

  handleDocumentClick(e) {
    if (!this.popup.contains(e.target) && !this.userIcon.contains(e.target)) {
      this.closePopup()
    }
  }

  handleEscapeKeyPress(e) {
    if (e.keyCode === ESC_KEY) this.closePopup()
  }

  componentDidMount() {
    this.popup = query(DROPDOWN_SELECTOR)
    this.userIcon = query(USER_ICON_SELECTOR)

    this.userIcon.addEventListener('click', this.handleUserIconClick)
    document.addEventListener('click', this.handleDocumentClick)
    document.addEventListener('keydown', this.handleEscapeKeyPress)

    $(document).on('enableFocusMode', this.closePopup)
  }

  componentWillUnmount() {
    this.userIcon.removeEventListener('click', this.handleUserIconClick)
    document.removeEventListener('click', this.handleDocumentClick)
    document.removeEventListener('keydown', this.handleEscapeKeyPress)

    $(document).off('enableFocusMode', this.closePopup)
  }

  render() {
    const { isOpen } = this.state

    return (
      <Popup isOpen={ isOpen } closePopup={ this.closePopup } />
    )
  }
}

export default PopupContainer
