import { Caption, Heading2 } from '../textNodes'

const BELL = '🕭'

const SchoolRatingChartEmpty = () => (
  <div className="schoolRatingChartEmpty">
    <p className="schoolRatingChartEmpty-bell">{ BELL }</p>
    <Heading2 className="schoolRatingChartEmpty-title">
      { I18n.t('schoolRating.empty.title') }
    </Heading2>
    <Caption className="schoolRatingChartEmpty-caption">
      { I18n.t('schoolRating.empty.caption') }
    </Caption>
  </div>
)

export default SchoolRatingChartEmpty
