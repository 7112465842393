import { Component } from 'preact'
import cx from 'classnames'

import { Heading3 } from '../../textNodes'

export default class NewPaybarEnrollmentSwitch extends Component {
  render() {
    const isGrantEnrollment = this.props.enrollment === 'grant'
    const grantClasses = cx('newPaybarSwitch-selectorItem', { is__active: isGrantEnrollment })
    const defaultClasses = cx('newPaybarSwitch-selectorItem', { is__active: !isGrantEnrollment })

    return (
      <div className="newPaybarSwitch">
        <Heading3 className="newPaybarSwitch-heading">
          { I18n.t('newPaybar.school.enrollment') }
        </Heading3>
        <div className="newPaybarSwitch-selector">
          <span className={ defaultClasses } onClick={ this.props.onSetDefaultEnrollment }>
              { I18n.t('newPaybar.school.defaultEnrollment') }
          </span>
          <span className={ grantClasses } onClick={ this.props.onSetGrantEnrollment }>
              { I18n.t('newPaybar.school.grantEnrollment') }
          </span>
        </div>
      </div>
    )
  }
}
