export default function GiftButton(props) {
  return (
    <button className={ `giftButton is__${props.type}` }
      onClick={ props.onClick }
      data-goal={ props.goal }
    >
      <span className="giftButton-icon">🎁</span>
      <span className="giftButton-text">{ I18n.t('newPaybar.giftButtonText') }</span>
    </button>
  )
}
