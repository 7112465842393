import cx from 'classnames'

const LectureTag = ({ title, isActive, onClick, textColor }) => (
  <button
    style={ textColor ? `--lecturesTagTextColor: ${textColor}` : null }
    class={ cx('lecturesTags-tag', { is__active: isActive }) }
    type="button"
    onClick={ onClick }
  >
    { title }
  </button>
)

export default ({ tags, activeTagKey, onChooseTag }) => (
  <div className="lecturesTags">
    <div className="lecturesTags-in">
      { tags.map(tag => (
        <LectureTag
          title={ tag.title }
          textColor={ tag.textColor }
          isActive={ activeTagKey === tag.key }
          onClick={ () => onChooseTag(tag.key) }/>
      )) }
    </div>
  </div>
)
