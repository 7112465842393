import { Component } from 'preact'
import { Caption } from '../../textNodes'

import { birmanize } from 'lib/textHelper'
import { hasCourseStarted } from './courseHelpers'

export default class NewPaybarFooterCaptionCourse extends Component {
  get purchaseText() {
    const { type, companyPaymentType, product } = this.props
    const suffix = (type === 'company' && companyPaymentType === 'invoice') ? 'Invoice' : ''

    return I18n.t(`newPaybar.submitButtonCaption.${product}.${type}${suffix}`)
  }

  get isStudying() {
    const { product, subscription } = this.props

    return hasCourseStarted(product, subscription.startsAt)
  }

  get subscriptionText() {
    const startsAt = birmanize(this.props.subscription.startsAt, { format: 'fullMonthDateYearOnly' })

    if (this.isStudying) return I18n.t('newPaybar.course.subscriptionStudying')

    return I18n.t('newPaybar.course.subscription', { startsAt })
  }

  get captionText() {
    if (this.props.isPurchaseScreen) return this.purchaseText
    if (this.props.isSubscriptionScreen) return this.subscriptionText

    return null
  }

  render() {
    if (!this.captionText) return null

    return (
      <Caption html={ this.captionText } />
    )
  }
}
