import { Component } from 'preact'

import { Caption } from '../../textNodes'

export default class NewPaybarEmailFormCaptionBookshelf extends Component {
  get userEmailNotice() {
    if (!this.props.userEmailNotice) return `newPaybar.emailNotices.default.${this.props.type}`

    const suffix = this.props.cannotRead ? 'CannotRead' : ''

    return `newPaybar.emailNotices.${this.props.product}.${this.props.userEmailNotice}${suffix}`
  }

  render(props) {
    return (<Caption html={ I18n.t(this.userEmailNotice, { email: props.userEmail }) } />)
  }
}
