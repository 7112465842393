import { Component } from 'preact'

import { Caption } from '../../textNodes'
import NewPaybarDevicesIcons from './newPaybarDevicesIcons'

export default class NewPaybarRequirements extends Component {
  get captionText() {
    const version = this.props.hasMobileVersion ? 'hasMobileVersion' : 'default'
    return I18n.t(`newPaybar.requirements.${this.props.product}.${ version }`)
  }

  render() {
    return (
      <div className="newPaybarRequirements">
        <NewPaybarDevicesIcons
          className="newPaybarRequirements-icon"
          hasMobileVersion={ this.props.hasMobileVersion }
        />
        <div className="newPaybarRequirements-text">
          <Caption html={ this.captionText } />
        </div>
      </div>
    )
  }
}
