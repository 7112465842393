const CLASS_ACTUAL = 'is__actual'
const CLASS_SCROLLABLE_PARENT = 'horizontalScrollableModuleWrapper'
const CLASS_SCROLLABLE = 'is__horizontalScrollableTable'
const CLASS_STICKY_ELEMENT = 'contestsTable-caption'
const ELEMENT_TYPE = 'th'

{
  class HorizontalScrollableTable {
    constructor($table) {
      this.$table = $table
      this.$tableHolder = this.$table.parents(`.${CLASS_SCROLLABLE_PARENT}`)

      this.checkActualElement()
    }

    get isScrollable() {
      const holder = this.$tableHolder[0]

      return holder.scrollWidth > holder.offsetWidth
    }

    checkActualElement() {
      this.$actualElement = this.$table.find(`.${CLASS_ACTUAL}`)

      if (this.$actualElement && this.isScrollable) {
        this.$actualElement.parents('table').addClass(CLASS_SCROLLABLE)
        this.scrollToActualElement()
      }
    }

    scrollToActualElement() {
      const holder = this.$tableHolder
      const holderScrollWidth = holder[0].scrollWidth
      const actualEl = this.$actualElement
      const actualElWidth = actualEl.width()
      const prevElements = actualEl.closest(ELEMENT_TYPE).prevAll().get()
      const actualElLeftPosition = prevElements.reduce((sum, el) => sum + $(el).outerWidth(true), 0)
      const stuckEl = actualEl.closest(ELEMENT_TYPE).prevAll(`.${CLASS_STICKY_ELEMENT}`)
      const stuckElWidth = stuckEl ? stuckEl.width() : 0
      const scrollPosition = actualElLeftPosition - stuckElWidth - actualElWidth

      if (holderScrollWidth > (actualElLeftPosition + actualElWidth)) {
        holder.scrollLeft(scrollPosition)
      } else {
        holder.scrollLeft(0)
      }
    }
  }

  $('.js__horizontalScrollableTable').each(function() {
    new HorizontalScrollableTable($(this))
  })
}
