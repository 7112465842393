import roundPolyline from 'lib/roundPolyline'

const CORNER_RADIUS = 2
const CORNER_SHIFT = CORNER_RADIUS + 1.3

export const getWeekStartCoords = (chartParams, week) => {
  const { number, rank } = week
  const {
    colWidth,
    gapWidth,
    rowHeight,
    rowTopOffset,
  } = chartParams

  const isFirst = rank === 1
  const notFirstY = (rank - 1) * rowHeight

  const y = (isFirst ? 0 : notFirstY) + rowTopOffset
  const x = (number - 1) * (colWidth + gapWidth)

  return { x, y }
}

const getLine = (from, to) => {
  return `M${from.x},${from.y} L${to.x},${to.y}`
}

const getConnectorPath = (from, to) => {
  const noAngle = from.y === to.y

  // TODO: Учесть, что линия может быть прямой и при слишком большой разнице по горизонтали
  const smallAngle = Math.abs(from.y - to.y) < 15
  if (noAngle || smallAngle) return getLine(from, to)

  const isUp = from.y > to.y

  const startCoords = [from.x, from.y]
  const endCoords = [to.x, to.y]

  const pointsDown = [
    startCoords,
    [from.x + CORNER_SHIFT, from.y],
    [from.x + CORNER_SHIFT * 2, from.y + CORNER_SHIFT],
    [to.x - CORNER_SHIFT * 2, to.y - CORNER_SHIFT],
    [to.x - CORNER_SHIFT, to.y],
    endCoords,
  ]

  const pointsUp = [
    startCoords,
    [from.x + CORNER_SHIFT, from.y],
    [from.x + CORNER_SHIFT * 2, from.y - CORNER_SHIFT],
    [to.x - CORNER_SHIFT * 2, to.y + CORNER_SHIFT],
    [to.x - CORNER_SHIFT, to.y],
    endCoords,
  ]

  let result = ''
  const points = isUp ? pointsUp : pointsDown

  points.forEach(point => result += point.join(',') + ' ')

  return roundPolyline(result, CORNER_RADIUS)
}

export const getStepPath = (chartParams, coords, prevCoords = null, isExtended) => {
  const { colWidth } = chartParams
  const { x, y } = coords

  const step = `M${x},${y} h${isExtended ? colWidth * 2 : colWidth}`

  if (prevCoords) {
    const connector = getConnectorPath({ ...prevCoords, x: prevCoords.x + colWidth }, coords)

    return `${connector} ${step}`
  }

  return step
}

export const getFullWeeksPath = (chartParams, stepsCoords) => {
  let result = ''
  let prevCoords = null

  stepsCoords.forEach(coords => {
    result += getStepPath(chartParams, coords, prevCoords)
    prevCoords = coords
  })

  return result
}
