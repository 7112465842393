import CollectureSubscriptionPricing from './collectureSubscriptionPricing'
import CollectureUpgradePricing from './collectureUpgradePricing'

export const CollecturePricingFullView = (props) => {
  return (
    <div className="module">
      <CollectureSubscriptionPricing { ...props } />

      { props.isUpgrade &&
        <CollectureUpgradePricing { ...props } />
      }
    </div>
  )
}

export const CollecturePricingCompactView = (props) => {
  return (
    <div>
      { !props.isUpgrade &&
        <CollectureSubscriptionPricing { ...props } />
      }

      { props.isUpgrade &&
        <CollectureUpgradePricing { ...props } />
      }
    </div>
  )
}
