import { Component } from 'preact'

export default class NewPaybarSchoolShelf extends Component {
  get image() {
    const isPrep = this.props.product === 'prep'

    return `/assets/images/school/paybar-${isPrep ? 'prep' : this.props.schoolKey}.png`
  }

  get maskStyles() {
    return {
      'mask-image': `url(${this.image})`,
      '-webkit-mask-image': `url(${this.image})`,
    }
  }

  render() {
    return (
      <div className={ `newPaybarSchoolShelf is__${this.props.schoolKey || 'prep'}` }>
        <div className="newPaybarSchoolShelf-inner" style={ this.maskStyles }>
          <img src={ this.image } />
        </div>
      </div>
    )
  }
}
