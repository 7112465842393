import { Component } from 'preact'
import cx from 'classnames'

import { Caption } from '../../textNodes'
import { SchoolDevicesIcon } from '../../icons'

export default class NewPaybarSchoolRequirements extends Component {
  render() {
    const classNames = cx('newPaybarRequirements', this.props.className)

    return (
      <div className={ classNames }>
        <div className="newPaybarRequirements-icon">
          <SchoolDevicesIcon />
        </div>
        <div className="newPaybarRequirements-text">
          <Caption html={ I18n.t(`newPaybar.requirements.${ this.props.product }`) } />
        </div>
      </div>
    )
  }
}
