import { Component } from 'preact'
import { Caption } from '../textNodes'
import { Image } from '../image'
import cx from 'classnames'

import { capitalize } from 'lib/textHelper'
import { formatScore } from 'lib/school/classroom/scoreHelper'
import { THINSP, ARROW_UP, ARROW_DOWN, NBSP } from 'lib/constants'
import { getLastWeek, getPrevWeek, isStatusDisabled, isStatusPaused } from '../schoolRating/helpers'
import { getCountryFlagUrl } from '../schoolRatings/helpers'

import SchoolFacesTags from './schoolFacesTags'
import SchoolFacesDiploma from './schoolFacesDiploma'

const PHOTO_SIZE = [1000, 588]

const formatScoreDiff = score => {
  const sign = score < 0 ? '−' : '+'

  return `${sign}${formatScore(Math.abs(score))}`
}

export default class SchoolFacesStudent extends Component {
  get about() {
    const { job, organization } = this.props

    const result = [job, organization].filter(item => !!item).join(', ')

    return capitalize(result)
  }

  get prevWeek() {
    return getPrevWeek(this.props.weeks)
  }

  get lastWeek() {
    return getLastWeek(this.props.weeks)
  }

  get score() {
    if (!this.lastWeek) return 0

    return formatScore(this.lastWeek.score)
  }

  get scoreDiff() {
    if (this.props.isCertificationStarted) return this.overallScoreDiff

    if (!this.lastWeek || !this.prevWeek) return 0

    return this.lastWeek.score - this.prevWeek.score
  }

  get overallScoreDiff() {
    return this.lastWeek.score - this.props.weeks[0].score
  }

  get rankDiff() {
    if (this.props.isCertificationStarted) return this.overallRankDiff

    return (this.lastWeek || {}).diff
  }

  get overallRankDiff() {
    return this.props.weeks[0].rank - this.lastWeek.rank
  }

  get decoratedRankDiff() {
    const { rankDiff } = this

    if (!rankDiff) return null

    return (rankDiff > 0 ? ARROW_UP : ARROW_DOWN) + Math.abs(rankDiff)
  }

  get bureausphereLink() {
    return this.props.englishName ? `/burosfera/${ this.props.englishName }` : null
  }

  render() {
    const { name, englishName, enrollmentStatus, photo, city, country, hasDiploma } = this.props

    const isDisabled = isStatusDisabled(enrollmentStatus)
    const isPaused = isStatusPaused(enrollmentStatus)

    const hasScore = this.score !== 0
    const hasScoreDiff = this.scoreDiff !== 0
    const rankDirection = this.rankDiff > 0 ? 'inc' : 'dec'

    const classNames = cx(
      'schoolFacesStudent',
      `is__rank-${ rankDirection }`,
      {
        is__paused: isPaused,
        is__disabled: isDisabled,
        is__obfuscated: !this.props.englishName,
      }
    )
    const nameHtml = isDisabled ? `<s>${name}</s>` : name
    const countryFlagUrl = getCountryFlagUrl(country, city)

    return (
      <div className={ classNames }>
        <a href={ this.bureausphereLink } target="_blank" className="schoolFacesStudent-top no-u">
          <Image className="schoolFacesStudent-img" src={ photo } size={ PHOTO_SIZE } bordered/>
          <Caption className="schoolFacesStudent-name">
            <span className="pseudoLink" dangerouslySetInnerHTML={{ __html: nameHtml }}/>

            { !isDisabled && !isPaused && !!this.rankDiff &&
              <small className="schoolFacesStudent-rankDiff">
                { NBSP + this.decoratedRankDiff }
              </small>
            }
          </Caption>
        </a>

        <div className="schoolFacesStudent-bottom">
          <Caption className="schoolFacesStudent-about">
            <span dangerouslySetInnerHTML={{ __html: this.about }}/>

            { (!!this.about && !!city) && ', '}
            { !!city &&
              <span className="nowrap">
                { city }
                { countryFlagUrl && <img className="schoolRatingsFlag" src={ countryFlagUrl } alt={ country } title={ country }/> }
              </span>
            }
          </Caption>

          <Caption>
            { hasScore &&
              <span className="schoolFacesStudent-score">
                { this.score }

                { hasScoreDiff &&
                  <small className="schoolFacesStudent-scoreDiff">
                    { THINSP + formatScoreDiff(this.scoreDiff) }
                  </small>
                }
              </span>
            }

            <SchoolFacesTags {...this.props }/>

            { hasDiploma && !isDisabled && !isPaused && <SchoolFacesDiploma {...this.props} /> }
          </Caption>
        </div>
      </div>
    )
  }
}
