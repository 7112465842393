import { createStore as createReduxStore } from 'redux'

import schoolRatingReducer from './schoolRatingReducer'

const DEFAULT_STATE = {
  weeksCount: 16,
  activeStudentUid: null,
  pinnedStudentUid: null,
  chartParams: null,
  students: [],
}

export function createStore(props) {
  const { isProduction } = window.application
  const devtoolsExtension = isProduction ? undefined : window.__REDUX_DEVTOOLS_EXTENSION__

  return createReduxStore(
    schoolRatingReducer,
    {
      schoolRating: {
        ...DEFAULT_STATE,
        ...props,
      },
    },
    devtoolsExtension && devtoolsExtension(),
  )
}
