const ANSWERS_PREFIX = 'schoolTest/answer'

class LocalState {
  addAnswer(answerId) {
    try {
      localStorage.setItem([ANSWERS_PREFIX, answerId].join('.'), true)
    } catch (e) {
      console.warn('Failed to add answer: ', e)
    }
  }

  removeAnswer(answerId) {
    try {
      localStorage.removeItem([ANSWERS_PREFIX, answerId].join('.'))
    } catch (e) {
      console.warn('Failed to remove answer: ', e)
    }
  }

  getAnswers() {
    const answersMatch = new RegExp('^' + ANSWERS_PREFIX + '.')

    return Object
      .keys(localStorage || {})
      .filter(key => answersMatch.test(key))
      .map(key => key.split('.').slice(1).join('.'))
  }
}

module.exports = LocalState
