import { connect } from 'preact-redux'

import { Caption } from '../textNodes'

const SovietCompilationAuthor = (props) => {
  if (!props.authorName) return

  return (
    <Caption className={`compilationAuthor ${props.className}`}>
      <span className="js__compilationAuthor">
        { props.authorGender === 'female' ? 'Собрала ' : 'Собрал ' }
        <a href={`/soviet/selected/${props.authorEnglishName}/`}>
          <nobr>{ props.authorName }</nobr>
        </a>
      </span>
      { props.viewsCount &&
        <span className="compilationViewsCount">👁 { props.viewsCount }</span>
      }
    </Caption>
  )
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    authorName: sovietCompilation.userName,
    authorEnglishName: sovietCompilation.userEnglishName,
    authorGender: sovietCompilation.userGender,
    viewsCount: sovietCompilation.viewsCount,
  }
}

export default connect(mapStateToProps)(SovietCompilationAuthor)

