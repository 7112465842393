import { Image } from '../image'

const CollectureSpacerCover = (props) => {
  const COVER_SIZES = {
    desktop: [255, 109],
    laptop: [128, 109],
    mobile: [335, 132],
  }
  const DEVICES = ['desktop', 'laptop', 'mobile']
  const IMG_DIR = '/assets/images/spacers/collectures/collectureSpacer/'

  return (
    <a
      href={ props.href }
      className="collectureSpacer-cover is__fitToContent doubleHover no-u" >
        { DEVICES.map(device => (
            <Image
              className={ `device device__${device}` }
              src={ `${IMG_DIR}${props.id}-${device}.png` }
              size={ COVER_SIZES[device] }
            />
        )) }
    </a>
  )
}

export default CollectureSpacerCover
