import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import expandScreenToFlags from './expandScreenToFlags'
import { setFormHasContent } from './newPaybarActions'

const MIN_FORM_HEIGHT = 441
const MIN_FORM_MARGIN_TOP = 18
const TABS_HEIGHT = 36

class NewPaybarPersonalFormWrapper extends Component {
  constructor(props) {
    super(props)

    this.$document = $(document)
    this.minFormHeight = MIN_FORM_HEIGHT

    this.state = {
      isWrapperScrollable: false,
      formTopPosition: 'auto',
    }

    this.unsetAnimated = this.unsetAnimated.bind(this)
  }

  componentDidMount() {
    this.$document.on('appResize', this.handleUpdate.bind(this))

    this.props.setFormHasContent(this.formHeight > TABS_HEIGHT)
  }

  componentWillUpdate() {
    this.handleUpdate()
  }

  componentDidUpdate() {
    if (this.props.isPurchaseScreen) this.setFormPosition()

    this.props.setFormHasContent(this.formHeight > TABS_HEIGHT)
  }

  unsetAnimated() {
    if (this.formWrapper) this.formWrapper.removeEventListener('animationend', this.unsetAnimated)
  }

  setAnimated() {
    this.formWrapper.addEventListener('animationend', this.unsetAnimated)
  }

  handleUpdate() {
    if (!this.props.isSuccessScreen) {
      this.setFormPosition()
      this.setMinFormHeight()
    }
  }

  resetForm() {
    if (this.state.isWrapperScrollable || this.state.formTopPosition !== 'auto') {
      this.setState({
        isWrapperScrollable: false,
        formTopPosition: 'auto',
      })
    }
  }

  setFormScrollable() {
    const formTopPosition = this.formWrapperHeight < this.minFormHeight ? `${ MIN_FORM_MARGIN_TOP }px` : `${this.formWrapperHeight - this.minFormHeight}px`

    if (formTopPosition !== this.state.formTopPosition) {
      this.setState({
        isWrapperScrollable: this.isFormWrapperScrollable,
        formTopPosition: formTopPosition,
      })
    }
  }

  setFormPosition() {
    if (this.isFormWrapperScrollable) {
      this.setFormScrollable()
    } else {
      this.resetForm()
    }
  }

  setMinFormHeight() {
    this.minFormHeight = this.formHeight > TABS_HEIGHT ? this.formHeight : MIN_FORM_HEIGHT
  }

  get isFormWrapperScrollable() {
    const isFormEmpty = this.formWrapperHeight <= TABS_HEIGHT

    return isFormEmpty ? false : this.formHeight > this.formWrapperHeight
  }

  get formWrapperHeight() {
    if (!this.formWrapper) return 0

    return Math.floor(this.formWrapper.getBoundingClientRect().height)
  }

  get formHeight() {
    if (!this.form) return 0

    return Math.floor(this.form.getBoundingClientRect().height)
  }

  render(props) {
    const wrapperClassNames = cx('newPaybarPersonalForm-wrapper', {
      is__scrollable: this.state.isWrapperScrollable,
      has__content: this.props.hasFormContent,
    })

    return (
      <div className={ wrapperClassNames } ref={ el => this.formWrapper = el }>
        <div className="newPaybarPersonalForm-wrapperIn" ref={ props.scrollableRef }>
          <div
            className="newPaybarPersonalForm-content"
            ref={ el => this.form = el }
            style={ { marginTop: this.state.formTopPosition } }>
            <div className="newPaybarPersonalForm-contentIn" ref={ props.formRef }>
              { props.children }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    hasFormContent: newPaybar.hasFormContent,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

const mapDispatchToProps = { setFormHasContent }

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarPersonalFormWrapper)
