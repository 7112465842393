import { Component, cloneElement } from 'preact'

export default class SizeContainer extends Component {
  constructor() {
    super()

    this.state = {
      isInitiated: false,
    }

    this.resize = this.resize.bind(this)
  }

  componentDidMount() {
    this.parentNode = this.base.parentNode

    $(document).on('appResize.container', this.resize)
    this.resize()
  }

  componentWillUnmount() {
    $(document).off('appResize.container', this.resize)
  }

  resize() {
    const clientRect = this.base.parentNode.getBoundingClientRect()

    this.setState({
      isInitiated: true,
      width: clientRect.width,
      height: clientRect.height,
    })
  }

  render({ children }) {
    if (!this.state.isInitiated) {
      return <div />
    }

    return cloneElement(children[0], this.state)
  }
}
