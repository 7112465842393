import { Cols } from '../cols'
import { TextNode } from '../textNodes'

import cx from 'classnames'

const MIN_WORDS = 2
const isTitleTooShort = (title) => title && title.split(/\s/).length <= MIN_WORDS

export default ({ previous, next }) => {
  const previousClasses = cx('assetsNavigation-previous', { no__link: !previous })
  const nextClasses = cx(
    'assetsNavigation-next',
    {
      has__shortTitle: isTitleTooShort((next || {}).title),
      no__link: !next
    },
  )

  return (
    <Cols className="assetsNavigation" isRibbed>
      <div className={ previousClasses }>
        { previous &&
          <TextNode>
            { previous.isDisabled &&
                <span
                  className="disabled"
                  dangerouslySetInnerHTML={{ __html: `<span class="assetsNavigation-pointer">❰ </span>${ previous.title }` }}
                />
            }

            { !previous.isDisabled &&
                <a
                  href={ previous.href }
                  className="no-u"
                  dangerouslySetInnerHTML={{ __html: `<span class="assetsNavigation-pointer">❰ </span>${ previous.title }` }}
                />
            }
          </TextNode>
        }
      </div>

      <div className={ nextClasses }>
        { next &&
          <TextNode>
            { next.isDisabled &&
                <span
                  className="disabled"
                  dangerouslySetInnerHTML={{ __html: `${ next.title }<span class="assetsNavigation-pointer"> ❱</span>` }}
                />
            }

            { !next.isDisabled &&
                <a
                  href={ next.href }
                  className="no-u"
                  dangerouslySetInnerHTML={{ __html: `${ next.title }<span class="assetsNavigation-pointer"> ❱</span>` }}
                />
            }
          </TextNode>
        }
      </div>
    </Cols>
  )
}
