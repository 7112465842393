import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { NewPaybarCardIframeCompliance, NewPaybarCardIframeLogo } from './newPaybarIframeParts'
import { setCardFormValidity, setPaymentFailed } from './newPaybarActions'
import { Caption } from '../textNodes'

import updateUserPaymentMethod from 'lib/products/updateUserPaymentMethod'

const parseEvent = (event) => {
  try {
    return (JSON.parse(event.data) || {})
  } catch (e) {
    return {}
  }
}

class NewPaybarCardIframe extends Component {
  constructor(props) {
    super(props)

    this.handleIframeMessage = this.handleIframeMessage.bind(this)
    this.handleIframeLoad = this.handleIframeLoad.bind(this)
    this.submit = this.submit.bind(this)
    this.updateUserPaymentMethod = this.updateUserPaymentMethod.bind(this)
  }

  get alreadyHasCard() {
    return !!this.props.userCardNumber
  }

  get isChangingCard() {
    return this.props.currentPaymentMethod === 'newCard'
  }

  get canSkipLoading() {
    return this.alreadyHasCard && !this.props.currentPaymentMethod
  }

  get isUnnecessary() {
    return this.alreadyHasCard && !this.isChangingCard
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIframeMessage)

    this.mountOnApplication()
    this.props.setCardFormValidity({ isValid: false })
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessage)
  }

  componentDidUpdate() {
    this.mountOnApplication()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.src !== nextProps.src ||
      this.state.isIframeLoaded !== nextState.isIframeLoaded ||
      this.props.currentPaymentMethod !== nextProps.currentPaymentMethod ||
      this.props.type !== nextProps.type
  }

  mountOnApplication() {
    window.application.newPaybar.card = this
  }

  handleIframeMessage(event) {
    const payload = parseEvent(event)
    const messageType = payload.event

    switch (messageType) {
      case 'setCardFormState':
        this.props.setCardFormValidity(payload)
        break

      case 'setCardAccepted':
        this.submitHandle.resolve()
        break

      case 'setCardRejected':
        this.submitHandle.reject()
        break

      default:
        break
    }
  }

  handleIframeLoad() {
    this.setState({ isIframeLoaded: true })
  }

  submit() {
    this.submitHandle = {}

    if (this.isUnnecessary || !this.iframe) return Promise.resolve()

    return new Promise((resolve, reject) => {
      this.submitHandle.resolve = () => this.updateUserPaymentMethod().then(resolve).catch(reject)
      this.submitHandle.reject = reject

      this.iframe
        .contentWindow
        .postMessage(JSON.stringify({ event: 'submit' }), '*')
    })
  }

  updateUserPaymentMethod() {
    if (!this.isChangingCard) return Promise.resolve()

    return updateUserPaymentMethod({
      paymentMethod: 'payture::default',
      token: this.props.accessToken || '',
    })
  }

  render() {
    const className = cx('newPaybarCardIframe', { has__fullyLoadedIframe: this.state.isIframeLoaded })

    const wrapClassName = cx('newPaybarCardIframeWrap', { is__hidden: this.isUnnecessary })

    return (
      <div className={ wrapClassName} >
        <div className={ className }>

          <div className="newPaybarCardIframe-iframeWrap">
           { !this.canSkipLoading &&
              <iframe
                ref={ el => this.iframe = el }
                src={ this.props.src }
                className="newPaybarCardIframe-iframe"
                frameborder="0"
                scrolling="no"
                onLoad={ this.handleIframeLoad }>
              </iframe>
            }
          </div>

          <div className="newPaybarCardIframe-help">
            <Caption
              className="newPaybarCardIframe-cvvHelp"
              html={ I18n.t('newPaybar.cardIframe.cvvHelp') } />
            <Caption html={ I18n.t('newPaybar.cardIframe.paytureHelp') } />
          </div>

          <NewPaybarCardIframeCompliance />
          <NewPaybarCardIframeLogo />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = { setCardFormValidity, setPaymentFailed }

export default connect(null, mapDispatchToProps)(NewPaybarCardIframe)
