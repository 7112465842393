import LectureNavigation from './lectureNavigation'

$('.js__lectureNavigation').each((index, el) => {
  const $root = $(el)
  const $next = $root.next()
  if ($next.is('.webpageLikes')) $next.after($root)

  const options = JSON.parse($root.attr('data-options') || null)

  $root.empty().reactify(LectureNavigation, options)
  $root.children().unwrap()
})
