{
  const CLASS_ACTIVE = 'is__active'

  class Selector {
    constructor($el) {
      this.$el = $el
      this.selectorGroup = this.$el.data('selector-group')
      this.$selectorControls = this.$el.find('.js__selectorControl')
      this.$selectorItems = $('.js__selectorItem').filter(`[data-selector-group=${this.selectorGroup}]`)

      this.selectorControlsIds = this.$selectorControls.map((_, control) => $(control).data('selector-id')).get()
      this.selectorItemsIds = this.$selectorItems.map((_, item) => $(item).data('selector-id')).get()

      this.currentId = this.selectorControlsIds[0]
      this.setActivePair()

      this.bindEvents()
    }
    
    bindEvents() {
      this.$selectorControls.click((e) => {
        this.currentId = $(e.target).data('selector-id')

        this.setActivePair()
        e.preventDefault()
      })

      this.$selectorItems.click((e) => {
        let nextIdIndex = (this.selectorItemsIds.indexOf(this.currentId) + 1) % this.selectorItemsIds.length

        this.currentId = this.selectorItemsIds[nextIdIndex]

        this.setActivePair()
        e.preventDefault()
      })
    }

    setActivePair() {
      this.$selectorControls
        .add(this.$selectorItems)
        .removeClass(CLASS_ACTIVE)
        .filter(`[data-selector-id=${this.currentId}]`)
        .addClass(CLASS_ACTIVE)
    }
  }

  $('.js__selector').each(function() {
    new Selector($(this))
  })
}
