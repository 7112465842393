import { Component } from 'preact'
import { Caption } from '../textNodes'

import cx from 'classnames'

class DiscountBannerSticker extends Component {
  get discountAmount() {
    return parseInt(this.props.discount, 10)
  }

  get hasPercentageDiscount() {
    return !Number.isNaN(this.discountAmount) && this.discountAmount > 0
  }

  get hasExtraCharge() {
    return !Number.isNaN(this.discountAmount) && this.discountAmount < 0
  }

  get discountIcon() {
    const sign = this.props.hasBlackTheme ? '−' : ''
    const textWithDiscountAmount = `${ sign }${ this.props.discount }<span>%</span>`

    return this.hasPercentageDiscount ? textWithDiscountAmount : '%'
  }

  get stickerIcon() {
    return this.props.icon || this.discountIcon
  }

  get captionText() {
    if (this.props.caption) return this.props.caption

    return this.hasExtraCharge ? I18n.t('discountBanner.extraCharge') : I18n.t('discountBanner.discount')
  }

  render() {
    const stickerClasses = cx(
      'spacerSticker-wrapper',
      { has__percentageDiscount: this.hasPercentageDiscount && !this.props.icon }
    )

    return (
      <div className={ stickerClasses }>
        <div className="spacerSticker">
          <div
            className="percentage"
            dangerouslySetInnerHTML={{ __html: this.stickerIcon }} />
          <Caption>{ this.captionText }</Caption>
        </div>
      </div>
    )
  }
}

export default DiscountBannerSticker
