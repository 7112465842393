import { Component } from 'preact'

import { FormInput } from '../form'
import { Cols } from '../cols'

class CommentFormAuthorField extends Component {
  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
  }

  handleInput({ value }) {
    this.props.onEdit({ name: value })
  }

  render(props) {
    const { id, name, value, placeholder, required, label } = props
    const type = props.type || 'text'
    const inputId = `${id || 'new'}-${name}`

    return (
      <Cols className="formRow is__transposeOnMobile" divisionProportions="3:9">
        <div className={ `formLabel commentForm-${name}Label` }>
          <label htmlFor={ inputId }>{ label }</label>
        </div>
        <div>
          <FormInput
            className="is__fullWidth"
            id={ inputId }
            name={name}
            type={type}
            initialValue={ value }
            placeholder={ placeholder }
            onInput={ this.handleInput }
            onValidityChange={ props.onValidityChange }
            required={required}
          />
        </div>
      </Cols>
    )
  }
}

export default CommentFormAuthorField
