import { Caption } from '../textNodes'
import BookSpacerLink from './bookSpacerLink'
import isBookshelfAll from 'lib/isBookshelfAll'

// FIXME: вынести текст в I18n
export const MobileText = (props) => {
  const {
    productId,
    title,
    titleMobile,
    href,
    linkText,
    isProductBookshelf,
    hasSpecialOffer,
    onClick,
    prices,
  } = props
  const isBookshelfWithSpecialOffer = isProductBookshelf && hasSpecialOffer
  const { renewalPeriod } = prices
  const shouldDisplayOneTimePrice = renewalPeriod >= 12
  const isBookshelfAllSpacer = isBookshelfAll(productId)

  const defaultUnits = I18n.t(`subscription.defaultUnits_${prices.renewalPeriod}`)
  const subscriptionUnits = I18n.t(`subscription.subscriptionUnits_${prices.renewalPeriod}`)

  return (
    <div className="bookSpacer-text device mobile">
      <Caption>
        <b>{ titleMobile || title }</b><br />
        { isBookshelfWithSpecialOffer && !isBookshelfAllSpacer &&
          <span className="bookSpacer-specialOfferHighlight">
            {`Переключение ${prices.specialOffer.oneTime} ${defaultUnits} `}
          </span>
        }

        { isBookshelfAllSpacer && hasSpecialOffer &&
          <span>
            <s className="is__crossedOut">{ prices.oneTime }</s>
            <span className="bookSpacer-specialOfferHighlight">
            {` ${prices.specialOffer.oneTime}`}
            </span>
          </span>
        }

        { !hasSpecialOffer &&
          <span>{ shouldDisplayOneTimePrice ? prices.oneTime : prices.default }</span>
        }

        {`, затем ${prices.subscription} ${subscriptionUnits}`}
      </Caption>
      <BookSpacerLink
        isSmall
        href={ href }
        text={ linkText }
        onClick={ onClick }
      />
    </div>
  )
}

export const MobilePreorderText = (props) => {
  const {
    title,
    description,
    href,
    linkText,
    onClick,
  } = props

  return (
    <div className="bookSpacer-text device mobile">
      <Caption>
        <b>{ title }</b><br />
        { description }
      </Caption>
      <BookSpacerLink
        isSmall
        href={ href }
        text={ linkText }
        onClick={ onClick }
      />
    </div>
  )
}
