module.exports = {
  theme1: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/01.jpg',
    previewUrl: '/assets/images/compilations/previews/01.png',
  },
  theme2: {
    layout: 'top',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/02.jpg',
    previewUrl: '/assets/images/compilations/previews/02.png',
  },
  theme3: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/03.jpg',
    previewUrl: '/assets/images/compilations/previews/03.png',
  },
  theme4: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/04.jpg',
    previewUrl: '/assets/images/compilations/previews/04.png',
  },
  theme5: {
    layout: 'bottom',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/05.jpg',
    previewUrl: '/assets/images/compilations/previews/05.png',
  },
  theme6: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/06.jpg',
    previewUrl: '/assets/images/compilations/previews/06.png',
  },
  theme7: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/07.jpg',
    previewUrl: '/assets/images/compilations/previews/07.png',
  },
  theme8: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/08.png',
    previewUrl: '/assets/images/compilations/previews/08.png',
  },
  theme9: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/09.png',
    previewUrl: '/assets/images/compilations/previews/09.png',
  },
  theme10: {
    layout: 'bottom',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/10.png',
    previewUrl: '/assets/images/compilations/previews/10.png',
  },
  theme11: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/11.png',
    previewUrl: '/assets/images/compilations/previews/11.png',
  },
  theme12: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/12.png',
    previewUrl: '/assets/images/compilations/previews/12.png',
  },
  theme13: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/13.png',
    previewUrl: '/assets/images/compilations/previews/13.png',
  },
  theme14: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/14.png',
    previewUrl: '/assets/images/compilations/previews/14.png',
  },
  theme15: {
    layout: 'top',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/15.png',
    previewUrl: '/assets/images/compilations/previews/15.png',
  },
  theme16: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/16.png',
    previewUrl: '/assets/images/compilations/previews/16.png',
  },
  theme17: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/17.jpg',
    previewUrl: '/assets/images/compilations/previews/17.png',
  },
  theme18: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/18.jpg',
    previewUrl: '/assets/images/compilations/previews/18.png',
  },
  theme19: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/19.jpg',
    previewUrl: '/assets/images/compilations/previews/19.png',
  },
  theme20: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/20.jpg',
    previewUrl: '/assets/images/compilations/previews/20.png',
  },
  theme21: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/21.jpg',
    previewUrl: '/assets/images/compilations/previews/21.png',
  },
  theme22: {
    layout: 'top',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/22.jpg',
    previewUrl: '/assets/images/compilations/previews/22.png',
  },
  theme23: {
    layout: 'bottom',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/23.jpg',
    previewUrl: '/assets/images/compilations/previews/23.png',
  },
  theme24: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/24.jpg',
    previewUrl: '/assets/images/compilations/previews/24.png',
  },
  theme25: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/25.png',
    previewUrl: '/assets/images/compilations/previews/25.png',
  },
  theme26: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/26.png',
    previewUrl: '/assets/images/compilations/previews/26.png',
  },
  theme27: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/27.png',
    previewUrl: '/assets/images/compilations/previews/27.png',
  },
  theme28: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/28.png',
    previewUrl: '/assets/images/compilations/previews/28.png',
  },
  theme29: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/29.png',
    previewUrl: '/assets/images/compilations/previews/29.png',
  },
  theme30: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/30.png',
    previewUrl: '/assets/images/compilations/previews/30.png',
  },
  theme31: {
    layout: 'center',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/31.png',
    previewUrl: '/assets/images/compilations/previews/31.png',
  },
  theme32: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/32.png',
    previewUrl: '/assets/images/compilations/previews/32.png',
  },
  theme33: {
    layout: 'center',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/33.png',
    previewUrl: '/assets/images/compilations/previews/33.png',
  },
  theme34: {
    layout: 'center',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/34.jpg',
    previewUrl: '/assets/images/compilations/previews/34.png',
  },
  theme35: {
    layout: 'left',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/35.jpg',
    previewUrl: '/assets/images/compilations/previews/35.png',
  },
  theme36: {
    layout: 'left',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/36.jpg',
    previewUrl: '/assets/images/compilations/previews/36.png',
  },
  theme37: {
    layout: 'bottom',
    textColor: 'black',
    coverUrl: '/assets/images/compilations/covers/37.jpg',
    previewUrl: '/assets/images/compilations/previews/37.png',
  },
  theme38: {
    layout: 'top',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/38.jpg',
    previewUrl: '/assets/images/compilations/previews/38.png',
  },
  theme39: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/39.jpg',
    previewUrl: '/assets/images/compilations/previews/39.png',
  },
  theme40: {
    layout: 'center',
    textColor: 'white',
    coverUrl: '/assets/images/compilations/covers/40.jpg',
    previewUrl: '/assets/images/compilations/previews/40.png',
  },
}
