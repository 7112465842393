'use strict';

const ANIMATION_DATA_PREFIX = 'data-animation-';
const INDEXED_PROPERTY = new RegExp('^([a-z0-9]+)-');

const selectAnimationAttributes = (attributes) => {
  let animationAttributes = {};

  $.each(attributes, (_, attr) => {
    if (attr.name.indexOf(ANIMATION_DATA_PREFIX) >= 0) {
      const attrKey = attr.name.replace(ANIMATION_DATA_PREFIX, '');
      animationAttributes[attrKey] = attr.value;
    }
  });

  return animationAttributes;
};

const selectAnimationAttributesById = (attributes, animationId) => {
  let animationAttributes = {};

  for (let key in attributes) {
    if (key.indexOf(animationId) === 0) {
      const attrKey = key.replace(INDEXED_PROPERTY, '');
      animationAttributes[attrKey] = attributes[key];
    }
  }

  return animationAttributes;
};

module.exports = function($) {
  $.fn.animationsObject = function() {
    const animationAttributes = selectAnimationAttributes(this[0].attributes);
    let multipleAnimationObjects = [];
    let extractedAnimationIds = [];

    for (let key in animationAttributes) {
      if (!animationAttributes.hasOwnProperty(key)) continue;
      const match = key.match(INDEXED_PROPERTY) || [];
      const animationId = match[1];

      if (!animationId) return animationAttributes;
      if (extractedAnimationIds.indexOf(animationId) >= 0) continue;

      const animationAttrs = selectAnimationAttributesById(animationAttributes, animationId);
      multipleAnimationObjects.push(animationAttrs);
      extractedAnimationIds.push(animationId);
    }

    return multipleAnimationObjects;
  };
};
