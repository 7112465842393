import { Caption } from '../textNodes'
import { formatPrice } from 'lib/textHelper'

const LectureSpacerInlinePricing = ({ prices, className }) => {
  const currency = I18n.t('subscription.unitsOneTime')
  const subscriptionUnits = I18n.t(`subscription.subscriptionUnits_${prices.renewalPeriod}`)
  const subscriptionPeriod = I18n.t(`subscription.prices.defaultLabel_${prices.subscriptionPeriod}`)
  const { oneTime, previousOneTime, isApplicableForDiscount } = prices
  const oneTimePrice = formatPrice(oneTime, { currency })
  const discountAvailable = (isApplicableForDiscount && previousOneTime !== oneTime)

  return (
    <Caption className={`lectureSpacer-pricing ${className}`}>
      {
        discountAvailable ?
          <span>{ subscriptionPeriod } <s>{ formatPrice(prices.previousOneTime) }</s> <span className="lectureSpacer-pricingDiscounted">{ oneTimePrice }</span></span> :
          <span>{ `${subscriptionPeriod} ${oneTimePrice}` }</span>
      }


      <span className="lectureSpacer-pricingNextPeriod">{`, затем ${formatPrice(prices.subscription)} ${subscriptionUnits}`}</span>
    </Caption>
  )
}

export default LectureSpacerInlinePricing
