import { Component } from 'preact'
import { connect } from 'preact-redux'

class NewPaybarNotificationDisclaimer extends Component {
  get notificationText() {
    return this.props.disclaimer[this.props.type]
  }

  render() {
    return (
      <div className="newPaybarNotificationDisclaimer">
        <span
          className="newPaybarNotice-message"
          dangerouslySetInnerHTML={{ __html: this.notificationText }} />
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    disclaimer: newPaybar.disclaimer,
    type: newPaybar.type,
  }
}

export default connect(mapStateToProps)(NewPaybarNotificationDisclaimer)
