import { addTag } from './helpers'

export const handleEnter = allTags => ([key, state]) => {
  if ((key === 'Enter' || key === ';') && state.currentValue) {
    const { currentValue, selectedTags } = state

    state = {
      selectedTags: addTag(currentValue, selectedTags, allTags),
      currentValue: '',
    }
  }

  return [key, state]
}

export const handleBackspace = cb => ([key, state]) => {
  if (key === 'Backspace' && state.currentValue === '') {
    cb()
  }

  return [key, state]
}
