import { Component } from 'preact'
import cx from 'classnames'

import { birmanize, formatPrice } from 'lib/textHelper'
import { decorateSchoolPrice } from './schoolHelpers'
import { TextNode, Caption } from '../../textNodes'

import Price from '../../price/price'
import Pricing from '../../price/pricing'

export default class NewPaybarSchoolPricing extends Component {
  get isCouponApplied() {
    return this.props.prices.isCouponApplied
  }

  get couponValidUntil() {
    return new Date((this.props.prices.couponValidUntil || 0) * 1000)
  }

  get prices() {
    return this.props.isMonthly ? this.props.prices.monthly : this.props.prices
  }

  get maxPrice() {
    const price = this.isCouponApplied ? this.prices.previousOneTime : this.prices.max

    if (!price || price === this.prices.oneTime) return ''

    return I18n.t('newPaybar.pricingInfo.prevPrice', { price: formatPrice(price) })
  }

  get currentPrice() {
    return decorateSchoolPrice(formatPrice(this.prices.oneTime))
  }

  get nextPrice() {
    const { endsAt } = this.props.prices
    const { previousOneTime, nextOneTime } = this.prices
    const isCouponEndsBeforePriceChange = this.couponValidUntil <= new Date(endsAt).getTime()
    const priceAfterCoupon = isCouponEndsBeforePriceChange ? previousOneTime : nextOneTime

    const price = this.isCouponApplied ? priceAfterCoupon : nextOneTime

    if (!price) return null

    return decorateSchoolPrice(formatPrice(price))
  }

  get nextPriceSince() {
    const { willChangeAt } = this.props.prices
    const couponEndsAt = new Date(this.couponValidUntil.getTime() + 1000)
    const priceWillChangeAt = this.isCouponApplied ? couponEndsAt : willChangeAt

    const date = birmanize(priceWillChangeAt, { format: 'weekDayAndDate' }).toLowerCase()

    return I18n.t('newPaybar.pricingInfo.nextPriceSince', { date })
  }

  get pricingInfo() {
    if (this.props.skipPricingInfo) return null

    return I18n.t(`newPaybar.pricingInfo.school.${this.props.type}`)
  }

  get units() {
    return this.props.isMonthly ? I18n.t('subscription.unitsMonthly') : I18n.t('subscription.unitsOneTime')
  }

  render() {
    const classnames = cx('newPaybarPricing', { is__monthly: this.props.isMonthly })

    return (
      <div className={ classnames }>
          <Pricing>
            <Price
              label={ this.maxPrice }
              amount={ this.currentPrice }
              units={ this.units }
              highlighted
              smallUnits
              hasCompactUnitsView />

            { this.nextPrice !== null &&
              <Price
                label={ this.nextPriceSince }
                amount={ this.nextPrice }
                units={ this.units }
                smallUnits
                hasCompactUnitsView />
            }
          </Pricing>

          { this.pricingInfo &&
            <TextNode html={ this.pricingInfo } className="newPaybarPricing-info module device device__mobile" />
          }

          { this.pricingInfo &&
            <Caption html={ this.pricingInfo } className="newPaybarPricing-info module device device__desktop device__laptop" />
          }
      </div>
    )
  }
}
