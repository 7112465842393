import { formatNumber } from 'lib/textHelper'

const isPrepBonus = bonus => bonus.column === 'Подг. курсы'

export const formatBonusScore = (bonus) => {
  const isPrep = isPrepBonus(bonus)
  const score = formatNumber(bonus.score, { precision: isPrep ? 3 : 2 })

  return `+${score}`
}
