import { Component } from 'preact'

import { FormInput } from '../form'

export default class SovietAskFormAuthorFieldset extends Component {
  constructor(props) {
    super(props)

    this.handleNameInput = this.handleNameInput.bind(this)
    this.handleEmailInput = this.handleEmailInput.bind(this)
  }

  handleNameInput({ value }) {
    this.props.onEdit({ name: value })
  }

  handleEmailInput({ value }) {
    this.props.onEdit({ email: value })
  }

  render(props) {
    const id = props.id || 'new'
    const fields = [
      {
        id: `${id}-name`,
        name: 'name',
        type: 'text',
        onIput: this.handleNameInput,
        label: I18n.t('soviet.askForm.name'),
        placeholder: I18n.t('soviet.askForm.namePlaceholder'),
        value: props.name,
      },
      {
        id: `${id}-email`,
        name: 'email',
        type: 'email',
        onIput: this.handleEmailInput,
        label: I18n.t('soviet.askForm.email'),
        placeholder: I18n.t('soviet.askForm.emailPlaceholder'),
        value: props.email,
      },
    ]

    return (
      <div className="sovietAskForm-fieldset">
        { fields.map(field => (
          <div className="sovietAskForm-fieldsetRow">
            <div className="formLabel">
              <label htmlFor={ field.id }>{ field.label }</label>
            </div>

            <FormInput
              className="is__fullWidth"
              id={ field.id }
              name={ field.name }
              type={ field.type }
              initialValue={ field.value }
              placeholder={ field.placeholder }
              onInput={ field.onIput }
              onValidityChange={ props.onValidityChange }
              required />
          </div>
        )) }
      </div>
    )
  }
}
