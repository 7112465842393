import { Component } from 'preact'

import { Heading1 } from '../textNodes'
import SovietUnanswered from './sovietUnanswered'
import DoubleHover from '../../assets/scripts/doubleHover'
import { fetchSovietsUnanswered } from './fetch'

const FIRST_BATCH_AMOUNT = 50
const FETCH_DELAY = 5000

export default class SovietsUnanswered extends Component {
  constructor(props) {
    super(props)

    this.state = {
      soviets: [],
      showSpinner: true,
    }

    this.fetchAllSovietsUnanswered = this.fetchAllSovietsUnanswered.bind(this)
    this.triggerNewImagesAppeared = this.triggerNewImagesAppeared.bind(this)
  }

  componentDidMount() {
    this.fetchInitialBatch()
    setTimeout(this.fetchAllSovietsUnanswered, FETCH_DELAY)
  }

  componentDidUpdate() {
    $('.doubleHover').each(function() { new DoubleHover($(this)) })
    $('.footer').toggle(!this.state.showSpinner)
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimeout)
  }

  fetchInitialBatch() {
    fetchSovietsUnanswered(FIRST_BATCH_AMOUNT)
      .then(soviets => this.setState({ soviets, showSpinner: true }))
  }

  fetchAllSovietsUnanswered() {
    fetchSovietsUnanswered()
      .then(soviets => {
        return this.setState({ soviets, showSpinner: false }, this.triggerNewImagesAppeared)
      })
  }

  renderQuestionsList() {
    return this.state.soviets.map(soviet => {
      return (
        <SovietUnanswered
          key={ soviet.id }
          withCover={ true }
          { ...soviet }
        />
      )
    })
  }

  triggerNewImagesAppeared() {
    $(document).trigger('newImagesAppeared')
  }

  render() {
    return (
      <div className="sovietsUnanswered">
        <Heading1 className="sovietsUnanswered-pageTitle webpage-title is__medium is__wide">
          Без ответа
        </Heading1>

        { !!this.state.soviets.length &&
          this.renderQuestionsList()
        }

        { this.state.showSpinner &&
          <div className="sovietsUnanswered-spinner" />
        }
      </div>
    )
  }
}
