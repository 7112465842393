import { Component } from 'preact'
import cx from 'classnames'

const Week = ({ isActive, number }) => {
  const classNames = cx('schoolRatingChartHeader-week', { is__disabled: !isActive })
  return <span className={ classNames }>{ number }</span>
}

export default class SchoolRatingChartHeader extends Component {
  get weeks() {
    const { totalWeeksCount, weeks } = this.props
    const result = []
    const lastActiveWeek = Math.max(...weeks)

    for (let number = 1; number <= totalWeeksCount; number += 1) {
      const isActive = number <= lastActiveWeek

      result.push(<Week isActive={ isActive } number={ number }/>)
    }

    return result
  }

  render() {
    const classNames = cx('schoolRatingChartHeader', { is__sticky: this.props.isSticky })

    return <div className={ classNames }>{ this.weeks }</div>
  }
}
