export default function GetGiftBookReadButton({ accessUrl, keyColor }) {
  const styles = `
      --buttonColor: #fff;
      --buttonTextColor: #000;
      --buttonHoverColor: ${keyColor};
      --buttonHoverTextColor: #fff;
  `

  return (
    <div className="getGiftPresentOverlay">
      <a
        href={ accessUrl }
        style={ styles }
        className="getGiftPresentOverlay-button button is__enlargedOnMobiles"
      >{ I18n.t('getGift.readButton') }</a>
    </div>
  )
}
