import cx from 'classnames'

const LinkWithArrow = (props) => {
  const classes = cx(
    'module',
    'linkWithArrow',
    {
      is__small: props.isSmall,
      is__inline: props.isInline,
      is__wrap: props.isWrap,
    },
    props.className
  )

  const linkClasses = cx(
    'is__noUnderline',
    {
      doubleHover: props.isDoubleHover,
      novisited: props.isNoVisited,
    }
  )

  return (
    <div className={ classes }>
      <a
        href={ props.href }
        className={ linkClasses }
      >
        <span className="linkWithArrow-text">
          { props.text || props.children }
        </span>
        <span className="linkWithArrow-arrow">❱</span>
      </a>
    </div>
  )
}

export default LinkWithArrow
