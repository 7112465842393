module.exports = $ => {
  $(document).on('copy', '[data-copy-text]', function(e) {
    const { currentTarget } = e
    const plainText = currentTarget?.getAttribute('data-copy-text')
    const event = e.originalEvent

    if (plainText) {
      event.clipboardData.setData('text/plain', plainText)
      event.preventDefault()
    }
  })
}
