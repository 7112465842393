import { Component } from 'preact'

class SovietHeaderImageUploadButton extends Component {
  constructor(props) {
    super(props)

    this.handleFileAttachment = this.handleFileAttachment.bind(this)
  }

  handleFileAttachment(e) {
    const files = [e.target.files[0]]

    $(document).trigger('headerImageUpload', { files })

    e.target.value = ''
  }

  render() {
    return (
      <div className="button is__thin is__medium sovietUploadButton">
        <input type="file"
          id="sovietUploadButton"
          accept="image/jpeg,image/png,image/gif"
          onChange={ this.handleFileAttachment } />
        <label htmlFor="sovietUploadButton">Загрузить обложку</label>
      </div>
    )
  }
}

export default SovietHeaderImageUploadButton
