import { Component } from 'preact'
import cx from 'classnames'
import reachGoal from 'lib/reachGoal'

const BUNDLE_PRODUCT_ID = 'bookshelf-all'

export default class SubscriptionButton extends Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    e.preventDefault()

    this.reachGoal()
    this.redirect()
  }

  reachGoal() {
    reachGoal(this.props.goal)
  }

  redirect() {
    window.parent.location = this.href
  }

  get productId() {
    const { isBundle, subscription: { product_id: productId } } = this.props

    return isBundle ? BUNDLE_PRODUCT_ID : productId
  }

  get href() {
    const slug = this.props.slug || `/projects/${this.productId}/`
    const query = this.isUpgrade ? 'upgrade' : 'self'

    return `${slug}?${query}`
  }

  get classNames() {
    return cx(
      'button is__purchaseLike is__enlargedOnMobiles',
      {
        is__preorderLike: this.isPreorder,
        is__upgradeLike: this.isUpgrade,
      }
    )
  }

  get text() {
    if (this.isUpgrade) return this.upgradeText
    if (this.isPreorder) return this.preorderText

    return I18n.t(`newPaybar.buyButtonText.book.${ this.isSubscribed ? 'subscribed' : 'default' }`)
  }

  get preorderText() {
    return I18n.t(`newPaybar.buyButtonText.book.${ this.isSubscribed ? 'subscribedPreorder' : 'preorder' }`)
  }

  get upgradeText() {
    return I18n.t('newPaybar.buyButtonText.bookshelf.upgradeCompact')
  }

  get isPreorder() {
    const { isBundle, prices } = this.props

    return isBundle ? prices.bundled.preorder : prices.preorder
  }

  get isUpgrade() {
    return this.props.isBundle && !this.isSubscribed && this.props.prices.bundled.specialOffer
  }

  get isSubscribed() {
    const { isBundle, subscription: { id, original_product_id: originalProductId } } = this.props

    return isBundle ? originalProductId === BUNDLE_PRODUCT_ID : !!id
  }

  render() {
    return (
      <a href={ this.href }
         className={ this.classNames }
         onClick={ this.onClick }
         dangerouslySetInnerHTML={{ __html: this.text }}>
      </a>
    )
  }
}
