import NewPaybarPrep from './prep/newPaybar'
import NewPaybarBookshelf from './bookshelf/newPaybar'
import NewPaybarBook from './bookshelf/newPaybar'
import NewPaybarSchool from './school/newPaybar'
import NewPaybarLecture from './lecture/newPaybar'
import NewPaybarCourse from './course/newPaybar'

import NewPaybarNotificationEmailSent from './newPaybarNotificationEmailSent'
import NewPaybarNotificationPaymentFailed from './newPaybarNotificationPaymentFailed'
import NewPaybarNotificationSubscriptionUpdateFailed from './newPaybarNotificationSubscriptionUpdateFailed'
import NewPaybarNotificationSubscriptionCancellationFailed
  from './newPaybarNotificationSubscriptionCancellationFailed'
import NewPaybarNotificationSubscriptionCancellationRevertFailed
  from './newPaybarNotificationSubscriptionCancellationRevertFailed'
import NewPaybarNotificationDisclaimer from './newPaybarNotificationDisclaimer'

import NewPaybarFooterPrep from './prep/newPaybarFooter'
import NewPaybarFooterBookshelf from './bookshelf/newPaybarFooter'
import NewPaybarFooterBook from './bookshelf/newPaybarFooter'
import NewPaybarFooterCourse from './course/newPaybarFooter'

import NewPaybarOverlaySchool from './school/newPaybarOverlay'
import NewPaybarOverlayBookshelf from './bookshelf/newPaybarOverlay'
import NewPaybarOverlayBook from './bookshelf/newPaybarOverlay'
import NewPaybarOverlayLecture from './lecture/newPaybarOverlay'
import NewPaybarOverlayCourse from './course/newPaybarOverlay'

import NewPaybarEmailFormCaptionSchool from './school/newPaybarEmailFormCaption'
import NewPaybarEmailFormCaptionBookshelf from './bookshelf/newPaybarEmailFormCaption'
import NewPaybarEmailFormCaptionBook from './bookshelf/newPaybarEmailFormCaption'

import NewPaybarNotificationEmailSentPrep from './prep/newPaybarNotificationEmailSent'
import NewPaybarNotificationEmailSentBookshelf from './bookshelf/newPaybarNotificationEmailSent'
import NewPaybarNotificationEmailSentBook from './bookshelf/newPaybarNotificationEmailSent'

import NewPaybarFooterCaptionSchool from './school/newPaybarFooterCaption'
import NewPaybarFooterCaptionBookshelf from './bookshelf/newPaybarFooterCaption'
import NewPaybarFooterCaptionBook from './bookshelf/newPaybarFooterCaption'

import NewPaybarLaunchpadSchool from './school/newPaybarLaunchpad'
import NewPaybarLaunchpadBookshelf from './bookshelf/newPaybarLaunchpad'
import NewPaybarLaunchpadBook from './bookshelf/newPaybarLaunchpad'
import NewPaybarLaunchpadLecture from './lecture/newPaybarLaunchpad'
import NewPaybarLaunchpadCourse from './course/newPaybarLaunchpad'

import NewPaybarPersonalFieldsetDefault from './newPaybarPersonalFieldset'
import NewPaybarPersonalFieldsetSchool from './school/newPaybarPersonalFieldset'
import NewPaybarPersonalFieldsetCourse from './course/newPaybarPersonalFieldset'

export function detectPaybarInner(product) {
  const PAYBAR_MAPPING = {
    prep: NewPaybarPrep,
    bookshelf: NewPaybarBookshelf,
    book: NewPaybarBook,
    school: NewPaybarSchool,
    lecture: NewPaybarLecture,
    collecture: NewPaybarLecture,
    course: NewPaybarCourse,
  }

  return PAYBAR_MAPPING[product]
}

export function detectPaybarNotice(noticeType) {
  const NOTICE_MAPPING = {
    emailSent: NewPaybarNotificationEmailSent,
    paymentFailed: NewPaybarNotificationPaymentFailed,
    paymentFailedAmountExceed: NewPaybarNotificationPaymentFailed,
    paymentFailedForeignIssuerBlockedCard: NewPaybarNotificationPaymentFailed,
    paymentFailedForeignIssuerBlockedCardCompletely: NewPaybarNotificationPaymentFailed,
    subscriptionUpdateFailed: NewPaybarNotificationSubscriptionUpdateFailed,
    subscriptionCancellationFailed: NewPaybarNotificationSubscriptionCancellationFailed,
    subscriptionCancellationRevertFailed: NewPaybarNotificationSubscriptionCancellationRevertFailed,
    disclaimer: NewPaybarNotificationDisclaimer,
  }

  return NOTICE_MAPPING[noticeType]
}

export function detectPaybarFooter(product) {
  const FOOTER_MAPPING = {
    prep: NewPaybarFooterPrep,
    school: NewPaybarFooterPrep,
    bookshelf: NewPaybarFooterBookshelf,
    book: NewPaybarFooterBook,
    lecture: NewPaybarFooterBook,
    collecture: NewPaybarFooterBook,
    course: NewPaybarFooterCourse,
  }

  return FOOTER_MAPPING[product]
}

export function detectPaybarOverlay(product) {
  const OVERLAY_MAPPING = {
    prep: NewPaybarOverlaySchool,
    school: NewPaybarOverlaySchool,
    lecture: NewPaybarOverlayLecture,
    collecture: NewPaybarOverlayLecture,
    bookshelf: NewPaybarOverlayBookshelf,
    book: NewPaybarOverlayBook,
    course: NewPaybarOverlayCourse,
  }

  return OVERLAY_MAPPING[product]
}

export function detectPaybarEmailFormCaption(product) {
  const EMAIL_CAPTION_MAPPING = {
    prep: NewPaybarEmailFormCaptionSchool,
    school: NewPaybarEmailFormCaptionSchool,
    lecture: NewPaybarEmailFormCaptionBookshelf,
    collecture: NewPaybarEmailFormCaptionBookshelf,
    bookshelf: NewPaybarEmailFormCaptionBookshelf,
    book: NewPaybarEmailFormCaptionBook,
    course: NewPaybarEmailFormCaptionSchool,
  }

  return EMAIL_CAPTION_MAPPING[product]
}

export function detectPaybarNotificationSentEmail(product) {
  const EMAIL_CAPTION_MAPPING = {
    prep: NewPaybarNotificationEmailSentPrep,
    school: NewPaybarNotificationEmailSentPrep,
    lecture: NewPaybarNotificationEmailSentBookshelf,
    collecture: NewPaybarNotificationEmailSentBookshelf,
    bookshelf: NewPaybarNotificationEmailSentBookshelf,
    book: NewPaybarNotificationEmailSentBook,
    course: NewPaybarNotificationEmailSentBookshelf,
  }

  return EMAIL_CAPTION_MAPPING[product]
}

export function detectPaybarFooterCaption(product) {
  const EMAIL_CAPTION_MAPPING = {
    prep: NewPaybarFooterCaptionSchool,
    school: NewPaybarFooterCaptionSchool,
    bookshelf: NewPaybarFooterCaptionBookshelf,
    book: NewPaybarFooterCaptionBook,
    lecture: NewPaybarFooterCaptionBook,
    collecture: NewPaybarFooterCaptionBook,
  }

  return EMAIL_CAPTION_MAPPING[product]
}

export function detectPaybarLaunchpad(product) {
  const LAUNCHPAD_MAPPING = {
    prep: NewPaybarLaunchpadSchool,
    school: NewPaybarLaunchpadSchool,
    lecture: NewPaybarLaunchpadLecture,
    collecture: NewPaybarLaunchpadLecture,
    bookshelf: NewPaybarLaunchpadBookshelf,
    book: NewPaybarLaunchpadBook,
    course: NewPaybarLaunchpadCourse,
  }

  return LAUNCHPAD_MAPPING[product]
}

export function detectPaybarFieldset(product) {
  if (product === 'school') return NewPaybarPersonalFieldsetSchool

  if (product === 'course') return NewPaybarPersonalFieldsetCourse

  return NewPaybarPersonalFieldsetDefault
}
