const CLASS_ACTIVE = 'is__active'
const SAFEZONE = 50
const MIN_TRIGGER = 5

export default class Anchor {
  constructor(el) {
    this.el = el
    this.id = this.el.getAttribute('data-id')

    this.calculateTrigger()
  }

  get spread() {
    return $(this.el).closest('.spread')[0]
  }

  get page() {
    return $(this.el).closest('.page')[0]
  }

  get pageIn() {
    return $(this.el).closest('.page-in')[0]
  }

  get isSwissTheme() {
    return this.spread.classList.contains('is_swiss') ||
      this.page.classList.contains('grid__column')
  }

  get pageOffset() {
    const spreadTop = this.spread
      .getBoundingClientRect()
      .top

    const pageTop = this.page
      .getBoundingClientRect()
      .top

    const pageInPaddingTop = getComputedStyle(this.pageIn).getPropertyValue('padding-top')
    const pagePaddingTop = getComputedStyle(this.page).getPropertyValue('padding-top')

    return pageTop - spreadTop + parseInt(pageInPaddingTop, 10) + parseInt(pagePaddingTop, 10)
  }

  calculateTrigger() {
    const scrollTop = window.application.currentScrollPosition
    const offsetTop = scrollTop + this.el.getBoundingClientRect().top

    this.trigger = Math.max(offsetTop - SAFEZONE - this.pageOffset, MIN_TRIGGER)
  }

  setActive() {
    this.el.classList.add(CLASS_ACTIVE)
  }

  setInactive() {
    this.el.classList.remove(CLASS_ACTIVE)
  }
}
