import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { setTheme } from './sovietCompilationActions'

import compilationThemes from 'lib/current/compilationThemes'

import ScrollingSelector from '../scrollingSelector/scrollingSelector'

class ThemeCell extends Component {
  constructor(props) {
    super(props)

    this.switchTheme = this.switchTheme.bind(this)
  }

  switchTheme() {
    const currentTheme = compilationThemes[this.props.theme]

    this.props.setTheme({
      theme: this.props.theme,
      ...currentTheme,
    })
  }

  render(props) {
    const currentTheme = compilationThemes[props.theme]
    const previewUrl = currentTheme.previewUrl || currentTheme.coverUrl
    const cellStyle = {
      'background-image': `url('${previewUrl}')`,
    }

    return (
      <li
        style={ cellStyle }
        className={ cx({ is__active: this.props.isActive }) }
        onClick={ this.switchTheme } />
    )
  }
}

const CustomCoverCell = (props) => {
  const cellStyle = {}

  if (props.customCoverUrl) {
    cellStyle['background-image'] = `url('${props.customCoverUrl}')`
  }

  const classes = cx(
    'customCoverCell',
    {
      is__active: props.isActive,
      is__uploaded: props.customCoverUrl,
    }
  )

  return (
    <li
      style={ cellStyle }
      className={ classes }
      onClick={ props.setTheme }>
      Своя
    </li>
  )
}

class SovietCompilationThemePicker extends Component {
  constructor(props) {
    super(props)

    this.switchTheme = this.switchTheme.bind(this)
    this.switchToCustomTheme = this.switchToCustomTheme.bind(this)
  }

  switchTheme(params) {
    this.props.setTheme(params)
  }

  switchToCustomTheme() {
    this.props.setTheme({
      theme: 'custom',
      layout: this.props.customThemeLayout,
      textColor: this.props.customThemeTextColor,
      coverUrl: this.props.customCoverUrl,
    })
  }

  render(props) {
    return (
      <ScrollingSelector className={`compilationThemePicker ${props.className || ''}`}>
        <ul className="compilationThemePicker-list">
          <CustomCoverCell
            isActive={ this.props.theme === 'custom' }
            customCoverUrl={ props.customCoverUrl }
            setTheme={ this.switchToCustomTheme }
          />
          { Object.keys(compilationThemes).map((theme) => {
            return (
              <ThemeCell
                theme={ theme }
                isActive={ this.props.theme === theme }
                setTheme={ this.switchTheme } />
            )
          }) }
        </ul>
      </ScrollingSelector>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    theme: sovietCompilation.theme,
    layout: sovietCompilation.layout,
    textColor: sovietCompilation.textColor,
    customThemeLayout: sovietCompilation.customThemeLayout,
    customThemeTextColor: sovietCompilation.customThemeTextColor,
    coverUrl: sovietCompilation.coverUrl,
    customCoverUrl: sovietCompilation.customCoverUrl,
  }
}

const mapDispatchToProps = {
  setTheme,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationThemePicker)
