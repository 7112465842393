import cx from 'classnames'

import NewPaybarLaunchpadItem from '../newPaybarLaunchpadItem'
import LectureOnDevices from '../../lectureOnDevices/lectureOnDevices'
import getAccessLink from 'lib/getAccessLink'

export default class NewPaybarLaunchpadItemCourse extends NewPaybarLaunchpadItem {
  isImageLoaded() {
    if (this.$image) return this.$image && this.$image.complete

    return true
  }

  get accessLink() {
    if (this.isLocked) return null

    const productId = this.props.courseId

    return getAccessLink({ product: 'course', productId })
  }

  get classNames() {
    return cx(
      'newPaybarLaunchpadItemCourse',
      {
        is__locked: this.isLocked,
        is__loaded: this.state.isLoaded,
      }
    )
  }

  render() {
    const cover = 'files/_cover.jpg'
    const buttonText = I18n.t('newPaybar.course.launchpad.button')

    return (
      <a className={ this.classNames } href={ this.accessLink }>
        { this.isLocked &&
          <LectureOnDevices
            cover={ cover }
            href={ null }
            hasOnlyDesktop={ true }
            withMonitorIndent={ true }
          />
        }

        { !this.isLocked &&
          <img className="newPaybarLaunchpadItemCourse-img" src={ cover } onLoad={ this.setLoaded } />
        }

        { !this.isLocked &&
          <a href={ this.accessLink } className="newPaybarLaunchpadItemCourse-button no-u doubleHover">
            <button type="button" className="newPaybarAccessButton button module device laptop desktop">
              { buttonText }
            </button>
            <button type="button" className="button is__thin is__enlargedOnMobiles module device mobile">
              { buttonText }
            </button>
          </a>
        }
      </a>
    )
  }
}
