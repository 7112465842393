const Caption = ({ html, ...props }) => (
  <div {...props} className={`caption module ${props.className ? props.className : ''}`}>
    <div className="caption-text"
      dangerouslySetInnerHTML={ html ? { __html: html } : null }
    >
      { props.children }
    </div>
  </div>
)

export default Caption
