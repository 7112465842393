import { connect } from 'preact-redux'
import cx from 'classnames'
import Tag from '../tag/tag'
import { Caption } from '../textNodes'
import { capitalize } from 'lib/textHelper'
import { formatScore } from 'lib/school/classroom/scoreHelper'

import {
  pinStudent,
  setActiveStudent,
  unpinStudent,
  unsetActiveStudent,
} from './schoolRatingActions'

import { isBlackMarked, splitName } from './helpers'
import { getCountryFlagUrl } from '../schoolRatings/helpers'
import SchoolRatingConnectedStudentItem from './schoolRatingConnectedStudentItem'

import { NBSP, THINSP, ARROW_UP, ARROW_DOWN } from 'lib/constants'
import { MIN_DESKTOP_WIDTH } from 'lib/envHelpers'

class SchoolRatingStudent extends SchoolRatingConnectedStudentItem {
  constructor(props) {
    super(props)

    this.checkCompactness = this.checkCompactness.bind(this)
  }

  componentDidMount() {
    this.checkCompactness()

    $(document).on('appResize', this.checkCompactness)
  }

  componentWillUnmount() {
    $(document).off('appResize', this.checkCompactness)
  }

  get rank() {
    if (this.props.fairRank) return this.props.fairRank

    if (!this.lastWeek) return null

    return this.lastWeek.rank
  }

  get diff() {
    if (!this.lastWeek || !this.lastWeek.diff) return null

    const { diff } = this.lastWeek

    return (diff > 0 ? ARROW_UP : ARROW_DOWN) + Math.abs(diff)
  }

  get name() {
    const splittedName = splitName(this.props.name)
    const firstName = capitalize(splittedName.first)
    const lastName = capitalize(splittedName.last)
    const firstLetter = firstName[0] + '.'

    const result = [
      this.state.isCompact ? firstLetter : firstName,
      lastName,
    ]

    return result.filter(item => !!item).join(NBSP)
  }

  get bureausphereLink() {
    return this.props.englishName ? `/burosfera/${ this.props.englishName }` : null
  }

  get score() {
    if (!this.lastWeek || !this.lastWeek.score) return null

    const { score } = this.lastWeek

    return formatScore(score)
  }

  get countryFlagSrc() {
    const { country, city } = this.props
    return getCountryFlagUrl(country, city)
  }

  get bottomText() {
    const { job, organization, city } = this.props

    const result = [job, organization, city]
      .filter(item => !!item)
      .join(', ')

    return capitalize(result)
  }

  get isUser() {
    const { user } = window.application

    if (!user) return false

    return Number(user.id) === Number(this.props.uid)
  }

  checkCompactness() {
    if (!this.$topWrap) return

    const isCompact = this.$topWrap.scrollWidth > this.$topWrap.clientWidth

    if (isCompact !== this.state.isCompact) this.setState({ isCompact })
  }

  render() {
    const { enrollmentStatus, enrollmentGranted, isNew, country, weeks } = this.props

    const isOnMobile = window.application.viewWidth < MIN_DESKTOP_WIDTH

    const hasBlackMark = isBlackMarked(enrollmentStatus)
    const hasAnyTags = enrollmentGranted || isNew || hasBlackMark

    const classNames = cx(
      'schoolRatingStudent schoolRatingConnectedStudentItem',
      `is__${ this.color }`,
      {
        is__active: this.isPinned || this.isActive,
        is__faded: this.isFaded,
        is__disabled: this.isDisabled,
        is__user: this.isUser,
        has__hover: weeks.length > 0 && !isOnMobile,
        is__obfuscated: !this.props.englishName,
      },
    )

    return (
      <div className={ classNames }
           onMouseEnter={ this.onMouseEnter }
           onMouseLeave={ this.onMouseLeave }
           onClick={ this.onClick }>

        <Caption className="schoolRatingStudent-top">
          <span className="schoolRatingStudent-topWrap" ref={ el => this.$topWrap = el }>
            { !this.isFaded && this.rank &&
            <span className="schoolRatingStudent-rank">
              { this.rank }
              { this.diff &&
              <span className="schoolRatingStudent-diff">{ THINSP + this.diff }</span> }
              { NBSP }
            </span>
            }

            <a className="schoolRatingStudent-name" href={ this.bureausphereLink } target="_blank">
              { this.name }
            </a>

            { hasAnyTags &&
            <span className="schoolRatingStudent-tags">
                { NBSP }
              { enrollmentGranted && <Tag type="granted" text={ I18n.t('schoolRating.tags.granted') }/> }
              { isNew && <Tag type="rookie" text={ I18n.t('schoolRating.tags.rookie') }/> }
              { hasBlackMark && <Tag type="blackMark" text={ I18n.t('schoolRating.tags.blackMark') }/> }
              </span>
            }

            { this.score &&
            <span className="schoolRatingStudent-score">
                { NBSP + this.score }
              </span>
            }
          </span>
        </Caption>

        <Caption className="schoolRatingStudent-bottom">
          <span dangerouslySetInnerHTML={ { __html: this.bottomText } }></span>

          { this.countryFlagSrc &&
            <img src={ this.countryFlagSrc } alt={ country } title={ country } className="schoolRatingsFlag"/>
          }
        </Caption>
      </div>
    )
  }
}

const mapStateToProps = ({ schoolRating }) => {
  return {
    activeStudentUid: schoolRating.activeStudentUid,
    pinnedStudentUid: schoolRating.pinnedStudentUid,
  }
}

const mapDispatchToProps = {
  setActiveStudent,
  unsetActiveStudent,
  pinStudent,
  unpinStudent,
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolRatingStudent)
