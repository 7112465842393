import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarPricing from '../newPaybarPricing'
import NewPaybarPayer from '../newPaybarPayer'
import NewPaybarPersonalForm from '../newPaybarPersonalForm'
import NewPaybarOverlay from '../newPaybarOverlay'
import NewPaybarGiftMessage from '../newPaybarGiftMessage'
import expandScreenToFlags from '../expandScreenToFlags'
import NewPaybarSpecialPriceLabel from '../newPaybarSpecialPriceLabel'
import NewPaybarTabs from '../newPaybarTabs'
import NewPaybarSubscriptionStatus from '../bookshelf/newPaybarSubscriptionStatus'
import NewPaybarSubscriptionStatusMobile from '../newPaybarSubscriptionStatusMobile'
import NewPaybarSchoolRequirements from '../school/newPaybarSchoolRequirements'
import NewPaybarLaunchpad from './newPaybarLaunchpad'
import NewPaybarLectureDevices from './newPaybarLectureDevices'
import NewPaybarLectureViewButton from './newPaybarLectureViewButton'
import NewPaybarCollectureUpgrade from './newPaybarCollectureUpgrade'

import isSubscribed from '../isSubscribed'
import NewPaybarTitle from '../newPaybarTitle'
import NewPaybarSubheading from '../newPaybarSubheading'
import isCompanySubscription from '../isCompanySubscription'

class NewPaybarLecture extends Component {
  get hasReaderDiscount() {
    return this.props.prices.isApplicableForReaderDiscount
  }

  get isUpgrade() {
    const { type, prices } = this.props

    return type === 'upgrade' && prices.specialOffer
  }

  get shouldDisplaySpecialPriceLabel() {
    const { type, isPurchaseScreen } = this.props

    if (!isPurchaseScreen || type === 'company') return false

    return this.hasReaderDiscount || this.isUpgrade
  }

  get isCollecture() {
    return this.props.product === 'collecture'
  }

  render() {
    const { props } = this
    const isCompanyEdit = isCompanySubscription(props)
    const isGiftedSubscription = props.subscription.subscription_type === 'gift'
    const shouldDisplayPricing = props.isPurchaseScreen || props.isInitialScreen

    const shouldDisplaySubscriptionStatus = !isGiftedSubscription &&
      ['self', 'company'].includes(props.type) &&
      (props.isSubscriptionScreen || props.isSuccessScreen)

    const shouldDisplayRequirements = props.isSuccessScreen || props.isSubscriptionScreen

    return (
      <div className="newPaybar-infoIn module">
        { !props.isSuccessScreen &&
          <NewPaybarTabs
            device="mobile"
            type={ props.type }
            product={ props.product } />
        }

        { this.shouldDisplaySpecialPriceLabel &&
          <div className="newPaybar-label">
            <NewPaybarSpecialPriceLabel text={ I18n.t('newPaybar.specialPriceLabel') } />
          </div>
        }

        { !props.isSubscriptionScreen &&
          <NewPaybarTitle
            className="newPaybarTitle module device device__mobile"
            type={ props.type }
            screen={ props.screen }
            product={ props.product }
            productTitle={ props.productTitle } />
        }

        { isCompanyEdit &&
          <NewPaybarTitle
            className="newPaybarTitle module device device__mobile"
            type={ props.type }
            product={ props.product } />
        }

        { !props.isSubscriptionScreen && this.isCollecture &&
          <NewPaybarSubheading>
            { this.props.productSubheading }
          </NewPaybarSubheading>
        }

        { props.isSuccessScreen &&
          <NewPaybarOverlay className="device device__mobile" />
        }

          <div className="newPaybarDevicesAndPricing">
            { (props.isInitialScreen || props.isPurchaseScreen) &&
              <NewPaybarLectureDevices product={ props.product } />
            }

            { shouldDisplayRequirements &&
                <NewPaybarSchoolRequirements
                  className="module device device__laptop device__desktop"
                  product={ props.product } />
            }

            { shouldDisplayPricing &&
              <NewPaybarPricing
                product={ props.product }
                type={ props.type }
                productId={ props.productId }
                isPreorder={ props.isPreorder }
                prices={ props.prices }
                smallUnits={ props.smallUnits }
                hasCompactUnitsView={ this.isCollecture }
                key={ `newPaybarPricing-${props.isUserAuthenticated}` } />
            }

            { (props.type === 'gift' && !props.isSuccessScreen) &&
              <NewPaybarGiftMessage
                prices={ props.prices }
                type={ props.type }
                product={ props.product }
                className="device device__laptop device__desktop" />
            }
          </div>

        { props.isSubscriptionScreen && !isCompanyEdit &&
          <div className="newPaybarLaunchpadMobileWrapper module device device__mobile">
            <NewPaybarLaunchpad />

            { props.isUserAuthenticated &&
              <NewPaybarSchoolRequirements product={ props.product } />
            }
          </div>
        }

        { isCompanyEdit &&
          <NewPaybarSchoolRequirements
            product={ props.product }
            className="module device device__mobile" />
        }

        { this.isUpgrade &&
          <NewPaybarCollectureUpgrade
            lectures={ this.props.lectures }
            className="device device__mobile"
            ownedProducts={ this.props.prices.specialOffer } />
        }

        { props.isPurchaseScreen &&
          <NewPaybarPayer payerRef={ props.payerRef } isCompact />
        }

        { shouldDisplaySubscriptionStatus &&
          <NewPaybarSubscriptionStatus className="device device__laptop device__desktop" />
        }

        { isCompanyEdit &&
          <NewPaybarSubscriptionStatusMobile type={ props.type } product={ props.product } />
        }

        { !props.isSuccessScreen &&
          <NewPaybarPersonalForm
            subscriptionUrl={ props.subscriptionUrl }
            scrollableRef={ props.scrollableRef }
            isPreorder={ props.isPreorder }>

            { this.isUpgrade &&
              <NewPaybarCollectureUpgrade
                lectures={ this.props.lectures }
                className="device device__laptop device__desktop"
                ownedProducts={ this.props.prices.specialOffer } />
            }
          </NewPaybarPersonalForm>
        }

        <NewPaybarLectureViewButton isSubscribed={ this.props.isSubscribed } />

        { this.props.children }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    productId: newPaybar.productId,
    productTitle: newPaybar.productTitle,
    productSubheading: newPaybar.productSubheading,
    screen: newPaybar.screen,
    prices: newPaybar.prices,
    smallUnits: newPaybar.smallUnits,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    isSubscribed: isSubscribed(newPaybar),
    subscription: newPaybar.subscription,
    lectures: newPaybar.lectures,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarLecture)
