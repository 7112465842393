export function getSchoolKeyFromSubscription(subscription) {
  const productId = subscription.productId || subscription.product_id

  if (!productId) return null

  return productId.split('-')[1]
}

export function getSchoolNameFromSubscription(subscription) {
  const schoolKey = getSchoolKeyFromSubscription(subscription)

  if (!schoolKey) return ''

  return I18n.t(`newPaybar.school.schoolNames.${schoolKey}`)
}

export function decorateSchoolPrice(price) {
  return String(price).replace(' ', '<span class="large-price-thinsp"> </span>')
}
