import { Component } from 'preact'
import { connect } from 'preact-redux'

import { setHeaderImageToNone } from './sovietActions'

import { Image } from '../image'
import DeleteButton from '../deleteButton/deleteButton'

import DELETE_HEADERS from 'authJsonHeaders'

class SovietHeaderImage extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    fetch(`/soviet/advices/${this.props.id}/header_image`, {
      method: 'DELETE',
      headers: DELETE_HEADERS,
    })
      .then((res) => {
        if (res.ok) this.props.setHeaderImageToNone()
      })
  }

  render(props) {
    if (!props.headerImageUrl) return null

    const isImageImported = props.questionImageUrl && props.questionImageUrl === props.headerImageUrl

    return (
      <div className="sovietHeaderImage-wrapper">
        <Image
          src={ props.headerImageUrl }
          size={ props.headerImageSize }
          bordered="true"
          className="sovietHeaderImage" />

        { props.isEditor && !isImageImported &&
          <DeleteButton onClick={ this.handleClick } />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    id: soviet.id,
    isEditor: soviet.isEditor,
    questionImageUrl: soviet.questionImageUrl,
    headerImageUrl: soviet.headerImageUrl,
    headerImageSize: soviet.headerImageSize,
  }
}

const mapDispatchToProps = {
  setHeaderImageToNone,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietHeaderImage)
