import { Component } from 'preact'
import { FormInput } from '../form'
import { Caption } from '../textNodes'
import { getUserEmail, getUserName } from './helpers'

const SUBMIT_DELAY = 450
const MIN_CODE_LENGTH = 6

export default class GetGiftForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: getUserName() || null,
      email: getUserEmail() || null,
      code: null,
    }

    this.validityStates = {
      name: !!this.state.name,
      email: !!this.state.email,
      code: false,
    }

    this.submitTimeout = null

    this.focusFirstEmptyField = this.focusFirstEmptyField.bind(this)
    this.onInput = this.onInput.bind(this)
    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.validateCode = this.validateCode.bind(this)
    this.clearCodeError = this.clearCodeError.bind(this)
  }

  componentDidMount() {
    setTimeout(this.focusFirstEmptyField, 150)
  }

  componentDidUpdate(prevProps) {
    const { hasCodeError } = this.props

    if (prevProps.hasCodeError !== hasCodeError) this.setState({ hasCodeError })
  }

  focusFirstEmptyField() {
    const $emptyFields = $(this.el).find('input').filter((index, field) => !field.value)

    if ($emptyFields.length) $emptyFields[0].focus()
  }

  onInput(e) {
    this.setState({ [e.name]: e.value })

    if (this.isValid) {
      clearTimeout(this.submitTimeout)
      this.submitTimeout = setTimeout(this.props.onSubmit, SUBMIT_DELAY)
    }
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity
  }

  clearCodeError() {
    this.setState({ hasCodeError: false })
  }

  validateCode(code) {
    return !this.state.hasCodeError && (code.length >= MIN_CODE_LENGTH)
  }

  get isValid() {
    return Object.keys(this.validityStates).every(field => this.validityStates[field])
  }

  // HACK: Сафари, он не выравнивает пустые инпуты по БЛ, поэтому плейсхолдеры
  render() {
    const { name, email, code, hasCodeError } = this.state

    return (
      <form className="getGiftForm" ref={ el => this.el = el }>
        <label className="getGiftForm-field">
          <Caption className="getGiftForm-label" html={ I18n.t('getGift.form.name') } />
          <div className="getGiftForm-inputWrapper">
            <FormInput
              className="is__fullWidth"
              name="name"
              type="text"
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ name }
              required/>
          </div>
        </label>

        <label className="getGiftForm-field">
          <Caption className="getGiftForm-label" html={ I18n.t('getGift.form.email') } />
          <div className="getGiftForm-inputWrapper">
            <FormInput
              className="is__fullWidth"
              name="email"
              type="email"
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ email }
              required/>
          </div>
        </label>

        <label className="getGiftForm-field">
          <Caption className="getGiftForm-label" html={ I18n.t('getGift.form.code') } />
          <div className="getGiftForm-inputWrapper">
            <FormInput
              className={ `is__fullWidth ${hasCodeError ? 'is__invalid' : ''}` }
              name="code"
              type="text"
              minlength={ MIN_CODE_LENGTH }
              onInput={ this.onInput }
              onKeydown={ this.clearCodeError }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ code }
              validate={ this.validateCode }
              required/>
          </div>
          { hasCodeError &&
          <Caption className="getGiftForm-error" html={ I18n.t('getGift.form.errors.code') } />
          }
        </label>
      </form>
    )
  }
}
