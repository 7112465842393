import { Component } from 'preact'

import AUTH_JSON_HEADERS from 'authJsonHeaders'
import jsonToSoviet from 'lib/soviets/jsonToSoviet'

import { Heading1 } from '../textNodes'
import SovietTitleWithMeta from '../sovietTitleWithMeta'
import { NoNewCommentsIcon } from '../icons'

import DoubleHover from '../../assets/scripts/doubleHover'

const FIRST_BATCH_AMOUNT = 50
const FETCH_INTERVAL = 3000

export default class SovietsWithNewComments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      soviets: [],
      isLoaded: false,
    }

    this.fetchSovietsWithNewComments = this.fetchSovietsWithNewComments.bind(this)
  }

  fetchSovietsWithNewComments(limit = 0, offset = 0) {
    const limitParam = limit ? `&limit=${limit}` : ''
    const offsetParam = offset ? `&offset=${offset}` : ''
    const url = `/soviet/advices?scope=with_new_comments${limitParam}${offsetParam}`

    return fetch(url, { headers: AUTH_JSON_HEADERS })
      .then(res => res.json())
      .then(json => json.map(jsonToSoviet))
      .then(soviets => this.setState({ soviets, isLoaded: true }))
      .then(() => this.fetchTimeout = setTimeout(this.fetchSovietsWithNewComments, FETCH_INTERVAL))
      .catch(e => console.error(e))
  }

  componentDidMount() {
    this.fetchSovietsWithNewComments(FIRST_BATCH_AMOUNT)
  }

  componentDidUpdate() {
    $('.doubleHover').each(function() { new DoubleHover($(this)) })
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimeout)
  }

  renderSovietsList() {
    const hasAnyCovers = this.state.soviets.some(soviet => soviet.coverUrl)

    return this.state.soviets.map(soviet => {
      return (
        <SovietTitleWithMeta
          key={ soviet.key }
          soviet={ soviet }
          withCover={ hasAnyCovers }
          isSans
          withTags
          withCommentsCount
        />
      )
    })
  }

  render() {
    return (
      <div className="sovietsWithNewComments">
        <Heading1 className="sovietsWithNewComments-pageTitle webpage-title is__medium is__wide">
          Советы с новыми комментариями
        </Heading1>

        { this.state.soviets.length > 0 && this.state.isLoaded &&
          this.renderSovietsList()
        }

        { this.state.soviets.length === 0 && this.state.isLoaded &&
          <div className="sovietsWithNewComments-placeholder">
            <NoNewCommentsIcon />
            Все комментарии разобраны.
            <br />
            Офигенно!
          </div>
        }
      </div>
    )
  }
}
