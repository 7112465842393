import { Component } from 'preact'
import cx from 'classnames'

import { schoolEnrollEndedSpacer as config } from './schoolSpacersConfig'
import { fetchSchoolData, getSchoolLink } from './schoolSpacerUtils'
import { getStoredEmail, subscribeUser } from '../subscriptionForm/emailUtils'
import { birmanize } from 'lib/textHelper'

import { TextNode } from '../textNodes'
import LinkWithArrow from '../linkWithArrow'
import SchoolSpacerTitle from './schoolSpacerTitle'
import EmailForm from '../emailForm/emailForm'
import SchoolSpacerIcon from './schoolSpacerIcon'

const ICONS_MAP = {
  designers: 'designersFunicularIcon',
  editors: 'editorsEscalatorIcon',
  managers: 'managersCartIcon',
}

const EVENT_ID = 1

export default class SchoolEnrollEndedSpacer extends Component {
  constructor(props) {
    super(props)

    const { email, isSubscribed } = getStoredEmail(props.eventType)

    this.state = { noReturnDate: '', email, isSubscribed }

    this.isFull = props.size === 'full'
    this.isHalf = props.size === 'half'
    this.schoolLink = getSchoolLink(props.schoolKey)
    this.hasKey = props.schoolKey

    this.subscribe = this.subscribe.bind(this)
  }

  componentDidMount() {
    fetchSchoolData()
      .then(json => this.setState({ ...json }))
      .catch(e => console.error(e))
  }

  subscribe({ email }) {
    return subscribeUser({ email, eventType: this.props.eventType, eventId: EVENT_ID })
      .then(isSubscribed => this.setState({ isSubscribed }))
  }

  render(props) {
    const configKey = props.schoolKey || 'default'
    const classes = cx(
      'schoolEnrollEndedSpacer',
      {
        is__full: this.isFull,
        is__half: this.isHalf,
        is__default: !this.hasKey,
        [`is__${props.schoolKey}`]: this.hasKey,
      }
    )
    const desktopTitlePart = `Занятия с ${birmanize(this.state.noReturnDate, { format: 'dateOnly' })}`
    const desktopTitle = `${config.title[configKey]}<span class="device desktop">. ${desktopTitlePart}</span>`
    const text = !props.schoolKey.length ? config.textDefault : config.text

    return (
      <div className={ classes }>
        <div className="device laptop desktop">
          <div className="schoolEnrollEndedSpacer-cols">
            { props.schoolKey &&
              <SchoolSpacerIcon iconKey={ ICONS_MAP[props.schoolKey] } href={ this.schoolLink } />
            }
            <div className="schoolEnrollEndedSpacer-text">
              <SchoolSpacerTitle href={ this.schoolLink } title={ desktopTitle } />
              <div className="schoolEnrollEndedSpacer-undertitle">
                <div className="schoolEnrollEndedSpacer-about">
                  <TextNode>
                    <span
                      dangerouslySetInnerHTML={ { __html: text } }
                    /> { this.hasKey && <LinkWithArrow href={ this.schoolLink } text="Программа" isInline isDoubleHover /> }
                  </TextNode>
                </div>
                <EmailForm
                  email={ this.state.email }
                  onSubmit={ this.subscribe }
                  className="schoolEnrollEndedSpacer-form"
                  isSubscribed={ this.state.isSubscribed }
                  thinButton
                />
              </div>
            </div>
          </div>
        </div>

        <div className="device mobile">
          <div className="schoolEnrollOpenSpacer-cols">
            { props.schoolKey &&
              <SchoolSpacerIcon iconKey={ ICONS_MAP[props.schoolKey] } href={ this.schoolLink } />
            }
            <SchoolSpacerTitle href={ this.schoolLink } title={ config.title[configKey] } />
          </div>
          <TextNode>
            <span
              dangerouslySetInnerHTML={ { __html: text } }
            /> { this.hasKey && <LinkWithArrow href={ this.schoolLink } text="Программа" isInline isDoubleHover /> }
          </TextNode>
          <EmailForm
            email={ this.state.email }
            onSubmit={ this.subscribe }
            className="schoolEnrollEndedSpacer-form"
            isSubscribed={ this.state.isSubscribed }
            thinButton
          />
        </div>
      </div>
    )
  }
}
