import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { Cols } from '../cols'

import CompilationCategories from 'lib/current/compilationCategories'
import { setCategories } from './sovietCompilationActions'

class SovietCompilationCategories extends Component {
  constructor(props) {
    super(props)

    this.categories = CompilationCategories
      .all()
      .filter(category => category.tags.length)
      .concat(CompilationCategories.sunk)

    this.state = {
      isCustom: props.categories && props.categories.length > 0,
    }

    this.handleManualAssign = this.handleManualAssign.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
  }

  handleManualAssign() {
    this.setState({ isCustom: !this.state.isCustom })
  }

  handleCategoryChange(event) {
    const category = event.target.value

    this.props.setCategories([category])
  }

  render(props) {
    const currentCategory = ((props.categories || [])[0] || {})

    const selectedCategoryTitle = currentCategory || props.mainColumn

    return (<Cols className="formRow compilationCategories" divisionProportions="2:4:5">
      <div className="formLabel">
        <label htmlFor="compilationCategories">Рубрика</label>
      </div>

      <div>
        <select
          name="compilationCategories"
          disabled={ !this.state.isCustom }
          onChange={ this.handleCategoryChange }>
          { this.categories.map(category => (
            <option
              value={ category.title }
              selected={ category.title === selectedCategoryTitle }>
              { category.title }
            </option>)
          )}
        </select>

        <div className="checkboxLabel">
          <label>
            <input type="checkbox"
              checked={ this.state.isCustom }
              onChange={ this.handleManualAssign } />
            Задать вручную
          </label>
        </div>
      </div>
      <div />
    </Cols>)
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    mainColumn: sovietCompilation.mainColumn,
    categories: sovietCompilation.categories,
  }
}

const mapDispatchToProps = {
  setCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationCategories)
