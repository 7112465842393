export const spacerFonts = {
  austin: {
    name: 'Austin',
    fileName: 'austin-cy.woff2',
  },
  rightGrotesk: {
    name: 'Right Grotesk',
    fileName: 'right-grotesk-tall-fine.woff2',
  },
  gilroy: {
    name: 'Gilroy',
    fileName: 'gilroy-black.woff2',
  },
  bebasNeue: {
    name: 'Bebas Neue',
    fileName: 'bebas-neue-book.woff2',
  },
  drukXCondensed: {
    name: 'Druk XCondensed',
    fileName: 'druk-xcondensed-cyr-super-italic.woff2',
  },
}
