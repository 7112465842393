import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import { Caption } from '../textNodes'
import { isOnMobile } from 'lib/envHelpers'

import { changeBasementItem, closeBasement } from './newPaybarActions'
import isSubscriptionCancelled from './isSubscriptionCancelled'
import isEmployeeSubscription from './isEmployeeSubscription'
import isCompanyOwnerSubscription from './isCompanyOwnerSubscription'
import hasBasementCancellationSupport from './helpers/hasBasementCancellationSupport'
import getLicenseUrl from './helpers/getLicenseUrl'

const BasementSwitcherItem = ({ item, isActive, onClick, product }) => {
  const classNames = cx('newPaybarBasementSwitcher-item no-u', { is__active: isActive })
  const title = I18n.t(`newPaybar.links.${product}.${item}`)
  const licenseHref = getLicenseUrl(product)

  if (item === 'license') {
    return (<a href={ licenseHref } className={ classNames } onClick={ onClick }>{ title }</a>)
  }

  return (
    <button className={ classNames } onClick={ onClick } type="button">
      { title }
    </button>
  )
}

class NewPaybarBasementSwitcher extends Component {
  get subscriptionItemToOpen() {
    const { subscription } = this.props

    if (isSubscriptionCancelled(subscription)) return 'lastChance'
    if (isCompanyOwnerSubscription(subscription)) return 'cancellationText'

    return 'cancellation'
  }

  onChange(item, event) {
    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) return true

    event.preventDefault()

    if (!isOnMobile() && item === this.props.activeBasementItem) {
      this.props.closeBasement()
    } else {
      this.props.changeBasementItem(item)
    }

    return false
  }

  render() {
    const { classNames, type, product, subscription, activeBasementItem } = this.props

    const isGift = type === 'gift'

    const canCancelSubscription = !!subscription.id && !isEmployeeSubscription(subscription) &&
      hasBasementCancellationSupport(product)
    const shouldDisplayCancellationItem = !isGift &&
      (canCancelSubscription || isSubscriptionCancelled(subscription))

    const switcherClassNames = cx('newPaybarBasementSwitcher', classNames)

    return (
      <Caption className={ switcherClassNames }>
        <BasementSwitcherItem
          item="license"
          isActive={ activeBasementItem === 'license' }
          onClick={ this.onChange.bind(this, 'license') }
          product={ product }/>

          { shouldDisplayCancellationItem &&
            <BasementSwitcherItem
              item="cancellation"
              isActive={ ['cancellation', 'cancellationText', 'lastChance'].includes(activeBasementItem) }
              onClick={ this.onChange.bind(this, this.subscriptionItemToOpen) }
              product={ product }/>
          }
      </Caption>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => ({
  type: newPaybar.type,
  product: newPaybar.product,
  subscription: newPaybar.subscription,
  activeBasementItem: newPaybar.activeBasementItem,
})

const mapDispatchToProps = { changeBasementItem, closeBasement }

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarBasementSwitcher)
