const Template1Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,15v2H5V15ZM5,22H38V20H5Zm0,5H26V25H5Z"/></svg>)
const Template2Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,15v2H5V15ZM5,22H38V20H5Zm6,5H32V25H11Z"/></svg>)
const Template3Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,14v2H5V14ZM11,21H32V19H11Z"/><path class="icon-text" d="M38,25v1H5V25ZM9,29H34V28H9Z"/></svg>)
const Template4Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M36,8v2H7V8ZM11,15H32V13H11Z"/><path class="icon-text" d="M7,19H20v1H7ZM7,29H20V28H7ZM23,19v1H36V19Zm0,7H36V25H23ZM7,23H20V22H7Zm0,9H20V31H7Zm16-9H36V22H23Zm0,6H36V28H23Zm0,3H36V31H23ZM7,26H20V25H7Zm0,9H20V34H7Zm16,0H36V34H23Z"/></svg>)
const Template5Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,8v2H12V8ZM12,15H32V13H12Z"/><path class="icon-text" d="M12,19H23v1H12Zm0,10H23V28H12ZM27,19v1H38V19Zm0,7H38V25H27ZM12,23H23V22H12Zm0,9H23V31H12Zm15-9H38V22H27Zm0,6H38V28H27Zm0,3H38V31H27ZM12,26H23V25H12Zm0,9H23V34H12Zm15,0h7V34H27Z"/></svg>)
const Template6Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,8v2H12V8ZM12,15H32V13H12Z"/><path class="icon-text" d="M38,19v1H12V19ZM12,29H38V28H12Zm0-6H38V22H12Zm0,9H38V31H12Zm0-6H38V25H12Zm0,9H30V34H12Z"/></svg>)
const Template7Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M31,8v2H5V8ZM5,15H25V13H5Z"/><path class="icon-text" d="M31,19v1H5V19ZM5,29H31V28H5Zm0-6H31V22H5Zm0,9H31V31H5Zm0-6H31V25H5Zm0,9H23V34H5Z"/></svg>)
const Template8Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M31,8v2H5V8ZM5,15H25V13H5Z"/><path class="icon-text" d="M38,19v1H5V19ZM5,29H38V28H5Zm0-6H38V22H5Zm0,9H38V31H5Zm0-6H38V25H5Zm0,9H30V34H5Z"/></svg>)
const Template9Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M19,8v2H5V8ZM5,15H19V13H5Zm0,5H15V18H5Z"/><path class="icon-text" d="M38,8V9H23V8ZM23,18H38V17H23Zm0,9H38V26H23Zm0-15H38V11H23Zm0,9H38V20H23Zm0,9H38V29H23Zm0-15H38V14H23Zm0,9H38V23H23Zm0,9H34V32H23Z"/></svg>)
const Template10Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M38,8V9H5V8ZM5,18H38V17H5Zm0,9H38V26H5ZM5,12H38V11H5Zm0,9H38V20H5Zm0,9H38V29H5ZM5,15H38V14H5Zm0,9H38V23H5Zm0,9H30V32H5Z"/></svg>)
const Template11Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M12,8H23V9H12Zm0,10H23V17H12Zm0,9H23V26H12ZM27,8V9H38V8Zm0,7H38V14H27ZM12,12H23V11H12Zm0,9H23V20H12Zm0,9H23V29H12ZM27,12H38V11H27Zm0,6H38V17H27Zm0,9H38V26H27Zm0-6H38V20H27Zm0,9H38V29H27ZM12,15H23V14H12Zm0,9H23V23H12Zm0,9H23V32H12Zm15-9H38V23H27Zm0,9h7V32H27Z"/></svg>)
const Template12Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,28v2H5V28ZM5,35H30V33H5Z"/><path class="icon-text" d="M38,24H5V8H38ZM6,23H37V9H6Z"/></svg>)
const Template13Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><rect class="icon-title" x="12" y="23" width="26" height="2"/><path class="icon-text" d="M5,8V19H38V8ZM37,18H6V9H37ZM12,28H38v1H12Zm0,3H38v1H12Zm0,3H30v1H12Z"/></svg>)
const Template14Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M20,8v2H5V8ZM5,15H15V13H5Z"/><path class="icon-text" d="M24,8V35H38V8ZM37,34H25V9H37ZM5,19H20v1H5Zm0,9H20v1H5Zm0-6H20v1H5Zm0,9H20v1H5Zm0-6H20v1H5Zm0,9H16v1H5Z"/></svg>)
const Template15Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,8v2H23V8ZM23,15H33V13H23Z"/><path class="icon-text" d="M5,35H19V8H5ZM6,9H18V34H6ZM38,19v1H23V19ZM23,28H38v1H23Zm0-6H38v1H23Zm0,9H38v1H23Zm0-6H38v1H23Zm0,9H34v1H23Z"/></svg>)
const Template16Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M26,8v2H5V8ZM5,15H21V13H5Z"/><path class="icon-text" d="M30,8V35h8V8Zm7,26H31V9h6ZM5,19H26v1H5Zm0,9H26v1H5Zm0,6H18v1H5ZM5,22H26v1H5Zm0,9H26v1H5Zm0-6H26v1H5Z"/></svg>)
const Template17Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M5,28H38v1H5ZM38,8V25H5V8ZM37,9H6V24H37ZM5,32H38V31H5Zm0,3H30V34H5Z"/></svg>)
const Template18Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M38,8H5V25H38ZM37,24H6V9H37ZM12,28H23v1H12Zm26,0v1H27V28ZM27,34h7v1H27ZM12,31H23v1H12Zm15,0H38v1H27ZM12,34H23v1H12Z"/></svg>)
const Template19Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M5,23H19v2H5Zm0,7H15V28H5Z"/><path d="M5,8V19H38V8ZM37,18H6V9H37ZM23,23H38v1H23Zm0,3H38v1H23Zm0,3H38v1H23Zm0,3H38v1H23Zm0,3H34v1H23Z" class="icon-text"/></svg>)
const Template20Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M5,23h7v2H5Zm0,7h7V28H5Z"/><path d="M5,8V19H38V8ZM37,18H6V9H37ZM16,23h9v1H16Zm0,3h9v1H16Zm0,3h9v1H16Zm0,3h9v1H16Zm0,3h9v1H16ZM29,23h9v1H29Zm0,3h9v1H29Zm0,3h9v1H29Zm0,3h9v1H29Zm0,3h7v1H29Z" class="icon-text"/></svg>)
const Template21Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M38,14v2H5V14ZM5,21H26V19H5Z"/><path d="M38,25v1H5V25ZM5,29H30V28H5Z" class="icon-text"/></svg>)
const Template22Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M19,35H5V8H19ZM6,34H18V9H6Z"/><path d="M23,8H38v2H23Zm0,10H38v2H23Zm0-5H38v2H23Zm0,10H33v2H23Z" class="icon-title"/></svg>)
const Template23Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M19,35H5V8H19ZM6,34H18V9H6ZM23,8H38V9H23Zm0,3H38v1H23Zm0,3H38v1H23Zm0,3H38v1H23Zm0,3H38v1H23Zm0,3H38v1H23Zm0,3H38v1H23Zm0,3H34v1H23Z"/></svg>)
const Template24Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text"d="M38,35H24V8H38ZM25,34H37V9H25Z"/><path d="M5,8H20v2H5ZM5,18H20v2H5Zm0-5H20v2H5ZM5,23H15v2H5Z" class="icon-title"/></svg>)
const Template25Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M38,35H24V8H38ZM25,34H37V9H25ZM5,8H20V9H5Zm0,3H20v1H5Zm0,3H20v1H5Zm0,3H20v1H5Zm0,3H20v1H5Zm0,3H20v1H5Zm0,3H20v1H5Zm0,3H16v1H5Z"/></svg>)
const Template26Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M19,35H5V8h14V35z M6,34h12V9H6V34z"/><path class="icon-text" d="M23,18h15v0.5H23V18z M23,20.5h15V20H23V20.5z M23,22.5h15V22H23V22.5z M23,16.5h15V16H23V16.5z M23,14.5h15V14 H23V14.5z M23,8v0.5h15V8H23z M23,10.5h15V10H23V10.5z M23,12.5h15V12H23V12.5z M23,34.5h11V34H23V34.5z M23,24.5h15V24H23V24.5z M23,26.5h15V26H23V26.5z M23,28.5h15V28H23V28.5z M23,30.5h15V30H23V30.5z M23,32.5h15V32H23V32.5z"/></svg>)
const Template27Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M38,35H24V8h14V35z M25,34h12V9H25V34z"/><path class="icon-text" d="M5,18h15v0.5H5V18z M5,20.5h15V20H5V20.5z M5,22.5h15V22H5V22.5z M5,16.5h15V16H5V16.5z M5,14.5h15V14H5V14.5z M5,8v0.5h15V8H5z M5,10.5h15V10H5V10.5z M5,12.5h15V12H5V12.5z M5,34.5h11V34H5V34.5z M5,24.5h15V24H5V24.5z M5,26.5h15V26H5V26.5z M5,28.5h15V28H5V28.5z M5,30.5h15V30H5V30.5z M5,32.5h15V32H5V32.5z"/></svg>)
const Template28Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-text" d="M5 8V35H13V8H5ZM12 34H6V9H12V34Z"/><path class="icon-title" d="M38 8H17V10H38V8ZM38 13H17V15H38V13ZM17 18H38V20H17V18ZM33 23H17V25H33V23Z"/></svg>)
const Template29Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M39 8H18V10H39V8ZM34 13H18V15H34V13Z"/><path class="icon-text" d="M6 35V8H14V35H6ZM7 34H13V9H7V34ZM18 19H39V20H18V19ZM18 22H39V23H18V22ZM39 25H18V26H39V25ZM18 28H39V29H18V28ZM39 31H18V32H39V31ZM18 34H31V35H18V34Z"/></svg>)
const Template30Icon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M5 8H26V10H5V8ZM5 13H26V15H5V13ZM26 18H5V20H26V18ZM5 23H21V25H5V23Z"/><path class="icon-text" d="M30 8V35H38V8H30ZM37 34H31V9H37V34Z"/></svg>)

module.exports = {
  headerTemplate1: Template1Icon,
  headerTemplate2: Template2Icon,
  headerTemplate3: Template3Icon,
  headerTemplate4: Template4Icon,
  headerTemplate5: Template5Icon,
  headerTemplate6: Template6Icon,
  headerTemplate7: Template7Icon,
  headerTemplate8: Template8Icon,
  headerTemplate9: Template9Icon,
  headerTemplate10: Template10Icon,
  headerTemplate11: Template11Icon,
  headerTemplate12: Template12Icon,
  headerTemplate13: Template13Icon,
  headerTemplate14: Template14Icon,
  headerTemplate15: Template15Icon,
  headerTemplate16: Template16Icon,
  headerTemplate17: Template17Icon,
  headerTemplate18: Template18Icon,
  headerTemplate19: Template19Icon,
  headerTemplate20: Template20Icon,
  headerTemplate21: Template21Icon,
  headerTemplate22: Template22Icon,
  headerTemplate23: Template23Icon,
  headerTemplate24: Template24Icon,
  headerTemplate25: Template25Icon,
  headerTemplate26: Template26Icon,
  headerTemplate27: Template27Icon,
  headerTemplate28: Template28Icon,
  headerTemplate29: Template29Icon,
  headerTemplate30: Template30Icon,
}
