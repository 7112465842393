import RealtimeCountdown from '../realtimeCountdown/realtimeCountdown'

const StickyButtonCountdown = ({ to }) => (
  <span>
    × <span className="stickyButton-countdown">
      <RealtimeCountdown to={ to } isTextOnly/>
    </span>
  </span>
)

export default StickyButtonCountdown
