export const EXPIRED_TIME = { days: 0, hours: 0, minutes: 0, seconds: 0 }

export const getTimeLeft = ({ from, to, noDays }) => {
  const fromDate = new Date(from)
  const toDate = new Date(to)

  if (fromDate >= toDate) return EXPIRED_TIME

  const diff = (toDate.getTime() - fromDate.getTime()) / 1000
  const returnHours = noDays ? Math.floor(diff / 60 / 60) : Math.floor((diff / 60 / 60) % 24)

  return {
    days: Math.floor(diff / 60 / 60 / 24),
    hours: returnHours,
    minutes: Math.floor((diff / 60) % 60),
    seconds: Math.floor(diff % 60),
  }
}

export const padDigit = (digit) => {
  return String(digit).padStart(2, '0')
}
