import { Component } from 'preact'
import cx from 'classnames'

import { LabelTitleLink } from '../labelTitleLink'
import { Image } from '../image'
import { Cols } from '../cols'
import { typograf, pluralize } from 'lib/textHelper'
import hasValidCover from './hasValidCover'
import Heading2 from '../textNodes/heading2'

const SovietImage = (props) => {
  const imageClassnames = cx('module', 'doubleHover', { is__equalized: props.isEqualized })

  return (
    <a
      href={ props.href }
      className="compilationCell-cover sovietCover module doubleHover is__multiplied"
      style={ { backgroundColor: props.soviet.coverBgColor } }
    >
      <Image
        src={ props.coverUrl }
        size={ props.coverSize }
        href={ props.href }
        align="top"
        fillModule="true"
        className={ imageClassnames } />
    </a>
  )
}

const SovietLabelTitleLink = (props) => {
  const shouldOmitLabel = (props.mainColumn === props.column) || props.skipColumn
  const label = shouldOmitLabel ? '' : props.column

  return (
    <LabelTitleLink
      className={ cx({ doubleHover: hasValidCover(props) }) }
      label={ label }
      title={ props.title }
      linkText={ props.linkText }
      linkSuffix={ props.linkSuffix }
      href={ props.href }
      size={ props.size }
      align={ props.align } />
  )
}

class FullCell extends Component {
  render(props) {
    const hasCover = hasValidCover(props)
    const classNames = cx(
      'compilationRow',
      'compilationCell',
      'is__full',
      {
        is__reversed: props.isReversed,
        is__hyphenated: hasCover,
        has__cover: hasCover,
        has__noCover: !hasCover,
      },
      props.className.split(' ')
    )

    if (hasCover) {
      return (
        <Cols
          divisionProportions="12:4"
          className={ classNames }
          transposeOnMobile="true">
          <SovietImage { ...props } />
          <SovietLabelTitleLink { ...props } />
        </Cols>
      )
    }

    return (
      <div className={ classNames }>
        <SovietLabelTitleLink { ...{ ...props, ...{ size: 'large' } } } />
      </div>
    )
  }
}

class HalfCell extends Component {
  render(props) {
    if (hasValidCover(props)) {
      return (
        <div className={`compilationCell has__cover ${props.className}`}>
          <SovietImage { ...props } />
          <SovietLabelTitleLink { ...{ ...props, ...{ size: 'small', align: '' } } } />
        </div>
      )
    }

    return (
      <div className={`compilationCell is__hyphenated has__noCover ${props.className}`}>
        <SovietLabelTitleLink { ...{ ...props, ...{ size: props.size } } } />
      </div>
    )
  }
}

class TagsCell extends Component {
  render() {
    if (!window.soviet || !window.soviet.relatedTags) return null

    const tagColors = window.soviet.tagColors
    const tags = window.soviet.relatedTags
    const className = cx(
      'sovietTagsCell-list',
      'is__special',
      `has__${tags.length}Tags`
    )

    return (
      <div className="compilationCell sovietTagsCell">
        <Heading2>{ I18n.t('soviet.relatedTags') }</Heading2>

        <ul className={ className }>
          {
            tags.map((tag) => {
              const tagStyle = { backgroundColor: tagColors[tag.name.replace(/[\s]/g, ' ')] }

              return (
                <li className="sovietTagsCell-tag">
                  <a
                    className="sovietTagsCell-link"
                    href={ `/soviet/${tag.alias || tag.slug}/` } style={ tagStyle }>
                    <span className="sovietTagsCell-text">
                      { typograf(tag.name) }
                    </span>
                    <span className="sovietTagsCell-sovietsAmount">
                      { tag.usage }
                      <span className="sovietTagsCell-sovietsAmountCaption">
                        { ' ' + pluralize(tag.usage, 'совет', 'совета', 'советов') }
                      </span>
                    </span>
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

export { HalfCell, FullCell, TagsCell }
