import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import SovietCompilationCoverUploader from './sovietCompilationCoverUploader'

const CoverContainer = (props) => {
  return (<div className="compilationCover">{ props.children }</div>)
}

const Cover = (props) => {
  if (props.nonRetinaCoverUrl) {
    return (
      <CoverContainer>
        <div className="compilationCover-image image"
          data-src={ props.coverUrl }
          data-nonretina-src={ props.nonRetinaCoverUrl }>
          { props.children }
        </div>
      </CoverContainer>
    )
  }

  return (
    <CoverContainer>
      <div className="compilationCover-image image is__fillModule">
        <img className="image-img" src={ props.coverUrl }/>
        <div className="image-in">
          { props.children }
        </div>
      </div>
    </CoverContainer>
  )
}

class SovietCompilationCover extends Component {
  render(props) {
    if (props.isReadOnly) return (<Cover { ...props } />)

    return (
      <div>
        <Cover { ...props } />
        <SovietCompilationCoverUploader />
      </div>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    coverUrl: sovietCompilation.coverUrl,
    nonRetinaCoverUrl: sovietCompilation.nonRetinaCoverUrl,
    isReadOnly: sovietCompilation.isReadOnly,
  }
}


export default connect(mapStateToProps)(SovietCompilationCover)
