import { Component } from 'preact'
import { connect } from 'preact-redux'

import { detectPaybarFooterCaption } from './variants'

import expandScreenToFlags from './expandScreenToFlags'

class NewPaybarFooterCaption extends Component {
  render(props) {
    const NewPaybarFooterCaptionProduct = detectPaybarFooterCaption(props.product)

    return (
      <NewPaybarFooterCaptionProduct { ...props } />
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  const screenFlags = expandScreenToFlags(newPaybar.screen)
  const showShelfPreorderCaption = newPaybar.isShelfPreorder && screenFlags.isSubscriptionScreen

  return {
    subscription: newPaybar.subscription,
    product: newPaybar.product,
    type: newPaybar.type,
    isPreorder: newPaybar.isPreorder || showShelfPreorderCaption,
    cannotRead: newPaybar.cannotRead,
    noticeType: newPaybar.noticeType,
    ...screenFlags,
  }
}

export default connect(mapStateToProps)(NewPaybarFooterCaption)
