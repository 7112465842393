const NO_SAVE_CLASS = 'is__noSave'
const NO_SAVE_POSITION_CLASS = 'is__noSavePosition'
const NO_SAVE_SPEED_CLASS = 'is__noSaveSpeed'

module.exports = klass => {
  const classes = (klass + '').split(' ')
  const shouldSaveProgress = !classes.includes(NO_SAVE_CLASS) &&
    !classes.includes(NO_SAVE_POSITION_CLASS)
  const shouldSavePlaybackRate = !classes.includes(NO_SAVE_CLASS) &&
    !classes.includes(NO_SAVE_SPEED_CLASS)

  return { shouldSaveProgress, shouldSavePlaybackRate }
}
