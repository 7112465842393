const ALLOWED_TAGS_WITHOUT_HREF = ['A', 'BUTTON']

$('.js__leadMagnet').each(function() {
  const $el = $(this)
  const options = JSON.parse($el.attr('data-options')) || {}
  const hasHrefOption = !!options.href
  const href = options.href || $el.find('[href]').attr('href')
  const leadMagnetId = options.id || ''

  $el.on('click', function(e) {
    const currentHref = $el.find('[href]').attr('href')

    if (!hasHrefOption && href !== currentHref) return
    if (!hasHrefOption && !ALLOWED_TAGS_WITHOUT_HREF.includes(e.target.tagName)) return

    e.preventDefault()

    const suffix = leadMagnetId ? `:${leadMagnetId}` : ''
    $(document).trigger(`leadMagnetShow${suffix}`)
  })
})
