import cx from 'classnames'

import { Image } from '../image'

const ImacScreenshot = (props) => {
  const size = [396, 230]
  const maskId = 'id-' + Date.now() + Math.random()
  const clipPathStyle = `clip-path: url(#${maskId});`
  const screenshotClasses = cx(
    'screenshot',
    'media',
    'module',
    props.className
  )

  return (
    <div className={ screenshotClasses }>
      <div class="browser is__imac module">
        <div class="module browser-wrapper">
          <div class="browser-top">
            <svg class="browser-mask" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
              <defs>
                <clipPath id={ maskId }>
                  <rect x="0.5" y="0.5" width="100%" height="100%" rx="2.52%" fill="#fff"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>

          <div class="module browser-viewport">
            <div class="module browser-frame" style={ clipPathStyle }>
              <div class="module browser-content is__fitToContent">
                <Image src={ props.src } size={ size } />
              </div>
            </div>
          </div>

          <div class="browser-bottom">
            <svg class="browser-monitor" xmlns="http://www.w3.org/2000/svg" width="396" height="35" viewBox="-0.3 0 396 35">
              <path d="M396 0V21.1396C396 23.6325 396 25.5271 395.9 27.0228C395.8 28.5185 395.5 29.5157 395.1 30.4131C394.3 32.0085 393 33.3048 391.4 34.1026C390.5 34.5014 389.5 34.8006 388 34.9003C386.5 35 384.6 35 382.1 35H13.9C11.4 35 9.5 35 8 34.9003C6.5 34.8006 5.5 34.5014 4.6 34.1026C3 33.3048 1.7 32.0085 0.9 30.4131C0.5 29.5157 0.2 28.4188 0.1 26.9231C2.24083e-08 25.4273 0 23.5328 0 21.0399V0"></path>
            </svg>
            <svg class="browser-basement" height="44" width="130" viewBox="0 0 130 44" xmlns="http://www.w3.org/2000/svg">
              <path d="M120.707 41.1066C113.013 38.712 107.517 32.0272 106.718 24.0454L104.22 0H25.6803L23.1822 24.0454C22.3828 32.0272 16.887 38.712 9.19293 41.1066L0 44H25.6803H104.32H130L120.707 41.1066Z"></path>
            </svg>
          </div>

        </div>
      </div>
    </div>


  )
}

export default ImacScreenshot
