{
  class ParallaxFrame {
    constructor($el) {
      this.$frame = $el
      this.$objects = this.$frame.find('.js__parallaxObject')
      this.$document = $(document)

      this.bindEvents()
    }

    init() {
      this.scrollTop = window.pageYOffset
      this.frameHeight = this.$frame.innerHeight()

      const viewportHeight = window.application.viewHeight
      const frameOffset = this.$frame.offset().top
      const correction = frameOffset < viewportHeight ? viewportHeight - frameOffset : 0

      this.parallaxStart = Math.max(frameOffset - viewportHeight, 0)

      const fullScrollCycle = this.parallaxStart + viewportHeight + this.frameHeight
      this.parallaxEnd = Math.max(fullScrollCycle - correction, this.frameHeight)

      this.scrollSpan = this.parallaxEnd - this.parallaxStart

      this.collectObjects()
      this.setShifts()
    }

    bindEvents() {
      this.$document.on('appReady appResize', this.init.bind(this))

      this.$document.on('appScroll', (_, scrollTop) => {
        this.scrollTop = scrollTop
        this.setShifts()
      })
    }

    collectObjects() {
      this.objects = this.$objects
        .map((_, el) => {
          const $el = $(el)

          return {
            $el,
            maxShift: $el.innerHeight() - this.frameHeight,
          }
        })
        .get()
    }

    setShifts() {
      if (this.scrollTop < this.parallaxStart) return
      if (this.scrollTop >= this.parallaxEnd) return

      const scrolledFraction = (this.scrollTop - this.parallaxStart) / this.scrollSpan

      this.objects.forEach((object) => {
        object.$el.css({ transform: `translateY(-${object.maxShift * scrolledFraction}px)` })
      })
    }
  }

  $('.js__parallaxFrame').each(function() {
    new ParallaxFrame($(this))
  })
}
