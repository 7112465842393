import Impressions from './impressions'

{
  $('.js__impressions').each(function() {
    const data = JSON.parse(decodeURIComponent($(this).data('props')))

    $(this).reactify(Impressions, data)
    $(this).children().unwrap()
  })
}
