const Soviet = require('../soviet')
const camelize = require('../camelize')

const { typograf } = require('../textHelper')

module.exports = json => {
  const sovietJson = Object.assign(
    {},
    json,
    {
      title: typograf(json.title),
      compiled_title: typograf(json.compiled_title),
    }
  )
  return Soviet.fromJSON(camelize(sovietJson))
}
