{
  const CLASS_STICKY = 'is__sticky'
  const CLASS_AFTER_STICKY_HEADER = 'is__afterStickyHeader'

  class StickyHeader {
    constructor($el) {
      this.$el = $el
      this.$nextEl = $el.next()
      this.$document = $(document)
      this.height = this.$el.outerHeight()

      this.updateStickiness = this.updateStickiness.bind(this)

      this.setListeners()
      this.addMarginForNextElement()
    }

    setListeners() {
      // HACK: use timeout to calculate getBoundingClientRect correctly on reload in Safary
      this.$document
        .on('appScroll appResize', this.updateStickiness)
        .on('appReady', () => setTimeout(this.updateStickiness, 100))
    }

    addMarginForNextElement() {
      this.$nextEl.addClass(CLASS_AFTER_STICKY_HEADER)
    }

    updateStickiness() {
      const isSticky = this.height >= this.$nextEl[0].getBoundingClientRect().top

      this.$el.toggleClass(CLASS_STICKY, isSticky)
    }
  }

  $('.js__stickyHeader').each(function() {
    new StickyHeader($(this))
  })
}
