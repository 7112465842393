import cx from 'classnames'

import { schoolGraduatedSpacer as config } from './schoolSpacersConfig'

import SchoolSpacerTitle from './schoolSpacerTitle'
import { TextNode } from '../textNodes'
import LinkWithArrow from '../linkWithArrow'
import { LeftTrumpetIcon, RightTrumpetIcon } from '../icons'

const PROJECTS_LINK = '/school/projects/'

const SchoolGraduatedSpacer = (props) => {
  const classes = cx(
    'schoolGraduatedSpacer',
    {
      is__full: props.size === 'full',
      is__half: props.size === 'half',
    }
  )

  return (
    <div className={ classes }>
      <div className="schoolGraduatedSpacer-leftTrumpet">
        <LeftTrumpetIcon />
      </div>
      <div className="schoolGraduatedSpacer-rightTrumpet">
        <RightTrumpetIcon />
      </div>
      <SchoolSpacerTitle href={ PROJECTS_LINK } title={ props.title } />
      <TextNode className="schoolGraduatedSpacer-caption">
        <LinkWithArrow
          href={ PROJECTS_LINK }
          text={ config.caption }
          isInline
          isDoubleHover
          isNoVisited
          isWrap
        />
      </TextNode>
    </div>
  )
}

export default SchoolGraduatedSpacer
