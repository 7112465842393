import cx from 'classnames'

import { pluralize, birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../calendar/timezoneUtils'

import { Image } from '../image'
import { TextNode } from '../textNodes'
import Impressions from '../impressions/impressions'

const SovietTitleWithMetaCommentsCount = ({ commentsCount, newCommentsCount }) => {
  const newText = ` ${pluralize(newCommentsCount, 'новый', 'новых', 'новых')}`

  return (
    <div className="sovietTitleWithMeta-commentsCount">
      <nobr> 🗩
        { commentsCount > 0 &&
          commentsCount
        }
        { newCommentsCount > 0 &&
          <span>{ ` +${newCommentsCount}`}
            <span class="device desktop">{ newText }</span>
          </span>
        }
      </nobr>
    </div>
  )
}

const SovietTitleWithMeta = (props) => {
  const {
    url,
    title,
    authorGenitiveName,
    coverUrl,
    coverSize,
    commentsCount,
    newCommentsCount,
    date,
    impressionSubject,
    impressionSummary,
    tagSummaries,
    isUnicorn,
  } = props.soviet
  const hasTags = !!tagSummaries.length
  const hasAnyComments = !!commentsCount || !!newCommentsCount
  const withCover = props.withCover
  const decoratedUrl = !isUnicorn ? `${url}preview-comments` : url

  const tags = tagSummaries
    .map(tag => {
      return (
        <a key={ tag.slug } href={ `/soviet/${tag.alias || tag.slug}` } className="is__noUnderline">
          { tag.name }
        </a>
      )
    })

  return (
    <div className={ cx('module', 'sovietTitleWithMeta', { has__tags: hasTags }) }>
      { coverUrl && withCover &&
        <a href={ decoratedUrl } className="module sovietTitleWithMeta-coverWrapper sovietCover doubleHover is__noUnderline">
          <Image src={ coverUrl } size={ [coverSize.width, coverSize.height] } fillModule />
        </a>
      }

      { !coverUrl && withCover &&
        <div className="sovietTitleWithMeta-coverWrapper is__blank" />
      }

      <div className="module">
        <a href={ decoratedUrl } className="sovietTitleWithMeta-titleWrapper doubleHover is__noUnderline">
          <div className="sovietTitleWithMeta-title">
            { title }
            { hasAnyComments &&
              <SovietTitleWithMetaCommentsCount
                commentsCount={ commentsCount }
                newCommentsCount={ newCommentsCount } />
            }
          </div>
          { hasAnyComments &&
            <SovietTitleWithMetaCommentsCount
              commentsCount={ commentsCount }
              newCommentsCount={ newCommentsCount } />
          }
        </a>
        <div className="sovietTitleWithMeta-undertitle">
          <TextNode className="sovietTitleWithMeta-link">
            <a href={ decoratedUrl } className="doubleHover is__noUnderline">
              <span className="link">{ `Совет ${authorGenitiveName}` }</span>
              { ' · ' }
              { birmanize(moscowifyLocal(new Date(date)), { format: 'dateYearOnly' }) }
            </a>
          </TextNode>
          { hasTags &&
            <div className="sovietTitleWithMeta-tags">
              { tags }
            </div>
          }
          <Impressions
            mod="small"
            impressionSubject={ impressionSubject }
            helpful={ impressionSummary.helpful }
            useless={ impressionSummary.useless }
            selected={ impressionSummary.selected }
          />
        </div>
        { hasTags &&
          <div className="sovietTitleWithMeta-tags">
            { tags }
          </div>
        }
      </div>
    </div>
  )
}

export default SovietTitleWithMeta
