const MAX_AUTHOR_TAGS = 5
const MAX_RECENT_TAGS = 15

export const noop = () => {}

export const compose = (...fns) =>
  fns.reduce(
    (prevFn, nextFn) =>
      (...args) => nextFn(prevFn(...args)),
    value => value
  )


export const areTagsDifferent = tag1 => tag2 =>
  tag1.value.toLowerCase() !== tag2.value.toLowerCase()


export const pushUnique = (arr, el) =>
  [...arr.filter(areTagsDifferent(el)), el]


export const findSimilarTags = tag => allTags =>
  allTags.filter(_tag => _tag
    .toLowerCase()
    .match(new RegExp(`(^| )${tag.toLowerCase()}`, 'g')))


export const getAllTags = (firstNode, ...restNodes) => obj =>
  restNodes.reduce(
    (acc, next) => acc ? acc[next] : [],
    obj[firstNode]
  ) || []


export const tagsToStringArray = tags => tags.map(tag => tag.value)


export const stringArrayToTags = tags => tags.map(tag => ({ value: tag }))


export const omitAlreadySelected = selectedTags => allTags => {
  const selectedTagsValues = tagsToStringArray(selectedTags)
  return allTags.filter(tag => !selectedTagsValues.includes(tag))
}


export const isTagNew = (allTagsList, tag) => !allTagsList.includes(tag)


export const addTag = (currentValue, selectedTags, allTags) =>
  pushUnique(
    selectedTags,
    { isTagNew: isTagNew(allTags, currentValue), value: currentValue }
  )


export const sortTagsAlphabetically = tags =>
  tags.slice().sort((a, b) => a.localeCompare(b))


export const getFirstElements = n => arr => arr.slice(0, n)


const mergeWith = arr1 => arr2 => [...arr2, ...arr1]


export const getRecentSuggestionList = (allTags, author, selectedTags, related = []) => {
  const selectedAuthorTags = compose(
    getAllTags('byAuthor', author),
    omitAlreadySelected(selectedTags),
    getFirstElements(MAX_AUTHOR_TAGS)
  )(allTags)

  const relatedTags = related.filter(tag => !selectedAuthorTags.includes(tag))

  return compose(
    getAllTags('byAuthor', author),
    omitAlreadySelected(selectedTags),
    getFirstElements(MAX_AUTHOR_TAGS),
    mergeWith(relatedTags),
    omitAlreadySelected(selectedTags),
    getFirstElements(MAX_RECENT_TAGS)
  )(allTags)
}


export const getProperSuggestionsList = (allTags, currentValue, selectedTags) => {
  return compose(
    getAllTags('all'),
    findSimilarTags(currentValue),
    omitAlreadySelected(selectedTags),
    sortTagsAlphabetically
  )(allTags)
}
