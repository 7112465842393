import { urlToParamsArray, urlParamsArrayToStr } from 'urlHelpers'

const TYPES = ['upgrade', 'self', 'gift', 'company']
const LEAD_REG_EXP = /^contactForm[\d,_,\w]*/

const isAdditionalParam = type => !TYPES.includes(type) && !LEAD_REG_EXP.test(type)

function getParamsWithoutType(type = '') {
  let params = urlToParamsArray(window.location.search)

  if (!!type) return params.filter(({ name }) => (name !== type))

  params = params
    .filter(param => isAdditionalParam(param.name))

  return params
}

const toUrlWithParams = params => window.location.pathname + urlParamsArrayToStr(params)

export function setTypeToUrl(type) {
  const additinalParam = isAdditionalParam(type) ? type : ''
  const params = getParamsWithoutType(additinalParam)
  params.push({ name: type })

  window.history.replaceState({}, document.title, toUrlWithParams(params))
}

export function removeTypeFromUrl(type = '') {
  const params = getParamsWithoutType(type)

  window.history.replaceState({}, document.title, toUrlWithParams(params))
}

export function getTypeFromUrl() {
  const params = urlToParamsArray(window.location.search)
  const result = params.find(param => TYPES.includes(param.name) || LEAD_REG_EXP.test(param.name))

  return result ? result.name : null
}
