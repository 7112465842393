import { Component } from 'preact'
import DoubleHover from '../../assets/scripts/doubleHover'

import LectureSpacerFull from './lectureSpacerFull'
import LectureSpacerHalf from './lectureSpacerHalf'
import { lectureHref } from './lectureSpacerUtils'
import fetchProductPricing from 'lib/products/fetchPricing'

export default class LectureSpacer extends Component {
  constructor(props) {
    super(props)
    this.lectureKey = props.id.replace('lecture-', '')
    this.state = { prices: {} }
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate() {
    $('.doubleHover').each(function() { new DoubleHover($(this)) })
  }

  fetch() {
    fetchProductPricing(this.props.id)
      .then((json) => this.setState({
        ...this.state,
        prices: json,
        hasSpecialOffer: json.specialOffer && json.specialOffer.oneTime,
      }))
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  isReady() {
    return !!this.state.prices.oneTime
  }

  render() {
    if (!this.isReady()) return null

    const { size, id, content } = this.props
    const props = {
      ...this.state,
      content: content,
      href: lectureHref(id),
      lectureKey: this.lectureKey,
    }
    return (size === 'half' ?
      <LectureSpacerHalf { ...props } /> :
      <LectureSpacerFull { ...props } />
    )
  }
}
