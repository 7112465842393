//  by @enxaneta: https:// stackoverflow.com/a/60502958/12114682

function removeEmptyElements(array) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i] === '') {
      array.splice(i, 1)
    }
  }
  return array
}

function getPoints(poly) {
  //  poly is the polygon's element d attribute
  let polyPoints = poly
    .replace(/(\r?\n|\r|\t)+/g, '')
    .replace(/\-/g, ' -')
    .split(/[\s,]+/)
  polyPoints = removeEmptyElements(polyPoints)
  let points = []
  for (let i = 0; i < polyPoints.length; i += 2) {
    let temp = [Number(polyPoints[i]), Number(polyPoints[i + 1])]
    points.push(temp)
  }
  return points// // [[10, 40],[15, 45],[30, 70],[35, 75]]
}

function getAngle(c, l) {
  let deltaX = l.x - c.x
  let deltaY = l.y - c.y
  let a = Math.atan2(deltaY, deltaX)
  return a // in radians;
}

function polygonWithRoundedCorners(poly, r = 2.5) {
  let points = getPoints(poly)
  // move to the first point
  let d = `M${ points[0][0] },${ points[0][1] }`

  for (let i = 1; i < points.length - 1; i += 1) {
    let previous = i - 1
    let next = i + 1
    let c = {}// the control point
    c.x = points[i][0]
    c.y = points[i][1]
    let l1 = {}
    l1.x = points[previous][0]
    l1.y = points[previous][1]
    let l2 = {}
    l2.x = points[next][0]
    l2.y = points[next][1]
    let a1 = getAngle(c, l1)
    let a2 = getAngle(c, l2)

    // if great precision is needed remove .toFixed(3)
    // x1 and y1 are defining the start point of the Bézier
    let x1 = (c.x + r * Math.cos(a1)).toFixed(3)
    let y1 = (c.y + r * Math.sin(a1)).toFixed(3)
    // x2 and y2 are defining the end point of the Bézier
    let x2 = (c.x + r * Math.cos(a2)).toFixed(3)
    let y2 = (c.y + r * Math.sin(a2)).toFixed(3)

    d += `L${x1},${y1} Q${c.x},${c.y} ${x2},${y2}`
  }
  // move to the last point and return the d attribute
  return (d += `L${ points[points.length - 1][0] },${
    points[points.length - 1][1]
  }`)
}


export default polygonWithRoundedCorners
