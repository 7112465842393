import SubscriptionButton from './subscriptionButton'

{
  const initializeSubscriptionButton = (_, el) => {
    const subscription = window.productSubscription || {}
    const $el = $(el)

    const options = JSON.parse($el.attr('data-options') || null)
    const props = {
      ...subscription,
      ...options,
    }

    $el.reactify(SubscriptionButton, props)
  }

  $('.js__subscriptionButton').each(initializeSubscriptionButton)
}
