export function setVisible() {
  return { type: 'SET_VISIBLE' }
}

export function setHidden() {
  return { type: 'SET_HIDDEN' }
}

export function setScreen(screen) {
  return {
    type: 'SET_SCREEN',
    screen,
  }
}

export function switchToInitialScreen() {
  return { type: 'SWITCH_TO_INITIAL_SCREEN' }
}

export function subscribeSomeoneElse() {
  return { type: 'SUBSCRIBE_SOMEONE_ELSE' }
}

export function setUser(params) {
  return {
    type: 'SET_USER',
    ...params,
  }
}

export function revertPreviousUserState() {
  return { type: 'REVERT_PREVIOUS_USER_STATE' }
}

export function setUserEmailNotice({ notice, email }) {
  return {
    type: 'SET_USER_EMAIL_NOTICE',
    notice,
    email,
  }
}

export function setLoading() {
  return { type: 'SET_LOADING' }
}

export function setBasementLoading() {
  return { type: 'SET_BASEMENT_LOADING' }
}

export function setFormValidity({ isValid }) {
  return { type: 'SET_FORM_VALIDITY', isValid }
}

export function setCardFormValidity({ isValid }) {
  return { type: 'SET_CARD_FORM_VALIDITY', isValid }
}

const VERY_SPECIAL_ERROR_CODES = [
  'AMOUNT_EXCEED',
  'FOREIGN_ISSUER_BLOCKED_CARD',
  'FOREIGN_ISSUER_BLOCKED_CARD_COMPLETELY',
]

export function setPaymentFailed(e) {
  if (e && e.charge_failed) {
    const errorCode = e.charge_failed.error_code

    if (VERY_SPECIAL_ERROR_CODES.includes(errorCode)) return { type: `SET_PAYMENT_FAILED_${errorCode}` }
  }

  return { type: 'SET_PAYMENT_FAILED' }
}

export function setSubscriptionUpdateFailed() {
  return { type: 'SET_SUBSCRIPTION_UPDATE_FAILED' }
}

export function setSubscriptionCancellationFailed() {
  return { type: 'SET_SUBSCRIPTION_CANCELLATION_FAILED' }
}

export function setSubscriptionCancellationRevertFailed() {
  return { type: 'SET_SUBSCRIPTION_CANCELLATION_REVERT_FAILED' }
}

export function changePaymentMethod(paymentMethod = 'newCard') {
  return { type: 'CHANGE_PAYMENT_METHOD', paymentMethod }
}

export function changePaymentMethodToNewCard() {
  return changePaymentMethod('newCard')
}

export function changePaymentMethodToExistingCard() {
  return changePaymentMethod('existingCard')
}

export function changePaymentMethodToInternationalCard() {
  return changePaymentMethod('internationalCard')
}

export function setTypeSelf() {
  return { type: 'SET_TYPE_SELF' }
}

export function setTypeGift() {
  return { type: 'SET_TYPE_GIFT' }
}

export function setTypeUpgrade() {
  return { type: 'SET_TYPE_UPGRADE' }
}

export function setTypeCompany() {
  return { type: 'SET_TYPE_COMPANY' }
}

export function setScreenSuccess({ subscription, presenteeEmail }) {
  return { type: 'SET_SCREEN_SUCCESS', subscription, presenteeEmail }
}

export function setFormHasContent(hasFormContent) {
  return {
    type: 'SET_FORM_HAS_CONTENT',
    hasFormContent,
  }
}

export function setVisibleGift() {
  return { type: 'SET_VISIBLE_GIFT' }
}

export function addDirtyForm() {
  return { type: 'ADD_DIRTY_FORM' }
}

export function removeDirtyForm() {
  return { type: 'REMOVE_DIRTY_FORM' }
}

export function setUpdatedSubscription({ subscription }) {
  return { type: 'SET_UPDATED_SUBSCRIPTION', subscription }
}

export function setCancelledSubscription({ token, token_valid_until: tokenValidUntil }) {
  return { type: 'SET_CANCELLED_SUBSCRIPTION', token, tokenValidUntil }
}

export function revertCancelledSubscription() {
  return { type: 'REVERT_CANCELLED_SUBSCRIPTION' }
}

export function setUpdatedPricing(prices) {
  return { type: 'SET_UPDATED_PRICING', prices }
}

export function setPaymentType(paymentType) {
  return { type: 'SET_PAYMENT_TYPE', paymentType }
}

export function setCompanyPaymentType(companyPaymentType) {
  return { type: 'SET_COMPANY_PAYMENT_TYPE', companyPaymentType }
}

export function setEnrollment(enrollment) {
  return { type: 'SET_ENROLLMENT', enrollment }
}
export function setParticipantType(participantType) {
  return { type: 'SET_PARTICIPANT_TYPE', participantType }
}

export function setParticipantStudent(isStudent, formType) {
  return { type: 'SET_PARTICIPANT_STUDENT', isStudent, formType }
}

export function setTermsAgreement(isAgreedToTerms, formType) {
  return { type: 'SET_TERMS_AGREEMENT', isAgreedToTerms, formType }
}

export function changeBasementItem(item) {
  return { type: 'CHANGE_BASEMENT_ITEM', item }
}

export function closeBasement() {
  return { type: 'CLOSE_BASEMENT' }
}
