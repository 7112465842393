import cx from 'classnames'

import { Heading3, TextNode } from '../textNodes'
import { YoutubeIcon } from '../icons'

const CompactSpacer = (props) => {
  const classes = cx(
    'youtubeSpacer',
    'module',
    'banner-debug',
    props.className
  )

  return (
    <div className={ classes }>
      <Heading3>
        <div className="youtubeSpacer-icon">
          <YoutubeIcon />
        </div>
        Ютуб бюро
      </Heading3>
      <TextNode>
        Видеосоветы, лекции, вебинары, разборы, анонсы курсов, книг и наборов в школы
      </TextNode>
      <div className="youtubeSpacer-cols">
        <div className="youtubeSpacer-left">
          <TextNode className="youtubeSpacer-name">
            <a href="https://www.youtube.com/bureaugorbunov">bureaugorbunov</a>
          </TextNode>
        </div>
        <div className="youtubeSpacer-buttonWrapper">
          <a href="https://www.youtube.com/bureaugorbunov" className="button youtubeSpacer-button">Подписаться</a>
        </div>
      </div>
    </div>
  )
}

const FullSpacer = (props) => {
  const classes = cx(
    'youtubeSpacer',
    'module',
    'device',
    'device__laptop',
    'device__desktop',
    'banner-debug',
    props.className
  )

  return (
    <div>
      <a href="https://www.youtube.com/bureaugorbunov" className={ classes }>
        <div className="youtubeSpacer-icon">
          <YoutubeIcon />
        </div>
        <div className="youtubeSpacer-titleWrapper">
          <div className="youtubeSpacer-title">Ютуб бюро</div>
        </div>
        <div className="youtubeSpacer-undertitle">
          <TextNode>
            Видеосоветы, лекции, вебинары, разборы, анонсы
            <span className="device laptop">: </span>
            <span className="device desktop"> курсов, книг и наборов в школы: </span>
            <span className="link">bureaugorbunov</span>
          </TextNode>
        </div>
      </a>
      <CompactSpacer className={ `${props.className} device device__mobile` } />
    </div>
  )
}

const YoutubeSpacer = (props) => {
  const isHalf = props.size === 'half'
  const isFull = props.size === 'full'

  const classes = cx(
    {
      is__half: isHalf,
      is__full: isFull,
    }
  )

  if (isFull) return (<FullSpacer className={ classes } />)

  return (<CompactSpacer className={ classes } />)
}

export default YoutubeSpacer
