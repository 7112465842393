import { Component } from 'preact'

import UpgradePricing from '../subscriptionPricing/upgradePricing'
import CollecturePricingFooter from './collecturePricingFooter'

import { Heading3, Caption } from '../textNodes'

export default class CollectureUpgradePricing extends Component {
  get hasOtherLectures() {
    return this.props.prices.specialOffer.ownedLectures.length > 0
  }

  get hasOtherCollectures() {
    return this.props.prices.specialOffer.ownedCollectures.length > 0
  }

  get hasOtherLecturesAndCollectures() {
    return this.hasOtherLectures && this.hasOtherCollectures
  }

  get titleText() {
    let suffix = this.hasOtherLectures ? 'lectures' : 'collectures'

    if (this.hasOtherLecturesAndCollectures) suffix = 'lecturesAndCollectures'

    return I18n.t(`collectures.pricing.titleUpgradeFor.${suffix}`)
  }

  get captionText() {
    const { upgradePeriod } = this.props.prices
    const periodSuffix = 'Upgrade' + upgradePeriod

    return I18n.t(`collectures.pricing.subscriptionFooter.caption${periodSuffix}`)
  }

  render() {
    return (
      <div className="collecturePrice">
        <Heading3>{ this.titleText }</Heading3>

        <UpgradePricing
          product="collecture"
          prices={ this.props.prices }
          size="title" />

        { this.props.hasCaption &&
          <Caption html={ this.captionText } />
        }
      </div>
    )
  }
}
