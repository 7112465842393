import cx from 'classnames'
import { TextNode, Heading2 } from '../textNodes'
import LectureSpacerLink from './lectureSpacerLink'
import LectureSpacerInlinePricing from './lectureSpacerInlinePricing'

const MAX_TITLE_SIZE = 50

const LectureSpacerTitle = ({ href, content, prices }) => {
  const { title, sentence, humanizedSize, authorGenitiveCase } = content

  return (
    <div className={ cx('lectureSpacer-title', { is__small: title.length > MAX_TITLE_SIZE }) }>
      <a href={ href } className="lectureSpacer-title-link doubleHover no-u">
        <div className='module'>
          <Heading2>{ title }</Heading2>
          <TextNode>{ sentence }</TextNode>

          <LectureSpacerInlinePricing prices={ prices } className="device device__mobile" />
          <LectureSpacerLink title={ `${humanizedSize} ${authorGenitiveCase}` } href={ href } />
        </div>
      </a>
    </div>
  )
}

export default LectureSpacerTitle
