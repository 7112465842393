import { Component } from 'preact'

class Warning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
    }
  }

  componentDidMount() {
    $(window).on(this.props.events.on, this.enable.bind(this))
    $(window).on(this.props.events.off, this.disable.bind(this))
  }

  enable() {
    this.setState({ isActive: true })
  }

  disable() {
    this.setState({ isActive: false })
  }

  render(props) {
    return (
      <div className={`
            warning
            is__${ props.id }
            ${this.state.isActive ? 'is__active' : ''}
          `}
        >
        { props.message }
      </div>
    )
  }
}

module.exports = Warning
