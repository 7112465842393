import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { formatText, wrapText } from 'lib/textHelper'

import { SovietTitle, SovietMeta } from './sovietSplashBits'
import SovietTitleInput from './sovietTitleInput'
import SovietQuestionInput from './sovietQuestionInput'
import SovietQuestionerNameInput from './sovietQuestionerNameInput'

import { Cols } from '../cols'

const DIVIDER = '\u0020\u200B'

const compileQuestionHTML = ($, text) => {
  return { __html: wrapText($, formatText(text)) }
}

const SovietTitlePlaceholder = (props) => {
  if (props.isEditor) return <SovietTitleInput />

  const titleClass = cx(
    'sovietTitle',
    {
      [`is__${props.titleSize}`]: props.titleSize,
      is__medium: !props.titleSize,
    }
  )

  return <SovietTitle
    className={ titleClass }
    title={ props.title }
    isHangingTitle={ props.isHangingTitle }
    headerTemplate={ props.headerTemplate }
    isFakeHeadingForSeo={ props.isFakeHeadingForSeo }
    titleSize={ props.titleSize } />
}

const SovietQuestionPlaceholder = (props) => {
  if (props.isEditor) return <SovietQuestionInput />

  if (props.hasQuestionCols) {
    const questionColsHTML = props.question.split(DIVIDER).map(text => compileQuestionHTML($, text))

    return (
      <div className="sovietQuestion">
        <Cols transposeOnMobile>
          <div className="sovietQuestion-col" dangerouslySetInnerHTML={ questionColsHTML[0] } />
          <div className="sovietQuestion-col" dangerouslySetInnerHTML={ questionColsHTML[1] } />
        </Cols>
      </div>
    )
  }

  const questionHTML = compileQuestionHTML($, props.question.replace(DIVIDER, '\n'))

  return <div className="sovietQuestion" dangerouslySetInnerHTML={ questionHTML } />
}

const SovietMetaPlaceholder = (props) => {
  if (props.isEditor) return <SovietQuestionerNameInput />

  return <SovietMeta { ...props } />
}

class SovietTitleQuestionMeta extends Component {
  constructor(props) {
    super(props)
  }

  render(props) {
    if (props.headerTemplate === 'leftHalfPage') {
      return (
        <Cols className="sovietTitleQuestionMeta-cols" transposeOnMobile="true">
          <SovietTitlePlaceholder { ...props } />
          <div className="module">
            <SovietQuestionPlaceholder { ...props } />
            <SovietMetaPlaceholder { ...props } />
          </div>
        </Cols>
      )
    }

    if (props.headerTemplate === 'leftNarrowWithTitleTemplate') {
      return (
        <Cols className="sovietTitleQuestionMeta-cols" divisionProportions="4:12" transposeOnMobile="true">
          <SovietTitlePlaceholder { ...props } />
          <div className="module">
            <SovietQuestionPlaceholder { ...props } />
            <SovietMetaPlaceholder { ...props } />
          </div>
        </Cols>
      )
    }

    return (
      <div class="sovietTitleQuestionMeta-wrapper">
        { !props.isTitleHidden &&
          <SovietTitlePlaceholder { ...props } />
        }

        { !props.isQuestionHidden &&
          <SovietQuestionPlaceholder { ...props } />
        }

        { props.isTitleHidden && props.headerTemplate === 'default' ? (
          <div className="sovietQuestionPlaceholder-strut">
            <SovietMetaPlaceholder { ...props } />
          </div>
        ) : (
          <SovietMetaPlaceholder { ...props } />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    isEditor: soviet.isEditor,
    hideQuestioner: soviet.isLecture,
    headerTemplate: soviet.headerTemplate,
    title: soviet.title,
    isTitleHidden: soviet.isTitleHidden,
    titleSize: soviet.titleSize,
    isHangingTitle: soviet.isHangingTitle,
    question: soviet.question,
    isQuestionHidden: soviet.isQuestionHidden,
    questionStyle: soviet.questionStyle,
    hasQuestionCols: soviet.hasQuestionCols,
    questionerName: soviet.questionerName,
    questionDate: soviet.questionDate,
    commentCount: soviet.commentCount,
    newCommentCount: soviet.newCommentCount,
    viewsCount: soviet.viewsCount,
    publishAt: soviet.publishAt,
  }
}

export default connect(mapStateToProps)(SovietTitleQuestionMeta)
