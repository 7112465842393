const KEYDOWN_THRESHOLD = 1500

let buffer = []
let lastKeydownAt

module.exports = (sequence, callback) => {
  document.addEventListener('keydown', (event) => {
    const keyCode = event.keyCode
    const currentTime = Date.now()

    if (!event.ctrlKey && !event.altKey) return null
    if (!sequence.includes(keyCode)) return null
    if (currentTime - lastKeydownAt > KEYDOWN_THRESHOLD) buffer = []

    buffer.push(keyCode)
    lastKeydownAt = currentTime

    if (!buffer.join('-').includes(sequence.join('-'))) return null

    buffer = []
    return callback()
  })
}
