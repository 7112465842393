import { ProductMenuItem } from '../product'

const SovietsSwitcherItem = ({ item, isActive, keepActiveItemClickable }) => {
  const className = isActive ? 'device laptop desktop' : ''

  return <ProductMenuItem { ...{ ...item, isActive, className, keepActiveItemClickable } }>
    { I18n.t(`soviet.switcher.${item.name}`) }
  </ProductMenuItem>
}

export default SovietsSwitcherItem
