const SubscriptionRetry = ({ url, productId }) => {
  const product = productId.split('-')[0]
  const email = I18n.t(`product.email.${product}`)
  const content = I18n.t('subscription.retry', { email, url })

  return (
    <span
      className="subscriptionRetry"
      dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default SubscriptionRetry
