const readAttachment = (rawFile) => {
  if (!rawFile) return null

  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.readAsDataURL(rawFile)
  })
}

module.exports = { readAttachment: readAttachment }
