import { Component } from 'preact'
import { connect } from 'preact-redux'

import UploadBar from '../upload/bar'
import AUTH_HEADERS from 'authHeaders'

import { fetchSovietFiles } from './fetchSovietFiles'

import {
  setFilesAndOpenFilesPanel,
  setFilesUploadError,
} from './sovietActions'

class SovietFilesUploader extends Component {
  constructor(props) {
    super(props)

    this.handleUploadError = this.handleUploadError.bind(this)
    this.dropCounter = 0
  }

  componentDidMount() {
    const $document = $(document)

    $document.on('appDragStart.sovietFilesUpload', () => this.setState({ isDragOver: true }))
    $document.on('appDragEnd.sovietFilesUpload', () => this.setState({ isDragOver: false }))
    $document.on('appDrop.sovietFilesUpload', (_, data) => this.handleDrop(data.files))
  }

  componentWillUnmount() {
    $(document).off('appDragStart.sovietFilesUpload appDragEnd.sovietFilesUpload appDrop.sovietFilesUpload')
  }

  render() {
    return (
      <div className={ `sovietFilesUploader ${this.state.isDragOver ? 'is__dragOver' : ''}` }>
        <UploadBar file={ this.state.uploadingFileName }
            loaded={ this.state.uploadingFileLoaded }
            total={ this.state.uploadingFileTotal }
            isActive={ this.state.isUploading }
            isFailed={ this.props.hasFilesUploadError }
          />
      </div>
    )
  }

  handleDrop(files) {
    this.batchUpload(files)
  }

  batchUpload(files) {
    if (files.length) {
      this.uploadFile(files.pop())
        .then(() => this.batchUpload(files))
        .catch(this.handleUploadError)
    } else {
      fetchSovietFiles(this.props.id)
        .then((filesJson) => {
          this.setState(
            { isUploading: false },
            () => { this.props.setFilesAndOpenFilesPanel(filesJson) }
          )
        })
    }
  }

  uploadFile(rawfile) {
    this.setState({
      isUploading: true,
      uploadingFileName: rawfile.name,
      uploadingFileTotal: rawfile.size,
    })

    const formData = new FormData()
    formData.append('file', rawfile)

    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/pages/soviet/${this.props.id}/index_draft/files`,
        data: formData,
        contentType: false,
        processData: false,
        headers: AUTH_HEADERS,
        type: 'POST',
        xhr: this.handleProgressUpdate.bind(this),
        success: res => resolve(res),
        error: xhr => reject(xhr),
      })
    })
  }

  handleProgressUpdate() {
    const xhr = new window.XMLHttpRequest()

    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) this.setState({ uploadingFileLoaded: e.loaded })
    }, false)

    return xhr
  }

  handleUploadError() {
    this.props.setFilesUploadError()
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    id: soviet.id,
    hasFilesUploadError: soviet.hasFilesUploadError,
  }
}

const mapDispatchToProps = {
  setFilesAndOpenFilesPanel,
  setFilesUploadError,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietFilesUploader)
