import { Component } from 'preact'
import debounce from 'debounce'
import cx from 'classnames'
import { formatNumber, parseNumber, signNumber } from 'lib/textHelper'
import JSON_HEADERS from 'jsonHeaders'
import AUTH_HEADERS from 'authHeaders'

const CORRECTION_TYPE = 'correction'
const UP_KEYCODE = 38
const DOWN_KEYCODE = 40
const DEFAULT_STEP = 0.5

class TaskResultMarkPart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      score: this.formatValue(props.score),
      hasChanges: false,
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.send = debounce(500, this.send.bind(this))
  }

  formatValue(value) {
    value = formatNumber(value)

    if (this.props.part === CORRECTION_TYPE) {
      value = signNumber(value)

      if (value === '+0') value = ''
    }

    return value
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.score === undefined) {
      this.setState({ score: nextProps.score })
    }

    if (this.isPending && nextProps.score === undefined) {
      this.send()
    }
  }

  handleInput(e) {
    this.setState({
      score: e.target.value,
      status: null,
      hasChanges: true,
    })

    this.send()
  }

  handleKeydown(e) {
    if (e.keyCode === UP_KEYCODE) {
      e.preventDefault()
      this.changeBy(DEFAULT_STEP)
    }

    if (e.keyCode === DOWN_KEYCODE) {
      e.preventDefault()
      this.changeBy(-DEFAULT_STEP)
    }
  }

  handleBlur(e) {
    this.setState({
      score: this.formatValue(e.target.value),
    })
  }

  changeBy(step) {
    const currentScore = this.state.score ? this.state.score : 0
    const newScore = parseNumber(currentScore) + step
    if (isNaN(newScore)) return

    const newScoreValue = this.formatValue(newScore)

    this.setState({ score: newScoreValue, status: null, hasChanges: true })
    this.send()
  }

  send() {
    if (!this.isScoreValid()) {
      this.setState({ status: 'error' })
      return
    }

    this.isPending = false
    this.setState({ status: null })

    $.ajax({
      url: this.props.apiUrl,
      type: 'PUT',
      headers: Object.assign({}, AUTH_HEADERS, JSON_HEADERS),
      dataType: 'json',
      data: JSON.stringify({
        mark_parts: { [this.props.part]: parseNumber(this.state.score) },
        reviewer_id: this.props.reviewerId,
      }),
      beforeSend: this.handleBeforeSend.bind(this),
      success: this.handleSendSuccess.bind(this),
      error: this.handleSendError.bind(this),
    })
  }

  isScoreValid() {
    const score = parseNumber(this.state.score)

    return !isNaN(+score) || this.state.score.trim() === ''
  }

  handleBeforeSend(xhr) {
    if (this.currentXhr) this.currentXhr.abort()

    this.currentXhr = xhr

    return true
  }

  handleSendSuccess(json) {
    if (this.isScoreMatchesRemote(json)) {
      this.setState({ status: 'success', hasChanges: false })
      this.props.onReceiveScore({
        score: json.score,
        average: json.average,
      })
    } else {
      this.send()
    }
  }

  handleSendError(xhr, error) {
    if (error === 'abort') return

    this.isPending = true
    this.setState({ status: 'error' })
    this.props.onSendError()
  }

  isScoreMatchesRemote(json) {
    const remoteScore = json.parts.find(part => {
      return part.column === this.props.part &&
       (part.reviewer_id == this.props.reviewerId || part.reviewer_id == window.task.credentials.id)
    }) || { score: '' }

    return this.state.score === this.formatValue(remoteScore.score)
  }

  render(props) {
    const classNames = cx(
      'taskResultMarkPart',
      `${this.state.status ? 'is__' + this.state.status : ''}`,
      {
        has__unsavedChanges: this.state.hasChanges,
        is__correction: props.part === CORRECTION_TYPE,
      })

    return (
      <div className={ classNames }>
        <div className="taskResultMarkPart-in">
          <div className="taskResultMarkPart-label">
            { props.part === CORRECTION_TYPE ? 'Корр.' : props.part }
          </div>
          <input className="taskResultMarkPart-input"
              name={ props.part }
              type="text"
              disabled={ props.disabled }
              value={ this.state.score }
              onInput={ this.handleInput }
              onKeydown={ this.handleKeydown }
              onBlur={ this.handleBlur }
              placeholder={ props.part === CORRECTION_TYPE ? '+0' : '' }
            />
        </div>
      </div>
    )
  }
}

module.exports = TaskResultMarkPart
