import Cookies from 'js-cookie'
import headers from 'lib/jsonHeaders'
import productApiUrl from 'lib/products/productApiUrl'

export const checkGiftCode = (formData) => {
  return fetch('/get/activate.php', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(formData),
    headers,
  })
}

export const resendGift = (code) => {
  return fetch('/get/resend.php', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ code }),
    headers,
  })
}

// TODO: вынести в хелперы, унифицировать тут и в форме советов
const getUserNameFromCookies = () => (Cookies.get('name') || '').replace(/\+/g, ' ') || null
const getUserEmailFromCookies = () => Cookies.get('email') || null

export const getUserName = () => {
  const savedName = (window.application.user || {}).name

  if (savedName) return savedName

  const cookieName = getUserNameFromCookies()

  if (cookieName) return cookieName

  return null
}

export const getUserEmail = () => {
  const savedName = (window.application.user || {}).email

  if (savedName) return savedName

  const cookieName = getUserEmailFromCookies()

  if (cookieName) return cookieName

  return null
}

export const fetchProductDetails = productId => fetch(productApiUrl({ productId }), { headers })
  .then(res => res.json())
