import cx from 'classnames'

import sovietAuthorsByEmail from 'lib/current/sovietAuthorsByEmail'

import UserAvatar from '../userAvatar/userAvatar'

const AvatarWithName = ({ comment }) => {
  const sovietAuthor = sovietAuthorsByEmail[comment.email] || {}
  const commentAuthorName = sovietAuthor.name || comment.name

  return (
    <span>
      <UserAvatar
        name={ commentAuthorName }
        isActive={ !!comment.sovietUsername }
        src={ sovietAuthor.authorAvatarUrl || comment.avatarUrl }
      />
      <span className={ cx('sovietComment-authorName', { link: !!comment.sovietUsername }) }>
        { commentAuthorName }
      </span>
    </span>
  )
}

const SovietCommentAuthor = ({ comment }) => {
  return (
    <div className="module sovietComment-author">
      <div>
        { comment.sovietUsername &&
          <a href={ `/bb/soviet/${comment.sovietUsername}/` } name={ comment.sovietUsername } className="no-u">
            <AvatarWithName comment={ comment } />
          </a>
        }

        { !comment.sovietUsername &&
          <AvatarWithName comment={ comment } />
        }
      </div>
    </div>
  )
}

export default SovietCommentAuthor
