import { Component } from 'preact'
import cx from 'classnames'
import { Caption } from '../textNodes'

import { getLastWeekStudentsWithFairRank, getSortedWeekStudents } from './helpers'
import SchoolRatingStudent from './schoolRatingStudent'

export default class SchoolRatingStudentsListWeek extends Component {
  get title() {
    const { number } = this.props.week

    if (!number) return null

    return I18n.t('schoolRating.weekTitle', { number })
  }

  get students() {
    const { week: { students }, isLastWeek } = this.props

    if (isLastWeek) return getLastWeekStudentsWithFairRank(students)

    return getSortedWeekStudents(students)
  }

  render() {
    const titleClassNames = cx(
      'schoolRatingStudentsListWeek-title',
      'js__schoolRatingWeekAnchor',
      { is__empty: !this.props.week.number }
    )

    return (
      <section className="schoolRatingStudentsListWeek">
        { this.props.hasTitle &&
          <Caption className={ titleClassNames } data-week={ this.props.week.number }>
            { this.title }
          </Caption>
        }

        <div className="schoolRatingStudentsListWeek-students">
          { this.students.map(student => (
            <SchoolRatingStudent { ...student } />
          )) }
        </div>
      </section>
    )
  }
}
