const documentEvents = ['wheel', 'keydown', 'mousedown', 'touchstart']
const windowEvents = ['orientationchange', 'resize']

const notifyParent = payload => window.parent.postMessage(JSON.stringify(payload), '*')
const notifyParentAboutEvent = ({ type }) => {
  notifyParent({ event: 'userInteractionWithEmbeddable', type })
}

module.exports = {
  bind: () => {
    documentEvents
      .forEach(eventType => document.addEventListener(eventType, notifyParentAboutEvent))

    windowEvents
      .forEach(eventType => window.addEventListener(eventType, notifyParentAboutEvent))
  },
}
