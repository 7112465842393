import { Component } from 'preact'
import { Caption } from '../textNodes'

export default class CommentFormCaption extends Component {
  shouldComponentUpdate() {
    return false
  }

  render({ note, title }) {
    return (
      <div className="commentFormCaption">
        <Caption html={ note || `${title} модерируются` } />
      </div>
    )
  }
}
