import { birmanize, truncateLinks } from 'lib/textHelper'

import { TextNode } from '../textNodes'
import SovietUnansweredImage from './sovietUnansweredImage'

const SovietUnanswered = (props) => {
  const {
    slug,
    question,
    questionerName,
    questionImageUrl,
    questionImageSize,
    questionCreatedAt,
    coverUrl,
    coverSize,
    withCover,
  } = props
  const url = `https://bureau.ru/soviet/${slug}/edit`

  return (
    <div className="module sovietUnanswered">
      { coverUrl && withCover &&
        <SovietUnansweredImage
          sovietUrl={ url }
          imageUrl={ coverUrl }
          imageSize={ coverSize } />
      }

      { !coverUrl && questionImageUrl && withCover &&
        <SovietUnansweredImage
          sovietUrl={ url }
          imageUrl={ questionImageUrl }
          imageSize={ questionImageSize } />
      }

      { !coverUrl && !questionImageUrl && withCover &&
        <div className="sovietUnanswered-coverWrapper is__blank" />
      }

      <div className="module">
        <a href={ url } className="sovietUnanswered-questionWrapper doubleHover is__noUnderline">
          <TextNode>
            { truncateLinks(question) }
            <div className="sovietUnanswered-link">
              <span className="link">{ questionerName }</span>
              { ' · ' }
              { birmanize(questionCreatedAt, { format: 'dateYearOnly' }) }
            </div>
          </TextNode>
        </a>
      </div>
    </div>
  )
}

export default SovietUnanswered
