const toVideoPlayerOptions = require('toVideoPlayerOptions')
const VimeoVideo = require('./vimeoVideo')

$(document).on('appReady', () => {
  document
    .querySelectorAll('.vimeoVideo')
    .forEach(el => {
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      new VimeoVideo(el, options)
    })
})
