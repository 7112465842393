import { Component } from 'preact'
import { connect } from 'preact-redux'

import SovietHeaderImage from './sovietHeaderImage'

class SovietHeaderImagePlaceholder extends Component {
  constructor(props) {
    super(props)

    this.handleFileAttachment = this.handleFileAttachment.bind(this)
  }

  handleFileAttachment(e) {
    const files = [e.target.files[0]]

    $(document).trigger('headerImageUpload', { files })

    e.target.value = ''
  }

  render(props) {
    if (!props.headerTemplateRequiresImage) return null
    if (!props.isEditor && !props.headerImageUrl) return null

    if (props.headerImageUrl) {
      return <SovietHeaderImage src={ props.headerImageUrl } size={ props.headerImageSize } />
    }

    return (
      <div className="sovietHeaderImagePlaceholder module">
        <label htmlFor="headerImageUploadPlaceholder">
          <span className="device laptop desktop">
            Загрузите обложку или перетащите картинку сюда
          </span>
          <span className="device mobile">
            Загрузить обложку
          </span>
        </label>
        <input type="file"
          id="headerImageUploadPlaceholder"
          accept="image/jpeg,image/png,image/gif"
          onChange={ this.handleFileAttachment } />
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    isEditor: soviet.isEditor,
    headerImageUrl: soviet.headerImageUrl,
    headerImageSize: soviet.headerImageSize,
    headerTemplateRequiresImage: soviet.headerTemplateRequiresImage,
  }
}

export default connect(mapStateToProps)(SovietHeaderImagePlaceholder)
