class SubscriptionStats {
  constructor($el) {
    this.$el = $el
    $(document).on('appSetUser', this.fetchAndDisplayStats.bind(this))
  }

  fetchAndDisplayStats(_, user) {
    if (!user || !user.isStaff) return

    this.fetchStats().then(this.displayStats.bind(this))
  }

  get subscriptionTypes() {
    return this.$el.attr('data-subscription-stats-for')
  }

  fetchStats() {
    if (!this.subscriptionTypes) return null

    return $.get({
      url: `/mailing_lists/${this.subscriptionTypes}/stats/`,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      xhrFields: { withCredentials: true },
    })
  }

  displayStats({ specialSubscribersCount, listSubscribersCount, clusterSubscribersCount, title }) {
    const stats = [specialSubscribersCount, listSubscribersCount, clusterSubscribersCount]
      .filter(stat => stat || stat === 0)
    const html = stats.join(' / ')

    const $statsEl = $('<a>', {
      html,
      title,
      href: `https://bureau.ru/school/cp/enroll.php?type=${this.subscriptionTypes}`,
      class: 'subscriptionStats',
    })

    this.$el.append($statsEl)
  }
}

module.exports = SubscriptionStats
