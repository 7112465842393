const camelize = require('./camelize')

const isObject = (candidate) => (
  typeof candidate === 'object' &&
  !Array.isArray(candidate) &&
  candidate !== null
)

const deepCamelize = (obj) => {
  let result = camelize(obj)

  return Object.keys(result).reduce((hash, key) => {
    if (isObject(result[key])) {
      hash[key] = deepCamelize(result[key])
    } else {
      hash[key] = result[key]
    }

    return hash
  }, {})
}

module.exports = deepCamelize
