import { Component } from 'preact'
import { Caption } from '../../textNodes'
import { LaptopDesktopWideIcon } from '../../icons'

export default class NewPaybarCourseCaption extends Component {
  get fullDates() {
    return `Курс ${this.props.dates}.`
  }

  render() {
    const schedule = I18n.t('newPaybar.course.schedule')

    if (!this.props.isPurchaseScreen && !this.props.isInitialScreen) {
      return (
        <div className="newPaybarRequirements">
          <div className="newPaybarRequirements-icon">
            <LaptopDesktopWideIcon />
          </div>
          <div className="newPaybarRequirements-text">
            <Caption html={ this.props.requirements } />
            <Caption html={ schedule } />
          </div>
        </div>
      )
    }

    if (this.props.isPurchaseScreen || this.props.isInitialScreen) {
      return (
        <div className="newPaybarCourseCaption">
          <Caption html={ this.fullDates } />
          <Caption html={ schedule } />
        </div>
      )
    }

    return null
  }
}
