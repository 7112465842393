import isValidName from 'lib/users/isValidName'
import cx from 'classnames'

import UserAvatar from '../userAvatar/userAvatar'

const CommentFormAuthorRow = ({ commentId, author, isAuthenticated }) => {
  const { name, email, avatarUrl } = author
  const isAuthenticatedAndHasName = isAuthenticated && isValidName(name)
  const classes = cx(
    'commentForm-authorRow',
    {
      is__authenticated: isAuthenticatedAndHasName,
      not__authenticated: !isAuthenticatedAndHasName,
    }
  )

  return (
    <div className={ classes }>
      <UserAvatar
        name={ name }
        isActive={ isAuthenticatedAndHasName }
        src={ avatarUrl }
      />

      { isAuthenticatedAndHasName &&
        <div className="commentForm-author">
          { name }
          <span className="commentForm-authorEmail">
            , <a className="is__masked" href={ `mailto:${email}` }>{ email }</a>
          </span>
        </div>
      }

      { !isAuthenticatedAndHasName &&
        <div className="commentForm-author">
          <label htmlFor={ `${commentId}-name` }>Имя и фамилия</label>
        </div>
      }
    </div>
  )
}

export default CommentFormAuthorRow
