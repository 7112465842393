import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import { isOnSafari } from 'lib/envHelpers'

import { getStudentsWeekNumbers } from './helpers'
import { unpinStudent } from './schoolRatingActions'
import SchoolRatingChart from './schoolRatingChart'
import SchoolRatingStudentsList from './schoolRatingStudentsList'

class SchoolRating extends Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    $(document).on('click', this.onClick)
  }

  componentWillUnmount() {
    $(document).off('click', this.onClick)
  }

  onClick(e) {
    const $target = $(e.target)
    const isOutsideActiveElements = !$target.closest('.schoolRatingConnectedStudentItem').length

    if (isOutsideActiveElements) this.props.unpinStudent()
  }

  get hasManyWeeks() {
    const weeks = getStudentsWeekNumbers(this.props.students)

    return weeks.length > 1
  }

  render() {
    const classNames = cx('schoolRating', {
      has__transitions: !isOnSafari(),
      has__differentWeekStudents: this.hasManyWeeks,
    })

    return (
      <div className={ classNames }>
        <SchoolRatingChart />
        <SchoolRatingStudentsList />
      </div>
    )
  }
}

const mapDispatchToProps = { unpinStudent }

const mapStateToProps = ({ schoolRating }) => ({ students: schoolRating.students })

export default connect(mapStateToProps, mapDispatchToProps)(SchoolRating)
