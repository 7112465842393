import { Component } from 'preact'
import { connect } from 'preact-redux'
import TextareaAutosize from 'react-textarea-autosize'

import { QuestionMarkInCircleIcon } from '../icons'
import { setSovietText } from './sovietActions'

class SovietText extends Component {
  constructor(props) {
    super(props)

    this.state = { value: '' }
    this.caretPosition = 0

    this.handleInput = this.handleInput.bind(this)
    this.rememberCaretPosition = this.rememberCaretPosition.bind(this)
    this.placeFileCode = this.placeFileCode.bind(this)
  }

  componentDidMount() {
    $(document).on('sovietInsertFile', this.placeFileCode)
  }

  componentWillUnmount() {
    $(document).off('sovietInsertFile')
  }

  handleInput(event) {
    this.props.setSovietText(event.target.value)
    this.rememberCaretPosition()
  }

  rememberCaretPosition() {
    this.caretPosition = this.textarea.selectionStart
  }

  placeFileCode(_, file) {
    if (this.caretPosition === undefined || this.caretPosition === null) return

    const text = this.props.text || ''

    const beforeString = text.substring(0, this.caretPosition)
    const afterString = text.substring(this.caretPosition)
    const newText = [beforeString, file.code, afterString].join('')

    this.props.setSovietText(newText)
  }

  render(props) {
    return (
      <div className="sovietText">
        <label htmlFor="sovietText">
          Текст совета
          <a class="is__noUnderline sovietText-help" href="/soviet/templates/" target="_blank">
           <QuestionMarkInCircleIcon />
          </a>
        </label>
        <TextareaAutosize
          id="sovietText"
          inputRef={ textarea => this.textarea = textarea }
          className="sovietText-text"
          value={ props.text }
          onInput={ this.handleInput }
          onClick={ this.rememberCaretPosition }
          onKeyDown={ this.rememberCaretPosition }
        />
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    text: soviet.text,
  }
}

const mapDispatchToProps = {
  setSovietText,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietText)
