class SuperLectureEditLinks {
  constructor(el) {
    this.lectures = el.querySelectorAll('.embeddedLecture')
  }

  get canEdit() {
    return window.application.user?.isStaff
  }

  render() {
    if (this.canEdit && !this.hasRendered) {
      this.appendEditLinksWithShafts()
    }
  }

  appendEditLinksWithShafts() {
    this.hasRendered = true

    this.lectures.forEach(container => {
      const slug = container.getAttribute('data-lecture-slug')
      const href = `/lectures/${slug}/edit/`
      const target = slug

      const shaft = document.createElement('div')
      shaft.setAttribute('class', 'module embeddedLecture-editLinkShaft')

      const link = document.createElement('a')
      link.setAttribute('href', href)
      link.setAttribute('target', target)
      link.classList.add('embeddedLecture-editLink')

      shaft.prepend(link)
      container.prepend(shaft)
    })
  }
}

module.exports = SuperLectureEditLinks
