import { TextNode, Heading2 } from '../textNodes'
import LectureSpacerLink from './lectureSpacerLink'
import LectureSpacerInlinePricing from './lectureSpacerInlinePricing'

const LectureSpacerTitleHalf = ({ href, content, prices }) => {
  const { title, sentence, humanizedSize } = content

  return (
    <div className="lectureSpacer-title">
      <a href={ href } className="lectureSpacer-title-link doubleHover no-u">
        <div className="module">
          <Heading2>{ title }</Heading2>
          <TextNode>{ sentence }</TextNode>

          <LectureSpacerInlinePricing prices={ prices } />
          <LectureSpacerLink title={ humanizedSize } href={ href } />
        </div>
      </a>
    </div>
  )
}

export default LectureSpacerTitleHalf
