import { Component } from 'preact'

import { Heading3 } from '../textNodes'

export default class CourseTitle extends Component {
  get titleText() {
    const { title, author } = this.props

    return I18n.t('lectures.courseTitle', { title, author })
  }

  render() {
    return (<Heading3 className="courseTitle" html={ this.titleText } />)
  }
}
