import { Component } from 'preact'

export default class NewPaybarSubheading extends Component {
  render() {
    return (
      <div className="newPaybarSubheading subheader module device device__mobile">
        <div className="subheader-in">
          { this.props.children }
        </div>
      </div>
    )
  }
}
