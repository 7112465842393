import { Component } from 'preact'

class SovietCompilationCopy extends Component {
  constructor(props) {
    super(props)

    this.state = { isCopied: false }

    this.copy = this.copy.bind(this)
    this.cleanUp = this.cleanUp.bind(this)
  }

  componentDidMount() {
    $(document).on('touchstart.sovietCompilationCopy', this.cleanUp)
  }

  componentWillUnmount() {
    $(document).off('touchstart.sovietCompilationCopy')
  }

  copy() {
    window._isFullCopyInProcess = true
    const compilationHtml = document.querySelector('.js__sovietCompilationHtml')
    const range = document.createRange()
    range.selectNode(compilationHtml)

    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()

    this.setState({ isCopied: true })
  }

  cleanUp() {
    window._isFullCopyInProcess = false

    this.setState({ isCopied: false })
  }

  render() {
    const buttonText = this.state.isCopied ? 'Скопировано' : 'Скопировать'

    return (
      <button
        className="button compilationCopyButton
          is__small is__thin is__transparent is__enlargedOnMobiles"
        onClick={ this.copy }
        onMouseout={ this.cleanUp }
        data-goal="COMPILATION_COPY">
        { buttonText }
      </button>
    )
  }
}

module.exports = SovietCompilationCopy
