module.exports = {
  create: () => {
    const { card, form } = window.application.newPaybar
    const { handleSubscription } = form

    if (!card) return handleSubscription()

    const isUsingNewCardInsteadOfExisting = (card.props.currentPaymentMethod === 'newCard')

    return (
      isUsingNewCardInsteadOfExisting ?
        card.submit().then(handleSubscription) :
        card.submit().then(handleSubscription, handleSubscription)
    )
  },
  update: () => window.application.newPaybar.form.handleSubscription('PATCH'),
  cancel: require('./cancelSubscription'),
  revert: require('./revertSubscriptionCancellation'),
}
