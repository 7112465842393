import JSON_HEADERS from 'jsonHeaders'

export default class SchoolTestAnswers {
  constructor(test) {
    this.test = test
    this.testId = this.test.id
    this.revision = this.test.revision
  }

  get state() {
    return this.test.state
  }

  get answers() {
    return this.test.questions
      .map(question => question.visibleAnswers.filter(answer => answer.isChecked))
      .map(answers => answers.map(answer => answer.id.replace(this.testId + '.', '')))
      .reduce((acc, answers) => acc.concat(answers), [])
  }

  get resultsApiUrl() {
    const [courseId, testId] = this.testId.split('/')
    const { eventId } = window.classroom || {}

    return `/classroom/${courseId}/${eventId}/tests/${testId}/results/`
  }

  submit({ onBeforeLoad, onAfterLoad }) {
    onBeforeLoad()

    return fetch(this.resultsApiUrl, {
      method: 'POST',
      headers: JSON_HEADERS,
      credentials: 'include',
      body: JSON.stringify({ answers: this.answers, revision: this.revision }),
    })
    .then(res => {
      onAfterLoad()

      if (!res.ok) throw Error(res.statusText)

      return res.json()
    })
  }
}
