export default function NewPaybarLaunchpadLinks() {
  const defaultLinks = [
    {
      href: '/soviet/20140623/',
      title: I18n.t('newPaybar.links.codex'),
    },
    {
      href: 'https://docs.google.com/document/d/1353YyfFWRP3dd-EhrvsBGTYRV9_0prwbjh8MQheAzpM',
      title: I18n.t('newPaybar.links.offer'),
    },
  ]

  return (
    <ul className="newPaybarLaunchpadLinks">

      {defaultLinks.map(link => (
        <li className="newPaybarLaunchpadLinks-item">
          <a href={ link.href } target="_blank">{ link.title }</a>
        </li>
      ))}

    </ul>
  )
}
