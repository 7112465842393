const DEFAULT_COLUMN = 'Дизайн'
const TAGS_COLUMNS = {
  'Текст, редактура и информационный стиль': 'Текст',
  'Дизайн и право': 'Право',
  'Взаимоотношения с клиентом': 'Переговоры',
  'Управление проектом': 'Управление',
  'Типографика в вебе': 'Вёрстка',
  'Модульная вёрстка': 'Вёрстка',
  'Компоновка и стиль информационных сайтов': 'Вёрстка',
  'Навигация в общественных местах': 'Навигация',
  'Решение задач': 'Дизайн продуктов',
  'Дизайн продуктов': 'Дизайн продуктов',
  'Организация студии': 'Организация',
  'Веб-разработка': 'Веб-разработка',
  'Разработка веб-интерфейсов': 'Веб-разработка',
  'Логотипы и фирменные стили': 'Фирстиль',
  'Рекламная ретушь': 'Графика',
  'Технический дизайн и графика': 'Графика',
  'Шрифт в дизайне': 'Шрифт',
  'Визуализация данных': 'Визуализация',
  'Представление информации': 'Визуализация',
  Интерфейс: 'Интерфейс',
  Сайты: 'Сайты',
  Вдохновение: 'Вдохновение',
  Информатика: 'Информатика',
}

const AUTHORS_COLUMNS = { 'Илья Бирман': 'Интерфейс' }

class SovietColumn {
  constructor(soviet) {
    this.soviet = soviet
  }

  get value() {
    return this._columnFromTags() || this._columnByAuthor() || DEFAULT_COLUMN
  }

  _columnFromTags() {
    let column

    (this.soviet.concepts || this.soviet.tags || []).forEach((tag) => {
      const cleanTag = tag.replace(/ /g, ' ')

      if (TAGS_COLUMNS[cleanTag] && !column) {
        column = TAGS_COLUMNS[cleanTag]
      }
    })

    return column
  }

  _columnByAuthor() {
    let column = null

    Object.keys(AUTHORS_COLUMNS).forEach((author) => {
      if (this.soviet.author === author) {
        column = AUTHORS_COLUMNS[author]
      }
    })

    return column
  }
}

module.exports = SovietColumn
