import { Component } from 'preact'
import { Heading3 } from '../textNodes'

import SchoolFacesStudent from './schoolFacesStudent'
import { getStudentsForFacesLargeOnly } from '../schoolRating/helpers'

export default class SchoolFacesLargeOnly extends Component {
  get students() {
    return getStudentsForFacesLargeOnly(this.props.students)
  }

  render() {
    return (
      <div className="schoolFaces">
        <section className="schoolFaces-section is__large">
          <Heading3>{ I18n.t('schoolFaces.titleAbc') }</Heading3>

          <div className="schoolFaces-cols">
            { this.students.map(student => (
              <SchoolFacesStudent { ...student } hasDiploma canDisplayGrantedTag/>
            )) }
          </div>
        </section>
      </div>
    )
  }
}
