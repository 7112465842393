const { findTimeZone, getUTCOffset } = require('timezone-support')
const MOSCOW_TZ = findTimeZone('Europe/Moscow')

const moscowTzOffset = date => -getUTCOffset(date, MOSCOW_TZ).offset * 60 * 1000

const getTimezoneOffsetInMilliseconds = date =>
  date.getTimezoneOffset() * 60 * 1000

const convertUtcToTimezone = (utcDate, timezoneOffset) =>
  new Date(utcDate.getTime() + timezoneOffset)

export const datify = date => new Date(date)

export const addTimezoneOffset = date =>
  new Date(date.getTime() + getTimezoneOffsetInMilliseconds(date))

export const removeTimezoneOffset = date =>
  new Date(date.getTime() - getTimezoneOffsetInMilliseconds(date))

export const moscowify = utcDate =>
  convertUtcToTimezone(utcDate, moscowTzOffset(utcDate))

export const moscowifyLocal = date => moscowify(addTimezoneOffset(date))

export const setTimeToMidnightByMoscow = utcDate => new Date(
  Date.UTC(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(), 0, 0, 0) - moscowTzOffset(utcDate)
)
