import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import { birmanize, formatPrice } from 'lib/textHelper'

import { Caption } from '../../textNodes'

class NewPaybarSubscriptionStatus extends Component {
  get date() {
    if (!this.props.at) return null

    return birmanize(new Date(this.props.at), { format: 'dateYearOnly' })
  }

  get price() {
    return this.props.renewalPrice
  }

  get captionText() {
    const { date } = this
    const price = formatPrice(this.price)

    if (this.props.subscription.subscription_type === 'gift') {
      return I18n.t(`newPaybar.subscriptionStatus.gift.${this.props.product}`, { date, price })
    }

    return I18n.t(`newPaybar.subscriptionStatus.${this.props.product}`, { date, price })
  }

  render() {
    const isAppropriateSubscriptionType = ['self', 'company', 'gift']
      .includes(this.props.subscription.subscription_type)
    const hasDateAndPrice = this.price && this.date

    const shouldDisplayStatus = hasDateAndPrice && isAppropriateSubscriptionType

    if (!shouldDisplayStatus) return null

    const className = cx('newPaybarSubscriptionStatus module', this.props.className)

    return (
      <div className={ className }>
        <Caption html={ this.captionText } />
      </div>
    )
  }
}


const mapStateToProps = ({ newPaybar }) => {
  return {
    subscription: newPaybar.subscription,
    product: newPaybar.product,
    at: newPaybar.subscription.nextPayment || newPaybar.subscription.next_payment,
    renewalPrice: newPaybar.subscription.renewalPriceInLocalCurrency ||
      newPaybar.subscription.renewal_price_in_local_currency ||
      newPaybar.subscription.renewalPrice || newPaybar.subscription.renewal_price,
  }
}

export default connect(mapStateToProps)(NewPaybarSubscriptionStatus)
