import SubscriptionPricing from './subscriptionPricing'
import SubscriptionPricingDiscount from './subscriptionPricingDiscount'
import OneTimeSubscriptionPrice from './oneTimeSubscriptionPrice'
import SubscriptionPricingPreorderCaption from './subscriptionPricingPreorderCaption'

{
  const { productSubscription = {} } = window
  const prices = productSubscription.prices || {}

  if (prices.isCouponApplied) {
    document.body.classList.add('has__couponApplied')
  }

  if (prices.couponValidUntil) {
    document.body.classList.add('has__coupon')
  }

  if (prices.specialOffer) {
    document.body.classList.add('has__specialOffer')
  }

  $('.js__subscriptionPricing, .js__subscriptionPrices').each(function() {
    $(this).reactify(SubscriptionPricing, {
      prices: productSubscription.prices,
      smallUnits: !!$(this).attr('data-small-units'),
    })
  })

  $('.js__oneTimeSubscriptionPrice').each(function() {
    $(this).reactify(OneTimeSubscriptionPrice, {
      price: productSubscription.prices.oneTime,
      label: $(this).attr('data-label'),
      units: $(this).attr('data-units'),
    })
  })

  $('.js__subscriptionPricingDiscount').each(function() {
    if (!productSubscription.prices.isApplicableForDiscount) {
      $(this).addClass('is__empty')
    }

    $(this).reactify(
      SubscriptionPricingDiscount,
      {
        prices: productSubscription.prices,
        caption: $(this).attr('data-caption'),
      }
    )
  })

  $('.js__subscriptionPricingPreorderCaption').each(function() {
    if (productSubscription.prices.preorder) {
      $(this).reactify(SubscriptionPricingPreorderCaption)
    } else {
      $(this).remove()
    }
  })
}
