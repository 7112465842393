import parsePattern from './patternParser'
import { lowerify } from 'lib/dateParser'
import { shortMonths, monthsParentalCaseList } from './dateUtils'
import { birmanize } from 'lib/dateHelper'

const MONTHS_PATTERN = lowerify(monthsParentalCaseList).join('|')
const SHORT_MONTHS_PATTERN = lowerify(shortMonths).join('|')

const makeDottedWithYear = date => `${ String(date.getDate()).padStart(2, '0') }.${ String(date.getMonth() + 1).padStart(2, '0') }.${ date.getFullYear() }`
const makeDottedWithNoYear = date => `${ String(date.getDate()).padStart(2, '0') }.${ String(date.getMonth() + 1).padStart(2, '0') }`

const makeVerbalWithYear = date => birmanize(date, { format: 'fullMonthDateYearOnly' })
const makeVerbalWithNoYear = date => birmanize(date, { format: 'fullMonthDateOnly' })
const makeShortVerbalWithYear = date => birmanize(date, { format: 'dateYearOnly' })
const makeShortVerbalWithNoYear = date => birmanize(date, { format: 'dateOnly' })

export default (rawDate, prevDate) => {
  const newDate = new Date(rawDate)

  if (!prevDate) return makeShortVerbalWithYear(newDate)

  const patternResolvers = {
    ['^([0]?[1-9]|[12]\\d|3[01])\\.([0]?[1-9]|1[0-2])\\.(\\d{4})$']: makeDottedWithYear,
    ['^([0]?[1-9]|[12]\\d|3[01])\\.([0]?[1-9]|1[0-2])$']: makeDottedWithNoYear,
    [`^([0]?[1-9]|[12]\\d|3[01])[ | ](${ MONTHS_PATTERN })$`]: makeVerbalWithNoYear,
    [`^([0]?[1-9]|[12]\\d|3[01])[ | ](${ MONTHS_PATTERN })[ | ](\\d{4})$`]: makeVerbalWithYear,
    [`^([0]?[1-9]|[12]\\d|3[01])[ | ](${ SHORT_MONTHS_PATTERN })$`]: makeShortVerbalWithNoYear,
    [`^([0]?[1-9]|[12]\\d|3[01])[ | ](${ SHORT_MONTHS_PATTERN })[ | ](\\d{4})$`]: makeShortVerbalWithYear,
  }

  return parsePattern(patternResolvers, prevDate, newDate) || makeShortVerbalWithYear(newDate)
}
