const FormSubmitButton = (props) => (
  <button
    {...props}
    className={`button ${props.className ? props.className : ''}`}
    type="submit"
    dangerouslySetInnerHTML={{ __html: props.text }}
  >
    { props.children }
  </button>
)

export default FormSubmitButton
