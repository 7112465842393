import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

class SovietCompilationUploadButton extends Component {
  constructor(props) {
    super(props)

    this.handleFileAttachment = this.handleFileAttachment.bind(this)
  }

  handleFileAttachment(e) {
    const files = [e.target.files[0]]

    $(document).trigger('appDrop.coverUpload', { files })

    e.target.value = ''
  }

  render(props) {
    const placeholder = props.hasCoverUploadError ? props.defaultUploadError : props.placeholder

    return (
      <div className="compilationUploadButton-wrapper">
        <div className="button is__big is__thin compilationUploadButton">
          <input type="file"
            id="compilationUploadButton"
            accept="image/jpeg,image/png,image/gif"
            onChange={ this.handleFileAttachment } />
          <label htmlFor="compilationUploadButton">Загрузить свою картинку</label>
        </div>
        <div className="compilationUploadButton-placeholderWrapper">
          <div className="compilationUploadButton-placeholder"
            dangerouslySetInnerHTML={{ __html: placeholder }} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    hasCoverUploadError: sovietCompilation.hasCoverUploadError,
  }
}


export default connect(mapStateToProps)(SovietCompilationUploadButton)
