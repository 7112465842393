import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import CrossButton from '../crossButton/crossButton'
import NewPaybarAccessButton from './newPaybarAccessButton'
import NewPaybarLaunchpad from './newPaybarLaunchpad'
import expandScreenToFlags from './expandScreenToFlags'

import { setHidden } from './newPaybarActions'
import { detectPaybarOverlay } from './variants'
import isLaunchpad from './isLaunchpad'

const NewPaybarOverlayCloseButton = ({ onClick }) => {
  return (
    <button
      className="newPaybarOverlay-closeButton button is__thin is__enlargedOnMobiles"
      onClick={ onClick }>
      { I18n.t('newPaybar.successScreen.close') }
    </button>
  )
}

class NewPaybarOverlay extends Component {
  constructor(props) {
    super(props)

    this.onOverlayClick = this.onOverlayClick.bind(this)
  }

  get shouldDisplayAccessButton() {
    const selfOrUpgrade = this.props.type === 'self' || this.props.type === 'upgrade'
    const canAccess = this.props.isUserAuthenticated && this.props.canRead && selfOrUpgrade

    const shouldDisplayAccessButton = this.props.isSuccessScreen && canAccess

    if (['prep', 'school'].includes(this.props.product)) {
      return shouldDisplayAccessButton && !this.props.isPreorder
    }

    return shouldDisplayAccessButton
  }

  get shouldDisplayOverlay() {
    return this.props.isSuccessScreen || isLaunchpad(this.props)
  }

  get shouldDisplayOverlayStub() {
    const { product, isSuccessScreen, isUserAuthenticated } = this.props

    const isBookshelfLaunchpad = product === 'bookshelf' && isLaunchpad(this.props)

    return isSuccessScreen || (isUserAuthenticated && isBookshelfLaunchpad)
  }

  onOverlayClick(e) {
    const isBgClick = e.target === this.$overlayBg

    if (this.props.isSuccessScreen || !isBgClick) return

    this.props.setHidden()
  }

  render() {
    const {
      className,
      product,
      accessLink,
      isSuccessScreen,
    } = this.props

    const overlayClassName = cx('newPaybarOverlay', 'module', className, `is__${product}`)
    const OverlayProduct = detectPaybarOverlay(product)

    if (!this.shouldDisplayOverlay) {
      return (
        <div className={ overlayClassName }>
          <div className="newPaybarOverlay-bgClickHandler"
            onMouseDown={ this.onOverlayClick }
            onTouchStart={ this.onOverlayClick }
            ref={ el => this.$overlayBg = el }/>
        </div>
      )
    }

    return (
      <div className={ overlayClassName }>
        <div className="newPaybarOverlay-in" ref={ this.props.overlayRef }>
          <div className="newPaybarOverlay-bgClickHandler"
            onMouseDown={ this.onOverlayClick }
            onTouchStart={ this.onOverlayClick }
            ref={ el => this.$overlayBg = el }/>

          <CrossButton onClick={ this.props.setHidden }/>

          { isSuccessScreen && <OverlayProduct { ...this.props }/> }

          { isLaunchpad(this.props) && <NewPaybarLaunchpad/> }
          { this.shouldDisplayOverlayStub && <div className="newPaybarOverlay-stub" ></div> }

          <div className="newPaybarOverlay-buttonsDeck">

            { this.shouldDisplayAccessButton &&
              <NewPaybarAccessButton product={ product } accessLink={ accessLink }/>
            }

            <NewPaybarOverlayCloseButton onClick={ this.props.setHidden }/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    email: newPaybar.userEmail,
    presenteeEmail: newPaybar.presenteeEmail,
    accessLink: newPaybar.accessLink,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    canRead: !newPaybar.cannotRead,
    isPreorder: newPaybar.isPreorder,
    subscription: newPaybar.subscription,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

const mapDispatchToProps = { setHidden }

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarOverlay)
