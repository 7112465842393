import { Component } from 'preact'

import SovietCommentReadable from './sovietCommentReadable'
import SovietCommentEditable from './sovietCommentEditable'

export default class SovietComment extends Component {
  constructor(props) {
    super(props)

    this.state = { isEditable: false }

    this.handleEditableToggle = this.handleEditableToggle.bind(this)
  }

  handleEditableToggle(isEditable) {
    this.setState({ isEditable: isEditable })
  }

  render(props) {
    if (this.state.isEditable && !props.isDefaultView) {
      return (
        <SovietCommentEditable
          comment={ props.comment }
          toggleEditable={ this.handleEditableToggle }
          onAfterEdit={ props.onAfterEdit } />
      )
    }

    return (
      <SovietCommentReadable
        comment={ props.comment }
        toggleEditable={ this.handleEditableToggle }
        isDefaultView={ props.isDefaultView }
        onStatusUpdate={ props.onStatusUpdate } />
    )
  }
}
