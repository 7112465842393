import { Component } from 'preact'
import { Heading1 } from '../textNodes'
import { Cols } from '../cols'
import { SovietAskFormIcon } from '../icons'
import SovietsSwitcher from '../sovietsSwitcher/sovietsSwitcher'

import SovietAskForm from './sovietAskForm'
import SovietAskFormCaption from './sovietAskFormCaption'
import SovietAskFormLeadText from './sovietAskFormLeadText'
import SovietAskFormSuccessFrame from './sovietAskFormSuccessFrame'

export default class SovietAskFormPageFrame extends Component {
  constructor(props) {
    super(props)

    this.state = { isSent: false }

    this.send = this.send.bind(this)
    this.reset = this.reset.bind(this)
  }

  handlePageTitle(title) {
    document.title = title
  }

  send() {
    this.setState({ isSent: true })
    this.handlePageTitle(I18n.t('soviet.askForm.success'))
  }

  reset() {
    this.setState({ isSent: false })
    this.handlePageTitle(I18n.t('soviet.askForm.title'))
  }

  render() {
    const { isSent } = this.state

    if (isSent) return (<SovietAskFormSuccessFrame onClick={ this.reset } />)

    return (
      <div class="sovietAskFormPage">
        <SovietsSwitcher />
        <Heading1 className="xxxxl">
          { I18n.t('soviet.askForm.title') }
        </Heading1>

        <Cols divisionProportions="11:1:4" transposeOnMobile>
          <div className="sovietAskFormLeadText-wrapper">
            <SovietAskFormLeadText />
          </div>

          <div className="module device device__desktop device__laptop" />

          <div className="module device device__desktop device__laptop sovietAskForm-icon">
            <SovietAskFormIcon />
          </div>
        </Cols>

        <Cols divisionProportions="11:1:4" transposeOnMobile>
          <div>
            <SovietAskForm onFormSubmit={ this.send } />
          </div>

          <div className="module device device__desktop device__laptop emptyCol" />

          <SovietAskFormCaption />
        </Cols>
      </div>
    )
  }
}
