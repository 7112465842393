import { Cols } from '../cols'

import LectureSpacerTitleHalf from './lectureSpacerTitleHalf'
import LectureSpacerCover from './lectureSpacerCover'

const LectureSpacerHalf = ({ href, lectureKey, prices, content }) => {
  return (
    <Cols className="lectureSpacer is__half device device__desktop device__laptop">
      <LectureSpacerCover
        size="half"
        className="lectureSpacer-cover"
        lectureKey={ lectureKey }
        href={ href } />

      <LectureSpacerTitleHalf
        href={ href }
        className="lectureSpacer-title"
        prices={ prices }
        content={ content }
      />
    </Cols>
  )
}

export default LectureSpacerHalf
