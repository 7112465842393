import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import { typograf } from 'lib/textHelper'

import { setTitle } from './sovietCompilationActions'

import RemainingCharsCounter from './remainingCharsCounter'

class SovietCompilationTitle extends Component {
  constructor(props) {
    super(props)

    this._switchTitleFontSize(this.props.title)

    this.handleInput = this.handleInput.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  _switchTitleFontSize(str) {
    this.setState({ switchFont: str.length >= this.props.fontSizeSwitchOn })
  }

  handleInput(event) {
    this._switchTitleFontSize(event.target.value)
    this.props.setTitle(event.target.value)
  }

  handleFocus() {
    this.setState({ focus: true })
  }

  handleBlur() {
    this.setState({ focus: false })
  }

  render(props) {
    const classNames = cx('compilationTitle-textWrapper', {
      is__focused: this.state.focus && !props.isReadOnly,
      is__readOnly: props.isReadOnly,
      is__switched: this.state.switchFont,
    })

    return (
      <div className={`compilationTitleWrapper module ${props.className || ''}`}>
        <div className="compilationTitle">
          <label htmlFor="compilationTitle"><a href="/soviet/selected/">Подборки</a></label>

          { !props.isReadOnly &&
            <RemainingCharsCounter
              maxlength={ props.maxlength }
              text={ props.title } />
          }

          <div className={ classNames }>
            { props.isReadOnly &&
              <div className="js__compilationTitle compilationTitle-text">
                { typograf(props.title) }
              </div>
            }

            { !props.isReadOnly &&
              <textarea
                id="compilationTitle"
                className="compilationTitle-text"
                placeholder={ props.placeholder }
                value={ props.title }
                onInput={ this.handleInput }
                onFocus={ this.handleFocus }
                onBlur={ this.handleBlur }
                readonly={ this.props.isReadOnly }/>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    title: sovietCompilation.title,
    isReadOnly: sovietCompilation.isReadOnly,
  }
}

const mapDispatchToProps = {
  setTitle,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationTitle)
