import { DeleteCrossIcon } from '../icons'

const DeleteButton = (props) => {
  return (
    <div className="deleteButton" { ...props }>
      <DeleteCrossIcon />
    </div>
  )
}

export default DeleteButton
