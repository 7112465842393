import { Component } from 'preact'
import cx from 'classnames'

import JSON_HEADERS from 'jsonHeaders'
import { RECAPTCHA_KEY } from 'recaptcha'
import EmailForm from '../emailForm/emailForm'
import { Caption } from '../textNodes'

import dummySanitize from 'lib/utils/dummySanitize'


class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
      hasRequestedEmail: false,
    }

    this.requestLoginEmail = this.requestLoginEmail.bind(this)
    this.sendLoginEmail = this.sendLoginEmail.bind(this)
  }

  componentDidMount() {
    if (!this.recaptchaScript) this.initializeRecaptcha()
  }

  initializeRecaptcha() {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `//www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`

    document.body.appendChild(script)
    this.recaptchaScript = script
  }

  requestLoginEmail({ email }) {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(RECAPTCHA_KEY, { action: 'login' })
          .then(token => this.sendLoginEmail({ email, token }))
          .then(resolve)
          .catch(reject)
      })
    })
  }

  sendLoginEmail({ email, token }) {
    return fetch('/sessions/', {
      method: 'POST',
      headers: JSON_HEADERS,
      body: JSON.stringify({ email, 'g-recaptcha-response-data': token }),
    })
      .then(() => this.setState({ email, hasRequestedEmail: true }))
      .catch(console.log)
  }

  render() {
    const { hasRequestedEmail, email } = this.state

    return (
      <div className="userPopup-emailWrapper">
        <div className="userPopup-emailHeading">
          <b>{ I18n.t('userPopup.loginToBureausphere') }</b>
        </div>

        <EmailForm onSubmit={ this.requestLoginEmail } />

        <Caption
          className={ cx({ is__visible: hasRequestedEmail }) }
          html={ I18n.t('emailForm.authenticationEmailSent', { email: dummySanitize(email) }) }
        />
      </div>
    )
  }
}

export default LoginForm
