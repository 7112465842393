import BookshelfPricing from './bookshelfPricing'

const { productSubscription = {} } = window

if (productSubscription) {
  $('.js__bookshelfPricing').each(function() {
    const prices = productSubscription.prices
    const smallUnits = $(this).data('small-units')
    const hasCaption = $(this).data('caption')
    const productId = productSubscription.subscription.product_id

    $(this).reactify(BookshelfPricing, { productId, prices, smallUnits, hasCaption })
  })


  $('.js__bundledSubscriptionPricing, .js__bundledSubscriptionPrices').each(function() {
    $(this).reactify(BookshelfPricing, {
      prices: productSubscription.prices.bundled,
      smallUnits: !!$(this).attr('data-small-units'),
      omitHeading: true,
      productId: 'bookshelf-all',
    })
  })
}
