import { Cols } from '../cols'

import SovietHeaderImagePlaceholder from './sovietHeaderImagePlaceholder'
import SovietTitleQuestionMeta from './sovietTitleQuestionMeta'
import { SovietSplashColumn } from './sovietSplashBits'

const DefaultTemplate = ({ column }) => {
  return (
    <div className="sovietSplash-plot">
      <SovietSplashColumn column={ column } />
      <SovietHeaderImagePlaceholder />
      <SovietTitleQuestionMeta />
    </div>
  )
}

const LeftWideTemplate = ({ column }) => {
  return (
    <div>
      <SovietHeaderImagePlaceholder />
      <Cols divisionProportions="4:12" className="sovietSplash-plot">
        <div className="sovietSplash-leftColumn">
          <SovietSplashColumn column={ column } />
        </div>
        <div className="module">
          <SovietTitleQuestionMeta />
        </div>
      </Cols>
    </div>
  )
}

const LeftNarrowTemplate = ({ column }) => {
  return (
    <div>
      <SovietHeaderImagePlaceholder />
      <Cols divisionProportions="3:13" className="sovietSplash-plot">
        <div className="sovietSplash-leftColumn">
          <SovietSplashColumn column={ column } />
        </div>
        <div className="module">
          <SovietTitleQuestionMeta />
        </div>
      </Cols>
    </div>
  )
}

const LeftNarrowWithTitleTemplate = (props) => {
  return (<DefaultTemplate { ...props } />)
}

const LeftRightTemplate = ({ column }) => {
  return (
    <div className="sovietSplash-leftRightWrapper">
      <SovietHeaderImagePlaceholder />
      <Cols divisionProportions="3:9" className="sovietSplash-plot">
        <div className="sovietSplash-leftColumn">
          <SovietSplashColumn column={ column } />
        </div>
        <div className="module">
          <SovietTitleQuestionMeta />
        </div>
      </Cols>
    </div>
  )
}

const LeftHalfPageTemplate = (props) => {
  return (<DefaultTemplate { ...props } />)
}

const ImageLeftTemplate = ({ column }) => {
  return (
    <Cols className="sovietSplash-plot">
      <SovietHeaderImagePlaceholder />
      <div className="module">
        <SovietSplashColumn column={ column } />
        <SovietTitleQuestionMeta />
      </div>
    </Cols>
  )
}

const ImageLeftNarrowTemplate = ({ column }) => {
  return (
    <Cols divisionProportions="5:11" className="sovietSplash-plot">
      <SovietHeaderImagePlaceholder />
      <div className="module">
        <SovietSplashColumn column={ column } />
        <SovietTitleQuestionMeta />
      </div>
    </Cols>
  )
}

const ImageRightTemplate = ({ column }) => {
  return (
    <Cols className="sovietSplash-plot" divisionProportions="7:1:8">
      <div className="module">
        <SovietSplashColumn column={ column } />
        <SovietTitleQuestionMeta />
      </div>
      <div />
      <SovietHeaderImagePlaceholder />
    </Cols>
  )
}

const ImageRightNarrowTemplate = ({ column }) => {
  return (
    <Cols divisionProportions="11:5" className="sovietSplash-plot">
      <div className="module">
        <SovietSplashColumn column={ column } />
        <SovietTitleQuestionMeta />
      </div>
      <SovietHeaderImagePlaceholder />
    </Cols>
  )
}

const ImageRightNarrowWithTitleTemplate = ({ column }) => {
  return (
    <Cols className="sovietSplash-plot" divisionProportions="10:1:5">
      <div className="module">
        <SovietSplashColumn column={ column } />
        <SovietTitleQuestionMeta />
      </div>
      <div />
      <SovietHeaderImagePlaceholder />
    </Cols>
  )
}

module.exports = {
  default: DefaultTemplate,
  leftWide: LeftWideTemplate,
  leftNarrow: LeftNarrowTemplate,
  leftNarrowWithTitleTemplate: LeftNarrowWithTitleTemplate,
  leftRight: LeftRightTemplate,
  leftHalfPage: LeftHalfPageTemplate,
  imageLeft: ImageLeftTemplate,
  imageLeftNarrow: ImageLeftNarrowTemplate,
  imageRight: ImageRightTemplate,
  imageRightNarrow: ImageRightNarrowTemplate,
  imageRightNarrowWithTitle: ImageRightNarrowWithTitleTemplate,
}
