import InputBase from './inputBase'

export default class FormInput extends InputBase {
  render(props) {
    return (
      <input
        {...props}
        className={ this.getClassNames('input') }
        onInput={ this.onInput }
        ref={ this.bindRef }
        value={ this.state.value } />
    )
  }
}
