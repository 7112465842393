import { Component } from 'preact'
import { isBlackMarked, isStatusDisabled, isStatusPaused } from '../schoolRating/helpers'

import Tag from '../tag/tag'

export default class SchoolFacesTags extends Component {
  get hasGrantedTag() {
    if (this.hasPausedTag || this.hasFailedTag) return false

    return this.props.canDisplayGrantedTag && this.props.enrollmentGranted
  }

  get hasFailedTag() {
    return isStatusDisabled(this.props.enrollmentStatus)
  }

  get hasPausedTag() {
    return isStatusPaused(this.props.enrollmentStatus)
  }

  get hasBlackMarkTag() {
    return isBlackMarked(this.props.enrollmentStatus)
  }

  get hasAnyTags() {
    return this.hasGrantedTag || this.hasFailedTag || this.hasPausedTag || this.hasBlackMarkTag
  }

  render() {
    if (!this.hasAnyTags) return null

    return (
      <span className="schoolFacesTags">
        { this.hasGrantedTag && <Tag type="granted" text={ I18n.t('schoolRating.tags.grantedFull') }/> }
        { this.hasFailedTag && <Tag type="disabled" text={ I18n.t(`schoolRating.tags.failed.${this.props.gender}`) }/> }
        { this.hasPausedTag && <Tag type="disabled" text={ I18n.t('schoolRating.tags.paused') }/> }
        { this.hasBlackMarkTag && <Tag type="blackMark" text={ I18n.t('schoolRating.tags.blackMark') }/> }
      </span>
    )
  }
}
