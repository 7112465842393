import CommentField from './commentField'

{
  const componentProps = (resultId) => {
    const userUid = (window.application.user || {}).id
    const { isStaff } = (window.application.user || {})
    const defaultComment = { text: '', draft: '' }
    const comment = window.task
      .comments
      .filter(({ task_result_id }) => task_result_id === resultId)
      .find(({ user_uid }) => user_uid === userUid) || defaultComment

    let apiUrlBase = resultId === null ? `${window.task.apiUrlBase}/comment` : `/classroom/task_results/${resultId}/comment`
    if (isStaff && !!comment.id) apiUrlBase = `/classroom/task_comments/${comment.id}/`

    return {
      apiUrlBase,
      text: comment.draft,
      isExpanded: comment.text !== comment.draft,
      isPublished: !!comment.id && !!comment.text,
    }
  }

  $('.js__taskComment').each(function() {
    const id = $(this).data('id') || null

    $(this).reactify(CommentField, componentProps(id))
  })
}
