import cx from 'classnames'

import { Caption, Heading3 } from '../textNodes'

const NewPaybarFieldsetHeading = ({ text, caption, className }) => (
  <div className={ cx('newPaybarPersonalFieldset-row is__heading', className) }>
    <Heading3>{ text }</Heading3>
    { caption && <Caption html={ caption } /> }
  </div>
)

export default NewPaybarFieldsetHeading
