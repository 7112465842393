import { Component } from 'preact'

import AUTH_JSON_HEADERS from 'authJsonHeaders'
import camelize from 'lib/camelize'

import { Heading2 } from '../textNodes'
import SovietCommentsMenu from './sovietCommentsMenu'
import SovietComment from '../sovietComment/sovietComment'

const INITIAL_COMMENTS_COUNT = { approved: 0, new: 0, sunk: 0 }
const COMMENTS_LOCATIONS = {
  all: /soviet\/[\d]+\/?(comments|preview|preview-comments)?\/?$/i,
  new: /soviet\/[\d]+\/comments\/new\/?$/i,
  sunk: /soviet\/[\d]+\/comments\/sunk\/?$/i,
}

export default class SovietComments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: [],
      count: INITIAL_COMMENTS_COUNT,
      activeTab: '',
      hasFetchedComments: false,
    }

    this.isModerator = window.application.user &&
      (window.application.user.isStaff || window.application.user.isModerator)
    this.isDefaultView = !this.isModerator

    this.updateLocation = this.updateLocation.bind(this)
    this.countComments = this.countComments.bind(this)
    this.refreshCommentsList = this.refreshCommentsList.bind(this)
    this.recountComments = this.recountComments.bind(this)
    this.setActiveTab = this.setActiveTab.bind(this)
  }

  componentDidMount() {
    this.setActiveTab()
    this.refreshCommentsList()

    $(window).on('popstate.comments', this.setActiveTab)
    if (this.isModerator) $(document).on('sovietCommentSent', this.refreshCommentsList)
  }

  componentWillUnmount() {
    $(window).off('popstate.comments')
  }

  componentDidUpdate(_, prevState) {
    if (this.state.hasFetchedComments !== prevState.hasFetchedComments) {
      this.scrollSelectedCommentIntoView()
    }
  }

  scrollSelectedCommentIntoView() {
    const hash = window.location.hash.substring(1)

    if (hash && hash.length) {
      requestAnimationFrame(() => {
        const el = document.querySelector(`a[name="${hash}"], #${hash}`)
        if (el) el.scrollIntoView()
      })
    }
  }

  countComments(comments) {
    const count = comments.reduce((hash, comment) => {
      hash[comment.status]++

      return hash
    }, { ...INITIAL_COMMENTS_COUNT })

    this.setState({ count })
  }

  fetchSovietComments() {
    return fetch(`/soviet/advices/${this.props.sovietKey}/comments/`, { headers: AUTH_JSON_HEADERS })
      .then(res => res.json())
  }

  refreshCommentsList() {
    this.fetchSovietComments()
      .then(json => {
        this.setState({
          hasFetchedComments: true,
          comments: json.map(camelize),
        }, () => this.countComments(this.state.comments))
      })
      .catch(e => console.error(e))
  }

  recountComments() {
    this.fetchSovietComments()
      .then(json => this.countComments(json))
      .catch(e => console.error(e))
  }

  setActiveTab() {
    if (this.isDefaultView) {
      this.setState({ activeTab: 'approved' })
      return
    }

    const location = window.location.pathname
    const activeTab = Object.keys(COMMENTS_LOCATIONS)
      .find(tab => COMMENTS_LOCATIONS[tab].test(location))

    if (activeTab) this.setState({ activeTab })
  }

  updateLocation(title, path) {
    history.pushState({}, title, path)
    this.setActiveTab()
    this.refreshCommentsList()
  }

  sortComments(comment1, comment2) {
    return new Date(comment1.createdAt).getTime() - new Date(comment2.createdAt).getTime()
  }

  get visibleComments() {
    return this.state.comments
      .filter((comment) => {
        switch (this.state.activeTab) {
          case 'all':
            return comment.status === 'approved' || comment.status === 'new'
          default:
            return comment.status === this.state.activeTab
        }
      })
      .sort(this.sortComments)
  }

  renderComments() {
    return this.visibleComments
      .map((comment) => {
        return (
          <SovietComment
            key={ comment.id }
            comment={ comment }
            isDefaultView={ this.isDefaultView }
            onAfterEdit={ this.refreshCommentsList }
            onStatusUpdate={ this.recountComments } />
        )
      })
  }

  render(props) {
    const hasApprovedComments = this.state.count.approved > 0

    if (!this.state.comments.length || (this.isDefaultView && !hasApprovedComments)) {
      return <Heading2>Комментариев пока нет</Heading2>
    }

    return (
      <div>
        { this.isDefaultView &&
          <Heading2>Комментарии</Heading2>
        }

        { !this.isDefaultView &&
          <SovietCommentsMenu
            sovietKey={ props.sovietKey }
            activeTab={ this.state.activeTab }
            count={ this.state.count }
            changeTabAction={ this.updateLocation }
          />
        }

        { !!this.visibleComments.length &&
          <div className="module is__textWidth">
            { this.renderComments() }
          </div>
        }
      </div>
    )
  }
}
