import cx from 'classnames'

const NewPaybarShelfDummy = ({ isWide, isLarge, productId }) => {
  const src = `/projects/${productId}/files/3d-covers${ isWide ? '-wide' : '' }${ isLarge ? '-large' : '' }.png?v=2`
  const classNames = cx('newPaybarShelfDummy', { is__wide: isWide, is__large: isLarge })

  return (
    <div className={ classNames }>
      <img src={ src } alt="" />
    </div>
  )
}

export default NewPaybarShelfDummy
