import cx from 'classnames'

const SpanProductMenuItemWrapper = props => (
  <span className={ cx({ is__active: props.isActive, is__disabled: props.isDisabled }) }>
    { props.children }
    { props.count > 0 &&
      <span class="product-menu-item-counter">{ `  ${props.count}` }</span>
    }
  </span>
)

const LinkProductMenuItemWrapper = props => (
  <a href={ props.link } onClick={ props.onClick } className={ props.className }>
    { props.children }
    { props.count > 0 &&
      <span class="product-menu-item-counter">{ `  ${props.count}` }</span>
    }
  </a>
)

const ProductMenuItem = (props) => {
  const classes = cx(
    'product-menu-item',
    {
      [`is__${props.mod}`]: props.mod,
      is__disabled: props.isDisabled,
      [props.className]: props.className,
    }
  )

  return (
    <div className={ classes }>
      { (props.isActive || props.isDisabled) && !props.keepActiveItemClickable &&
        <SpanProductMenuItemWrapper { ...props } />
      }

      { (props.isActive || props.isDisabled) && props.keepActiveItemClickable &&
        <LinkProductMenuItemWrapper { ...props } className="is__active" />
      }

      { !props.isActive && !props.isDisabled &&
        <LinkProductMenuItemWrapper { ...props } />
      }
    </div>
  )
}

export default ProductMenuItem
