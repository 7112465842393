import { Component } from 'preact'
import { formatNumber, wrapFractionalPart } from 'lib/textHelper'
import { formatBonusScore } from './helpers'

const formatScore = score => wrapFractionalPart(formatNumber(score))

export default class TaskResultMark extends Component {
  componentDidMount() {
    this.updateStrutScore()
  }

  componentDidUpdate() {
    this.updateStrutScore()
  }

  updateStrutScore() {
    if (this.props.score != null) {
      $('.js__taskMarkScore-strut').html(formatScore(this.props.score))
    }
  }

  render() {
    return (
      <div className="taskResultMark-scores">
        { !this.props.userIsStaff && this.props.correction && this.props.correction != null &&
          <div className="taskResultMarkPart is__correctionAverage">
            <div className="taskResultMarkPart-label">Корр.</div>
            <div className="taskResultMarkPart-value">
              { formatNumber(this.props.correction) }
            </div>
          </div>
        }

        { this.props.userIsStaff && this.props.average != null &&
          <div className="taskResultMarkPart is__average">
            <div className="taskResultMarkPart-label">Средняя</div>
            <div className="taskResultMarkPart-value">
              { formatNumber(this.props.average) }
            </div>
          </div>
        }

        { this.props.bonuses.length > 0 &&
          this.props.bonuses.map(bonusPart => (
            <div className="taskResultMarkPart is__bonus">
              <div className="taskResultMarkPart-label">{ bonusPart.column }</div>
              <div className="taskResultMarkPart-value">
                { formatBonusScore(bonusPart) }
              </div>
            </div>
          ))
        }

        { this.props.score != null &&
          <div
            className="taskResultMark-score"
            dangerouslySetInnerHTML={{ __html: formatScore(this.props.score) }}>
          </div>
        }
      </div>
    )
  }
}
