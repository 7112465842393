import { Component } from 'preact'
import Warning from './warning'

class Warnings extends Component {
  render(props) {
    return (
      <div className='warnings'>
        { props.warnings.map(warning => (
          <Warning
              id={ warning.id }
              events={ warning.events }
              message={ warning.message }
            />
        ))}
      </div>
    )
  }
}

{
  $('.js__warnings').each(function() {
    $(this).reactify(Warnings, {
      warnings: window.warnings,
    })
  })
}
