import LinedTextarea from './linedTextarea'

$('.js__linedTextarea').each(function() {
  const $textarea = $(this).find('textarea').remove()
  const text = $textarea.val()
  const name = $textarea.attr('name')
  const localStorageKey = $textarea.attr('data-local-storage-key')
  const minRows = $textarea.attr('data-min-rows')

  $(this).reactify(LinedTextarea, { text, name, localStorageKey, minRows })
  $(this).addClass('linedTextarea')
})
