import cx from 'classnames'

import { toInitials } from 'lib/textHelper'

import { BureausphereInIcon, BureausphereIcon } from '../icons'
import { Image } from '../image'

const UserAvatar = ({ name, isActive, src }) => {
  const classes = cx('userAvatar', { is__active: isActive })
  const bgClasses = cx('userAvatar-background', { is__custom: !!src })

  if (src) {
    return (
      <div className={ classes }>
        <div className={ bgClasses } style={{ backgroundImage: `url(${src})` }} />
      </div>
    )
  }

  return (
    <div className={ classes }>
      <div className={ bgClasses }>
        { isActive ? <BureausphereInIcon /> : <BureausphereIcon /> }
      </div>

      { isActive &&
        <div className="userAvatar-initials">{ toInitials(name) }</div>
      }
    </div>
  )
}

export default UserAvatar
