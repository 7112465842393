import { Component } from 'preact'

import Price from '../price/price'
import Pricing from '../price/pricing'

import { formatPrice } from 'lib/textHelper'

export default class UpgradePricing extends Component {
  get specialOffer() {
    return this.props.prices.specialOffer || this.props.prices.previousSpecialOffer
  }

  get upgradePeriod() {
    return this.props.prices.upgradePeriod
  }

  get subscriptionPeriod() {
    return this.props.prices.subscriptionPeriod
  }

  get upgradeLabel() {
    const { upgradePeriod, subscriptionPeriod } = this
    const { previousOneTime } = this.specialOffer

    if (previousOneTime && upgradePeriod !== subscriptionPeriod) {
      return I18n.t(`subscription.prices.defaultLabel_${upgradePeriod}`) + ` <s>${formatPrice(previousOneTime)}</s>`
    }

    return I18n.t('subscription.prices.upgradeLabel')
  }

  get subscriptionLabel() {
    const { upgradePeriod, subscriptionPeriod } = this

    if (upgradePeriod !== subscriptionPeriod) {
      return I18n.t(`subscription.prices.subscriptionLabel_${upgradePeriod}`)
    }

    return I18n.t('subscription.prices.upgradeSubscriptionLabel')
  }

  render() {
    const { prices, size = 'pageTitle', unitsSize = 'medium' } = this.props

    return (
      <Pricing currency={ prices.currency }>
        <Price
          label={ this.upgradeLabel }
          amount={ formatPrice(this.specialOffer.oneTime) }
          className="is__specialOffer"
          units={ I18n.t(`subscription.defaultUnits_${prices.upgradePeriod}`) }
          size={ size }
          unitsSize={ unitsSize }
        />

        <Price
          label={ this.subscriptionLabel }
          amount={ formatPrice(prices.subscription) }
          size={ size }
          unitsSize={ unitsSize }
          units={ I18n.t(`subscription.subscriptionUnits_${prices.renewalPeriod}`) }
          hasCompactUnitsView
        />
      </Pricing>
    )
  }
}
