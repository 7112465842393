import { Component } from 'preact'
import { Caption } from '../textNodes'


export default class SovietCommentFormCaption extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <div className="sovietCommentFormCaption">
        <Caption>
          Цель рубрики — обсуждение вопросов дизайна, веб-разработки,
          переговоров, редактуры и управления.
        </Caption>

        <Caption>
          Комментарии модерируются. Мы публикуем комментарии,
          которые добавляют к уже сказанному новые мысли и хорошие примеры.
        </Caption>

        <Caption>
          <a href="/soviet/howtocomment/" class="no-u">
            <span class="is__somethingNew">Новое</span>
          </a>
          <a href="/soviet/howtocomment/">Станьте модератором</a>
        </Caption>
      </div>
    )
  }
}
