export default class ProductUser {
  logout() {
    return fetch('/burosfera/logout/?silent', { credentials: 'include' })
      .then(res => {
        if (!res.ok) throw Error(res.statusText)

        window.application.user = null
      })
  }
}
