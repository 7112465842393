import { Component } from 'preact'
import { formatScore } from 'lib/school/classroom/scoreHelper'
import { splitName, isIntersectContainer } from './helpers'

export class Total extends Component {
  constructor(props) {
    super(props)

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  componentDidMount() {
    if (!this.props.preventIntersectWith) return

    const isIntersect = isIntersectContainer(this.props.preventIntersectWith, this.$el)

    if (isIntersect !== this.state.isIntersect) {
      this.setState({ isIntersect })
    }
  }

  onMouseEnter() {
    this.props.onMouseEnter(this.props.lastWeekNumber)
  }

  onMouseLeave() {
    this.props.onMouseLeave()
  }

  get name() {
    return splitName(this.props.name).last
  }

  render() {
    const { coords: { x, y } } = this.props

    const styles = `
      transform: translate(${x}px, ${y}px)
      translate(${this.state.isIntersect ? '-100%' : '18px'}, -9px);
    `

    return (
      <span className="schoolRatingGraphOverlay-total"
        style={ styles }
        ref={ el => this.$el = el }
        onMouseEnter={ this.onMouseEnter }
        onMouseLeave={ this.onMouseLeave }>

        <b>{ this.name }</b> { formatScore(this.props.score) }
      </span>
    )
  }
}
