import { toInitials } from 'lib/textHelper'
import { Image } from '../image'

const UserBurosferaLink = ({ englishName, children }) => {
  return (
    englishName ?
      <a href={ `/burosfera/${englishName}/` } name={ englishName } className="module doubleHover">
        { children }
      </a> :
      { children }
  )
}

const CommentAuthorAvatar = ({ author: { name, englishName, avatarUrl } }) => {
  if (avatarUrl) {
    return (
      <UserBurosferaLink englishName={ englishName }>
        <Image className="comment-avatar" src={ avatarUrl } size={ [126, 126] } />
      </UserBurosferaLink>
    )
  }
  const hasOnlyOneName = !name.includes(',')
  return (
    <UserBurosferaLink englishName={ englishName }>
      <div className="comment-avatar no__image">
        <div className="comment-avatarInitialsWrapper">
          <div className="comment-avatarInitials">{ hasOnlyOneName ? toInitials(name) : '' }</div>
        </div>
      </div>
    </UserBurosferaLink>
  )
}

export default CommentAuthorAvatar
