import { Component } from 'preact'

import { StickyOrderButtonBottom } from './pricingUtils'
import { Caption } from '../textNodes'

export default class CollecturePricingFooter extends Component {
  get hasCompactCaption() {
    return this.props.hasStickyOrderButton
  }

  get isUpgrade() {
    return !!this.props.prices.specialOffer
  }

  get captionText() {
    const { upgradePeriod, subscriptionPeriod } = this.props.prices
    const periodSuffix = this.isUpgrade ? ('Upgrade' + upgradePeriod) : subscriptionPeriod

    return I18n.t(`collectures.pricing.subscriptionFooter.captionCompact${periodSuffix}`)
  }

  render() {
    return (
      <div className="productSubscription-footer">
        <Caption html={ this.captionText } />
        <StickyOrderButtonBottom />
      </div>
    )
  }
}
