import { Component } from 'preact'

import SchoolRatingStudentsListWeek from './schoolRatingStudentsListWeek'
import { connect } from 'preact-redux'
import { getWeeksWithStudents } from './helpers'
import SchoolRatingStudentsListHeader from './schoolRatingStudentsListHeader'
import SchoolRatingStudentsListEmpty from './schoolRatingStudentsListEmpty'

class SchoolRatingStudentsList extends Component {
  get weeksWithStudents() {
    return getWeeksWithStudents(this.props.students)
  }

  get hasManyWeeks() {
    return this.weeksWithStudents.length > 1
  }

  render() {
    return (
      <div className="schoolRatingStudentsList">
        <SchoolRatingStudentsListHeader isSticky={ this.hasManyWeeks }/>

        { !this.weeksWithStudents.length &&
          <SchoolRatingStudentsListEmpty />
        }

        { this.weeksWithStudents.map(week => (
          <SchoolRatingStudentsListWeek
            week={ week }
            hasTitle={ this.hasManyWeeks }
            isLastWeek={ week.number === this.props.weeksCount }/>
        ))}
      </div>
    )
  }
}

const mapStateToProps = ({ schoolRating }) => {
  return {
    weeksCount: schoolRating.weeksCount,
    students: schoolRating.students,
  }
}

export default connect(mapStateToProps)(SchoolRatingStudentsList)
