import { Component } from 'preact'
import { Heading3, TextNode } from '../textNodes'

export default class CommentFormSuccessFrame extends Component {
  render() {
    return (
      <div className="commentFormSuccessFrame bureausans">
        <div>
          <Heading3>Спасибо! Ваш {this.props.title} отправлен</Heading3>
          <TextNode>
            Если он соответствует нашим условиям, то появится на сайте.
          </TextNode>
        </div>

        <footer>
          <button class="button is__enlargedOnMobiles" onClick={ this.props.onClick }>
            Написать ещё
          </button>
        </footer>
      </div>
    )
  }
}
