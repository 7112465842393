import { Provider } from 'preact-redux'
import { createStore } from '../schoolRating/schoolRatingStore'

import SchoolRating from '../schoolRating/schoolRating'
import SchoolFaces from '../schoolFaces/schoolFaces'
import SchoolFacesLargeOnly from '../schoolFaces/schoolFacesLargeOnly'

const WrappedSchoolRating = (props) => {
  const store = createStore(props)
  return (<Provider store={ store }><SchoolRating /></Provider>)
}

const LEVEL_COMPONENTS_MAP = {
  1: WrappedSchoolRating,
  2: SchoolFaces,
  3: SchoolFacesLargeOnly,
}

export default ({ level, students }) => {
  const ContentComponent = LEVEL_COMPONENTS_MAP[level]

  if (!ContentComponent || !students) return <div className="schoolRatingPreloader"></div>

  return <ContentComponent students={ students }/>
}
