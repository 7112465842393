const ESC_KEY = 27;
const CLASS_EXPANDED = 'is__full';

class SchoolEnrollSwitcher {
  constructor($el) {
    this.$el = $el;

    this._bindEvents();
  }

  hide() {
    this.$el.removeClass(CLASS_EXPANDED);
  }

  toggle() {
    this.$el.toggleClass(CLASS_EXPANDED);
  }

  _bindEvents() {
    $(document).on('keydown', (e) => {
      if (e.keyCode === ESC_KEY) this.hide();
    });

    this.$el.on('click', (e) => e.stopPropagation());
    this.$el.on('click', '.js__toggle', this.toggle.bind(this));
    $(document).on('click', this.hide.bind(this));
  }
}

module.exports = SchoolEnrollSwitcher;
