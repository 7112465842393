const stopwatch = window.performance || Date
const DRAW_INTERVAL = 10

module.exports = {
  get() {
    const current = stopwatch.now()
    const { lastLayoutAt = 0 } = this
    const isMinimalTimeSinceLastLayoutPassed = current - lastLayoutAt > DRAW_INTERVAL
    const isScrollPositionBlank = this.lastScrollPosition === undefined ||
      this.lastScrollPosition === null

    if (isScrollPositionBlank || isMinimalTimeSinceLastLayoutPassed) {
      this.lastScrollPosition = window.pageYOffset
    }

    return this.lastScrollPosition
  },
}
