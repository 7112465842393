import { Component } from 'preact'
import File from './file'

class UploadFiles extends Component {
  render({ files, onDelete }) {
    if (!files.length) return

    return (
      <div className="uploadFiles">
        { files.map(file => (
          <File
              file={ file }
              onDelete={ () => onDelete(file) }
            />
        ))}
      </div>
    )
  }
}

module.exports = UploadFiles
