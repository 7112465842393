class PageMenu {
  constructor($el) {
    this.id = Math.random().toString(10).slice(4);
    this.CLASS_ACTIVE = 'is__active';

    this.$el = $el;
    this.$toggler = this.$el.find('h1, button');
    this.$document = $(document);

    if (this.$el.attr('data-page-menu') !== 'initialized') this.setListeners();
  }

  setListeners() {
    this.$toggler.on('click', () => {
      this.$el.toggleClass(this.CLASS_ACTIVE);
      if (this.$el.hasClass(this.CLASS_ACTIVE)) {
        this.$document.trigger('pageMenuActivated', this.id);
      }
    });

    this.$document.on('pageMenuActivated', (_, id) => {
      if (this.id !== id) {
        this.$el.removeClass(this.CLASS_ACTIVE);
      }
    });

    this.$el.attr('data-page-menu', 'initialized')
  }
}

module.exports = PageMenu;
