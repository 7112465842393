import { Component } from 'preact'

const CLASS_SEARCHING = 'is__searching'

class HeaderSearch extends Component {
  constructor(props) {
    super(props)

    this.state = { isOpen: false }

    this.$header = $('.header')
    this.$document = $(document)

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount() {
    this.$document.on('keyup.header', (e) => {
      if (!this.state.isOpen) return

      if (e.keyCode === 27) {
        this.hide()
      }
    })

    this.$document.on('click.header', (e) => {
      if (!this.state.isOpen) return
      if (e.target && e.target.className.includes('headerSearch')) return

      this.hide()
    })
  }

  componentDidUpdate() {
    this.$header.toggleClass(CLASS_SEARCHING, this.state.isOpen)
  }

  componentWillUnmount() {
    this.$document.off('keyup.header')
    this.$document.off('click.header')
  }

  show() {
    if (this.state.isOpen) return

    this.setState({ isOpen: true })
    this.input.focus()
  }

  hide() {
    this.setState({ isOpen: false })
    this.input.blur()
  }

  render() {
    const placeholderText = window.application.isOnMobile ? 'Поиск' : 'Поиск советов'

    return (
      <div className="headerSearch">
        <button className="headerSearch-toggler" onClick={ this.show }>🔍</button>
        <form className="headerSearch-form" action="/mod/search/index.php" method="get">
          <input
            className="headerSearch-input"
            type="text"
            name="text"
            placeholder={ placeholderText }
            ref={ input => this.input = input }
          />
        </form>
        <button className="headerSearch-clear" onClick={ this.hide }>×</button>
      </div>
    )
  }
}

export default HeaderSearch
