import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import DELETE_HEADERS from 'authJsonHeaders'

import { fetchSovietFiles } from './fetchSovietFiles'
import { setFiles } from './sovietActions'

import { Image } from '../image'
import DeleteButton from '../deleteButton/deleteButton'

const PREVIEW_HEIGHT = 70

class SovietFile extends Component {
  constructor(props) {
    super(props)

    this.handleFileDelete = this.handleFileDelete.bind(this)
    this.insertFile = this.insertFile.bind(this)
  }

  handleFileDelete() {
    this.setState({ isDeleted: true }, this.deleteFile)
  }

  deleteFile() {
    fetch(`/pages/soviet/${this.props.sovietId}/index_draft/files/${this.props.file.id}`, {
      method: 'DELETE',
      headers: DELETE_HEADERS,
    })
      .then((res) => {
        if (res.ok) {
          this.refreshFilesList()
        } else {
          this.setState({ isDeleted: false })
        }
      })
  }

  refreshFilesList() {
    fetchSovietFiles(this.props.sovietId)
      .then(this.props.setFiles)
  }

  get isFileImage() {
    return this.props.file.width && this.props.file.height
  }

  get imageCode() {
    return `+image({src: '${this.props.file.name}'})`
  }

  get fileCode() {
    return `a(href="${this.props.file.name}") ${this.props.file.name}`
  }

  insertFile() {
    const snippetCode = this.isFileImage ? this.imageCode : this.fileCode

    $(document).trigger('sovietInsertFile', { code: snippetCode })
  }

  render(props) {
    const size = [props.file.width, props.file.height]
    const classes = cx('sovietFile', { is__deleted: this.state.isDeleted })

    return (
      <div className={ classes }>
        <div className="sovietFile-wrapper" onClick={ this.insertFile }>
          { this.isFileImage &&
            <Image
              src={ props.file.url }
              size={ size }
              style={{ width: `${size[0] / size[1] * PREVIEW_HEIGHT}px` }}
            />
          }

          { this.isFileImage &&
            <div className="sovietFile-hint">{ props.file.name }</div>
          }

          { !this.isFileImage &&
              <div className="sovietFile-placeholder">
                { props.file.name }
              </div>
          }
        </div>
        <DeleteButton onClick={ this.handleFileDelete }/>
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    sovietId: soviet.id,
  }
}

const mapDispatchToProps = {
  setFiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietFile)
