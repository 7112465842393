import { utmSource } from 'lib/helpers/linksHelper'

export const lectureHref = productId => {
  const source = utmSource({
    platform: 'Сайт бюро',
    postType: 'Банер',
    productType: 'Лекции',
    product: productId,
    postDate: new Date(),
  })

  const lectureKey = productId.replace('lecture-', '')
  return `/lectures/${lectureKey}/?utm_source=${source}`
}
