const Question = require('./schoolTestQuestion')
const { formatNumber } = require('lib/textHelper')

const CLASS_REVEALED = 'is__revealed is__exposed'
const MAX_QUESTION_SCORE = 1.5

I18n.translations.ru.schoolTrainingTest = {
  questionResults: {
    one: 'Вы правильно ответили<br>на <b>%{count}</b> вопрос из <b>%{total}</b>.',
    few: 'Вы правильно ответили<br>на <b>%{count}</b> вопроса из <b>%{total}</b>.',
    many: 'Вы правильно ответили<br>на <b>%{count}</b> вопросов из <b>%{total}</b>.',
    other: 'Вы правильно ответили<br>на <b>%{count}</b> вопросов из <b>%{total}</b>.',
  },
  flawlessVictory: 'Вы правильно ответили на все вопросы. От души поздравляем<br> и желаем вам дальнейших успехов в школе!',
}

const formatQuestionResults = (correctQuestionsCount, totalQuestionsCount) => {
  return I18n.t('schoolTrainingTest.questionResults',
    { count: correctQuestionsCount, total: totalQuestionsCount })
}

const formatFlawlessVictory = (score) => {
  return I18n.t('schoolTrainingTest.flawlessVictory', { score: formatNumber(score) })
}

class SchoolTrainingTest {
  constructor($el) {
    this.MAX_QUESTION_SCORE = MAX_QUESTION_SCORE
    this.$el = $el
    this.$submit = this.$el.find('.js__check')
    this.$reset = this.$el.find('.js__reset')
    this.$results = this.$el.find('.js__results p')

    this.id = this.$el.data('id')
    this.state = window.application.state
    this.correctCounter = 0

    this.questions = []

    this._fillInQuestions()

    this._init()
    this._bindEvents()
  }

  check() {
    if (this.isDone) {
      this.$results.html(this._formattedResults())
      this.$el.addClass(CLASS_REVEALED)
      this.questions.forEach(question => question.lock())
    }
  }

  reset() {
    this.$el.removeClass(CLASS_REVEALED)
    this.questions.forEach(question => question.reset())
    this._toggleSubmit()
    this._scrollToTop()
  }

  get isDone() {
    return this.questions.every(question => question.isDone)
  }

  get score() {
    return this.questions.reduce((total, question) => total + question.score, 0)
  }

  get fullScore() {
    return this.questions.length * this.MAX_QUESTION_SCORE
  }

  _fillInQuestions() {
    this.$el.find('.js__question').each((_, el) => {
      this.questions.push(new Question($(el), this))
    })
  }

  _bindEvents() {
    this.$submit.on('click', this.check.bind(this))
    this.$reset.on('click', this.reset.bind(this))
    this.$el.on('answerChecked', this._toggleSubmit.bind(this))
  }

  _init() {
    this.questions.forEach(question => question.init())
    this.check()
  }

  _toggleSubmit() {
    this.$submit.attr('disabled', !this.isDone)
  }

  _scrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 300)
  }

  _formattedResults() {
    const correctQuestionsCount = this.questions.filter(question => question.isCorrect).length
    const totalQuestionsCount = this.questions.length

    if (totalQuestionsCount === correctQuestionsCount) return this._formatFlawlessVictory()

    return formatQuestionResults(correctQuestionsCount, totalQuestionsCount)
  }

  _formatFlawlessVictory() {
    return formatFlawlessVictory(this.score)
  }
}

$('.js__schoolTest.is__training').each(function() {
  new SchoolTrainingTest($(this))
})
