import LinkWithArrow from '../linkWithArrow'

const BookSpacerLink = (props) => {
  return (
    <LinkWithArrow
      { ...props }
      isNoVisited
      isDoubleHover
      isSmall={ props.isSmall }
      isInline={ props.isInline }
    />
  )
}

export default BookSpacerLink
