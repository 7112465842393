const E_KEYCODE = 69

class EditLink {
  constructor() {
    $(document).on('appSetUser', this.handleCurrentUser.bind(this))
  }

  handleCurrentUser(_, user) {
    if (user && this.hasEditAccess(user) && this.address) {
      this.$link = this.buildLink()
      $('.js__header').append(this.$link)

      this.bindEvents()
    }
  }

  get address() {
    const $readOnly = $('meta[name="readOnly"]')
    const $pageId = $('meta[name="pageId"]')
    const $pageEditLink = $('meta[name="pageEditLink"]')
    const pageId = $pageId.attr('content')

    if ($readOnly.length || !$pageId.length) return null
    if ($pageEditLink.length) return $pageEditLink.attr('content')

    return (pageId + '/edit').replace('//', '/')
  }

  bindEvents() {
    $(document).on('keydown', (e) => {
      if (e.keyCode === E_KEYCODE && e.altKey) {
        e.preventDefault()
        window.open(this.$link.attr('href'))
      }
    })
  }

  buildLink() {
    const targetId = this.address.replace(/\//, '.')

    return $('<a></a>')
      .attr('class', 'js__edit header-edit')
      .attr('target', targetId)
      .attr('href', this.address)
  }

  hasEditAccess(user) {
    return user.isStaff
  }
}

module.exports = EditLink
