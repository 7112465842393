import cx from 'classnames'

import { typograf, sovietCommentsCount } from 'lib/textHelper'

import { Heading1 } from '../textNodes'
import { birmanize } from 'lib/textHelper'

const MOSCOW_TZ_OFFSET = 3 * 60 * 60 * 1000
const TOO_LONG_TITLE = 100

const formatDate = (unixtime) => {
  const dateWithOffset = new Date(unixtime * 1000)
  const date = new Date(
    dateWithOffset.getTime() +
    ((new Date()).getTimezoneOffset() * 60 * 1000) +
    MOSCOW_TZ_OFFSET
  )

  return birmanize(date, { format: 'dateYearOnly' })
}

const SovietViewsCount = ({ viewsCount }) => <span className="sovietViewsCount">👁 { viewsCount }</span>

const SovietCommentsCount = ({ commentCount, newCommentCount }) => {
  return (
    <span
      className="sovietCommentsCount"
      dangerouslySetInnerHTML={ { __html: sovietCommentsCount(commentCount, newCommentCount) } }
    />
  )
}

export const SovietSplashColumn = (props) => {
  return (
    <div className="sovietSplash-columnWrapper">
      <div className="sovietSplash-column">{ props.column }</div>
    </div>
  )
}

export const SovietTitle = (props) => {
  const className = cx(props.className, { is__tooLong: props.title.length >= TOO_LONG_TITLE })
  const titleText = props.isHangingTitle ? props.title : typograf(props.title)
  const hasImageLeftWithTitle = props.titleSize === 'largest' && (props.headerTemplate === 'imageLeft' || props.headerTemplate === 'imageLeftNarrow')
  const titleWithSwash = [<span class="sansSwash">{ titleText[0] }</span>, titleText.slice(1)]
  const title = hasImageLeftWithTitle ? titleText : titleWithSwash

  if (!props.isFakeHeadingForSeo) return <Heading1 className={ className }>{ title }</Heading1>

  return (
    <div className={`heading-1 is__fakeHeading ${props.className || ''}`}>
      <p>{ title }</p>
    </div>
  )
}

export const SovietMeta = (props) => {
  const hasViews = !!props.viewsCount
  const hasComments = !!props.commentCount || !!props.newCommentCount

  return (
    <div className="sovietMeta-wrapper">
      <div className="sovietMeta">
        { props.questionerName && !props.hideQuestioner &&
          <div className="sovietMeta-name">{ props.questionerName }</div>
        }
        <div className="sovietMeta-date">
          { formatDate(new Date(props.publishAt).getTime() / 1000) }
        </div>

        <div className="sovietMeta-stats">
          { hasViews &&
            <SovietViewsCount viewsCount={ props.viewsCount } />
          }

          { hasViews && '  ' }

          { hasComments &&
            <SovietCommentsCount { ...props } />
          }
        </div>
      </div>
    </div>
  )
}
