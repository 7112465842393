import { Component } from 'preact'
import { Heading1 } from '../textNodes'
import { Cols } from '../cols'

import SovietAskFormCaption from './sovietAskFormCaption'

export default class SovietAskFormSuccessFrame extends Component {
  render() {
    return (
      <div>
        <Heading1 className="is__large">
          { I18n.t('soviet.askForm.thankYou') }
        </Heading1>
        <Cols className="sovietAskFormSuccessFrame" divisionProportions="11:1:4" transposeOnMobile>
          <div>
            <p className="sovietAskFormSuccessFrame-caption" dangerouslySetInnerHTML={{ __html: I18n.t('soviet.askForm.questionSent') }} />

            <div className="module device device__desktop device__laptop">
              <div className=" is__align-left">
                <a onClick={ this.props.onClick } className="button is__thin">
                  { I18n.t('soviet.askForm.anotherQuestion') }
                </a>
              </div>
            </div>
          </div>

          <div className="module device device__desktop device__laptop emptyCol" />

          <SovietAskFormCaption />
        </Cols>

        <div className="module device device__mobile">
          <div className=" is__align-left">
            <a onClick={ this.props.onClick } className="button is__thin">
              { I18n.t('soviet.askForm.anotherQuestion') }
            </a>
          </div>
        </div>

      </div>
    )
  }
}
