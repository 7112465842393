import { Component } from 'preact'
import { connect } from 'preact-redux'

import { changePaymentMethodToNewCard } from './newPaybarActions'


class NewPaybarNoticePaymentFailed extends Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  get isActionable() {
    const { isUserAuthenticated, userCardNumber } = this.props

    return this.includesCardStatement && isUserAuthenticated && !!userCardNumber
  }

  get includesCardStatement() {
    return ['paymentFailed', 'paymentFailedAmountExceed'].includes(this.noticeType)
  }

  get noticeType() {
    return this.props.noticeType
  }

  get notificationParams() {
    const productEmail = I18n.t(`product.email.${this.props.product}`)
    const paybarAccessToken = this.props.accessToken || ''

    return { productEmail, paybarAccessToken }
  }

  get notificationText() {
    if (this.isActionable) {
      return I18n.t(`newPaybar.notice.${this.noticeType}Actionable`, this.notificationParams)
    }

    return I18n.t(`newPaybar.notice.${this.noticeType}`, this.notificationParams)
  }

  onClick(e) {
    if (e.target && e.target.nodeName === 'A' && /javascript:/.test(e.target.href)) {
      this.props.changePaymentMethodToNewCard()
    }
  }

  render() {
    return (
      <div className="newPaybarNoticePaymentFailed">
        <span class="notification-icon">
          <svg width="19" height="13" xmlns="http://www.w3.org/2000/svg">
          <g fill="#000" fill-rule="evenodd"><path d="M0 3h19v3H0z" />
          <path d="M1 1v11h17V1H1zm0-1h17a1 1 0 0 1 1 1v11a1 1 0 0 1-1
          1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z" fill-rule="nonzero" /></g></svg>
        </span>

        <span
          className="newPaybarNotice-message"
          onClick={ this.onClick }
          dangerouslySetInnerHTML={{ __html: this.notificationText }} />
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    userEmail: newPaybar.userEmail,
    noticeType: newPaybar.noticeType,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    userCardNumber: newPaybar.userCardNumber,
    accessToken: newPaybar.accessToken,
  }
}

const mapDispatchToProps = { changePaymentMethodToNewCard }

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarNoticePaymentFailed)
