import { Component } from 'preact'
import { connect } from 'preact-redux'
import { birmanize } from 'lib/textHelper'
import cx from 'classnames'

import { Heading2, TextNode } from '../../textNodes'
import { getSchoolKeyFromSubscription, getSchoolNameFromSubscription } from './schoolHelpers'

const MIN_DESKTOP_WIDTH = 960
const MIN_MOBILE_WIDTH = 320
const DESKTOP_ROCKET_HEIGHT = '65%'
const MOBILE_ROCKET_HEIGHT = '80%'
const NARROW_MOBILE_ROCKET_HEIGHT = '78.5%'

class NewPaybarOverlayPrep extends Component {
  constructor(props) {
    super(props)

    this.$document = $(document)
    this.gridSpacing = window.application.gridSpacing

    this.state = this.defaultState
  }

  componentDidMount() {
    this.resetRocketHeight()
    this.setRocketHeight()

    this.$document.on('appResize.overlayPrep', () => {
      this.resetRocketHeight()
      setTimeout(() => this.setRocketHeight())
    })
  }

  componentWillUnmount() {
    this.$document.off('appResize.overlayPrep')
  }

  componentWillUpdate() {
    if (!this.rocket) return

    this.resetRocketHeight()
  }

  componentDidUpdate() {
    if (!this.rocket) return

    this.setRocketHeight()
  }

  setRocketHeight() {
    const height = `${ this.leadRocketHeightToGrid }px`

    if (this.state.height !== height) {
      this.setState({ height })
    }
  }

  get isMobile() {
    return window.innerWidth < MIN_DESKTOP_WIDTH
  }

  get isNarrowMobile() {
    return window.innerWidth <= MIN_MOBILE_WIDTH
  }

  resetRocketHeight() {
    this.setState(this.defaultState)
  }

  get defaultState() {
    if (this.isNarrowMobile) return { height: NARROW_MOBILE_ROCKET_HEIGHT, width: 'auto' }

    if (this.isMobile) return { height: MOBILE_ROCKET_HEIGHT, width: 'auto' }

    return { height: DESKTOP_ROCKET_HEIGHT, width: 'auto' }
  }

  get leadRocketHeightToGrid() {
    return (this.rocket.offsetHeight / this.gridSpacing).toFixed() * this.gridSpacing
  }

  get resultScreenHeadingText() {
    const schoolName = getSchoolNameFromSubscription(this.props.subscription)

    return I18n.t(`newPaybar.successScreen.${this.props.product}.${ this.props.type }.heading`, { schoolName })
  }

  get resultScreenText() {
    if (this.noAccess) return this.resultNoAccessText

    return I18n.t(`newPaybar.successScreen.${this.props.product}.${ this.props.type }.textWithDate`,
      { startUntil: this.subscriptionValidUntil })
  }

  get resultScreenAccessText() {
    if (this.props.isUserAuthenticated && this.props.type === 'self') return ''

    const email = this.props.type === 'gift' ? this.props.presenteeEmail : this.props.email
    const startUntil = this.subscriptionValidUntil

    return I18n.t(`newPaybar.successScreen.${this.props.product}.${ this.props.type }.accessText`,
      { email, startUntil })
  }

  get resultNoAccessText() {
    const suffix = this.props.isUserAuthenticated ? 'User' : ''

    return I18n.t(`newPaybar.successScreen.${this.props.product}.${ this.props.type }.textNoAccess${suffix}`)
  }

  get subscriptionValidUntil() {
    return birmanize(this.props.newSubscriptionValidUntil, { format: 'fullMonthDateYearOnly' })
  }

  get noAccess() {
    return !this.props.isUserAuthenticated || this.props.type === 'gift'
  }

  get imageSrc() {
    return `/assets/images/school/paybar-${this.isPrep ? 'prep-rocket' : this.schoolKey}.png`
  }

  get schoolKey() {
    if (this.isPrep) return 'prep'

    return getSchoolKeyFromSubscription(this.props.subscription)
  }

  get isPrep() {
    return this.props.product === 'prep'
  }

  render() {
    const isSelfUnauthenticated = this.props.type === 'self' && !this.props.isUserAuthenticated
    const classNameOverlayLock = cx('newPaybarOverlay-lock', { is__disabled: !isSelfUnauthenticated })

    const shouldHideStub = this.props.product === 'school' && this.props.type === 'self' && this.props.isUserAuthenticated

    return (
      <div className="newPaybarOverlay-content">
        <div className="newPaybarOverlay-message">
          <Heading2 html={ this.resultScreenHeadingText }/>
          <TextNode html={ this.resultScreenText }/>
        </div>

        <div className="newPaybarOverlay-rocket"
          ref={ el => this.rocket = el }
          style={ { height: this.state.height } }>
          <img className={ `newPaybarShelf-rocketImage is__${this.schoolKey}` } src={ this.imageSrc }/>
        </div>

        { this.noAccess &&
          <div className="newPaybarOverlay-access">
            <span className={ classNameOverlayLock }>
              { I18n.t('newPaybar.successScreen.lock') }
            </span>
            <TextNode html={ this.resultScreenAccessText }/>
          </div>
        }

        { this.noAccess && !shouldHideStub &&
          <div className="newPaybarOverlay-stub"></div>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    subscription: newPaybar.subscription,
    newSubscriptionValidUntil: newPaybar.newSubscriptionValidUntil,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
  }
}

export default connect(mapStateToProps)(NewPaybarOverlayPrep)
