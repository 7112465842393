import { Component } from 'preact'
import cx from 'classnames'
import { formatNumber } from 'lib/textHelper'

import TaskResultMarkAverageCorrectionTable from './taskResultMarkAverageCorrectionTable'

const ScoreCell = ({ marks, reviewerId, part }) => {
  return (<td><p>{ formatNumber(marks[reviewerId].scores[part]) || '—' }</p></td>)
}

const CORRECTION_PART = 'correction'

class TaskResultMarkPartsTable extends Component {
  constructor(props) {
    super(props)

    this.state = this.parseScoreParts()
  }

  componentWillMount() {
    this.setVisiblity()
  }

  componentDidMount() {
    this.setSize()

    window.addEventListener('resize', this.setSize.bind(this))
  }

  setVisiblity() {
    let isVisible

    if (this.props.userIsStaff) {
      const isTeacherMark = ['teacher', 'teachers'].includes(this.props.markType)

      isVisible = !isTeacherMark && this.state.reviewerIds.length
    } else {
      isVisible = this.props.abilities.can_see_score && this.props.scoreParts.length
    }

    this.setState({ isVisible: !!isVisible })
  }

  setSize() {
    const viewWidth = this.table.parentNode.clientWidth

    if (this.minFullTableWidth) {
      this.setState({ isShrinked: this.minFullTableWidth > viewWidth })
    } else if (this.table.clientWidth > viewWidth) {
      this.minFullTableWidth = this.table.clientWidth

      this.setState({ isShrinked: true })
    }

    $(document).trigger('resultsTableRendered')
  }

  parseScoreParts() {
    if (!this.props.scoreParts) return {}

    const marks = {}

    this.props.scoreParts.forEach(part => {
      if (part.column === CORRECTION_PART) return
      if (this.props.bonusMarkParts.includes(part.column)) return

      if (!marks[part.reviewer_id]) {
        marks[part.reviewer_id] = {
          name: part.reviewer_name,
          scores: {},
        }
      }

      marks[part.reviewer_id].scores[part.column] = part.score
    })

    return {
      marks,
      reviewerIds: Object.keys(marks),
    }
  }

  get bonuses() {
    return (this.props.scoreParts || [])
      .filter(part => part.score)
      .filter(part => this.props.bonusMarkParts.includes(part.column))
  }

  render(props) {
    const { marks } = this.state
    const shouldDisplayReviewerName = this.state.reviewerIds.length > 1

    return (
      <div className={ cx('taskResultTable-wrap', { is__hidden: !this.state.isVisible }) }>
        <div className="device desktop laptop">
          <table className="taskResultTable" ref={ el => this.table = el }>
            <thead className={ cx('text', { is__small: this.state.isShrinked }) }>
              <tr>
                { shouldDisplayReviewerName && <th></th> }
                { props.parts.map(part => (
                  <th><p>{ part }</p></th>
                ))}
              </tr>
            </thead>

            <tbody>
              { this.state.reviewerIds.map(id => (
                <tr>
                  { shouldDisplayReviewerName &&
                    <th><p>{ marks[id].name }</p></th>
                  }

                  { props.parts.map(part => (
                    <ScoreCell marks={ marks } reviewerId={ id } part={ part } />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="device mobile">
          { props.canSeeScore && !props.userIsStaff &&
            <TaskResultMarkAverageCorrectionTable
              average={ props.average }
              bonuses={ this.bonuses }
              correction={ props.correction} />
          }

          <div className="taskResultTable-reviewers">
            { this.state.reviewerIds.map(id => (
              <div className="taskResultTable-reviewer">
                <table className={ `taskResultTable reviewer-${id}` }>
                  { shouldDisplayReviewerName &&
                    <thead>
                      <tr><th colSpan="2"><p>{ marks[id].name }</p></th></tr>
                    </thead>
                  }

                  <tbody>
                    {props.parts.map(part => (
                      <tr>
                        <td className="taskResultTable-partName"><p>{ part }</p></td>
                        <ScoreCell marks={ marks } reviewerId={ id } part={ part } />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

module.exports = TaskResultMarkPartsTable
