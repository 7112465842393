import { Component } from 'preact'

export default class NewPaybarBasementSubscriptionLicense extends Component {
  render(props) {
    return (
      <div className="newPaybarBasementSubscriptionLicense textBox">
        <div dangerouslySetInnerHTML={{ __html: props.licenseContents }} />
      </div>
    )
  }
}
