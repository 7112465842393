import { Component } from 'preact'
import { connect } from 'preact-redux'

import { setLayout, setTextColor, setCustomThemeLayout, setCustomThemeTextColor } from './sovietCompilationActions'

import { IconSelector, IconSelectorItem } from '../iconSelector'

const LeftLayoutIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M3,4H31V6H3ZM3,9H25v2H3ZM3,19H25v2H3Zm0-5H31v2H3Z"/><path class="icon-description" d="M3,32H20v1H3Zm0,3H20v1H3Zm0,3H13v1H3Z"/></svg>)
const TopLayoutIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M3,4H23V6H3ZM3,9H19v2H3ZM3,19H19v2H3Zm0-5H23v2H3Z"/><path class="icon-description" d="M27,4H40V5H27Zm0,3H40V8H27Zm0,3h8v1H27Z"/></svg>)
const BottomLayoutIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M3.5,22h20v2H3.5Zm0,5h16v2H3.5Zm0,10h16v2H3.5Zm0-5h20v2H3.5Z"/><path class="icon-description" d="M27.5,22h13v1h-13Zm0,3h13v1h-13Zm0,3h8v1h-8Z"/></svg>)
const CenterLayoutIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43"><path class="icon-title" d="M7,7H36V9H7Zm3,5H33v2H10ZM7,17H36v2H7Z"/><path class="icon-description" d="M14,28H29v1H14Zm0,3H29v1H14Zm3,3h9v1H17Z"/></svg>)

class SovietCompilationLayoutSwitch extends Component {
  constructor(props) {
    super(props)

    this.setThemeLayout = this.setThemeLayout.bind(this)
    this.setThemeTextColor = this.setThemeTextColor.bind(this)
  }

  setThemeLayout(layout) {
    if (this.props.theme === 'custom') {
      this.props.setCustomThemeLayout(layout)
    } else {
      this.props.setLayout(layout)
    }
  }

  setThemeTextColor(textColor) {
    if (this.props.theme === 'custom') {
      this.props.setCustomThemeTextColor(textColor)
    } else {
      this.props.setTextColor(textColor)
    }
  }

  render(props) {
    if (props.isReadOnly) return

    return (
      <div className="compilationLayoutSwitch">
        <IconSelector className="compilationLayoutSwitch-layout">
          <IconSelectorItem
            className={`is__left ${props.layout === 'left' ? 'is__active' : ''}`}
            onClick={ () => this.setThemeLayout('left') }>
            <LeftLayoutIcon />
          </IconSelectorItem>
          <IconSelectorItem
            className={`is__top ${props.layout === 'top' ? 'is__active' : ''}`}
            onClick={ () => this.setThemeLayout('top') }>
            <TopLayoutIcon />
          </IconSelectorItem>
          <IconSelectorItem
            className={`is__bottom ${props.layout === 'bottom' ? 'is__active' : ''}`}
            onClick={ () => this.setThemeLayout('bottom') }>
            <BottomLayoutIcon />
          </IconSelectorItem>
          <IconSelectorItem
            className={`is__center ${props.layout === 'center' ? 'is__active' : ''}`}
            onClick={ () => this.setThemeLayout('center') }>
            <CenterLayoutIcon />
          </IconSelectorItem>
        </IconSelector>

        <IconSelector className="compilationLayoutSwitch-color">
          <IconSelectorItem
            className={`is__white ${props.textColor === 'white' ? 'is__active' : ''}`}
            onClick={ () => this.setThemeTextColor('white') }>A
          </IconSelectorItem>
          <IconSelectorItem
            className={`is__black ${props.textColor === 'black' ? 'is__active' : ''}`}
            onClick={ () => this.setThemeTextColor('black') }>A
          </IconSelectorItem>
        </IconSelector>
      </div>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    theme: sovietCompilation.theme,
    layout: sovietCompilation.layout,
    textColor: sovietCompilation.textColor,
    customThemeLayout: sovietCompilation.customThemeLayout,
    customThemeTextColor: sovietCompilation.customThemeTextColor,
    isReadOnly: sovietCompilation.isReadOnly,
  }
}

const mapDispatchToProps = {
  setLayout,
  setTextColor,
  setCustomThemeLayout,
  setCustomThemeTextColor,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationLayoutSwitch)
