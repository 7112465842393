import { Component } from 'preact'
import cx from 'classnames'

import JSON_HEADERS from 'jsonHeaders'

import { recalculateCountPadding } from './impressionsUtils'
import { Caption } from '../textNodes'

const IMPRESSIONS_DEFAULT_STATE = { selected: '', helpful: '', useless: '' }
const DEFAULT_PAGE_ID = '/'

const UpvoteButton = (props) => {
  const unpaddedCount = parseInt(props.count, 10)
  const classes = cx(
    'button',
    'is__thin',
    'impressions-helpful',
    {
      is__selected: props.selected && !props.disabled,
      is__disabled: props.disabled,
      is__unauthenticated: props.unauthenticated,
      is__zero: !unpaddedCount,
    }
  )

  return (
    <div
      className={ classes }
      onClick={ props.onClick }
      { ...{ disabled: props.disabled } }>
      <div className="impressions-caption">
        { I18n.t('impressions.upvote') }
      </div>
      <div className="impressions-counter">
        { props.count }
      </div>
      <div className="impressions-counter impressions-counter__alternate">
        { unpaddedCount || '' }
      </div>
    </div>
  )
}

const DownvoteButton = (props) => {
  const unpaddedCount = parseInt(props.count, 10)
  const classes = cx(
    'button',
    'is__thin',
    'impressions-useless',
    {
      is__selected: props.selected && !props.disabled,
      is__disabled: props.disabled,
      is__unauthenticated: props.unauthenticated,
      is__zero: !unpaddedCount,
    }
  )

  return (
    <div
      className={ classes }
      onClick={ props.onClick }
      { ...{ disabled: props.disabled } }>
      <div className="impressions-caption">
        { I18n.t('impressions.downvote') }
      </div>
      <div className="impressions-counter">
        { props.count }
      </div>
      <div className="impressions-counter impressions-counter__alternate">
        { unpaddedCount || '' }
      </div>
    </div>
  )
}

class Impressions extends Component {
  constructor(props) {
    super(props)
    this.pageId = encodeURIComponent(props.impressionSubject || this.defaultPageId)
    this.changeEventId = `impressionChange:${this.pageId}`

    this.initialState = { ...IMPRESSIONS_DEFAULT_STATE, ...props }

    this.state = {
      ...IMPRESSIONS_DEFAULT_STATE,
      ...props,
      ...{ isAuthorized: !!window.application.user },
    }

    this.onSimilarImpressionChange = this.onSimilarImpressionChange.bind(this)
    this.voteHelpful = this.voteHelpful.bind(this)
    this.voteUseless = this.voteUseless.bind(this)
  }

  componentDidMount() {
    if (this.props.helpful === undefined || this.props.useless === undefined) {
      this.fetchImpressionsSummary()
    }

    $(document).on(this.changeEventId, this.onSimilarImpressionChange)
  }

  componentWillUnmount() {
    $(document).off(this.changeEventId, this.onSimilarImpressionChange)
  }

  get defaultPageId() {
    const pageId = $('meta[name="pageId"]').attr('content')

    if (pageId !== DEFAULT_PAGE_ID) return pageId

    return window.location.pathname
  }

  onSimilarImpressionChange(_, summary) {
    this.updateStateFromSummary(summary)
  }

  voteHelpful(e) {
    e.stopPropagation()
    e.preventDefault()

    if (this.state.selected === 'helpful') {
      this.deleteImpression('helpful')
    } else {
      this.setImpression('helpful')
    }
  }

  voteUseless(e) {
    e.stopPropagation()
    e.preventDefault()

    if (this.state.selected === 'useless') {
      this.deleteImpression('useless')
    } else {
      this.setImpression('useless')
    }
  }

  fetchImpressionsSummary() {
    fetch(`/impression_summaries/${this.pageId}`, {
      method: 'GET',
      headers: JSON_HEADERS,
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) return res.json()

        return Promise.resolve(this.state)
      })
      .then((json) => {
        this.updateStateFromSummary(json)
        $(document).trigger(this.changeEventId, json)
      })
  }

  updateStateFromSummary(summary) {
    const { selected, helpful, useless } = summary

    this.setState(
      { selected, helpful, useless },
      () => { this.initialState = { ...this.state } }
    )
  }

  setImpression(kind) {
    if (!this.state.isAuthorized) return

    this.initialState = { ...this.state }

    this.setState({
      selected: kind,
      [kind]: this.state[kind] + 1,
    })

    fetch(`/impressions/${this.pageId}`, {
      method: 'PUT',
      headers: JSON_HEADERS,
      credentials: 'include',
      body: JSON.stringify({ kind: kind }),
    })
      .then(res => this.handleImpressionChange(res, this.initialState))
  }

  deleteImpression(kind) {
    if (!this.state.isAuthorized) return

    this.initialState = { ...this.state }

    this.setState({
      selected: '',
      [kind]: Math.max(this.state[kind] - 1, 0),
    })

    fetch(`/impressions/${this.pageId}`, {
      method: 'DELETE',
      headers: JSON_HEADERS,
      credentials: 'include',
    })
      .then(res => this.handleImpressionChange(res))
  }

  handleImpressionChange(res) {
    if (res.ok) {
      this.fetchImpressionsSummary()
    } else {
      this.setState(this.initialState)
    }
  }

  render(props) {
    const classes = cx(
      'impressions',
      {
        is__disabled: props.disabled,
        is__unauthenticated: !this.state.isAuthorized,
        is__inversed: props.isInversed,
        is__regular: !/(small|medium)/.test(props.mod),
      },
      String(props.mod).split(' ').filter(m => m).map(m => `is__${m}`)
    )

    const helpfulCount = recalculateCountPadding(this.initialState.helpful, this.state.helpful)
    const uselessCount = recalculateCountPadding(this.initialState.useless, this.state.useless)

    return (
      <div className={ classes }>
        <div className="buttonGroup">
          <UpvoteButton
            selected={ this.state.selected === 'helpful' }
            count={ helpfulCount }
            disabled={ props.disabled }
            unauthenticated={ !this.state.isAuthorized }
            onClick={ this.voteHelpful } />
          <DownvoteButton
            selected={ this.state.selected === 'useless' }
            count={ uselessCount }
            disabled={ props.disabled }
            unauthenticated={ !this.state.isAuthorized }
            onClick={ this.voteUseless } />
        </div>

        { !this.state.isAuthorized &&
          <Caption className="impressions-hint" html={ I18n.t('impressions.hint') }></Caption>
        }
      </div>
    )
  }
}

export default Impressions
