import cx from 'classnames'

import Price from '../price/price'
import { Heading3, Caption } from '../textNodes'

export default function ShelfPrices(props) {
  return (
    <div className={ cx('shelfPrices module', { is__strikedOut: props.isStrikedOut }) }>

      { !props.omitHeading &&
        <Heading3>{ props.title }</Heading3>
      }

      <div className={ cx('shelfPrices-list', { has__caption: props.hasCaption }) }>
        <div className="shelfPrices-item">
          <Price
            className={ props.isApplicableForDiscount ? 'is__applicableForDiscount' : '' }
            label={ props.oneTimePriceLabel }
            amount={ props.oneTimePrice }
            units={ props.oneTimePriceUnits }
            highlighted={ props.highlighted }
            smallUnits={ props.smallUnits } />
        </div>

        <div className="shelfPrices-item">
          <Price
            className={props.isApplicableForSubscriptionDiscount ? 'is__applicableForDiscount' : ''}
            label={ props.subscriptionPriceLabel }
            amount={ props.subscriptionPrice }
            units={ props.subscriptionPriceUnits }
            highlighted={ props.highlightedSubscriptionPrice }
            smallUnits={ props.smallUnits } />
        </div>
      </div>

      { props.hasCaption &&
        <Caption html={ props.captionText } />
      }
    </div>
  )
}
