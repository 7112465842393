import { Component } from 'preact'
import { connect } from 'preact-redux'

import isSubscribed from '../isSubscribed'
import isLaunchpad from '../isLaunchpad'

import NewPaybarPricing from '../newPaybarPricing'
import NewPaybarDevices from './newPaybarDevices'
import NewPaybarSubscriptionStatus from './newPaybarSubscriptionStatus'
import NewPaybarTitle from '../newPaybarTitle'
import NewPaybarPayer from '../newPaybarPayer'
import NewPaybarPersonalForm from '../newPaybarPersonalForm'
import NewPaybarOverlay from '../newPaybarOverlay'
import NewPaybarGiftMessage from '../newPaybarGiftMessage'
import NewPaybarLaunchpadSet from './newPaybarLaunchpadSet'
import expandScreenToFlags from '../expandScreenToFlags'
import NewPaybarUpgrade from './newPaybarUpgrade'
import NewPaybarGlobalAccessButtons from './newPaybarGlobalAccessButtons'
import NewPaybarSpecialPriceLabel from '../newPaybarSpecialPriceLabel'
import NewPaybarRequirementsAndMobileVersion from './newPaybarRequirementsAndMobileVersion'
import NewPaybarSubscriptionStatusMobile from '../newPaybarSubscriptionStatusMobile'
import NewPaybarTabs from '../newPaybarTabs'
import isSubscriptionCancelled from '../isSubscriptionCancelled'
import isCompanySubscription from '../isCompanySubscription'

const ALL_BOOKS = 'all'
const SOME_BOOKS = 'some'
const NOT_ONE = 'none'

const withMobileVersion = book => book.hasMobileVersion

class NewPaybarBookshelf extends Component {
  get allHaveMobileVersion() {
    const { books } = this.props

    return books.length > 0 && books.every(withMobileVersion)
  }

  get someHaveMobileVersion() {
    const { books } = this.props

    return books.some(withMobileVersion)
  }

  get hasMobileVersion() {
    return this.props.hasMobileVersion || this.allHaveMobileVersion || this.someHaveMobileVersion
  }

  get howManyBooksHaveMobileVersion() {
    if (this.allHaveMobileVersion) return ALL_BOOKS
    if (this.someHaveMobileVersion) return SOME_BOOKS

    return NOT_ONE
  }

  get isUpgrade() {
    const { type, prices } = this.props

    return type === 'upgrade' && prices.specialOffer
  }

  get hasReaderDiscount() {
    return this.props.prices.isApplicableForReaderDiscount
  }

  get shouldDisplaySpecialPriceLabel() {
    const { type, isPurchaseScreen } = this.props

    if (!isPurchaseScreen || type === 'company') return false

    return this.hasReaderDiscount || this.isUpgrade
  }

  get isBookshelf() {
    return this.props.product === 'bookshelf'
  }

  get hasCompactUnitsView() {
    const { hasCompactUnitsView } = this.props

    return this.isBookshelf || hasCompactUnitsView
  }

  render(props) {
    const isCompanyEdit = isCompanySubscription(props)
    const isCancelledSubscription = isSubscriptionCancelled(props.subscription)

    const shouldDisplayPricing = props.isPurchaseScreen ||
      props.isInitialScreen ||
      isCancelledSubscription

    const shouldDisplaySubscriptionStatus = !isCancelledSubscription &&
      ['self', 'company'].includes(props.type) &&
      (props.isSubscriptionScreen || props.isSuccessScreen)

    const accessProps = { books: props.books, canRead: !props.cannotRead && props.isSubscribed }

    if (!isCancelledSubscription && isLaunchpad(props)) {
      return (
        <NewPaybarLaunchpadSet>
          <NewPaybarGlobalAccessButtons { ...accessProps } />
          { this.props.children }
        </NewPaybarLaunchpadSet>
      )
    }

    return (
      <div className="newPaybar-infoIn module">
        { !props.isSuccessScreen &&
          <NewPaybarTabs
            device="mobile"
            type={ props.type }
            product={ props.product } />
        }

        { this.shouldDisplaySpecialPriceLabel &&
          <div className="newPaybar-label">
            <NewPaybarSpecialPriceLabel text={ I18n.t('newPaybar.specialPriceLabel') }/>
          </div>
        }

        <NewPaybarTitle
          className="newPaybarTitle module device device__mobile"
          type={ props.type }
          screen={ props.screen }
          product={ props.product }
          productTitle={ props.productTitle } />

        { props.isSuccessScreen &&
          <NewPaybarOverlay className="device device__mobile" />
        }

        { shouldDisplayPricing &&
          <div className="newPaybarDevicesAndPricing">
            <NewPaybarDevices
              hasMobileVersion={ this.hasMobileVersion }
              howManyBooksHaveMobileVersion={ this.howManyBooksHaveMobileVersion }
              product={ props.product } />
            <NewPaybarPricing
              product={ props.product }
              type={ props.type }
              productId={ props.productId }
              isPreorder={ props.isPreorder }
              prices={ props.prices }
              smallUnits={ props.smallUnits }
              key={ `newPaybarPricing-${props.isUserAuthenticated}` }
              pricingBottomCaption={ props.pricingBottomCaption }
              hasCompactUnitsView={ this.hasCompactUnitsView } />

            { props.type === 'gift' &&
              <NewPaybarGiftMessage
                prices={ props.prices }
                type={ props.type }
                product={ props.product }
                className="device device__laptop device__desktop" />
            }
          </div>
        }

        { !isCancelledSubscription && (props.isSuccessScreen || props.isSubscriptionScreen) &&
          <NewPaybarRequirementsAndMobileVersion className="module" />
        }

        { this.isUpgrade &&
          <NewPaybarUpgrade
            isMobile
            books={ props.prices.specialOffer.ownedBooks }
            className="device device__mobile" />
        }

        { (isCancelledSubscription || props.isPurchaseScreen) &&
          <NewPaybarPayer payerRef={ props.payerRef } />
        }

        { shouldDisplaySubscriptionStatus &&
          <NewPaybarSubscriptionStatus className="device device__laptop device__desktop" />
        }

        { isCompanyEdit &&
          <NewPaybarSubscriptionStatusMobile type={ props.type } product={ props.product }/>
        }

        { !props.isSuccessScreen &&
          <NewPaybarPersonalForm
            subscriptionUrl={ props.subscriptionUrl }
            scrollableRef={ props.scrollableRef }
            isPreorder={ props.isPreorder }>
            { this.isUpgrade &&
              <NewPaybarUpgrade
                books={ props.prices.specialOffer.ownedBooks }
                className="device device__laptop device__desktop" />
            }
          </NewPaybarPersonalForm>
        }

        <NewPaybarGlobalAccessButtons { ...accessProps } />

        { this.props.children }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    productId: newPaybar.productId,
    productTitle: newPaybar.productTitle,
    screen: newPaybar.screen,
    prices: newPaybar.prices,
    isPreorder: newPaybar.isPreorder,
    books: newPaybar.shelfBooks,
    smallUnits: newPaybar.smallUnits,
    cannotRead: newPaybar.cannotRead,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    isSubscribed: isSubscribed(newPaybar),
    subscription: newPaybar.subscription,
    hasMobileVersion: newPaybar.hasMobileVersion,
    hasCompactUnitsView: newPaybar.hasCompactUnitsView,
    pricingBottomCaption: newPaybar.pricingBottomCaption,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarBookshelf)
