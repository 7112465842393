{
  const $document = $(document)
  const subscriptionEventId = $('#schoolId').val()

  $document.on('appSetUser', function(_, user) {
    if (user && user.name) $('.js__schoolDemoSpacer-button').prop('disabled', false)
  })

  $document.on(`subscriptionChanged.${subscriptionEventId}`, function(_, isSubscribed) {
    const $subscriptionControl = $('.js__schoolDemoSpacerSubscriptionControl')
    const $formFields = $subscriptionControl.find('input')

    $subscriptionControl.toggleClass('has__subscribed', isSubscribed)
    $formFields.prop('disabled', isSubscribed)
  })
}
