export const myMenuItems = [
  {
    title: 'My books',
    className: 'myBooks',
    href: '/books/my/',
  },
  {
    title: I18n.t('userPopup.card.default', { locale: 'en' }),
    className: 'card',
    href: '/pay/edit/',
  },
  {
    title: 'Sign out',
    className: 'logout',
    href: '/burosfera/logout/',
  },
]

export const otherMenuItems = []
