import { Component } from 'preact'

import { Caption } from '../../textNodes'

export default class NewPaybarEmailFormCaptionSchool extends Component {
  get userEmailNotice() {
    if (!this.props.userEmailNotice) return `newPaybar.emailNotices.default.${this.props.type}`

    const suffix = this.props.isPreorder ? 'Preorder' : ''

    return `newPaybar.emailNotices.${this.props.product}.${this.props.userEmailNotice}${suffix}`
  }

  get captionText() {
    return I18n.t(this.userEmailNotice, { email: this.props.userEmail })
  }

  render() {
    return (<Caption html={ this.captionText } />)
  }
}
