import cx from 'classnames'

const VkIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M13.3,20.4h1.5c0,0,0.5,0,0.7-0.3c0.2-0.2,0.2-0.6,0.2-0.6s0-2,0.9-2.3c0.9-0.3,2.1,1.9,3.4,2.8c0.9,0.6,1.7,0.5,1.7,0.5h3.4c0,0,1.7-0.1,0.9-1.5c0-0.1-0.5-1-2.5-2.8c-2.1-1.9-1.8-1.6,0.7-4.9c1.6-2,2.2-3.3,2-3.8s-1.3-0.4-1.3-0.4h-3.8c0,0-0.3,0-0.5,0.1s-0.3,0.4-0.3,0.4s-0.6,1.6-1.4,2.9c-1.7,2.8-2.4,3-2.6,2.8c-0.6-0.4-0.5-1.6-0.5-2.5c0-2.7,0.4-3.9-0.8-4.1c-0.6-0.1-0.9-0.2-1.9-0.2c-1.4,0-2.5,0-3.1,0.3C9.5,7,9.2,7.5,9.4,7.5s0.8,0.1,1.1,0.5c0.4,0.5,0.4,1.7,0.4,1.7s0.2,3.2-0.5,3.6c-0.5,0.3-1.2-0.2-2.7-2.8c-0.8-1.3-1-2-1.3-2.7C6.3,7.7,6.3,7.4,6.1,7.2C5.8,7,5.6,7,5.4,7C4.5,7,1.8,7,1.8,7S1.3,7,1,7.2C0.8,7.4,1,7.8,1,7.8s2.9,6.5,6,9.8C10,20.6,13.3,20.4,13.3,20.4"/></svg>
}

const FbIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M15.2,24v-9.6h3.2l0.5-3.7h-3.8V8.3c0-1.1,0.3-1.8,1.9-1.8h2V3.2C18.7,3.1,17.5,3,16.1,3c-2.9,0-4.8,1.7-4.8,4.9v2.8H8v3.7h3.3V24H15.2z"/></svg>
}

const TwIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M9.8,21.5c7.5,0,11.7-6.2,11.7-11.5V9.5c0.8-0.6,1.5-1.3,2-2.1C22.8,7.7,22,7.9,21.1,8c0.8-0.5,1.5-1.3,1.8-2.2c-0.8,0.5-1.7,0.8-2.6,1c-0.7-0.8-1.8-1.3-3-1.3c-2.3,0-4.1,1.8-4.1,4c0,0.3,0,0.6,0.1,0.9c-3.4-0.1-6.4-1.7-8.4-4.2C4.5,6.9,4.3,7.5,4.3,8.3c0,1.4,0.7,2.7,1.8,3.4c-0.6-0.1-1.3-0.3-1.8-0.6l0,0c0,2,1.4,3.6,3.3,3.9c-0.4,0.2-0.7,0.3-1.1,0.3c-0.3,0-0.5,0-0.8-0.1c0.5,1.6,2,2.8,3.8,2.8c-1.4,1.1-3.2,1.8-5.1,1.8c-0.3,0-0.6,0-1,0C5.3,20.8,7.5,21.5,9.8,21.5"/></svg>
}

const TgIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M22.2,5.1L4.4,12c-1.2,0.5-1.2,1.2-0.2,1.5L8.8,15l10.5-6.7c0.7-0.4,0.9-0.1,0.6,0.2l-8.5,7.8l0,0l0,0l0,0l0,0L11,21c0.5,0,0.8-0.2,1-0.5c0.6-0.6,2.1-2.2,2.1-2.2l4.6,3.4c0.8,0.5,1.5,0.2,1.7-0.8l3-14.4C23.7,5.3,23,4.7,22.2,5.1z"/></svg>
}

const SocialButton = (props) => {
  const classes = cx(
    'compilationSocial-button',
    {
      [props.type]: props.type,
    }
  )
  return (
    <div className={ classes }>
      { props.type === 'twitter' &&
        <TwIcon />
      }
      { props.type === 'facebook' &&
        <FbIcon />
      }
      { props.type === 'vkontakte' &&
        <VkIcon />
      }
      { props.type === 'telegram' &&
        <TgIcon />
      }
    </div>
  )
}

class SovietCompilationSocial {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <div className="compilationSocial likely">
        <SocialButton type="twitter" />
        <SocialButton type="facebook" />
        <SocialButton type="vkontakte" />
        <SocialButton type="telegram" />
      </div>
    )
  }
}
export default SovietCompilationSocial
