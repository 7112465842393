import CardMenuItem from './cardMenuItem'
import MyCourseMenuItem from './myCourseMenuItem'
import BurosferaCpdMenuItem from './burosferaCpdMenuItem'
import MenuItem from './menuItem'

const renderMenuItem = (isAuthenticated, isOpen) => (props, key) => {
  const fullProps = { key, isAuthenticated, isOpen }

  if (props.className === 'card') return (<CardMenuItem { ...props } { ...fullProps } />)
  if (props.className === 'myCourse') return (<MyCourseMenuItem { ...props } { ...fullProps } />)

  if (props.className === 'burosfera') return (<BurosferaCpdMenuItem { ...props } { ...fullProps } />)

  return (<MenuItem { ...props } key={ key } />)
}

export default renderMenuItem
