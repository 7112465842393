import { Component } from 'preact'

import { connect } from 'preact-redux'
import { setScreen, setTypeSelf } from './newPaybarActions'

class NewPaybarAccessButton extends Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    e.stopPropagation()
    this.props.setScreen('subscription')
    this.props.setTypeSelf()
  }

  render() {
    const { product, accessLink } = this.props
    const hasDirectAccess = ['book', 'lecture'].includes(product)
    const ButtonTag = hasDirectAccess ? 'a' : 'button'

    return (
      <ButtonTag
        href={ hasDirectAccess ? accessLink : undefined }
        type={ !hasDirectAccess ? 'button' : undefined }
        onClick={ !hasDirectAccess ? this.onClick : undefined }
        className="newPaybarAccessButton button is__enlargedOnMobiles"
        dangerouslySetInnerHTML={{ __html: I18n.t(`newPaybar.successScreen.${product}.self.access`) }} />
    )
  }
}

const mapDispatchToProps = { setScreen, setTypeSelf }

export default connect(null, mapDispatchToProps)(NewPaybarAccessButton)
