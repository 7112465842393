import { Component } from 'preact'
import cx from 'classnames'

class NewPaybarTabsSwitcher extends Component {
  render(props) {
    const className = cx('newPaybarTabsSwitcher', {
      is__active: props.isActive,
      is__upgrade: props.isUpgrade,
    })

    return (
      <button className={ className }
        onClick={ props.onClick }
        type="button"
      >
        <span className="newPaybarTabsSwitcher-in device desktop laptop">
          { props.text }
        </span>
        <span className="newPaybarTabsSwitcher-in device mobile">
          { props.mobileText || props.text }
        </span>
      </button>
    )
  }
}

export default NewPaybarTabsSwitcher
