import { Component } from 'preact'
import { birmanize } from 'lib/textHelper'
import cx from 'classnames'

import getCurrentEnrollment from '../enrollmentHelper'

import { Caption } from '../../textNodes'

const DAYS_TO_WARNING = 5

export default class NewPaybarFooterCaptionSchool extends Component {
  get purchaseText() {
    return I18n.t(`newPaybar.submitButtonCaption.${this.props.product}.${this.props.type}`)
  }

  get isStudying() {
    const enrollment = getCurrentEnrollment(this.props.subscription)

    return enrollment.status === 'active'
  }

  get subscriptionText() {
    if (!this.props.isUserAuthenticated) return null

    if (this.props.isPreorder) return this.subscriptionPreorderText

    if (this.isStudying) return I18n.t('newPaybar.school.subscriptionStudying')

    const validUntil = birmanize(this.props.subscription.validUntil, { format: 'fullMonthDateYearOnly' })
    return I18n.t('newPaybar.school.subscription', { date: validUntil })
  }

  get subscriptionPreorderText() {
    const hasEmailSentNotice = this.props.noticeType === 'emailSent'

    return hasEmailSentNotice ? null : I18n.t('newPaybar.school.subscriptionPreorder')
  }

  get captionText() {
    if (this.props.isPurchaseScreen) return this.purchaseText
    if (this.props.isSubscriptionScreen) return this.subscriptionText

    return null
  }

  get isFewDaysLeft() {
    if (this.props.isPreorder || !this.props.subscription.valid_until) return false

    const warningDate = new Date(this.props.subscription.valid_until)
    warningDate.setDate(warningDate.getDate() - DAYS_TO_WARNING)
    warningDate.setHours(0, 0, 0)

    return new Date() > warningDate
  }

  render() {
    if (!this.captionText) return null

    return (
      <Caption
        className={ cx('newPaybarFooterCaption', { is__fewDaysLeft: this.isFewDaysLeft }) }
        html={ this.captionText } />
    )
  }
}
