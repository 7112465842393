import JSON_HEADERS from 'jsonHeaders'
import { extractMeta } from 'lib/metaHelper'
import FormFieldsStorage from './formFieldsStorage'

const TRACKABLE_SCREENS = ['initial', 'purchase']

class PurchaseIntentTracking {
  constructor() {
    this.formFieldsStorage = new FormFieldsStorage()

    this.savePurchaseIntent = this.savePurchaseIntent.bind(this)
  }

  get intentData() {
    return {
      productId: this.lastState.productId,
      intent: this.lastState.type,
      coupon: this.lastState.prices.coupon,
      userEmail: this.lastState.userEmail,
      // FIXME: add presentee email to state
      presenteeEmail: this.formFieldsStorage.get().presenteeEmail,
      meta: extractMeta(),
    }
  }

  savePurchaseIntent() {
    if (!this.lastState.userEmail) return

    if (!TRACKABLE_SCREENS.includes(this.lastState.screen)) return

    fetch('/purchase_intents/', {
      headers: JSON_HEADERS,
      method: 'POST',
      body: JSON.stringify(this.intentData),
    }).catch(e => console.error(e)) // eslint-disable-line no-console
  }

  start({ state }) {
    this.lastState = state

    $(window)
      .off('beforeunload.purchaseIntentTracking')
      .on('beforeunload.purchaseIntentTracking', this.savePurchaseIntent)
  }

  cancel() {
    this.lastState = undefined

    $(window).off('beforeunload.purchaseIntentTracking')
  }
}

module.exports = PurchaseIntentTracking
