import { Component } from 'preact'

import { Cols } from '../cols'
import { Heading2 } from '../textNodes'

import LectureFollowingNavigationItem from './lectureFollowingNavigationItem'

const MAX_LECTURES = 6

const toUrl = ({ slug }) => `/lectures/${slug}/`
const addUrl = (lecture) => ({ ...lecture, ...{ url: toUrl(lecture) } })

export default class LectureFollowingLecturesNavigation extends Component {
  render() {
    let { lectures } = this.props
    if (!lectures || !lectures.length) return null

    const columns = Array(MAX_LECTURES).fill('something')
    lectures = lectures.map(addUrl)

    return (
      <div className="lectureFollowingLecturesNavigation">
        <Heading2>{ I18n.t('lectures.navigation.following.title') }</Heading2>
        <div className="horizontalScrollableModule is__fade is__noScrollbar">
          <div className="horizontalScrollableModuleWrapper">
            <div className="horizontalScrollableModuleContent">
              <Cols>
              { columns.map((_, index) => {
                return (<LectureFollowingNavigationItem item={ lectures[index] } />)
              })}
              </Cols>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
