import { Image } from '../image'
import { Caption } from '../textNodes'

import { typograf } from 'lib/textHelper'

const SovietSplashAvatar = ({ authorAvatarUrl, authorArchiveUrl, authorName, authorCaption }) => {
  return (
    <div className="sovietSplashAvatar">
      <div className="sovietSplashAvatar-image">
        <Image src={ authorAvatarUrl } size={ [144, 144] } />
      </div>
      <div className="sovietSplashAvatar-caption">
        <Caption html={ `<a href="/soviet/${authorArchiveUrl}/">${authorName}</a>\
          <br />\
          ${typograf(authorCaption)}` } />
      </div>
    </div>
  )
}

export default SovietSplashAvatar
