import { Component } from 'preact'
import cx from 'classnames'
import BadgePhone from '../badgePhone/badgePhone'

const COVER_RATIOS = {
  bookshelf: 0.61971831,
  book: 0.7072072072,
}

const MAX_MOBILE_SCREEN_WIDTH = 961

class NewPaybarShelf extends Component {
  constructor(props) {
    super(props)

    this.$document = $(document)

    this.isAnimated = this.props.type === 'animated'
    this.animationName = this.isAnimated ? 'showOverlayShelf' : 'none'
    this.translateY = this.isAnimated ? 'translateY(100%)' : 'translateY(0)'
    this.gridSpacing = window.application.gridSpacing
    this.need3dCovers = this.props.product === 'bookshelf'

    this.state = {
      parentHeight: 'auto',
      bookHeight: 'auto',
      bookWidth: 'auto',
      maxParentWidth: 'none',
      animationName: this.animationName,
      translateY: this.translateY,
    }
  }

  get isMobile() {
    return window.application.viewWidth < MAX_MOBILE_SCREEN_WIDTH
  }

  componentDidMount() {
    this.setParentHeight()

    this.$document.on('appResize', () => {
      this.updateAnimationName()
      this.setParentHeight()
      setTimeout(() => this.setImageSize())
    })
  }

  updateAnimationName() {
    if (this.isAnimated) {
      this.animationName = 'none'
      this.translateY = 'translateY(0)'

      if (this.state.animationName !== this.animationName
          && this.state.translateY !== this.translateY) {
        this.setState({
          animationName: this.animationName,
          translateY: this.translateY,
        })
      }
    }
  }

  componentDidUpdate() {
    this.setImageSize()
  }

  setParentHeight() {
    const parentHeight = this.props.mobileHeight && this.isMobile
      ? this.props.mobileHeight
      : this.props.height

    this.setState({ parentHeight: parentHeight })
  }

  setImageSize() {
    if (!this.books) return

    const parentHeight = this.props.resized ? this.leadBooksHeightToGrid : this.books.offsetHeight
    const bookHeight = parentHeight
    const coverRatio = COVER_RATIOS[this.props.product]
    const bookWidth = coverRatio * bookHeight
    const maxParentWidth = bookWidth * this.props.books.length

    if (this.state.parentHeight !== parentHeight) {
      this.setState({ parentHeight: parentHeight })
    }

    if (this.state.bookHeight !== bookHeight
        && this.state.bookWidth !== bookWidth
        && this.state.maxParentWidth !== maxParentWidth) {
      this.setState({ bookWidth, bookHeight, maxParentWidth })
    }
  }

  get leadBooksHeightToGrid() {
    return (this.books.offsetHeight / this.gridSpacing).toFixed() * this.gridSpacing
  }

  get shelfStyles() {
    return {
      height: this.state.parentHeight,
      maxWidth: this.state.maxParentWidth,
      animationName: this.state.animationName,
      transform: this.state.translateY,
    }
  }

  get bookImageStyles() {
    return { height: this.state.bookHeight, width: this.state.bookWidth }
  }

  bookStyles(bookKey) {
    switch (this.props.type) {
      case 'locked': return `
        max-width: ${this.state.bookWidth}px;
        -webkit-mask-image: url(/projects/book-${bookKey}/files/_book-cover-3d.png);
        mask-image: url(/projects/book-${bookKey}/files/_book-cover-3d.png);
      `
      default: return `max-width: ${this.state.bookWidth}px;`
    }
  }

  bookImageSrc(bookKey) {
    const { product, productId } = this.props

    const need3DImage = product === 'bookshelf'
    const base = productId ? `/projects/${productId}` : `/projects/book-${bookKey}`
    const filename = need3DImage ? '_book-cover-3d.png' : '_book-cover-face.jpg'

    return `${base}/files/${filename}`
  }

  bookHref(book) {
    const cannotRead = book.cannotRead || this.props.cannotRead

    if (cannotRead) return null

    const hasOnlyDemo = book.hasOnlyDemo || this.props.hasOnlyDemo

    return `/books/${book.key}/${hasOnlyDemo ? 'demo/' : ''}`
  }

  render() {
    const { className, type, cannotRead, books, shelfRef } = this.props

    const classNames = cx('newPaybarShelf module', className, `is__${type}`, { is__cannotRead: cannotRead })

    const hasMultipleBooks = books.length > 1
    const isAllBooksHasMobileVersion = books.every(book => book.hasMobileVersion)

    return (
      <div className={ classNames }
        style={ this.shelfStyles }
        ref={ el => this.books = el }>
        <div className="newPaybarShelf-in" ref={ shelfRef }>
          <div className="newPaybarShelf-books">
            { books.map(book => (
              <a className="newPaybarShelf-book no-u"
                href={this.bookHref(book)}
                style={ this.bookStyles(book.key) }>

                <img className="newPaybarShelf-cover"
                  src={ this.bookImageSrc(book.key) }
                  style={ this.bookImageStyles }/>

                { hasMultipleBooks && !isAllBooksHasMobileVersion && book.hasMobileVersion &&
                  <BadgePhone />
                }
              </a>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default NewPaybarShelf
