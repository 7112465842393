const fnsNextDay = require('date-fns/nextDay')
const startOfWeek = require('date-fns/startOfWeek')
const isMonday = require('date-fns/isMonday')
const addDays = require('date-fns/addDays')
const addWeeks = require('date-fns/addWeeks')
const startOfDay = require('date-fns/startOfDay')
const format = require('date-fns/format')
const birmanize = require('./dates/birmanize')

class AdvancedDate {
  constructor(date) {
    this.date = date
  }

  get value() {
    return this.date
  }

  get year() {
    return this.date.getFullYear()
  }

  get shortSlug() {
    return format(this.date, 'ddMMyy')
  }

  get eventSlug() {
    return format(this.date, 'yyyy-MM-dd')
  }

  get slug() {
    return format(this.date, 'yyyyMMdd')
  }

  get isPast() {
    return Date.now() >= this.date
  }

  get isFuture() {
    return Date.now() < this.date
  }

  week(weeks) {
    const date = startOfDay(addWeeks(this.date, weeks))

    return new AdvancedDate(date)
  }

  day(days) {
    const date = startOfDay(addDays(this.date, days))

    return new AdvancedDate(date)
  }

  nextDay() {
    const date = startOfDay(addDays(this.date, 1))

    return new AdvancedDate(date)
  }

  nextMonday() {
    const date = startOfDay(fnsNextDay(this.date, 1))

    return new AdvancedDate(date)
  }

  prevMonday() {
    const date = isMonday(this.date) ? startOfDay(addWeeks(this.date, -1)) :
      startOfDay(startOfWeek(this.date, { weekStartsOn: 1 }))

    return new AdvancedDate(date)
  }

  nextSunday() {
    const date = startOfDay(fnsNextDay(this.date, 0))

    return new AdvancedDate(date)
  }

  toText() {
    return birmanize(this.date, { format: 'fullMonthDateYearOnly' })
  }

  toTextShort() {
    return birmanize(this.date, { format: 'fullMonthDateOnly' })
  }
}

module.exports = AdvancedDate
