module.exports = {
  default: '#E30016',
  'book-typography': '#E30016',
  'book-ui': '#FC5620',
  'book-text': '#004F83',
  'book-fff': '#007A78',
  'book-kungfu': '#004F83',
  'book-japanese': '#EF1C47',
  'book-howtowritethat': '#004f83',
  'book-metro': '#0522A5',
  'book-carphoto': '#FF2800',
  'bookshelf-levelone': '#FD7A82',
}
