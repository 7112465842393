const DEMO_CLASSROOM_EVENT_ID = 44

class Header {
  constructor($el) {
    this.$el = $el;
    this.$document = $(document);
    this.$userAvatar = this.$el.find('.js__user-avatar');

    this.setListeners();
  }

  setListeners() {
    this.$document.on('appSetUser', (_, user) => {
      if (user) this._setAvatarOrInitials(user)
      this._showClassroomLink()
      this._trackUser(user)
    })
  }

  _setAvatarOrInitials({ avatarUrl, name }) {
    if (avatarUrl) {
      $('body').addClass("with__avatar")
      this.$userAvatar.css('background-image', `url('${avatarUrl}')`)
      return
    }
     
    const initials = name
      .split(/\s/)
      .map(part => part[0] || '')
      .map(letter => letter.toUpperCase())
      .join('')

    this.$userAvatar.text(initials)
  }

  _showClassroomLink() {
    const isClassroom = /\/classroom($|\/$|(\/[0-9]+))/i
    const path = location.pathname
    const $classroomLink = this.$el.find('.header-bell')

    $classroomLink.attr('href', this.classroomLink)

    if (isClassroom.test(path)) {
      $classroomLink.addClass('is__active')
      $classroomLink.removeAttr('href')
    }
  }

  _trackUser(user) {
    const userData = user || {}
    const userId = userData.id || 0

    try {
      dataLayer.push({
        event: 'User_Init',
        user_data: {
          id: userId,
          date_registered: user.date_registered || '',
          card_connected: user.card_connected || false,
          user_pic_exist: user.user_pic_exist || false,
          gmail_exists: user.gmail_exists || false,
        },
      })
    } catch (e) {}
  }

  get classroomLink() {
    const { eventId } = (window.classroom || {})

    if (eventId === DEMO_CLASSROOM_EVENT_ID) return '/school/classroom/demo/'
    if (eventId) return `/school/classroom/${eventId}/`

    return '/school/classroom/'
  }
}

module.exports = Header
