import SubscriptionPricing from '../subscriptionPricing/subscriptionPricing'
import UpgradePricing from '../subscriptionPricing/upgradePricing'

import CollectureSpacerMobilePricing from './collectureSpacerMobilePricing'
import { CollectureSpacerLink } from './collectureSpacerUtils'

const CollectureSpacerPricing = props => (
  <div className="collectureSpacer-pricing">
    <div className="device desktop">
      { !props.isUpgrade &&
        <SubscriptionPricing
          prices={ props.prices }
          size="pageTitle"
          unitsSize="heading" />
      }

      { props.isUpgrade &&
        <UpgradePricing
          product="collecture"
          prices={ props.prices }
          unitsSize="heading" />
      }
    </div>

    <div className="device laptop">
      { !props.isUpgrade &&
        <SubscriptionPricing
          prices={ props.prices }
          unitsSize="medium"
          size="title"
          hasCompactUnitsView />
      }

      { props.isUpgrade &&
        <UpgradePricing
          product="collecture"
          prices={ props.prices }
          size="title"
          unitsSize="medium" />
      }
    </div>

    <div className="device mobile">
      <CollectureSpacerMobilePricing { ...props } />
      <CollectureSpacerLink href={ props.href } isSmall />
    </div>
  </div>
)

export default CollectureSpacerPricing
