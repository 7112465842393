import { Component } from 'preact'
import cx from 'classnames'
import { getTimeLeft, padDigit, EXPIRED_TIME } from './helpers'

const SECOND_IN_MS = 1000

export default class RealtimeCountdown extends Component {
  constructor(props) {
    super(props)

    this.sections = ['days', 'hours', 'minutes']
    this.timerDelay = SECOND_IN_MS

    if (this.props.hasSeconds) { this.sections.push('seconds') }

    if (this.props.noDays) {
      const daysIndex = this.sections.indexOf('days')
      this.sections.splice(daysIndex, 1)
    }
  }

  componentDidMount() {
    this.setNextTick()
  }

  componentDidUpdate() {
    this.setNextTick()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  get isExpired() {
    return this.timeLeft === EXPIRED_TIME
  }

  get timeLeft() {
    return getTimeLeft({ from: new Date(), to: this.props.to, noDays: this.props.noDays })
  }

  getCaption(type) {
    return I18n.t(`realtimeCountdown.${type}`, { count: this.timeLeft[type] })
  }

  getShortCaption(type) {
    return I18n.t(`realtimeCountdown.short.${type}`)
  }

  setNextTick() {
    if (this.isExpired) {
      if (this.props.onExpiry) this.props.onExpiry()
      return
    }

    clearTimeout(this.timeout)

    this.timeout = setTimeout(this.tick.bind(this), this.timerDelay)
  }

  tick() {
    this.isTick = !this.isTick
    this.forceUpdate()
  }

  render() {
    const { isTextOnly, hasSeconds, hasShortUnits } = this.props

    const classNames = cx('realtimeCountdown', {
      is__textOnly: isTextOnly,
      is__tick: this.isTick,
      has__simplifiedDotsAnim: !hasSeconds,
    })

    return (
      <div className={ classNames }>
        { this.sections.map(type => (
          <span className={`realtimeCountdown-section realtimeCountdown-${type}`}>
            <span className="realtimeCountdown-digit">
              { padDigit(this.timeLeft[type]) }
              <span className="realtimeCountdown-dots">:</span>
            </span>
            { !isTextOnly && <small className="realtimeCountdown-caption">{ this.getCaption(type) }</small> }
            { !isTextOnly && hasShortUnits &&
             <small className="realtimeCountdownCaption-short">{ this.getShortCaption(type) }</small>
            }
          </span>
        )) }
      </div>
    )
  }
}
