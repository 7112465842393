import { Caption, TextNode } from '../../textNodes'

export default function NewPaybarSchoolCaption(props) {
  return (
    <div className="newPaybarSchoolCaption">
      <TextNode html={ props.caption } className="module device mobile"></TextNode>
      <Caption html={ props.caption } className="module device laptop desktop"></Caption>
    </div>
  )
}
