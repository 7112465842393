import { Component } from 'preact'
import cx from 'classnames'

import { Heading3, TextNode } from '../textNodes'
import FormInput from '../form/formInput'

import { subscribeUser } from '../subscriptionForm/emailUtils'
import reachGoal from 'lib/reachGoal'

const SUBSCRIPTION_LOCALSTORAGE_ID = 'soviet_weekly_subscription'
const EVENT_TYPE = 'soviet'

class SovietSubscriptionSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { isSubscribed: false, email: '' }
    this.userData = { email: '' }
    this.isValid = true

    this.handleInput = this.handleInput.bind(this)
    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.getSubscriptionData()
  }

  getEmailFromLocalStorage() {
    try {
      return localStorage.getItem(SUBSCRIPTION_LOCALSTORAGE_ID)
    } catch (e) {}
  }

  getFieldsFromUserData() {
    const user = window.application.user

    return user && {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    }
  }

  getSubscriptionData() {
    const emailFromLocalstorage = this.getEmailFromLocalStorage()
    if (emailFromLocalstorage) {
      this.userData.email = emailFromLocalstorage
    }

    const fieldsFromUserData = this.getFieldsFromUserData()
    if (fieldsFromUserData) {
      this.userData = Object.assign(this.userData, fieldsFromUserData)
      this.userData.isSubscribed = !!emailFromLocalstorage
    }

    this.setState({ isSubscribed: this.userData.isSubscribed, email: this.userData.email })
  }

  handleInput({ value }) {
    this.setState({
      email: value,
      hasError: !this.isValid,
      isSubscribed: false,
      isLoading: false,
    })
  }

  onInputValidityChange(name, validity) {
    this.isValid = validity
  }

  sendDataAndSaveState() {
    const { email } = this.state

    subscribeUser({ email, eventType: EVENT_TYPE })
      .then(isOk => {
        this.setState({ isSubscribed: isOk, isLoading: false, hasError: !isOk })

        if (isOk) {
          localStorage.setItem(SUBSCRIPTION_LOCALSTORAGE_ID, this.state.email)
          this.trackSubscription()
        }
      })
  }

  trackSubscription() {
    reachGoal(this.props.goal)
  }

  submitForm(e) {
    e.preventDefault()
    this.setState({ isLoading: true })
    this.sendDataAndSaveState()
  }

  render() {
    const formClasses = cx(
      'subscriptionForm',
      {
        is__default: this.state.email && !this.state.isSubscribed && !this.state.hasError && !this.state.isLoading,
        is__disabled: !this.state.email,
        is__subscribed: this.state.isSubscribed,
        has__error: this.state.email && this.state.hasError,
        is__loading: this.state.isLoading,
      }
    )

    return (
      <div className="sovietSubscriptionSpacer banner-debug">
        <div className="sovietSubscriptionSpacer-text module">
          <Heading3>Советы почтой каждую неделю</Heading3>
          <TextNode>Подпишитесь, чтобы не пропускать выход новых советов.</TextNode>
        </div>
        <form className={ formClasses } onSubmit={ this.submitForm }>
          <div className="subscriptionForm-inputWrapper">
            <FormInput
              className="subscriptionForm-input"
              type="email"
              name="email"
              placeholder="Электронная почта"
              required="required"
              onInput={ this.handleInput }
              onValidityChange={ this.onInputValidityChange }
              value={ this.state.email }
              silent
            />
          </div>
          <button className="button subscriptionForm-submit is__thin" type="submit">
            <span className="subscriptionForm-submitText is__default">→</span>
            <span className="subscriptionForm-submitText is__success">✓</span>
            <span className="subscriptionForm-submitText is__loading" />
          </button>
        </form>
      </div>
    )
  }
}

export default SovietSubscriptionSpacer
