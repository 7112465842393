const MAX_LOADED_SCREENS = 2
const MAX_SIMULTANEOUS_CONNECTIONS = 1
const getLoadRadius = () => window.innerHeight * MAX_LOADED_SCREENS

const LoadableImage = require('./loadableImage')
const ImagesList = require('./imagesList')

const MIN_WIDE_SCREEN_WIDTH = 1500

const hasWideScreen = () => window.application.viewWidth > MIN_WIDE_SCREEN_WIDTH
const shouldLoadRetina = () => window.application.supports.retina || hasWideScreen()
const hasRetinaVersion = (image) => {
  return image.getAttribute('data-nonretina-src') &&
    image.getAttribute('data-src') !== image.getAttribute('data-nonretina-src')
}


class RetinaImageLoader {
  constructor() {
    this.images = this._findRetinaImages()
    this.$document = $(document)

    this.loadRadius = getLoadRadius()
    this.imagesQueue = []

    this._bindEvents()
    this._calculate()
  }

  _findRetinaImages() {
    return $('.image[data-src]')
      .filter((_, el) => hasRetinaVersion(el))
      .map((_, el) => new LoadableImage($(el), {
        src: $(el).data('src'),
        loadEvent: 'retinaImageLoaded',
      }))
      .get()
  }

  _bindEvents() {
    this.$document.on('imagesQueueFree', () => {
      this._calculate()
      this._load()
    })

    this.$document.on('retinaImageLoaded', this._updateLoadingProgress.bind(this))
  }

  _calculate() {
    this.loadableImages = []

    if (!shouldLoadRetina()) return

    this.images.filter(image => !image.isLoaded).forEach(image => {
      image.calculate()
      if (image.isLoadable) this.loadableImages.push(image)
    })
  }

  _load() {
    if (!shouldLoadRetina()) return

    let position = window.application.currentScrollPosition

    this._buildQueueForPosition(position)
    this._handleQueue()
  }

  _buildQueueForPosition(position) {
    this.imagesQueue = (new ImagesList(this.loadableImages))
      .within({ radius: this.loadRadius, position })
  }

  _updateLoadingProgress(_, image) {
    this.loadableImages.splice(this.loadableImages.indexOf(image), 1)
    this.imagesQueue.splice(this.imagesQueue.indexOf(image), 1)

    this._handleQueue()
  }

  _handleQueue() {
    this.imagesQueue.slice(0, MAX_SIMULTANEOUS_CONNECTIONS).forEach(image => {
      image.load()
    })
  }
}

module.exports = RetinaImageLoader
