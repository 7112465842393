import { Component } from 'preact'
import { connect } from 'preact-redux'

import { Heading2 } from '../../textNodes'
import Cols from '../../cols/cols'
import NewPaybarLaunchpadItemLecture from './newPaybarLaunchpadItemLecture'

class NewPaybarLaunchpadLecture extends Component {
  get title() {
    if (!this.props.isUserAuthenticated) return this.unauthenticatedTitle

    return I18n.t('newPaybar.launchpad.lecture')
  }

  get unauthenticatedTitle() {
    return I18n.t('newPaybar.launchpad.lectureNoAccess')
  }

  render() {
    return (
      <section className="newPaybarLaunchpad-section">
        <Heading2 className="newPaybarLaunchpad-title">{ this.title }</Heading2>

        <Cols className="newPaybarLaunchpad-items newPaybarLaunchpadLecture-items">
          { this.props.lectures.map(lecture => (
            <NewPaybarLaunchpadItemLecture
              isUserAuthenticated={ this.props.isUserAuthenticated }
              lectureKey={ lecture.key }
              cover={ lecture.cover }
              title={ lecture.title }
              authorInstrumentalCase={ lecture.authorInstrumentalCase } />
          )) }
        </Cols>
      </section>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    lectures: newPaybar.lectures,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
  }
}

export default connect(mapStateToProps)(NewPaybarLaunchpadLecture)
