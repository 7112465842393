import cx from 'classnames'

import { Impressions } from '../impressions'

const VkIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M23.943,0H3.057A2.994,2.994,0,0,0,0,3.057V23.943A2.994,2.994,0,0,0,3.057,27H23.943A2.994,2.994,0,0,0,27,23.943V3.057A2.994,2.994,0,0,0,23.943,0ZM21.312,15.635a8.336,8.336,0,0,1,2,2.267c.68,1.1-.717,1.171-.717,1.171l-2.681.036a1.905,1.905,0,0,1-1.321-.4c-1.02-.7-1.963-2.451-2.681-2.231-.755.219-.717,1.829-.717,1.829a.946.946,0,0,1-.151.512.939.939,0,0,1-.529.219H13.308a7.025,7.025,0,0,1-4.984-2.231C5.8,14.172,3.567,8.978,3.567,8.978s-.151-.329,0-.512a1.049,1.049,0,0,1,.6-.182L7.04,8.247a.832.832,0,0,1,.491.329A.68.68,0,0,1,7.8,8.9a20.3,20.3,0,0,0,1.057,2.2c1.208,2.048,1.737,2.487,2.152,2.267.6-.329.415-2.889.415-2.889a2.946,2.946,0,0,0-.3-1.353,1.477,1.477,0,0,0-.906-.439c-.151,0,.113-.366.453-.549a6.687,6.687,0,0,1,2.492-.256,4.943,4.943,0,0,1,1.4.147c.982.219.642,1.133.642,3.328,0,.7-.113,1.682.377,2.011.189.146.755.037,2.077-2.231a23.549,23.549,0,0,0,1.1-2.34,1.1,1.1,0,0,1,.264-.33.58.58,0,0,1,.378-.073l3.02-.036s.906-.11,1.057.292-.339,1.427-1.585,3.036C19.878,14.355,19.651,14.1,21.312,15.635Z"/></svg>
}

const FbIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M23.943,0H3.057A2.994,2.994,0,0,0,0,3.057V23.943A2.994,2.994,0,0,0,3.057,27h8.714V16.968H8.5V13.052h3.271v-2.9C11.771,6.8,13.724,5,16.612,5a16.574,16.574,0,0,1,2.888.176V8.652h-2c-1.571,0-1.868.748-1.868,1.892v2.508h3.737l-.467,3.916H15.678V27h8.265A2.994,2.994,0,0,0,27,23.943V3.057A2.994,2.994,0,0,0,23.943,0Z"/></svg>
}

const TwIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M23.943,0H3.057A2.994,2.994,0,0,0,0,3.057V23.943A2.994,2.994,0,0,0,3.057,27H23.943A2.994,2.994,0,0,0,27,23.943V3.057A2.994,2.994,0,0,0,23.943,0ZM21.164,9.918v.456A10.349,10.349,0,0,1,10.652,20.7,10.6,10.6,0,0,1,5,19.084a6.937,6.937,0,0,0,.864.036,7.372,7.372,0,0,0,4.608-1.581A3.709,3.709,0,0,1,7.016,15.01a4.1,4.1,0,0,0,.684.07,4.652,4.652,0,0,0,.972-.14A3.612,3.612,0,0,1,5.72,11.393v-.035a3.619,3.619,0,0,0,1.656.456A3.551,3.551,0,0,1,5.72,8.794a3.972,3.972,0,0,1,.5-1.827,10.536,10.536,0,0,0,7.632,3.794,3.385,3.385,0,0,1-.108-.843A3.659,3.659,0,0,1,17.456,6.3a3.74,3.74,0,0,1,2.7,1.159,7.839,7.839,0,0,0,2.34-.878,3.758,3.758,0,0,1-1.62,2A6.989,6.989,0,0,0,23,8.021,7.75,7.75,0,0,1,21.164,9.918Z"/></svg>
}

const TgIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M23.943,0H3.057A2.994,2.994,0,0,0,0,3.057V23.943A2.994,2.994,0,0,0,3.057,27H23.943A2.994,2.994,0,0,0,27,23.943V3.057A2.994,2.994,0,0,0,23.943,0ZM21.938,7.347,19.223,20.129c-.19.908-.739,1.126-1.5.706l-4.137-3.052L11.6,19.714a1.062,1.062,0,0,1-.828.413l.282-4.215h0l7.676-6.919c.336-.3-.074-.444-.521-.173L8.734,14.789,4.642,13.514c-.883-.271-.889-.877.2-1.313L20.785,6.059C21.513,5.729,22.216,6.234,21.938,7.347Z"/></svg>
}

const SocialButton = (props) => {
  const classes = cx(
    'sovietSocial-button',
    {
      [props.type]: props.type,
    }
  )
  return (
    <div className={ classes }>
      { props.type === 'twitter' &&
        <TwIcon />
      }
      { props.type === 'facebook' &&
        <FbIcon />
      }
      { props.type === 'vkontakte' &&
        <VkIcon />
      }
      { props.type === 'telegram' &&
        <TgIcon />
      }
    </div>
  )
}

class SovietSocial {
  shouldComponentUpdate() {
    return false
  }

  render(props) {
    return (
      <div class="sovietSocial-wrapper">
        <Impressions mod="medium" disabled={ props.isDisabled || props.isImpressionsDisabled } />
        <div className={ cx('sovietSocial-likely likely', { is__disabled: props.isDisabled }) }>
          <SocialButton type="twitter" />
          <SocialButton type="facebook" />
          <SocialButton type="vkontakte" />
          <SocialButton type="telegram" />
        </div>
      </div>
    )
  }
}

export default SovietSocial
