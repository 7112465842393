import { Component } from 'preact'
import { connect } from 'preact-redux'

import { setCover, setCoverUploadError } from './sovietCompilationActions'
import UploadBar from '../upload/bar'
import AUTH_HEADERS from 'authHeaders'

const isValidUpload = (files) => files.length && /^image/.test(files[0].type)

class SovietCompilationCoverUploader extends Component {
  constructor(props) {
    super(props)

    this._displayCover = this._displayCover.bind(this)
    this._handleUploadError = this._handleUploadError.bind(this)
    this.dropCounter = 0
  }

  componentDidMount() {
    const $document = $(document)

    $document.on('appDragStart.coverUpload', () => this.setState({ isDragOver: true }))
    $document.on('appDragEnd.coverUpload', () => this.setState({ isDragOver: false }))
    $document.on('appDrop.coverUpload', (_, data) => this._handleDrop(data.files))
  }

  componentWillUnmount() {
    $(document).off('appDragStart.coverUpload appDragEnd.coverUpload appDrop.coverUpload')
  }

  render() {
    return (
      <div className={ `compilationCoverUploader ${this.state.isDragOver ? 'is__dragOver' : ''}` }>
        <UploadBar file={ this.state.uploadingFileName }
            loaded={ this.state.uploadingFileLoaded }
            total={ this.state.uploadingFileTotal }
            isActive={ this.state.isUploading }
          />
      </div>
    )
  }

  _handleDrop(files) {
    if (!isValidUpload(files)) return

    this._upload(files)
  }

  _upload(files) {
    this._uploadCover(files[0])
      .then(this._displayCover)
      .catch(this._handleUploadError)
      .then(() => this.setState({ isUploading: false }))
  }

  _uploadCover(rawfile) {
    this.setState({
      isUploading: true,
      uploadingFileName: rawfile.name,
      uploadingFileTotal: rawfile.size,
    })

    const formData = new FormData()
    formData.append('image', rawfile)

    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/soviet/advice_compilation_covers',
        data: formData,
        contentType: false,
        processData: false,
        headers: AUTH_HEADERS,
        type: 'POST',
        xhr: this._handleProgressUpdate.bind(this),
        success: res => resolve(res),
        error: xhr => reject(xhr),
      })
    })
  }

  _handleProgressUpdate() {
    const xhr = new window.XMLHttpRequest()

    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) this.setState({ uploadingFileLoaded: e.loaded })
    }, false)

    return xhr
  }

  _displayCover({ url, id }) {
    this.props.setCover({ url, id })
  }

  _handleUploadError() {
    this.props.setCoverUploadError()
  }
}

const mapDispatchToProps = {
  setCover,
  setCoverUploadError,
}

export default connect(null, mapDispatchToProps)(SovietCompilationCoverUploader)
