import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import { isOnMobile } from 'lib/envHelpers'
import { toSentence } from 'lib/textHelper'

import { Heading2, TextNode } from '../../textNodes'

import NewPaybarShelf from '../newPaybarShelf'
import NewPaybarShelfDummy from '../newPaybarShelfDummy'
import expandScreenToFlags from '../expandScreenToFlags'
import isBookshelfAll from 'lib/isBookshelfAll.js'


const SHELF_HEIGHTS = {
  bookshelf: '63.4%',
  book: '75%',
}

class NewPaybarOverlayBookshelf extends Component {
  constructor(props) {
    super(props)

    this.$document = $(document)
    this.state = {
      hiddenWidth: 'auto',
      shouldDisplayDummyShelf: this.shouldDisplayDummyShelf,
    }
  }

  componentDidMount() {
    this.setHiddenWidth()

    this.$document.on('appResize', () => {
      this.setHiddenWidth()
      this.setDummyShelfDisplay()
    })
  }

  componentDidUpdate() {
    this.setHiddenWidth()
  }

  setHiddenWidth() {
    if (!this.shelf) return

    this.hiddenWidth = this.shelf.offsetWidth
    if (this.state.hiddenWidth !== this.hiddenWidth) {
      this.setState({ hiddenWidth: this.hiddenWidth })
    }
  }

  setDummyShelfDisplay() {
    const { shouldDisplayDummyShelf } = this

    if (this.state.shouldDisplayDummyShelf !== shouldDisplayDummyShelf) {
      this.setState({ shouldDisplayDummyShelf })
    }
  }

  get resultScreenHeadingText() {
    const {
      type,
      product,
      isPreorder,
      prices: { previousSpecialOffer },
    } = this.props

    const suffix = isPreorder ? 'WithPreorder' : ''
    const hasUpgraded = !!previousSpecialOffer

    return I18n.t(`newPaybar.successScreen.${product}.${hasUpgraded ? 'upgrade' : type}.heading${suffix}`)
  }

  get resultScreenText() {
    const { product, type, prices } = this.props

    let result = I18n.t(`newPaybar.successScreen.${product}.${type}.text`)

    if (prices.previousSpecialOffer) {
      result += this.resultScreenPreviousCanceledText
    }

    return result
  }

  get resultScreenPreviousCanceledText() {
    const { ownedBooks = [], ownedBookshelves = [] } = this.props.prices.previousSpecialOffer
    const hasManyBooks = ownedBooks.length > 1 || ownedBookshelves.length > 0
    const onlyBookTitle = (ownedBooks[0] || {}).title

    const text = I18n.t(`newPaybar.successScreen.previousSubscriptionCanceled.${ hasManyBooks ? 'many' : 'one' }`,
      { bookTitle: onlyBookTitle })

    return `. ${text}`
  }

  get resultScreenAccessText() {
    if (
      this.props.canRead &&
      this.props.isUserAuthenticated &&
      (this.props.type === 'self' || this.props.type === 'upgrade')
    ) return ''

    const suffix = this.props.cannotRead ? 'CannotRead' : ''

    return I18n.t(`newPaybar.successScreen.${this.props.product}.${this.props.type}.accessText${suffix}`, this.props)
  }

  get hasAccessTextOverlay() {
    return !!this.resultScreenAccessText.length
  }

  get shouldDisplayDummyShelf() {
    return isBookshelfAll(this.props.productId) && isOnMobile()
  }

  render() {
    const { productId } = this.props
    const { shouldDisplayDummyShelf } = this.state

    const shouldDisplayLock = this.props.cannotRead || (this.props.type === 'self' && !this.props.isUserAuthenticated)

    const classNameOverlayLock = cx('newPaybarOverlay-lock', { is__disabled: !shouldDisplayLock })
    const classNameShelf = cx({ has__noOverlay: !this.hasAccessTextOverlay })

    const cannotRead = this.props.cannotRead || !this.props.isUserAuthenticated || this.props.type === 'gift'

    return (
      <div className="newPaybarOverlay-content">
        <div className="newPaybarOverlay-message">
          <Heading2 html={ this.resultScreenHeadingText } />
          <TextNode html={ this.resultScreenText } />
        </div>

        { shouldDisplayDummyShelf && <NewPaybarShelfDummy productId={ productId } isLarge />}

        { !shouldDisplayDummyShelf &&
          <NewPaybarShelf
            className={ classNameShelf }
            height={ SHELF_HEIGHTS[this.props.product]}
            mobileHeight="75%"
            type="animated"
            resized
            isSuccessScreen={ this.props.isSuccessScreen }
            product={ this.props.product }
            books={ this.props.books }
            cannotRead={ cannotRead }
            hasOnlyDemo={ this.props.hasOnlyDemo }
            shelfRef={ el => this.shelf = el } />
        }

        { this.hasAccessTextOverlay &&
          <div className="newPaybarOverlay-access">
          <span className={ classNameOverlayLock }>
            { I18n.t('newPaybar.successScreen.lock') }
          </span>
            <TextNode html={ this.resultScreenAccessText }/>
          </div>
        }

        { cannotRead &&
          <div className="newPaybarOverlay-hidden
            module devices
            device__laptop device__desktop"
            style={ { width: this.state.hiddenWidth } }>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    prices: newPaybar.prices,
    books: newPaybar.shelfBooks,
    productId: newPaybar.productId,
    cannotRead: newPaybar.cannotRead,
    canRead: !newPaybar.cannotRead,
    isPreorder: newPaybar.isPreorder,
    hasOnlyDemo: newPaybar.hasOnlyDemo,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    presenteeEmail: newPaybar.presenteeEmail,
    previousSpecialOffer: newPaybar.previousSpecialOffer,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarOverlayBookshelf)
