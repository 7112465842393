const AUTH_HEADERS = require('authHeaders')

module.exports = (body) => {
  return fetch('/mod/soviet_post/saveSoviet.php', {
    method: 'POST',
    headers: {
      ...AUTH_HEADERS,
      Accept: 'application/json',
    },
    body,
  })
}
