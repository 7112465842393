const jquery = require('jquery/dist/jquery.min')
const $ = jquery

window.$ = $
window.jQuery = $

import { render } from 'preact'
import reactify from 'reactify'

const imagesLoaded = require('imagesloaded')
imagesLoaded.makeJQueryPlugin($)

window.imagesLoaded = imagesLoaded

$.fn.reactify = reactify

module.exports = $
