import LectureNavigation from '../lectureNavigation/lectureNavigation'

$('.js__courseNavigation').each((index, el) => {
  const $root = $(el)
  const $next = $root.next()
  if ($next.is('.webpageLikes')) $next.after($root)
  if ($next.is('.navigationPlaceholder')) $next.remove()

  const options = JSON.parse($root.attr('data-options') || null)

  $root.empty().reactify(LectureNavigation, options)
  $root.children().unwrap()
})
