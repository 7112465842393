import { connect } from 'preact-redux'
import cx from 'classnames'

import { setPublishAt } from '../soviet/sovietActions'
import { setTimeToMidnightByMoscow, removeTimezoneOffset } from './timezoneUtils'

const makeClassName = (date, isEngaged, publishAt) => ({
  is__engaged: isEngaged,
  is__selected: publishAt === date,
})

const makeDateString = date => date.toISOString()

const prepareDate = date => makeDateString(setTimeToMidnightByMoscow(removeTimezoneOffset(new Date(date))))

const preventBlur = (e) => e.preventDefault()

const CalendarDay = ({ date, setPublishAt, publishAt }) => (
  <div
    onMouseDown={ preventBlur }
    onClick={ () => !date.isEngaged && setPublishAt(prepareDate(date.date)) }
    className={ cx('calendar-day', makeClassName(prepareDate(date.date), date.isEngaged, prepareDate(publishAt))) }
  >
    { date.date.getDate() }
  </div>
)

const mapStateToProps = ({ soviet: { publishAt } }) => ({
  publishAt,
})

const mapDispatchToProps = {
  setPublishAt,
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDay)
