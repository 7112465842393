const throttle = require('throttle')
const LoadableImage = require('./loadableImage')
const ImagesList = require('./imagesList')

const MAX_LOADED_SCREENS = 4
const MAX_SIMULTANEOUS_CONNECTIONS = 4
const SCROLL_THROTTLE = 400

const isInPreviewsMode = /Bin\/Previews$/.test(navigator.userAgent)
const getLoadRadius = () => isInPreviewsMode ? 9999999 : window.innerHeight * MAX_LOADED_SCREENS
const findImages = () => $('.image[data-src]').map((_, el) => new LoadableImage($(el))).get()

class ImageLoader {
  constructor() {
    this.images = findImages()
    this.$document = $(document)

    this._bindEvents()
    this._calculate()
    this._load()
  }

  _bindEvents() {
    $(window).on('scroll', throttle(SCROLL_THROTTLE, this._load.bind(this)))

    this.$document.on('appResize', () => {
      this._calculate()
      this._load()
    })

    this.$document.on('appLoadImagesIn', (_, el) => this._forceLoadImagesIn($(el)))
    this.$document.on('loadImages', this._loadAll.bind(this))
    this.$document.on('imageLoaded', this._updateLoadingProgress.bind(this))
    this.$document.on('newImagesAppeared', this.rescanImages.bind(this))
  }

  rescanImages() {
    this.images = findImages()
    this._calculate()
    this._load()
  }

  _calculate() {
    this.loadableImages = [];
    this.loadRadius = getLoadRadius();

    this.images.filter(image => !image.isLoaded).forEach(image => {
      image.calculate();
      if (image.isLoadable) this.loadableImages.push(image);
    });
  }

  _load() {
    let position = window.application.currentScrollPosition

    this._buildQueueForPosition(position);
    this._handleQueue();
  }

  _forceLoadImagesIn($el) {
    $el
      .find('.image:not(.is__loaded)[data-src]')
      .map((_, el) => new LoadableImage($(el), { src: $(el).data('src') }))
      .get()
      .forEach(image => image.load());
  }

  _buildQueueForPosition(position) {
    this.imagesQueue = (new ImagesList(this.loadableImages))
      .within({ radius: this.loadRadius, position })
  }

  _loadAll() {
    this.loadableImages.forEach(image => {
      image.load();
    });
  }

  _updateLoadingProgress(_, image) {
    const loadableIndex = this.loadableImages.indexOf(image)
    const queueIndex = this.imagesQueue.indexOf(image)

    if (loadableIndex >= 0) this.loadableImages.splice(loadableIndex, 1)
    if (queueIndex >= 0) this.imagesQueue.splice(queueIndex, 1)

    if (this.loadableImages.length === 0) this.$document.trigger('imagesLoaded', this)

    this._handleQueue()
  }

  _handleQueue() {
    this.imagesQueue.slice(0, MAX_SIMULTANEOUS_CONNECTIONS).forEach(image => {
      image.load()
    })

    if (this.imagesQueue.length === 0) this.$document.trigger('imagesQueueFree')
  }
}

module.exports = ImageLoader
