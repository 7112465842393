import { Component } from 'preact'
import cx from 'classnames'

import CommentFormAuthorField from './commentFormAuthorField'
import CommentFormAuthorHiddenField from './commentFormAuthorHiddenField'

class CommentFormAuthorFieldset extends Component {
  constructor(props) {
    super(props)
  }

  render({ isAuthenticated, onEdit, onValidityChange, disableEmailValidation, author }) {
    const wrapperHtmlClass = cx('commentForm-fieldset', { is__authenticated: isAuthenticated })
    return (
      <div className={wrapperHtmlClass}>
        {
          (isAuthenticated && author.name) ?
            <CommentFormAuthorHiddenField name="name" value={ author.name } /> :
            <CommentFormAuthorField
              name="name"
              label="Имя и фамилия"
              value={ author.name }
              placeholder="Обязательны полностью"
              onEdit={ onEdit }
              onValidityChange={ onValidityChange }
              required
            />
        }

        {
          (isAuthenticated && author.email) ?
            <CommentFormAuthorHiddenField name="email" value={ author.email } /> :
            <CommentFormAuthorField
              name="email"
              label="Эл. почта"
              type={ disableEmailValidation ? 'text' : 'email' }
              value={ author.email }
              placeholder="Не будет опубликована"
              onEdit={ onEdit }
              onValidityChange={ onValidityChange }
              required
            />
        }

        <CommentFormAuthorField
          name="company"
          label="Место работы"
          value={ author.company }
          onEdit={ onEdit }
          onValidityChange={ onValidityChange }
        />

        <CommentFormAuthorField
          name="job_title"
          label="Должность"
          value={ author.jobTitle }
          onEdit={ onEdit }
          onValidityChange={ onValidityChange }
        />
      </div>
    )
  }
}

export default CommentFormAuthorFieldset
