const CAM_REFRESH_PERIOD = 3000;
const REFRESH_URL = 'https://platform.bureau.ru/webcam/webcam_frame.jpg';

class CoworkafeLive {
  constructor($el) {
    this.$el = $el;

    this.image = new Image();
    this.image.onload = () => {
      this.$el[0].src = this.image.src;
      this._scheduleNext();
    };

    this.image.onerror = this._scheduleNext.bind(this);

    this.reloadCam();
  }

  reloadCam() {
    this.image.src = REFRESH_URL + '?v=' + Math.random();
  }

  _scheduleNext() {
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.reloadCam.bind(this), CAM_REFRESH_PERIOD);
  }
}

module.exports = CoworkafeLive;
