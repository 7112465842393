import { Component } from 'preact'
import { Cols } from '../cols'

import CollectureSpacerTitle from './collectureSpacerTitle'
import CollectureSpacerCover from './collectureSpacerCover'
import CollectureSpacerPricing from './collectureSpacerPricing'

import fetchProductPricing from 'lib/products/fetchPricing'

import { collectureHref } from './collectureSpacerUtils'

export default class CollectureSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { prices: {} }

    this.href = collectureHref(props.id)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch() {
    fetchProductPricing(this.props.id)
      .then((json) => {
        this.setState({
          prices: json,
          hasSpecialOffer: json.specialOffer && json.specialOffer.oneTime,
        })
      })
      .catch(e => console.error(e))
  }

  render() {
    if (!this.state.prices.default) return null

    return (
      <Cols className="collectureSpacer" transposeOnMobile >
        <CollectureSpacerCover
          className="collectureSpacer-cover"
          id={ this.props.id }
          href={ this.href } />

        <CollectureSpacerTitle
          className="collectureSpacer-title"
          id={ this.props.id }
          href={ this.href } />

        <CollectureSpacerPricing
          className="collectureSpacer-pricing"
          isUpgrade={ this.state.hasSpecialOffer }
          prices={ this.state.prices }
          href={ this.href } />
      </Cols>
    )
  }
}
