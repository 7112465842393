import { Component } from 'preact'
import { connect } from 'preact-redux'

import { detectPaybarLaunchpad } from './variants'

class NewPaybarLaunchpad extends Component {
  render() {
    const NewPaybarLaunchpadProduct = detectPaybarLaunchpad(this.props.product)

    return (
      <div className="newPaybarLaunchpad">
        <NewPaybarLaunchpadProduct />
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return { product: newPaybar.product }
}

export default connect(mapStateToProps)(NewPaybarLaunchpad)
