import { Component } from 'preact'
import { formatNumber } from 'lib/textHelper'
import { formatBonusScore } from './helpers'

const ResultRow = ({ title, score }) => (
  <tr>
    <td className="taskResultTable-partName">
      <p>{ title }</p>
    </td>
    <td>
      <p>{ score }</p>
    </td>
  </tr>
)

const CorrectionRow = ({ correction }) => (<ResultRow title={ I18n.t('classroom.correction') } score={ correction } />)

const AverageRow = ({ average }) => (<ResultRow title={ I18n.t('classroom.average') } score={ average } />)

const BonusRow = ({ title, score }) => (<ResultRow title={ title } score={ score } />)

class TaskResultMarkAverageCorrectionTable extends Component {
  render() {
    return (
      <div className="taskResultTable-common">
        <table className="taskResultTable">
          <tbody>
            { this.props.correction != null &&
              <CorrectionRow correction={ formatNumber(this.props.correction) } />
            }

            { this.props.average != null &&
              <AverageRow average={ formatNumber(this.props.average) } />
            }

            { this.props.bonuses.length > 0 &&
              this.props.bonuses.map(bonusPart => (
                <BonusRow title={ bonusPart.column } score={ formatBonusScore(bonusPart) } />
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}

module.exports = TaskResultMarkAverageCorrectionTable
