import JSON_HEADERS from 'jsonHeaders'

const getDummyResponse = () => {
  const response = { success: true }

  const shouldRespondWithError = Math.random() > 0.7

  return new Promise((resolve) => {
    if (shouldRespondWithError) throw new Error()

    setTimeout(() => { resolve(response) }, 600)
  })
}

module.exports = ({ productId, token }) => {
  // if (!process.env.APP_ENV) return getDummyResponse()

  const cancelApiUrl = `/api/products/${productId}/subscription/cancellations/${token}`

  return fetch(cancelApiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: JSON_HEADERS,
  })
    .then(res => res.json())
}
