const CLASS_TARGET = 'is__highlightMark'

module.exports = (e) => {
  e.preventDefault()

  const questionId = e.target.href.split('#').pop()
  const el = document.getElementById(questionId)

  if (el) {
    $(`.question.${CLASS_TARGET}`).removeClass(CLASS_TARGET)
    $(el).addClass(CLASS_TARGET).trigger('appHighlight')

    el.scrollIntoView()
  }
}
