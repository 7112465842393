const I18n = require('i18n-js')

I18n.defaultLocale = 'ru'
I18n.pluralization.ru = function(count) {
  var key = count % 10 == 1 && count % 100 != 11 ? 'one' : [2, 3, 4].indexOf(count % 10) >= 0 && [12, 13, 14].indexOf(count % 100) < 0 ? 'few' : count % 10 == 0 || [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 || [11, 12, 13, 14].indexOf(count % 100) >= 0 ? 'many' : 'other'
  return [key]
}

I18n.translations = Object.assign({}, require('lib/i18n'))

I18n.locale = $('html').attr('lang') || I18n.defaultLocale

module.exports = I18n
