import { Heading3, TextNode, Caption } from '../textNodes'

const LabelTitleLink = (props) => {
  let classes = ['labelTitleLink', 'module'].concat(props.className.split(' '))

  if (props.align) classes.push(`is__${props.align}`)
  if (props.size) classes.push(`is__${props.size}`)

  return (
    <a href={ props.href } className={ classes.join(' ') }>
      { props.label &&
        <Heading3 className="labelTitleLink-label">{ props.label }</Heading3>
      }
      <TextNode className="labelTitleLink-title">{ props.title }</TextNode>
      <Caption className="labelTitleLink-link">
        <span className="link">{ props.linkText }</span>
        { props.linkSuffix &&
          <span className="link no-u" dangerouslySetInnerHTML={{ __html: props.linkSuffix }} />
        }
      </Caption>
    </a>
  )
}

export default LabelTitleLink
