import PurchaseIntentTracking from './purchaseIntentTracking'

const OPEN_PAYBAR_TIMEOUT_IN_SECONDS = 60

const purchaseIntentTracking = new PurchaseIntentTracking()

function purchaseIntentMiddleware({ getState }) {
  return next => action => {
    const actionType = action.type
    const state = getState().newPaybar

    switch (actionType) {
      case 'SET_USER':
        purchaseIntentTracking.start({ state })
        break

      case 'ADD_DIRTY_FORM':
        purchaseIntentTracking.start({ state })
        break

      case 'SET_VISIBLE':
        clearTimeout(purchaseIntentTracking.lastScheduledStart)
        purchaseIntentTracking.lastScheduledStart = setTimeout(() => {
          purchaseIntentTracking.start({ state })
        }, OPEN_PAYBAR_TIMEOUT_IN_SECONDS * 1000)
        break

      case 'SET_HIDDEN':
        clearTimeout(purchaseIntentTracking.lastScheduledStart)
        break

      case 'SET_LOADING':
        purchaseIntentTracking.cancel({ state })
        break

      default:
        break
    }

    return next(action)
  }
}

export default purchaseIntentMiddleware
