import { Component } from 'preact'

const WORDS = [
  'Контент',
  'Подробнее...',
  'Веб-ресурс',
  'Тизер',
  'Юзабилити',
  'Динамично развивающийся',
  'Круговая диаграмма',
  'Текст текст текст',
  'Lorem ipsum',
  'PowerPoint',
  'Сайт в разработке',
  'Медийный баннер',
  'Бриф',
  'Креатив',
  'Социальная сеть',
  'Синергия идей',
  'Облако тегов',
  'Таиланд',
]

class ForbiddenWords extends Component {
  constructor(props) {
    super(props)

    this.state = { index: Math.floor(Math.random() * WORDS.length) }

    this.changeWord = this.changeWord.bind(this)
  }

  changeWord() {
    const index = (this.state.index + 1) % WORDS.length

    this.setState({ index })
  }

  render() {
    return (
      <div class="forbiddenWords">
        Запрещённые слова<br />
        <del onMouseOver={ this.changeWord }><span>{ WORDS[this.state.index] }</span></del>
      </div>
    )
  }
}

$('.js__forbiddenWords').each((_, el) => $(el).reactify(ForbiddenWords))
