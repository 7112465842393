import { Component } from 'preact'
import cx from 'classnames'

class SlideInLayer extends Component {
  constructor(props) {
    super(props)

    this.onOverlayClick = this.onOverlayClick.bind(this)
    this.onEscape = this.onEscape.bind(this)
  }

  componentDidMount() {
    this.setEventListener()
  }

  onOverlayClick(e) {
    const isBgClick = e.target === this.$overlayBg

    if (!isBgClick) return

    e.preventDefault()
    this.props.setHidden()
  }

  setEventListener() {
    $(document).on('keydown', this.onEscape)
  }

  onEscape({ keyCode }) {
    if (keyCode === 27) this.props.setHidden()
  }

  render(props) {
    const classes = cx('slideInLayer', props.className)

    return (
      <div className={ classes }>
        <div className="slideInLayer-box">{ props.children }</div>

        <div className="slideInLayer-overlay">
          <div className="slideInLayer-bgClickHandler"
            onMouseDown={ this.onOverlayClick }
            onTouchStart={ this.onOverlayClick }
            ref={ el => this.$overlayBg = el } />
        </div>
      </div>
    )
  }
}

export default SlideInLayer
