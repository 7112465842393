$('.image.is__paywalled, .notFoundImage.is__paywalled, a.is__paywalled')
  .on('click', () => {
    const paywallFooter = document.getElementById('paywallFooter')
    const pageTop = window.pageYOffset
    const { viewHeight } = window.application
    const paywallFooterTop = pageTop + paywallFooter.getBoundingClientRect().top
    const noticeShift = 144
    const scrollTop = paywallFooterTop - viewHeight / 2 + noticeShift
    const duration = 300

    $('html, body')
      .animate({ scrollTop }, { duration })
  })
