import { Component } from 'preact'
import throttle from 'throttle'

class HorizontalScrollableBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLeftFullyVisible: true,
      isRightFullyVisible: true,
    }

    this.checkBoundaries = throttle(300, this.checkBoundaries.bind(this))
  }

  componentDidMount() {
    $(document).on('appResize.horizontalScrollableBox boxGeometryChange', this.calculate.bind(this))

    this.calculate()
  }

  componentWillUnmount() {
    $(document).off('appResize.horizontalScrollableBox')
  }

  getScrollWidth() {
    return this.box.scrollWidth - this.box.clientWidth
  }

  calculate() {
    this.scrollWidth = this.getScrollWidth()
    this.checkBoundaries()
  }

  checkBoundaries() {
    if (this.box.scrollLeft <= 0) {
      this.setState({ isLeftFullyVisible: true })
    } else {
      this.setState({ isLeftFullyVisible: false })
    }

    if (this.box.scrollLeft >= this.scrollWidth) {
      this.setState({ isRightFullyVisible: true })
    } else {
      this.setState({ isRightFullyVisible: false })
    }
  }

  render(props) {
    return (
      <div className={`
            horizontalScrollableBox
            ${this.state.isLeftFullyVisible ? '' : 'is__leftScrolled'}
            ${this.state.isRightFullyVisible ? '' : 'is__rightScrolled'}
          `}
        >
        <div className="horizontalScrollableBox-view">
          <div className="horizontalScrollableBox-holder"
              ref={ el => this.box = el }
              onScroll={ this.checkBoundaries }
            >
            <div className="horizontalScrollableBox-shaft">
              { props.children }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

module.exports = HorizontalScrollableBox
