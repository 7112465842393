import { Component } from 'preact'
import Cookies from 'js-cookie'
import { toSnakeCase } from 'lib/textHelper'

import SubscriptionRetry from './subscriptionRetry'
import SubscriptionUpdateCard from './subscriptionUpdateCard'

class SubscriptionNotification extends Component {
  constructor(props) {
    super(props)

    this.state = { notifications: [] }
  }

  componentDidMount() {
    this.setState({ notifications: this.notificationsInCookiesOrUrl })
  }

  get notificationsInCookiesOrUrl() {
    return Object.keys(this.props.messages)
      .map(type => {
        const cookieLabel = `s_${toSnakeCase(type)}`
        const cookie = Cookies.get(cookieLabel)

        Cookies.remove(cookieLabel)

        return {
          type,
          message: this.props.messages[type],
          data: cookie,
        }
      })
      .filter(notification => {
        return notification.data !== undefined ||
          window.location.search.includes(notification.type)
      })
  }

  get productId() {
    return window.productSubscription?.subscription?.product_id
  }

  retryIsVisible(notification) {
    return (this.productId && notification.type === 'redeemFail')
  }

  render() {
    const { notifications } = this.state

    return (
      <div className="subscriptionNotifications">
        {notifications.map(notification => (
          <div className="topLevelNotification holder is__active">
            <div className="topLevelNotification-main">
              <span className="topLevelNotification-text">
                { notification.message }
              </span>

              {this.retryIsVisible(notification) &&
                <SubscriptionRetry
                  productId={this.productId}
                  url={`/subscriptions/${this.productId}/redeem/${notification.data}`}
                />
              }
            </div>

            <div className="topLevelNotification-side">
              { ['renewFail'].includes(notification.type) &&
                <SubscriptionUpdateCard />
              }
            </div>
          </div>
        ))}
      </div>
    )
  }
}

$('.js__subscriptionNotification').each((_, el) => {
  const DEFAULT_MESSAGES = I18n.t('subscriptionNotifications')

  const messages = {
    ...DEFAULT_MESSAGES,
    ...JSON.parse(el.dataset.messages || JSON.stringify({})),
  }

  $(el).reactify(SubscriptionNotification, { messages })
})
