import { Component } from 'preact'

import { Product, ProductMenuItem } from '../product'

export default class CommentsMenu extends Component {
  constructor(props) {
    super(props)

    this.baseUrl = window.location.pathname
  }

  switchTab(url, e) {
    e.preventDefault()
    this.props.changeTabAction('', url)
  }

  render({ count, activeTab }) {
    const allCount = count.approved + count.new
    const isAllActive = activeTab === 'all'
    const isNewActive = activeTab === 'new'
    const isSunkActive = activeTab === 'sunk'
    const humanizedActiveTab = I18n.t(`comments.menu.tabs.${activeTab}`)

    return (
      <Product name={humanizedActiveTab} mod="h2">
        <ProductMenuItem
          link={ this.baseUrl }
          isActive={ isAllActive }
          isDisabled={ allCount === 0 && !isAllActive }
          count={ allCount }
          onClick={ this.switchTab.bind(this, this.baseUrl) }
        >
          { I18n.t('comments.menu.tabs.all') }
        </ProductMenuItem>

        <ProductMenuItem
          link={ `${this.baseUrl}#comments-new` }
          isActive={ isNewActive }
          isDisabled={ count.new === 0 && !isNewActive }
          count={ count.new }
          onClick={ this.switchTab.bind(this, `${this.baseUrl}#comments-new`) }
        >
          { I18n.t('comments.menu.tabs.new') }
        </ProductMenuItem>

        <ProductMenuItem
          link={ `${this.baseUrl}#comments-sunk` }
          isActive={ isSunkActive }
          isDisabled={ count.sunk === 0 && !isSunkActive }
          onClick={ this.switchTab.bind(this, `${this.baseUrl}#comments-sunk`) }
        >
          { I18n.t('comments.menu.tabs.sunk') }
        </ProductMenuItem>
      </Product>
    )
  }
}
