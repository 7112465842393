import isSubscriptionCancelled from './isSubscriptionCancelled'
import NewPaybarFooterSubscriptionMessage from './newPaybarFooterSubscriptionMessage'
import NewPaybarSubscriptionStatus from './bookshelf/newPaybarSubscriptionStatus'
import NewPaybarFooterCaption from './newPaybarFooterCaption'

const NewPaybarSubscriptionInfo = (props) => {
  const { subscription } = props
  const isSubscribed = !!subscription.id
  const isCancelled = isSubscriptionCancelled(subscription)

  return (
    <div className="newPaybarSubscriptionInfo">
      <NewPaybarFooterSubscriptionMessage isCancelled={ isCancelled } {...props}/>

      { (isSubscribed && !isCancelled) &&
        <div>
          <NewPaybarFooterCaption isForcedSubscription/>
          <NewPaybarSubscriptionStatus/>
        </div>
      }
    </div>
  )
}

export default NewPaybarSubscriptionInfo
