class DoubleHover {
  constructor($el) {
    this.CLASS_HOVER = 'hover'
    this.$el = $el

    const href = this.$el.attr('href') || this.$el.find('a').attr('href')

    this.$doubles = $(`.doubleHover[href="${href}"], .doubleHover a[href="${href}"]`)

    this.setListeners()
  }

  setListeners() {
    this.$el.off('mouseenter.doublehover').on('mouseenter.doublehover', () => {
      this.$doubles.addClass('hover')
    })

    this.$el.off('mouseleave.doublehover').on('mouseleave.doublehover', () => {
      this.$doubles.removeClass('hover')
    })
  }
}

module.exports = DoubleHover
