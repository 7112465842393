const MAX_INITIAL_LINK_LENGTH = 40
const MAX_RIGHT_PART_LENGTH = 30
const SEPARATOR = '…'

const segmentsLengthSum = segments => segments.reduce((sum, segment) => sum += segment.length, 0)

const splitOnSegments = (pathname) => {
  const segmentRx = /[\W]+[\w]+/g
  let urlSegments = []
  let urlSegment = ''

  while ((urlSegment = segmentRx.exec(pathname)) !== null) {
    urlSegments.push(urlSegment[0])
  }

  return urlSegments
}

const gatherMaxPossibleSegments = (segments) => {
  let combinedSegments = []

  for (let i = 0; i < segments.length; i++) {
    const currentSegment = segments[i]
    let currentSegmentsWithNextLength = segmentsLengthSum([...combinedSegments, currentSegment])

    if (currentSegmentsWithNextLength > MAX_RIGHT_PART_LENGTH) break

    combinedSegments.push(currentSegment)
  }

  return combinedSegments
}

module.exports = (link) => {
  if (link <= MAX_INITIAL_LINK_LENGTH) return link

  const linkRx = /(https?:\/\/[^\/]+)(\/.+)/i
  const matches = linkRx.exec(link)
  if (!matches || !matches.length) return link

  const domain = matches[1]
  const rightPart = matches[2]

  if (!rightPart || rightPart.length < MAX_RIGHT_PART_LENGTH) return link

  const rightPartSegments = splitOnSegments(rightPart)

  let newRightPart = ''

  if (rightPartSegments.length > 1) {
    rightPartSegments.reverse()
    newRightPart = gatherMaxPossibleSegments(rightPartSegments).reverse().join('')
  } else {
    newRightPart = rightPart.slice(-MAX_RIGHT_PART_LENGTH)
  }

  return `${domain}/${SEPARATOR}${newRightPart}`
}
