export function urlToParamsArray(url) {
  if (!url.trim()) return []

  const params = url.split('&')
  const paramsSerialized = []

  params.forEach(param => {
    const paramSplitted = param.replace('?', '').split('=')

    paramsSerialized.push({
      name: paramSplitted[0],
      value: paramSplitted[1],
    })
  })

  return paramsSerialized
}

export function urlParamsArrayToStr(params) {
  let result = ''

  params.forEach((param, i) => {
    const prefix = i ? '&' : '?'
    const value = param.value ? `=${param.value}` : ''

    result += `${prefix}${param.name}${value}`
  })

  return result
}
