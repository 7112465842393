import { Component } from 'preact'
import TextareaAutosize from 'react-textarea-autosize'

export default class MediaTextarea extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <TextareaAutosize value={ this.props.text } />
    )
  }
}
