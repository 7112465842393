const { addTimezoneOffset } = require('./timezoneUtils')

class DateRange {
  constructor(start, end) {
    this.start = start
    this.end = end
  }
}

export const shortMonths = [
  'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
  'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек',
]

export const months = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
]

export const monthsParentalCaseList = [
  'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
  'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря',
]

const getShortMonthName = monthNumber => shortMonths[monthNumber]

const MONTH_COUNT = 12
const MONTH_TO_SHOW_COUNT = 3
const SUNDAY_NUMBER = 6

const offsetDay = day => day ? day - 1 : SUNDAY_NUMBER

const makeRange = (from, to) => [...Array(to - from).keys()].map(i => i + from)

const getWeekOfMonth = date => Math.abs(Math.ceil((date.getDate() - 1 - offsetDay(date.getDay())) / 7))

const addDays = (date, days) => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)

  return newDate
}

const getDates = range => {
  const dateArray = []
  let currentDate = range.start

  while (currentDate <= range.end) {
    dateArray.push(new Date(currentDate))
    currentDate = addDays(currentDate, 1)
  }

  return dateArray
}

export const generateMonth = (year, month) => {
  const startDate = new Date(year, month, 1)
  const endDate = new Date(year, month + 1, 0)

  const dates = getDates(new DateRange(startDate, endDate))

  return dates
}

export const groupMonthByWeeks = month => {
  let result = []

  month.forEach(date => {
    const currentWeek = getWeekOfMonth(date)

    result[currentWeek] = [...(result[currentWeek] || []), date]
  })

  return result
}

const monthNumberToMonth = monthNumber => ({
  name: getShortMonthName(monthNumber % MONTH_COUNT),
  isJanuary: monthNumber % MONTH_COUNT === 0,
  weeks: groupMonthByWeeks(generateMonth(new Date().getFullYear(), monthNumber)),
})

const trimToToday = (months, currentWeekNumber, currentDayOfWeek) => {
  const result = months.slice()

  result[0].weeks[currentWeekNumber] = result[0].weeks[currentWeekNumber]
    .filter(date => offsetDay(date.getDay()) >= currentDayOfWeek)

  result[0].weeks = result[0].weeks.slice(currentWeekNumber)

  return result
}

export const generateMonths = date => {
  const startMonth = date.getMonth()
  const endMonth = date.getMonth() + MONTH_TO_SHOW_COUNT
  const currentWeekNumber = getWeekOfMonth(date)
  const currentDayOfWeek = offsetDay(date.getDay())

  const monthsRange = makeRange(startMonth, endMonth).map(monthNumberToMonth)
  const result = trimToToday(monthsRange, currentWeekNumber, currentDayOfWeek)

  return result
}

const areDatesEqual = (date1, date2) => (
  date1.getFullYear() === date2.getFullYear()
  && date1.getMonth() === date2.getMonth()
  && date1.getDate() === date2.getDate()
)

const mapMonthDays = (month, cb) => month.weeks.map(week => week.map(cb))

export const isDateEngaged = (engagedDates, date) =>
  Boolean(engagedDates.find(d => areDatesEqual(d, date)))

export const markEngaged = (months, engagedDates) =>
  months.map(month => ({
    ...month,
    weeks: mapMonthDays(month, date => ({
      date,
      isEngaged: isDateEngaged(engagedDates, date),
    })),
  }))

export const datify = date => new Date(date)

export const unifyEngagedDates = engagedDates =>
  engagedDates
    .map(datify)
    .map(addTimezoneOffset)


export const omitSelectedDate = (engagedDates, currentSovietId) => {
  const filteredDates =
    Object.keys(engagedDates)
      .reduce((acc, key) =>
        engagedDates[key] !== currentSovietId ? [...acc, key] : acc, [])

  return unifyEngagedDates(filteredDates)
}
