class DynamicPlaceholders {
  constructor($el, options = {}) {
    this.$el = $el;
    this.rotations = options.rotations;
    this.templates = options.templates;
    this.lastPromotedCategory = null;
  }

  fill() {
    this.rotationIndices = this._rebuildRotationIndices();

    this.$el
      .find('[data-auto-fill]')
      .filter((_, el) => el.offsetParent)
      .each(this._replaceWithAppropriateContent.bind(this));
  }

  _rebuildRotationIndices() {
    return Object.keys(this.rotations)
      .reduce((grouped, key) => {
        grouped[key] = 1;
        return grouped;
      }, {});
  }

  _replaceWithAppropriateContent(_, el) {
    const $el = $(el);
    const replacementType = $el.data('auto-fill');

    this._replace(replacementType, $el);
  }

  _replace(type, $el) {
    const variants = this.rotations[type];
    const currentIndex = this.rotationIndices[type];
    const variant = variants[(currentIndex - 1) % variants.length];

    const fillParams = $el.data('auto-fill-params');

    const renderMethodType = type.charAt(0).toUpperCase() + type.slice(1);
    const rendered = this[`_render${renderMethodType}`](variant, fillParams);
    const $filler = $(rendered).addClass($el.attr('class'));

    if (type !== 'category') {
      $filler.attr('data-auto-fill', $el.data('auto-fill'))
        .attr('data-auto-fill-params', JSON.stringify($el.data('auto-fill-params')));
    }

    $el.replaceWith($filler);
    this.rotationIndices[type] = this.rotationIndices[type] + 1;
  }

  _renderSoviet(soviet, options = {}) {
    const params = Object.assign(
      {},
      { soviet: soviet },
      options
    );

    return this.templates.soviet(params);
  }

  _renderCourse(course, options = {}) {
    const params = Object.assign(
      {},
      { course: course },
      options
    );

    return this.templates.course(params);
  }

  _renderCategory(_, options) {
    const category = this._findNextPromotedCategory(options);
    this.lastPromotedCategory = category;

    return this.templates.promotedCategory({ category: category });
  }

  _findNextPromotedCategory(options) {
    const variants = this.rotations.category
      .filter(category => {
        return category !== this.lastPromotedCategory &&
          !(options.hasSmallArea && category.hasLongTitle);
      });
    let result = variants[Math.floor((Math.random() * variants.length))];

    for (var i = 0; i < options.categories.length; i++) {
      const projectCategoryTitle = options.categories[i];

      const correspondingCategory = variants
        .filter(category => category.normalizedTitle === projectCategoryTitle)
        .pop();

      if (correspondingCategory) {
        result = correspondingCategory;
        break;
      }
    }

    return result;
  }
}

module.exports = DynamicPlaceholders;
