import { Component } from 'preact'

import Price from '../price/price'
import Pricing from '../price/pricing'

import { formatPrice } from 'lib/textHelper'

export default class SubscriptionPricingWithFootnote extends Component {
  get defaultUnits() {
    return I18n.t('subscription.unitsOneTime')
  }

  get label() {
    const { subscriptionPeriod } = this.props.prices
    return I18n.t(`subscription.prices.defaultLabel_${subscriptionPeriod}`)
  }

  get footnote() {
    const { subscription, renewalPeriod } = this.props.prices
    const prefix = I18n.t('subscription.prices.after')
    const units = I18n.t(`subscription.subscriptionUnits_${renewalPeriod}`)
    return `${prefix} ${formatPrice(subscription)} ${units}`
  }

  render() {
    const { prices, largePrices, unitsSize } = this.props

    let defaultPriceLabelSuffix = ''

    if (prices.isApplicableForDiscount && prices.previousOneTime !== prices.oneTime) {
      defaultPriceLabelSuffix = ` <s>${prices.previousOneTime}</s>`
    }

    return (
      <Pricing currency={ prices.currency }>
        <Price
          label={ this.label + defaultPriceLabelSuffix }
          footnote={ this.footnote }
          amount={ formatPrice(prices.oneTime) }
          className={ prices.isApplicableForDiscount ? 'is__applicableForDiscount' : '' }
          unitsSize={ unitsSize }
          large={ largePrices }
          spaceTypeBeforeUnits={ 'regular' }
          units={ this.hasLeftUnits ? null : this.defaultUnits }
        />
      </Pricing>
    )
  }
}
