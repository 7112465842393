import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import AUTH_HEADERS from 'authHeaders'
import JSON_HEADERS from 'jsonHeaders'

import { setIsPublishing, setHasPublishError } from './sovietCompilationActions'
import { storeToJsonParams } from './sovietCompilationStore'

const PUBLISH_HEADERS = Object.assign({}, AUTH_HEADERS, JSON_HEADERS)

class SovietCompilationPublishButton extends Component {
  constructor(props) {
    super(props)

    this.publishCompilation = this.publishCompilation.bind(this)
  }

  publishCompilation() {
    if (!this.props.canPublish) return

    this.props.setIsPublishing()

    this._sendCompilationData()
  }

  _publishUrl() {
    if (!this.props.compilationId) return '/soviet/advice_compilations'

    return `/soviet/advice_compilations/${this.props.compilationId}`
  }

  _publishMethod() {
    if (!this.props.compilationId) return 'POST'

    return 'PUT'
  }

  _sendCompilationData() {
    return fetch(this._publishUrl(), {
      method: this._publishMethod(),
      headers: PUBLISH_HEADERS,
      body: JSON.stringify(this.props.jsonData),
    })
    .then((res) => {
      if (res.ok) {
        res.json().then(this._redirectToPublishedCompilation.bind(this))
      } else {
        this.props.setHasPublishError()
      }
    })
  }

  _redirectToPublishedCompilation(json) {
    try {
      localStorage.removeItem(this.props.scope)
    } catch (e) { }

    window.location = `/soviet/selected/${json.user_english_name}/${json.slug}/`
  }

  render(props) {
    const classNames = cx({
      compilationPublishButton: true,
      is__enlargedOnMobiles: true,
      sticky: true,
      button: true,
      has__failed: props.hasPublishError,
    })

    const buttonText = props.compilationId ? 'Сохранить' : 'Опубликовать'

    return (
      <div className="compilationPublishButton-shaft">
        <button
          onClick={ this.publishCompilation }
          className={ classNames } { ...{ disabled: !props.canPublish || props.isPublishing } }>
          { buttonText }
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scope: state.sovietCompilation.scope,
    compilationId: state.sovietCompilation.id,
    canPublish: state.sovietCompilation.canPublish,
    isPublishing: state.sovietCompilation.isPublishing,
    hasPublishError: state.sovietCompilation.hasPublishError,
    jsonData: storeToJsonParams(state),
  }
}

const mapDispatchToProps = {
  setIsPublishing,
  setHasPublishError,
}


export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationPublishButton)
