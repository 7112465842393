import Leadbar from './leadbar'

const DEFAULT_CLASS = 'is__default'

$('.js__leadbar').each((_, el) => {
  const $el = $(el)
  const leadMagnetId = $el.attr('data-lead-magnet-id')
  const $leadMagnet = $(`.js__leadMagnet[data-id='${leadMagnetId}']`)
  let props = $leadMagnet.data('options')

  if (!props.href) {
    const $link = $leadMagnet.find('[href]')
    props.href = $link.attr('href')
  }

  props.triggeredByDefault = $leadMagnet.hasClass(DEFAULT_CLASS)

  $el.reactify(Leadbar, props)
  $el.children().unwrap()
})
