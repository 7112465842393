export default ({ product, productId }) => {
  const key = productId.split('-').slice(1).join('-')

  switch (product) {
    case 'book':
      return `/books/${key}/`

    case 'bookshelf':
      return `/projects/bookshelf-${key}/`

    case 'lecture':
      return `/lectures/${key}/watch/`

    case 'course':
      return `/courses/${key}/classroom/`

    default:
      return null
  }
}
