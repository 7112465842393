import { Component } from 'preact'
import cx from 'classnames'

import { fetchSchoolData, getPrepLink } from '../schoolSpacerUtils'
import { schoolPrepsDeadlineScoreSpacer as config } from '../schoolSpacersConfig'
import { birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../../calendar/timezoneUtils'

import { Caption, TextNode } from '../../textNodes'
import SchoolSpacerTitle from '../schoolSpacerTitle'
import TiltedSquareNumber from '../tiltedSquareNumber'

const SpacerPlot = (props) => {
  const moscowTime = moscowifyLocal(new Date(props.endsAt))
  const endsAt = birmanize(moscowTime, { format: 'dateOnly' })
  const formattedScore = props.currentScore
    .toString()
    .replace('.', '<span>,</span>')

  return (
    <div className="schoolPrepsDeadlineScoreSpacer-plot">
      <a href={ props.link } className="novisited doubleHover is__noUnderline">
        <TiltedSquareNumber
          number={ formattedScore }
          caption={ `Макс. балл<br />до ${ endsAt }` }
          mobileCaption={ `Макс. балл<br />до ${ endsAt }` }
        />
      </a>
      <div className="schoolPrepsDeadlineScoreSpacer-text">
        <SchoolSpacerTitle href={ props.link } title={ config.title } />
        <TextNode>
          { config.text }
          <span className="device mobile">
            : <a
              href={ props.link }
              className="schoolPrepsDeadlineScoreSpacer-programLink novisited"
            >
              программа
            </a>
          </span>
        </TextNode>
      </div>
    </div>
  )
}

const EnrollButton = (props) => {
  const actionText = props.isGift ? 'Подарить' : 'Записаться'
  const link = `${props.link}&${props.isGift ? 'gift' : 'self'}`

  return (
    <div className="schoolPrepsDeadlineScoreSpacer-action">
      <a
        href={ link }
        className="schoolPrepsDeadlineScoreSpacer-button button is__thin is__transparent"
      >
        { actionText } за { props.currentPrice } ₽
      </a>
      <Caption>
        В пн цена повысится до { props.nextPrice } ₽
      </Caption>
    </div>
  )
}

export default class SchoolPrepsDeadlineScoreSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { currentPrice: '', nextPrice: '', currentScore: '', endsAt: '' }

    this.link = getPrepLink()
  }

  componentDidMount() {
    fetchSchoolData()
      .then(json => this.setState({
        currentPrice: json.currentPrepPrice.price,
        nextPrice: json.currentPrepPrice.nextPrice,
        currentScore: json.currentPrepScore.score,
        endsAt: json.currentPrepScore.endsAt,
      }))
      .catch(e => console.error(e))
  }

  render() {
    const { size, isGift } = this.props
    const classes = cx(
      'schoolPrepsDeadlineScoreSpacer',
      {
        is__full: size === 'full',
        is__half: size === 'half',
      }
    )

    return (
      <div className={ classes }>
        <div className="schoolPrepsDeadlineScoreSpacer-cols">
          <SpacerPlot
            link={ this.link }
            currentScore={ this.state.currentScore }
            endsAt={ this.state.endsAt }
          />
          { this.state.currentPrice &&
            <EnrollButton
              isGift={ isGift }
              link={ this.link }
              currentPrice={ this.state.currentPrice }
              nextPrice={ this.state.nextPrice }
            />
          }
        </div>
      </div>
    )
  }
}
