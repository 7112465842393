import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { setIsProcessing, setHasPublicationApprovalError, setIsPublicationApproved } from './sovietActions'

import { DropdownIcon } from '../icons'
import { isDateEngaged, omitSelectedDate } from '../calendar/dateUtils'
import { birmanize } from 'lib/textHelper'
import { datify, moscowifyLocal } from '../calendar/timezoneUtils'
import Show from './suggestInput/show'
import SovietCalendarPopupContainer from './sovietCalendarPopupContainer'
import { saveDraftAnd } from './saveDraft'

import PUBLISH_HEADERS from 'authJsonHeaders'

const HTTP_CONFLICT_CODE = 409

class SovietPublishButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDropdownOpened: false,
      isCurrentDateEngaged: false,
    }

    this.publishSoviet = this.publishSoviet.bind(this)
    this.previewSoviet = this.previewSoviet.bind(this)
    this.redirectToPublishedSoviet = this.redirectToPublishedSoviet.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.hideDropdownOnDocumentClick = this.hideDropdownOnDocumentClick.bind(this)
    this.bindButtonRef = this.bindButtonRef.bind(this)
    this.hideDropdown = this.hideDropdown.bind(this)
    this.onEsc = this.onEsc.bind(this)
    this.checkPublicationApprovalErrorCode = this.checkPublicationApprovalErrorCode.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.hideDropdownOnDocumentClick)
    document.addEventListener('keydown', this.onEsc)
    $(document).on('enableFocusMode', this.hideDropdown)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDropdownOnDocumentClick)
    document.removeEventListener('keydown', this.onEsc)
    $(document).off('enableFocusMode', this.hideDropdown)
  }

  componentWillReceiveProps({ hasPublicationApprovalError }) {
    if (!hasPublicationApprovalError) this.setState({ isCurrentDateEngaged: false })
  }

  publishSoviet() {
    this.sendPublicationApprovalRequest('PUT')
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then(this.redirectToPublishedSoviet)
            .catch(this.props.setHasPublicationApprovalError)
        } else if (res.status === HTTP_CONFLICT_CODE) {
          res.json().then(this.checkPublicationApprovalErrorCode)
        } else {
          this.props.setHasPublicationApprovalError()
        }
      })
      .catch(this.props.setHasPublicationApprovalError)
  }

  checkPublicationApprovalErrorCode(json) {
    const error = (json.errors || [])[0] || {}

    if (/unable_to_publish/.test(error.code)) this.setState({ isCurrentDateEngaged: true })

    this.props.setHasPublicationApprovalError()
  }

  previewSoviet() {
    const { setIsProcessing, slug } = this.props
    setIsProcessing()

    return saveDraftAnd({ context: this.context })
      .then(() => window.location = `/soviet/${slug}/preview/`)
  }

  sendPublicationApprovalRequest(method) {
    if (!this.props.canPublish) return Promise.reject()

    this.props.setIsProcessing()

    if (method === 'DELETE') return this.fetchPublicationApproval(method)

    return saveDraftAnd({ context: this.context })
      .then(() => this.fetchPublicationApproval(method))
  }

  fetchPublicationApproval(method) {
    return fetch(this.publicationApprovalUrl, {
      method,
      headers: PUBLISH_HEADERS,
      credentials: 'include',
    })
  }

  get publicationApprovalUrl() {
    return `/soviet/advices/${this.props.sovietId}/publication_approval`
  }

  redirectToPublishedSoviet(json) {
    window.location = `/soviet/${json.slug}/`
  }

  toggleDropdown() {
    this.setState({ isDropdownOpened: !this.state.isDropdownOpened })
  }

  hideDropdown() {
    this.setState({ isDropdownOpened: false })
  }

  hideDropdownOnDocumentClick(e) {
    if (!this.currentEl.contains(e.target)) this.setState({ isDropdownOpened: false })
  }

  onEsc(e) {
    if (e.key === 'Escape') this.hideDropdown()
  }

  bindButtonRef(ref) {
    this.currentEl = ref
  }

  get date() {
    return moscowifyLocal(datify(this.props.publishAt))
  }

  get publishText() {
    const isYearSame = (new Date()).getFullYear() === this.date.getFullYear()
    const dateFormat = isYearSame ? 'dateOnly' : 'dateYearOnly'
    const date = birmanize(this.date, { format: dateFormat })
    const weekDay = birmanize(this.date, { format: 'weekDay' })

    return `
      Опубликовать ${date}<span class="is__hiddenOnNarrowMobile">,
        <span class="smallcapitals">${weekDay}</span>
      </span>
      `.trim()
  }

  render(props) {
    const { isDropdownOpened, isCurrentDateEngaged } = this.state
    const buttonText = props.isPreview ? this.publishText : 'Посмотреть'
    const handleButtonClick = props.isPreview ? this.publishSoviet : this.previewSoviet
    const errorText = `${ birmanize(this.date, { format: 'dateOnly' }) } занято`
    const shouldShowDateError = isCurrentDateEngaged && props.hasPublicationApprovalError
    const isButtonDisabled = props.isPublishingOrUnpublishing || shouldShowDateError

    const wrapperClassName = cx(
      'sovietPublish-buttonWrapper',
      {
        has__failed: props.hasPublicationApprovalError,
      }
    )

    const buttonClassName = cx(
      'sovietPublish-publishButton',
      'sticky',
      'button',
      { has__error: shouldShowDateError }
    )

    const dropDownButtonClassName = cx(
      'button',
      'sticky',
      'sovietPublish-dropdownButton',
      { is__disabled: isButtonDisabled }
    )

    return (
      <div className="sovietPublish-container" ref={ this.bindButtonRef }>
        <div className={ wrapperClassName }>
          <button
            onClick={ handleButtonClick }
            className={ buttonClassName }
            { ...{ disabled: isButtonDisabled } }>
            <span dangerouslySetInnerHTML={ {
              __html: (props.isPreview && shouldShowDateError) ? errorText : buttonText,
            } } />
          </button>

          <button
            className={ dropDownButtonClassName }
            onClick={ this.toggleDropdown }>
            <div className="sovietPublish-dropdownIconWrapper">
              <DropdownIcon />
            </div>
          </button>
        </div>

        <Show if={ isDropdownOpened }>
          <div className="sovietPublish-dropdown">
            <SovietCalendarPopupContainer
              toggleDropdown={ this.toggleDropdown }
              hasPublishDateError={ shouldShowDateError } />
          </div>
        </Show>
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    sovietId: soviet.id,
    slug: soviet.slug,
    isPreview: soviet.isPreview,
    canPublish: soviet.canPublish,
    isPublishingOrUnpublishing: soviet.isPublishingOrUnpublishing,
    hasPublicationApprovalError: soviet.hasPublicationApprovalError,
    isPublicationApproved: soviet.isPublicationApproved,
    publishAt: soviet.publishAt,
    isCurrentDateEngaged: isDateEngaged(
      omitSelectedDate(soviet.engagedDates, soviet.id),
      new Date(soviet.publishAt)
    ),
  }
}

const mapDispatchToProps = {
  setIsProcessing,
  setHasPublicationApprovalError,
  setIsPublicationApproved,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietPublishButton)
