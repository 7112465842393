import { Component } from 'preact'

import TextareaAutosize from 'react-textarea-autosize'

import FormFieldsStorage from '../newPaybar/formFieldsStorage'

const DEFAULT_ROWS_COUNT = 5

export default class LinedTextarea extends Component {
  constructor(props) {
    super(props)

    this.formFieldsStorage = new FormFieldsStorage(props.localStorageKey)

    this.state = { text: props.text || this.localStorageValue }

    this.handleInput = this.handleInput.bind(this)
  }

  get localStorageValue() {
    return this.formFieldsStorage.get()[this.props.name]
  }

  handleInput(event) {
    this.setState({ text: event.target.value })
  }

  render() {
    return (
      <TextareaAutosize
        value={ this.state.text }
        name={ this.props.name }
        onInput={ this.handleInput }
        minRows={ this.props.minRows || DEFAULT_ROWS_COUNT }
        className="formTextarea" />
    )
  }
}
