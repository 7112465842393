import { Component } from 'preact'
import cx from 'classnames'
import { Caption, Heading2 } from '../textNodes'
import RealtimeCountdown from '../realtimeCountdown/realtimeCountdown'
import NewPaybarNotification from './newPaybarNotification'

export default class NewPaybarBasementLastChance extends Component {
  constructor(props) {
    super(props)

    this.state = { canRevert: true }

    this.disableRevert = this.disableRevert.bind(this)
  }

  get originalProduct() {
    const { subscription, product } = this.props

    if (subscription.original_product_id) return subscription.original_product_id.split('-')[0]

    return subscription.product || product
  }

  get title() {
    const { productTitle, subscription } = this.props

    return subscription.original_product_title || productTitle
  }

  get subtitle() {
    return I18n.t(`lastChance.subscriptionCancelled.${this.originalProduct}`)
  }

  get motivatorText() {
    const { subscription } = this.props
    const { valid_until: validUntil } = subscription
    const isTimeLeft = validUntil && new Date(validUntil) > new Date()

    if (!isTimeLeft) return I18n.t(`lastChance.motivator.${this.originalProduct}NoTimeLeft`)

    return I18n.t(`lastChance.motivator.${this.originalProduct}`)
  }

  disableRevert() {
    if (this.state.canRevert) this.setState({ canRevert: false })
  }

  render() {
    const { subscription, onRevert, isLoading } = this.props
    const revertButtonClassNames = cx('button is__purchaseLike is__enlargedOnMobiles', { is__disabled: isLoading })

    return (
      <div>
        <div className="newPaybarBasement-top is__disabled">
          <Heading2 className="newPaybarBasement-title">{ this.title }</Heading2>
          <Caption className="newPaybarBasement-subtitle">{ this.subtitle }</Caption>
        </div>

        <div className="newPaybarBasement-bottom">
          <RealtimeCountdown
            to={ subscription.retention_token_valid_until }
            onExpiry={ this.disableRevert }/>

          <Caption className="newPaybarBasement-text">
            { this.motivatorText }
          </Caption>

          <div className="newPaybarBasement-buttons">
            <NewPaybarNotification device="mobile" />

            <div className="newPaybarBasement-button">
              <button
                className={ revertButtonClassNames }
                disabled={ !this.state.canRevert }
                type="button"
                onClick={ onRevert }
                dangerouslySetInnerHTML={ { __html: I18n.t('lastChance.buttonRevert') } }>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
