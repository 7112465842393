import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { setChartParams, unpinStudent } from './schoolRatingActions'
import { getStudentsWeekNumbers } from './helpers'

import SchoolRatingGraph from './schoolRatingGraph'
import SchoolRatingChartHeader from './schoolRatingChartHeader'
import SchoolRatingChartEmpty from './schoolRatingChartEmpty'

import { MIN_DESKTOP_WIDTH } from 'lib/envHelpers'
const COL_WIDTH = 12
const WIDTH_COMPENSATOR = 1
const ROW_OFFSET = 5
const STUDENT_IN_LIST_HEIGHT = 45

class SchoolRatingChart extends Component {
  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.onResizeFinish = this.onResizeFinish.bind(this)
  }

  componentDidMount() {
    this.props.setChartParams(this.params)

    $(document).on('appResize', this.onResizeFinish)
    $(window).on('resize', this.onResize)
  }

  componentWillUnmount() {
    $(document).off('appResize', this.onResizeFinish)
    $(window).off('resize', this.onResize)
  }

  onResize() {
    if (this.isEmpty || this.state.isResizing) return

    this.setState({ isResizing: true })
  }

  onResizeFinish() {
    if (this.isEmpty) return

    this.props.setChartParams(this.params)

    this.setState({ isResizing: false })
  }

  get params() {
    const { weeksCount } = this.props
    const totalWidth = this.$chart.getBoundingClientRect().width - WIDTH_COMPENSATOR

    const gapsCount = weeksCount - 1
    const colsWidth = weeksCount * COL_WIDTH
    const gapWidth = (totalWidth - colsWidth) / gapsCount

    return {
      $el: this.$chart,
      totalWidth,
      gapWidth,
      colWidth: COL_WIDTH,
      rowHeight: STUDENT_IN_LIST_HEIGHT,
      rowTopOffset: ROW_OFFSET,
    }
  }

  get weeks() {
    return getStudentsWeekNumbers(this.props.students)
  }

  get isEmpty() {
    return !this.weeks.length || this.weeks.every(item => item === 0)
  }

  get shouldDisplayGraph() {
    const isOnMobile = window.application.viewWidth < MIN_DESKTOP_WIDTH
    const hasChartParams = !!this.props.chartParams

    return !(this.isEmpty || isOnMobile || !hasChartParams)
  }

  get styles() {
    if (!this.props.chartParams) return null

    const { chartParams: { gapWidth, colWidth } } = this.props

    return `
      --schoolRatingGapWidth: ${gapWidth}px;
      --schoolRatingColWidth: ${colWidth}px;
    `
  }

  render() {
    const classNames = cx('schoolRatingChart', { is__hidden: this.state.isResizing })

    return (
      <div className={ classNames } ref={ el => this.$chart = el } style={ this.styles }>
        <SchoolRatingChartHeader
          totalWeeksCount={ this.props.weeksCount }
          weeks={ this.weeks }
          isSticky={ !this.isEmpty } />

        <div className="schoolRatingChart-content">
          { this.isEmpty && <SchoolRatingChartEmpty/> }

          { this.shouldDisplayGraph && this.props.students.map(student => (
            <SchoolRatingGraph { ...student }/>
          )) }
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = { setChartParams, unpinStudent }

const mapStateToProps = ({ schoolRating }) => {
  return {
    weeksCount: schoolRating.weeksCount,
    students: schoolRating.students,
    chartParams: schoolRating.chartParams,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolRatingChart)
