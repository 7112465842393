import NewPaybarFooterSubscriptionMessage from './newPaybarFooterSubscriptionMessage'
import NewPaybarSubscriptionStatus from './bookshelf/newPaybarSubscriptionStatus'

export default function NewPaybarSubscriptionStatusMobile(props) {
  return (
    <div className="newPaybarSubscriptionStatusMobile">
      <NewPaybarFooterSubscriptionMessage product={ props.product } type={ props.type } />
      <NewPaybarSubscriptionStatus />
    </div>
  )
}
