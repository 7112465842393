import JSON_HEADERS from 'jsonHeaders'

const widthMap = {
  1: 'full',
  2: 'half',
}

const splitBySemicolon = content => (content || '')
  .split(';')
  .map(entity => entity.trim())
  .filter(entity => entity)

class BannerCell {
  constructor($el) {
    this.$el = $el
    this.$wrapper = this.$el.parent('.bannerWrapper')
    this.ids = String(this.$el.data('ids'))
      .split(',')
      .map(id => id.trim())
    this.width = widthMap[this.ids.length]
  }

  get id() {
    return this.ids[0]
  }

  asJson() {
    return this.ids.map((id) => {
      return {
        id: id,
        width: this.width,
      }
    })
  }
}

class Banners {
  constructor() {
    this.collectBannerCells()

    if (!this.bannerCells.length) return

    this.placements = splitBySemicolon($('meta[name=placement]').attr('content'))
    this.tags = splitBySemicolon($('meta[name=tags]').attr('content'))

    this.collectPageStyles()
    this.addUtmInfo()
    this.fetchBanners()
  }

  collectBannerCells() {
    this.bannerCells = $('.js__banner')
      .map((_, cell) => new BannerCell($(cell)))
      .get()
      .sort((a, b) => a.id - b.id)
  }

  collectPageStyles() {
    this.styles = $(':not(iframe) style')
      .get()
      .map((el) => {
        const styles = $(el).text().match(/:root[\s]*{([\s\w-:;.,()#]+)}/i)
        if (styles && styles[1]) return styles[1]

        return ''
      })
      .join('')
  }

  addUtmInfo() {
    this.meta = (window.location.search + '').replace(/^\?/, '')
  }

  fillFullCell(cell, index, banners) {
    if (banners[index].banner) {
      cell.$el.attr('src', `/embedded/banners/${banners[index].banner}?styles=${encodeURIComponent(this.styles)}&${this.meta}`)
    } else {
      cell.$wrapper.remove()
    }
  }

  fillHalfCells(cell, index, banners) {
    cell.$el.attr('src', `/embedded/banners/double/${banners[index].banner},${banners[index + 1].banner}?styles=${encodeURIComponent(this.styles)}&${this.meta}`)
  }

  fillCells(json) {
    const bannersPool = [...json]
    this.bannerCells.forEach((cell, index) => {
      switch (cell.width) {
        case 'full':
          this.fillFullCell(cell, index, bannersPool)
          break
        case 'half':
          this.fillHalfCells(cell, index, bannersPool)
          bannersPool.splice(index + 1, 1)
          break
      }
    })
  }

  fetchBanners() {
    fetch('/embedded/banners/collect/', {
      method: 'POST',
      headers: JSON_HEADERS,
      body: JSON.stringify({
        placements: this.placements,
        tags: this.tags,
        cells: this.bannerCells.map(cell => cell.asJson()),
      }),
      credentials: 'include',
    })
      .then(res => res.json())
      .then(json => this.fillCells(json))
  }
}

module.exports = Banners
