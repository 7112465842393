{
  const CLASS_STICKABLE = 'is__stickable'
  const CLASS_STICKY = 'is__sticky'

  class TaskResultHeader {
    constructor($el) {
      this.$el = $el
      this.$container = this.$el.parent()
      this.$document = $(document)

      this.height = this.$el.height()
      this.threshold = this.$el.is('.is__imageResult') ? 9 : 0

      this._bindEvents()
      this._calculate()
      this._draw()

      if (window.chrome) $('body').addClass('no__halfPixel')
    }

    _bindEvents() {
      this.$document.on('appScroll', (_, position) => this._draw(position))

      this.$document.on('appResize resultsTableRendered', () => {
        this._calculate()
        this._draw()
      })

      const taskResultHeader = this

      this.$document.find('.is__img').each(function() {
        $(this).imagesLoaded(() => {
          taskResultHeader._calculate()
          taskResultHeader._draw()
        })
      })

      this.$document.on('appHeightChanged', () => {
        this._calculate()
        this._draw()
      })
    }

    _calculate() {
      this.trigger = this.$container.offset().top + this.threshold
      this.isFitToViewport = this.$container.height() <= window.innerHeight - this.height

      this.$container.toggleClass(CLASS_STICKABLE, !this.isFitToViewport)
    }

    _draw(position) {
      if (this.isFitToViewport) return

      if (position === undefined) position = window.application.currentScrollPosition

      if (position > this.trigger) {
        this.$el.addClass(CLASS_STICKY)
      } else {
        this.$el.removeClass(CLASS_STICKY)
      }
    }
  }

  $('.js__taskResultHeader').each(function() {
    new TaskResultHeader($(this))
  })
}
