export default props => {
  let isNoticeVisible = !!props.noticeType
  if (props.noticeType === 'emailSent') {
    isNoticeVisible = props.screen === 'subscription' && props.hasSubscribedSelf
  }

  if (props.noticeType === 'disclaimer') {
    isNoticeVisible = !!props.disclaimer[props.type] && !props.hasSubscribedSelf
  }

  return isNoticeVisible
}
