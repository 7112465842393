import { Provider } from 'preact-redux'

import SovietCompilation from './sovietCompilation'
import SovietCompilationLayout from './sovietCompilationLayout'

import { readState, mergeState } from 'reduxStorage'
import { createStore, DEFAULT_STATE } from './sovietCompilationStore'

{
  const WrappedSovietCompilation = (props) => {
    return (
      <Provider store={ createStore(props) }>
        <SovietCompilation />
      </Provider>
    )
  }

  $('.js__compilation').each(function() {
    const scope = $(this).attr('persistence-scope') || 'soviet/selected/new'

    const initialState = mergeState(DEFAULT_STATE, window.sovietCompilation || {})
    let props = initialState

    const shouldReadDraft = !initialState.isPublished && !initialState.id
    const shouldPersist = shouldReadDraft

    if (shouldReadDraft) {
      const persistedState = readState(scope)
      props = mergeState(initialState, persistedState.sovietCompilation || {})
    }

    $(this).reactify(WrappedSovietCompilation, { ...props, ...{ scope, shouldPersist } })
  })

  $('.js__sovietCompilationRows').each(function() {
    const props = window.SOVIET_COMPILATION_ROWS
    $(this).reactify(SovietCompilationLayout, props)
  })
}
