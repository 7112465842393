import { pluralize, wrapFractionalPart } from 'lib/textHelper'
import { formatScore, formatMaxScore } from 'lib/school/classroom/scoreHelper'
import onKeydownSequence from 'lib/onKeydownSequence'

import Question from './schoolTestQuestion'
import SchoolTestRemoteState from './schoolTestRemoteState'
import SchoolTestAnswers from './schoolTestAnswers'
import SchoolTestResultMessage from './schoolTestResultMessage'
import installStubs from './schoolTestStubs'
import suppressAppealLinkRedirect from './suppressAppealLinkRedirect'


const CLASS_LOADING = 'is__loading'
const CLASS_REVEALED = 'is__revealed'
const CLASS_FAILED = 'is__failed'
const CLASS_EXPOSED = 'is__exposed'
const CLASS_QUESTIONS_HIDDEN = 'is__questionsHidden'

class SchoolTest {
  constructor($el) {
    this.$el = $el
    this.$submit = this.$el.find('.js__check')
    this.$results = this.$el.find('.js__results')
    this.$factoidResults = this.$el.find('.js__schoolTestResult-factoid')
    this.$resultsStatus = this.$el.find('.js__schoolTestResult-heading h2, .js__schoolTestResult-status')

    this.id = this.$el.data('id')
    this.revision = this.$el.data('revision')

    this.testData = installStubs((window.SCHOOL_TEST_DATA || {}))
    this.testResult = this.testData.result
    this.testAbilities = this.testData.abilities
    this.loadState()

    this.init()
    this.bindEvents()

    this.render()
  }

  get isDone() {
    return !!this.testResult ||
      !this.testAbilities.can_submit_answers ||
      this.questions.every(question => question.isDone)
  }

  get isBonus() {
    return /^bonus\//.test(this.id)
  }

  get state() {
    return this.currentState
  }

  loadState() {
    if (this.testResult) {
      const answers = (this.testResult.answers || []).map(id => [this.id, id].join('.'))
      this.currentState = new SchoolTestRemoteState({ answers })
      return
    }

    this.currentState = window.application.state
  }

  init() {
    this.questions = this.buildQuestions()
    this.questions.forEach(question => question.init())
  }

  buildQuestions() {
    return this.$el.find('.js__question')
      .map((_, el) => new Question($(el), this))
      .get()
  }

  bindEvents() {
    this.$submit.on('click', this.submit.bind(this))
    this.$el.on('answerChecked', this.toggleSubmit.bind(this))
    this.$el.on('click', '.js__schoolTest-appealLink', suppressAppealLinkRedirect)

    if (window.application.user && window.application.user.isStaff) {
      onKeydownSequence([49, 50, 51, 52], this.expose.bind(this))
    }

    setTimeout(() => this.$el.trigger('appHighlight'), 300)
  }

  render() {
    if (this.testResult) {
      this.displayResults(this.testResult)
      this.syncQuestionsState(this.testResult)
      this.reveal(this.testResult)
    }

    if (!this.testAbilities.can_submit_answers && !this.testResult) {
      this.failTest()
      return
    }

    this.toggleSubmit()
  }

  failTest() {
    this.questions.forEach(question => question.lock())
    this.displayDeadlineFail()
    this.fail()
  }

  reveal(result) {
    this.$el.addClass(CLASS_REVEALED)

    if (result.score === result.max_score) {
      $('.confetti').addClass('is__visible')
      new Confetti()
    }
  }

  fail() {
    this.$el.addClass(CLASS_FAILED)
  }

  expose() {
    this.$el.toggleClass(CLASS_EXPOSED)
  }

  setQuestionsDisplay() {
    if (this.testAbilities.can_display_incorrect_questions) return

    this.$el.toggleClass(CLASS_QUESTIONS_HIDDEN)
  }

  toggleSubmit() {
    this.$submit.attr('disabled', !this.isDone)
  }

  submit() {
    return (new SchoolTestAnswers(this))
      .submit({
        onBeforeLoad: this.onBeforeLoad.bind(this),
        onAfterLoad: this.onAfterLoad.bind(this),
      })
      .then(this.handleSubmitResult.bind(this))
      .catch(this.handleSubmitError.bind(this))
  }

  onBeforeLoad() {
    this.$submit.attr('disabled', true)
    this.$el.addClass(CLASS_LOADING)
  }

  onAfterLoad() {
    this.$submit.attr('disabled', false)
    this.$el.removeClass(CLASS_LOADING)
  }

  handleSubmitResult(result) {
    this.testResult = result
    this.loadState()
    this.render()
  }

  handleSubmitError(e) {
    console.error('Something went wrong', e)
  }

  formattedResults(results) {
    return (new SchoolTestResultMessage({ results, test: this })).html()
  }

  formatFactoidResults(results) {
    const formattedScore = wrapFractionalPart(formatScore(results.score))
      .replace(/,/, '<span class="comma">,</span>')
    const formattedCaption = I18n.t('schoolTest.results.factoid', {
      maxScore: formatMaxScore(results.max_score),
      scorePlural: pluralize(results.score, 'балл', 'балла', 'баллов'),
    })

    this.$factoidResults
      .find('.factoid-number')
      .html(formattedScore)

    this.$factoidResults
      .find('.caption-text')
      .text(formattedCaption)
  }

  displayResults(result) {
    this.setQuestionsDisplay()
    this.$results.html(this.formattedResults(result))
    this.formatFactoidResults(result)

    this.$resultsStatus
      .addClass('is__success')
      .html(I18n.t('schoolTest.results.heading'))
  }

  displayDeadlineFail() {
    this.setQuestionsDisplay()
    this.$results.remove()
    this.$resultsStatus
      .addClass('is__failed')
      .html(I18n.t('schoolTest.results.cannotSubmit.heading'))
  }

  syncQuestionsState({ incorrect_question_ids }) {
    const incorrectQuestionIds = incorrect_question_ids.map(id => [this.id, id].join('.'))

    this.questions
      .forEach(question => question.reload())

    this.questions
      .forEach(question => question.lock())

    this.questions
      .filter(question => incorrectQuestionIds.includes(question.id))
      .forEach(question => question.markAsIncorrect())
  }
}

$('.js__schoolTest:not(.is__training)').each(function() {
  new SchoolTest($(this))
})
