import { Component } from 'preact'
import { connect } from 'preact-redux'

import { detectPaybarNotificationSentEmail } from './variants'

class NewPaybarNotificationEmailSent extends Component {
  render(props) {
    const NewPaybarNotificationEmailSentProduct = detectPaybarNotificationSentEmail(props.product)

    return (
      <NewPaybarNotificationEmailSentProduct { ...props } />
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    userEmail: newPaybar.userEmail,
    noticeType: newPaybar.noticeType,
    cannotRead: newPaybar.cannotRead,
    isPreorder: newPaybar.isPreorder,
    subscription: newPaybar.subscription,
  }
}

export default connect(mapStateToProps)(NewPaybarNotificationEmailSent)
