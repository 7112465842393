import LinkWithArrow from '../linkWithArrow'

const LectureSpacerLink = ({ title, href, className }) => (
  <LinkWithArrow
    isNoVisited
    isDoubleHover
    className={ className }
    href={ href }
    text={ title }
  />
)

export default LectureSpacerLink
