{
  const ANIMATION_LENGTH = 200
  const MIN_ANGLE = 0
  const MAX_ANGLE = 35
  const ANGLE_RATIO = ANIMATION_LENGTH / MAX_ANGLE
  const CLASS_ACTIVE = 'is__active'

  const pad = (n, width, z) => {
    z = z || '0'
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
  }

  class Cover {
    constructor($wrapper) {
      this.$wrapper = $wrapper
      this.$el = this.$wrapper.find('.animatedCover')
      this.$framesBox = this.$wrapper.find('.animatedCoverSequence')
      this.$spine = this.$el.find('.animatedCover-spine')
      this.$frames = $('.animatedCover-frames')

      this.sequenceLength = this.$framesBox.data('length')
      this.sequenceBase = this.$framesBox.data('base')
      this.framesRatio = ANIMATION_LENGTH / this.sequenceLength

      this.initSequence()
      this.bindEvents()
      this.loadImages()
    }

    initSequence() {
      for (let i = 0; i <= this.sequenceLength; i++) {
        const frameFilePath = this.sequenceBase.replace('%%', pad(i, 2))

        this.$frames = this.$frames.add(
          $(`<div class="animatedCoverSequence-frame" style="background-image: url(${frameFilePath})"></div>`)
        )
      }

      this.$framesBox.append(this.$frames)
    }

    bindEvents() {
      $(document).on('appScroll', this.draw.bind(this))
    }

    loadImages() {
      this.$wrapper.find('div').each((_, el) => {
        const image = $(el).css('background-image').match(/url\((['"])?(.*?)\1\)/)

        if (image) {
          this.$el = this.$el.add($('<img>').attr('src', image.pop()))
        }
      })

      this.$el.imagesLoaded(() => this.$wrapper.addClass(CLASS_ACTIVE))

      this.draw()
    }

    seekSequence() {
      let frame = this.scrollTop / this.framesRatio | 0

      if (frame < 0) {
        frame = 0
      }

      if (frame > this.sequenceLength) {
        frame = this.sequenceLength
      }

      this.$frames
        .removeClass('is__visible')
        .eq(frame)
        .addClass('is__visible')
    }

    draw() {
      this.scrollTop = window.application.currentScrollPosition

      this.seekSequence()
    }
  }

  $('.js__animatedCover').each(function() {
    new Cover($(this))
  })
}
