import { Component } from 'preact'

import AUTH_JSON_HEADERS from 'authJsonHeaders'
import camelize from 'lib/camelize'

import { Heading2 } from '../textNodes'
import CommentsMenu from './commentsMenu'
import Comment from '../comment/comment'

const INITIAL_COMMENTS_COUNT = { approved: 0, new: 0, sunk: 0 }
const COMMENTS_LOCATIONS = {
  all: '',
  new: '#comments-new',
  sunk: '#comments-sunk',
}

export default class Comments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: [],
      count: INITIAL_COMMENTS_COUNT,
      activeTab: '',
      hasFetchedComments: false,
    }

    this.isModerator = window.application.user &&
      (window.application.user.isStaff || window.application.user.isModerator)
    this.isDefaultView = !this.isModerator

    this.updateLocation = this.updateLocation.bind(this)
    this.countComments = this.countComments.bind(this)
    this.refreshCommentsList = this.refreshCommentsList.bind(this)
    this.recountComments = this.recountComments.bind(this)
    this.setActiveTab = this.setActiveTab.bind(this)
  }

  componentDidMount() {
    this.setActiveTab()
    this.refreshCommentsList()

    $(window).on('popstate.comments', this.setActiveTab)
    if (this.isModerator) $(document).on('commentSent', this.refreshCommentsList)
  }

  componentWillUnmount() {
    $(window).off('popstate.comments')
  }

  componentDidUpdate(_, prevState) {
    if (this.state.hasFetchedComments !== prevState.hasFetchedComments) {
      this.scrollSelectedCommentIntoView()
    }
  }

  scrollSelectedCommentIntoView() {
    const hash = window.location.hash.substring(1)

    if (hash && hash.length) {
      requestAnimationFrame(() => {
        const el = document.querySelector(`a[name="${hash}"], #${hash}`)
        if (el) el.scrollIntoView()
      })
    }
  }

  countComments(comments) {
    const count = comments.reduce((hash, comment) => {
      hash[comment.status] += 1

      return hash
    }, { ...INITIAL_COMMENTS_COUNT })

    this.setState({ count })
  }

  fetchComments() {
    const subject = encodeURIComponent(this.props.commentableSubject)
    return fetch(`/commentables/${subject}/comments`, { headers: AUTH_JSON_HEADERS })
      .then(res => res.json())
  }

  refreshCommentsList() {
    this.fetchComments()
      .then(json => {
        this.setState({
          hasFetchedComments: true,
          comments: json.map(camelize),
        }, () => {
          this.countComments(this.state.comments)
          this.setNoMoreComments(this.state.comments)
          $(document).trigger('appResize')
        })
      })
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  recountComments() {
    this.fetchComments()
      .then(json => this.countComments(json))
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  setNoMoreComments(comments) {
    const approvedComments = comments.filter(({ status }) => status === 'approved')
    const featuredComments = approvedComments.filter(({ featured }) => featured)
    const noMoreComments = (approvedComments.length === featuredComments.length)

    const sameSubjectElements = document.querySelector(`[data-comments-subject='${this.props.commentableSubject}']`)
    if (sameSubjectElements) sameSubjectElements.classList.toggle('has__no-more-comments', noMoreComments)
  }

  setActiveTab() {
    if (this.isDefaultView) {
      this.setState({ activeTab: 'approved' })
      return
    }

    const locationHash = window.location.hash
    const activeTab = Object.keys(COMMENTS_LOCATIONS)
      .find(tab => COMMENTS_LOCATIONS[tab] === locationHash)

    if (activeTab) this.setState({ activeTab })
  }

  updateLocation(title, path) {
    window.history.pushState({}, title, path)
    this.setActiveTab()
    this.refreshCommentsList()
  }

  get isReverseOrder() {
    return this.props.mod?.includes('reverseOrder')
  }

  get isFeaturedFirst() {
    return this.props.mod?.includes('featuredFirst')
  }

  get isFeaturedOnly() {
    return this.props.mod?.includes('featuredOnly')
  }

  get isMenuHidden() {
    return this.isFeaturedOnly || this.props.mod?.includes('noMenu')
  }

  get isTitleHidden() {
    return this.props.mod?.includes('noTitle')
  }

  get isLimitApplied() {
    return this.props.limit
  }

  filterAndSort(comments) {
    if (this.isFeaturedOnly) comments = comments.filter(c => c.featured)
    if (this.isReverseOrder) comments.reverse()
    if (this.isFeaturedFirst) comments.sort((prev, next) => (next.featured - prev.featured))
    if (this.isLimitApplied) comments = comments.slice(0, this.props.limit)

    return comments
  }

  get visibleComments() {
    const comments = this.state.comments
      .filter((comment) => {
        switch (this.state.activeTab) {
          case 'all':
            return comment.status === 'approved' || comment.status === 'new'
          default:
            return comment.status === this.state.activeTab
        }
      })

    return this.filterAndSort(comments)
  }

  renderComments() {
    return this.visibleComments
      .map((comment) => {
        return (
          <Comment
            key={ comment.id }
            comment={ comment }
            commentableSubject={ this.props.commentableSubject }
            isDefaultView={ this.isDefaultView }
            onAfterEdit={ this.refreshCommentsList }
            onStatusUpdate={ this.recountComments } />
        )
      })
  }

  renderTitle() {
    const { title, genitiveTitle } = this.props
    if (this.isTitleHidden) return null

    const hasVisibleComments = this.visibleComments.length || this.state.count.approved > 0
    if (hasVisibleComments) return (<Heading2>{title}</Heading2>)

    return (<Heading2>{genitiveTitle} пока нет</Heading2>)
  }

  render({ title }) {
    return (
      <div className="comments">
        { this.renderTitle() }

        { !this.isDefaultView && !this.isMenuHidden &&
          <CommentsMenu
            title={ title }
            changeTabAction={ this.updateLocation }
            activeTab={ this.state.activeTab }
            count={ this.state.count }
          />
        }

        { !!this.visibleComments.length &&
          <div className="module">
            { this.renderComments() }
          </div>
        }
      </div>
    )
  }
}
