import Show from './show'
import CrossIcon from './crossIcon'
import { noop } from './helpers'

const handleKeyDown = (onBackspace, onEnter) => ({ code }) => (({
  Enter: onEnter,
  Backspace: onBackspace,
})[code] || noop)()

const triggerFocusMode = (e) => {
  e.stopPropagation()
  $(document).trigger('enableFocusMode')
}

export default ({
  value, isDeletable = true, onDelete, onClick, isNew, onPressEnter,
}) => (
  <div
    className={ `suggestInput-tag ${ isNew ? 'is__new' : '' }` }
    tabIndex="0"
    onKeyDown={
      handleKeyDown(
        () => onDelete && onDelete(false),
        () => onPressEnter && onPressEnter(value)
)
    }
    onClick={ e => (triggerFocusMode(e), onClick && onClick(value)) }
    onDblClick={ e => (triggerFocusMode(e), onPressEnter && onPressEnter(value)) }
  >
    <div className="suggestInput-tagContainer">
      <span className="suggestInput-tagValue">{ value }</span>

      <Show if={ isNew } className="suggestInput-tagNewLabel">
        новый
      </Show>

      <Show if={ isDeletable } className="suggestInput-tagDelete">
        <button
          tabIndex="-1"
          className="suggestInput-tagDeleteCross"
          onClick={ e => (triggerFocusMode(e), onDelete && onDelete()) }
        >
          <CrossIcon />
        </button>
      </Show>
    </div>
  </div>
)
