import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { Heading2, TextNode } from '../../textNodes'
import NewPaybarUpgradeScheme from './newPaybarUpgradeScheme'
import isBookshelfAll from 'lib/isBookshelfAll.js'
import NewPaybarShelfDummy from '../newPaybarShelfDummy'

class NewPaybarUpgrade extends Component {
  get ownedBooksAmount() {
    return this.props.books.length
  }

  get isUpgradeToAll() {
    return isBookshelfAll(this.props.productId)
  }

  get products() {
    const { specialOffer: { ownedBooks = [], ownedBookshelves = [] } } = this.props.prices

    const hasBooks = !!ownedBooks.length
    const hasBookshelves = !!ownedBookshelves.length

    let products = 'books'

    if (!hasBooks && hasBookshelves) products = 'bookshelves'
    if (hasBooks && hasBookshelves) products = 'all'

    return products
  }

  get productsText() {
    return I18n.t(`newPaybar.upgradeProducts.${this.products}`)
  }

  get hasLongProductsText() {
    return this.products === 'all'
  }

  get title() {
    if (this.isUpgradeToAll) return this.titleAll

    if (this.ownedBooksAmount > 3) return I18n.t('newPaybar.upgradeTitle.many')

    return I18n.t('newPaybar.upgradeTitle', { count: this.ownedBooksAmount })
  }

  get titleAll() {
    const products = this.productsText

    return I18n.t('newPaybar.upgradeTitle.all', { products })
  }

  get text() {
    if (this.isUpgradeToAll) return this.textAll

    return I18n.t('newPaybar.upgradeText', { count: this.ownedBooksAmount, shelfName: this.props.shelfName })
  }

  get textAll() {
    const products = this.productsText

    return I18n.t('newPaybar.upgradeText.all', { products })
  }


  render() {
    const { books, shelfBooks, className, isMobile, productId } = this.props

    return (
      <div className={ cx('newPaybarUpgrade', 'module', className, { is__upgradeToAll: this.isUpgradeToAll }) }>
        <div className="newPaybarUpgrade-top">
          <Heading2 className="newPaybarUpgrade-title" html={ this.title }></Heading2>

          { this.isUpgradeToAll &&
            <NewPaybarShelfDummy
              productId={ productId }
              isWide={ !this.hasLongProductsText || isMobile }/>
          }

          { !this.isUpgradeToAll &&
            <NewPaybarUpgradeScheme
              books={ books }
              shelfBooks={ shelfBooks }
              productId={ productId }
            />
          }
        </div>

        <div className="newPaybarUpgrade-bottom">
          <TextNode className="newPaybarUpgrade-text" html={ this.text }></TextNode>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    productId: newPaybar.productId,
    shelfBooks: newPaybar.shelfBooks,
    shelfName: newPaybar.shelfName,
    prices: newPaybar.prices,
  }
}

export default connect(mapStateToProps)(NewPaybarUpgrade)
