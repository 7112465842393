import { subscribeUser } from '../subscriptionForm/emailUtils'

{
  const CLASS_RESUBSCRIBED = 'has__resubscribed'
  const CLASS_OPTED_IN = 'has__optedIn'
  const Cookies = require('js-cookie')

  class EmailSubscriptionNotification {
    constructor($el) {
      this.$el = $el
      this.$email = this.$el.find('.js__unsubscribedEmail')
      this.unsubscribedEmail = Cookies.get('s_unsubscribe')
      this.resubscribedEmail = Cookies.get('s_resubscribe')
      this.optInList = Cookies.get('s_opt_in')
      this.type = this.$el.attr('data-type')
      this.eventId = this.$el.attr('data-event-id')

      if (/\?resubscribed/.test(window.location.search)) this.resubscribedEmail = this.userEmail
      if (/\?unsubscribed/.test(window.location.search)) this.unsubscribedEmail = this.userEmail
      if (/\?opt_in/.test(window.location.search)) this.optInList = 'school-welcome-series'

      if (this.unsubscribedEmail) {
        this.display()
        this.cleanUpCookies()
        this.setupResubscribe()
        this.bindEvents()
      } else if (this.resubscribedEmail) {
        this.cleanUpCookies()
        this.display()
        this.setupResubscribe()
        this.displayResubscribeMessage()
      } else if (this.optInList) {
        this.cleanUpCookies()
        this.display()
        this.displayOptInMessage()
      }
    }

    get userEmail() {
      const email = ___.getCookie('email')

      if (email) return email
      if (window.application.user) return window.application.user.email

      return null
    }

    display() {
      this.$el.addClass('is__active')
    }

    cleanUpCookies() {
      Cookies.remove('s_unsubscribe')
      Cookies.remove('s_unsubscribe', { domain: `.${window.location.hostname}` })
      Cookies.remove('s_resubscribe')
      Cookies.remove('s_resubscribe', { domain: `.${window.location.hostname}` })
      Cookies.remove('s_opt_in')
      Cookies.remove('s_opt_in', { domain: `.${window.location.hostname}` })
      Cookies.remove(`event_subscribe_${this.type}_${this.eventId}`)
      Cookies.remove(`event_subscribe_${this.type}_${this.eventId}`, { domain: `.${window.location.hostname}` })
    }

    setupResubscribe() {
      this.$email.text(this.unsubscribedEmail || this.resubscribedEmail)
    }

    bindEvents() {
      this.$el
        .find('.js__emailSubscriptionNotification-resubscribe')
        .on('click', this.resubscribe.bind(this))
    }

    resubscribe() {
      subscribeUser({
        email: this.unsubscribedEmail,
        eventType: this.type,
        eventId: this.eventId,
      })

      this.displayResubscribeMessage()
    }

    displayResubscribeMessage() {
      this.$el.addClass(CLASS_RESUBSCRIBED)
    }

    displayOptInMessage() {
      this.$el.addClass(CLASS_OPTED_IN)
    }
  }

  $('.js__emailSubscriptionNotification').each(function() {
    new EmailSubscriptionNotification($(this))
  })
}
