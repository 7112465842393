const isApplicableForFreeGift = ({ subscription: { product, id: subscriptionId } = {}, type }) => {
  const isSubscribed = !!subscriptionId
  const isBook = product === 'book'
  const isGift = type === 'gift'

  return isSubscribed && isBook && isGift
}

export default (props) => {
  return isApplicableForFreeGift(props)
}
