import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import { typograf } from 'lib/textHelper'

import { setSummary } from './sovietCompilationActions'

import RemainingCharsCounter from './remainingCharsCounter'
import SovietCompilationAuthor from './sovietCompilationAuthor'
import SovietCompilationCopy from './sovietCompilationCopy'
import SovietCompilationSocial from './sovietCompilationSocial'

class SovietCompilationSummary extends Component {
  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleInput(event) {
    this.props.setSummary(event.target.value)
  }

  handleFocus() {
    this.setState({ focus: true })
  }

  handleBlur() {
    this.setState({ focus: false })
  }

  render(props) {
    const classNames = cx('compilationSummary-textWrapper', {
      is__focused: this.state.focus && !props.isReadOnly,
      is__readOnly: props.isReadOnly,
    })

    return (
      <div className={`compilationSummaryWrapper module ${props.className || ''}`}>
        <div className="compilationSummary">
          { !props.isReadOnly &&
            <RemainingCharsCounter
              maxlength={ props.maxlength }
              text={ props.summary } />
          }

          { props.isReadOnly &&
            <div className={ classNames }>
              <div className="js__compilationSummary compilationSummary-text">
                { typograf(props.summary) }
              </div>
            </div>
          }

          { !props.isReadOnly &&
            <div className={ classNames }>
              <textarea
                className="compilationSummary-text"
                placeholder={ props.placeholder }
                value={ props.summary }
                onInput={ this.handleInput }
                onFocus={ this.handleFocus }
                onBlur={ this.handleBlur }
                readonly={ props.isReadOnly } />
            </div>
          }
        </div>

        { props.isReadOnly &&
          <SovietCompilationCopy />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    summary: sovietCompilation.summary,
    isReadOnly: sovietCompilation.isReadOnly,
  }
}

const mapDispatchToProps = {
  setSummary,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationSummary)
