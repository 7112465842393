import Input from './input'
import Tag from './tag'
import SpatialContainer from './spatialNavigation'
import { DropdownIcon } from '../../icons'

const renderTag = (onTagDelete, onTagSelect) => tag =>
  (<Tag
    value={ tag.value }
    onDelete={ onTagDelete(tag) }
    onPressEnter={ onTagSelect(tag) }
    isNew={ tag.isTagNew }
  />)

const optionalHandler = handler => handler || (() => {})

const TagsInput =
  ({
    registerRef,
    selectedTags,
    handleSuggestionDelete,
    handleInputFocus,
    handleInputChange,
    handleSuggestionSelect,
    handleKeyDown,
    currentValue,
    onCatalogueOpen,
    suggestionType,
  }) => (
    <div>
      <SpatialContainer direction="HORIZONTAL_TOP" className="suggestInput-tagsWrapper">
        { selectedTags.map(renderTag(handleSuggestionDelete, handleSuggestionSelect)) }
      </SpatialContainer>

      <SpatialContainer className="suggestInput-inputWrapper" nextDirectionButton={ suggestionType === 'PROPER' ? 'ArrowDown' : null }>
        <Input
          _ref={ registerRef }
          placeholder={ !selectedTags.length && 'Теги' }
          onFocus={ optionalHandler(handleInputFocus) }
          onInput={ optionalHandler(handleInputChange) }
          onKeyDown={ optionalHandler(handleKeyDown) }
          value={ currentValue }
        />

        <button
          tabIndex="-1"
          onClick={ optionalHandler(onCatalogueOpen) }
          className="button is__thin is__textColor is__noBorder suggestInput-catalogueButton"
        >
          <DropdownIcon />
        </button>
      </SpatialContainer>
    </div>
  )

export default TagsInput
