import { Component } from 'preact'

import Price from '../price/price'
import Pricing from '../price/pricing'

export default class SubscriptionPricing extends Component {
  shouldComponentUpdate() {
    return false
  }

  get period() {
    const { renewalPeriod, subscriptionPeriod } = this.props.prices
    const period = this.shouldDisplayOneTimePrice ? subscriptionPeriod : renewalPeriod

    return period > 12 ? 12 : period
  }

  get shouldDisplayOneTimePrice() {
    return this.props.prices.subscriptionPeriod >= 6
  }

  get defaultLabel() {
    const suffix = this.shouldDisplayPreviousOneTimePrice ?
      ` <s>${ this.previousPrice }</s>` : ''

    const label = this.props.defaultPriceLabel ||
      I18n.t(`subscription.prices.defaultLabel_${this.period}`) + suffix

    return label
  }

  get shouldDisplayPreviousOneTimePrice() {
    return this.props.prices.isApplicableForDiscount && this.previousPrice !== this.currentPrice
  }

  get previousPrice() {
    const { previousOneTime, previousDefault } = this.props.prices

    return this.shouldDisplayOneTimePrice ? previousOneTime : previousDefault
  }

  get currentPrice() {
    return this.shouldDisplayOneTimePrice ? this.props.prices.oneTime : this.props.prices.default
  }

  get subscriptionLabel() {
    const suffix = this.shouldDisplayPreviousSubscriptionPrice ?
      ` <s>${ this.props.prices.previousSubscription }</s>` : ''

    const label = this.props.subscriptionPriceLabel ||
      I18n.t(`subscription.prices.subscriptionLabel_${this.period}`) + suffix

    return label
  }

  get shouldDisplayPreviousSubscriptionPrice() {
    const { previousSubscription, subscription, isApplicableForDiscount } = this.props.prices

    return !!previousSubscription && isApplicableForDiscount &&
      previousSubscription !== subscription
  }

  get hasLeftUnits() {
    return this.props.prices.currency === 'USD'
  }

  get leftUnits() {
    return this.hasLeftUnits ? this.defaultUnits : null
  }

  get defaultUnits() {
    return I18n.t(`subscription.defaultUnits_${this.period}`)
  }

  get subscriptionUnits() {
    const fullUnits = I18n.t(`subscription.subscriptionUnits_${this.props.prices.renewalPeriod}`)
    const period = fullUnits.split('/').slice(-1)

    return this.hasLeftUnits ? `/${period}` : fullUnits
  }

  render() {
    const { prices, largePrices, smallUnits, hasCompactUnitsView, size, unitsSize } = this.props

    return (
      <Pricing currency={ prices.currency }>
        <Price
          label={ this.defaultLabel }
          amount={ this.shouldDisplayOneTimePrice ? prices.oneTime : prices.default }
          className={ prices.isApplicableForDiscount ? 'is__applicableForDiscount' : '' }
          size={ size }
          smallUnits={ smallUnits }
          unitsSize={ unitsSize }
          hasCompactUnitsView={ hasCompactUnitsView }
          large={ largePrices }
          leftUnits={ this.leftUnits }
          units={ this.hasLeftUnits ? null : this.defaultUnits }
        />

        <Price
          label={ this.subscriptionLabel }
          amount={ prices.subscription }
          className={ this.shouldDisplayPreviousSubscriptionPrice ? 'is__applicableForDiscount' : '' }
          size={ size }
          smallUnits={ smallUnits }
          unitsSize={ unitsSize }
          hasCompactUnitsView={ hasCompactUnitsView }
          large={ largePrices }
          leftUnits={ this.leftUnits }
          units={ this.subscriptionUnits }
        />
      </Pricing>
    )
  }
}
