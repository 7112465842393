import { Component } from 'preact'

export default class NewPaybarNotificationEmailSentBookshelf extends Component {
  get notificationText() {
    const suffix = this.props.cannotRead ? 'CannotRead' : ''

    return I18n.t(
      `newPaybar.notice.${this.props.product}.${this.props.noticeType}.text${suffix}`,
      { email: this.props.userEmail }
    )
  }

  get notificationButtonText() {
    return I18n.t(`newPaybar.notice.${this.props.product}.${this.props.noticeType}.buttonText`)
  }

  render() {
    return (
      <div className="newPaybarNotificationEmailSent">
        <span
          className="newPaybarNotice-message"
          dangerouslySetInnerHTML={{ __html: this.notificationText }}>
        </span>
      </div>
    )
  }
}
