export function setActiveStudent(studentUid) {
  return { type: 'SET_ACTIVE_STUDENT', studentUid }
}

export function unsetActiveStudent() {
  return { type: 'UNSET_ACTIVE_STUDENT' }
}

export function pinStudent(studentUid) {
  return { type: 'PIN_STUDENT', studentUid }
}

export function unpinStudent() {
  return { type: 'UNPIN_STUDENT' }
}

export function setChartParams(chartParams) {
  return { type: 'SET_CHART_PARAMS', chartParams }
}
