import { Component } from 'preact'
import { connect } from 'preact-redux'

import { getSchoolKeyFromSubscription, getSchoolNameFromSubscription } from './schoolHelpers'
import getCurrentEnrollment from '../enrollmentHelper'

import NewPaybarLaunchpadItemSchool from './newPaybarLaunchpadItemSchool'
import { Heading2 } from '../../textNodes'

class NewPaybarLaunchpadSchool extends Component {
  get isPrep() {
    return this.props.product === 'prep'
  }

  get title() {
    if (!this.props.isUserAuthenticated) return this.unauthenticatedTitle

    return I18n.t('newPaybar.launchpad.school')
  }

  get unauthenticatedTitle() {
    const schoolName = getSchoolNameFromSubscription(this.props.subscription)

    return I18n.t(`newPaybar.launchpad.${this.props.product}NoAccess`, { schoolName })
  }

  get enrollment() {
    const { prepEnrollment, schoolEnrollment } = this.props.subscription

    if (!prepEnrollment && !schoolEnrollment) return {}

    return this.isPrep ? prepEnrollment : schoolEnrollment
  }

  get schoolKey() {
    return this.isPrep ? 'prep' : getSchoolKeyFromSubscription(this.props.subscription)
  }

  render() {
    const { subscription } = this.props
    const enrollment = getCurrentEnrollment(this.props.subscription)
    const isStudying = enrollment.status === 'active'
    const isFinished = enrollment.status === 'finished'
    const nextDeadline = isFinished ? null : enrollment.next_deadline_at
    const isTermsRequired = subscription.subscription_type === 'gift' || subscription.plan === 'loan'

    return (
      <section className="newPaybarLaunchpad-section">
        <Heading2 className="newPaybarLaunchpad-title">{ this.title }</Heading2>

        <div className="newPaybarLaunchpad-items">
          <NewPaybarLaunchpadItemSchool
            product={ this.props.product }
            schoolKey={ this.schoolKey }
            isStudying={ isStudying || isFinished }
            nextDeadline={ nextDeadline }
            isUserAuthenticated={ this.props.isUserAuthenticated }
            canAccess
            isTermsRequired={ isTermsRequired } />
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    subscription: newPaybar.subscription,
    subscriptionEnrollment: newPaybar.subscriptionEnrollment,
    product: newPaybar.product,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
  }
}

export default connect(mapStateToProps)(NewPaybarLaunchpadSchool)
