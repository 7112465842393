import { Component } from 'preact'
import { Heading3 } from '../textNodes'

import SchoolFacesStudent from './schoolFacesStudent'
import { getStudentsForFaces, getGrantStudentsForFaces } from '../schoolRating/helpers'

export default class SchoolFaces extends Component {
  get students() {
    return getStudentsForFaces(this.props.students)
  }

  get grantStudents() {
    return getGrantStudentsForFaces(this.students)
  }

  render() {
    return (
      <div className="schoolFaces">
        { this.grantStudents.length > 0 &&
          <section className="schoolFaces-section is__large">
            <Heading3><span className="is__highlighted">{ I18n.t('schoolFaces.titleGranted') }</span></Heading3>

            <div className="schoolFaces-cols">
              { this.grantStudents.map(student => <SchoolFacesStudent { ...student }/>) }
            </div>
          </section>
        }

        <section className="schoolFaces-section">
          <Heading3>{ I18n.t('schoolFaces.titleAll', { count: this.students.length }) }</Heading3>

          <div className="schoolFaces-cols">
            { this.students.map(student => (
              <SchoolFacesStudent { ...student } canDisplayGrantedTag/>
            ))}
          </div>
        </section>
      </div>
    )
  }
}
