import { Cols } from '../cols'

import LectureSpacerTitle from './lectureSpacerTitle'
import LectureSpacerCover from './lectureSpacerCover'
import LectureSpacerPricing from './lectureSpacerPricing'

const LectureSpacerFull = ({ href, lectureKey, prices, content }) => {
  return (
    <Cols className="lectureSpacer" divisionProportions=":11:1" transposeOnMobile>
      <LectureSpacerCover
        size="full"
        className="lectureSpacer-cover"
        lectureKey={ lectureKey }
        href={ href } />

      <LectureSpacerTitle
        href={ href }
        className="lectureSpacer-title"
        prices={ prices }
        content={ content }
      />

      <LectureSpacerPricing
        className="lectureSpacer-pricing"
        prices={ prices }
        href={ href } />
    </Cols>
  )
}

export default LectureSpacerFull
