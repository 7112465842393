export default (prices = {}) => {
  const hasCouponDiscount = prices.isApplicableForDiscount && prices.isCouponApplied

  return {
    ...prices,
    default: hasCouponDiscount ? prices.default : prices.previousDefault,
    oneTime: hasCouponDiscount ? prices.oneTime : prices.previousOneTime,
    isApplicableForDiscount: hasCouponDiscount,
    isApplicableForReaderDiscount: false,
    specialOffer: null,
  }
}
