import { Component } from 'preact'
import { connect } from 'preact-redux'

import { FormInput } from '../form'

import { setQuestionerName } from './sovietActions'

class SovietQuestionerNameInput extends Component {
  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
  }

  handleInput({ value }) {
    this.props.setQuestionerName(value)
  }

  render(props) {
    if (props.hideQuestioner) return null

    return (
      <div className="sovietQuestionerNameInput-outerWrapper">
        <div className="sovietQuestionerNameInput-wrapper module">
          <FormInput
            className="sovietQuestionerNameInput-text"
            value={ props.questionerName }
            required={ true }
            onInput={ this.handleInput } />
        </div>
        <div className="module">
          <div className="sovietQuestionerNameInput-email">
            { props.questionerEmail }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    hideQuestioner: soviet.isLecture,
    questionerName: soviet.questionerName,
    questionerEmail: soviet.questionerEmail,
  }
}

const mapDispatchToProps = {
  setQuestionerName,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietQuestionerNameInput)
