const BaseModule = require('_base/base')
const SuperLectureEditLinks = require('./superLectureEditLinks')

const SCROLL_DURATION = 300
const TOP_H1_TARGET_PADDING = 18.5
const PREVIEW_SAFE_ZONE = 20
const CAPTION_SCROLL_THRESHOLD = 2

class SuperLecture extends BaseModule {
  get currentScrollPosition() {
    return window.application.currentScrollPosition
  }

  preInit() {
    this.isFinal = true

    this.$document = $(document)
    this.el = this.$el[0]
    this.editLinks = new SuperLectureEditLinks(this.el)

    this.previewsHolderWrap = this.el.querySelector('.superLecturePreviewsHolderWrap')
    this.previewsHolder = this.el.querySelector('.superLecturePreviewsHolder')
    this.previewsHeader = this.el.querySelector('.superLecturePreviewsHeader')
    this.previewsList = this.el.querySelector('.superLecturePreviews')
    this.previewCaptions = this.el.querySelectorAll('.superLecturePreviews-item .caption-text')
    this.hasNavigator = !!this.previewsHolderWrap

    this.onScroll = this.onScroll.bind(this)
    this.lockUpdates = this.lockUpdates.bind(this)
    this.releaseUpdates = this.releaseUpdates.bind(this)

    if (this.hasNavigator) this.bindEvents()
  }

  preCalculate() {
    if (!this.hasNavigator) return

    this.previewsHolder.classList.remove('is__ready')
    const currentHolderHeight = this.previewsHolder.offsetHeight

    this.previewsHolderWrap.style.height = currentHolderHeight + 'px'
    this.previewsHolder.style.setProperty('--height', currentHolderHeight + 'px')

    this.previewsHolder.classList.add('is__ready')

    this.lastY = window.pageYOffset
    this.previewsTop = this.lastY + this.previewsHolderWrap.getBoundingClientRect().top
    this.previewsHeight = currentHolderHeight

    this.buildTriggers()
  }

  preRender() {
    this.editLinks.render()

    if (!this.hasNavigator) return

    this.$document
      .off('appScroll.superLecture')
      .on('appScroll.superLecture', this.onScroll)

    this.setActiveLectureFromCurrentPosition()
    this.ensurePreviewsAreVisible()
    this.markOverflowingCaptions()
  }

  bindEvents() {
    this.$el.find('.superLecturePreviews').on('click', 'a', (e) => {
      e.preventDefault()

      const lectureId = e.currentTarget.href.split('#').pop()
      this.scrollToLecture(lectureId)
    })
  }

  buildTriggers() {
    this.triggers = Array.from(this.previewsHeader.querySelectorAll('a[href^="#"]'))
      .map(a => {
        const id = a.href.split('#').pop()
        const top = window.pageYOffset + document.getElementById(id).getBoundingClientRect().top
        const topShift = window.application.viewHeight / 2

        return ({
          id,
          top: top - topShift,
        })
      })
  }

  scrollToLecture(lectureId) {
    const { currentScrollPosition, previewsTop, previewsHeight, currentShaftHeight } = this

    const el = document.getElementById(lectureId).parentNode
    const elTop = el.getBoundingClientRect().top
    const scrollTop = elTop + currentScrollPosition - previewsHeight - TOP_H1_TARGET_PADDING
    const requiredShaftHeight = scrollTop - previewsTop + previewsHeight
    const duration = SCROLL_DURATION

    const start = () => {
      if (requiredShaftHeight > currentShaftHeight) this.setShaftHeightTo(requiredShaftHeight)
      this.lockUpdates()
      this.setActiveLecture(lectureId)
    }

    const complete = () => {
      if (requiredShaftHeight < currentShaftHeight) this.setShaftHeightTo(requiredShaftHeight)
      this.releaseUpdates()
    }

    $('html, body').animate({ scrollTop }, { duration, start, complete })
  }

  lockUpdates() {
    this.isLocked = true
  }

  releaseUpdates() {
    this.isLocked = false
  }

  setActiveLecture(lectureId) {
    this.previewsHeader
      .querySelectorAll('.is__active')
      .forEach(el => el.classList.remove('is__active'))

    const activePreview = this.previewsHeader
      .querySelector(`a[href="#${lectureId}"]`)
      .closest('.superLecturePreviews-item')

    activePreview.classList.add('is__active')

    this.ensureActivePreviewIsVisible(activePreview)
  }

  ensureActivePreviewIsVisible(el) {
    const clientRect = el.getBoundingClientRect()
    const { viewWidth } = window.application

    const left = clientRect.left - PREVIEW_SAFE_ZONE
    const right = viewWidth - clientRect.left - clientRect.width - PREVIEW_SAFE_ZONE

    if (left < 0 || right < 0) {
      const { scrollLeft } = this.previewsList
      const delta = left < 0 ? left : -right

      this.previewsList.scrollLeft = scrollLeft + delta
    }
  }

  onScroll() {
    this.setActiveLectureFromCurrentPosition()
    this.updateShaftHeight()
  }

  setActiveLectureFromCurrentPosition() {
    if (this.isLocked) return

    const { currentScrollPosition } = this
    const currentTrigger = this.triggers
      .filter(trigger => currentScrollPosition > trigger.top)
      .pop() || this.triggers[0]

    this.setActiveLecture(currentTrigger.id)
  }

  updateShaftHeight() {
    if (this.isLocked) return

    const { currentScrollPosition, currentShaftHeight, previewsHeight, previewsTop } = this

    let newShaftHeight = currentScrollPosition - previewsTop
    const maxShaftScrollPosition = currentShaftHeight + previewsTop
    const shaftBottomDistance = maxShaftScrollPosition - currentScrollPosition - previewsHeight
    const isPreviewsVisible = currentScrollPosition < maxShaftScrollPosition
    const isPreviewsStuck = isPreviewsVisible &&
      currentScrollPosition > previewsTop &&
      Math.abs(currentShaftHeight - previewsHeight) >= 1

    if (currentScrollPosition >= this.lastY) {
      if (isPreviewsVisible && shaftBottomDistance > 0) {
        const delta = this.previewsHeader.getBoundingClientRect().bottom
        this.setShaftHeightTo(newShaftHeight + delta)
      } else if (!isPreviewsVisible) {
        this.setShaftHeightTo(newShaftHeight)
      }
    }

    this.previewsHeader.classList.toggle('is__stuck', isPreviewsStuck)

    this.lastY = currentScrollPosition
  }

  setShaftHeightTo(px) {
    if (this.currentShaftHeight !== px) {
      this.previewsHolder.style.setProperty('--height', px + 'px')
      this.currentShaftHeight = px
    }
  }

  ensurePreviewsAreVisible() {
    this.setShaftHeightTo(
      this.currentScrollPosition - this.previewsTop + this.previewsHeight
    )

    this.updateShaftHeight()
  }

  markOverflowingCaptions() {
    this.previewCaptions.forEach(captionText => {
      const caption = captionText.parentNode
      caption.classList.remove('is__overflowing')

      if (captionText.scrollHeight - captionText.offsetHeight > CAPTION_SCROLL_THRESHOLD) {
        caption.classList.add('is__overflowing')
      }
    })
  }
}

$('.js__superLecture').each((_, el) => new SuperLecture($(el)))
