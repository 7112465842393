import { Component } from 'preact'
import { Caption } from '../textNodes'

export default class SovietAskFormCaption extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <Caption className="sovietAskFormCaption">
        { I18n.t('soviet.askForm.caption') }
      </Caption>
    )
  }
}
