import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { Heading2, TextNode, Caption } from '../../textNodes'
import LectureOnDevices from '../../lectureOnDevices/lectureOnDevices'
import NewPaybarLectureRotatedCovers from './newPaybarLectureRotatedCovers'

class NewPaybarOverlayLecture extends Component {
  get noAccess() {
    return this.props.isPreorder || !this.props.isUserAuthenticated || this.props.type === 'gift'
  }

  get heading() {
    if (this.isUpgrade) return I18n.t('newPaybar.successScreen.collecture.upgrade.heading')

    return I18n.t(`newPaybar.successScreen.lecture.${this.props.type}.heading`)
  }

  get isUpgrade() {
    return !!this.props.prices.previousSpecialOffer
  }

  get text() {
    let result = I18n.t(`newPaybar.successScreen.lecture.${this.props.type}.text`)

    if (this.isUpgrade) result += '. <br class="device mobile">' + this.previousCanceledText

    return result
  }

  get upgradedFromCollecturesOnlyCaption() {
    const { ownedCollectures = [] } = this.props.prices.previousSpecialOffer
    const suffix = ownedCollectures.length > 1 ? 'many' : 'one'

    return I18n.t(`newPaybar.successScreen.collecture.productTypes.collectures.${suffix}`)
  }

  get upgradedFromLecturesOnlyCaption() {
    const { ownedLectures = [] } = this.props.prices.previousSpecialOffer
    const suffix = ownedLectures.length > 1 ? 'many' : 'one'

    const lectureData = {
      title: ownedLectures[0].title,
      author: ownedLectures[0].authorInstrumentalCase,
    }

    return I18n.t(`newPaybar.successScreen.collecture.productTypes.lectures.${suffix}`, lectureData)
  }

  get previousCanceledText() {
    const { ownedLectures = [], ownedCollectures = [] } = this.props.prices.previousSpecialOffer

    let productTypes = I18n.t('newPaybar.successScreen.collecture.productTypes.all')
    const hasLectures = ownedLectures.length
    const hasCollectures = ownedCollectures.length

    if (hasLectures && !hasCollectures) productTypes = this.upgradedFromLecturesOnlyCaption
    if (!hasLectures && hasCollectures) productTypes = this.upgradedFromCollecturesOnlyCaption

    return I18n.t('newPaybar.successScreen.collecture.previousCanceledCaption', { productTypes })
  }

  get accessText() {
    if (this.props.isUserAuthenticated && this.props.type === 'self') return ''

    const email = this.props.type === 'gift' ? this.props.presenteeEmail : this.props.email

    return I18n.t(`newPaybar.successScreen.lecture.${this.props.type}.accessText`, { email })
  }

  get lectureKey() {
    return this.props.productId
      .split('-')
      .slice(1)
      .join('-')
  }

  get isCollecture() {
    return this.props.product === 'collecture'
  }

  render() {
    const isGift = this.props.type === 'gift'
    const isSelfUnauthenticated = this.props.type === 'self' && !this.props.isUserAuthenticated
    const classNameOverlayLock = cx('newPaybarOverlay-lock', { is__disabled: !isSelfUnauthenticated })

    const accessHref = this.noAccess ? null : `/lectures/${this.lectureKey}/watch/`
    const cover = this.props.lectures[0].cover

    return (
      <div className="newPaybarOverlay-content">
        <div className="newPaybarOverlay-message">
          <Heading2 html={ this.heading } />
          <TextNode html={ this.text } />
        </div>

        { !this.isCollecture &&
          <LectureOnDevices cover={ cover } href={ accessHref } />
        }

        { this.isCollecture &&
          <div className="newPaybarLectureRotatedCovers-wrapper">
            <NewPaybarLectureRotatedCovers lectures={ this.props.lectures } />
          </div>
        }

        { this.noAccess &&
          <div className="newPaybarOverlay-access">
            { !isGift &&
              <span className={ classNameOverlayLock }>
                { I18n.t('newPaybar.successScreen.lock') }
              </span>
            }
            <TextNode html={ this.accessText } />
            <Caption html={ I18n.t(`newPaybar.successScreen.lecture.${this.props.type}.supportCaption`) } />
          </div>
        }

        { this.noAccess &&
          <div className="newPaybarOverlay-stub" />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    productId: newPaybar.productId,
    subscription: newPaybar.subscription,
    newSubscriptionValidUntil: newPaybar.newSubscriptionValidUntil,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    isPreorder: newPaybar.isPreorder,
    lectures: newPaybar.lectures,
    prices: newPaybar.prices,
  }
}

export default connect(mapStateToProps)(NewPaybarOverlayLecture)
