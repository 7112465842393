import CollecturePricing from './collecturePricing'

const productSubscription = window.productSubscription || {}

if (productSubscription) {
  $('.js__collecturePricing').each(function() {
    const productId = productSubscription.subscription.product_id
    const prices = productSubscription.prices || {}

    const options = $(this).data('options') || $(this).data() || {}
    const isCompact = options.view === 'compact' || view === 'compact'
    const hasCaption = options.hasCaption || false

    const props = {
      productId,
      prices,
      isCompact,
      hasCaption,
    }

    $(this).reactify(CollecturePricing, props)
    $(this).children().unwrap()
  })
}
