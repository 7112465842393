import { Component } from 'preact'

import CommentReadable from './commentReadable'
import CommentEditable from './commentEditable'

export default class Comment extends Component {
  constructor(props) {
    super(props)

    this.state = { isEditable: false }

    this.handleEditableToggle = this.handleEditableToggle.bind(this)
  }

  handleEditableToggle(isEditable) {
    this.setState({ isEditable: isEditable })
  }

  render(props) {
    if (this.state.isEditable && !props.isDefaultView) {
      return (
        <CommentEditable
          comment={ props.comment }
          commentableSubject={ props.commentableSubject }
          toggleEditable={ this.handleEditableToggle }
          onAfterEdit={ props.onAfterEdit } />
      )
    }

    return (
      <CommentReadable
        comment={ props.comment }
        commentableSubject={ props.commentableSubject }
        toggleEditable={ this.handleEditableToggle }
        isDefaultView={ props.isDefaultView }
        onStatusUpdate={ props.onStatusUpdate }
        onFeaturedToggle={ props.onAfterEdit } />
    )
  }
}
