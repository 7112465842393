import { Component } from 'preact'
import { connect } from 'preact-redux'

import { setHeaderImage, setHeaderImageUploadError } from './sovietActions'
import UploadBar from '../upload/bar'
import AUTH_HEADERS from 'authHeaders'

const isValidUpload = files => files.length && /^image/.test(files[0].type)

class SovietHeaderImageUploader extends Component {
  constructor(props) {
    super(props)

    this.displayHeaderImage = this.displayHeaderImage.bind(this)
    this.handleUploadError = this.handleUploadError.bind(this)
    this.dropCounter = 0
  }

  componentDidMount() {
    const $document = $(document)

    $document.on('headerImageUpload', (_, data) => this.handleDrop(data.files))
  }

  componentWillUnmount() {
    $(document).off('headerImageUpload')
  }

  render() {
    return (
      <div className="sovietHeaderImageUploader">
        <UploadBar file={ this.state.uploadingFileName }
            loaded={ this.state.uploadingFileLoaded }
            total={ this.state.uploadingFileTotal }
            isActive={ this.state.isUploading }
          />
      </div>
    )
  }

  handleDrop(files) {
    if (!isValidUpload(files)) return

    this.upload(files)
  }

  upload(files) {
    this.uploadHeaderImage(files[0])
      .then(this.displayHeaderImage)
      .catch(this.handleUploadError)
      .then(() => this.setState({ isUploading: false }))
  }

  uploadHeaderImage(rawfile) {
    this.setState({
      isUploading: true,
      uploadingFileName: rawfile.name,
      uploadingFileTotal: rawfile.size,
    })

    const formData = new FormData()
    formData.append('image', rawfile)

    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/soviet/advices/${this.props.id}/header_image`,
        data: formData,
        contentType: false,
        processData: false,
        headers: AUTH_HEADERS,
        type: 'PUT',
        xhr: this.handleProgressUpdate.bind(this),
        success: res => resolve(res),
        error: xhr => reject(xhr),
      })
    })
  }

  handleProgressUpdate() {
    const xhr = new window.XMLHttpRequest()

    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) this.setState({ uploadingFileLoaded: e.loaded })
    }, false)

    return xhr
  }

  displayHeaderImage(data) {
    this.props.setHeaderImage(data.url, data.size)
  }

  handleUploadError() {
    this.props.setHeaderImageUploadError()
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    id: soviet.id,
  }
}

const mapDispatchToProps = {
  setHeaderImage,
  setHeaderImageUploadError,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietHeaderImageUploader)
