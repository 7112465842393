import { Component } from 'preact'
import cx from 'classnames'

const ESC_KEY = 27

import { DropdownIcon } from '../icons'

export default class ExtendableDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = { isOpen: false || props.isOpen }

    this.toggle = this.toggle.bind(this)
    this.hide = this.hide.bind(this)
    this.hideOnDocumentClick = this.hideOnDocumentClick.bind(this)
    this.onEsc = this.onEsc.bind(this)

    this.bindCurrentElRef = this.bindCurrentElRef.bind(this)
  }

  componentDidMount() {
    if (!this.props.isComboBox) {
      document.addEventListener('keydown', this.onEsc)
      document.addEventListener('click', this.hideOnDocumentClick)
    }

    $(document).on('enableFocusMode', this.hide)
  }

  componentWillUnmount() {
    if (!this.props.isComboBox) {
      document.removeEventListener('keydown', this.onEsc)
      document.removeEventListener('click', this.hideOnDocumentClick)
    }

    $(document).off('enableFocusMode', this.hide)
  }

  componentWillReceiveProps({ isOpen }) {
    if (isOpen === null || isOpen === undefined) return

    this.setState({ isOpen })
  }

  onEsc(e) {
    if (e.keyCode === ESC_KEY) this.hide()
  }

  toggle(e) {
    e.preventDefault()

    this.setState({ isOpen: !this.state.isOpen })
  }

  hide() {
    this.setState({ isOpen: false })
  }

  hideOnDocumentClick(e) {
    if (!this.currentEl.contains(e.target)) this.hide()
  }

  bindCurrentElRef(el) {
    this.currentEl = el
  }

  render() {
    const className = cx(
      'dropdown-wrapper',
      this.wrapperClassName,
      this.props.wrapperClassName,
      { is__open: this.state.isOpen || this.props.isOpen }
    )

    return (
      <div className={ className }>
        <div className="dropdown-current"
          ref={ this.bindCurrentElRef }
          onClick={ this.toggle }>
            { this.currentItem || this.props.currentItem }
          <DropdownIcon />
        </div>

        <div className="dropdown">
          { this.dropdownItems || this.props.children }
        </div>
      </div>
    )
  }
}
