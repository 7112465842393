import { Component } from 'preact'
import cx from 'classnames'

const SCROLL_OFFSET_THRESHOLD = 5

class ScrollingSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hitLeftBoundary: true,
      hitRightBoundary: false,
    }

    this.scrollLeft = this.scrollLeft.bind(this)
    this.scrollRight = this.scrollRight.bind(this)
    this.checkScrollBoundaries = this.checkScrollBoundaries.bind(this)

    $(document).on('appResize', this.checkScrollBoundaries)
  }

  componentDidMount() {
    this.checkScrollBoundaries()
  }

  scrollLeft() {
    this.checkScrollBoundaries()
    this.scrollShaftBy(-this.scrollStep)
  }

  scrollRight() {
    this.checkScrollBoundaries()
    this.scrollShaftBy(this.scrollStep)
  }

  checkScrollBoundaries() {
    const shaft = this.shaftEl
    const hitLeftBoundary = !shaft || shaft.scrollLeft === 0
    const hitRightBoundary = shaft.scrollWidth - (shaft.scrollLeft + shaft.offsetWidth) < SCROLL_OFFSET_THRESHOLD

    return this.setState({ hitLeftBoundary, hitRightBoundary })
  }

  scrollShaftBy(step) {
    $(this.shaftEl).animate({ scrollLeft: this.shaftEl.scrollLeft + step })
  }

  get scrollStep() {
    return 300
  }

  render(props) {
    const pickerClasses = cx(
      'scrollingSelector-overflow',
      'module',
      {
        has__hitLeftBoundary: this.state.hitLeftBoundary,
        has__hitRightBoundary: this.state.hitRightBoundary,
       }
    )

    return (
      <div className={`scrollingSelector-wrapper module ${props.className || ''}`}>
        <div className={ pickerClasses }>
          <span className="scrollingSelector-prevButton" onClick={ this.scrollLeft }>←</span>
          <span className="scrollingSelector-nextButton" onClick={ this.scrollRight }>→</span>
          <div className="scrollingSelector-shaft"
            ref={ el => this.shaftEl = el }
            onScroll={ this.checkScrollBoundaries }>
            <div className="scrollingSelector">
              { props.children }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScrollingSelector
