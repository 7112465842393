import InputBase from './inputBase'
import { TextNode } from '../textNodes'

export default class FormCheckbox extends InputBase {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    this.validate()
  }

  onChange(e) {
    this.setState({
      value: e.target.checked,
      isValid: true,
      isDirty: true,
    }, this.validateAndRunCallbacks)
  }

  render() {
    return (
      <label className={ this.getClassNames('checkbox') }>
        <input
          type="checkbox"
          ref={ this.bindRef }
          name={ this.props.name }
          required={ this.props.required }
          checked={ this.state.value }
          onChange={ this.onChange } />

        <TextNode className="checkbox-caption" html={ this.props.text }/>
      </label>
    )
  }
}
