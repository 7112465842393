const truncateLink = require('../truncateLink')
const hljs = require('./hljsConfig')


const md = require('markdown-it')('commonmark', {
  html: true,
  xhtmlOut: false,
  linkify: true,
  breaks: true,
  highlight: function(code, lang) {
    const highlightedCode = lang ? hljs.highlightAuto(code, [lang]).value : hljs.highlightAuto(code).value
    return `<pre class="module media"><code class="hljs ${lang || ''}">${highlightedCode.trim()}</code></pre>`
  },
})

md
  .enable('linkify')
  .disable('blockquote')
  .disable('table')


const defaultRender = md.renderer.rules.link_open || function(tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options)
}

md.renderer.rules.link_open = function(tokens, idx, options, env, self) {
  const isShortUrl = (url) => url.includes('youtu.be')
  const link = tokens[idx]
  const linkHref = (link.attrs.find(attr => attr[0] === 'href') || [])[1]
  const linkText = (tokens[idx + 1] || {}).content

  if (linkHref && linkHref === linkText && !isShortUrl(linkHref)) {
    tokens[idx + 1].content = truncateLink(linkText)
  }

  return defaultRender(tokens, idx, options, env, self)
}

module.exports = md
