import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarRequirements from './newPaybarRequirements'
import NewPaybarMobileVersionCaption from './newPaybarMobileVersionCaption'

class NewPaybarRequirementsAndMobileVersion extends Component {
  render() {
    const hasMultipleBooks = this.props.books.length > 1

    const booksWithMobileVersion = this.props.books.filter(book => book.hasMobileVersion)
    const hasBooksWithMobileVersion = booksWithMobileVersion.length > 0
    const isAllBooksHasMobileVersion = booksWithMobileVersion.length === this.props.books.length

    return (
      <div className={ this.props.className }>
        <NewPaybarRequirements
          product={ this.props.product }
          hasMobileVersion={ this.props.hasMobileVersion || isAllBooksHasMobileVersion } />

        { hasMultipleBooks && hasBooksWithMobileVersion && !isAllBooksHasMobileVersion &&
          <NewPaybarMobileVersionCaption books={ booksWithMobileVersion } />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    books: newPaybar.shelfBooks,
    hasMobileVersion: newPaybar.hasMobileVersion,
  }
}

export default connect(mapStateToProps)(NewPaybarRequirementsAndMobileVersion)
