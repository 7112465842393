export function hasCompanyPurchaseSupport(product) {
  const products = ['course']

  return products.includes(product)
}

export function hasCompanySubscription(subscription) {
  return subscription.subscription_type === 'employee'
}

export function hasCompanySubscriptionFromOther(subscription) {
  if (!hasCompanySubscription(subscription)) return false

  return subscription.user_id !== subscription.owner_id
}

export function hasSelfSubscription(subscription) {
  return subscription.subscription_type === 'self'
}
