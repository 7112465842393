const serialize = JSON.stringify
const deserialize = JSON.parse

export function mergeState(initialState, persistedState) {
  return persistedState
    ? { ...initialState, ...persistedState }
    : initialState
}

export function readState(scope) {
  try {
    return deserialize(localStorage.getItem(scope)) || {}
  } catch (e) {
    console.warn('Failed to retrieve initial state: ', e)
  }

  return {}
}

export function persistRawState(scope, state) {
  try {
    localStorage.setItem(scope, serialize(state))
  } catch (e) {
    console.warn('Failed to persist state: ', e)
  }
}

export function persistState(store, scope, slicer) {
  return function() {
    const slicerFn = slicer || ((state) => state)
    const state = slicerFn(store.getState())

    persistRawState(scope, state)
  }
}
