import { Component } from 'preact'

import { birmanize } from 'lib/textHelper'

import { Caption } from '../textNodes'
import Impressions from '../impressions/impressions'

export default class CommentFooter extends Component {
  constructor(props) {
    super(props)

    this.approve = this.approve.bind(this)
    this.sink = this.sink.bind(this)
    this.unsink = this.unsink.bind(this)
  }

  approve() {
    this.props.updateStatus('approved', 'justApproved')
  }

  sink() {
    this.props.updateStatus('sunk')
  }

  unsink() {
    this.props.updateStatus('new')
  }

  render(props) {
    const { comment, status, featured, isDefaultView } = props
    const isJustApproved = status === 'justApproved'

    if (isJustApproved) {
      return (
        <footer className="comment-footer">
          <Caption>Опубликован</Caption>
        </footer>
      )
    }

    const isApproved = status === 'approved'
    const isSunk = status === 'sunk'
    const commentImpressions = comment.impressionSummary || {}

    return (
      <footer className="comment-footer">
        { isApproved &&
          <Impressions
            impressionSubject={ comment.impressionSubject }
            selected={ commentImpressions.selected }
            helpful={ commentImpressions.helpful }
            useless={ commentImpressions.useless }
            mod="small"
          />
        }
        { !isApproved &&
          <button
            className="comment-approveButton button is__thin is__small"
            onClick={ this.approve }
          >
            Разрешить
          </button>
        }
        <Caption className="comment-date">
          { birmanize(comment.createdAt, { format: 'dateYearOnly' }) }
        </Caption>

        { !isDefaultView &&
          <div className="comment-actions">
            <button className="comment-actionButton" onClick={ this.props.edit }>✎</button>
            <button className="comment-actionButton" onClick={ this.props.toggleFeatured }>{ featured ? '★' : '☆'}</button>
            { isSunk ?
                <button className="comment-actionButton" onClick={ this.unsink }>↺</button> :
                <button className="comment-actionButton" onClick={ this.sink }>🗑</button>
            }
          </div>
        }
      </footer>
    )
  }
}
