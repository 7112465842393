const { parseDate } = require('./dateParser')
const AdvancedDate = require('./utils/advancedDate')
const birmanize = require('./utils/dates/birmanize')

const { FULL_MONTHS, WEEK_DAYS } = require('./utils/dates/constants')

const formatDate = (dateString, options = {}) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const yearPart = year === (new Date()).getFullYear() ? '' : ` ${year}`
  const fullMonth = FULL_MONTHS[date.getMonth()]
  const day = date.getDate()
  const weekDay = WEEK_DAYS[date.getDay()]

  const hours = date.getHours() === 0 ? '00' : date.getHours()
  const minutes = ('0' + date.getMinutes()).slice(-2)

  switch (options.format) {
    case 'weekDayAndTime':
      return `${weekDay} ${day} ${fullMonth}${yearPart}, ${hours}:${minutes} мск`
    case 'weekDayAndDate':
      return `${weekDay} ${day} ${fullMonth}${yearPart}`
    default:
      return `${day} ${fullMonth}${yearPart}`
  }
}

const parseTillDateToUnixTime = (till, options = {}) => {
  try {
    const date = parseDate(till, options).value

    return date.getTime() / 1000 + 86399
  } catch (e) {
    return NaN
  }
}

const parseDateToAdvanced = str => new AdvancedDate(parseDate(str).value)

const shortenMonths = date => date
  .replace('января', 'янв')
  .replace('февраля', 'фев')
  .replace('марта', 'мар')
  .replace('апреля', 'апр')
  .replace('августа', 'авг')
  .replace('сентября', 'сен')
  .replace('октября', 'окт')
  .replace('ноября', 'ноя')
  .replace('декабря', 'дек')

module.exports = {
  birmanize,
  format: formatDate,
  parseTillDateToUnixTime,
  parseDate,
  shortenMonths,
  parseDateToAdvanced,
  AdvancedDate,
}
