import { Component } from 'preact'
import { connect } from 'preact-redux'

import TextareaAutosize from 'react-textarea-autosize'

import { setKicker } from './sovietActions'

class SovietKickerInput extends Component {
  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
  }

  handleInput(e) {
    this.props.setKicker(e.target.value)
  }

  render() {
    return (
      <div className="sovietKickerInput">
        <label htmlFor="sovietKickerInput">Подводка для соцсетей</label>
        <TextareaAutosize
          className="input is__fullWidth sovietKickerInput-text"
          id="sovietKickerInput"
          placeholder="Подводка для соцсетей"
          value={ this.props.kicker }
          onInput={ this.handleInput } />
      </div>
    )
  }
}

const mapStateToProps = ({ soviet: { kicker } }) => ({ kicker })
const mapDispatchToProps = { setKicker }

export default connect(mapStateToProps, mapDispatchToProps)(SovietKickerInput)
