import { Component } from 'preact'

import { FormInput } from '../form'
import { Cols } from '../cols'

class SovietCommentFormAuthorFieldset extends Component {
  constructor(props) {
    super(props)

    this.handleNameInput = this.handleNameInput.bind(this)
    this.handleEmailInput = this.handleEmailInput.bind(this)
  }

  handleNameInput({ value }) {
    this.props.onEdit({ name: value })
  }

  handleEmailInput({ value }) {
    this.props.onEdit({ email: value })
  }

  render(props) {
    const id = props.id || 'new'
    const nameInputId = `${id}-name`
    const emailInputId = `${id}-email`

    return (
      <div className="sovietCommentForm-fieldset">
        <Cols className="formRow is__transposeOnMobile" divisionProportions="3:9">
          <div className="formLabel sovietCommentForm-nameLabel">
            <label htmlFor={ nameInputId }>Имя и фамилия</label>
          </div>
          <div>
            <FormInput
              className="is__fullWidth"
              id={ nameInputId }
              name="name"
              type="text"
              initialValue={ props.name }
              placeholder="Обязательны полностью"
              onInput={ this.handleNameInput }
              onValidityChange={ props.onValidityChange }
              required />
          </div>
        </Cols>

        { !props.askNameOnly &&
          <Cols className="formRow is__transposeOnMobile" divisionProportions="3:9">
            <div className="formLabel sovietCommentForm-emailLabel">
              <label htmlFor={ emailInputId }>Эл. почта</label>
            </div>
            <div>
              <FormInput
                className="is__fullWidth"
                id={ emailInputId }
                name="email"
                type={ props.disableEmailValidation ? 'text' : 'email' }
                initialValue={ props.email }
                placeholder="Не будет опубликована"
                onInput={ this.handleEmailInput }
                onValidityChange={ props.onValidityChange }
                required />
            </div>
          </Cols>
        }
      </div>
    )
  }
}

export default SovietCommentFormAuthorFieldset
