class DirtyForms {
  constructor() {
    this.bindEvents()
  }

  preventUnloadWhenHasUnsavedChanges(e) {
    const $unsavedChanges = $('.has__unsavedChanges')

    if ($unsavedChanges.length) {
      const message = 'У вас есть несохранённые изменения'
      e.returnValue = message

      return message
    }
  }

  bindEvents() {
    $(window).on('beforeunload', this.preventUnloadWhenHasUnsavedChanges.bind(this))
  }
}

module.exports = DirtyForms
