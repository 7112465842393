import { Component } from 'preact'

import 'lib/vendor/easing'
import GetGiftPresent from './getGiftPresent'
import { isOnMobile } from 'lib/envHelpers'

const CLASS_READY = 'is__ready'
const CLASS_UNSEALED = 'is__unsealed'
const CLASS_UNSEALING = 'is__unsealing'
const CLASS_OPENED = 'is__opened'
const CLASS_MANUALLY_CONTROLLED = 'is__manuallyControlled'

const UNSEALING_ANIMATION_LENGTH = 600
const UNSEALING_HEIGHT_COMPENSATOR = 300
const OPENING_LENGTH = 300
const WINDOW_MIN_WIDTH = 880

const VISIBLE_BOX_PART = 0.27
const MOBILE_BOX_SIZE = 350
const MOBILE_PRESENT_PART = 0.95
const DESKTOP_PRESENT_PART = 0.85

const SEALTED_DESKTOP_PART = 0.5
const SEALTED_MOBILE_PART = 0.33

export default class GetGiftBox extends Component {
  componentDidMount() {
    this.$box = $(this.box)
    this.$caps = this.$box.find('.box-cap-inner')
    this.$content = $(this.content)

    this.$styles = $('<style>').appendTo('head')
    this.$innerStuff = this.$box.find('.box-side')
      .filter('.is__left, .is__right, .is__back')
      .add('.box-cap-side.is__inside')

    this.$html = $('html')
    this.$body = $('body')
    this.$form = $('.getGift')
    $(document).on('appReady', () => {
      this.init()
      this.setListeners()
    })
  }

  setListeners() {
    this.$body.on('codeAccepted', this.onCodeAccepted.bind(this))
    $(window).on('resize.all', this.init.bind(this))
  }

  onCodeAccepted() {
    this.$body.off('codeAccepted')
    this.unseal()

    setTimeout(() => {
      this.$body.addClass(CLASS_UNSEALED)
      this.setDrawListeners()
      this.setScrollInterceptor()
      this.setContentFinalPosition()
    }, UNSEALING_ANIMATION_LENGTH)

    window.scrollTo(0, 4)
  }

  init() {
    this.setBoxPositions()
    this.setContentFinalPosition()

    this.moveBox(this.boxPosition.sealed)
    this.setBoxSizeInCss()
    this.setBodyReady()

    if (this.isUnsealing || this.isOpened) this.drawOpening()
  }

  setBodyReady() {
    this.$body.addClass(CLASS_READY)
  }

  setBoxPositions() {
    this.boxPosition = {
      sealed: this.calculateBoxSealedPosition(),
      unsealed: this.calculateBoxUnsealedPosition(),
      opened: this.calculateBoxOpenedPosition(),
    }
  }

  calculateBoxSealedPosition() {
    const visibleBoxSize = this.calculateBoxSize() * VISIBLE_BOX_PART
    const { viewHeight } = window.application
    const availableHeight = viewHeight - this.$form.height() - visibleBoxSize
    const sealtedBoxPart = isOnMobile() ? SEALTED_MOBILE_PART : SEALTED_DESKTOP_PART

    return -availableHeight * sealtedBoxPart
  }

  calculateBoxUnsealedPosition() {
    const { viewHeight } = window.application
    const mobile = -viewHeight / 5
    const desktop = -viewHeight / 3

    return isOnMobile() ? mobile : desktop
  }

  calculateBoxOpenedPosition() {
    return this.calculateBoxSize() / 4
  }

  setContentFinalPosition() {
    const boxContentHeight = this.calculateBoxContentHeight()
    let contentFinalPosition = -(boxContentHeight + this.boxPosition.opened)
    if (isOnMobile()) {
      const mobileCompensator = 0.3 * this.calculateBoxSize()
      contentFinalPosition += mobileCompensator
    }

    this.contentFinalPosition = contentFinalPosition
  }

  calculateBoxContentHeight() {
    const bodyVisibleHeight = this.calculateBodyVisibleHeight()
    const presentVerticalPart = isOnMobile() ? MOBILE_PRESENT_PART : DESKTOP_PRESENT_PART

    return bodyVisibleHeight * presentVerticalPart
  }

  calculateBodyVisibleHeight() {
    // NOTE: this.$body.height() != window.application.viewHeight in safari
    let bodyVisibleHeight = this.$body.height()
    if (this.isUnsealing && !this.isOpened) bodyVisibleHeight -= UNSEALING_HEIGHT_COMPENSATOR

    return bodyVisibleHeight
  }

  setBoxSizeInCss() {
    const remSize = this.calculateBoxSize()
    this.$html.css({ fontSize: remSize + 'px' })
  }

  calculateBoxSize() {
    if (isOnMobile()) return MOBILE_BOX_SIZE

    const maxSize = window.application.viewWidth * 0.9
    const minSize = WINDOW_MIN_WIDTH * 0.9
    let size = window.application.viewHeight
    if (size > maxSize) size = maxSize
    if (size < minSize) size = minSize

    return size % 2 ? size : size - 1
  }

  setDrawListeners() {
    $(window).on('scroll', this.drawOpening.bind(this))
    this.$box.on('click', this.showPresent)
  }

  showPresent() {
    $('html, body').animate({ scrollTop: OPENING_LENGTH }, 1000, 'linear')
  }

  setScrollInterceptor() {
    $(document).on('wheel', () => {
      $('html, body').stop()
      this.$body.addClass(CLASS_MANUALLY_CONTROLLED)
    })
  }

  unseal() {
    this.isUnsealing = true
    this.$body.addClass(CLASS_UNSEALING)
    this.moveBox(this.boxPosition.unsealed)
  }

  drawOpening() {
    const progress = this.calculateProgress()
    this.moveOpenedBox(progress)
    this.openBoxCover(progress)
    this.moveContent(progress)
    this.showInnerStuff(progress)

    if (this.boxShouldBeOpened(progress)) this.markBoxOpened()
  }

  calculateProgress() {
    let scrollTop = this.isOpened ? OPENING_LENGTH : window.pageYOffset
    if (scrollTop < 0) scrollTop /= 5
    const progress = scrollTop / OPENING_LENGTH

    return progress > 1 ? 1 : progress
  }

  openBoxCover(progress) {
    const angle = jQuery.easing.easeOutCubic(null, progress, 2, 150, 1)
    this.$caps.css({ transform: `rotateY(${angle}deg)` })
  }

  moveOpenedBox(progress) {
    const { unsealed, opened } = this.boxPosition
    let position = unsealed + progress * (opened - unsealed)
    if (position < unsealed) position = unsealed

    this.moveBox(position)
  }

  moveContent(progress) {
    const position = this.contentFinalPosition * progress
    const brightness = this.progressToBrightness(progress)
    this.$content.css({
      transform: `translate(-50%, ${position}px)`,
      filter: `brightness(${brightness})`,
    })
  }

  showInnerStuff(progress) {
    const brightness = this.progressToBrightness(progress)
    this.$innerStuff.css({ filter: `brightness(${brightness})` })
  }

  moveBox(position) {
    this.$box.css({ transform: `translateY(${ position }px)` }, UNSEALING_ANIMATION_LENGTH)
  }

  progressToBrightness(progress) {
    const linearConverter = (v) => 0.66 * v + 0.34
    const brightness = linearConverter(progress)
    return brightness > 1 ? 1 : brightness
  }

  boxShouldBeOpened(progress) {
    return progress >= 1 && !this.isOpened
  }

  markBoxOpened() {
    this.isOpened = true
    $(window).off('scroll')
    $(document).trigger('boxOpened') // NOTE: fire confetti
    this.debouncedSetBodyOpenedClass()
  }

  debouncedSetBodyOpenedClass() {
    const debounceDelay = 300
    let timeout = setTimeout(() => this.$body.addClass(CLASS_OPENED), debounceDelay)
    document.addEventListener('scroll', () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => this.$body.addClass(CLASS_OPENED), debounceDelay)
    }, false)
  }

  get product() {
    const productId = this.props.present?.productId || this.props.present?.product_id

    return productId?.match(/[a-z]*/)[0]
  }

  render() {
    const { isPresentReady, email, present } = this.props

    return (
      <div className="getGiftBox">
        <div className="box-wrapper">
          <div className="box" ref={ el => this.box = el}>
            <div className="box-content" ref={ el => this.content = el}>
              { isPresentReady &&
                  <GetGiftPresent
                    { ...present }
                    email={email}
                    product={ this.product }
                    productKey={ present.key } />
              }
            </div>
            <div className="box-side is__top">
              <div className="box-cap is__left">
                <div className="box-cap-inner">
                  <div className="box-cap-side is__inside"/>
                  <div className="box-cap-side is__outside"/>
                </div>
              </div>
              <div className="box-cap is__right">
                <div className="box-cap-inner">
                  <div className="box-cap-side is__inside"/>
                  <div className="box-cap-side is__outside"/>
                </div>
              </div>
            </div>
            <div className="box-side is__front"/>
            <div className="box-side is__right"/>
            <div className="box-side is__back"/>
            <div className="box-side is__left"/>
          </div>
        </div>
      </div>
    )
  }
}
