import { Component } from 'preact'
import debounce from 'debounce'

import TextareaAutosize from 'react-textarea-autosize'
import cx from 'classnames'

import AUTH_HEADERS from 'authHeaders'
import JSON_HEADERS from 'jsonHeaders'

const COMMENT_HEADERS = Object.assign({}, AUTH_HEADERS, JSON_HEADERS)

export default class CommentField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: props.text,
      hasChanges: false,
      isExpanded: props.isExpanded,
      isDisabled: false,
      hasErrors: false,
      isPublished: !!props.isPublished,
    }

    this.expand = this.expand.bind(this)
    this.collapse = this.collapse.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.submitComment = this.submitComment.bind(this)
    this.saveDraft = debounce(300, this.saveDraft.bind(this))
    this.updateCommentStickiness = this.updateCommentStickiness.bind(this)
  }

  get comment() {
    return this.textarea.closest('.js__taskComment')
  }

  get nextEl() {
    return this.comment.nextSibling
  }

  get textareaVerticalIndent() {
    return this.countCommentCssIndent('padding-top') +
      this.countCommentCssIndent('padding-bottom') +
      this.countCommentCssIndent('margin-top') +
      this.countCommentCssIndent('margin-bottom')
  }

  countCommentCssIndent(prop) {
    return Number($(this.comment).css(prop).replace('px', ''))
  }

  componentDidMount() {
    $(document).on('appScroll', this.updateCommentStickiness)
    this.updateCommentStickiness()
  }

  componentWillUnmount() {
    $(document).off('appScroll', this.updateCommentStickiness)
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.isExpanded && this.state.isExpanded) this.textarea.focus()
  }

  expand() {
    if (!this.state.isExpanded) {
      this.setState({ isExpanded: true })
    }
  }

  collapse() {
    if (!this.state.hasChanges) {
      this.setState({ isExpanded: false })
    }
  }

  handleInput(event) {
    this.setState({
      text: event.target.value,
      isDisabled: false,
      hasErrors: false,
      hasChanges: true,
    })

    if (this.shouldSaveDraft()) this.saveDraft()

    this.updateCommentStickiness()
  }

  submitComment() {
    if (this.state.isDisabled) return

    this.setState({ isDisabled: true })

    fetch(this.props.apiUrlBase, {
      method: 'PUT',
      headers: COMMENT_HEADERS,
      body: JSON.stringify({ text: this.state.text }),
    })
      .then(res => this.setState({
        hasChanges: !res.ok && this.state.hasChanges,
        isExpanded: !res.ok,
        isDisabled: res.ok,
        hasErrors: !res.ok,
        isPublished: res.ok,
      }))
  }

  shouldSaveDraft() {
    return !this.state.isPublished
  }

  saveDraft() {
    fetch(this.props.apiUrlBase, {
      method: 'PUT',
      headers: COMMENT_HEADERS,
      body: JSON.stringify({ draft: this.state.text }),
    })
  }

  updateCommentStickiness() {
    const prevElHeight = this.comment.previousSibling.offsetHeight
    const windowHeight = window.application.viewHeight
    const stickingPoint = windowHeight / 2

    if (prevElHeight < stickingPoint) {
      this.comment.classList.remove('is__sticky')
      this.textarea.classList.remove('is__scrollable')
      return
    }

    const nextElTopCoordinate = this.nextEl.getBoundingClientRect().top
    const commentTopCoordinate = this.comment.getBoundingClientRect().top

    const textareaVerticalIndent = this.textareaVerticalIndent
    const textareaVisableHeight = this.textarea.offsetHeight
    const textareaHeight = Number(this.textarea.style.height.replace('px', ''))
    const textareaHiddenHeight = textareaHeight - textareaVisableHeight

    if (nextElTopCoordinate < windowHeight + textareaVerticalIndent) {
      this.comment.classList.remove('is__sticky')
      if ((windowHeight - nextElTopCoordinate > textareaHiddenHeight) ||
       (commentTopCoordinate < stickingPoint)) {
        this.textarea.classList.remove('is__scrollable')
      }
    } else if (commentTopCoordinate > stickingPoint + textareaVerticalIndent) {
      this.comment.classList.add('is__sticky')
      this.textarea.classList.add('is__scrollable')
    }
  }

  render() {
    const placeholderText = 'Комментарий'
    const submitAttrs = { disabled: this.state.isDisabled ? 'disabled' : '' }
    const buttonText = this.state.isPublished ? 'Сохранить' : 'Опубликовать'

    const boxClasses = cx({
      'taskComment-box': true,
      is__expanded: this.state.isExpanded,
      has__errors: this.state.hasErrors,
      has__changes: this.state.hasChanges,
      has__unsavedChanges: this.state.hasChanges,
    })

    return (
      <div className={ boxClasses }>
        <TextareaAutosize
          className="taskComment-text"
          placeholder={ placeholderText }
          value={ this.state.text }
          inputRef={ textarea => this.textarea = textarea }
          onClick={ this.expand }
          onFocus={ this.expand }
          onBlur={ this.collapse }
          onInput={ this.handleInput } />
        <button
          className="button taskComment-submit"
          {...submitAttrs}
          onClick={ this.submitComment }>{ buttonText }</button>
      </div>
    )
  }
}
