import cx from 'classnames'

export const IconSelector = (props) => {
  const classnames = cx(
    'iconSelector',
    {
      [props.className]: props.className,
      is__small: props.isSmall,
    },
  )

  return (
    <div className={ classnames }>
      <div className="iconSelector-items">
        { props.children }
      </div>
    </div>
  )
}

export const IconSelectorItem = (props) => {
  return (
    <div className={ `iconSelector-item ${props.className || ''}`} onClick={ props.onClick }>
      { props.children }
    </div>
  )
}
