import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import {
  switchToInitialScreen,
  setTypeSelf,
  changePaymentMethodToNewCard,
} from './newPaybarActions'

import { Caption } from '../textNodes'

class NewPaybarPayer extends Component {
  constructor(props) {
    super(props)

    this.onLogout = this.onLogout.bind(this)
  }

  onLogout() {
    this.props.switchToInitialScreen()

    if (this.props.type === 'upgrade') {
      this.props.setTypeSelf()
    }
  }

  get cardNumbersVariants() {
    const cartNumber = this.props.userCardNumber
    return [
      cartNumber,
      cartNumber.replace('···', '··'),
      cartNumber.replace('···', '·'),
      cartNumber.replace(/\d···/, '·'),
      cartNumber.replace(/\d···\d/, '·'),
    ]
  }

  get changeUserText() {
    if (this.props.userFirstName) {
      return I18n.t('newPaybar.imNotUserName', { firstName: this.props.userFirstName })
    }

    return I18n.t('newPaybar.anotherEmail')
  }

  render() {
    const {
      userEmail,
      userName,
      userCardNumber,
      payerRef,
      currentPaymentMethod,
      companyPaymentType,
      isCompact,
      type,
    } = this.props

    const hasExistingCard = !!userCardNumber && !currentPaymentMethod
    const shouldDisplayCardRow = type === 'company' ? hasExistingCard && companyPaymentType === 'card' : hasExistingCard
    const className = cx('module', 'newPaybarPayer', this.props.className, { is__compact: isCompact })

    if (!userEmail) return null

    return (
      <div className={ className } ref={ payerRef }>
        <div className="newPaybarPayer-info">
          { userName &&
            <Caption>{ userName }</Caption>
          }

          { shouldDisplayCardRow &&
            <Caption>
              <span className="newPaybarPayer-card" onClick={ this.props.changePaymentMethodToNewCard }>
                { I18n.t('newPaybar.userCardNumber') + ' '}

                <span className="newPaybarPayer-cardSet">
                  { this.cardNumbersVariants.map(cardNumber => (
                    <span>{ cardNumber + ' ' }</span>
                  )) }
                </span>
              </span>

              <span className="newPaybarPayer-edit pseudoLink" onClick={ this.props.changePaymentMethodToNewCard }>
                { I18n.t('newPaybar.changeCard') }
              </span>
            </Caption>
          }

          <Caption><b>{ userEmail }</b></Caption>
        </div>

        <Caption className="newPaybarPayer-changeCard">
          <span className="pseudoLink" onClick={ this.onLogout }
            dangerouslySetInnerHTML={{ __html: this.changeUserText }}>
          </span>
        </Caption>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    userEmail: newPaybar.userEmail,
    userFirstName: newPaybar.userFirstName,
    userName: newPaybar.userName,
    userCardNumber: newPaybar.userCardNumber,
    currentPaymentMethod: newPaybar.currentPaymentMethod,
    companyPaymentType: newPaybar.companyPaymentType,
  }
}

const mapDispatchToProps = {
  switchToInitialScreen,
  setTypeSelf,
  changePaymentMethodToNewCard,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarPayer)
