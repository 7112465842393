import { combineReducers } from 'redux'
import { detectMainColumn } from 'lib/soviets/compilation'

const sovietCompilation = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TITLE':
      return {
        ...state,
        title: action.title,
        hasPublishError: false,
      }

    case 'SET_SUMMARY':
      return {
        ...state,
        summary: action.summary,
        hasPublishError: false,
      }

    case 'SET_DESCRIPTION':
      return {
        ...state,
        description: action.description,
        hasPublishError: false,
      }

    case 'SET_LINKS':
      return {
        ...state,
        links: action.links,
      }

    case 'SET_SOVIETS':
      return {
        ...state,
        soviets: action.soviets,
        mainColumn: detectMainColumn(action.soviets),
      }

    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.categories,
      }

    case 'SET_LAYOUT':
      return {
        ...state,
        layout: action.layout,
        hasPublishError: false,
      }

    case 'SET_CUSTOM_THEME_LAYOUT':
      return {
        ...state,
        layout: action.layout,
        customThemeLayout: action.layout,
        hasPublishError: false,
      }

    case 'SET_TEXT_COLOR':
      return {
        ...state,
        textColor: action.textColor,
        hasPublishError: false,
      }

    case 'SET_CUSTOM_THEME_TEXT_COLOR':
      return {
        ...state,
        textColor: action.textColor,
        customThemeTextColor: action.textColor,
        hasPublishError: false,
      }

    case 'SET_COVER':
      return {
        ...state,
        coverUrl: action.url,
        customCoverUrl: action.url,
        nonRetinaCoverUrl: null,
        coverId: action.id,
        hasCoverUploadError: false,
        hasPublishError: false,
        theme: 'custom',
      }

    case 'SET_THEME':
      return {
        ...state,
        theme: action.theme,
        layout: action.layout,
        textColor: action.textColor,
        coverUrl: action.coverUrl,
        nonRetinaCoverUrl: null,
        hasCoverUploadError: false,
        hasPublishError: false,
      }

    case 'SET_COVER_UPLOAD_ERROR':
      return {
        ...state,
        coverUrl: '',
        hasCoverUploadError: true,
      }

    case 'SET_CAN_PUBLISH':
      return {
        ...state,
        canPublish: action.canPublish,
      }

    case 'SET_IS_PUBLISHING':
      return {
        ...state,
        isPublishing: true,
        hasPublishError: false,
      }

    case 'SET_HAS_PUBLISH_ERROR': {
      return {
        ...state,
        isPublishing: false,
        hasPublishError: true,
      }
    }

    default:
      return state
  }
}

const sovietCompilationReducer = combineReducers({
  sovietCompilation,
})

export default sovietCompilationReducer
