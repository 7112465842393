import { Component } from 'preact'
import { Caption, TextNode } from '../textNodes'

class SubscriptionPricingDiscount extends Component {
  shouldComponentUpdate() {
    return false
  }

  render(props) {
    if (!props.prices.isApplicableForDiscount) return

    if (props.caption) {
      return (
        <Caption html={ I18n.t('books.discountPriceCaption') } />
      )
    }

    return (
      <TextNode
        className="subscriptionPricing-info is__special"
        html={ `${I18n.t('books.discountPriceCaption')}.` } />
    )
  }
}

module.exports = SubscriptionPricingDiscount
