import { Component } from 'preact'

const ACCEPTABLE_LOADING_TIME = 7000

export default class NewPaybarLaunchpadItem extends Component {
  constructor() {
    super()

    this.setLoaded = this.setLoaded.bind(this)
  }

  componentDidMount() {
    if (this.isImageLoaded) {
      this.setLoaded()
    } else {
      setTimeout(this.setLoaded, ACCEPTABLE_LOADING_TIME)
    }
  }

  setLoaded() {
    this.setState({ isLoaded: true })
  }

  get isImageLoaded() {
    return this.$image && this.$image.complete
  }

  get isLocked() {
    return !this.props.isUserAuthenticated
  }
}
