class CompilationCategories {}

const buildCategory = props => props

CompilationCategories.all = () => [
  {
    title: 'Свежак',
    key: '',
    tags: [],
    textColor: '#fff',
    keyColor: '#e20016',
    backgroundColor: '#000',
  },
  {
    title: 'С чего начать',
    key: 'start',
    tags: ['С чего начать'],
    textColor: '#fff',
    keyColor: '#fff',
    backgroundColor: '#5c35bf',
  },
  {
    title: 'Золотой фонд',
    key: 'gold',
    tags: ['Золотой фонд'],
    textColor: '#000',
    keyColor: '#e20016',
    backgroundColor: '#e9ddc2',
  },
  {
    title: 'Дизайн продуктов',
    key: 'productdesign',
    tags: ['Дизайн продуктов'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#000',
  },
  {
    title: 'Дизайн',
    key: 'design',
    tags: ['Дизайн'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#77808c',
  },
  {
    title: 'Типографика',
    key: 'layout',
    tags: ['Вёрстка'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#e30016',
  },
  {
    title: 'Интерфейс',
    key: 'ui',
    tags: ['Интерфейс'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#fc5620',
  },
  {
    title: 'Сайты',
    key: 'websites',
    tags: ['Сайты'],
    textColor: '#fff',
    keyColor: '#fff',
    backgroundColor: '#0000ff',
  },
  {
    title: 'Текст',
    key: 'text',
    tags: ['Текст'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#004f83',
  },
  {
    title: 'Веб-разработка',
    key: 'webdev',
    tags: ['Веб-разработка'],
    keyColor: '#fff',
    textColor: '#000',
    backgroundColor: '#00cefd',
  },
  {
    title: 'Графика',
    key: 'graphics',
    tags: ['Графика'],
    keyColor: '#000',
    textColor: '#000',
    backgroundColor: '#fff',
  },
  {
    title: 'Фирстиль',
    key: 'branding',
    tags: ['Фирстиль'],
    keyColor: '#000',
    textColor: '#000',
    backgroundColor: 'yellow',
  },
  {
    title: 'Шрифт',
    key: 'font',
    tags: ['Шрифт'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#df3d4a',
  },
  {
    title: 'Навигация',
    key: 'wayfinding',
    tags: ['Навигация'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#2a8d8a',
  },
  {
    title: 'Визуализация',
    key: 'data',
    tags: ['Визуализация'],
    keyColor: '#b33',
    textColor: '#000',
    backgroundColor: '#f9f6ed',
  },
  {
    title: 'Переговоры',
    key: 'clients',
    tags: ['Переговоры'],
    keyColor: '#000',
    textColor: '#000',
    backgroundColor: '#fcc033',
  },
  {
    title: 'Управление',
    key: 'fff',
    tags: ['Управление'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#2c5956',
  },
  {
    title: 'Право',
    key: 'law',
    tags: ['Право'],
    keyColor: '#fff',
    textColor: '#fff',
    backgroundColor: '#406e1d',
  },
].map(buildCategory)

CompilationCategories.default = CompilationCategories.all()[0]

CompilationCategories.sunk = buildCategory({
  title: 'Утопленное',
  key: 'sunk',
  tags: ['Утопленное'],
  keyColor: '#fff',
  textColor: '#fff',
  backgroundColor: '#000',
})

CompilationCategories.urlsMap = CompilationCategories.all().reduce((grouped, category) => {
  grouped[category.key] = category

  return grouped
}, {})

CompilationCategories.findByKey = function(key) {
  return this.urlsMap[key]
}


module.exports = CompilationCategories
