export const fetchEnrollmentRating = (enrollment) => {
  const status = window.location.search.replace(/^\?/, '')
  const suffix = status === 'obfuscated' ? `?status=${status}` : ''
  const url = `https://bureau.ru/classroom/events/${enrollment}/reports/race.json${suffix}`

  return fetch(url)
    .then(res => res.json())
    .catch(e => {
      throw new Error(e)
    })
}

export const getCountryFlagUrl = (country, city = null) => {
  if (!country || (country === 'Россия')) return null

  const controversialCities = ['севастополь', 'симферополь', 'керчь', 'евпатория', 'феодосия', 'ялта', 'херсон', 'мелитополь', 'бердянск', 'мариуполь', 'донецк', 'луганск']
  if (city && controversialCities.includes(city.toLowerCase())) return null

  return `https://bureau.ru/school/i/flags/${country}.png`
}
