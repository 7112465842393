import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { setCanPublish, setLayout, setTextColor } from './sovietCompilationActions'

import SovietCompilationTitle from './sovietCompilationTitle'
import SovietCompilationSummary from './sovietCompilationSummary'
import SovietCompilationAuthor from './sovietCompilationAuthor'
import SovietCompilationSocial from './sovietCompilationSocial'
import SovietCompilationCover from './sovietCompilationCover'
import SovietCompilationDescription from './sovietCompilationDescription'
import SovietCompilationUploadButton from './sovietCompilationUploadButton'
import SovietCompilationLayoutSwitch from './sovietCompilationLayoutSwitch'
import SovietCompilationThemePicker from './sovietCompilationThemePicker'
import SovietCompilationPublishButton from './sovietCompilationPublishButton'
import SovietCompilationAutoLayout from './sovietCompilationAutoLayout'
import SovietCompilationCategories from './sovietCompilationCategories'

import hasTextSomethingMeaningful from './hasTextSomethingMeaningful'

import { Cols } from '../cols'
import { Caption } from '../textNodes'
import { Impressions } from '../impressions'
import { Likely } from '../likely'

const SovietCompilationHowtoLink = () => {
  return (
    <Caption className="compilationHowtoLink">
      <a href="/soviet/selected/howto/">Как сделать хорошую подборку советов</a>
    </Caption>
  )
}

class SovietCompilationForm {
  constructor(props) {
    this.props = props

    this.TITLE_MAX_LENGTH = 45
    this.TITLE_FONT_SIZE_SWITCH_ON = 24
    this.SUMMARY_MAX_LENGTH = 100
  }

  isValid() {
    return this.props.title && this._isLengthValid(this.props.title, this.TITLE_MAX_LENGTH)
  }

  _isLengthValid(field, maxLength) {
    return field && field.length > 0 && field.length <= maxLength
  }
}

class SovietCompilation extends Component {
  constructor(props) {
    super(props)

    this.$header = $('.js__header')

    this.form = new SovietCompilationForm(this.props)
    this.setEmptyIf = this.setEmptyIf.bind(this)
  }

  setEmptyIf(isEmpty) {
    this.setState({ isEmpty: isEmpty })
  }

  componentDidUpdate() {
    this.form = new SovietCompilationForm(this.props)
    this.props.setCanPublish(this.form.isValid())

    this.$header.toggleClass('is__inversed', this.props.textColor === 'white')

    if (this.props.canEdit && this.props.isReadOnly) {
      this.$header.append(this._buildEditLink())
    } else {
      this.$header.remove('.js__edit')
    }
  }

  _buildEditLink() {
    const href = this.props.isPublished ? window.sovietCompilation.editLink : '/soviet/selected/new/'

    return $('<a />')
      .attr('class', 'js__edit header-edit')
      .attr('href', href)
  }

  render(props) {
    const hasUsefulDescription = hasTextSomethingMeaningful(props.description)
    const classNames = cx({
      compilation: true,
      is__empty: this.state.isEmpty,
      can__publish: props.canPublish,
      is__published: props.isPublished,
      is__readOnly: props.isReadOnly,
      is__edit: !props.isReadOnly,
      [`is__${props.layout}`]: true,
      [`is__${props.textColor}`]: true,
      has__uselessDescription: !hasUsefulDescription,
      has__usefulDescription: hasUsefulDescription,
      has__cover: !!props.coverUrl,
      has__coverUploadError: props.hasCoverUploadError,
    })

    return (
      <div className={classNames}>
        <div className="compilationSplash">
          <div className="compilationLayout">
            <SovietCompilationTitle
              placeholder="Название"
              maxlength={ this.form.TITLE_MAX_LENGTH }
              fontSizeSwitchOn={ this.form.TITLE_FONT_SIZE_SWITCH_ON } />
            <div className="compilationLayout-margin" />
            <SovietCompilationSummary
              placeholder="Чем полезна подборка?"
              maxlength={ this.form.SUMMARY_MAX_LENGTH } />
          </div>
          <SovietCompilationCover>
            <SovietCompilationTitle
              className="device device__mobile"
              placeholder="Название"
              maxlength={ this.form.TITLE_MAX_LENGTH }
              fontSizeSwitchOn={ this.form.TITLE_FONT_SIZE_SWITCH_ON } />
          </SovietCompilationCover>

          { !props.isReadOnly &&
            <SovietCompilationThemePicker className="device device__laptop device__desktop" />
          }

          { props.isReadOnly &&
            <div className="device laptop desktop">
              <SovietCompilationAuthor />
              <SovietCompilationSocial />
            </div>
          }
        </div>

        { !props.isReadOnly &&
          <SovietCompilationThemePicker className="device device__mobile" />
        }

        { !props.isReadOnly &&
          <Cols className="compilationBottom" divisionProportions="11:1:4">
            <SovietCompilationUploadButton
              placeholder="Не менее<br>1910×1000 пикселей"
              defaultUploadError="Нужна картинка крупнее.<br>Не менее 1910×1000 пикселей" />
            <SovietCompilationLayoutSwitch />
          </Cols>
        }

        <SovietCompilationSummary
          className="device device__mobile"
          placeholder="Чем полезна подборка?"
          maxlength={ this.form.SUMMARY_MAX_LENGTH } />

        { props.isReadOnly &&
          <div className="device mobile">
            <SovietCompilationAuthor />
            <SovietCompilationSocial />
          </div>
        }

        <SovietCompilationDescription />
        { props.canAssignCategory && !props.isPublished &&
          <SovietCompilationCategories />
        }

        { props.isPublished && hasUsefulDescription &&
          <div className="impressionsAndLikely module">
            <div className="impressionsWrapper">
              <Impressions impressionSubject={ `soviet/advice_compilation-${props.compilationId}` } />
            </div>
            <Likely />
          </div>
        }

        { !props.isPublished &&
          <SovietCompilationPublishButton />
        }
        <SovietCompilationAutoLayout setEmptyIf={ this.setEmptyIf } />
        { !props.isPublished &&
          <SovietCompilationHowtoLink />
        }
        { props.isPublished && !hasUsefulDescription &&
          <div className="impressionsAndLikely module">
            <div className="impressionsWrapper">
              <Impressions impressionSubject={ `soviet/advice_compilation-${props.compilationId}` } />
            </div>
            <Likely />
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    compilationId: sovietCompilation.id,
    isReadOnly: sovietCompilation.isReadOnly,
    isPublished: sovietCompilation.isPublished,
    canPublish: sovietCompilation.canPublish,
    canEdit: sovietCompilation.canEdit,
    canAssignCategory: sovietCompilation.canChangeApproval && sovietCompilation.id,
    hasCoverUploadError: sovietCompilation.hasCoverUploadError,
    title: sovietCompilation.title,
    summary: sovietCompilation.summary,
    description: sovietCompilation.description,
    links: sovietCompilation.links,
    layout: sovietCompilation.layout,
    textColor: sovietCompilation.textColor,
    coverUrl: sovietCompilation.coverUrl,
  }
}

const mapDispatchToProps = {
  setCanPublish,
  setLayout,
  setTextColor,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilation)
