import { Component } from 'preact'
import cx from 'classnames'

import CrossButton from '../crossButton/crossButton'
import NewPaybarBasementSwitcher from './newPaybarBasementSwitcher'
import NewPaybarBasementSubscriptionLicense from './newPaybarBasementSubscriptionLicense'
import NewPaybarBasementSubscriptionCancellation from './newPaybarBasementSubscriptionCancellation'
import NewPaybarBasementSubscriptionCancellationText from './newPaybarBasementSubscriptionCancellationText'
import NewPaybarBasementLastChance from './newPaybarBasementLastChance'
import isSubscriptionCancelled from './isSubscriptionCancelled'
import NewPaybarSubscriptionInfo from './newPaybarSubscriptionInfo'

export default class NewPaybarBasement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0,
      activeItem: this.props.activeItem,
      isChanging: false,
    }

    this.setRef = this.setRef.bind(this)
    this.changeHeightWithTransition = this.changeHeightWithTransition.bind(this)
    this.changeActiveItem = this.changeActiveItem.bind(this)
  }

  componentDidMount() {
    this.changeHeightWithTransition()

    this.$el.addEventListener('transitionstart', this.onTransitionStart)
    this.$el.addEventListener('transitionend', this.onTransitionEnd)
  }

  componentWillUnmount() {
    this.$el.removeEventListener('transitionstart', this.onTransitionStart)
    this.$el.removeEventListener('transitionend', this.onTransitionEnd)
  }

  componentDidUpdate() {
    if (this.state.isChanging || (this.state.activeItem === this.props.activeItem)) return

    if (this.props.activeItem === undefined) {
      this.close()
      return
    }

    this.setState({ isChanging: true })

    this.changeActiveItem()
    setTimeout(this.changeHeightWithTransition, 10)
  }

  close() {
    this.setState({
      height: 0,
      isChanging: true,
    })

    setTimeout(() => {
      this.setState({
        prevActiveItem: this.state.activeItem,
        activeItem: undefined,
        isChanging: false,
      })
    }, 500)
  }

  changeActiveItem() {
    if (this.state.activeItem === this.props.activeItem) return

    this.setState({
      prevActiveItem: this.state.activeItem,
      activeItem: this.props.activeItem,
    })
  }

  changeHeightWithTransition() {
    const height = this.basementHeight

    this.setState({
      height,
      isChanging: false,
    })
  }

  setRef($el) {
    this.$el = $el

    this.props.getRef($el)
  }

  get basementHeight() {
    const $basement = $('.newPaybarBasement')

    return $basement.length ? $basement.innerHeight() : 0
  }

  render() {
    const {
      onClose,
      onSubscriptionCancel,
      onSubscriptionCancellationRevert,
      product,
      canDisplaySubscriptionInfo,
      subscription,
      licenseContents,
    } = this.props

    const { activeItem, prevActiveItem, height } = this.state

    const wrapperClassNames = cx(
      'newPaybarBasement-animWrapper',
      `was__${prevActiveItem}`,
      `is__${activeItem}`,
    )

    const wrapperStyles = `height: ${height}px;`

    const classNames = cx('newPaybarBasement', `is__${activeItem}`,)

    const shouldDisplaySubscriptionInfo = canDisplaySubscriptionInfo &&
      (!!subscription.id || isSubscriptionCancelled(subscription)) &&
      activeItem !== 'license'

    return (
      <div className={ wrapperClassNames } style={ wrapperStyles } ref={ this.setRef }>
        <CrossButton onClick={ onClose } device="mobile"/>

        { activeItem !== undefined &&
          <div className={ classNames }>
            <div className="newPaybarBasement-cross">
              <CrossButton onClick={ onClose } device="laptop desktop"/>
            </div>

            <div className="module device mobile">
              { shouldDisplaySubscriptionInfo &&
                <NewPaybarSubscriptionInfo subscription={ subscription } product={ product } type="self"/>
              }
              <NewPaybarBasementSwitcher { ...this.props } />
            </div>

            <div className="newPaybarBasement-content">
              { activeItem === 'license' && <NewPaybarBasementSubscriptionLicense licenseContents={ licenseContents }/> }
              { activeItem === 'cancellation' && <NewPaybarBasementSubscriptionCancellation { ...this.props } onCancel={ onSubscriptionCancel }/> }
              { activeItem === 'cancellationText' && <NewPaybarBasementSubscriptionCancellationText product={ product } /> }
              { activeItem === 'lastChance' && <NewPaybarBasementLastChance { ...this.props } onRevert={ onSubscriptionCancellationRevert }/> }
            </div>
          </div>
        }
      </div>
    )
  }
}
