import { Component } from 'preact'

import { AttachmentIcon } from '../icons'

class SovietFilesButton extends Component {
  constructor(props) {
    super(props)

    this.toggleFilesPanel = this.toggleFilesPanel.bind(this)
  }

  toggleFilesPanel() {
    this.props.toggleFilesPanelVisibility()
  }

  render() {
    return (
      <a
        className="sovietFilesButton button"
        onClick={ this.toggleFilesPanel }>
        <AttachmentIcon />
        { !!this.props.filesCount &&
            <span class="sovietFilesButton-count badge">{ this.props.filesCount }</span>
        }
      </a>
    )
  }
}

export default SovietFilesButton
