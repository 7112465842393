import { Component } from 'preact'
import cx from 'classnames'

import LecturesTags from '../lecturesTags/lecturesTags'
import LecturesCourse from '../lecturesCourse/lecturesCourse'

const MAX_COURSES_TO_SHOW = 3

export default class LecturesByTag extends Component {
  constructor(props) {
    super(props)
    this.state = { activeTagKey: props.tags[0].key }

    this.changeActiveTagKey = this.changeActiveTagKey.bind(this)
  }

  changeActiveTagKey(newTag) {
    this.setState({ activeTagKey: newTag })
  }

  get activeTag() {
    return this.props.tags.find(tag => tag.key === this.state.activeTagKey)
  }

  get coursesToShow() {
    return this.activeTag.courses
      .map(courseKey => this.props.courses.find(course => course.key === courseKey))
      .filter(course => !!course)
      .slice(0, MAX_COURSES_TO_SHOW)
  }

  render() {
    const styles = `--lecturesKeyColor: ${this.activeTag.color};`
    const classNames = cx('lecturesByTag', `is__${this.coursesToShow.length}`)

    return (
      <div class={ classNames } style={ styles }>
        <div className="lecturesByTag-spacer" />

        <div className="lecturesByTag-in">
          <LecturesTags
            tags={ this.props.tags }
            onChooseTag={ this.changeActiveTagKey }
            activeTagKey={ this.activeTag.key } />

          <div className="lecturesByTag-container">
            { this.coursesToShow.map((course, index) => (
              <LecturesCourse course={ course } hasLecturesList={ index < 1 } />
            )) }
          </div>
        </div>
      </div>
    )
  }
}
