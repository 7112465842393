import JSON_HEADERS from 'jsonHeaders'

import { RECAPTCHA_KEY } from 'recaptcha'

const queryPreparationApi = ({ email, productId, subscriptionType, token, coupon, meta }) => {
  const preparationUrl = `/api/system/products/${productId}/subscription/preparation/`

  return fetch(preparationUrl, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ email, subscriptionType, token, coupon, meta }),
    headers: JSON_HEADERS,
  })
    .then(res => res.json())
}

module.exports = (params) => {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(RECAPTCHA_KEY, { action: 'preparation' })
        .then(token => queryPreparationApi({ ...params, token }))
        .then(resolve)
        .catch(reject)
    })
  })
}
