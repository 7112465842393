import { Component } from 'preact'
import cx from 'classnames'

const MAX_EXPIRE_DAYS = 3
const MS_PER_DAY = 24 * 60 * 60 * 1000

export default class SubscriptionMark extends Component {
  constructor(props) {
    super(props)
  }

  get isExpiring() {
    return this.daysLeft <= MAX_EXPIRE_DAYS && this.daysLeft >= 0
  }

  get daysLeft() {
    if (!this.validUntil) return Infinity

    return Math.floor((new Date(this.validUntil) - new Date()) / MS_PER_DAY)
  }

  get validUntil() {
    return this.props.next_subscription_retention_at
  }

  get label() {
    if (this.daysLeft === 0) return '<1'

    return this.isExpiring ? this.daysLeft : '✓'
  }

  get captionText() {
    const labelBase = 'subscription.subscriptionMarkLabel'

    if (!this.isExpiring) return I18n.t(`${labelBase}.default`)

    if (this.daysLeft === 0) return I18n.t(`${labelBase}.lastDay`)

    return I18n.t(`${labelBase}.daysLeft`, { count: this.daysLeft })
  }

  render() {
    const className = cx(
      'subscriptionMark',
      `is__${this.daysLeft}daysLeft`,
      { is__expiring: this.isExpiring }
    )

    return (
      <div className={ className }>
        <div className="subscriptionMark-inner">
          <span className="subscriptionMark-label">{ this.label }</span>
          <div className="module subscriptionMark-caption">
            <div className="subscriptionMark-captionText">{ this.captionText }</div>
          </div>
        </div>
      </div>
    )
  }
}
