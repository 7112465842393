{function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&"object"==typeof s?pug_classes_object(s):s||""}
function pug_classes_array(r,a){for(var s,e="",u="",c=Array.isArray(a),g=0;g<r.length;g++)(s=pug_classes(r[g]))&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=" ");return e}
function pug_classes_object(r){var a="",n="";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=" ");return a}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function soviet(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (options, textHelper) {
      options = options || locals || {}
options.class = options.class || ''
const soviet = options.soviet || {}
const sovietTitle = soviet.compiledTitle || soviet.title
const sovietHyphenatedTitle = soviet.hyphenatedCompiledTitle || soviet.hyphenatedTitle
const isLarge = sovietTitle.length <= 70

options.class += ' soviet'

let titleSize = 'is__sizeLead'
if (options.isLarge) {
  titleSize = 'is__sizeLarge'
}

pug_html = pug_html + "\u003Cdiv" + (pug_attr("class", pug_classes(["spacer",options.class], [false,true]), false, false)+pug_attr("style", pug_style(options.style), true, false)) + "\u003E\u003Ca" + (" class=\"module\""+pug_attr("href", soviet.url, true, false)+pug_attr("data-goal", `${options.goal || 'SPACER_SOVIET_CLICK'}`, true, false)) + "\u003E\u003Cdiv" + (pug_attr("class", pug_classes(["p","soviet-title","is__firstChild",titleSize], [false,false,false,true]), false, false)) + "\u003E\u003Cstrong class=\"spacer-col-title\"\u003E" + (pug_escape(null == (pug_interp = soviet.column) ? "" : pug_interp)) + " \u003C\u002Fstrong\u003E" + (pug_escape(null == (pug_interp = options.noHyphen ? sovietTitle : sovietHyphenatedTitle) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"p is__sizeSmall is__lastChild col-caption\"\u003E\u003Cspan class=\"link\"\u003EСовет " + (pug_escape(null == (pug_interp = soviet.authorGenitiveName) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (soviet.commentsCount || soviet.newCommentsCount) {
pug_html = pug_html + "\u003Cspan class=\"link no-u\"\u003E" + (null == (pug_interp = textHelper.sovietCommentsCount(soviet.commentsCount, soviet.newCommentsCount)) ? "" : pug_interp) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "options" in locals_for_with ?
        locals_for_with.options :
        typeof options !== 'undefined' ? options : undefined, "textHelper" in locals_for_with ?
        locals_for_with.textHelper :
        typeof textHelper !== 'undefined' ? textHelper : undefined));
    ;;return pug_html;} exports.soviet = soviet}
{function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_attrs(t,r){var a="";for(var s in t)if(pug_has_own_property.call(t,s)){var u=t[s];if("class"===s){u=pug_classes(u),a=pug_attr(s,u,!1,r)+a;continue}"style"===s&&(u=pug_style(u)),a+=pug_attr(s,u,!1,r)}return a}
function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&"object"==typeof s?pug_classes_object(s):s||""}
function pug_classes_array(r,a){for(var s,e="",u="",c=Array.isArray(a),g=0;g<r.length;g++)(s=pug_classes(r[g]))&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=" ");return e}
function pug_classes_object(r){var a="",n="";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=" ");return a}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_merge(e,r){if(1===arguments.length){for(var t=e[0],g=1;g<e.length;g++)t=pug_merge(t,e[g]);return t}for(var l in r)if("class"===l){var n=e[l]||[];e[l]=(Array.isArray(n)?n:[n]).concat(r[l]||[])}else if("style"===l){var n=pug_style(e[l]);n=n&&";"!==n[n.length-1]?n+";":n;var a=pug_style(r[l]);a=a&&";"!==a[a.length-1]?a+";":a,e[l]=n+a}else e[l]=r[l];return e}
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function course(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (options) {
      options = options || locals || {}

const course = options.course || {}
const courseKey = course.key || ''
const courseLink = `/courses/${courseKey}/`
const COURSE_GOAL = `COURSE_${courseKey.toUpperCase()}_CLICK`
const COURSE_APPLY_GOAL = `COURSE_${courseKey.toUpperCase()}_APPLY`
const courseDynamicStyles = {
  class: `is__${course.key}`,
  style: `background: ${course.keyColor}; color: ${course.textColor}`,
}
const courseEvent = course.closestEvent || {}
const isOpen = (courseEvent.status === "open")

let courseTitle = course.title
if (options.fullWidth) {
  courseTitle = courseTitle.replace('и представление информации', 'и представление информации')
}
const courseLead = course.lead || course.sentence

options.class = options.class || ''
options.class += options.isSmall ? 'is__small' : ''


if ((isOpen)) {
pug_html = pug_html + "\u003Cdiv" + (pug_attr("class", pug_classes(["course","is__opened","module","banner-debug",options.class], [false,false,false,false,true]), false, false)) + "\u003E\u003Ca" + (" class=\"course-header doubleHover\""+pug_attr("href", courseLink, true, false)+pug_attr("data-goal", COURSE_GOAL, true, false)) + "\u003E\u003Cdiv" + (pug_attrs(pug_merge([{"class": "course-logo"},courseDynamicStyles]), false)) + "\u003E" + (null == (pug_interp = course.icon) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"p course-title is__firstChild is__lastChild\"\u003E" + (pug_escape(null == (pug_interp = courseTitle) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003Cdiv class=\"course-content\"\u003E\u003Ca" + (pug_attrs(pug_merge([{"class": "course-enroll button","href": pug_escape(`${courseLink}?self`),"data-goal": pug_escape(COURSE_APPLY_GOAL)},courseDynamicStyles]), false)) + "\u003EЗаписаться\u003C\u002Fa\u003E\u003Ca" + (" class=\"course-description doubleHover\""+pug_attr("href", courseLink, true, false)+pug_attr("data-goal", COURSE_GOAL, true, false)) + "\u003E\u003Cdiv class=\"p is__sizeLead course-dates\"\u003E" + (pug_escape(null == (pug_interp = courseEvent.dates) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"p is__sizeSmall\"\u003E\u003Cspan class=\"link course-link\"\u003E" + (pug_escape(null == (pug_interp = course.shortDescription) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug_attr("class", pug_classes(["course","module","banner-debug",options.class], [false,false,false,true]), false, false)) + "\u003E\u003Ca" + (" class=\"course-header doubleHover\""+pug_attr("href", courseLink, true, false)+pug_attr("data-goal", COURSE_GOAL, true, false)) + "\u003E\u003Cdiv" + (pug_attrs(pug_merge([{"class": "course-logo"},courseDynamicStyles]), false)) + "\u003E" + (null == (pug_interp = course.icon) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"p course-title is__firstChild is__lastChild\"\u003E" + (pug_escape(null == (pug_interp = courseTitle) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003Ca" + (" class=\"course-content doubleHover\""+pug_attr("href", courseLink, true, false)+pug_attr("data-goal", COURSE_GOAL, true, false)) + "\u003E\u003Cdiv" + (pug_attrs(pug_merge([{"class": "course-logo"},courseDynamicStyles]), false)) + "\u003E" + (null == (pug_interp = course.icon) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"course-description hyphen\"\u003E\u003Cdiv class=\"course-lead p is__sizeSmall\"\u003E" + (pug_escape(null == (pug_interp = courseLead) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"course-link p is__sizeSmall\"\u003E\u003Cspan class=\"course-link-long link\"\u003E" + (pug_escape(null == (pug_interp = course.description) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"course-link-short link\"\u003E" + (pug_escape(null == (pug_interp = course.shortDescription) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
    }.call(this, "options" in locals_for_with ?
        locals_for_with.options :
        typeof options !== 'undefined' ? options : undefined));
    ;;return pug_html;} exports.course = course}
{function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&"object"==typeof s?pug_classes_object(s):s||""}
function pug_classes_array(r,a){for(var s,e="",u="",c=Array.isArray(a),g=0;g<r.length;g++)(s=pug_classes(r[g]))&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=" ");return e}
function pug_classes_object(r){var a="",n="";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=" ");return a}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;function promotedCategory(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (options) {
      options = options || locals || {};
const category = options.category || {};

pug_html = pug_html + "\u003Cdiv" + (pug_attr("class", pug_classes(["promoted-category","module",`is__promotedCategory-${category.key}`], [false,false,true]), false, false)) + "\u003E\u003Cdiv class=\"promoted-category-holder\"\u003E\u003Cdiv class=\"promoted-category-in\"\u003E\u003Csvg class=\"promoted-category-triangle\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" viewBox=\"0 0 197.434 388.365\"\u003E\u003Cdefs\u003E\n  \u003Cstyle\u003E\n    .a {\n      fill: none;\n      fill: var(--backgroundColor);\n      stroke: rgba(0, 0, 0, .2);\n      stroke: var(--strokeColor);\n      stroke-width: .5px;\n    }\n  \u003C\u002Fstyle\u003E\n\u003C\u002Fdefs\u003E\n\u003Cpath class=\"a\" d=\"M98.8,99.8C55.3,56.4,11.8,10.5,2.5.7L0.7,2.2V386l2.1,1.7c9.7-10.3,52.4-55.3,95.3-98.1,49.2-49.1,98.8-95.3,98.8-95.3s-49-45.6-98.1-94.5\" transform=\"translate(-0.2 -0.016)\"\u002F\u003E\n\u003C\u002Fsvg\u003E\u003Ca" + (" class=\"promoted-category-link\""+pug_attr("href", category.customPath ? category.customPath : `/projects/${category.key}/`, true, false)) + "\u003E\u003Cdiv class=\"promoted-category-link-in\"\u003E\u003Cdiv class=\"promoted-category-icon\"\u003E\u003Cimg" + (" class=\"js__inlineSVG\""+pug_attr("src", `/assets/images/categories/${category.icon}.svg`, true, false)) + "\u002F\u003E\u003C\u002Fdiv\u003E\u003Ch2 class=\"promoted-category-title is__firstChild\"\u003E" + (pug_escape(null == (pug_interp = category.title) ? "" : pug_interp)) + "\u003C\u002Fh2\u003E\u003Cdiv class=\"promoted-category-projects\"\u003E";
// iterate category.promotedProjects
;(function(){
  var $$obj = category.promotedProjects;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var project = $$obj[index];
const classes = [];
if (index === 0) classes.push('is__firstChild');
if (index === category.promotedProjects.length - 1) classes.push('is__lastChild');

pug_html = pug_html + "\u003Cdiv" + (pug_attr("class", pug_classes(["p","is__sizeMedium",classes], [false,false,true]), false, false)) + "\u003E" + (null == (pug_interp = project) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var project = $$obj[index];
const classes = [];
if (index === 0) classes.push('is__firstChild');
if (index === category.promotedProjects.length - 1) classes.push('is__lastChild');

pug_html = pug_html + "\u003Cdiv" + (pug_attr("class", pug_classes(["p","is__sizeMedium",classes], [false,false,true]), false, false)) + "\u003E" + (null == (pug_interp = project) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"promoted-category-counter p is__sizeSmall is__lastChild\"\u003E\u003Cspan class=\"link\"\u003E" + (pug_escape(null == (pug_interp = category.projectsCount) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "options" in locals_for_with ?
        locals_for_with.options :
        typeof options !== 'undefined' ? options : undefined));
    ;;return pug_html;} exports.promotedCategory = promotedCategory}