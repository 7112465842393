import { Component } from 'preact'
import { connect } from 'preact-redux'
import TextareaAutosize from 'react-textarea-autosize'

import { setTitle } from './sovietActions'

class SovietTitleInput extends Component {
  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
  }

  handleInput(event) {
    this.props.setTitle(event.target.value)
  }

  render(props) {
    return (
      <div className="sovietTitleInput-wrapper">
        <TextareaAutosize
          className="sovietTitleInput-text"
          placeholder="Главная мысль"
          value={ props.title }
          onInput={ this.handleInput } />
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    title: soviet.title,
    titleSize: soviet.titleSize,
  }
}

const mapDispatchToProps = {
  setTitle,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietTitleInput)
