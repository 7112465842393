import cx from 'classnames'

import { Heading3, TextNode } from '../textNodes'
import { FbIcon } from '../icons'

const Icon = () => {
  return (
    <div className="fbSpacer-icon">
      <FbIcon />
    </div>
  )
}

const CompactSpacer = (props) => {
  const classes = cx(
    'fbSpacer',
    'module',
    'banner-debug',
    props.className
  )

  return (
    <div className={ classes }>
      <Heading3>
        <Icon />
        Фейсбук бюро
      </Heading3>
      <TextNode>
        Новости, свежие советы, лекции, анонсы курсов, наборов в школы, книг и проектов
      </TextNode>
      <div className="fbSpacer-cols">
        <div className="fbSpacer-left">
          <TextNode className="fbSpacer-name">
            <a href="https://www.facebook.com/bureaugorbunov">bureaugorbunov</a>
          </TextNode>
        </div>
        <div className="fbSpacer-buttonWrapper">
          <a href="https://www.facebook.com/bureaugorbunov" className="button fbSpacer-button">Подписаться</a>
        </div>
      </div>
    </div>
  )
}

const FullSpacer = (props) => {
  const classes = cx(
    'fbSpacer',
    'module',
    'device',
    'device__laptop',
    'device__desktop',
    'banner-debug',
    props.className
  )

  return (
    <div>
      <a href="https://www.facebook.com/bureaugorbunov" className={ classes }>
        <Icon />
        <div className="fbSpacer-titleWrapper">
          <div className="fbSpacer-title">Фейсбук бюро</div>
        </div>
        <div className="fbSpacer-undertitle">
          <TextNode>
            Новости, советы, курсы, лекции, школы, книги и проекты: <span className="link">bureaugorbunov</span>
          </TextNode>
        </div>
      </a>
      <CompactSpacer className={ `${props.className} device device__mobile` } />
    </div>
  )
}

const FbSpacer = (props) => {
  const isHalf = props.size === 'half'
  const isFull = props.size === 'full'

  const classes = cx(
    {
      is__half: isHalf,
      is__full: isFull,
    }
  )

  if (isFull) return (<FullSpacer className={ classes } />)

  return (<CompactSpacer className={ classes } />)
}

export default FbSpacer
