import { Component } from 'preact'
import cx from 'classnames'

import PageMenu from '../../assets/scripts/pageMenu'

export default class Product extends Component {
  componentDidMount() {
    new PageMenu($(this.wrapperRef))
  }

  render(props) {
    const classes = cx(
      'module',
      'product',
      'js__pageMenu',
      {
        [props.className]: props.className,
        ['is__' + props.mod]: !!props.mod,
      }
    )

    return (
      <div className={ classes } ref={ (dom) => this.wrapperRef = dom }>
        { props.name &&
          <div className={ cx('product-name', { has__noMenu: !props.children }) }>
            <span
              className="product-name-inner"
              dangerouslySetInnerHTML={{ __html: props.name }}
            />
            { props.children &&
              <button className="product-menu-toggler">
                <div className="product-menu-toggler-icon" />
              </button>
            }
          </div>
        }

        { props.children &&
          <div className="product-menu">
            { props.children }
          </div>
        }
      </div>
    )
  }
}
