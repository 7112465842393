import { Component } from 'preact'

import { CollecturePricingFullView, CollecturePricingCompactView } from './collecturePricingView'

import cx from 'classnames'

export default class CollecturePricing extends Component {
  get isUpgrade() {
    return !!this.props.prices.specialOffer
  }

  render() {
    const subscriptionClasses = cx('collecturePrice module', { is__strikedOut: this.isUpgrade })

    return (
      <div>
        { this.props.isCompact &&
          <CollecturePricingCompactView
            { ...this.props }
            isUpgrade={ this.isUpgrade } />
        }

        { !this.props.isCompact &&
          <CollecturePricingFullView
            { ...this.props }
            isUpgrade={ this.isUpgrade }
            className={ subscriptionClasses } />
        }
      </div>
    )
  }
}
