{
  const CLASS_FADEDOUT = 'is__fadedOut';

  class BooksDiscountBanner {
    constructor($el) {
      this.$el = $el;
      this.$document = $(document);
      this.isFaded = false;

      this.setListeners();
    }

    init() {
      this.viewportHeight = window.innerHeight;
      this.bannerOffset = this.$el.offset().top;

      this.checkScroll(window.application.currentScrollPosition);
    }

    setListeners() {
      this.$document.on('appScroll', (_, position) => this.checkScroll(position));
      this.$document.on('appReady appResize', this.init.bind(this));
    }

    checkScroll(position) {
      if (this.isFaded) return;

      const windowBottomEdge = position + this.viewportHeight;

      if (windowBottomEdge > this.bannerOffset) this.fadeOut();
    }

    fadeOut() {
      this.isFaded = true;
      this.$el.addClass(CLASS_FADEDOUT);
    }
  }

  $('.js__bookDiscount').each(function() {
    new BooksDiscountBanner($(this));
  });
}
