const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))
const shouldInstallStubs = () => /localhost|\.artgorbunov|\.bureau/.test(window.location.hostname)

const VARIANTS = {
  school_test_missed_everything: {
    abilities: { can_submit_answers: false },
    result: null,
  },

  school_test_passed_flawlessly: {
    abilities: { can_submit_answers: true },
    result: {
      score: 45.35,
      max_score: 45.35,
      missed_deadline: false,
      answers: [
        'ded31705c6da8e23fb3d251640db1d4e.3',
        '90b4e7aa7c29700869e89bb2267207e9.1',
        '21d02c1559b89f16a9130cea76949aee.0',
        '85205c14cde6466b2e0c149f75b3a1fc.2',
        '571ec553ca67df5fe67035d2572540e5.5',
        'cec65cf43c42624ef5c055e1be13afbc.2',
        '76af6d6c4461eccf69891d2913d7fa8c.1',
        'b96bfb012f9493946df4326785fb74b7.2',
        '43492b349a9c55b55ebd0afd2834fba1.0',
        'b9b0e352d24826818b3b7f1ec4544fdc.3',
        '76af6d6c4461eccf69891d2913d7fa8c.0',
        '854d91cb472fa43383c8472e29960953.1',
        '83592cad3f115cc1d6535222c1cafa43.0',
        'b9b0e352d24826818b3b7f1ec4544fdc.1',
        '854d91cb472fa43383c8472e29960953.0',
        'fab0c216b4f71ae9ead293f005e180fc.2',
        '21d02c1559b89f16a9130cea76949aee.1',
        '3bec197e0c392b3cbffc3609758d6249.0',
        '63ee06d8d2d041afbd2024c7f5a3b9cb.0',
        '571ec553ca67df5fe67035d2572540e5.1',
        'cfb537aa2c3a8f5a13ade957d9d487b2.1',
        '90b4e7aa7c29700869e89bb2267207e9.2',
        '4007d3c2de8f1bc32965793f878187c5.0',
        '4ac39285576562813ecbfeae85e4ced7.4',
        '9abb6770f05d81d171dfb2ec73b5d6cd.3',
        '21d02c1559b89f16a9130cea76949aee.3',
        'ded31705c6da8e23fb3d251640db1d4e.4',
        'a4ef988e89192b823aa6c0d0878d8460.2',
        '571ec553ca67df5fe67035d2572540e5.3',
        'cb1b70e78b738aea39c7eeeea56ac4a0.3',
        'cec65cf43c42624ef5c055e1be13afbc.4',
        'aecf0cf62f8a20c8ec7721b4ac9b99c1.3',
        'e496090d29f51e18a18189db85e85fdd.0',
        '7bced728a03367d8598f134d3476fd75.4',
        '61729cf129ec409e868da47fa2761e2d.5',
        '61729cf129ec409e868da47fa2761e2d.2',
        'e496090d29f51e18a18189db85e85fdd.1',
        'cec65cf43c42624ef5c055e1be13afbc.3',
        'cfb537aa2c3a8f5a13ade957d9d487b2.2',
        '4007d3c2de8f1bc32965793f878187c5.2',
        'e0f8ab3252abccb9db33c1b06f6cbe8a.0',
        '9abb6770f05d81d171dfb2ec73b5d6cd.2',
        '85205c14cde6466b2e0c149f75b3a1fc.1',
        'aecf0cf62f8a20c8ec7721b4ac9b99c1.2',
        '43492b349a9c55b55ebd0afd2834fba1.2',
        'e496090d29f51e18a18189db85e85fdd.3',
        'bcd0e0e6f09d7681ec101b72c4259dd7.0',
        '63ee06d8d2d041afbd2024c7f5a3b9cb.2',
        '63ee06d8d2d041afbd2024c7f5a3b9cb.1',
        '61729cf129ec409e868da47fa2761e2d.1',
        '854d91cb472fa43383c8472e29960953.2',
        '7bced728a03367d8598f134d3476fd75.0',
        '3bec197e0c392b3cbffc3609758d6249.3',
        'a4ef988e89192b823aa6c0d0878d8460.3',
        'aecf0cf62f8a20c8ec7721b4ac9b99c1.0',
        '61729cf129ec409e868da47fa2761e2d.0',
        '90b4e7aa7c29700869e89bb2267207e9.3',
        '21d02c1559b89f16a9130cea76949aee.2',
        '83592cad3f115cc1d6535222c1cafa43.4',
        'ded31705c6da8e23fb3d251640db1d4e.1',
        '7bced728a03367d8598f134d3476fd75.1',
        'a4ef988e89192b823aa6c0d0878d8460.1',
        '76af6d6c4461eccf69891d2913d7fa8c.2',
        'cec65cf43c42624ef5c055e1be13afbc.1',
        'aecf0cf62f8a20c8ec7721b4ac9b99c1.1',
        '85205c14cde6466b2e0c149f75b3a1fc.0',
        'e0f8ab3252abccb9db33c1b06f6cbe8a.1',
        'bcd0e0e6f09d7681ec101b72c4259dd7.1',
        'cb1b70e78b738aea39c7eeeea56ac4a0.2',
        'cfb537aa2c3a8f5a13ade957d9d487b2.3',
        'b9b0e352d24826818b3b7f1ec4544fdc.0',
        '9abb6770f05d81d171dfb2ec73b5d6cd.1',
        '4ac39285576562813ecbfeae85e4ced7.0',
        '61729cf129ec409e868da47fa2761e2d.4',
        '4007d3c2de8f1bc32965793f878187c5.1',
        'e0f8ab3252abccb9db33c1b06f6cbe8a.3',
        '83592cad3f115cc1d6535222c1cafa43.5',
        '4ac39285576562813ecbfeae85e4ced7.2',
        'e0f8ab3252abccb9db33c1b06f6cbe8a.4',
        '571ec553ca67df5fe67035d2572540e5.6',
        'b96bfb012f9493946df4326785fb74b7.0',
        'ded31705c6da8e23fb3d251640db1d4e.2',
        'b9b0e352d24826818b3b7f1ec4544fdc.2',
        'e496090d29f51e18a18189db85e85fdd.4',
        'a4ef988e89192b823aa6c0d0878d8460.0',
        '43492b349a9c55b55ebd0afd2834fba1.1',
        '7bced728a03367d8598f134d3476fd75.3',
        'e496090d29f51e18a18189db85e85fdd.2',
        'b96bfb012f9493946df4326785fb74b7.1',
        'cfb537aa2c3a8f5a13ade957d9d487b2.0',
        'fab0c216b4f71ae9ead293f005e180fc.4',
        '61729cf129ec409e868da47fa2761e2d.3',
        '7bced728a03367d8598f134d3476fd75.2',
        'cb1b70e78b738aea39c7eeeea56ac4a0.1',
      ],
      incorrect_question_ids: [],
    },
  },
}

module.exports = (testData) => {
  if (!shouldInstallStubs()) return testData

  let stub = {}
  const search = window.location.search.replace('?', '')

  Object.keys(VARIANTS).forEach((variant) => {
    if (search === variant) {
      stub = deepCopy(VARIANTS[variant])
    }
  })

  return { ...testData, ...stub }
}
