import { Component } from 'preact'
import { connect } from 'preact-redux'

import { detectPaybarEmailFormCaption } from './variants'

class NewPaybarEmailFormCaption extends Component {
  render(props) {
    const PaybarEmailFormCaptionProduct = detectPaybarEmailFormCaption(props.product)

    return (
      <PaybarEmailFormCaptionProduct { ...props } />
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    userEmail: newPaybar.userEmail,
    userEmailNotice: newPaybar.userEmailNotice,
    product: newPaybar.product,
    type: newPaybar.type,
    cannotRead: newPaybar.cannotRead,
    isPreorder: newPaybar.isPreorder,
  }
}

export default connect(mapStateToProps)(NewPaybarEmailFormCaption)
