import { Component } from 'preact'
import cx from 'classnames'

import { Caption } from '../textNodes'

export default class NewPaybarGiftMessage extends Component {
  get giftMessage() {
    return I18n.t(
      `newPaybar.giftMessage.${this.props.product}.${this.discountKey}.subscription_${this.props.prices.renewalPeriod}`,
      { price: this.subscriptionPrice }
    )
  }

  get subscriptionPrice() {
    const { previousSubscription, subscription } = this.props.prices

    return previousSubscription || subscription
  }

  get shouldDisplayPreviousSubscriptionPrice() {
    const { previousSubscription, subscription, isApplicableForDiscount } = this.props.prices

    return isApplicableForDiscount && previousSubscription !== subscription
  }

  get discountKey() {
    return this.shouldDisplayPreviousSubscriptionPrice ? 'withoutDiscount' : 'withDiscount'
  }

  render(props) {
    const className = cx('newPaybarGiftMessage', 'module', props.className)

    return (
      <div className={ className }>
        <Caption html={ this.giftMessage } />
      </div>
    )
  }
}
