import { Component } from 'preact'
import cx from 'classnames'

import { SlideInLayer } from '../slideInLayer'
import { Cols } from '../cols'
import { Heading3, TextNode } from '../textNodes'
import EmailForm from '../emailForm/emailForm'
import { setTypeToUrl, removeTypeFromUrl, getTypeFromUrl } from '../newPaybar/typesUrlHelper'
import { subscribeUser } from '../subscriptionForm/emailUtils'
import { typograf } from 'lib/textHelper'

const DEFAULT_MAILING_LIST = 'default'
const FILE_TYPES = /\.(jpg|jpeg|png|bmp|gif|pdf)$/

export default class Leadbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: (getTypeFromUrl() === this.urlType) || this.shouldTriggerByDefault,
      email: this.email,
      isSubscribed: false,
    }

    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    this.revealIfSubscribed()
    this.setEventListener()

    setTimeout(this.focusInputIfVisible.bind(this), 200) // HACK: something forbids focus atm
  }

  componentDidUpdate() {
    this.revealIfSubscribed()
    this.focusInputIfVisible()
  }

  shouldComponentUpdate(_, nextState) {
    return this.state.isVisible !== nextState.isVisible ||
      this.state.isSubscribed !== nextState.isSubscribed ||
      this.state.email !== nextState.email
  }

  focusInputIfVisible() {
    if (this.state.isVisible) this.emailFormInput.focus()
  }

  get defaultUrlType() {
    return 'contactForm'
  }

  get urlType() {
    return `${this.defaultUrlType}${this.idCapitalized}`
  }

  get shouldTriggerByDefault() {
    return this.props.triggeredByDefault && (getTypeFromUrl() === this.defaultUrlType)
  }

  get meta() {
    return `&leadMagnetName=${this.props.name || ''}&leadMagnetHref=${this.href}&leadMagnetId=${this.id}`
  }

  get mailingList() {
    return this.props.mailingList || DEFAULT_MAILING_LIST
  }

  get href() {
    return this.props.href || ''
  }

  get buttonText() {
    return this.props.buttonText || this.defaultButtonText
  }

  get defaultButtonText() {
    const isFile = FILE_TYPES.test(this.href)
    const suffix = isFile ? 'downloadFile' : 'openLink'

    return I18n.t(`leadMagnet.${suffix}`)
  }

  get idCapitalized() {
    if (!this.id) return ''

    return this.id.charAt(0).toUpperCase() + this.id.slice(1)
  }

  get email() {
    return window.application.user && window.application.user.email
  }

  get id() {
    return this.props.id || ''
  }

  setEventListener() {
    const suffix = this.id ? `:${this.id}` : ''
    $(document).on(`leadMagnetShow${suffix}`, this.show)
  }

  hide() {
    removeTypeFromUrl()
    this.setState({ isVisible: false })
  }

  show() {
    setTypeToUrl(this.urlType)
    this.setState({ isVisible: true })
  }

  submit({ email }) {
    return subscribeUser({
      email,
      eventType: this.mailingList,
      eventId: this.id,
      additionalMeta: this.meta,
    })
      .then(isSubscribed => this.setState({ email, isSubscribed }))
      .catch(e => {
        console.error(e) // eslint-disable-line no-console
        this.setState({ email })
      })
  }

  redirect() {
    if (this.href) window.location = this.href
  }

  revealIfSubscribed() {
    if (!this.href) return null
    if (this.state.isSubscribed) return this.reveal()

    return this.fetchSubscriptionData()
      .then(res => this.setState({ isSubscribed: res.ok }, () => this.reveal()))
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  reveal() {
    if (this.state.isVisible) {
      this.hide()
      this.redirect()
    }
  }

  fetchSubscriptionData() {
    const email = (this.email || '').toLowerCase()

    return $.get({
      url: `/mailing_lists/${this.mailingList}/opt_ins/?email=${email}&leadMagnetId=${this.id}`,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      xhrFields: { withCredentials: true },
    })
  }

  render(props) {
    return (
      <div className= { cx('leadbar', { is__visible: this.state.isVisible }) }>
        <SlideInLayer isVisible={ this.state.isVisible } setHidden={ this.hide }>
          <div className="module is__boxedDefault is__bleedBackgroundSides is__noMargins textBox">
            { props.name &&
              <Heading3>{ props.name }</Heading3>
            }

            <Cols transposeOnMobile className="is__desktopDivisionProportions6to10">
              <div className="leadbar-description">
                <TextNode>{ typograf(props.text) }</TextNode>
                { props.children }
              </div>
              <div className="leadbar-emailForm">
                <EmailForm
                  email={ this.state.email }
                  isSubscribed={ this.state.isSubscribed }
                  onSubmit={ this.submit }
                  onFormStateSubscribed={ this.props.href ? undefined : this.hide }
                  buttonText={ this.buttonText }
                  inputRef={ el => this.emailFormInput = el }
                />
              </div>
            </Cols>
          </div>
        </SlideInLayer>
      </div>
    )
  }
}
