import { Component } from 'preact'

export default class Form extends Component {
  render(props) {
    return (
      <form {...props}
        className={`
          form
          ${props.className ? props.className : ''}
        `}
        noValidate
        onSubmit={e => {
          e.preventDefault()
          if (props.isDisabled) return

          const json = Array.from(e.target.querySelectorAll('[name]'))
            .reduce((_json, $field) =>
              Object.assign(_json, { [$field.getAttribute('name')]: $field.value })
            , {})

          props.onSubmit(json)
        }}
      >
        { props.children }
      </form>
    )
  }
}
