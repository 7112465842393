import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { Cols } from '../cols'

import SovietSplash from './sovietSplash'
import SovietSplashAvatar from './sovietSplashAvatar'
import SovietSocial from './sovietSocial'
import SovietText from './sovietText'
import SovietFilesButton from './sovietFilesButton'
import SovietFilesPanel from './sovietFilesPanel'
import SovietFilesUploader from './sovietFilesUploader'
import SovietPublishButton from './sovietPublishButton'
import SovietAuthorSelector from './sovietAuthorSelector'
import SovietPublishAtSelector from './sovietPublishAtSelector'
import SovietTypeSelector from './sovietTypeSelector'
import SovietTextColorSelector from './sovietTextColorSelector'
import SovietHeaderImageUploadButton from './sovietHeaderImageUploadButton'
import SovietMainThinkInput from './sovietMainThinkInput'
import SovietKickerInput from './sovietKickerInput'
import SovietTagsInput from './suggestInput/suggestInput'

import { toggleFilesPanelVisibility } from './sovietActions'

class Soviet extends Component {
  constructor(props) {
    super(props)
  }

  get wrapperClasses() {
    return cx(
      'sovietEditor',
      {
        is__preview: this.props.isPreview,
        is__editor: this.props.isEditor,
        is__broken: this.props.isBroken,
        is__unicorn: this.props.isUnicorn,
        is__filesPanelVisible: this.props.isFilesPanelVisible,
      })
  }

  get editorStyles() {
    return `:root {
      --keyColor: ${this.props.keyColor};
      --keyUnderlineColor: ${this.props.keyUnderlineColor};
      --linkColor: ${this.props.keyColor};
      --linkVisitedColor: ${this.props.keyColor};
      --linkUnderlineColor: ${this.props.keyUnderlineColor};
      --linkVisitedUnderlineColor: ${this.props.keyUnderlineColor};
      --focusColor: ${this.props.textColor};
      --sovietHeaderBackground: ${this.props.bgColor};
    }`
  }

  render(props) {
    return (
      <div className={ this.wrapperClasses }>
        <style>
          { this.editorStyles }
        </style>
        <SovietSplash />

        { !props.isEditor &&
          <Cols
            className="sovietUndersplash"
            transposeOnMobile="true"
            divisionProportions="6:10">
            <SovietSplashAvatar { ...props } />
            <div className="module device device__laptop device__desktop">
              <SovietSocial
                isImpressionsDisabled={ !props.isUnicorn }
                isDisabled={ props.isPreview || (!props.isUnicorn && props.isBroken) }
              />
            </div>
          </Cols>
        }

        { props.isEditor &&
          <div className="sovietUndersplash">
            <Cols className="sovietUndersplash-fieldset">
              <div className="module sovietUndersplash-left">
                <SovietAuthorSelector />
                <SovietPublishAtSelector />
                <SovietTypeSelector />
              </div>
              <div className="module sovietUndersplash-right  device device__laptop device__desktop">
                <SovietTextColorSelector />
                <SovietHeaderImageUploadButton />
              </div>
            </Cols>

            <div className="sovietUndersplash-fieldset">
              <SovietMainThinkInput />
            </div>

            <SovietTagsInput />
          </div>
        }

        { props.isEditor &&
          <SovietText />
        }

        { props.isEditor &&
          <SovietKickerInput />
        }

        <div className="sovietButtonsDeck">
          { props.isEditor &&
              <SovietFilesButton
                filesCount={ (this.props.files || []).length }
                toggleFilesPanelVisibility={ this.props.toggleFilesPanelVisibility } />
          }

          { (props.isEditor || props.isPreview) &&
            <SovietPublishButton />
          }
        </div>

        { props.isEditor &&
          <SovietFilesPanel />
        }

        { props.isEditor &&
          <SovietFilesUploader />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    id: soviet.id,
    isPreview: soviet.isPreview,
    isEditor: soviet.isEditor,
    isUnicorn: soviet.isUnicorn,
    isBroken: soviet.isBroken,
    isPublished: soviet.isPublished,
    isFilesPanelVisible: soviet.isFilesPanelVisible,
    authorAvatarUrl: soviet.authorAvatarUrl,
    authorArchiveUrl: soviet.authorArchiveUrl,
    authorName: soviet.authorName,
    authorGenitiveName: soviet.authorGenitiveName,
    authorCaption: soviet.authorCaption,
    keyColor: soviet.keyColor,
    keyUnderlineColor: soviet.keyUnderlineColor,
    bgColor: soviet.bgColor,
    files: soviet.files,
    url: soviet.url,
  }
}

const mapDispatchToProps = {
  toggleFilesPanelVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(Soviet)
