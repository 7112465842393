import { formatText, sanitize, wrapText } from 'lib/textHelper'

import {
  placeSovietTitles,
  markImportantLinks,
  wrapListLikeLinks,
  plainTextCompilationSelection,
  preprocessPlainText,
} from './sovietCompilationDescriptionUtils'

const postprocessSovietLinks = (html, soviets) => {
  const $html = $('<div>').append($(html))

  return placeSovietTitles(markImportantLinks($html), soviets).html()
}

const onCopy = (e) => {
  e.clipboardData.setData('text/plain', plainTextCompilationSelection())
  e.preventDefault()
}

export default function({ description, soviets }) {
  const html = wrapText($, wrapListLikeLinks($, formatText(preprocessPlainText(description))))
  const postprocessedHtml = postprocessSovietLinks(html, soviets)
  const sanitizedHtml = sanitize(postprocessedHtml)

  return (
    <div
      className="js__sovietCompilationHtml sovietCompilationHtml"
      onCopy={ onCopy }
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}>
    </div>
  )
}
