import UserAvatar from '../userAvatar/userAvatar'

const AvatarWithName = ({ avatarVisible, author: { name, jobTitle, company, avatarUrl } }) => {
  return (
    <div className="comment-authorInner">
      {
        avatarVisible &&
          <UserAvatar isActive={ true } name={ name } src={ avatarUrl } />
      }
      <div className="comment-authorDetails">
        <span className="comment-authorName">
          { name }
        </span>
        { jobTitle &&
          <span className="comment-authorJobTitle">
            { `, ${jobTitle}` }
          </span>
        }
        { company &&
          <span className="comment-authorCompany">
            { `, ${company}` }
          </span>
        }
      </div>
    </div>
  )
}

const CommentAuthorDetails = (props) => {
  const { author: { englishName } } = props
  return (
    englishName ?
    <a href={ `/burosfera/${englishName}/` } name={ englishName } className="module comment-author no-u doubleHover">
      <AvatarWithName { ...props } />
    </a> :
    <div className="module comment-author">
      <AvatarWithName { ...props } />
    </div>
  )
}

export default CommentAuthorDetails
