import cx from 'classnames'

import { Heading3, TextNode } from '../textNodes'
import { ZenIcon as ZenRawIcon } from '../icons'

const ZEN_URL = 'https://zen.yandex.ru/bureau'

const ZenIcon = () => {
  return (
    <div className="zenSpacer-icon">
      <ZenRawIcon />
    </div>
  )
}

const CompactSpacer = (props) => {
  const classes = cx(
    'zenSpacer',
    'module',
    'banner-debug',
    props.className
  )

  return (
    <div className={ classes }>
      <Heading3>
        <ZenIcon />
        Дзен бюро
      </Heading3>
      <TextNode>
        Новости, свежие советы, лекции, анонсы курсов, наборов в школы, книг и проектов
      </TextNode>
      <div className="zenSpacer-cols">
        <div className="zenSpacer-left">
          <TextNode className="zenSpacer-name">
            <a className="novisited" href={ ZEN_URL }>bureau</a>
          </TextNode>
        </div>
        <div className="zenSpacer-buttonWrapper">
          <a href={ ZEN_URL } className="button zenSpacer-button">Присоединиться</a>
        </div>
      </div>
    </div>
  )
}

const FullSpacer = (props) => {
  const classes = cx(
    'zenSpacer',
    'module',
    'device',
    'device__laptop',
    'device__desktop',
    'banner-debug',
    props.className
  )

  return (
    <div>
      <a href={ ZEN_URL } className={ classes }>
        <ZenIcon />
        <div className="zenSpacer-titleWrapper">
          <div className="zenSpacer-title">Дзен бюро</div>
        </div>
        <div className="zenSpacer-undertitle">
          <TextNode>
            Новости, советы, курсы, лекции, школы, книги и проекты: <span className="link">bureau</span>
          </TextNode>
        </div>
      </a>
      <CompactSpacer className={ `${props.className} device device__mobile` } />
    </div>
  )
}

const ZenSpacer = (props) => {
  const isHalf = props.size === 'half'
  const isFull = props.size === 'full'

  const classes = cx(
    {
      is__half: isHalf,
      is__full: isFull,
    }
  )

  if (isFull) return (<FullSpacer className={ classes } />)

  return (<CompactSpacer className={ classes } />)
}

export default ZenSpacer
