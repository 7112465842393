import { Component } from 'preact'
import { connect } from 'preact-redux'

import { birmanize } from '../../../../lib/textHelper'
import { moscowifyLocal } from '../../calendar/timezoneUtils'

import expandScreenToFlags from '../expandScreenToFlags'
import { getSchoolNameFromSubscription } from './schoolHelpers'
import isLaunchpad from '../isLaunchpad'

import NewPaybarPayer from '../newPaybarPayer'
import NewPaybarPersonalForm from '../newPaybarPersonalForm'
import NewPaybarTitle from '../newPaybarTitle'
import NewPaybarSchoolCaption from './newPaybarSchoolCaption'
import NewPaybarSchoolPricing from './newPaybarSchoolPricing'
import NewPaybarSchoolRequirements from './newPaybarSchoolRequirements'
import NewPaybarLaunchpad from '../newPaybarLaunchpad'
import NewPaybarOverlay from '../newPaybarOverlay'
import NewPaybarTabs from '../newPaybarTabs'
import NewPaybarSubscriptionStatus from '../bookshelf/newPaybarSubscriptionStatus'

class NewPaybarSchool extends Component {
  get caption() {
    const date = birmanize(this.props.dateOfNoReturn, { format: 'dateOnly' })
    const year = new Date(this.props.dateOfNoReturn).getFullYear()

    return I18n.t('newPaybar.school.caption', { date, year })
  }


  render(props) {
    const isSuccessOrLaunchpad = props.isSuccessScreen || props.isSubscriptionScreen
    const isMonthlyPayment = props.subscription.paymentType === 'monthly'

    const shouldDisplayPricing = props.isPurchaseScreen || props.isInitialScreen
    const shouldDisplaySubscriptionStatus = isSuccessOrLaunchpad && isMonthlyPayment

    return (
      <div className="newPaybar-infoIn module">
        { !props.isSuccessScreen &&
          <NewPaybarTabs device="mobile" type={ props.type } product={ props.product } />
        }

        { !props.isSubscriptionScreen &&
          <NewPaybarTitle
            className="newPaybarTitle module device device__mobile"
            type={ props.type }
            screen={ props.screen }
            product={ props.product }
            productTitle={ getSchoolNameFromSubscription(props.subscription) } />
        }

        { props.isSuccessScreen &&
          <NewPaybarOverlay className="device device__mobile" />
        }

        <div className="newPaybarSchoolPricingAndInfo">
          { shouldDisplayPricing &&
            <NewPaybarSchoolPricing
              type={ props.type }
              prices={ props.prices }
              isMonthly={ props.paymentType === 'monthly' } />
          }
          { props.isPurchaseScreen &&
            <NewPaybarSchoolCaption caption={ this.caption }/>
          }
          { (props.isSuccessScreen || props.isSubscriptionScreen) &&
            <NewPaybarSchoolRequirements product={ props.product } />
          }
        </div>

        { isLaunchpad(props) &&
          <div className="newPaybarLaunchpadMobileWrapper module device device__mobile">
            <NewPaybarLaunchpad />
            <NewPaybarSchoolRequirements product={ props.product } />
          </div>
        }

        { props.isUserAuthenticated && shouldDisplaySubscriptionStatus &&
          <NewPaybarSubscriptionStatus className="device device__laptop device__desktop" />
        }

        { props.isPurchaseScreen &&
          <NewPaybarPayer payerRef={ props.payerRef } isCompact />
        }

        { !props.isSuccessScreen &&
          <NewPaybarPersonalForm
            subscriptionUrl={ props.subscriptionUrl }
            scrollableRef={ props.scrollableRef } />
        }

        { this.props.children }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    prices: newPaybar.prices,
    subscription: newPaybar.subscription,
    screen: newPaybar.screen,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    paymentType: newPaybar.paymentType,
    dateOfNoReturn: moscowifyLocal(new Date(newPaybar.dateOfNoReturn)),
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarSchool)
