const URLS = {
  book: '/books/license/',
  bookshelf: '/books/license/',
  school: '/school/license/',
  prep: '/school/license/',
  course: '/courses/license/',
  lecture: '/lectures/license/',
  collecture: '/lectures/license/',
}

module.exports = product => URLS[product]
