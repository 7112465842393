import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { detectPaybarNotice } from './variants'

import Notification from '../notification/notification'

import isNoticeTypeVisible from './isNoticeTypeVisible'

class NewPaybarNotification extends Component {
  get isVisible() {
    return isNoticeTypeVisible(this.props)
  }

  render(props) {
    if (!this.isVisible) return

    const className = cx('newPaybarNotice', `is__${props.noticeType}`, `device ${props.device}`)
    const NewPaybarNotice = detectPaybarNotice(props.noticeType)

    return (
      <div className={ className }>
        <Notification getRef={ this.props.getRef }>
          <NewPaybarNotice product={ props.product } />
        </Notification>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    type: newPaybar.type,
    screen: newPaybar.screen,
    hasSubscribedSelf: newPaybar.hasSubscribedSelf,
    userEmail: newPaybar.userEmail,
    noticeType: newPaybar.noticeType,
    disclaimer: newPaybar.disclaimer,
  }
}

export default connect(mapStateToProps)(NewPaybarNotification)
