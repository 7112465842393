import { Component } from 'preact'
import { connect } from 'preact-redux'

class NewPaybarButtonSave extends Component {
  get text() {
    const shouldDisplaySuccessText = this.props.hasUpdatedSubscription && !this.isCompanyFormChanged

    return I18n.t(`newPaybar.saveButtonText${shouldDisplaySuccessText ? 'Success' : ''}`)
  }

  get isDisabled() {
    return !this.props.isValid || !this.isCompanyFormChanged || this.props.isLoading
  }

  get isCompanyFormChanged() {
    return this.props.dirtyForms.includes('company')
  }

  render() {
    return (
      <span className="newPaybarButtonSave">
        <button
          className="newPaybarButtonSave-button button"
          onClick={ this.props.onSubscriptionUpdate }
          dangerouslySetInnerHTML={{ __html: this.text }}
          disabled={ this.isDisabled }
        >
        </button>
      </span>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    isLoading: newPaybar.isLoading,
    isValid: newPaybar.isValid,
    dirtyForms: newPaybar.dirtyForms,
    hasUpdatedSubscription: newPaybar.hasUpdatedSubscription,
  }
}

export default connect(mapStateToProps)(NewPaybarButtonSave)
