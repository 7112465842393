import Cookies from 'js-cookie'

import getUnconfirmedPurchases from 'products/getUnconfirmedPurchases'

export default class Purchase {
  constructor(props) {
    this.props = props
  }

  rememberUnconfirmed() {
    Cookies.set('email', this.props.userEmail)
    Cookies.set('paybarAccessToken', this.props.accessToken)

    const currentUnconfirmedPurchases = getUnconfirmedPurchases()
    const unconfirmedPurchases = currentUnconfirmedPurchases.concat([this.props.productId])
    Cookies.set('unconfirmedPurchases', JSON.stringify(unconfirmedPurchases))
  }

  forget() {
    Cookies.remove('email')
    Cookies.remove('paybarAccessToken')
    Cookies.remove('unconfirmedPurchases')
  }
}
