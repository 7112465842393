import { Component } from 'preact'
import { formatScore } from 'lib/school/classroom/scoreHelper'
import { NBSP } from 'lib/constants'
import { capitalize } from 'lib/textHelper'
import Tag from '../tag/tag'

export default class SchoolFacesDiploma extends Component {
  reduceTeamMembers(team, part) {
    const peoplesInline = this.props[`diploma${capitalize(part)}`]

    if (!peoplesInline) return team

    const peoples = peoplesInline
      .split(', ')
      .filter(student => student !== this.props.name)
      .map(student => student.replace(' ', NBSP))

    return [...team, ...peoples]
  }

  get teamMembers() {
    return ['designers', 'editors', 'managers'].reduce(this.reduceTeamMembers.bind(this), []).join(', ')
  }

  get artDirectorLine() {
    const artDirector = (this.props.diplomaArtDirector || '').replace(' ', NBSP)

    return `${I18n.t('schoolFaces.diploma.artDirector', { artDirector })}`
  }

  get teamLine() {
    return I18n.t('schoolFaces.diploma.team', { team: this.teamMembers })
  }

  render() {
    const { diplomaTitle: title, diplomaUrl: url, diplomaScore: score } = this.props

    return (
      <div className="schoolFacesDiploma">
        <p>
          { this.teamLine }<br/>
          { this.artDirectorLine }

          { !!title &&
            <span className="schoolFacesDiploma-project">
              <Tag type="diploma" text="Диплом" />
              <a href={ url }>{ title }</a><br/>
              { !!score && (NBSP + formatScore(score)) }
            </span>
          }
        </p>
      </div>
    )
  }
}
