import cx from 'classnames'
import getEmailsArrayFromString from './getEmailsArrayFromString'

import FormInput from '../form/formInput'
import TextareaAutosize from 'react-textarea-autosize'

const EMAIL_TEMPLATE = /\S+@\S+\.\S+/

export default class NewPaybarEmployeesTextarea extends FormInput {
  validate() {
    const allEmailsValid = this.validEmails.length === this.emails.length
    const inLimit = this.emails.length <= this.props.maxEmails
    const isValid = inLimit && allEmailsValid

    this.setState({ isValid })

    if (this.props.onValidityChange) this.props.onValidityChange(this.props.name, isValid)
  }

  dispatchResize() {
    document.dispatchEvent(new Event('appResize'))
  }

  get emails() {
    const emails = getEmailsArrayFromString(this.state.value)

    const notUser = email => email !== this.props.userEmail
    const isUnique = (email, index, array) => array.indexOf(email) === index

    return emails
      .filter(notUser)
      .filter(isUnique)
  }

  get validEmails() {
    return this.emails.filter(email => email.trim().match(EMAIL_TEMPLATE))
  }

  get counter() {
    const number = this.props.maxEmails - this.validEmails.length
    return String(number).replace('-', '−')
  }

  render() {
    const classnames = cx('newPaybarPersonalFieldset-input input', {
      is__invalid: !this.state.isValid && !this.props.silent,
      is__false: !this.state.isDirty,
    })

    return (
      <div className="newPaybarEmployeesTextarea">
        <TextareaAutosize
          className={ classnames }
          minRows={ 5 }
          maxRows={ 25 }
          { ...this.props }
          value={ this.state.value }
          onInput={ this.onInput }
          onHeightChange={ this.dispatchResize }
        />

        <span className="newPaybarEmployeesTextarea-counter" data-counter={ this.counter }></span>
      </div>
    )
  }
}
