export function setHeaderTemplate(templateParams) {
  return {
    type: 'SET_HEADER_TEMPLATE',
    ...templateParams,
  }
}

export function setHeaderTextColor(headerTextColor) {
  return {
    type: 'SET_HEADER_TEXT_COLOR',
    headerTextColor,
  }
}

export function setTitle(title) {
  return {
    type: 'SET_TITLE',
    title,
  }
}

export function setMainThink(mainThink) {
  return {
    type: 'SET_MAIN_THINK',
    mainThink,
  }
}

export function setKicker(kicker) {
  return {
    type: 'SET_KICKER',
    kicker,
  }
}

export function setQuestion(question) {
  return {
    type: 'SET_QUESTION',
    question,
  }
}

export function setQuestionerName(questionerName) {
  return {
    type: 'SET_QUESTIONER_NAME',
    questionerName,
  }
}

export function setSovietText(text) {
  return {
    type: 'SET_SOVIET_TEXT',
    text,
  }
}

export function setAuthor(authorParams) {
  return {
    type: 'SET_AUTHOR',
    ...authorParams,
  }
}

export function setType({ isImportant, isLecture }) {
  return {
    type: 'SET_TYPE',
    isImportant,
    isLecture,
  }
}

export function setCanPublish(canPublish) {
  return {
    type: 'SET_CAN_PUBLISH',
    canPublish,
  }
}

export function setPublishAt(publishAt) {
  return {
    type: 'SET_PUBLISH_AT',
    publishAt: (new Date(publishAt)).toISOString(),
  }
}

export function setTags(tags) {
  return {
    type: 'SET_TAGS',
    tags,
  }
}

export function setIsProcessing() {
  return {
    type: 'SET_IS_PROCESSING',
  }
}

export function setHasPublicationApprovalError() {
  return {
    type: 'SET_HAS_PUBLICATION_APPROVAL_ERROR',
  }
}

export function setHeaderImage(headerImageUrl, headerImageSize) {
  return {
    type: 'SET_HEADER_IMAGE',
    headerImageUrl,
    headerImageSize,
  }
}

export function setHeaderImageToNone() {
  return {
    type: 'SET_HEADER_IMAGE_TO_NONE',
  }
}

export function setHeaderImageUploadError() {
  return {
    type: 'SET_HEADER_IMAGE_UPLOAD_ERROR',
  }
}

export function toggleFilesPanelVisibility() {
  return { type: 'TOGGLE_FILES_PANEL_VISIBILITY' }
}

export function setFiles(files) {
  return {
    type: 'SET_FILES',
    files,
  }
}

export function setFilesAndOpenFilesPanel(files) {
  return {
    type: 'SET_FILES_AND_OPEN_FILES_PANEL',
    files,
  }
}

export function setFilesUploadError() {
  return {
    type: 'SET_FILES_UPLOAD_ERROR',
  }
}

export function setIsPublicationApproved(isPublicationApproved) {
  return {
    type: 'SET_PUBLICATION_APPROVED',
    isPublicationApproved,
  }
}
