{
  const CLASS_ACTIVE = 'is__active';

  class Curtain {
    constructor($el) {
      this.$el = $el;
      this.$rope = this.$el.find('.curtain-rope');
      this.$content = this.$el.find('.curtain-content');

      this.$rope.on('click', this.unveil.bind(this));
    }

    unveil(e) {
      e.preventDefault();

      this.$el.addClass(CLASS_ACTIVE);
    }
  }

  $('.curtain').each(function() {
    new Curtain($(this));
  });
}
