import { Component } from 'preact'
import cx from 'classnames'
import fetchCounters from 'lib/soviets/fetchCounters'

import SovietsSwitcherItem from './sovietsSwitcherItem'
import { Product } from '../product'

const ITEMS = [
  {
    name: 'released',
    link: '/soviet/',
  },
  {
    name: 'withNewComments',
    link: '/soviet/newcomments/',
    isStaff: true,
    isModerator: true,
  },
  {
    name: 'scheduled',
    link: '/soviet/scheduled/',
    isStaff: true,
  },
  {
    name: 'unanswered',
    link: '/soviet/questions/',
    isStaff: true,
  },
  {
    name: 'compilations',
    link: '/soviet/selected/',
  },
]

export default class SovietsSwitcher extends Component {
  constructor(props) {
    super(props)

    this.state.items = ITEMS
  }

  componentDidMount() {
    if (this.user.isStaff || this.user.isModerator) this.fetchCounters()
    if (this.props.customItems) this.applyCustomItems()
  }

  fetchCounters() {
    fetchCounters()
      .then(this.setCounters.bind(this))
  }

  setCounters(counters) {
    const countedItems = this.state.items.map(item => ({ ...item, count: counters[item.name] }))

    this.setState({ items: countedItems })
  }

  applyCustomItems() {
    const modifiedItems = this.state.items.map(item => {
      const customItem = this.props.customItems.find(custom => custom.name === item.name)

      return customItem ? { ...item, ...customItem } : item
    })

    this.setState({ items: modifiedItems })
  }

  get user() {
    return window.application.user || {}
  }

  get displayedItems() {
    const { isStaff = false, isModerator = false } = this.user

    const staffAllowed = item => item.isStaff === undefined ||
      item.isStaff === isStaff

    const moderatorsAllowed = item => item.isModerator === undefined ||
      item.isModerator === isModerator

    return this.state.items
      .filter(staffAllowed)
      .filter(moderatorsAllowed)
  }

  get currentItem() {
    return this.displayedItems.find(item => item.name === this.props.activeItem)
  }

  get currentItemName() {
    if (!this.currentItem) return null

    const { name, count } = this.currentItem

    let result = I18n.t(`soviet.switcher.${name}`)

    if (count > 0) result += ` <span class="product-menu-item-counter">${count}</span>`

    return result
  }

  render() {
    const { activeItem, isAside = true } = this.props
    const mobileProductClassNames = cx('is__small', { is__aside: isAside })

    return (
      <Product className={ mobileProductClassNames } name={ this.currentItemName }>
        { this.displayedItems.map(item => (
          <SovietsSwitcherItem
            item={ item }
            isActive={ item.name === activeItem }
            keepActiveItemClickable={ this.props.keepActiveItemClickable } />
        ))}
      </Product>
    )
  }
}
