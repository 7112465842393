const SchoolSpacerEnrollButton = (props) => {
  const enrollLink = `${props.schoolLink}&${props.isGift ? 'gift' : 'self'}`
  const buttonText = props.isGift ? 'Подарить' : 'Поступить'

  return (
    <div className="schoolSpacerEnrollButton-buttonWrapper">
      <a href={ enrollLink } className="button schoolSpacerEnrollButton-button">
        { buttonText }
      </a>
    </div>
  )
}

export default SchoolSpacerEnrollButton
