import { Component } from 'preact'

import getLicenseUrl from './helpers/getLicenseUrl'

import { Caption } from '../textNodes'

class NewPaybarFooterLinks extends Component {
  render(props) {
    return (
      <section className="newPaybarFooter-links">
        <Caption>
          <a href={ getLicenseUrl(props.product) }>
            { I18n.t(`newPaybar.links.${props.product}.license`) }
          </a>
        </Caption>
      </section>
    )
  }
}

export default NewPaybarFooterLinks
