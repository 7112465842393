const TARGET_WEEK_TOP_MARGIN = 50
const WEEK_HTML_CLASS = 'js__curriculumWeek'
const SCROLL_TYPES = { last: 'lastWeek', coord: 'coordinate' }

const localStorageId = () => `classroom-scroll-${window.classroom.eventId}`

function saveToLocalStore(scrollParams) {
  try {
    localStorage.setItem(localStorageId(), JSON.stringify(scrollParams))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to save scroll position: ', e)
  }
}

function getFromLocalStore() {
  let result = null
  try {
    result = localStorage.getItem(localStorageId())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to get scroll position: ', e)
  }
  return (result ? JSON.parse(result) : null)
}

const allWeeks = () => document.getElementsByClassName(WEEK_HTML_CLASS)

const elementTop = el => window.pageYOffset + el.getBoundingClientRect().top

function saveScroll() {
  const curriculumWeeks = allWeeks()
  const lastWeek = curriculumWeeks[curriculumWeeks.length - 1]
  const lastWeekTop = elementTop(lastWeek)
  const middleOfPage = window.scrollY + window.innerHeight / 2

  if (middleOfPage > lastWeekTop) {
    saveToLocalStore({
      type: SCROLL_TYPES.last,
      y: window.scrollY,
      weeksCount: curriculumWeeks.length,
    })
  } else {
    saveToLocalStore({ type: SCROLL_TYPES.coord, y: window.scrollY })
  }
}

function getInitialScrollPosition() {
  const scroll = getFromLocalStore()
  if (!scroll) return null

  let scrollTopPx = null
  const curriculumWeeks = allWeeks()
  const scrolledToLastWeek = scroll.type === SCROLL_TYPES.last
  const newWeekAdded = scroll.weeksCount < curriculumWeeks.length
  const scrollCoordinateIsValid = typeof scroll.y === 'number'
  if (scrolledToLastWeek && newWeekAdded) {
    const lastWeek = curriculumWeeks[curriculumWeeks.length - 1]
    const lastWeekTop = elementTop(lastWeek)
    scrollTopPx = lastWeekTop - TARGET_WEEK_TOP_MARGIN
  } else if (scrollCoordinateIsValid) {
    scrollTopPx = scroll.y
  }

  return scrollTopPx
}

function onClassroomPage() {
  const classroomWrapper = document.getElementsByClassName('js__classroom')[0]
  return document.contains(classroomWrapper)
}

function listenForScroll() {
  let saveTimeout = null
  window.addEventListener('scroll', function() {
    if (saveTimeout) clearTimeout(saveTimeout)
    saveTimeout = setTimeout(saveScroll, 100)
  })
}

function initiate() {
  if (!onClassroomPage()) return

  const scrollTop = getInitialScrollPosition()
  if (scrollTop) window.scrollTo(0, scrollTop)

  listenForScroll()
}

module.exports = { initiate }
