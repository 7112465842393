const MAX_TRIES = 5
const IMAGE_LOAD_TIMEOUT = 100

const toBlobUrl = (xhr) => window.URL.createObjectURL(xhr.response)
const revokeBlobUrl = (url) => window.URL.revokeObjectURL(url)

class LoadableImage {
  constructor($el, options = {}) {
    this.$el = $el
    this.$document = $(document)

    this.src = options.src || this.$el.data('nonretina-src') || this.$el.data('src')

    this.loadEvent = options.loadEvent || 'imageLoaded'
    this.isForcedToLoad = this.$el.closest('.fotorama').length > 0 || this.$el.is('.is__forcedToLoad')
    this.tries = 0

    this.calculate()

    if (/data:image/.test(this.src)) this._displayEmbedded()
  }

  calculate() {
    this.isLoadable = this.isForcedToLoad || !!this.$el[0].offsetParent
    this.top = this.$el.offset().top
    this.bottom = this.top + this.$el.outerHeight(true)
  }

  load() {
    if (this.isLoading || this.isLoaded) return

    this.isLoading = true

    this.xhr = new XMLHttpRequest()
    this.xhr.open('GET', this.src)
    this.xhr.responseType = 'blob'
    this.xhr.addEventListener('load', this._display.bind(this))
    this.xhr.addEventListener('error', this._handleLoadError.bind(this))
    this.xhr.send()
  }

  cancel() {
    this.isLoading = false
    if (this.xhr) this.xhr.abort()
  }

  distanceFrom(view) {
    if (this.isForcedToLoad) return 0

    if (this.bottom < view.top) {
      return view.top - this.bottom
    } else if (this.top > view.bottom) {
      return this.top - view.bottom
    }

    return 0
  }

  _display(e) {
    this.isLoading = false
    this.isLoaded = true

    const xhr = e.target
    this._displayImage(toBlobUrl(xhr))
  }

  _displayImage(src) {
    clearTimeout(this._displayTimeout)
    this._displayTimeout = setTimeout(() => this._displayFullyLoadedImage(src), IMAGE_LOAD_TIMEOUT)

    const img = new Image() // HACK: fix bg image flickering (blobs still have to be loaded)
    img.onload = () => this._displayFullyLoadedImage(src)
    img.src = src
  }

  _displayFullyLoadedImage(src) {
    clearTimeout(this._displayTimeout)
    if (this.isDisplayed) return

    const previousSrc = this.$el.data('last-src')

    const $img = this.$el.find('> .image-img')

    if ($img.length) {
      $img.attr('src', src)
    } else {
      this.$el.css('background-image', `url(${src})`)
    }

    this.$el.addClass('is__loaded')
    this.isDisplayed = true
    this.$document.trigger(this.loadEvent, this)

    this.$el.data('last-src', src)

    revokeBlobUrl(previousSrc)
  }

  _displayEmbedded() {
    this.isLoaded = true
    this._displayFullyLoadedImage(this.src)
  }

  _handleLoadError() {
    this.tries += 1
    this.isLoading = false
    this.isLoaded = this.tries > MAX_TRIES

    if (this.isLoaded) this.$document.trigger(this.loadEvent, this)
  }
}

module.exports = LoadableImage
