import { Component } from 'preact'
import { connect } from 'preact-redux'

import { setMainThink } from './sovietActions'

class SovietMainThinkInput extends Component {
  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
  }

  handleInput(e) {
    this.props.setMainThink(e.target.value)
  }

  render() {
    return (
      <input
        className="input is__padded is__medium is__fullWidth sovietMainThink-text"
        placeholder="Главная мысль"
        value={ this.props.mainThink }
        onInput={ this.handleInput } />
    )
  }
}

const mapStateToProps = ({ soviet }) => { return { mainThink: soviet.mainThink } }
const mapDispatchToProps = { setMainThink }

export default connect(mapStateToProps, mapDispatchToProps)(SovietMainThinkInput)
