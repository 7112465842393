import cx from 'classnames'

export default ({ className, href, title, key, isDisabled = false }) => (
  <li className={ cx('dropdown-item', className, { is__disabled: isDisabled }) } key={ key }>
    <a
      className="dropdown-item-link"
      href={ href }
      dangerouslySetInnerHTML={ { __html: title } } />
  </li>
)
