import cx from 'classnames'

import NewPaybarLaunchpadItem from '../newPaybarLaunchpadItem'
import getAccessLink from 'lib/getAccessLink'
import { Caption } from '../../textNodes'

export default class NewPaybarLaunchpadItemLecture extends NewPaybarLaunchpadItem {
  get classNames() {
    return cx('newPaybarLaunchpadItemLecture', {
      is__locked: this.isLocked,
      is__loaded: this.state.isLoaded,
    })
  }

  get accessLink() {
    const productId = 'lecture-' + this.props.lectureKey

    return this.isLocked ? null : getAccessLink({ product: 'lecture', productId })
  }

  get src() {
    return this.props.cover
  }

  render() {
    return (
      <a className={ this.classNames } href={ this.accessLink }>
        <img className="newPaybarLaunchpadItemLecture-img" src={ this.src } onLoad={ this.setLoaded } ref={ el => this.$image = el } />

        { this.props.title && this.props.authorInstrumentalCase &&
          <Caption className="newPaybarLaunchpadItemLecture-title">
            { this.props.title + ' c ' + this.props.authorInstrumentalCase }
          </Caption>
        }
      </a>
    )
  }
}
