let INTERSECTION_OBSERVER_OPTIONS = { threshold: 1.0, rootMargin: '0px' }
const CLASS_PLAYED = 'is__played'

const customThreshold = window.CUSTOM_INTERSECTION_OBSERVER_THRESHOLD || 1.0

INTERSECTION_OBSERVER_OPTIONS.threshold = customThreshold

class LecturesPreview {
  constructor($wrapper) {
    this.$wrapper = $wrapper
    this.$previewVideo = $wrapper.find('.video.lecturePreviewVideo video')

    this.observer = new IntersectionObserver(this.buildObserver, INTERSECTION_OBSERVER_OPTIONS)

    this.startObserving()
  }

  buildObserver(entries) {
    entries.forEach((entry) => {
      const video = entry.target
      const videoWrapper = video.closest('.video')

      if (entry.isIntersecting) {
        video.play()

        if (video.readyState === 4) $(videoWrapper).addClass(CLASS_PLAYED)
      }

      if (!entry.isIntersecting) {
        $(videoWrapper).removeClass(CLASS_PLAYED)
        video.pause()
      }
    })
  }

  startObserving() {
    if (this.$previewVideo.length) {
      this.$previewVideo.get().forEach(video => this.observer.observe(video))
    }
  }
}

$('.js__lecturesPreview').each(function() {
  new LecturesPreview($(this))
})
