import BookSystemRequirements from './bookSystemRequirements'

const { productSubscription = {} } = window
const existingSubscription = productSubscription.subscription || {}
const product = existingSubscription.product || ''

$('.js__bookSystemRequirements').each(function() {
  const books = (window.productDetails || {}).books || []

  const props = { ...$(this).data('options'), books, product }

  $(this).reactify(BookSystemRequirements, props)
})
