import { Component } from 'preact'

import NewPaybarFieldsetHeading from './newPaybarFieldsetHeading'
import { Caption, TextNode } from '../textNodes'
import { FormInput } from '../form'

export default class NewPaybarPersonalFieldsetCompanySection extends Component {
  render() {
    return (
      <div class="newPaybarPersonalFieldset-section">
        <NewPaybarFieldsetHeading text={ I18n.t('newPaybar.companyForm.payer') } />

        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.companyForm.title') }
          </TextNode>
          <FormInput
            className="newPaybarPersonalFieldset-input"
            name="companyTitle"
            type="text"
            autoComplete="organization"
            required
            onInput={ this.props.onInput }
            onValidityChange={ this.props.onInputValidityChange }
            initialValue={ this.props.rememberedFields.companyTitle }
            ref={ ref => this.props.fieldRefs.companyTitle = ref } />
        </label>

        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.companyForm.CEO') }
          </TextNode>
          <FormInput
            className="newPaybarPersonalFieldset-input"
            name="companyCEO"
            type="text"
            placeholder={ I18n.t('newPaybar.companyForm.CEOPlaceholder') }
            autoComplete="off"
            required
            onInput={ this.props.onInput }
            onValidityChange={ this.props.onInputValidityChange }
            initialValue={ this.props.rememberedFields.companyCEO }
            ref={ ref => this.props.fieldRefs.companyCEO = ref }
          />
        </label>

        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.companyForm.email') }
          </TextNode>
          <div className="newPaybarPersonalFieldset-inputWithCaption">
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="companyEmail"
              type="email"
              autoComplete="off"
              required
              onInput={ this.props.onInput }
              onValidityChange={ this.props.onInputValidityChange }
              initialValue={ this.props.rememberedFields.companyEmail }
              ref={ ref => this.props.fieldRefs.companyEmail = ref } />

            <Caption html={ I18n.t('newPaybar.companyForm.emailCaption') } />
          </div>
        </label>
      </div>
    )
  }
}
