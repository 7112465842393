import { Component } from 'preact'

export default class CommentFormAuthorHiddenField extends Component {
  shouldComponentUpdate() {
    return false
  }

  render({ name, value }) {
    return (
      <input type="hidden" name={name} value={ value } />
    )
  }
}
