{
  const BaseModule = require('_base/base')

  const CLASS_ACTIVE = 'is__active'
  const CLASS_SCROLLABLE = 'is__scrollable'
  const CLASS_LOADED = 'is__loaded'
  const CLASS_NO_DOTS = 'is__noDots'
  const CLASS_NO_CLICK = 'is__noClick'
  const MIN_SCROLL_DISTANCE = 300

  class Fotorama extends BaseModule {
    postInit() {
      this.$shaft = this.$el.find('.fotoramaNew-shaft')
      this.$slides = this.$shaft.children()

      this.length = this.$slides.length
      this.slides = this.$slides.get()
      this.isScrollable = this.$el.hasClass(CLASS_SCROLLABLE)
      this.hasDots = !this.$el.hasClass(CLASS_NO_DOTS)
      this.isClickable = !this.$el.hasClass(CLASS_NO_CLICK)
      this.currentSlideIndex = 0
      this.scrollSpeed = this.$el.data('speed') || 1

      if (this.hasDots) this.buildDots()

      this.bindEvents()
      this.showCurrentSlide()

      if (this.isScrollable) {
        this.calculateScrollTriggers()
        this.handleScrollPosition()
        this.waitTilAllSlidesLoaded()
      }
    }

    buildDots() {
      const $dotsWrapper = $('<div class="fotoramaNew-dots"/>')

      for (let i = 0; i < this.$slides.length; i += 1) {
        const $dot = $('<div class="fotoramaNew-dot"/>')
        $dotsWrapper.append($dot)
      }

      this.$el.append($dotsWrapper)
      this.$dots = this.$el.find('.fotoramaNew-dot')
      this.$dots.on('click', this.handleDotClick.bind(this))
    }

    bindEvents() {
      if (this.isScrollable) {
        $(document).on('allSlidesLoaded', this.handleScrollPosition.bind(this))
        $(document).on('appScroll', this.handleScrollPosition.bind(this))
        $(document).on('appResize', () => {
          this.calculateScrollTriggers()
          this.handleScrollPosition()
        })
      }

      if (this.isClickable) this.$slides.on('click', this.nextSlide.bind(this))
    }

    handleDotClick(e) {
      const $el = $(e.target)
      this.currentSlideIndex = this.$dots.index($el)

      this.showCurrentSlide()
    }

    nextSlide() {
      this.currentSlideIndex += 1

      if (this.currentSlideIndex > this.$slides.length - 1) {
        this.currentSlideIndex = 0
      }

      this.showCurrentSlide()
    }

    showCurrentSlide() {
      this.$slides.removeClass(CLASS_ACTIVE)
      this.$slides.eq(this.currentSlideIndex).prevAll().addBack().addClass(CLASS_ACTIVE)

      if (this.hasDots) {
        this.$dots.removeClass(CLASS_ACTIVE)
        this.$dots.eq(this.currentSlideIndex).addClass(CLASS_ACTIVE)
      }
    }

    calculateScrollTriggers() {
      this.scrollDistance = (window.innerHeight - this.$el.height()) / this.scrollSpeed

      this.scrollDistance = Math.max(this.scrollDistance, MIN_SCROLL_DISTANCE)

      this.startTrigger = Math.max(this.$el.offset().top - this.scrollDistance, 0)
      const scrollStep = this.scrollDistance / this.length

      this.triggers = [...Array(this.length)].map((_, i) => scrollStep * i + this.startTrigger)
    }

    handleScrollPosition(_, scrollTop = window.application.currentScrollPosition) {
      if (!this.isAllSlidesLoaded) return

      this.scrollTop = scrollTop

      this.triggers.forEach((trigger, i) => {
        if (this.scrollTop >= trigger) this.currentSlideIndex = i
      })

      if (this.scrollTop > this.startTrigger + this.scrollDistance) {
        this.currentSlideIndex = this.$slides.length - 1
      }
      if (this.scrollTop < this.startTrigger) this.currentSlideIndex = 0

      this.showCurrentSlide()
      this.showProgress()
    }

    showProgress() {
      this.$el.attr('data-scroll-progress', this.normalizedProgress)
    }

    get isAllSlidesLoaded() {
      return this.slides.every(slide => slide.classList.contains(CLASS_LOADED))
    }

    get normalizedProgress() {
      return Math.max(0, Math.min(1, (this.scrollTop - this.startTrigger) / this.scrollDistance))
    }

    waitTilAllSlidesLoaded() {
      this.slidesLoadingTimer = setInterval(() => this.checkSlidesLoading(), 100)
    }

    checkSlidesLoading() {
      if (!this.isAllSlidesLoaded) return

      $(document).trigger('allSlidesLoaded')
      clearInterval(this.slidesLoadingTimer)
    }
  }

  $('.js__fotoramaNew').each(function() {
    new Fotorama($(this))
  })
}
