import { Component } from 'preact'
import { connect } from 'preact-redux'
import TextareaAutosize from 'react-textarea-autosize'

import { setLinks, setDescription } from './sovietCompilationActions'
import { extractSovietKeys } from 'lib/soviets/compilation'
import SovietCompilationFormattedDescription from './sovietCompilationFormattedDescription'

class SovietCompilationDescription extends Component {
  constructor(props) {
    super(props)

    this.state = { value: '' }

    this.handleInput = this.handleInput.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.rebuildLinks = this.rebuildLinks.bind(this)
  }

  rebuildLinks(value) {
    this.props.setLinks(extractSovietKeys(value))
  }

  handleInput(event) {
    const description = event.target.value

    this.props.setDescription(description)

    clearTimeout(this.setLinksTimeout)
    this.setLinksTimeout = setTimeout(this.rebuildLinks, 300, description)
  }

  handleFocus() {
    this.setState({ focus: true })
  }

  handleBlur() {
    this.setState({ focus: false })
  }

  render(props) {
    if (props.isReadOnly) return (<SovietCompilationFormattedDescription { ...props } />)

    return (
      <div className="compilationLinks">
        <label htmlFor="compilationLinks">Описание подборки и ссылки на советы</label>
        <TextareaAutosize
          id="compilationLinks"
          className={`compilationLinks-text ${this.state.focus ? 'is__focused' : ''}`}
          placeholder={ props.placeholder }
          value={ props.description }
          onInput={ this.handleInput }
          onFocus={ this.handleFocus }
          onBlur={ this.handleBlur } />
      </div>
    )
  }
}

const mapStateToProps = ({ sovietCompilation }) => {
  return {
    links: sovietCompilation.links,
    soviets: sovietCompilation.soviets,
    description: sovietCompilation.description,
    descriptionHtml: sovietCompilation.descriptionHtml,
    isReadOnly: sovietCompilation.isReadOnly,
  }
}

const mapDispatchToProps = {
  setLinks,
  setDescription,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCompilationDescription)
