const countRemainingChars = (props) => props.maxlength - props.text.length

const RemainingCharsCounter = (props) => {
  const charsRemaining = countRemainingChars(props)

  return (
    <div className={`sovietCompilation-counter ${charsRemaining < 0 ? 'is__exceeded' : ''}`}>{ charsRemaining }</div>
  )
}

export default RemainingCharsCounter
