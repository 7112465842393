import { Component } from 'preact'
import MenuItem from './menuItem'

export default class MyCourseMenuItem extends Component {
  constructor(props) {
    super(props)

    this.state = { course: null }
  }

  get course() {
    return this.state.course
  }

  get title() {
    const { dates } = this.course
    return I18n.t('userPopup.myCourse', { dates })
  }

  get href() {
    return `/courses/${this.course.key}/classroom/`
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.fetchMyCourse()
        .then(res => this.setState({ course: res }))
    }
  }

  fetchMyCourse() {
    return fetch('/api/courses/my/', { credentials: 'include' })
      .then(res => res.json())
      .catch(() => null)
  }

  render() {
    const { course } = this.state
    const className = String(this.props.className) + ' is__highlighted'

    if (!course) return null

    return (
      <MenuItem { ...this.props } title={ this.title } href={ this.href } className={ className } />
    )
  }
}
