import { Component } from 'preact'
import { pluralize } from 'lib/textHelper'
import isBookshelfAll from 'lib/isBookshelfAll'

import ShelfPrices from './shelfPrices'

export default class BookshelfPricing extends Component {
  get isBookshelfAll() {
    return isBookshelfAll(this.props.productId)
  }

  get isApplicableForDiscount() {
    return this.props.prices.isApplicableForDiscount && !this.isUpgrade
  }

  get isApplicableForSubscriptionDiscount() {
    return this.hasDifferentSubscriptionPrices && !this.isUpgrade
  }

  get hasDifferentSubscriptionPrices() {
    const { subscription, previousSubscription } = this.props.prices

    return !!previousSubscription && subscription !== previousSubscription
  }

  get isUpgrade() {
    return !!this.props.prices.specialOffer
  }

  get renewalPeriod() {
    return this.props.prices.renewalPeriod
  }

  get pricesCaption() {
    let caption = I18n.t(`books.priceCaption_${this.renewalPeriod}`)

    if (!this.isUpgrade) {
      caption += `,<br/>${I18n.t('books.priceCaptionRenewsAutomatically')}`
    }

    return caption
  }

  get pricesUpgradeCaption() {
    const captionType = this.isBookshelfAll ? 'captionUpgrade' : 'captionSpecial'

    return I18n.t(`books.bookshelf.subscriptionFooter.${captionType}`)
  }

  get oneTimePriceLabel() {
    const { prices } = this.props
    const suffix = this.isUpgrade ? 'Discount' : ''
    let label = I18n.t(`subscription.prices.defaultLabel_${this.renewalPeriod}${suffix}`)

    if (this.isApplicableForDiscount && prices.previousOneTime !== prices.oneTime) {
      label += ` <s>${ prices.previousOneTime }</s>`
    }

    return label
  }

  get subscriptionPriceLabel() {
    let label = I18n.t(`subscription.prices.subscriptionLabel_${this.renewalPeriod}`)

    if (this.isApplicableForSubscriptionDiscount) {
      label += ` <s>${ this.props.prices.previousSubscription }</s>`
    }

    return label
  }

  get oneTimeUnits() {
    const suffix = this.isUpgrade ? 'Discount' : ''
    return I18n.t(`subscription.defaultUnits_${this.renewalPeriod}${suffix}`)
  }

  get oneTimePrice() {
    const { prices } = this.props
    const shouldDisplayOneTimePrice = prices.renewalPeriod >= 12

    if (shouldDisplayOneTimePrice || this.isUpgrade) return prices.oneTime

    return prices.default
  }

  get specialOfferTitle() {
    const booksCount = this.props.prices.specialOffer.ownedBooks.length
    const pluralizedBookCount = pluralize(booksCount,
      'отдельную книгу',
      'отдельные книги',
      'отдельных книг')

    if (this.isBookshelfAll) return I18n.t('books.bookshelf.bookshelfAllspecialOfferTitle')

    return I18n.t('books.bookshelf.specialOfferTitle', { booksCount: booksCount, pluralizedBookCount: pluralizedBookCount })
  }

  get upgradeOneTimePriceLabel() {
    const { renewalPeriod, previousOneTime } = this.props.prices

    if (this.isBookshelfAll) return I18n.t(`subscription.prices.defaultLabel_${renewalPeriod}`) + ` <s class="previousOneTimeValue">${previousOneTime}</s>`

    return I18n.t('subscription.prices.upgradeLabel')
  }

  get updateSubscriptionPriceLabel() {
    if (this.isBookshelfAll) return I18n.t(`subscription.prices.subscriptionLabel_${this.props.prices.renewalPeriod}`)

    return I18n.t('subscription.prices.upgradeSubscriptionLabel')
  }

  render() {
    const { prices } = this.props
    return (
      <div>
        <ShelfPrices
          title={ I18n.t('books.bookshelf.offerTitle') }
          oneTimePriceLabel={ this.oneTimePriceLabel }
          oneTimePrice={ this.isUpgrade ? prices.previousOneTime : this.oneTimePrice }
          oneTimePriceUnits={ this.oneTimeUnits }
          subscriptionPriceUnits={ I18n.t(`subscription.subscriptionUnits_${this.renewalPeriod}`) }
          subscriptionPriceLabel={ this.subscriptionPriceLabel }
          subscriptionPrice={this.isUpgrade ? prices.previousSubscription : prices.subscription }
          isStrikedOut={ this.isUpgrade }
          smallUnits={ this.props.smallUnits }
          hasCaption={ this.props.hasCaption }
          omitHeading={ this.props.omitHeading }
          isApplicableForDiscount={ this.isApplicableForDiscount }
          isApplicableForSubscriptionDiscount={ this.isApplicableForSubscriptionDiscount }
          captionText={ this.pricesCaption } />

        { this.isUpgrade &&
          <ShelfPrices
            title={ this.specialOfferTitle }
            oneTimePriceLabel={ this.upgradeOneTimePriceLabel }
            oneTimePrice={ prices.specialOffer.oneTime }
            oneTimePriceUnits={ this.oneTimeUnits }
            subscriptionPriceUnits={ I18n.t(`subscription.subscriptionUnits_${this.renewalPeriod}`) }
            subscriptionPriceLabel={ this.updateSubscriptionPriceLabel }
            subscriptionPrice={ prices.subscription }
            highlighted={ true }
            highlightedSubscriptionPrice={ this.hasDifferentSubscriptionPrices }
            smallUnits={ this.props.smallUnits }
            hasCaption={ this.props.hasCaption }
            omitHeading={ this.props.omitHeading }
            captionText={ this.pricesUpgradeCaption } />
        }
      </div>
    )
  }
}
