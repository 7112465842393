import { Component } from 'preact'

import cx from 'classnames'
import { Caption } from '../../textNodes'
import NewPaybarDevicesIcons from './newPaybarDevicesIcons'

export default class NewPaybarDevices extends Component {
  get isBookshelf() {
    return this.props.product === 'bookshelf'
  }

  get bookshelfCaptionText() {
    return I18n.t(`newPaybar.devices.bookshelf.hasMobileVersion.${ this.props.howManyBooksHaveMobileVersion }`)
  }

  get captionText() {
    const { hasMobileVersion, product } = this.props
    const { isBookshelf, bookshelfCaptionText } = this
    const version = hasMobileVersion ? 'hasMobileVersion' : 'default'

    return isBookshelf ? bookshelfCaptionText : I18n.t(`newPaybar.devices.${ product }.${ version }`)
  }

  render() {
    const className = cx('newPaybarDevices', { has__mobileVersion: this.props.hasMobileVersion })

    return (
      <div className={ className }>
        <NewPaybarDevicesIcons
          className="newPaybarDevices-icon"
          hasMobileVersion={ this.props.hasMobileVersion }
        />
        <div className="newPaybarDevices-text">
          <Caption html={ this.captionText } />
        </div>
      </div>
    )
  }
}
