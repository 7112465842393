const isOnMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  .test(navigator.userAgent)
const isOnIOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/)
  && navigator.userAgent.match(/AppleWebKit/)
const orientation = () => window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'

class MobileTrueHeight {
  constructor() {
    this.savedHeight = window.innerHeight
    this.savedOrientation = orientation()
    this.check100vh = this.check100vh.bind(this)
    this.schedule100vhCheck = this.schedule100vhCheck.bind(this)

    this.enable()
  }

  schedule100vhCheck() {
    window.removeEventListener('touchmove', this.schedule100vhCheck)

    window.addEventListener('touchend', this.check100vh)
  }

  check100vh() {
    const currentHeight = window.innerHeight
    const currentOrientation = orientation()

    if (this.savedOrientation !== currentOrientation) {
      this.savedHeight = currentHeight
      this.savedOrientation = currentOrientation

      return
    }

    if (this.savedHeight < currentHeight) {
      this.setTrueHeight(currentHeight, currentOrientation)
      this.savedHeight = currentHeight
    } else {
      this.disable()
    }
  }

  setTrueHeight(currentHeight, currentOrientation) {
    try {
      localStorage.setItem(`${currentOrientation}True100vh`, currentHeight)
    } catch (e) {}
  }

  enable() {
    if (isOnMobileDevice && !isOnIOS) window.addEventListener('touchmove', this.schedule100vhCheck)
  }

  disable() {
    window.removeEventListener('touchmove', this.schedule100vhCheck)
    window.removeEventListener('touchend', this.check100vh)
  }
}

module.exports = MobileTrueHeight
