const RU = {
  BIRMAN_MONTHS: ['янв', 'фев', 'мар', 'апр', 'мая', 'июня', 'июля', 'авг', 'сен', 'окт', 'ноя', 'дек'],
  BIRMAN_IN_MONTHS: ['янв', 'фев', 'мар', 'апр', 'мае', 'июне', 'июле', 'авг', 'сен', 'окт', 'ноя', 'дек'],
  FULL_MONTHS: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
  FULL_IN_MONTHS: ['январе', 'феврале', 'марте', 'апреле', 'мае', 'июне', 'июле', 'августе', 'сентябре', 'октябре', 'ноябре', 'декабре'],
  WEEK_DAYS: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
  SHORT_WEEK_DAYS: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
}

const EN = {
  BIRMAN_MONTHS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  BIRMAN_IN_MONTHS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  FULL_MONTHS: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
  FULL_IN_MONTHS: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
  WEEK_DAYS: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
  SHORT_WEEK_DAYS: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
}

module.exports = (typeof I18n !== 'undefined' && I18n.locale === 'en') ? EN : RU
