import { Component } from 'preact'
import cx from 'classnames'

{
  class FontTester extends Component {
    constructor(props) {
      super(props)

      this.state = {
        text: props.placeholder,
        isFocused: false,
      }

      this.handleFocus = this.handleFocus.bind(this)
      this.handleBlur = this.handleBlur.bind(this)
      this.handleInput = this.handleInput.bind(this)
    }

    handleFocus() {
      this.setState({ isFocused: true })
    }

    handleBlur() {
      this.setState({ isFocused: false })
    }

    handleInput(event) {
      this.setState({ text: event.target.value })
    }

    render(props) {
      const testerClasses = cx(
        'fontTester-wrapper',
        { is__focused: this.state.isFocused }
      )

      return (
        <div className={ testerClasses }>
          <div className="fontTester-labelWrapper">
            <div className="fontTester-label">{ props.label }</div>
          </div>
          <input
            type="text"
            className={ `fontTester-field ${props.fontClass}` }
            value={ this.state.text }
            onFocus={ this.handleFocus }
            onBlur={ this.handleBlur }
            onInput={ this.handleInput }
            />
        </div>
      )
    }
  }

  $('.js__fontTester').each(function() {
    $(this).reactify(FontTester, $(this).data())
  })
}
