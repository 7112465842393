import cx from 'classnames'

import { MobileScreenshot, ImacScreenshot } from '../screenshot'

const LectureOnDevices = (props) => {
  const classes = cx(
    'lectureOnDevices',
    'no-u',
    {
      hasOnlyDesktop: props.hasOnlyDesktop,
      is__withMonitorIndent: props.withMonitorIndent,
    },
    props.className
  )

  return (
    <a className={ classes } href={ props.href } >
      <div className="lectureOnDevices-in">
        <ImacScreenshot className="lectureOnDevices-desktop" src={ props.cover } />

        { !props.hasOnlyDesktop &&
          <MobileScreenshot className="lectureOnDevices-tablet" src={ props.cover } />
        }

        { !props.hasOnlyDesktop &&
          <MobileScreenshot className="lectureOnDevices-mobile" src={ props.cover } />
        }
      </div>
    </a>
  )
}

export default LectureOnDevices
