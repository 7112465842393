import cx from 'classnames'

import { PhoneIcon } from '../icons'

const BadgePhone = (props) => {
  const classes = cx('badgePhone', { is__captioned: props.captioned })

  return (
    <div className={ classes }>
      <PhoneIcon />
      { props.captioned &&
        <div className="badgePhone-caption">
          { I18n.t('books.hasMobileVersionSticker') }
        </div>
      }
    </div>
  )
}

export default BadgePhone
