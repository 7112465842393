import TaskResultMark from './taskResultMark'

$(document).on('appReady', () => {
  $('.js__taskResultMark').each(function() {
    const id = $(this).data('id')
    const result = window.task.results.filter(_result => _result.id === id)[0]

    $(this).reactify(TaskResultMark, {
      score: result.score,
      average: result.average,
      userParts: result.user_parts,
      shouldScore: result.should_score,
      abilities: window.task.abilities,
      userId: window.task.credentials.id,
      userIsStaff: window.task.userIsStaff,
      scorableMarkParts: window.task.mark.scorable_parts,
      bonusMarkParts: window.task.mark.bonus_parts,
      lockedMarkParts: window.task.mark.locked_parts,
      apiUrl: `${window.task.apiUrlBase}/users/${result.user_id}/mark`,
    })
  })
})
