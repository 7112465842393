const RECAPTCHA_KEY = '6LeTUVgeAAAAABo9OnX6IL8_8lEU3SCPtuBgwYAP'

const initRecaptcha = () => {
  if (!window.hasRecaptcha) $(`<script type="text/javascript" src="//www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}"></script>`).appendTo(document.body);
  window.hasRecaptcha = true
}

const getRecaptcha = () => {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(RECAPTCHA_KEY, { action: 'form_submission' })
        .then(resolve)
    })
  })
}

module.exports = {
  RECAPTCHA_KEY,
  initRecaptcha,
  getRecaptcha
}
