{
  const CLASS_ACTIVE = 'is__active'
  const CLASS_OPENED = 'is__opened'

  class SchoolFeedBackList {
    constructor($box) {
      this.$box = $box
      this.$label = this.$box.find('.schoolFeedbackList-label span')
      this.$datesBox = this.$box.find('.schoolFeedbackList-dates')
      this.$dates = this.$datesBox.find('a')
      this.$lists = this.$box.find('.schoolFeedbackList-list')

      this._bindEvents()
    }

    _bindEvents() {
      const schoolFeedBackList = this

      this.$label.on('click', (e) => {
        e.stopPropagation()

        this.$box.toggleClass(CLASS_OPENED)
      })

      $(document).on('click', () => {
        this.$box.removeClass(CLASS_OPENED)
      })

      this.$datesBox.on('click', (e) => {
        e.stopPropagation()
      })

      this.$dates.on('click', function(e) {
        e.preventDefault()

        const $date = $(this)
        const $target = $($date.attr('href'))

        schoolFeedBackList.$dates.removeClass(CLASS_ACTIVE)
        $date.addClass(CLASS_ACTIVE)

        schoolFeedBackList.$lists.removeClass(CLASS_ACTIVE)
        $target.addClass(CLASS_ACTIVE)

        schoolFeedBackList.$box.removeClass(CLASS_OPENED)
      })
    }
  }

  $('.js__schoolFeedbackList').each(function() {
    new SchoolFeedBackList($(this))
  })
}
