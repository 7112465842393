import { connect } from 'preact-redux'
import cx from 'classnames'
import { ExtendableDropdown } from '../form'

import { setType } from './sovietActions'

class SovietTypeSelector extends ExtendableDropdown {
  constructor(props) {
    super(props)

    this.wrapperClassName = 'sovietTypeSelector'

    this.stopClick = this.stopClick.bind(this)
    this.setImportant = this.setImportant.bind(this)
    this.setLecture = this.setLecture.bind(this)
  }

  stopClick(e) {
    e.stopPropagation()
  }

  setImportant() {
    this.props.setType({
      isLecture: this.props.isLecture,
      isImportant: !this.props.isImportant,
    })
  }

  setLecture() {
    this.props.setType({
      isLecture: !this.props.isLecture,
      isImportant: this.props.isImportant,
    })
  }

  get sovietType() {
    let type = 'Обычный совет'

    if (this.props.isImportant) type = 'Важный совет'
    if (this.props.isLecture) type = 'Лекция'
    if (this.props.isImportant && this.props.isLecture) type = 'Важный совет, лекция'

    return type
  }

  get currentItem() {
    const currentClasses = cx(
      'sovietTypeSelector-current',
      { is__bold: this.props.isImportant }
    )

    return (
      <div className={ currentClasses }>{ this.sovietType }</div>
    )
  }

  get dropdownItems() {
    return [
      (<label onClick={ this.stopClick }>
        <input
          type="checkbox"
          onChange={ this.setImportant }
          { ...{ checked: this.props.isImportant } } />
        Важный совет
      </label>),
      (<label onClick={ this.stopClick }>
        <input
          type="checkbox"
          onChange={ this.setLecture }
          { ...{ checked: this.props.isLecture } } />
        Лекция
      </label>),
    ]
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    isImportant: soviet.isImportant,
    isLecture: soviet.isLecture,
  }
}

const mapDispatchToProps = {
  setType,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietTypeSelector)
