import { Component } from 'preact'
import cx from 'classnames'

const EMAIL_TEMPLATE = /\S+@\S+\.\S+/

export default class InputBase extends Component {
  constructor(props) {
    super(props)
    const { isValid, value, initialValue } = props

    this.state = {
      value: value || initialValue || null,
      isValid: (isValid === undefined) || isValid,
    }

    this.bindRef = this.bindRef.bind(this)
    this.onInput = this.onInput.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    if (this.props.initialValue !== null && this.props.initialValue !== undefined) {
      this.validate()
    }
  }

  componentWillReceiveProps({ value, name }) {
    // HACK: При смене имени инпута обязательно перезаписываем значение
    if (this.props.name !== name) {
      this.setState({ value: value || '' }, this.validate)
    }

    if (value === null || value === undefined) return

    this.setState({ value }, this.validate)
  }

  onInput(e) {
    this.setState({
      value: e.target.value,
      isValid: true,
      isDirty: true,
    }, this.validateAndRunCallbacks)
  }

  validateAndRunCallbacks() {
    this.validate()

    const handleInput = this.props.onInput || this.props.onChange
    if (handleInput) handleInput({ ...this.props, ...this.state })

    return true
  }

  validate() {
    const { name, required, type, validate, onValidityChange } = this.props
    const { value } = this.state

    let isValid = true

    if (required && !this.hasValue) isValid = false
    if (type === 'email' && this.hasValue && !value.match(EMAIL_TEMPLATE)) isValid = false
    if (validate) isValid = validate(value)

    this.setState({ isValid })
    if (onValidityChange) onValidityChange(name, isValid)
  }

  get hasValue() {
    const { value } = this.state
    const isString = typeof value === 'string'

    return isString ? value.trim() : value
  }

  bindRef(el) {
    this.el = el
  }

  resetState() {
    this.setState({
      isValid: true,
      isDirty: false,
    })
  }

  getClassNames(inputClass) {
    return cx(
      inputClass,
      this.props.className,
      {
        is__invalid: !this.state.isValid && !this.props.silent,
        is__false: !this.state.isDirty,
      }
    )
  }
}
