const BaseModule = require('_base/base')

const CLASS_HALFFOLD = 'is__halffold'

class Spread extends BaseModule {
  get currentScrollPosition() {
    return window.application.currentScrollPosition
  }

  preInit() {
    this.$document = $(document)
  }

  preCalculate() {
    this.setTriggers()
    this.bindEvents()
  }

  preRender() {
    this.reactToCurrentScrollPosition()
  }

  setTriggers() {
    this.triggers = { hold: this.$el.offset().top }
  }

  bindEvents() {
    this.$document.on('appScroll', this.reactToCurrentScrollPosition.bind(this))
  }

  reactToCurrentScrollPosition() {
    this.markAsHalffoldIfNecessary()
  }

  markAsHalffoldIfNecessary() {
    this.$el.toggleClass(CLASS_HALFFOLD, this.currentScrollPosition >= this.triggers.hold)
  }
}

$('.spread').each(function() {
  new Spread($(this))
})
