import { combineReducers } from 'redux'

const schoolRating = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_STUDENT':
      return {
        ...state,
        activeStudentUid: action.studentUid,
      }

    case 'UNSET_ACTIVE_STUDENT':
      return {
        ...state,
        activeStudentUid: null,
      }

    case 'PIN_STUDENT':
      return {
        ...state,
        pinnedStudentUid: action.studentUid,
      }

    case 'UNPIN_STUDENT':
      return {
        ...state,
        pinnedStudentUid: null,
      }

    case 'SET_CHART_PARAMS':
      return {
        ...state,
        chartParams: action.chartParams,
      }

    default:
      return state
  }
}

const schoolRatingReducer = combineReducers({ schoolRating })

export default schoolRatingReducer
