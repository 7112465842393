import { Component } from 'preact'
import cx from 'classnames'

import { sanitize, formatText, wrapText } from 'lib/textHelper'
import AUTH_JSON_HEADERS from 'authJsonHeaders'

import DoubleHover from '../../assets/scripts/doubleHover'
import CommentAuthorDetails from './commentAuthorDetails'
import CommentAuthorAvatar from './commentAuthorAvatar'
import CommentFooter from './commentFooter'
import { Image } from '../image'
import { Cols } from '../cols'

export default class CommentReadable extends Component {
  constructor(props) {
    super(props)
    const { status, featured } = props.comment
    this.state = { status, featured }
    this.updateStatus = this.updateStatus.bind(this)
    this.toggleFeatured = this.toggleFeatured.bind(this)
  }

  componentDidMount() {
    $(this.wrapperRef).find('.doubleHover').each(function() { new DoubleHover($(this)) })
  }

  componentDidUpdate(prevProps) {
    const prevStatus = prevProps.comment.status
    const { status } = this.props.comment
    if (status !== prevStatus) this.setState({ status })
  }

  updateStatus(status, intermediateStatus = status) {
    this.setState({ status: intermediateStatus })
    this.update({ status })
  }

  toggleFeatured() {
    const featured = !this.state.featured
    this.setState({ featured })
    this.update({ featured })
  }

  update(commentParams) {
    const beforeUpdateStatus = this.state.status
    const { comment, commentableSubject, onFeaturedToggle, onStatusUpdate } = this.props
    const subject = encodeURIComponent(commentableSubject)
    fetch(`/commentables/${subject}/comments/${comment.id}/`, {
      method: 'PATCH',
      headers: AUTH_JSON_HEADERS,
      body: JSON.stringify(commentParams),
    })
      .then((res) => {
        if (!res.ok) this.setState({ status: beforeUpdateStatus })

        if ('featured' in commentParams) {
          onFeaturedToggle()
        } else {
          onStatusUpdate()
        }
      })
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  buildCommentTextHTML({ body }) {
    return { __html: wrapText($, sanitize(formatText(body))) }
  }

  extractAuthorFrom(comment) {
    let author = {}
    Object.keys(comment).forEach((key) => {
      if (key.includes('author')) {
        let authorKey = key.replace('author', '')
        authorKey = authorKey.charAt(0).toLowerCase() + authorKey.slice(1)

        author[authorKey] = comment[key]
      }
    })

    return author
  }

  render(props) {
    const { comment, isDefaultView } = props
    const author = this.extractAuthorFrom(comment)
    const { status, featured } = this.state
    const classes = cx(
      'module',
      'comment',
      'textBox',
      `is__${status}`
    )

    return (
      <div className={ classes } id={ `comment-${comment.id}` } ref={ (dom) => this.wrapperRef = dom }>
        <div className="module device device__mobile">
          <div className="module comment-body">
            <CommentAuthorDetails author={ author } avatarVisible="true" />
            <div
              className="comment-text"
              dangerouslySetInnerHTML={ this.buildCommentTextHTML(comment) }
            />
            { comment.imageUrl &&
              <Image
                src={ comment.imageUrl }
                size={ comment.imageSize }
                className="comment-asset"
                bordered
              />
            }
          </div>

          <CommentFooter
            comment={comment}
            isDefaultView={isDefaultView}
            status={status}
            featured={featured}
            updateStatus={this.updateStatus}
            toggleFeatured={this.toggleFeatured}
            edit={() => this.props.toggleEditable(true)}
          />
        </div>

        <Cols
          className="comment-cols device device__desktop device__laptop"
          divisionProportions="2:14"
        >
          <CommentAuthorAvatar author={ author } />
          <div>
            <div className="module comment-body">
              <CommentAuthorDetails author={ author } />
              <div
                className="comment-text"
                dangerouslySetInnerHTML={ this.buildCommentTextHTML(comment) }
              />
              { comment.imageUrl &&
                <Image
                  src={ comment.imageUrl }
                  size={ comment.imageSize }
                  className="comment-asset"
                  bordered
                />
              }
            </div>

            <CommentFooter
              comment={comment}
              isDefaultView={isDefaultView}
              status={status}
              featured={featured}
              updateStatus={this.updateStatus}
              toggleFeatured={this.toggleFeatured}
              edit={() => this.props.toggleEditable(true)}
            />
          </div>
        </Cols>
      </div>
    )
  }
}
