import { Component } from 'preact'
import '../../../assets/scripts/lib/tabbable'
import { noop } from './helpers'

// HACK: this doesn't look good
// In all other handlers we rely on tab and $.tab*.
// More than that .suggestInput-input is a global dependency here
// which can break in any moment
const focusMainInput = () => $('.suggestInput-input').focus()

const tabber = {
  VERTICAL: {
    ArrowDown: () => $.tabNext(),
    ArrowUp: () => $.tabPrev(),
  },
  HORIZONTAL: {
    ArrowRight: () => $.tabNext(),
    ArrowLeft: () => $.tabPrev(),
  },
  HORIZONTAL_TOP: {
    ArrowRight: () => $.tabNext(),
    ArrowLeft: () => $.tabPrev(),
    ArrowDown: () => focusMainInput(),
  },
  HORIZONTAL_BOTTOM: {
    ArrowRight: () => $.tabNext(),
    ArrowLeft: () => $.tabPrev(),
    ArrowUp: () => focusMainInput(),
  },
}

const tab = (direction, key) => ((tabber[direction] || {})[key] || noop)()

const canMoveFocusFromInput = (selectionStart, code, length) =>
  ((selectionStart === 0 && code === 'ArrowLeft') ||
   (selectionStart === length && code === 'ArrowRight'))

const isDirectionButtonPressed = (button, code) => button && (button === code)

export default class SpatialContainer extends Component {
  constructor(props) {
    super(props)

    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  handleKeyDown(e) {
    const { direction = 'HORIZONTAL', nextDirectionButton, prevDirectionButton } = this.props
    const { target: { selectionStart, tagName, value }, code } = e

    if (isDirectionButtonPressed(nextDirectionButton, code)) {
      return $.tabNext()
    } else if (isDirectionButtonPressed(prevDirectionButton, code)) {
      return $.tabPrev()
    } else if (tagName === 'INPUT' && !canMoveFocusFromInput(selectionStart, code, value.length)) {
      return null
    }
    return tab(direction, e.code)
  }

  componentDidMount() {
    this.ref.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    this.ref.removeEventListener('keydown', this.handleKeyDown)
  }

  render() {
    const { direction, ...props } = this.props

    return (
      <div ref={ref => this.ref = ref} { ...props }>
        { this.props.children }
      </div>
    )
  }
}
