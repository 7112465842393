import { pluralize } from 'lib/textHelper'

import { Caption } from '../textNodes'

const HOUR_DURATION = 1000 * 60
const DAY_DURATION = HOUR_DURATION * 60 * 24

const calculateDaysLeft = (enrollEndDate) => {
  const now = new Date().getTime()
  const till = new Date(enrollEndDate).getTime()

  return ((till - now) / DAY_DURATION | 0) + 1
}

export const Countdown = (props) => {
  const daysLeft = calculateDaysLeft(props.enrollEndDate)

  return (
    <div className="module countdown">
      <div className="countdown-days">
        { daysLeft }
      </div>
      { pluralize(daysLeft, 'день', 'дня', 'дней') }
      <br />
      до конца
    </div>
  )
}

export const CountdownMobile = (props) => {
  const daysLeft = calculateDaysLeft(props.enrollEndDate)

  return (
    <div className="module countdownMobile">
      <div className="countdownMobile-days">
        { daysLeft }
      </div>
      <div className="countdownMobile-caption">
        <Caption>{ pluralize(daysLeft, 'день', 'дня', 'дней') }<br />до конца</Caption>
      </div>
    </div>
  )
}
