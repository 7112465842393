import cx from 'classnames'

const Image = (props) => {
  const heightToWidthRatio = props.size[1] / props.size[0]
  const align = (props.align || '').toLowerCase()
  const imageClasses = cx(
    'image',
    'media',
    'module',
    {
      is__bordered: props.bordered,
      'is__align-top': align.includes('top'),
      'is__align-right': align.includes('right'),
      'is__align-bottom': align.includes('bottom'),
      'is__align-left': align.includes('left'),
      is__fillModule: props.fillModule,
    },
    props.className
  )

  const spacerStyles = { paddingBottom: `${(heightToWidthRatio * 100).toFixed(2)}%` }

  return (
    <div className={ imageClasses } style={ props.style }>
      <img class="image-img" src={ props.src }/>

      <div className="image-spacer" style={ spacerStyles }></div>
      { props.children }
    </div>
  )
}

export default Image
