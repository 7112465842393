import { Component } from 'preact'

import { Image } from '../../image'
import getAccessLink from 'lib/getAccessLink'

const DISPLAYED_ON_MOBILE_LECTURES = [
  'typography',
  'ui',
  'icons',
  'visual',
  'fff',
  'analytics',
  'clients',
]

const shouldDisplayOnMobile = key => DISPLAYED_ON_MOBILE_LECTURES.includes(key)

const accessLink = key => getAccessLink({ product: 'lecture', productId: `lecture-${key}` })

export default class NewPaybarLectureRotatedCovers extends Component {
  render() {
    return (
      <div className="newPaybarLectureRotatedCovers module" >
        { this.props.lectures.map(function(lecture) {
          let classes = `newPaybarLectureRotatedCovers-cover is__${lecture.key}`

          if (shouldDisplayOnMobile(lecture.key)) {
            classes += ' is__displayedOnMobile'
          }

          return (
            <div className={ classes }>
              <a href={ accessLink(lecture.key) }>
                <Image
                  src={ lecture.cover }
                  size={ [100, 143] } />
              </a>
            </div>
          )
        })
        }
      </div>
    )
  }
}
