import { Component } from 'preact'
import TextareaAutosize from 'react-textarea-autosize'
import cx from 'classnames'
import AUTH_HEADERS from 'authHeaders'
import isValidName from 'lib/users/isValidName'

import { Form } from '../form'
import { Caption } from '../textNodes'

import SovietCommentFormAuthorRow from '../sovietCommentForm/sovietCommentFormAuthorRow'
import SovietCommentFormAuthorFieldset from '../sovietCommentAtoms/sovietCommentFormAuthorFieldset'

export default class SovietCommentEditable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.comment.name,
      email: props.comment.email,
      text: props.comment.text,
      status: props.comment.status,
      isValid: false,
      isSent: false,
      hasError: false,
      attachmentUrl: props.comment.imageUrl,
      isAttachmentDeleted: false,
    }

    this.isCommentAuthorAuthenticated = !!props.comment.sovietUsername
    this.isEditable = !this.isCommentAuthorAuthenticated || !isValidName(props.comment.name)

    this.validityStates = this.isCommentAuthorAuthenticated ? {} : { name: false, email: false }

    this.handleInput = this.handleInput.bind(this)
    this.handleAuthorFieldsetEdit = this.handleAuthorFieldsetEdit.bind(this)
    this.displayAttachment = this.displayAttachment.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.submit = this.submit.bind(this)
    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.handleFetchError = this.handleFetchError.bind(this)
    this.finishEditing = this.finishEditing.bind(this)
  }

  get isValid() {
    return this.state.text &&
      this.state.text.trim() &&
      Object.keys(this.validityStates).every(field => this.validityStates[field])
  }

  get isDisabled() {
    return !this.isValid || this.state.isLoading
  }

  handleInput(e) {
    this.setState({
      text: e.target.value,
      isValid: this.isValid,
      hasError: false,
    })
  }

  handleAuthorFieldsetEdit({ name = this.state.name, email = this.state.email }) {
    this.setState({ name, email })
  }

  displayAttachment() {
    if (!this.fileInput || !this.fileInput.files) return

    const { files } = this.fileInput

    const rawFile = files[0]

    this.readAttachment(rawFile)
      .then(dataUrl => this.setState({ attachmentUrl: dataUrl }))
  }

  deleteAttachment() {
    this.fileInput.value = ''
    this.setState({ attachmentUrl: '', isAttachmentDeleted: true })
  }

  readAttachment(rawFile) {
    if (!rawFile) return null

    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(e.target.result)
      reader.readAsDataURL(rawFile)
    })
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity

    this.setState({ isValid: this.isValid, hasError: false })
  }

  submit(data) {
    this.setState({ isLoading: true, hasError: false })

    const body = this.toFormData(data)

    fetch(`/soviet/advice_comments/${this.props.comment.id}/`, {
      method: 'PATCH',
      headers: {
        ...AUTH_HEADERS,
        Accept: 'application/json',
      },
      body: body,
    })
      .then(res => {
        if (!res.ok) throw Error(res.statusText)

        this.handleSentComment()
      })
      .catch(this.handleFetchError)
  }

  toFormData(data) {
    let formData = new FormData()

    Object.keys(data).forEach(param => formData.append(param, data[param]))
    formData.append('status', this.state.status)

    if (this.fileInput && this.fileInput.files && this.fileInput.files.length) {
      formData.append('image', this.fileInput.files[0])
    }
    if (this.state.isAttachmentDeleted) formData.append('image', '')

    return formData
  }

  handleSentComment() {
    this.setState({ isSent: true, text: '' })
    this.finishEditing()
    this.props.onAfterEdit()
  }

  handleFetchError() {
    this.setState({ hasError: true, isLoading: false })
  }

  finishEditing() {
    this.props.toggleEditable(false)
  }

  render(props) {
    const comment = props.comment
    const submitAttrs = { disabled: this.isDisabled ? 'disabled' : '' }
    const className = cx('sovietCommentForm', { is__loading: this.state.isLoading })

    return (
      <div className="module sovietComment" id={ `comment-${comment.id}` }>
        <Form className={ className } onSubmit={ this.submit }>
          <SovietCommentFormAuthorRow
            commentId={ comment.id }
            name={ comment.name }
            email={ comment.email }
            isAuthenticated={ this.isCommentAuthorAuthenticated }
          />

          { this.isEditable &&
            <SovietCommentFormAuthorFieldset
              id={ comment.id }
              name={ this.state.name }
              email={ this.state.email }
              onEdit={ this.handleAuthorFieldsetEdit }
              onValidityChange={ this.onInputValidityChange }
              disableEmailValidation
            />
          }

          <div className="sovietCommentForm-textWrapper">
            <TextareaAutosize
              name="text"
              className="sovietCommentForm-text"
              placeholder="Комментарий"
              value={ this.state.text }
              onInput={ this.handleInput } />
          </div>

          { this.state.attachmentUrl &&
            <div
              className="sovietCommentForm-attachment is__position"
              style={ { backgroundImage: `url(${this.state.attachmentUrl})` } }>
            </div>
          }

          <footer className="sovietCommentForm-footer">
            <span className="sovietCommentForm-submitWrapper">
              <button
                className="button is__enlargedOnMobiles"
                type="submit"
                { ...submitAttrs }>
                Сохранить
              </button>
            </span>

            <button
              className="sovietCommentForm-cancelButton"
              onClick={ this.finishEditing }
            >
              Отменить
            </button>

            { this.state.hasError &&
              <div className="sovietCommentForm-error">
                <Caption className="error">Ошибка отправки. Попробуйте ещё раз</Caption>
              </div>
            }
          </footer>
        </Form>
      </div>
    )
  }
}
