import { Component } from 'preact'
import { getLastWeek, isStatusDisabled, isStatusPaused } from './helpers'

export default class SchoolRatingConnectedStudentItem extends Component {
  constructor(props) {
    super(props)

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    const { uid: studentUid, activeStudentUid: currentActiveUid } = this.props
    const { activeStudentUid: newActiveUid } = nextProps

    const isBecomingActive = (studentUid !== currentActiveUid) && (studentUid === newActiveUid)
    const isBecomingInactive = (studentUid === currentActiveUid) && (studentUid !== newActiveUid)

    return isBecomingActive || isBecomingInactive
  }

  get lastWeek() {
    return getLastWeek(this.props.weeks)
  }

  get color() {
    if (!this.lastWeek || !this.lastWeek.diff) return 'color-default'

    return this.lastWeek.diff > 0 ? 'color-inc' : 'color-dec'
  }

  get isActive() {
    const { uid, activeStudentUid } = this.props

    return activeStudentUid && (activeStudentUid === uid)
  }

  get isPinned() {
    const { uid, pinnedStudentUid } = this.props

    return pinnedStudentUid && (pinnedStudentUid === uid)
  }

  get isDisabled() {
    return isStatusDisabled(this.props.enrollmentStatus)
  }

  get isFaded() {
    return this.isDisabled || isStatusPaused(this.props.enrollmentStatus)
  }

  onClick() {
    if (this.isPinned) {
      this.props.unpinStudent()
    } else {
      this.props.pinStudent(this.props.uid)
    }
  }

  onMouseEnter() {
    this.props.setActiveStudent(this.props.uid)
  }

  onMouseLeave() {
    this.props.unsetActiveStudent()
  }
}
