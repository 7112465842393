import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import TagsInput from './tagsInput'
import FocusTrap from './focusTrap'
import { SuggestionsDropdown } from './suggestions'
import { handleBackspace, handleEnter } from './keyHandlers'
import predicateToSuggestionType from './predicateToSuggestionType'
import {
  pushUnique,
  getAllTags,
  tagsToStringArray,
  stringArrayToTags,
  areTagsDifferent,
  isTagNew,
  compose,
  getProperSuggestionsList,
  getRecentSuggestionList,
  addTag,
} from './helpers'

import fetchRelated from './fetchRelated'

import { setTags } from '../sovietActions'

class SuggestInputField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTags: stringArrayToTags(props.tags),
      allTags: {},
      currentValue: '',
      isInputFocused: false,
      isCatalogueToggled: false,
      related: [],
    }

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.handleSuggestionDelete = this.handleSuggestionDelete.bind(this)
    this.handleSuggestionEdit = this.handleSuggestionEdit.bind(this)

    this.handleComponentClick = this.handleComponentClick.bind(this)
    this.handleInputFocus = this.handleInputFocus.bind(this)
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)

    this.handleToggleCatalogue = this.handleToggleCatalogue.bind(this)
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this)
    this.registerInputRef = this.registerInputRef.bind(this)
    this.updateTags = this.updateTags.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleDocumentKeyDown)
    document.addEventListener('mousedown', this.handleBlur)

    this.setState({ allTags: window.sovietTags || {} })
    this.updateRelated()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleDocumentKeyDown)
    document.removeEventListener('mousedown', this.handleBlur)
  }

  componentDidUpdate(_, prevState) {
    const currentTags = this.state.selectedTags

    if (prevState.selectedTags.length !== currentTags.length) {
      this.updateTags(currentTags)
      this.updateRelated()
    }
  }

  updateTags(newTags) {
    this.props.setTags(tagsToStringArray(newTags))
  }

  updateRelated() {
    const { selectedTags } = this.state

    if (!selectedTags.length) {
      return this.setState({ related: [] })
    }

    return fetchRelated(selectedTags[selectedTags.length - 1].value)
      .then(res => this.setState({ related: res }))
  }

  processBlur() {
    const { selectedTags, currentValue, allTags } = this.state

    this.setState({
      isCatalogueToggled: false,
      isInputFocused: false,
      selectedTags: currentValue ? addTag(currentValue, selectedTags, getAllTags('all')(allTags)) : selectedTags,
      currentValue: '',
    })
    this.input.blur()
  }

  handleDocumentKeyDown(e) {
    if (e.code === 'Escape') {
      this.processBlur()
    }

    if (this.state.isInputFocused && ['ArrowDown', 'ArrowUp'].includes(e.code)) {
      e.preventDefault()
    }
  }

  handleKeyDown(key) {
    const pipe = compose(
      handleEnter(getAllTags('all')(this.state.allTags)),
      handleBackspace(() => $.tabPrev())
    )

    const input = [key, this.state]
    const output = pipe(input)[1]

    this.setState(output)
  }

  handleSuggestionDelete(tag) {
    return (isDeletedByClick = true) => {
      const newTags = this.state.selectedTags.filter(areTagsDifferent(tag))

      if (isDeletedByClick) {
        this.handleComponentClick()
      } else {
        $.tabPrev()
      }
      this.setState({ selectedTags: newTags })
    }
  }

  handleSuggestionEdit(tag) {
    return () => {
      const { currentValue, selectedTags, allTags } = this.state
      const newTags = selectedTags.filter(areTagsDifferent(tag))

      if (currentValue) {
        newTags.push({
          value: currentValue,
          isTagNew: isTagNew(getAllTags('all')(allTags), currentValue),
        })
      }

      this.setState({
        currentValue: tag.value,
        selectedTags: newTags,
      })

      this.handleComponentClick()
    }
  }

  handleInputChange({ target: { value } }) {
    this.setState({ currentValue: value.replace(/;/g, '') })
  }

  handleComponentClick() {
    if (document.activeElement !== this.input) {
      this.input.focus()
      setTimeout(() => this.input.setSelectionRange(0, 0))
    }
  }

  handleToggleCatalogue(e) {
    e.stopPropagation()
    $(document).trigger('enableFocusMode')

    this.setState({ isCatalogueToggled: !this.state.isCatalogueToggled })
  }

  handleInputFocus() {
    this.setState({ isInputFocused: true })
  }

  handleBlur(e) {
    if (!this.componentRef.contains(e.target)) {
      this.processBlur()
    }
  }

  handleSuggestionSelect(tag) {
    const { selectedTags } = this.state
    const newTags = pushUnique(selectedTags, { value: tag })

    this.setState({
      selectedTags: newTags,
      currentValue: '',
      isCatalogueToggled: false,
    })

    this.handleComponentClick()
  }

  registerInputRef(ref) {
    this.input = ref
  }

  render() {
    const {
      selectedTags, currentValue, isInputFocused, isCatalogueToggled, allTags, related
    } = this.state

    const { author } = this.props

    const recentSuggestionList = getRecentSuggestionList(allTags, author, selectedTags, related)
    const properSuggestionList = getProperSuggestionsList(allTags, currentValue, selectedTags)

    const suggestionType = predicateToSuggestionType(
      currentValue,
      isInputFocused,
      isCatalogueToggled,
      properSuggestionList
    )

    const className = cx('suggestInput', { is__focused: isInputFocused })

    return (
      <div
        className={ className }
        onClick={ this.handleComponentClick }
        ref={ el => this.componentRef = el }
      >
        <FocusTrap isBeginning />
        <TagsInput
          registerRef={ this.registerInputRef }
          selectedTags={ selectedTags }
          handleSuggestionDelete={ this.handleSuggestionDelete }
          handleSuggestionSelect={ this.handleSuggestionEdit }
          handleInputFocus={ this.handleInputFocus }
          handleInputChange={ this.handleInputChange }
          handleKeyDown={ this.handleKeyDown }
          currentValue={ currentValue }
          onCatalogueOpen={ this.handleToggleCatalogue }
          suggestionType={ suggestionType }
        />
        <SuggestionsDropdown
          suggestionType={ suggestionType }
          recentSuggestionsList={ recentSuggestionList }
          properSuggestionsList={ properSuggestionList }
          catalogueSuggestionsList={ this.props.tags }
          handleSuggestionSelect={ this.handleSuggestionSelect }
          handleToggleCatalogue={ this.handleToggleCatalogue }
        />
        <FocusTrap isBeginning={ false } />
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    tags: soviet.tags,
    author: soviet.authorName,
  }
}

const mapDispatchToProps = {
  setTags,
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestInputField)
