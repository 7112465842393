import { Component } from 'preact'
import { pluralize } from 'lib/textHelper'

import { Cols } from '../cols'
import { TextNode, Caption } from '../textNodes'
import { Form, FormInput } from '../form'
import Notification from '../notification/notification'

import AUTH_HEADERS from 'authJsonHeaders'

export default class SovietInvite extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isValid: false,
      invitesLeft: props.invitesLeft,
      isLoading: false,
      sentEmails: [],
      email: '',
    }

    this.validityStates = {
      name: false,
      email: false,
    }

    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.onNameFocus = this.onNameFocus.bind(this)
    this.onEmailFocus = this.onEmailFocus.bind(this)
    this.trackEmail = this.trackEmail.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  get isValid() {
    return Object.keys(this.validityStates)
      .every(field => this.validityStates[field])
  }

  get isDisabled() {
    return !this.state.isValid ||
      this.state.isLoading ||
      this.hasJustSentInvite ||
      this.state.invitesLeft <= 0
  }

  get hasJustSentInvite() {
    return this.state
      .sentEmails
      .map(email => email.toLowerCase())
      .includes(this.state.email.toLowerCase())
  }

  get invitesCaption() {
    const { invitesLeft } = this.state

    if (invitesLeft <= 0) return 'У вас больше <br class="device mobile" />нет приглашений'

    const pluralizedInvites = pluralize(invitesLeft, 'приглашение', 'приглашения', 'приглашений')
    return `У вас осталось <br class="device mobile" />${invitesLeft} ${pluralizedInvites}`
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity

    this.setState({ isValid: this.isValid })
  }

  onNameFocus() {
    if (this.hasJustSentInvite) this.nameInput.el.select()
  }

  onEmailFocus() {
    if (this.hasJustSentInvite) this.emailInput.el.select()
  }

  onSubmit(json) {
    if (this.isDisabled) return

    this.setState({ isLoading: true })

    fetch('/soviet/honorable_invite_requests', {
      method: 'POST',
      headers: AUTH_HEADERS,
      body: JSON.stringify(json),
    })
      .then((res) => res.ok ? this.handleSentInvite(json) : this.handleError(res))
      .catch(this.setError.bind(this))
  }

  handleSentInvite({ name, email }) {
    this.setState({
      isLoading: false,
      hasError: false,
      hasInvitesError: false,
      hasInviteeError: false,
      invitesLeft: this.state.invitesLeft - 1,
      sentEmails: this.state.sentEmails.concat([email]),
    }, () => this.trickSafariIntoSentForm({ name, email }))
  }

  trickSafariIntoSentForm({ name, email }) {
    this.nameInput.el.value = ''
    this.emailInput.el.value = ''

    this.nameInput.el.value = name
    this.emailInput.el.value = email
  }

  handleError(res) {
    res
      .json()
      .then((json) => {
        const error = (json.errors || [])[0] || {}

        if (/invite_limit_reached/.test(error.code)) return this.setInvitesError()
        if (/already_honorable/.test(error.code)) return this.setInviteeError()

        return this.setError()
      })
      .catch(this.setError.bind(this))
  }

  setError() {
    this.setState({
      isLoading: false,
      hasError: true,
      hasInvitesError: false,
      hasInviteeError: false,
    })
  }

  setInvitesError() {
    this.setState({
      isLoading: false,
      hasError: false,
      hasInvitesError: true,
      hasInviteeError: false,
      invitesLeft: 0,
    })
  }

  setInviteeError() {
    this.setState({
      isLoading: false,
      hasError: false,
      hasInvitesError: false,
      hasInviteeError: true,
    })
  }

  trackEmail({ value }) {
    this.setState({ email: value })
  }

  render(props) {
    const fullWidthFloorClass = props.fullWidth ? 'fullWidthFloor' : ''
    const fullWidthNotificationClass = props.fullWidth ? 'is__fullWidth' : ''

    return (
      <div className="sovietInvite-wrapper">
        <div className={`is__form ${fullWidthFloorClass}`}>
          <Cols divisionProportions="5:11" className="sovietInvite is__transposeOnMobile">
            <div className="sovietInvite-help">
              <TextNode>Пригласите <br class="device desktop laptop" />друзей в новые советы</TextNode>
            </div>

            <Form className="sovietInvite-form" onSubmit={ this.onSubmit }>
              <div className="inputGroup">
                <FormInput
                  className="input is__mediumLarge is__padded is__rounded is__inverted"
                  placeholder="Имя"
                  name="name"
                  required="required"
                  ref={ el => this.nameInput = el }
                  onClick={ this.onNameFocus }
                  onValidityChange={ this.onInputValidityChange } />

                <FormInput
                  className="input is__mediumLarge is__padded is__rounded is__inverted"
                  placeholder="Электронная почта"
                  name="email"
                  type="email"
                  required="required"
                  ref={ el => this.emailInput = el }
                  onInput={ this.trackEmail }
                  onClick={ this.onEmailFocus }
                  onValidityChange={ this.onInputValidityChange } />

                <button
                  className="inputSubmit is__mediumLarge is__padded is__rounded is__inverted"
                  disabled={ this.isDisabled }
                  type="submit">
                  { this.hasJustSentInvite ? 'Отправлено' : 'Пригласить' }
                </button>

                <Caption className="sovietInvite-formCaption" html={ this.invitesCaption } />
              </div>
            </Form>
          </Cols>
        </div>

        <div className={`is__noPadding ${fullWidthFloorClass}`}>
          { this.state.hasInvitesError &&
            <Notification
              className={`is__error ${fullWidthNotificationClass}`}
              html="Не получилось отправить. У вас закончились приглашения" />
          }

          { this.state.hasError &&
            <Notification
              className={`is__error ${fullWidthNotificationClass}`}
              html="Не получилось отправить приглашение. Попробуйте позже или напишите нам: <b><a href='mailto:support@artgorbunov.ru'>support@artgorbunov.ru</a></b>" />
          }

          { this.state.hasInviteeError &&
            <Notification
              className={`is__success ${fullWidthNotificationClass}`}
              html="Уже в клубе! Ваше приглашение осталось у вас" />
          }
        </div>
      </div>
    )
  }
}
