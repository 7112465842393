import { Component } from 'preact'
import debounce from 'debounce'
import UploadBar from './bar'
import UploadFiles from './files'
import { Caption } from '../textNodes'

class Upload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      files: props.files,
      isEnabled: props.isEnabled,
      isSuccess: false,
      error: false,
    }

    this.uploadHeadingPlaceholder = 'Результаты задания'
    this.desktopUploadPlaceholder = this.props.isUploadEnabled ? 'Перетащите на страницу файлы с результатами или вставьте сюда ссылку на документ' : 'Вставьте ссылку на документ'
    this.mobileUploadPlaceholder1 = '↑ Загрузите файлы с результатами'
    this.mobileUploadPlaceholder2 = this.props.isUploadEnabled ? 'Или вставьте ссылку на документ' : 'Вставьте ссылку на документ'

    this.linkUrl = debounce(300, this.linkUrl)
    this.delete = this.delete.bind(this)
  }

  componentDidMount() {
    if (!this.props.isUploadEnabled) return

    const $document = $(document)

    $document.on('appDragStart.upload', () => this.setState({ isDragOver: true }))
    $document.on('appDragEnd.upload', () => this.setState({ isDragOver: false }))
    $document.on('appDrop.upload', (_, data) => this.batchUpload(data.files))
  }

  componentWillUnmount() {
    $(document).off('appDragStart.upload appDragEnd.upload appDrop.upload')
  }

  get hasError() {
    return this.state.error && window.application.errors[this.state.error.code]
  }

  handleTextareaInput(e) {
    this.setState({
      link: e.target.value,
      isSuccess: false,
    })

    this.linkUrl()
  }

  handleTextareaKeydown(e) {
    if (e.keyCode === 13) e.preventDefault()
  }

  batchUpload(files) {
    if (!this.state.isEnabled || !files.length || !this.props.isUploadEnabled) return

    this.upload(files.pop())
      .then(() => this.batchUpload(files))
  }

  upload(rawfile) {
    const formData = new FormData()

    formData.append('file', rawfile)

    this.setState({
      isUploading: true,
      isSuccess: false,
      uploadingFileName: rawfile.name,
      uploadingFileTotal: rawfile.size,
    })

    return $.ajax({
      url: this.props.apiUrl,
      data: formData,
      contentType: false,
      processData: false,
      headers: this.props.authHeaders,
      type: 'POST',
      xhr: this.handleProgressUpdate.bind(this),
      success: this.handleSuccessfulUpload.bind(this),
      error: this.handleErrorUpload.bind(this),
    })
  }

  handleSuccessfulUpload(res) {
    this.setState({
      files: [...this.state.files, res.results[0]],
      isUploading: false,
      isSuccess: true,
    })
  }

  handleErrorUpload(xhr) {
    if (xhr.responseJSON) {
      this.setState({ error: xhr.responseJSON.errors[0] })
    } else {
      this.setState({ error: { code: 'school.task_result.something_bad_happened' } })
    }
  }

  handleProgressUpdate() {
    const xhr = new window.XMLHttpRequest()

    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) {
        this.setState({
          uploadingFileLoaded: e.loaded,
        })
      }
    }, false)

    return xhr
  }

  linkUrl() {
    fetch(this.props.apiUrl, {
      method: 'POST',
      headers: Object.assign({}, this.props.authHeaders, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        link: this.state.link,
        sessionId: this.props.sessionId,
      }),
    }).then(res => {
      if (res.ok) {
        this.setState({ isSuccess: true })
      } else {
        res.json().then(json => this.setState({ error: json.errors[0] }))
      }
    })
  }

  delete(file) {
    fetch(`${this.props.apiUrl}/${file.id}`, {
      method: 'DELETE',
      headers: this.props.authHeaders,
    }).then(res => {
      if (res.ok) {
        this.setState({
          files: this.state.files.filter(f => f !== file),
          isSuccess: false,
        })
      } else {
        res.json().then(json => this.setState({ error: json.errors[0] }))
      }
    })
  }

  render() {
    const links = this.state.files.filter(f => f.type === 'text/link')
    const files = this.state.files.filter(f => f.type.indexOf('image') === 0)

    return (
      <div className="uploadBox">
        <div className="uploadGroup is__desktop">
          <div className={`
                upload
                ${this.state.isEnabled ? '' : 'is__disabled'}
                ${this.state.isDragOver ? 'is__dragOver' : ''}
                ${this.state.isSuccess ? 'is__success' : ''}
                ${!this.state.files.length ? 'is__empty' : ''}
              `}
            >
            <div className="upload-instructions">
              <div className="upload-heading">{ this.uploadHeadingPlaceholder }</div>
              <textarea class="upload-textarea"
                  required
                  value={ this.state.link }
                  onInput={ this.handleTextareaInput.bind(this) }
                  onKeyDown={ this.handleTextareaKeydown }
                >
              </textarea>
              <div className="upload-placeholder">{ this.desktopUploadPlaceholder }</div>
            </div>

            <UploadFiles
              files={ this.state.files }
              onDelete={ this.delete }
            />
          </div>
        </div>

        <div className={`
              uploadGroup is__mobile
              ${this.state.isEnabled ? '' : 'is__disabled'}
              ${!this.state.files.length ? 'is__empty' : ''}
            `}>
          <div className="uploadGroup-heading">{ this.uploadHeadingPlaceholder }</div>

          <div className="uploadGroup-files">
            <UploadFiles
                files={ this.state.files }
                onDelete={ this.delete }
              />
          </div>

          <div className={`
                upload is__mobile
                ${this.state.isEnabled && this.props.isUploadEnabled ? '' : 'is__disabled'}
                ${!files.length ? 'is__empty' : ''}
              `}
            >
            <div className="upload-instructions">
              { this.mobileUploadPlaceholder1 }

              <input className="upload-fileInput"
                  type="file"
                  onChange={ (e) => this.batchUpload([...e.target.files]) }
                />
            </div>

            <UploadFiles
                files={ files }
                onDelete={ this.delete }
              />
          </div>

          <div className={`
                upload is__mobile
                ${this.state.isEnabled ? '' : 'is__disabled'}
                ${this.state.isSuccess ? 'is__success' : ''}
                ${!links.length ? 'is__empty' : ''}
              `}
            >
            <div className="upload-instructions">
              <textarea class="upload-textarea"
                  required
                  value={ this.state.link }
                  onInput={ this.handleTextareaInput.bind(this) }
                  onKeyDown={ this.handleTextareaKeydown }
                >
              </textarea>
              <div className="upload-placeholder">{ this.mobileUploadPlaceholder2 }</div>
            </div>

            <UploadFiles
                files={ links }
                onDelete={ this.delete }
              />
          </div>
        </div>

        { this.hasError &&
          <Caption className="upload-error">
            { window.application.errors[this.state.error.code](this.state.error.params) }
          </Caption>
        }

        <UploadBar file={ this.state.uploadingFileName }
            loaded={ this.state.uploadingFileLoaded }
            total={ this.state.uploadingFileTotal }
            isActive={ this.state.isUploading }
            isFailed={ this.hasError }
          />
      </div>
    )
  }
}

module.exports = Upload
