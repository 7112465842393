import { Component } from 'preact'
import { connect } from 'preact-redux'

import { fetchSovietFiles } from './fetchSovietFiles'
import { setFiles } from './sovietActions'

import { Caption } from '../textNodes'
import SovietFile from './sovietFile'
import { NewImageIcon } from '../icons'

class SovietFilesPanel extends Component {
  constructor(props) {
    super(props)

    this.handleFileAttachment = this.handleFileAttachment.bind(this)
  }

  componentDidMount() {
    fetchSovietFiles(this.props.id)
      .then(this.props.setFiles)
  }

  handleFileAttachment(e) {
    const files = Object.values(e.target.files)

    $(document).trigger('appDrop.sovietFilesUpload', { files })

    e.target.value = ''
  }

  filesList() {
    if (!this.props.files || !this.props.files.length) return null

    return this.props.files
      .map(file => (<SovietFile key={ file.id } file={ file } />))
  }

  render() {
    return (
      <div className="sovietFilesPanel">
        <div className="sovietFilesPanel-shaftWrapper">
          <div className="sovietFilesPanel-shaft">
            <div className="sovietFilesPanel-new">
              <NewImageIcon />
              <input type="file" onChange={ this.handleFileAttachment } multiple="true" />
            </div>
            { this.filesList() }
            <div className="sovietFilesPanel-buffer" />
          </div>
        </div>
        <Caption className="sovietFilesPanel-hint">
          Перетаскивайте файлы прямо в браузер.<br />
          Клик в картинку добавит её код в нужное место совета.
        </Caption>
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    id: soviet.id,
    files: soviet.files,
  }
}

const mapDispatchToProps = {
  setFiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietFilesPanel)
