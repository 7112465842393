import InputBase from './inputBase'
import TextareaAutosize from 'react-textarea-autosize'

const DEFAULT_ROWS_COUNT = 9

export default class FormInput extends InputBase {
  dispatchResize() {
    document.dispatchEvent(new Event('appResize'))
  }

  render() {
    return (
      <TextareaAutosize
        { ...this.props }
        className={ this.getClassNames('input') }
        minRows={ this.props.minRows || DEFAULT_ROWS_COUNT }
        onInput={ this.onInput }
        value={ this.state.value }
        onHeightChange={ this.dispatchResize } />
    )
  }
}
