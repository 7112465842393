import { isBookshelf } from './bookSpacerUtils'
import { BookshelfCover, BookCover, MobileCover } from './bookSpacerCoverUtils'
import BookSpacerCouponSticker from './bookSpacerCouponSticker'

const BookSpacerCover = (props) => {
  const isProductBookshelf = isBookshelf(props.id)
  const isHalf = props.cellSize === 'half'
  const isFull = props.cellSize === 'full'

  return (
    <a
      href={ props.href }
      className="bookSpacerCover module doubleHover is__fitToContent"
      onClick={ props.onClick }
    >
      { props.isCouponApplied &&
        <BookSpacerCouponSticker discount={ props.discount }/>
      }

      { isProductBookshelf &&
        <BookshelfCover
          id={ props.id }
          coverSize={ props.coverSize }
          halfCoverLaptopSize={ props.halfCoverLaptopSize }
          fullCoverLaptopSize={ props.fullCoverLaptopSize }
          isHalf={ isHalf }
          isFull={ isFull }
        />
      }

      { !isProductBookshelf &&
        <BookCover
          id={ props.id }
          isHalf={ isHalf }
          isFull={ isFull } />
      }

      <MobileCover
        coverMobileSize={ props.coverMobileSize }
        id={ props.id }
        labelText={ props.labelText }
      />
    </a>
  )
}

export default BookSpacerCover
