import { Component } from 'preact'
import { Heading2 } from '../textNodes'
import LecturePreview from '../lecturePreview/lecturePreview'

import { utmSource } from 'lib/helpers/linksHelper'

export default class LecturesCourse extends Component {
  render() {
    const { hasLecturesList } = this.props
    const { title, key, cover, authorInstrumentalCase, lectures } = this.props.course

    const fullTitle = title + ` с ${authorInstrumentalCase}`
    const source = utmSource({
      platform: 'Сайт бюро',
      productType: 'Лекции',
      product: key,
      postDate: new Date(),
    })
    const href = `/lectures/${key}/?utm_source=${source}`

    const lecturesWithVideo = (lectures || []).filter(lecture => !!lecture.cover)

    return (
      <div className="lecturesCourse" style={ `background-image: url(${cover})` }>
        <a href={ href } className="lecturesCourse-link no-u">
          <Heading2 className="lecturesCourse-title" html={ fullTitle } />
        </a>

        { hasLecturesList &&
          <div className="lecturesCourse-lectures">
            { lecturesWithVideo.map((lecture, index) => (
              <LecturePreview { ...lecture }
                courseKey={ key }
                lectureKey={ lecture.key }
                key={ index } />
            ))}
          </div>
        }
      </div>
    )
  }
}
