const InvalidLoginLinkNotification = ({ email }) => {
  const notification = I18n.t('vac.invalidLoginLink', { email })

  return (
    <div className="topLevelNotificationsWrap">
      <div className="topLevelNotification holder is__active">
        <div className="topLevelNotification-main">
          <span className="topLevelNotification-text"
            dangerouslySetInnerHTML={ { __html: notification } } />
        </div>
      </div>
    </div>
  )
}

export default InvalidLoginLinkNotification
