import cx from 'classnames'

import { Image } from '../image'

const MobileScreenshot = (props) => {
  const size = [396, 230]
  const maskId = 'id-' + Date.now() + Math.random()
  const clipPathStyle = `clip-path: url(#${maskId});`
  const screenshotClasses = cx(
    'screenshot',
    'media',
    'module',
    props.className
  )

  return (
    <div className={ screenshotClasses }>
      <div class="browser is__mobile module">
        <div class="module browser-wrapper">
          <div class="browser-top">
            <svg class="browser-mask" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
              <defs>
                <clipPath id={ maskId }>
                  <rect x="0.5" y="0.5" width="100%" height="100%" rx="9.41%" fill="#fff"></rect>
                </clipPath>
              </defs>
            </svg>
            <svg class="browser-border" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
              <rect x="0.5" y="0.5" width="100%" height="100%" rx="9.41%"></rect>
            </svg>
          </div>

          <div class="module browser-viewport">
            <div class="module browser-frame" style={ clipPathStyle }>
              <div class="module browser-content">
                <Image src={ props.src } size={ size } />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  )
}

export default MobileScreenshot
