import { Component } from 'preact'
import cx from 'classnames'

import { TextNode } from '../textNodes'

class NewPaybarFooterSubscriptionMessage extends Component {
  get subscriptionMessageText() {
    if (this.props.isCancelled) return I18n.t('newPaybar.cancelled')

    return I18n.t(`newPaybar.success.${this.props.product}.${this.props.type}`)
  }

  render() {
    const classNames = cx('newPaybarFooter-message', { is__cancelled: this.props.isCancelled })

    return (<TextNode className={ classNames }>{ this.subscriptionMessageText }</TextNode>)
  }
}

export default NewPaybarFooterSubscriptionMessage
