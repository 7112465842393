import { Component } from 'preact'
import TextareaAutosize from 'react-textarea-autosize'
import cx from 'classnames'
import Cookies from 'js-cookie'

import AUTH_HEADERS from 'authHeaders'

import { Form } from '../form'
import { Cols } from '../cols'
import { Caption } from '../textNodes'
import { AttachmentIconWithHover } from '../icons'
import DeleteButton from '../deleteButton/deleteButton'

import { readState, mergeState, persistRawState } from 'lib/reduxStorage'

import CommentFormSuccessFrame from './commentFormSuccessFrame'
import CommentFormAuthorRow from './commentFormAuthorRow'
import CommentFormAuthorFieldset from '../commentAtoms/commentFormAuthorFieldset'
import CommentFormCaption from './commentFormCaption'

const INITIAL_FORM_STATE = {
  name: (Cookies.get('name') || '').replace(/\+/g, ' ') || null,
  email: Cookies.get('email') || null,
  jobTitle: '',
  company: '',
  body: '',
  attachmentUrl: '',
  isValid: false,
  isLoading: false,
  isSent: false,
  hasError: false,
}

export default class CommentForm extends Component {
  constructor(props) {
    super(props)

    this.author = this.prepareAuthorAttributes(window.application.user || {})

    this.scope = props.commentableSubject + '/comment/draft'
    this.isAuthenticated = !!this.author.id

    this.state = mergeState(INITIAL_FORM_STATE, readState(this.scope))

    this.validityStates = this.isAuthenticated ? {} : { name: false, email: false }

    this.handleBodyInput = this.handleBodyInput.bind(this)
    this.handleAuthorFieldsetEdit = this.handleAuthorFieldsetEdit.bind(this)
    this.displayAttachment = this.displayAttachment.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.submit = this.submit.bind(this)
    this.reset = this.reset.bind(this)
    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.handleFetchError = this.handleFetchError.bind(this)
  }

  get isValid() {
    return this.state.body &&
      this.state.body.trim() &&
      Object.keys(this.validityStates).every(field => this.validityStates[field])
  }

  get isDisabled() {
    return !this.isValid || this.state.isLoading
  }

  prepareAuthorAttributes(user) {
    user.jobTitle = user.profession
    user.company = user.jobPlace
    return user
  }

  componentDidUpdate() {
    persistRawState(this.scope, { text: this.state.body })
  }

  handleBodyInput(e) {
    this.setState({
      body: e.target.value,
      isValid: this.isValid,
      hasError: false,
    })
  }

  handleAuthorFieldsetEdit({
    name = this.state.name,
    email = this.state.email,
    jobTitle = this.state.jobTitle,
    company = this.state.company,
  }) {
    this.setState({ name, email, jobTitle, company })
  }

  displayAttachment() {
    if (!this.fileInput || !this.fileInput.files) return

    const { files } = this.fileInput

    const rawFile = files[0]

    this.readAttachment(rawFile)
      .then(attachmentUrl => this.setState({ attachmentUrl }, () => $(document).trigger('appResize')))
  }

  deleteAttachment() {
    this.fileInput.value = ''
    this.setState({ attachmentUrl: '' })
  }

  readAttachment(rawFile) {
    if (!rawFile) return Promise.resolve('')

    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(e.target.result)
      reader.readAsDataURL(rawFile)
    })
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity

    this.setState({ isValid: this.isValid, hasError: false })
  }

  submit(data) {
    if (!this.isValid) return

    this.setState({ isLoading: true, hasError: false })

    const requestBody = this.toFormData(data)
    const subject = encodeURIComponent(this.props.commentableSubject)
    fetch(`/commentables/${subject}/comments`, {
      method: 'POST',
      headers: {
        ...AUTH_HEADERS,
        Accept: 'application/json',
      },
      body: requestBody,
    })
      .then(res => {
        if (!res.ok) throw Error(res.statusText)

        this.handleSentComment()
      })
      .catch(this.handleFetchError)
  }

  toFormData(data) {
    let formData = new FormData()

    Object.keys(data).forEach(key => {
      const authorFields = ['email', 'name', 'job_title', 'company']
      const formKey = (authorFields.includes(key) ? `author_${key}` : key)
      formData.append(`comment[${formKey}]`, data[key])
    })

    if (this.fileInput && this.fileInput.files && this.fileInput.files.length) {
      formData.append('comment_image', this.fileInput.files[0])
    }

    return formData
  }

  handleSentComment() {
    if (this.state.name) Cookies.set('name', this.state.name)
    if (this.state.email) Cookies.set('email', this.state.email)

    $(document).trigger('commentSent')

    this.setState({ isSent: true, text: '' })
  }

  handleFetchError() {
    this.setState({ hasError: true, isLoading: false })
  }

  reset() {
    this.setState({
      ...INITIAL_FORM_STATE,
      name: this.state.name,
      email: this.state.email,
    })
  }

  render({ title, pluralTitle, moderationNote, mod = '' }) {
    const { isSent, isLoading } = this.state
    if (isSent) return (<CommentFormSuccessFrame onClick={ this.reset } title={ title.toLowerCase() } />)

    const colsClassNames = cx('commentFormFloor is__transposeOnMobile', { is__noTopLine: mod.includes('noTopLine') })
    const formClassNames = cx('commentForm', { is__loading: isLoading })
    const submitAttrs = { disabled: this.isDisabled ? 'disabled' : '' }

    return (
      <Cols className={ colsClassNames } divisionProportions="11:1:4">
        <Form className={ formClassNames } onSubmit={ this.submit }>
          <CommentFormAuthorRow author={ this.author } isAuthenticated={ this.isAuthenticated } />
          <CommentFormAuthorFieldset
            isAuthenticated={ this.isAuthenticated }
            author={ this.author }
            onEdit={ this.handleAuthorFieldsetEdit }
            onValidityChange={ this.onInputValidityChange }
          />

          <div className="commentForm-textWrapper">
            <TextareaAutosize
              name="body"
              className="commentForm-text"
              placeholder="Ваше мнение"
              value={ this.state.body }
              onInput={ this.handleBodyInput } />
          </div>

          { this.state.attachmentUrl &&
            <div
              className="commentForm-attachment is__position"
              style={ { backgroundImage: `url(${this.state.attachmentUrl})` } }>

              <DeleteButton onClick={ this.deleteAttachment }/>
            </div>
          }

          <footer className="commentForm-footer">
            <span className="commentForm-submitWrapper">
              <button
                className="button is__enlargedOnMobiles"
                type="submit"
                { ...submitAttrs }>
                Отправить
              </button>
            </span>

            { this.state.hasError &&
              <div className="commentForm-error">
                <Caption className="error">Ошибка отправки. Попробуйте ещё раз</Caption>
              </div>
            }

            <div className="commentForm-attach attach">
              <AttachmentIconWithHover />
              <input
                ref={ el => this.fileInput = el }
                type="file"
                name="attachment"
                onChange={ this.displayAttachment }
                accept="image/jpeg,image/png,image/gif" />
            </div>
          </footer>
        </Form>

        <div className="module device device__desktop device__laptop"></div>

        <CommentFormCaption title={pluralTitle} note={moderationNote} />
      </Cols>
    )
  }
}
