import { Component } from 'preact'
import cx from 'classnames'

import { Heading2, TextNode } from '../../textNodes'
import { Image } from '../../image'

export default class NewPaybarCollectureUpgrade extends Component {
  get title() {
    return I18n.t('collectures.upgrade.title', { productType: this.productType })
  }

  get text() {
    return I18n.t('collectures.upgrade.text', { productType: this.productType })
  }

  get productType() {
    const { ownedLectures, ownedCollectures } = this.props.ownedProducts

    if (ownedCollectures.length < 1) return I18n.t('collectures.upgrade.fromLectures')

    if (ownedLectures.length < 1) return I18n.t('collectures.upgrade.fromCollectures')

    return I18n.t('collectures.upgrade.fromLecturesAndCollectures')
  }

  render() {
    const { className } = this.props

    return (
      <div className={ cx('newPaybarUpgrade', 'module', 'is__upgradeToAll', className) }>
        <div className="newPaybarUpgrade-top newPaybarUpgrade-covers">
          { this.props.lectures.map(lecture => (
            <div className="newPaybarUpgrade-cover">
              <Image
                  src={ lecture.cover }
                  size={ [100, 130] } />
            </div>
          )) }
        </div>

        <div className="newPaybarUpgrade-bottom">
          <Heading2 className="newPaybarUpgrade-title" html={ this.title }></Heading2>
          <TextNode className="newPaybarUpgrade-text" html={ this.text }></TextNode>
        </div>
      </div>
    )
  }
}
