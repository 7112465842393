import { Component } from 'preact'
import Cookies from 'js-cookie'
import cx from 'classnames'
import { Heading1, TextNode } from '../textNodes'
import GetGiftForm from './getGiftForm'
import GetGiftBox from './getGiftBox'
import { fetchProductDetails } from './helpers'

import Confetti from 'lib/vendor/confetti'
import keyColors from 'lib/keyColors'
import hideIosKeyboard from '../../assets/scripts/lib/hideIosKeyboard'

const GIFT_CLASS = "giftPage"

export default class GetGift extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isValid: true,
      present: null,
    }

    $('body').addClass(GIFT_CLASS)
    this.checkCode = this.checkCode.bind(this)
    this.acceptCode = this.acceptCode.bind(this)
    this.resendAccessMail = this.resendAccessMail.bind(this)
    this.setCodeError = this.setCodeError.bind(this)
    this.unsetLoading = this.unsetLoading.bind(this)

    new Confetti()
  }

  resendAccessMail() {
    const code = $(this.el).find('input[name=code]').val()

    $.post('/get/resend.php', { code })
  }

  checkCode() {
    this.unsetCodeError()
    this.setLoading()
    this.saveUserDataToCookies()

    $.post('/get/activate.php', $(this.el).find('form').serialize())
      .done(present => {
        this.setState({ present })
        fetchProductDetails(present.productId).then(this.acceptCode)
      })
      .fail(this.setCodeError)
      .always(this.unsetLoading)
  }

  acceptCode(presentDetails) {
    const keyColor = keyColors[presentDetails.product_id] || keyColors.default
    this.setState({
      present: { ...this.state.present, ...presentDetails, keyColor },
      isPresentReady: true,
    })

    hideIosKeyboard()
    $('body').trigger('codeAccepted', Object.assign(this.data, this.state.present))
  }

  saveUserDataToCookies() {
    Cookies.set('name', this.data.name)
    Cookies.set('email', this.data.email)
  }

  setLoading() {
    this.setState({ isLoading: true })
  }

  unsetLoading() {
    this.setState({ isLoading: false })
  }

  setCodeError() {
    this.setState({ hasCodeError: true })
  }

  unsetCodeError() {
    this.setState({ hasCodeError: false })
  }

  get data() {
    let data = {}

    $(this.el).find('form').serializeArray().forEach(field => data[field.name] = field.value)

    return data
  }

  render() {
    const classNames = cx('getGift', {
      is__loading: this.state.isLoading,
      has__codeError: this.state.hasCodeError,
    })

    return (
      <div className={ classNames } ref={ el => this.el = el }>
        <div className="getGift-header">
          <Heading1 className="is__large">{ I18n.t('getGift.title') }</Heading1>
          <TextNode html={ I18n.t('getGift.codeHint') }/>
        </div>

        <GetGiftForm onSubmit={ this.checkCode } hasCodeError={ this.state.hasCodeError }/>

        <GetGiftBox
          isPresentReady={ this.state.isPresentReady }
          present={ this.state.present }
          email={ this.data.email }
          onResend={ this.resendAccessMail }/>
      </div>
    )
  }
}
