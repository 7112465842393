import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { Heading2 } from '../../textNodes'
import Cols from '../../cols/cols'
import NewPaybarLaunchpadItemBook from './newPaybarLaunchpadItemBook'

class NewPaybarLaunchpadBookshelf extends Component {
  get title() {
    const suffix = !this.props.isUserAuthenticated ? 'NoAccess' : ''

    return I18n.t(`newPaybar.launchpad.book${suffix}`)
  }

  get isAllBooksUnreadable() {
    return this.props.books.every(book => book.cannotRead && !book.hasOnlyDemo)
  }

  get hasPhoneBadges() {
    if (this.props.books.length <= 1) return false

    const isAllBooksHasMobileVersion = this.props.books.every(book => book.hasMobileVersion)

    return !isAllBooksHasMobileVersion
  }

  render() {
    const globalHasOnlyDemo = this.props.hasOnlyDemo
    const globalCannotRead = this.props.cannotRead && !globalHasOnlyDemo

    const isTitleHidden = globalCannotRead || this.isAllBooksUnreadable

    return (
      <section className="newPaybarLaunchpad-section">
        <Heading2 className={ cx('newPaybarLaunchpad-title', { is__hidden: isTitleHidden }) }>
          { this.title }
        </Heading2>

        <Cols className="newPaybarLaunchpad-items">
          { this.props.books.map(book => (
            <NewPaybarLaunchpadItemBook
              isUserAuthenticated={ this.props.isUserAuthenticated }
              productId={ this.props.productId }
              bookKey={ book.key }
              cannotRead={ globalCannotRead || book.cannotRead }
              hasOnlyDemo={ globalHasOnlyDemo || book.hasOnlyDemo }
              badgePhone={ this.hasPhoneBadges && book.hasMobileVersion } />
          )) }
        </Cols>
      </section>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    books: newPaybar.shelfBooks,
    product: newPaybar.product,
    productId: newPaybar.productId,
    cannotRead: newPaybar.cannotRead,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    hasOnlyDemo: newPaybar.hasOnlyDemo,
  }
}

export default connect(mapStateToProps)(NewPaybarLaunchpadBookshelf)
