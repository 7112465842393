import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { subscribeSomeoneElse } from './newPaybarActions'

class NewPaybarButtonMore extends Component {
  get buttonText() {
    return I18n.t(`newPaybar.moreButtonText.${this.props.product}`)
  }

  render() {
    const className = cx('newPaybarButtonMore-button', 'button')

    return (
      <span className="newPaybarButtonMore">
        <button
          className={ className }
          onClick={ this.props.subscribeSomeoneElse }
          dangerouslySetInnerHTML={{ __html: this.buttonText }}>
        </button>
      </span>
    )
  }
}

const mapDispatchToProps = { subscribeSomeoneElse }

export default connect(null, mapDispatchToProps)(NewPaybarButtonMore)
