import cx from 'classnames'

import { sanitize } from 'lib/textHelper'

import { Caption } from '../textNodes'

const Factoid = (props) => {
  const isNumberSet = props.number !== ''
  const modClasses = props.mod && props.mod.length ? props.mod.split(' ').map(mod => `is__${mod}`) : []
  const classes = cx(
    'module',
    'factoid',
    props.className,
    modClasses,
    { is__simple: isNumberSet }
  )

  return (
    <div className={ classes }>
      { isNumberSet &&
        <div
          className="factoid-number"
          dangerouslySetInnerHTML={ { __html: sanitize(String(props.number)) } }
        />
      }
      { isNumberSet &&
        <Caption>{ props.children }</Caption>
      }

      { !isNumberSet &&
        props.children
      }
    </div>
  )
}

export default Factoid
