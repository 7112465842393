import { Component } from 'preact'
import cx from 'classnames'

import { sanitize, birmanize, formatText, wrapText } from 'lib/textHelper'
import AUTH_JSON_HEADERS from 'authJsonHeaders'

import SovietCommentAuthor from './sovietCommentAuthor'
import { Image } from '../image'
import { Caption } from '../textNodes'
import Impressions from '../impressions/impressions'

export default class SovietCommentReadable extends Component {
  constructor(props) {
    super(props)
    this.state = { status: props.comment.status }

    this.approve = this.approve.bind(this)
    this.edit = this.edit.bind(this)
    this.sink = this.sink.bind(this)
    this.unsink = this.unsink.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.comment.status !== prevProps.comment.status) {
      this.setState({ status: this.props.comment.status })
    }
  }

  buildCommentTextHTML() {
    return { __html: wrapText($, sanitize(formatText(this.props.comment.text))) }
  }

  edit() {
    this.props.toggleEditable(true)
  }

  updateStatus(status, intermediateStatus = status) {
    const currentStatus = this.state.status

    this.setState({ status: intermediateStatus })

    return fetch(`/soviet/advice_comments/${this.props.comment.id}/`, {
      method: 'PATCH',
      headers: AUTH_JSON_HEADERS,
      body: JSON.stringify({ status: status }),
    })
      .then((res) => {
        if (!res.ok) this.setState({ status: currentStatus })

        this.props.onStatusUpdate()
      })
      .catch(e => console.error(e))
  }

  approve() {
    this.updateStatus('approved', 'justApproved')
  }

  sink() {
    this.updateStatus('sunk')
  }

  unsink() {
    this.updateStatus('new')
  }

  render(props) {
    const comment = props.comment
    const commentImpressions = comment.impressionSummary || {}
    const isApproved = this.state.status === 'approved'
    const isJustApproved = this.state.status === 'justApproved'
    const isSunk = this.state.status === 'sunk'

    const classes = cx(
      'module',
      'sovietComment',
      'textBox',
      `is__${this.state.status}`
    )

    return (
      <div className={ classes } id={ `comment-${comment.id}` }>
        <SovietCommentAuthor comment={ comment } />

        <div className="module sovietComment-body">
          <div className="sovietComment-text"
            dangerouslySetInnerHTML={ this.buildCommentTextHTML() } />

          { comment.imageUrl &&
            <Image
              src={ comment.imageUrl }
              size={ comment.imageSize }
              className="sovietComment-asset"
              bordered
            />
          }
        </div>

        { !isJustApproved &&
          <footer className="sovietComment-footer">
            { isApproved &&
              <Impressions
                impressionSubject={ comment.impressionSubject }
                selected={ commentImpressions.selected }
                helpful={ commentImpressions.helpful }
                useless={ commentImpressions.useless }
                mod="small"
              />
            }
            { !isApproved &&
              <button
                className="sovietComment-approveButton button is__thin is__small"
                onClick={ this.approve }
              >
                Разрешить
              </button>
            }
            <Caption className="sovietComment-date">
              { birmanize(comment.createdAt, { format: 'dateYearOnly' }) }
            </Caption>

            { !props.isDefaultView &&
              <div className="sovietComment-actions">
                <button className="sovietComment-actionButton" onClick={ this.edit }>✎</button>
                { !isSunk &&
                  <button className="sovietComment-actionButton" onClick={ this.sink }>🗑</button>
                }
                { isSunk &&
                  <button className="sovietComment-actionButton" onClick={ this.unsink }>↺</button>
                }
              </div>
            }
          </footer>
        }

        { isJustApproved &&
          <footer className="sovietComment-footer">
            <Caption>Опубликован</Caption>
          </footer>
        }
      </div>
    )
  }
}
