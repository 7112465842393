function camelCase(str) {
  return str.replace(/[_.-](\w|$)/g, function(_, x) {
    return x.toUpperCase()
  })
}

module.exports = function(obj) {
  if (!obj) return obj

  if (typeof obj === 'string') return camelCase(obj)

  return Object.keys(obj).reduce((hash, key) => {
    hash[camelCase(key)] = obj[key]
    return hash
  }, {})
}
