const $backLink = $('meta[name="backLink"]')

if ($backLink.length) {
  const backTitleLinkHref = $backLink.attr('href')
  const backTitleLinkTitle = $backLink.attr('title')

  const linkWithArrows = [
    '<span class="backTitleLink-leftArrow">❰ </span>',
    backTitleLinkTitle,
    '<span class="backTitleLink-rightArrow"> ❱</span>',
  ].join('')

  const $backTitleLink = $(`
    <div class="backTitleLink">
      <div class="backTitleLink-in">
        <a class="no-u" href="${backTitleLinkHref}">${linkWithArrows}</a>
      </div>
    </div>
  `.trim())

  $('.heading-1, .product').first().before($backTitleLink)
}
