const CLASS_CHECKED = 'is__checked'

class SchoolTestAnswer {
  constructor($el, question) {
    this.$el = $el
    this.question = question
    this.test = question.test

    this.id = this.question.id + '.' + this.$el.data('id')
    this.isRight = parseInt(this.$el.data('correct'), 10)

    this.$el.attr('data-log-id', this.id)

    this._bindEvents()
  }

  init() {
    if (this.state.getAnswers().indexOf(this.id) > -1) {
      this.toggle()
    }
  }

  get state() {
    return this.test.state
  }

  get isCorrect() {
    if (this.isRight) return this.isChecked

    return !this.isChecked
  }

  get sameAnswers() {
    return this.question.answers.filter(answer => answer.id === this.id)
  }

  toggle() {
    const newValue = !this.isChecked

    this.sameAnswers.forEach(answer => {
      answer.isChecked = newValue
      answer.$el.toggleClass(CLASS_CHECKED, answer.isChecked)
    })

    this._record()
    this.$el.trigger('answerChecked')
  }

  lock() {
    this.$el.off('click.answer')
  }

  reset() {
    this.sameAnswers.forEach(answer => {
      answer.isChecked = false
      answer.$el.removeClass(CLASS_CHECKED)
    })

    this._record()
    this._bindEvents()
  }

  reload() {
    this.reset()
    this.init()
  }

  _record() {
    this.isChecked ? this.state.addAnswer(this.id) : this.state.removeAnswer(this.id)
  }

  _bindEvents() {
    this.$el.off('click.answer').on('click.answer', this.toggle.bind(this))
  }
}

module.exports = SchoolTestAnswer
