export default class SchoolTestRemoteState {
  constructor({ answers }) {
    this.answers = answers
  }

  getAnswers() { return this.answers }
  addAnswer() {}

  // Here is the tricky part: we can't remove answers from remote but have to do that during reset.
  // So we delegate #removeAnswer to local state
  removeAnswer(answerId) {
    window.application.state.removeAnswer(answerId)
  }
}
