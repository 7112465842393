import { Component } from 'preact'

export default class SovietAskFormLeadText extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <p className="sovietAskFormLeadText">
        { I18n.t('soviet.askForm.purpose') }
      </p>
    )
  }
}
