I18n.translations.ru.schoolTest = {
  results: {
    heading: '<span class="sign">✓ </span>Тест сдан',
    regular: {
      metDeadline: {
        hasErrors: '<div class="textNode is__firstChild is__lastChild"><p>Вы правильно ответили на %{correctQuestionsCount} %{correctQuestionsPlural} из %{totalQuestionsCount}, успели к дедлайну и заработали <b>%{score} %{scorePlural}</b> из <b>%{maxScore}</b>. Мы просим вас не обсуждать с другими студентами, как вы ответили на вопросы теста. Чтобы не провоцировать обсуждение, мы скрыли вопросы и ответы — вы увидите их после дедлайна.</p></div>',
        hasNoErrors: '<div class="textNode is__firstChild is__lastChild"><p>Вы успели к дедлайну, ответили правильно на все вопросы и заработали максимальные <b>%{score} %{scorePlural}</b>. От души поздравляем и желаем вам дальнейших успехов в школе!  Мы просим вас не обсуждать с другими студентами, как вы ответили на вопросы теста. Чтобы не провоцировать обсуждение, мы скрыли вопросы и ответы — вы увидите их после дедлайна.</p></div>',
      },
      missedDeadline: {
        hasErrors: '<div class="textNode is__firstChild is__lastChild"><p>Вы правильно ответили на %{correctQuestionsCount} %{correctQuestionsPlural} из %{totalQuestionsCount}. К сожалению, вы не успели к дедлайну и заработали <b>%{score} %{scorePlural}</b> из <b>%{maxScore}</b>. Напоминаем, что по правилам после трёх сорванных дедлайнов студенты отчисляются из школы.  Мы просим вас не обсуждать с другими студентами, как вы ответили на вопросы теста. Чтобы не провоцировать обсуждение, мы скрыли вопросы и ответы — вы увидите их после дедлайна.</p></div>',
        hasNoErrors: '<div class="textNode is__firstChild is__lastChild"><p>Вы ответили правильно на все вопросы, но, к сожалению, не успели к дедлайну и заработали только <b>%{score} %{scorePlural}</b> из <b>%{maxScore}</b>. Напоминаем, что по правилам после трёх сорванных дедлайнов студенты отчисляются из школы.  Мы просим вас не обсуждать с другими студентами, как вы ответили на вопросы теста. Чтобы не провоцировать обсуждение, мы скрыли вопросы и ответы — вы увидите их после дедлайна.</p></div>',
      },
    },
    bonus: {
      metDeadline: {
        hasErrors: '<div class="textNode is__firstChild is__lastChild"><p>Вы правильно ответили на %{correctQuestionsCount} %{correctQuestionsPlural} из %{totalQuestionsCount} и заработали <b>%{score} %{scorePlural}</b> из <b>%{maxScore}</b>. Мы просим вас не обсуждать с другими студентами, как вы ответили на вопросы теста в прошлый раз и теперь. Чтобы не провоцировать обсуждение исправлений и обновлённых тестов, мы скрываем вопросы и ответы после сдачи теста.</p></div>',
        hasNoErrors: '<div class="textNode is__firstChild"><p>Вы ответили правильно на все вопросы и заработали максимальные <b>%{score} %{scorePlural}</b>. От души поздравляем и желаем вам дальнейших успехов в школе!</p></div><div class="textNode is__lastChild"><p>Мы просим вас не обсуждать с другими студентами, как вы ответили на вопросы теста в прошлый раз и теперь. Чтобы не провоцировать обсуждение исправлений и обновлённых тестов, мы скрываем вопросы и ответы после сдачи теста.</p></div>',
      },
      missedDeadline: {
        hasErrors: '<div class="textNode is__firstChild is__lastChild"><p>Вы правильно ответили на %{correctQuestionsCount} %{correctQuestionsPlural} из %{totalQuestionsCount}. К сожалению, вы не успели к дедлайну и заработали <b>%{score} %{scorePlural}</b> из <b>%{maxScore}</b>. Напоминаем, что по правилам после трёх сорванных дедлайнов студенты отчисляются из школы.</p></div>',
        hasNoErrors: '<div class="textNode is__firstChild is__lastChild"><p>Вы ответили правильно на все вопросы, но, к сожалению, не успели к дедлайну и заработали только <b>%{score} %{scorePlural}</b> из <b>%{maxScore}</b>. Напоминаем, что по правилам после трёх сорванных дедлайнов студенты отчисляются из школы.</p></div>',
      },
    },
    factoid: '%{scorePlural} из %{maxScore}',
    cannotSubmit: {
      heading: '<span class="sign">× </span>Тест не сдан',
    },
  },
}
