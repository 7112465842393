import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { Heading3 } from '../textNodes'

import {
  changePaymentMethodToNewCard,
  changePaymentMethodToExistingCard,
  changePaymentMethodToInternationalCard,
} from './newPaybarActions'


class NewPaybarCardSwitch extends Component {
  get headingText() {
    return I18n.t('newPaybar.cardSwitch.heading')
  }

  get existingCardText() {
    const { userCardNumber } = this.props

    return I18n.t('newPaybar.cardSwitch.existingCard', { userCardNumber })
  }

  get hasExistingCard() {
    return !!this.props.userCardNumber
  }

  get cardText() {
    const card = this.hasExistingCard ? 'newCard' : 'card'
    return I18n.t(`newPaybar.cardSwitch.${card}`)
  }

  get internationalCardText() {
    const card = this.hasExistingCard ? 'newInternationalCard' : 'internationalCard'
    return I18n.t(`newPaybar.cardSwitch.${card}`)
  }

  get isHidden() {
    return this.hasExistingCard && !this.props.currentPaymentMethod
  }

  get isDefaultSwitch() {
    return !this.hasExistingCard && !this.props.currentPaymentMethod
  }

  get changeUrl() {
    const accessToken = this.props.accessToken || ''

    return `/pay/international/${accessToken}`
  }

  render() {
    const existingCardClasses = cx(
      'newPaybarSwitch-selectorItem',
      { is__active: this.props.currentPaymentMethod === 'existingCard' }
    )

    const newCardClasses = cx(
      'newPaybarSwitch-selectorItem',
      { is__lastItem: I18n.locale === 'en' },
      { is__active: this.props.currentPaymentMethod === 'newCard' || this.isDefaultSwitch }
    )

    const internationalCardClasses = cx(
      'newPaybarSwitch-selectorItem',
      { is__active: this.props.currentPaymentMethod === 'internationalCard' }
    )

    const switcherClasses = cx(
      'newPaybarSwitch is__cardSwitch',
      { is__hidden: this.isHidden }
    )

    return (
      <div className={ switcherClasses }>
        <Heading3 className="newPaybarSwitch-heading">
          { this.headingText }
        </Heading3>

        <div className="newPaybarSwitch-selector">
          { this.hasExistingCard &&
            <span
              className={ existingCardClasses }
              onClick={ this.props.changePaymentMethodToExistingCard }>
              { this.existingCardText }
            </span>
          }
          <span
            className={ newCardClasses }
            onClick={ this.props.changePaymentMethodToNewCard }>
            { this.cardText }
          </span>
          <span className="newPaybarSwitch-selectorItem"
            className={ internationalCardClasses }
            onClick={ this.props.changePaymentMethodToInternationalCard }>
            <a href={ this.changeUrl }>{ this.internationalCardText }</a>
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    currentPaymentMethod: newPaybar.currentPaymentMethod,
    userCardNumber: newPaybar.userCardNumber,
  }
}

const mapDispatchToProps = {
  changePaymentMethodToNewCard,
  changePaymentMethodToExistingCard,
  changePaymentMethodToInternationalCard,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarCardSwitch)
