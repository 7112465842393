import { Component } from 'preact'
import { connect } from 'preact-redux'
import { birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../../calendar/timezoneUtils'
import cx from 'classnames'

import { Heading2, TextNode } from '../../textNodes'
import LectureOnDevices from '../../lectureOnDevices/lectureOnDevices'

const I18N_BASE = 'newPaybar.successScreen.course'

class NewPaybarOverlayCourse extends Component {
  get resultScreenHeadingText() {
    const headingType = this.isSelfPurchase ? 'self' : this.props.type
    return I18n.t(`${I18N_BASE}.${headingType}.heading`)
  }

  get resultScreenText() {
    if (this.isPaidByInvoice) {
      const date = moscowifyLocal(new Date(this.props.productDetails.starts_at))
      const suffix = this.props.productDetails.missed_invoice_payment_deadline ?
        'BeforeDeadline' : 'OnWorkdays'

      return I18n.t(`${I18N_BASE}.${this.props.type}.invoicePayment${suffix}`,
        { date: birmanize(date, { format: 'fullMonthDateOnly' }) })
    }

    if (this.isSelfAuthenticated) return this.selfAuthenticatedText

    return I18n.t(`${I18N_BASE}.${this.props.type}.text`)
  }

  get resultScreenAccessText() {
    const { newSubscriptionCompany, presenteeEmail } = this.props
    let email = this.isSelfPurchase ? this.props.email : presenteeEmail
    email = this.isPaidByInvoice ? newSubscriptionCompany.email : email

    if (this.isSelfUnauthenticated) return this.selfUnauthenticatedAccessText

    return I18n.t(`${I18N_BASE}.${this.props.type}.accessText${this.companyPaymentTypeSuffix}`,
      { email })
  }

  get selfAuthenticatedText() {
    const key = this.isCurriculumPublished ? 'authenticatedText' : 'authenticatedTextNoLectures'

    return I18n.t(`${I18N_BASE}.self.${key}`, { date: this.startDate })
  }

  get selfUnauthenticatedAccessText() {
    const key = this.isCurriculumPublished ? 'accessText' : 'accessTextNoLectures'

    return I18n.t(`${I18N_BASE}.self.${key}`, { email: this.props.email, date: this.startDate })
  }

  get companyPaymentTypeSuffix() {
    if (this.props.type !== 'company') return ''

    return this.props.companyPaymentType === 'card' ? 'PaidByCard' : 'PaidByInvoice'
  }

  get startDate() {
    return birmanize(this.props.productDetails.starts_at, { format: 'fullMonthDateYearOnly' })
  }

  get isCurriculumPublished() {
    return this.props.productDetails.isCurriculumPublished
  }

  get hasSubscribedSelf() {
    if (this.props.type !== 'company') return false

    return this.props.companyPaymentType === 'card' && this.props.participantType === 'self'
  }

  get isSelfAuthenticated() {
    return this.isSelfPurchase && this.props.isUserAuthenticated
  }

  get isSelfUnauthenticated() {
    return (this.props.type === 'self' || this.hasSubscribedSelf) && !this.props.isUserAuthenticated
  }

  get isSelfPurchase() {
    return this.props.type === 'self' ||
      (this.props.type === 'company' && this.props.participantType === 'self')
  }

  get isPaidByInvoice() {
    return this.props.type === 'company' && this.props.companyPaymentType === 'invoice'
  }

  render() {
    const classNameOverlayLock = cx('newPaybarOverlay-lock', { is__disabled: !this.isSelfUnauthenticated })
    const cover = 'files/_cover.jpg'
    const accessHref = this.isSelfAuthenticated ? this.props.accessLink : null

    return (
      <div className="newPaybarOverlay-content">
        <div className="newPaybarOverlay-message">
          <Heading2 html={ this.resultScreenHeadingText }/>
          <TextNode html={ this.resultScreenText }/>
        </div>

        <LectureOnDevices
          cover={ cover }
          href={ accessHref }
          hasOnlyDesktop={ true }
          withMonitorIndent={ true }
        />

        { (!this.isSelfAuthenticated || this.isPaidByInvoice) &&
          <div className="newPaybarOverlay-access">
            { this.isSelfUnauthenticated &&
              <span className={ classNameOverlayLock }>
                { I18n.t('newPaybar.successScreen.lock') }
              </span>
            }
            <TextNode html={ this.resultScreenAccessText }/>
          </div>
        }

        { !this.isSelfAuthenticated &&
          <div className="newPaybarOverlay-stub"></div>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    productDetails: newPaybar.productDetails,
    newSubscriptionCompany: newPaybar.newSubscriptionCompany,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    companyPaymentType: newPaybar.companyPaymentType,
    participantType: newPaybar.participantType,
  }
}

export default connect(mapStateToProps)(NewPaybarOverlayCourse)
