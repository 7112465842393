import { spacerFonts } from './schoolStudentFeedbackSpacerFonts'
import fontsRoot from 'lib/current/fontsRoot'

const READY_CLASS = 'is__ready'

const url = fileName => `url(${fontsRoot}/${fileName})`

$('.js__schoolStudentFeedbackSpacer.is__svg').addClass(READY_CLASS)

$('.js__schoolStudentFeedbackSpacer.is__text').each((_, el) => {
  const $el = $(el)
  const template = $(el).data('template')
  const fontSettings = spacerFonts[template] || {}
  const font = new FontFace(fontSettings.name, url(fontSettings.fileName))

  document.fonts.add(font)
  font.load().then(() => $el.addClass(READY_CLASS))
})
