import { Component } from 'preact'
import { connect } from 'preact-redux'
import TextareaAutosize from 'react-textarea-autosize'
import cx from 'classnames'

import { setQuestion } from './sovietActions'

import { Cols } from '../cols'

const DIVIDER = '\u0020\u200B'

class SovietQuestionInput extends Component {
  constructor(props) {
    super(props)

    this.handleInput = this.handleInput.bind(this)
    this.handleCol1Input = this.handleCol1Input.bind(this)
    this.handleCol2Input = this.handleCol2Input.bind(this)
  }

  handleInput(event) {
    this.props.setQuestion(event.target.value)
  }

  handleCol1Input(event) {
    const combinedCols = `${event.target.value}${DIVIDER}${this.questionCol2}`
    this.props.setQuestion(combinedCols)
  }

  handleCol2Input(event) {
    const combinedCols = `${this.questionCol1}${DIVIDER}${event.target.value}`
    this.props.setQuestion(combinedCols)
  }

  get questionCol1() {
    return this.props.question.split(DIVIDER)[0]
  }

  get questionCol2() {
    return this.props.question.split(DIVIDER)[1] || ''
  }

  get unitedColsVal() {
    return this.props.question.replace(DIVIDER, '\n')
  }

  get className() {
    if (this.props.questionMinimumLines) {
      const lines = this.props.questionMinimumLines
      const count = lines.charAt(0).toUpperCase() + lines.slice(1)

      return `sovietQuestionInput-wrapper is__minimum${count}Lines`
    }

    return 'sovietQuestionInput-wrapper'
  }

  render(props) {
    if (props.hasQuestionCols) {
      return (
        <div className={ this.className }>
          <Cols transposeOnMobile>
            <TextareaAutosize
              className="sovietQuestionInput-text"
              placeholder="Вопрос"
              value={ this.questionCol1 }
              onInput={ this.handleCol1Input } />
            <TextareaAutosize
              className="sovietQuestionInput-text"
              value={ this.questionCol2 }
              onInput={ this.handleCol2Input } />
          </Cols>
        </div>
      )
    }

    return (
      <div className={ this.className }>
        <div className="sovietQuestionInput-singleColWrapper">
          <TextareaAutosize
            className="sovietQuestionInput-text"
            placeholder="Вопрос"
            value={ this.unitedColsVal }
            onInput={ this.handleInput } />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    question: soviet.question,
    hasQuestionCols: soviet.hasQuestionCols,
    questionMinimumLines: soviet.questionMinimumLines,
  }
}

const mapDispatchToProps = {
  setQuestion,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietQuestionInput)
