const throttle = require('throttle')

const VideoPlayerState = require('lib/videoPlayerState')

const SAVE_PROGRESS_RATE_MS = 1000

const nope = e => console.error(e) // eslint-disable-line no-console

class KinescopeVideo {
  constructor(el, options = {}) {
    this.el = el
    this.url = el.getAttribute('data-kinescope-url')
    this.id = el.getAttribute('data-kinescope-id')
    this.domId = `ki-${this.id}`
    this.startTime = el.getAttribute('data-kinescope-start')
    this.playerState = new VideoPlayerState({ id: `ki-${this.id}` })
    this.shouldSaveProgress = options.shouldSaveProgress
    this.shouldSavePlaybackRate = options.shouldSavePlaybackRate

    let startTime = (this.shouldSaveProgress ? this.playerState.progress : 0) || this.startTime

    Kinescope.IframePlayer
      .create(
        this.domId,
        { url: this.url + `?t=${startTime}`, localStorage: false, preload: true }
      )
      .then(player => { this.player = player; this.setup() })
  }

  setup() {
    this.el.addEventListener('playNow', this.player.play.bind(this.player))

    if (this.shouldSavePlaybackRate) {
      this.playbackRate = this.playerState.playbackRate
      this.player.setPlaybackRate(this.playbackRate).catch(nope)

      this.player.on(this.player.Events.PlaybackRateChange, this.savePlaybackRate.bind(this))

      document.addEventListener('playbackRateChange', this.onPlaybackRateChange.bind(this))
    }

    if (this.shouldSaveProgress) {
      this.player.on(
        this.player.Events.TimeUpdate,
        throttle(SAVE_PROGRESS_RATE_MS, this.saveProgress.bind(this))
      )
    }
  }

  onPlaybackRateChange(e) {
    const { playbackRate, id } = e.detail

    if (this.id !== id && playbackRate !== this.playbackRate) {
      this.player.setPlaybackRate(playbackRate).catch(nope)
    }
  }

  saveProgress({ data: { seconds, duration } }) {
    this.playerState.saveProgress({ seconds, duration })
  }

  savePlaybackRate({ data: { playbackRate } }) {
    this.playbackRate = playbackRate
    this.playerState.savePlaybackRate(playbackRate)
    this.syncPlaybackRates()
  }

  syncPlaybackRates() {
    const { playbackRate, id } = this
    const event = new CustomEvent('playbackRateChange', { detail: { playbackRate, id } })

    document.dispatchEvent(event)
  }
}

module.exports = KinescopeVideo
