import { Component } from 'preact'

import cx from 'classnames'
import BadgePhone from '../../badgePhone/badgePhone'
import { Caption } from '../../textNodes'

export default class NewPaybarMobileVersionCaption extends Component {
  constructor(props) {
    super(props)
  }

  get lastBook() {
    return this.props.books.slice(-1).pop()
  }

  get captionText() {
    if (this.props.books.length > 1) {
      return I18n.t('newPaybar.hasMobileVersion.many')
    }

    return I18n.t('newPaybar.hasMobileVersion.single', { bookTitle: this.lastBook.title })
  }

  get bookCoverUrl() {
    if (this.props.books.length > 1) {
      return null
    }

    return `/projects/book-${ this.lastBook.key }/files/_book-cover-face.jpg`
  }

  render() {
    const bookClassName = cx('newPaybarMobileVersionCaption-book', {
      is__empty: !this.bookCoverUrl,
    })

    return (
      <div className="newPaybarMobileVersionCaption">
        <div className={ bookClassName } style={ `background-image: url(${ this.bookCoverUrl })` }>
          <BadgePhone />
        </div>
        <div className="newPaybarMobileVersionCaption-text">
          <Caption html={ this.captionText } />
        </div>
      </div>
    )
  }
}
