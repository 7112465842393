{
  const BaseModule = require('_base/base');

  class AlignCaptions extends BaseModule {
    preInit() {
      this.isFinal = true;
    }

    postInit() {
      this.$captions = this.$el.find('.caption');
    }

    preRender() {
      this.$captions.css('min-height', 0);
    }

    postCalculate() {
      this.maxHeight = 0;

      this.$captions.each((i, el) => {
        const captionHeight = $(el).height();

        if (this.maxHeight < captionHeight) {
          this.maxHeight = captionHeight;
        }
      });
    }

    postRender() {
      this.$captions.css('height', this.maxHeight);
    }
  }

  $('.js__alignCaptions').each(function() {
    new AlignCaptions($(this));
  });
}
