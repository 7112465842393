
const petrovich = require('petrovich')

const Hyphenator = require('./vendor/hyphenator')
const SovietColumn = require('./sovietColumn')
const sovietAuthors = require('./current/sovietAuthors')

const hyphenator = Hyphenator

hyphenator.languages.ru.leftmin = 3
hyphenator.languages.ru.rightmin = 3
hyphenator.config({
  minwordlength: 5,
  orphancontrol: 2,
})

class Soviet {
  constructor(data) {
    this.slug = data.slug
    this.url = data.url ? (data.url + '').replace(/http:\/\//, '//') : this.urlFromSlug()
    this.title = data.title
    this.compiledTitle = data.compiledTitle || data.title
    this.author = data.author || 'А. Г.'
    this.concepts = this._extractConcepts(data.concepts || data.tags) || []
    this.coverUrl = data.coverUrl
    this.coverSize = data.coverSize || [0, 0]
    this.commentsCount = parseInt(data.commentsCount, 10) || 0
    this.newCommentsCount = parseInt(data.newCommentsCount, 10) || 0
    this.date = data.date || data.publishAt
    this.impressionSubject = data.impressionSubject || ''
    this.impressionSummary = data.impressionSummary || {}
    this.tagSummaries = data.tagSummaries || []
    this.isUnicorn = data.isUnicorn
    this.coverBgColor = sovietAuthors[this.author] ? sovietAuthors[this.author].coverBgColor : 'inherit'
  }

  get column() {
    return (new SovietColumn(this)).value
  }

  get authorGenitiveName() {
    if (this.key === '20170323') return 'Половнёва и Кулматова'
    if (this.author.includes('.')) return this.author
    if (this.author === 'Рецепты') return 'рецептов'

    const lastname = this.author.split(' ')[1]

    if (/(на|ва)$/.test(lastname)) {
      return petrovich.female.last.genitive(lastname)
    }

    return petrovich.male.last.genitive(lastname)
  }

  get hyphenatedTitle() {
    return hyphenator.hyphenate(this.title + '', 'ru')
  }

  get hyphenatedCompiledTitle() {
    return hyphenator.hyphenate(this.compiledTitle + '', 'ru')
  }

  get key() {
    const parts = this.url.split('/')
    const date = parts.find(part => /[0-9]{6}/.test(part))
    const suffix = parts.find(part => /^[0-9]{1}$/.test(part))

    return [date, suffix].filter(part => part).join('/')
  }

  urlFromSlug() {
    if (!this.slug) return ''

    return `//bureau.ru/soviet/${this.slug}/`
  }

  toJSON() {
    return this.asJson()
  }

  asJson() {
    return Object.assign(
      {},
      {
        url: this.url,
        title: this.title,
        compiledTitle: this.compiledTitle,
        author: this.author,
        key: this.key,
        column: this.column,
        date: this.date,
        authorGenitiveName: this.authorGenitiveName,
        hyphenatedTitle: this.hyphenatedTitle,
        hyphenatedCompiledTitle: this.hyphenatedCompiledTitle,
        coverUrl: this.coverUrl,
        coverSize: this.coverSize,
        commentsCount: this.commentsCount,
        newCommentsCount: this.newCommentsCount,
        impressionSubject: this.impressionSubject,
        impressionSummary: this.impressionSummary,
        tagSummaries: this.tagSummaries,
        isUnicorn: this.isUnicorn,
        coverBgColor: this.coverBgColor,
      }
    )
  }

  _extractConcepts(concepts) {
    return (concepts + '').trim()
      .split(';')
      .map((concept) => {
        return concept
          .replace(/^\s+/g, '')
          .replace(/\s+$/g, '')
          .replace(/\s+/g, ' ')
          .trim()
      })
      .filter(concept => (concept + '').trim().length > 0)
  }
}

Soviet.fromJSON = (data) => {
  return new Soviet({
    url: data.url,
    slug: data.slug,
    title: data.title || data.mainThink || data.main_think,
    compiledTitle: data.compiledTitle || data.compiled_title,
    author: data.author,
    concepts: (data.tags || data.concepts || []).join(';'),
    coverUrl: data.coverUrl || data.headerImageUrl,
    coverSize: data.coverUrl ? data.coverSize : data.headerImageSize,
    commentsCount: data.approvedCommentCount || data.approved_comment_count || data.comment_cnt,
    newCommentsCount: data.newCommentCount || data.new_comment_count || data.comment_cnt_new,
    date: new Date(data.date || data.publishAt || data.publish_at),
    impressionSubject: data.impressionSubject || data.impression_subject || '',
    impressionSummary: data.impressionSummary || data.impression_summary || {},
    tagSummaries: data.tagSummaries || data.tag_summaries || [],
    isUnicorn: data.unicorn,
    coverBgColor: data.coverBgColor,
  })
}

Soviet.dummy = new Soviet({
  url: 'https://bureau.ru/bb/soviet/20150720/',
  title: 'От чего зависит успех продукта?',
  compiledTitle: 'От чего зависит успех продукта?',
  author: 'А. Г.',
  concepts: 'Дизайн продуктов',
  coverUrl: 'https://bureau.ru/bb/soviet/20150720/rams.jpg',
  coverSize: [832, 1170],
})


module.exports = Soviet
