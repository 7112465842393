import BookshelfPricingFooter from './bookshelfPricingFooter'

const { productSubscription } = window

if (productSubscription) {
  $('.js__bookshelfPricingFooter').each(function() {
    const prices = productSubscription.prices
    const productId = productSubscription.subscription.product_id

    const pricingFooterWrapper = $(this).find('.bookSubscription-info')

    pricingFooterWrapper.reactify(BookshelfPricingFooter, { productId, prices })
  })
}
