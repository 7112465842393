export const MIN_DESKTOP_WIDTH = 961

export const isOnSafari = () => {
  const ua = navigator.userAgent.toLowerCase()

  if (ua.indexOf('chrome') > -1) return false

  return ua.indexOf('safari') !== -1
}

export const isOnMobile = () => window.innerWidth < MIN_DESKTOP_WIDTH
