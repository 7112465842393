import {
  DesignersFunicularIcon,
  EditorsEscalatorIcon,
  ManagersCartIcon,
  DesignersChaletIcon,
  EditorsMushroomIcon,
  ManagersLegoIcon,
  DesignersBellIcon,
  EditorsBellIcon,
  ManagersMegaphoneIcon,
} from '../icons'

const ICONS_MAP = {
  designersFunicularIcon: DesignersFunicularIcon,
  editorsEscalatorIcon: EditorsEscalatorIcon,
  managersCartIcon: ManagersCartIcon,
  designersChaletIcon: DesignersChaletIcon,
  editorsMushroomIcon: EditorsMushroomIcon,
  managersLegoIcon: ManagersLegoIcon,
  designersBellIcon: DesignersBellIcon,
  editorsBellIcon: EditorsBellIcon,
  managersMegaphoneIcon: ManagersMegaphoneIcon,
}

const SchoolIcon = (props) => {
  const Icon = ICONS_MAP[props.iconKey]

  return (
    <a href={ props.href } className="schoolSpacerIcon doubleHover is__noUnderline">
      <Icon />
    </a>
  )
}

export default SchoolIcon
