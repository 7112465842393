import restoreInitialPrices from './helpers/restoreInitialPrices'
import isSubscriptionCancelled from './isSubscriptionCancelled'
import isSubscribed from './isSubscribed'

export function revertStateToInitial(state) {
  return {
    ...state,
    screen: 'initial',
    activeBasementItem: undefined,
    userEmailNotice: null,
    noticeType: null,
    accessToken: null,
    cardIframeUrl: null,
    currentPaymentMethod: null,
    isCardFormValid: false,
    isValid: false,
    presenteeEmail: null,
    hasSubscribedSelf: false,
    newSubscriptionValidUntil: null,
    newSubscriptionCompany: null,
    isUserAuthenticated: false,
    userEmail: null,
    userName: null,
    userFirstName: null,
    userCardNumber: null,
    hasFormContent: false,
    dirtyForms: [],
    prices: restoreInitialPrices(state.prices),
    subscription: {
      product_id: state.subscription.product_id,
      product: state.subscription.product,
    },
  }
}

export function removeDirtyForm(state) {
  const dirtyForms = state.dirtyForms.slice()
  const removeIndex = dirtyForms.findIndex(form => form === state.type)

  dirtyForms.splice(removeIndex, 1)

  return dirtyForms
}

export function addDirtyForm(state) {
  let dirtyForms = state.dirtyForms.slice()
  const currentFormType = state.type
  const isFormDirty = dirtyForms.includes(currentFormType)

  if (isFormDirty) dirtyForms = removeDirtyForm(state)

  dirtyForms.push(currentFormType)

  return dirtyForms
}

function getSelfScreen(state) {
  const { isUserAuthenticated, subscription } = state

  if (!isUserAuthenticated && isSubscribed(state)) return 'subscription'

  if (!isUserAuthenticated) return 'initial'

  if (isSubscribed(state) || isSubscriptionCancelled(subscription)) return 'subscription'

  if (subscription.subscription_type === 'employee') return 'subscription'

  return 'purchase'
}

export function getGiftScreen(state) {
  let newGiftScreen = state.screen

  if (state.isUserAuthenticated) {
    newGiftScreen = 'purchase'
  } else if (state.hasSubscribedSelf) {
    newGiftScreen = 'initial'
  }

  return newGiftScreen
}

export function getTypeToOpen(state) {
  if (state.hasCompanyTabOnly) return 'company'

  if (state.prices.specialOffer) return 'upgrade'

  if (state.dirtyForms.length === 0 || state.dirtyForms.includes('self')) {
    return 'self'
  }

  return state.dirtyForms.slice(-1)[0]
}

export function getScreenToOpen(state) {
  const typeToOpen = getTypeToOpen(state)

  if (typeToOpen === 'company' && state.hasCompanyTabOnly) return 'subscription'

  if (typeToOpen === 'gift') return getGiftScreen(state)

  return getSelfScreen(state)
}

export function getBasementItemToOpen({ subscription }) {
  if (isSubscriptionCancelled(subscription)) return 'lastChance'

  return undefined
}

export function isValid(params) {
  const {
    screen,
    isFormValid,
    isCardFormValid,
    currentPaymentMethod,
    userCardNumber,
    companyPaymentType,
  } = params

  if (screen === 'subscription') return isFormValid

  const isUsingExistingCard = currentPaymentMethod === 'existingCard' ||
                              currentPaymentMethod === undefined

  if (isUsingExistingCard && userCardNumber) return isFormValid

  if (companyPaymentType === 'invoice') return isFormValid

  return isFormValid && isCardFormValid
}

export function setScreenSuccess(state, { subscription, presenteeEmail }) {
  const hasUpgraded = state.type === 'upgrade'
  const hasSubscribedSelf = state.type === 'self' || hasUpgraded

  const { prices } = state
  if (hasUpgraded) {
    prices.previousSpecialOffer = prices.specialOffer
    prices.specialOffer = null
  }

  const result = {
    ...state,
    type: hasUpgraded ? 'self' : state.type,
    screen: 'success',
    activeBasementItem: undefined,
    isLoading: false,
    newSubscriptionValidUntil: subscription.validUntil,
    newSubscriptionCompany: subscription.company,
  }

  if (hasSubscribedSelf) {
    return {
      ...result,
      prices,
      subscription,
      hasSubscribedSelf: true,
    }
  }

  return { ...result, presenteeEmail }
}
