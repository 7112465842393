import { utmSource } from 'lib/helpers/linksHelper'

import LinkWithArrow from '../linkWithArrow'

export const collectureHref = id => {
  const source = utmSource({
    platform: 'Сайт бюро',
    postType: 'Банер',
    productType: 'Лекции',
    product: id,
    postDate: new Date(),
  })

  return `/lectures/${id}/?utm_source=${source}`
}

export const CollectureSpacerLink = props => (
  <LinkWithArrow
    {...props}
    isNoVisited
    isDoubleHover
    text={ I18n.t('collectures.spacer.link') }
    isSmall={ props.isSmall }
    isInline={ props.isInline }
  />
)
