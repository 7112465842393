import { Component } from 'preact'
import cx from 'classnames'

import { Heading2, TextNode } from '../textNodes'
import isBookshelfAll from 'lib/isBookshelfAll'
import { isOnMobile } from 'lib/envHelpers'
import NewPaybarShelf from '../newPaybar/newPaybarShelf'
import NewPaybarShelfDummy from '../newPaybar/newPaybarShelfDummy'
import GetGiftPresentOverlay from './getGiftPresentOverlay'

export default class GetGiftPresent extends Component {
  constructor(props) {
    super(props)

    const { shouldDisplayDummyShelf } = this
    this.state = { shouldDisplayDummyShelf }
  }

  componentDidMount() {
    $(document).on('appResize', this.setDummyShelfDisplay.bind(this))
  }

  setDummyShelfDisplay() {
    const { shouldDisplayDummyShelf } = this

    if (this.state.shouldDisplayDummyShelf !== shouldDisplayDummyShelf) {
      this.setState({ shouldDisplayDummyShelf })
    }
  }

  get shouldDisplayDummyShelf() {
    return isBookshelfAll(this.props.productId) && isOnMobile()
  }

  get accessUrl() {
    const { product, productId, productKey } = this.props

    return (product === 'bookshelf' ? `/projects/${productId}/?self` : `/books/${productKey}/`)
  }

  get bookCover() {
    return `/projects/${this.props.productId}/files/_book-cover-face.jpg`
  }

  render() {
    const { product, productId, books, canRead, email, keyColor } = this.props
    const { shouldDisplayDummyShelf } = this.state
    const shouldDisplayFullShelf = (product === 'bookshelf') && !shouldDisplayDummyShelf
    const classNames = cx('getGiftPresent', `is__${product}`, {
      is__cannotRead: !canRead,
      is__dummyShelf: shouldDisplayDummyShelf,
    })

    return (
      <div className={ classNames } >
        <div className="getGiftPresent-in">
          <div className="getGiftPresent-title">
            <Heading2 html={ I18n.t('getGift.openedTitle') } />
            <TextNode html={ I18n.t('getGift.openedSubtitle') } />
          </div>
          <a className="getGiftPresent-product no-u" href={ this.accessUrl }>
            { product === 'book' &&
              <img className="getGiftBook" src={ this.bookCover } />
            }

            { shouldDisplayFullShelf &&
              <NewPaybarShelf height={180} product="bookshelf" books={ books } />
            }

            { shouldDisplayDummyShelf && <NewPaybarShelfDummy productId={ productId } isLarge /> }
          </a>
        </div>

        <GetGiftPresentOverlay
          canRead={ canRead }
          email={ email }
          product={ product }
          accessUrl={ this.accessUrl }
          keyColor={ keyColor } />
      </div>
    )
  }
}
