import { Component } from 'preact'

import { Image } from '../image'

import CourseTitle from './courseTitle'

const IMAGE_PROPORTIONS = {
  desktop: [514, 279],
  laptop: [543, 288],
  mobile: [158, 237],
}

const toUrl = ({ key }) => `/lectures/${key}/`

export default class CourseSkinCover extends Component {
  render() {
    if (!this.props.course) return null

    const { key, cover, title, authorInstrumentalCase } = this.props.course
    const { device } = this.props

    return (
      <div className="courseSkinCover courseItem">
        <a href={ toUrl({ key }) } className="module doubleHover no-u">
          <Image className="courseCover" src={ cover } size={ IMAGE_PROPORTIONS[device] } />
          <CourseTitle title={ title } author={ authorInstrumentalCase } />
        </a>
      </div>
    )
  }
}
