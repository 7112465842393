const equalize = () => {
  $('.js__equalize').each((_, container) => {
    const parts = $(container).find('.is__equalized').get()
    parts.forEach(el => $(el).css({ height: 'auto' }))

    const maxHeight = Math.max(...parts.map(part => part.offsetHeight))

    parts.forEach(el => $(el).css({ height: `${maxHeight}px` }))
  })
}

module.exports = () => {
  $(document).on('appReady appResize', equalize)
}

