const monthsParentalCaseList = [
  'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
  'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря',
]

const shortMonths = [
  'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
  'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек',
]

const lowerify = stringsArray => stringsArray.map(s => s.toLowerCase())

const DOTTED_MONTH_DATE_REGEXP =
  new RegExp(/^([0]?[1-9]|[12]\d|3[01])\.([0]?[1-9]|1[0-2])\.?(\d{4})?$/)

const WORD_MONTH_DATE_REGEXP =
  new RegExp(`^([0]?[1-9]|[12]\\d|3[01])[ | ](${
    lowerify([...monthsParentalCaseList, ...shortMonths]).join('|')
  })[ | ]?(\\d{4})?$`)

const recognizeMonth = month => Math.max(
  lowerify(monthsParentalCaseList).indexOf(month),
  lowerify(shortMonths).indexOf(month)
)

const getMonthNumber = month => isNaN(month) ? recognizeMonth(month) : month

const getYear = year => year || new Date().getFullYear()

const makeDate = (day, month, year) => (
  new Date(getYear(year), getMonthNumber(month), day))

const tryParse = (date, regexp, options) => {
  const parsed = regexp.exec(date)

  return parsed ? makeDate(parsed[1], parsed[2], parsed[3] || options?.year) : date
}

const increaseMonth = date => {
  const cloned = date

  cloned.setMonth(cloned.getMonth() - 1)
  return cloned
}

const omitIfAlreadyParsed = fn => (date, options) => typeof date === 'string' ? fn(date, options) : date

const parseDotted = date => {
  const parsed = tryParse(date, DOTTED_MONTH_DATE_REGEXP)

  if (typeof parsed === 'string') {
    return parsed
  }

  return increaseMonth(parsed)
}

const parseWordMonth = (date, options) => tryParse(date, WORD_MONTH_DATE_REGEXP, options)

const pipeThroughParsers = (date, options) => omitIfAlreadyParsed(parseDotted)(omitIfAlreadyParsed(parseWordMonth)(date, options))

omitIfAlreadyParsed(parseWordMonth)(omitIfAlreadyParsed(parseDotted))

const parseDate = (date, options = {}) => {
  const value = pipeThroughParsers(date.toLowerCase(), options)
  const isValid = typeof value !== 'string'

  return {
    value,
    isValid,
  }
}

module.exports = {
  lowerify,
  parseDate,
}
