import { Component } from 'preact'
import cx from 'classnames'

const EMAIL_TEMPLATE = /\S+@\S+\.\S+/

const MIN_LOADING_TIME = 300
const DELAY_BEFORE_LOADING = 100
const SUBMISSION_DELAY = 1500

const STATE_TO_CLASS = {
  default: '',
  disabled: 'is__disabled',
  invalid: 'has__error',
  loading: 'is__loading',
  loaded: 'is__loaded',
  submitted: 'is__submitted',
  subscribed: 'is__subscribed',
}

const ___ = window.___

const findInitialEmail = () => {
  return (___ && ___.getCookie && ___.getCookie('email')) ||
    (window.URLSearchParams ? new URLSearchParams(window.location.search).get('presetEmail') : '') ||
    ''
}

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)

class EmailForm extends Component {
  constructor(props) {
    super(props)

    const initialEmail = props.email || findInitialEmail()
    let formState = 'disabled'

    if (initialEmail) formState = 'default'
    if (props.isSubscribed) formState = 'subscribed'

    this.state = {
      email: initialEmail,
      formState: formState,
    }

    this.afterSubmitState = 'submitted'
    this.loadingStartTime = 0

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.waitForPreloaderAndHandleResponse = this.waitForPreloaderAndHandleResponse.bind(this)

    this.onSubmit = this.props.onSubmit || function() { return Promise.resolve() }

    this.placeholder = I18n.t('emailForm.placeholder')
  }

  componentDidUpdate(_, prevState) {
    if (this.props.isSubscribed) this.afterSubmitState = 'subscribed'

    const hasStateChanged = prevState.formState !== this.state.formState

    Object.keys(STATE_TO_CLASS).forEach(formState => {
      const callbackName = `onFormState${capitalize(formState)}`
      const callback = this.props[callbackName]

      if (hasStateChanged && this.state.formState === formState && callback) callback()
    })
  }

  handleSubmit(e) {
    if (this.isLoading) return

    e.preventDefault()

    if (!EMAIL_TEMPLATE.test(this.state.email)) return this.setState({ formState: 'invalid' })

    this.hideIosKeyboard()
    this.cleanUpCurrentLoadingTimeouts()
    this.scheduleLoadingIndicator()

    this.isLoading = true
    this.onSubmit({ email: this.state.email })
      .then(this.waitForPreloaderAndHandleResponse)
      .catch(this.waitForPreloaderAndHandleResponse)
  }

  hideIosKeyboard() {
    if (document.activeElement) document.activeElement.blur()
  }

  cleanUpCurrentLoadingTimeouts() {
    this.loadingStartTime = 0

    clearTimeout(this.loadingTimeout)
    clearTimeout(this.loadingDelayTimeout)
    clearTimeout(this.submittedTimeout)
  }

  scheduleLoadingIndicator() {
    this.loadingDelayTimeout = setTimeout(() => {
      this.setState({ formState: 'loading' })
      this.loadingStartTime = new Date().getTime()
    }, DELAY_BEFORE_LOADING)
  }

  waitForPreloaderAndHandleResponse() {
    this.isLoading = false

    clearTimeout(this.loadingDelayTimeout)

    this.loadingTimeout = setTimeout(() => {
      this.setState({ formState: 'loaded' })
      this.submittedTimeout = setTimeout(() => {
        this.setState({ formState: this.afterSubmitState })
      }, SUBMISSION_DELAY)
    }, this.preloaderDisplayDuration)
  }

  get preloaderDisplayDuration() {
    if (!this.loadingStartTime) return 0

    return MIN_LOADING_TIME - (new Date().getTime() - this.loadingStartTime)
  }

  handleInput(e) {
    const email = e.target.value.toLowerCase()
    const formState = email.trim().length ? 'default' : 'disabled'

    this.setState({ email, formState })
  }

  handleFocus(e) {
    // HACK: чтобы клавиатура не перекрывала инпут
    if (window.application.isOnIOS) {
      e.target.scrollIntoView()
    }
  }

  render(props) {
    const className = cx(
      'subscriptionForm',
      STATE_TO_CLASS[this.state.formState],
      props.className,
      { has__buttonText: props.buttonText }
    )

    const buttonClassName = cx(
      'button',
      'subscriptionForm-submit',
      { is__thin: props.thinButton }
    )

    return (
      <form onSubmit={ this.handleSubmit } className={ className }>
        <div className="subscriptionForm-inputWrapper">
          <input
            type="email"
            name="email"
            autoComplete={ props.autoComplete === undefined ? 'email' : props.autoComplete }
            value={ this.state.email }
            onInput={ this.handleInput }
            onFocus={ this.handleFocus }
            ref={ props.inputRef }
            className="subscriptionForm-input"
            placeholder={ this.placeholder } />
        </div>

        <button className={ buttonClassName } type="submit">
          { props.buttonText &&
            <span className="subscriptionForm-submitText is__text">{props.buttonText}</span>
          }
          <span className="subscriptionForm-submitText is__default">→</span>
          <span className="subscriptionForm-submitText is__success">✓</span>
          <span className="subscriptionForm-submitText is__loading"></span>
          <span className="subscriptionForm-submitText is__reset">↻</span>
        </button>
      </form>
    )
  }
}

module.exports = EmailForm
