import { Component } from 'preact'

import { Caption } from '../textNodes'

export default class NewPaybarNewCardCaption extends Component {
  render() {
    return (
      <Caption className="newPaybarNewCardCaption">
        { I18n.t('newPaybar.newCardCaption') }
      </Caption>
    )
  }
}
