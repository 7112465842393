let fontCheckInterval
let forcedRenderTimeout

const CHECK_INTERVAL = 25
const MAX_FONTS_WAIT = 4000
const supportsFontsReady = 'fonts' in document

module.exports = function(callback) {
  const userAgent = navigator.userAgent.toLowerCase()
  let isRendered

  const $fontCheckers = $('.js__font-checker')
  const fonts = $.makeArray($fontCheckers.map((_, el) => {
    return {
      $el: $(el),
      minWidth: +$(el).attr('data-min-width'),
      maxWidth: +$(el).attr('data-max-width'),
      name: $(el).attr('class'),
    }
  }))

  const doRender = () => {
    if (isRendered) return

    clearInterval(fontCheckInterval)
    $fontCheckers.hide()
    isRendered = true

    callback()
  }

  const isLoaded = (font) => {
    if (font.minWidth) return font.$el.width() >= font.minWidth
    if (font.maxWidth) return font.$el.width() <= font.maxWidth

    return true
  }

  if (userAgent.indexOf('safari') < 0) {
    doRender()
    return
  }

  forcedRenderTimeout = setTimeout(doRender, MAX_FONTS_WAIT)

  fontCheckInterval = setInterval(function() {
    let allFontsRendered = true

    fonts.forEach(font => {
      if (!isLoaded(font)) {
        allFontsRendered = false
        font.old = font.$el.width()
      }
    })

    if (allFontsRendered) {
      clearInterval(fontCheckInterval)
      clearTimeout(forcedRenderTimeout)

      if (supportsFontsReady) {
        document.fonts.ready.then(function() { setTimeout(doRender, CHECK_INTERVAL) })
      } else {
        doRender()
      }
    }
  }, CHECK_INTERVAL)
}
