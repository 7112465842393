import { Component } from 'preact'

import { Cols } from '../cols'
import { Caption } from '../textNodes'
import { Image } from '../image'

import CourseTitle from './courseTitle'

const MAX_LECTURES_IN_COURSE = 3
const LECTURE_COVER_SIZE = [1280, 720]
const PARTS_PROPORTIONS = {
  desktop: '2:2',
  laptop: '4:2',
  mobile: '1:1',
}

const toUrl = ({ key }) => `/lectures/${key}/`

const LectureInCourse = ({ lecture, device }) => {
  if (!lecture) return null

  const { hyphenatedTitle, duration, cover } = lecture

  return (
    <Cols className="lecturesList-item" transposeOnMobile divisionProportions={ PARTS_PROPORTIONS[device] }>
      <Image className="lectureCover is__noMargins" src={ cover } size={ LECTURE_COVER_SIZE } />
      <Caption>
        <span>{ hyphenatedTitle }</span>
        <span className="lectureDuration">{ duration }</span>
      </Caption>
    </Cols>
  )
}

export default class CourseSkinList extends Component {
  get lecturesList() {
    const { units } = this.props.course

    return units
      .map(unit => unit.assets.filter(asset => asset.type === 'lecture'))
      .flat()
      .slice(0, MAX_LECTURES_IN_COURSE)
  }

  render() {
    if (!this.props.course) return null

    const { key, title, authorInstrumentalCase, units } = this.props.course
    const { device } = this.props

    return (
      <div className="courseSkinList cutOffBelow courseItem">
        <a href={ toUrl({ key }) } className="module doubleHover no-u">
          <CourseTitle title={ title } author={ authorInstrumentalCase } />

          { units &&
            <div className="lecturesList">
              { this.lecturesList.map(lecture => {
                return (<LectureInCourse lecture={ lecture } device={ device } />)
              })}
            </div>
          }
        </a>
      </div>
    )
  }
}
