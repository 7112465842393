import { Component } from 'preact'

import cx from 'classnames'

class DiscountBannerText extends Component {
  get hasLongText() {
    const { productType } = this.props

    return productType === 'prep' || productType === 'bookshelf'
  }

  render() {
    const classes = cx(
      'discountSpacer-text',
      {
        has__longText: this.hasLongText,
        has__customText: this.props.hasCustomText,
      }
    )

    return (
      <div className={ classes }>
        <div
          className="device laptop desktop"
          dangerouslySetInnerHTML={{ __html: this.props.desktopText }} />

        <div
          className="device mobile"
          dangerouslySetInnerHTML={{ __html: this.props.mobileText }} />
      </div>
    )
  }
}

export default DiscountBannerText
