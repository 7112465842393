import Upload from './upload'

{
  const $webpage = $('.webpage')
  const mods = $webpage.attr('mod')
  const isUploadEnabled = !(mods && mods.indexOf('uploadDisabled') >= 0)

  $('.js__upload').each(function() {
    $(this).reactify(Upload, {
      isEnabled: window.upload.isEnabled,
      files: window.upload.files,
      apiUrl: window.upload.apiUrl,
      authHeaders: {
        'X-User-Email': $('meta[name="email"]').attr('content'),
        'X-User-Token': $('meta[name="token"]').attr('content'),
      },
      sessionId: `sid${Math.random().toString(10).slice(2)}`,
      isUploadEnabled: isUploadEnabled,
    })
  })

  class StickyUploadBox {
    constructor($el) {
      this.$el = $el
      this.$document = $(document)

      this.SLIDE_IN_THRESHOLD = 50

      this._setListeners()
    }

    _setListeners() {
      this.$document.on('appReady', () => {
        if (window.application.currentScrollPosition <= this.SLIDE_IN_THRESHOLD) {
          this._hide()
        } else {
          this._show()
        }
      })

      this.$document.on('appScroll', (__, position) => {
        if (position > this.SLIDE_IN_THRESHOLD) {
          this._show()
        }
      })
    }

    _show() {
      this.$el.css({'bottom': '9px'})
    }

    _hide() {
      this.$el.css({'bottom': '-1000px'})
    }
  }

  $('.uploadColWrapper.stickyWrapper').each(function() {
    new StickyUploadBox($(this))
  })
}
