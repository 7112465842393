import { Component } from 'preact'
import cx from 'classnames'

import { Heading3 } from '../../textNodes'

export default class NewPaybarPaymentSwitch extends Component {
  render() {
    const isMonthly = this.props.payment === 'monthly'
    const oneTimeClasses = cx('newPaybarSwitch-selectorItem', { is__active: !isMonthly })
    const monthlyClasses = cx('newPaybarSwitch-selectorItem', { is__active: isMonthly })

    return (
      <div className="newPaybarSwitch">
        <Heading3 className="newPaybarSwitch-heading">
          { I18n.t('newPaybar.school.payment') }
        </Heading3>
        <div className="newPaybarSwitch-selector">
          <span className={ oneTimeClasses } onClick={ this.props.onSetOneTime }
            dangerouslySetInnerHTML={{ __html: I18n.t('newPaybar.school.oneTime') }}>
          </span>
          <span className={ monthlyClasses } onClick={ this.props.onSetMonthly }>
            { I18n.t('newPaybar.school.monthly') }
            </span>
        </div>
      </div>
    )
  }
}
