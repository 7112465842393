const BaseModule = require('_base/base')

import Anchor from './anchor'

const MIN_SCROLL_HEIGHT = 75

class Anchors extends BaseModule {
  get currentScrollPosition() {
    return window.application.currentScrollPosition
  }

  preInit() {
    this.isFinal = true

    this.$document = $(document)
    this.scrollTop = 0
  }

  preCalculate() {
    this.buildAnchors()
    this.bindEvents()
  }

  preRender() {
    this.setActive()
  }

  buildAnchors() {
    this.anchors = Array.from(this.$el[0].querySelectorAll('.js__anchor'))
      .map(el => new Anchor(el))

    this.squashAnchors()
  }

  squashAnchors() {
    if (!this.anchors.length) return

    const spread = this.anchors[0].spread
    const { viewHeight } = window.application

    let maxTrigger = this.currentScrollPosition + spread.getBoundingClientRect().bottom - viewHeight

    this.anchors.reverse()

    this.anchors.forEach(anchor => {
      if (anchor.trigger > maxTrigger) {
        anchor.trigger = maxTrigger
        maxTrigger -= MIN_SCROLL_HEIGHT
      } else {
        maxTrigger = anchor.trigger - MIN_SCROLL_HEIGHT
      }
    })

    this.anchors.reverse()
  }

  bindEvents() {
    this.$document.on('appScroll', this.setActive.bind(this))
  }

  setActive() {
    this.currentAnchorId = '1'

    this.anchors.forEach(anchor => {
      if (this.currentScrollPosition >= anchor.trigger) this.currentAnchorId = anchor.id
    })

    this.activateCurrent()
    this.notifyCollaborators()
  }

  activateCurrent() {
    if (this.currentAnchor && this.currentAnchor.id === this.currentAnchorId) return

    const anchor = this.anchors.find(_anchor => _anchor.id === this.currentAnchorId)

    if (this.currentAnchor) this.currentAnchor.setInactive()
    if (anchor) anchor.setActive()

    this.currentAnchor = anchor
  }

  notifyCollaborators() {
    if (!this.currentAnchor) return

    this.$el.trigger('anchor', { id: this.currentAnchorId, anchor: this.currentAnchor })
  }
}

$('.spread').each(function() {
  const $spread = $(this)
  const spreadId = __.generateID(6)

  $spread.attr('data-spread-id', spreadId)

  window.application.anchorsBySpreadId = window.application.anchorsBySpreadId || {}
  window.application.anchorsBySpreadId[spreadId] = new Anchors($spread)
})
