import Tag from './tag'
import Show from './show'
import AllTagsCatalogue from '../sovietTagsCatalogue'
import SpatialContainer from './spatialNavigation'

const renderRecentSuggestion = onSelect => value =>
  <Tag
    onClick={ onSelect }
    onPressEnter={ onSelect }
    isDeletable={ false }
    value={ value }
  />

const renderProperSuggestion = onSelect => value =>
  <li
    onClick={ () => onSelect(value) }
    onKeyDown={ e => onSelect && e.code === 'Enter' && onSelect(value) }
    className="suggestInput-suggestionListItem"
    tabIndex="0"
  >
    { value }
  </li>

export const RecentSuggestions = ({ list, onSuggestionSelect }) => (
  <div className="suggestInput-recentSuggestionsList">
    <ul className="suggestionsList">
      { list.map(renderRecentSuggestion(onSuggestionSelect)) }
    </ul>
  </div>
)

export const ProperSuggestions = ({ list, onSuggestionSelect }) => (
  <ul className="suggestInput-properSuggestionsList suggestionsList">
    {list.map(renderProperSuggestion(onSuggestionSelect))}
  </ul>
)

export const SuggestionsDropdown =
  ({
    suggestionType,
    recentSuggestionsList,
    properSuggestionsList,
    catalogueSuggestionsList,
    handleSuggestionSelect,
  }) => (
  <span>
    <Show if={ suggestionType === 'PROPER' }>
      <SpatialContainer direction="VERTICAL" className="suggestInput-suggestionsWrapper">
        <ProperSuggestions
          list={ properSuggestionsList }
          onSuggestionSelect={ handleSuggestionSelect }
        />
      </SpatialContainer>
    </Show>

    <Show if={ suggestionType === 'RECENT' && recentSuggestionsList.length }>
      <SpatialContainer direction="HORIZONTAL_BOTTOM" className="suggestInput-suggestionsWrapper">
        <RecentSuggestions
          list={ recentSuggestionsList }
          onSuggestionSelect={ handleSuggestionSelect }
        />
      </SpatialContainer>
    </Show>

    <Show if={ suggestionType === 'CATALOGUE' }>
      <SpatialContainer className="suggestInput-suggestionsWrapper is__catalogue">
        <AllTagsCatalogue
          selectedTags={ catalogueSuggestionsList }
          onSelect={ handleSuggestionSelect } />
      </SpatialContainer>
    </Show>
  </span>
  )
