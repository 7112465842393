const Notification = ({ html, ...props }) => (
  <div {...props} className={`notification ${props.className ? props.className : ''}`} ref={ props.getRef }>
    <div className="notification-text"
      dangerouslySetInnerHTML={ html ? { __html: html } : null }
    >
      { props.children }
    </div>
  </div>
)

export default Notification
