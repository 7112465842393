import { Component } from 'preact'
import Price from '../price/price'

class OneTimeSubscriptionPrice extends Component {
  render(props) {
    return (
      <div className="pricing">
        <Price
          label={ props.label }
          amount={ props.price }
          units="₽" />
      </div>
    )
  }
}

module.exports = OneTimeSubscriptionPrice
