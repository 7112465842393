import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { detectPaybarFooter } from './variants'

import NewPaybarFooterLinks from './newPaybarFooterLinks'
import NewPaybarNotification from './newPaybarNotification'
import NewPaybarBasementSwitcher from './newPaybarBasementSwitcher'

class NewPaybarFooter extends Component {
  render(props) {
    const { product, isLoading, noticeType, isSuccessScreen } = props

    const classNames = cx('newPaybarFooter', { is__loading: isLoading })
    const NewPaybarFooterProduct = detectPaybarFooter(product)

    return (
      <div className={ classNames }>
        { noticeType && !isSuccessScreen && <NewPaybarNotification device="mobile" />}

        <NewPaybarFooterProduct { ...props } />

        { props.isLicenseLoaded &&
          <NewPaybarBasementSwitcher/>
        }
        { !props.isLicenseLoaded &&
          <NewPaybarFooterLinks product={ product } />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    noticeType: newPaybar.noticeType,
    isLoading: newPaybar.isLoading,
  }
}

export default connect(mapStateToProps)(NewPaybarFooter)
