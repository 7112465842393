import cx from 'classnames'

import { Image } from '../image'

import {
  bookshelfCoverSize,
  bookshelfHalfLaptopCoverSize,
  mobileCoverSize,
  HALF_BOOK_COVER_SIZE,
  FULL_BOOK_COVER_SIZE,
} from './bookSpacerUtils'

const imgDir = '/banners/partials/files/'

export const MobileCover = (props) => {
  const imgSrcMobile = `${imgDir}${props.id}-mobile.png`
  const hasLabel = props.labelText !== ''

  return (
    <Image
      size={ mobileCoverSize(props.coverMobileSize) }
      src={ imgSrcMobile }
      className="device device__mobile"
    >
      { hasLabel &&
        <div className="bookSpacerLabel">{ props.labelText }</div>
      }
    </Image>
  )
}

export const BookCover = (props) => {
  const imgSrcHalf = `${imgDir}${props.id}-half.png`
  const imgSrcFull = `${imgDir}${props.id}-full.png`

  return (
    <div>
      { props.isHalf &&
        <Image
          size={ HALF_BOOK_COVER_SIZE }
          src={ imgSrcHalf }
          className="device device__desktop device__laptop"
        />
      }

      { props.isFull &&
        <Image
          size={ FULL_BOOK_COVER_SIZE }
          src={ imgSrcFull }
          className="device device__desktop device__laptop"
        />
      }
    </div>
  )
}

export const BookshelfCover = (props) => {
  const imgSrcBookshelf = `${imgDir}${props.id}.png`
  const imgSrcBookshelfHalfLaptop = `${imgDir}${props.id}-half-laptop.png`

  const hasCustomFullCoverLaptop = props.fullCoverLaptopSize !== ''
  const customCoverLaptopSize = props.halfCoverLaptopSize || props.fullCoverLaptopSize

  const defaultImageClasses = cx(
    'device',
    'device__desktop',
    { device__laptop: props.isFull && !hasCustomFullCoverLaptop }
  )

  return (
    <div>
      { props.isHalf &&
        <Image
          size={ bookshelfHalfLaptopCoverSize(customCoverLaptopSize) }
          src={ imgSrcBookshelfHalfLaptop }
          className="device device__laptop"
        />
      }

      { props.isFull && hasCustomFullCoverLaptop &&
        <Image
          size={ bookshelfHalfLaptopCoverSize(props.fullCoverLaptopSize) }
          src={ imgSrcBookshelfHalfLaptop }
          className="device device__laptop"
        />
      }

      <Image
        size={ bookshelfCoverSize(props.coverSize) }
        src={ imgSrcBookshelf }
        className={ defaultImageClasses }
      />
    </div>
  )
}
