import { Component } from 'preact'
import { Heading3, TextNode } from '../textNodes'

export default class SovietCommentFormSuccessFrame extends Component {
  render() {
    return (
      <div className="sovietCommentFormSuccessFrame bureausans">
        <div>
          <Heading3>Спасибо! Ваш комментарий отправлен</Heading3>
          <TextNode>
            Если он соответствует нашим условиям, то, скорее всего,
            появится на сайте. В любом случае мы благодарны за ваш вклад в обсуждение.
          </TextNode>
        </div>

        <footer>
          <button class="button is__enlargedOnMobiles" onClick={ this.props.onClick }>
            Написать ещё
          </button>
        </footer>
      </div>
    )
  }
}
