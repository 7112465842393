import JSON_HEADERS from 'jsonHeaders'

module.exports = ({ paymentMethod, token }) => {
  const body = JSON.stringify({ paymentMethod, token })

  return fetch('/pay/new_payment_method/', {
    method: 'POST',
    credentials: 'include',
    headers: JSON_HEADERS,
    body,
  }).then(() => undefined)
}
