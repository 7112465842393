import debounce from 'debounce'
import DRAFT_HEADERS from 'authJsonHeaders'
import { storeToJsonParams } from './sovietStore'

const saveDraftInstantly = (data) => {
  return fetch(`/soviet/advices/${data.sovietId}`, {
    method: 'PUT',
    headers: DRAFT_HEADERS,
    body: JSON.stringify(data),
  })
}

const saveDraftWithDebounce = debounce(300, saveDraftInstantly)
const saveDraftAnd = ({ context }) => saveDraftInstantly(storeToJsonParams(context.store.getState()))

export { saveDraftWithDebounce, saveDraftInstantly, saveDraftAnd }
