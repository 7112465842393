import SubscriptionPricing from '../subscriptionPricing/subscriptionPricing'

import { Heading3, Caption } from '../textNodes'

export default function CollectureSubscriptionPricing(props) {
  const subscriptionPeriod = this.props.prices.subscriptionPeriod
  const captionText = I18n.t(`collectures.pricing.subscriptionFooter.caption${subscriptionPeriod}`)

  return (
    <div className={ props.className }>
      <Heading3>{ I18n.t('collectures.pricing.title') }</Heading3>

      <SubscriptionPricing
        prices={ props.prices }
        smallUnits
        hasCompactUnitsView />

      { props.hasCaption &&
        <Caption html={ captionText } />
      }
    </div>
  )
}
