const toVideoPlayerOptions = require('toVideoPlayerOptions')
const YoutubeVideo = require('./youtubeVideo')

$(document).on('appReady', () => {
  document
    .querySelectorAll('.youtubeVideo')
    .forEach(el => {
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      new YoutubeVideo(el, options)
    })
})
