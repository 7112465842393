import { Component } from 'preact'
import MenuItem from './menuItem'

const getCardMenuItem = (cardNumber, isCardNumberFetched) => {
  if (cardNumber) return I18n.t('userPopup.card.current', { cardNumber })

  if (isCardNumberFetched) return I18n.t('userPopup.card.missing')

  return I18n.t('userPopup.card.default')
}

export default class CardMenuItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cardNumber: null,
      isCardNumberFetched: false,
    }
  }

  componentWillReceiveProps({ isOpen, isAuthenticated }) {
    const { isCardNumberFetched } = this.state

    if (isOpen && isAuthenticated && !isCardNumberFetched) {
      this.fetchCardNumber().then(res => this.setState({
        cardNumber: res && res.cardNumber,
        isCardNumberFetched: true,
      }))
    }
  }

  fetchCardNumber() {
    return fetch('/api/system/user/card/', { credentials: 'include' })
      .then(result => result.json())
      .catch(console.log)
  }

  render() {
    const { ...props } = this.props
    const { cardNumber, isCardNumberFetched } = this.state

    return (
      <MenuItem
        { ...props }
        title={ getCardMenuItem(cardNumber, isCardNumberFetched) }
        isDisabled={ !isCardNumberFetched }
      />
    )
  }
}
