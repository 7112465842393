import { Component } from 'preact'
import cx from 'classnames'

import UploadBar from '../upload/bar'

export default class SovietAskFormUploadImage extends Component {
  componentDidMount() {
    const $document = $(document)

    $document.on('appDragStart.sovietAskFormFileUpload', () => this.setState({ isDragOver: true }))
    $document.on('appDragEnd.sovietAskFormFileUpload', () => this.setState({ isDragOver: false }))
    $document.on('appDrop.sovietAskFormFileUpload', (_, data) => this.props.onFileUpload(data.files))
  }

  componentWillUnmount() {
    $(document).off('appDragStart.sovietAskFormFileUpload appDragEnd.sovietAskFormFileUpload appDrop.sovietAskFormFileUpload')
  }

  render() {
    const className = cx('sovietAskFormFileUploader', { is__dragOver: this.state.isDragOver })

    return (
      <div className={ className }>
        <UploadBar />
      </div>
    )
  }
}
