import { Component } from 'preact'

import Price from '../price/price'
import Pricing from '../price/pricing'
import NewUpgradePricing from './newUpgradePricing'

// FIXME: should be superseded by <NewUpgradePricing />
export default class UpgradePricing extends Component {
  get upgradeLabel() {
    if (this.props.isBookshelfAll) return this.upgradeToAllLabel

    return I18n.t('subscription.prices.upgradeLabel')
  }

  get upgradeToAllLabel() {
    const { renewalPeriod, previousOneTime } = this.props.prices

    return I18n.t(`subscription.prices.defaultLabel_${renewalPeriod}`) + ` <s>${previousOneTime}</s>`
  }

  get subscriptionLabel() {
    const suffix = this.props.isBookshelfAll ?
      `subscriptionLabel_${this.props.prices.renewalPeriod}` : 'upgradeSubscriptionLabel'
    let label = I18n.t(`subscription.prices.${suffix}`)

    if (this.hasDifferentSubscriptionPrices) {
      label += ` <s>${ this.props.prices.previousSubscription }</s>`
    }

    return label
  }

  get hasDifferentSubscriptionPrices() {
    const { subscription, previousSubscription } = this.props.prices

    return !!previousSubscription && subscription !== previousSubscription
  }

  render() {
    if (this.props.product === 'collecture') return (<NewUpgradePricing { ...this.props } />)

    const { prices, size = 'pageTitle', unitsSize = 'medium' } = this.props

    return (
      <Pricing currency={ prices.currency }>
        <Price
          label={ this.upgradeLabel }
          amount={ prices.specialOffer.oneTime }
          className="is__specialOffer"
          units={ I18n.t(`subscription.defaultUnits_${prices.renewalPeriod}`) }
          size={ size }
          unitsSize={ unitsSize }
        />

        <Price
          label={ this.subscriptionLabel }
          amount={ prices.subscription }
          className={this.hasDifferentSubscriptionPrices ? 'is__specialOffer' : ''}
          size={ size }
          unitsSize={ unitsSize }
          units={ I18n.t(`subscription.subscriptionUnits_${prices.renewalPeriod}`) }
        />
      </Pricing>
    )
  }
}
