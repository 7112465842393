import { Component } from 'preact'

import { Cols } from '../cols'
import { Heading2 } from '../textNodes'
import LectureFollowingNavigationItem from './lectureFollowingNavigationItem'

const MAX_COURSES = 5

const toUrl = ({ key }) => `/lectures/${key}/`
const addUrl = (course) => ({ ...course, ...{ url: toUrl(course) } })

export default class LectureFollowingCoursesNavigation extends Component {
  render() {
    let { courses } = this.props
    if (!courses || !courses.length) return null

    courses = courses.map(addUrl)

    const columns = Array(MAX_COURSES).fill('something')

    return (
      <div className="lectureFollowingCoursesNavigation">
        <Heading2>{ I18n.t('lectures.navigation.following.title') }</Heading2>
        <Cols>
        { columns.map((_, index) => {
          return (<LectureFollowingNavigationItem item={ courses[index] } />)
        })}
        </Cols>
      </div>
    )
  }
}
