import { Component } from 'preact'
import cx from 'classnames'

class UploadBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadedKB: 0,
      totalKB: 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      loadedKB: this.convertToKB(nextProps.loaded),
      totalKB: this.convertToKB(nextProps.total),
    })
  }

  convertToKB(size) {
    return (size / 1024).toFixed()
  }

  render({ file, loaded, total, isActive, isFailed }) {
    const className = cx('uploadBar', { is__active: isActive, is__failed: isFailed })
    const message = isFailed ? `Failed to upload ${file}` : `Uploading ${file}`

    return (
      <div className={ className }>
        <div className="uploadBar-name">
          { message }
        </div>

        <div className="uploadBar-status">
          { this.state.loadedKB } / { this.state.totalKB } KB
        </div>

        <div
            className="uploadBar-progress"
            style={ `width: ${loaded / total * 100}%` }
          >
          { message }
        </div>
      </div>
    )
  }
}

module.exports = UploadBar
