const Answer = require('./schoolTestAnswer')

const CLASS_INCORRECT = 'is__incorrect'

class SchoolTestQuestion {
  constructor($el, test) {
    this.$el = $el
    this.test = test

    this.id = this.test.id + '.' + this.$el.data('id')
    this.answers = []

    this._fillInAnswers()
  }

  init() {
    this.answers.forEach(answer => answer.init())

    if (window.location.hash === '#' + this.$el.attr('id')) this.$el.addClass('is__highlightMark')
  }

  get visibleAnswers() {
    return this.answers.filter(answer => !!answer.$el[0].offsetParent)
  }

  get isCorrect() {
    return this.visibleAnswers.every(answer => answer.isCorrect)
  }

  get isDone() {
    return this.visibleAnswers.some(answer => answer.isChecked)
  }

  get score() {
    const rightQuestionsCount = this.answers
      .filter(answer => answer.isRight)
      .length
    const correctQuestionsCount = this.answers
      .filter(answer => answer.isRight && answer.isChecked)
      .length
    const invalidQuestionsCount = this.answers
      .filter(answer => !answer.isRight && answer.isChecked)
      .length

    const scorePart = this.maxScore / rightQuestionsCount
    const score = correctQuestionsCount * scorePart - invalidQuestionsCount * scorePart

    return score <= 0 ? 0 : score
  }

  get maxScore() {
    return this.test.MAX_QUESTION_SCORE
  }

  _fillInAnswers() {
    this.$el.find('.js__answer').each((_, el) => {
      this.answers.push(new Answer($(el), this))
    })
  }

  lock() {
    this.answers.forEach(answer => answer.lock())
  }

  reset() {
    this.answers.forEach(answer => answer.reset())
  }

  reload() {
    this.answers.forEach(answer => answer.reload())
  }

  markAsIncorrect() {
    this.$el.addClass(CLASS_INCORRECT)
  }
}

module.exports = SchoolTestQuestion
