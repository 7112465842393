import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { setHeaderTextColor } from './sovietActions'
import { IconSelector, IconSelectorItem } from '../iconSelector'

class SovietTextColorSelector extends Component {
  constructor(props) {
    super(props)

    this.setTextColorWhite = this.setTextColor.bind(this, 'white')
    this.setTextColorBlack = this.setTextColor.bind(this, 'black')
  }

  setTextColor(color) {
    this.props.setHeaderTextColor(color)
  }

  render(props) {
    return (
      <IconSelector isSmall className="sovietTextColorSelector">
        <IconSelectorItem
          className={ cx('is__white', { is__active: props.headerTextColor === 'white' }) }
          onClick={ this.setTextColorWhite }>
          А
        </IconSelectorItem>
        <IconSelectorItem
          className={ cx('is__black', { is__active: props.headerTextColor === 'black' }) }
          onClick={ this.setTextColorBlack }>
          А
        </IconSelectorItem>
      </IconSelector>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    headerTextColor: soviet.headerTextColor,
  }
}

const mapDispatchToProps = {
  setHeaderTextColor,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietTextColorSelector)
