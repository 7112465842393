import { extractSovietKey } from 'lib/soviets/compilation'

const LINK = /^(http:|https:|\/\/)/

export function markImportantLinks($html) {
  $html
    .find('br+a, p a:last-child, p a:first-child, li a:first-child:last-child')
    .filter((_, link) => LINK.test(link.textContent))
    .addClass('is__importantLink')

  return $html
}

const wrapLinkInLi = ($el) => $el.prev('a').add($el).wrap('<li>').parent()

const stripEmptyParagraphs = ($el) => {
  $el.find('p').filter((_, el) => el.innerText.trim() === '').remove()
}

const addNewlineAfterLinkPrecedingText = (text) => {
  const replacer = (_, p1, p2) => `${p1}\n\n${p2}`
  return text.replace(/(https?:\/\/.+|\[.+\]\(https?:\/\/.+\))\n((?!https?:\/\/.+|\[.+\]\(https?:\/\/.+\)))/ig, replacer)
}

export function wrapListLikeLinks($, html) {
  const $html = $('<div>').append($(html))

  $html.find('p img').unwrap()

  $html
    .find('p')
    .each((_, el) => {
      const $el = $(el)
      const $ul = $('<ul class="noIndent">')

      $el.find('a+br+a').prev('br').remove()
      $el.find('br+a+a').prev().prev().remove()

      $el
        .find('a+a')
        .each((__, aEl) => wrapLinkInLi($(aEl)).appendTo($ul))

      if ($ul.children().length > 0) $(el).after($ul)
    })

  stripEmptyParagraphs($html)

  return $html.html()
}

export function groupSovietsByKey(soviets) {
  return soviets.reduce((hash, soviet) => {
    hash[soviet.key] = soviet

    return hash
  }, {})
}

export function placeSovietTitles($html, soviets) {
  const sovietsByKey = groupSovietsByKey(soviets)

  $html
    .find('a')
    .each((_, link) => {
      const text = link.textContent
      const slug = extractSovietKey(text)

      link.textContent = (sovietsByKey[slug] || {}).title || text
    })

  return $html
}

export function addMetaToPlainTextVersionOf($html) {
  const coverLink = $('meta[property="og:image"]').attr('content')
  const title = $('.js__compilationTitle').first().text().trim()
  const summary = $('.js__compilationSummary').first().text().trim()
  const author = $('.js__compilationAuthor').first().text().trim()

  const meta = [title, summary, author].filter(part => part).join('\n')

  $html.prepend($(`<p>${meta}\n\n</p>`))
  $html.prepend($(`<p>${coverLink}\n\n\n</p>`))

  return $html
}

export function plainTextVersionOf(html) {
  let $div = $(document.createElement('div')).append(html)

  if (window._isFullCopyInProcess) $div = $div.find('.js__sovietCompilationHtml')

  $div.find('a').each((_, el) => el.textContent = el.href)
  $div.find('br').replaceWith('\n')
  $div.find('li:not(:first-child)').before('\n')
  $div.find('.textNode + .textNode.is__list').find('li:first-child').before('\n')
  $div.find('.textNode + .textNode:not(.is__list)').find('p').before('\n')

  const headers = ['1', '2', '3']
    .map(level => `.heading-${level}:not(:first-child) h${level}`)
    .join(',')
  $div.find(headers).before('\n\n')

  const shouldAddMeta = $div.find('.js__sovietCompilationHtml').length ||
    window._isFullCopyInProcess

  if (shouldAddMeta) addMetaToPlainTextVersionOf($div)

  return $div[0].textContent
}

export function plainTextCompilationSelection() {
  const selection = window.getSelection()

  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    const clonedSelection = range.cloneContents()

    return plainTextVersionOf(clonedSelection)
  }
}

export function preprocessPlainText(text) {
  text = addNewlineAfterLinkPrecedingText(text)

  return text
}
