import { Component } from 'preact'
import { connect } from 'preact-redux'

import { FormInput } from '../form'
import ExtendableDropdown from '../../modules/form/extendableDropdown'
import SovietCalendarPopupContainer from './sovietCalendarPopupContainer'
import { parseDate } from 'lib/dateParser'

import {
  datify,
  moscowify,
  moscowifyLocal,
  setTimeToMidnightByMoscow,
  removeTimezoneOffset,
  addTimezoneOffset,
} from '../calendar/timezoneUtils'
import formatDate from '../calendar/dateFormatter'

import { setPublishAt } from './sovietActions'

const isValidDate = date =>
  parseDate(date).isValid

const prepareDate = date =>
  setTimeToMidnightByMoscow(removeTimezoneOffset(date)).toISOString()

class SovietPublishAtSelector extends Component {
  constructor(props) {
    super(props)
    const initialValue = formatDate(moscowifyLocal(datify(this.props.publishAt)))
    this.state = {
      isDropdownOpen: false,
      isValid: isValidDate(initialValue),
      value: initialValue,
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.blur = this.blur.bind(this)
    this.buildInputRef = this.buildInputRef.bind(this)
  }

  handleInput({ value }) {
    this.setState({ value })
  }

  componentWillReceiveProps({ publishAt }) {
    const { value } = this.state
    const isValid = isValidDate(value)

    if (isValid) {
      const date = parseDate(value).value
      const publishDateWithMoscowOffset = addTimezoneOffset(moscowify(new Date(publishAt)))

      if (publishAt !== prepareDate(date)) {
        this.setState({
          value: formatDate(publishDateWithMoscowOffset, this.state.value),
        })
      }
    }
  }

  handleFocus() {
    this.setState({ isDropdownOpen: true })
  }

  handleBlur() {
    const { value } = this.state
    const isValid = isValidDate(value)

    if (isValid) {
      const date = parseDate(value).value
      if (this.props.publishAt !== prepareDate(date)) this.props.setPublishAt(prepareDate(date))
    }

    this.setState({ isValid, isDropdownOpen: false })
  }

  blur() {
    this.input.el.blur()
  }

  buildInputRef(el) {
    this.input = el
  }

  render() {
    const { isValid, value } = this.state

    return (
      <div className="sovietPublishAtSelector">
        <ExtendableDropdown isComboBox isOpen={ this.state.isDropdownOpen } currentItem={
          <FormInput
            className="sovietPublishAtSelector-input is__medium is__padded"
            onInput={ this.handleInput }
            onFocus={ this.handleFocus }
            onBlur={ this.handleBlur }
            value={ value }
            validate={ isValidDate }
            isValid={ isValid }
            ref={ this.buildInputRef }
            required />
        }>
          <SovietCalendarPopupContainer
            afterClick={ this.blur }
            isVerbose />
        </ExtendableDropdown>
      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    publishAt: soviet.publishAt,
  }
}

const mapDispatchToProps = {
  setPublishAt,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietPublishAtSelector)
