import cx from 'classnames'

import NewPaybarShelf from '../../newPaybar/newPaybarShelf'

const BOOK_HEIGHT_DESKTOP = 90
const BOOK_HEIGHT_MOBILE = 180
const DETAILED_BY_OWNED_BOOKS_PLAN = ['bookshelf-bureau']

// key → bookKey, чтобы не конфликтовать с ключами Реакта
const Book = ({ bookKey, isAppendix }) => {
  const className = cx('paybarScheme-ownedBook', { is__appendix: isAppendix })
  const bgUrl = `/projects/book-${bookKey}/files/_book-cover-spine.png`

  return (
    <div className={ className } style={ `background-image: url(${bgUrl});` }></div>
  )
}

export default function NewPaybarUpgradeScheme(props) {
  const { shelfBooks, productId } = props
  const books = props.books.slice(0, 3)
  const hasDetailedPlan = id => DETAILED_BY_OWNED_BOOKS_PLAN.includes(id)

  return (
    <div className="newPaybarUpgradeScheme">
      { hasDetailedPlan(productId) &&
        <div className="newPaybarUpgradeScheme-books">
          { books.map(book => <Book bookKey={ book.key } isAppendix={ book.isAppendix }/>) }
        </div>
      }

      <div className="paybarScheme-shelf module device device__laptop device__desktop">
        <NewPaybarShelf
          height={ BOOK_HEIGHT_DESKTOP }
          product="bookshelf"
          books={ shelfBooks }
          cannotRead />
      </div>

      <div className="paybarScheme-shelf module device device__mobile">
        <NewPaybarShelf
          height={ BOOK_HEIGHT_MOBILE }
          product="bookshelf"
          books={ shelfBooks }
          cannotRead />
      </div>
    </div>
  )
}
