import { Component } from 'preact'
import cx from 'classnames'
import { Cols } from '../cols'
import { Caption, TextNode } from '../textNodes'
import {
  BookSystemRequirementsIcon,
  BookSystemRequirementsWithMobileIcon,
} from '../icons'

const ALL_BOOKS = 'all'
const SOME_BOOKS = 'some'
const NOT_ONE = 'none'

const withMobileVersion = book => book.hasMobileVersion

class BookSystemRequirements extends Component {
  get isBookshelf() {
    return this.props.product === 'bookshelf'
  }

  get allHaveMobileVersion() {
    const { books, hasMobileVersion } = this.props

    return (books.length > 0 && books.every(withMobileVersion) || hasMobileVersion)
  }

  get someHaveMobileVersion() {
    const { books } = this.props

    return books.some(withMobileVersion)
  }

  get hasMobileVersion() {
    return this.allHaveMobileVersion || this.someHaveMobileVersion
  }

  get howManyBooksHaveMobileVersion() {
    if (this.allHaveMobileVersion) return ALL_BOOKS
    if (this.someHaveMobileVersion) return SOME_BOOKS

    return NOT_ONE
  }

  get bookshelfRequirementsText() {
    return I18n.t(`books.bookshelf.requirements.hasMobileVersion.${ this.howManyBooksHaveMobileVersion }`)
  }

  get bookRequirementsText() {
    const suffix = this.hasMobileVersion ? 'mainWithMobile' : 'mainWithoutMobile'

    return I18n.t(`books.bookSystemRequirements.${ suffix }`)
  }

  get requirementsText() {
    const { isBookshelf, bookshelfRequirementsText, bookRequirementsText } = this

    return isBookshelf ? bookshelfRequirementsText : bookRequirementsText
  }

  render() {
    const classes = cx(
      'bookSystemRequirements',
      {
        has__mobileVersion: this.hasMobileVersion,
        is__someBooksHaveMobileVersion: this.someHaveMobileVersion && !this.allHaveMobileVersion,
      }
    )

    const Icon = () => {
      return (
        this.hasMobileVersion ?
          <BookSystemRequirementsWithMobileIcon /> : <BookSystemRequirementsIcon />
      )
    }

    return (
      <Cols
        className={ classes }
        isRibbed
        mobileRibMask={ [false, true] }
      >
        <div className="bookSystemRequirements-main">
          <div className="bookSystemRequirements-text">
            <TextNode html={ this.requirementsText } />
          </div>
          <div className="bookSystemRequirements-icon">
            <Icon />
          </div>
        </div>
        <div className="bookSystemRequirements-retina">
          <Caption html={ I18n.t('books.bookSystemRequirements.retina') } />
        </div>
      </Cols>
    )
  }
}

export default BookSystemRequirements
