import TaskResultMarkPartsTable from './taskResultMarkPartsTable'

$(document).on('appReady', () => {
  $('.js__taskResultMarkPartsTable').each(function() {
    const id = $(this).data('id')
    const result = window.task.results.filter(_result => _result.id === id)[0]

    $(this).reactify(TaskResultMarkPartsTable, {
      scoreParts: result.parts || [],
      markType: window.task.mark.type,
      parts: window.task.mark.parts,
      bonusMarkParts: window.task.mark.bonus_parts,
      average: result.average,
      canSeeScore: window.task.abilities.can_see_score,
      correction: result.correction,
      userId: window.task.credentials.id,
      userIsStaff: window.task.userIsStaff,
      abilities: window.task.abilities,
    })
  })
})
