import { Caption } from '../textNodes'
import { formatPrice } from 'lib/textHelper'

import { THINSP } from 'lib/constants'

const CollectureSpacerMobilePricing = (props) => {
  const { isUpgrade, prices } = props

  const subscriptionUnits = I18n.t(`subscription.subscriptionUnits_${prices.renewalPeriod}`)

  return (
    <Caption>
      { isUpgrade &&
        <span>
          <s className="is__crossedOut">{ formatPrice(prices.specialOffer.previousOneTime) }</s>
          <span className="is__highlight">
            { ` ${formatPrice(prices.specialOffer.oneTime)}` }
            { THINSP }{ I18n.t(`subscription.defaultUnits_${prices.upgradePeriod}`) }
          </span>
        </span>
      }

      { !isUpgrade &&
        <span>{ formatPrice(prices.previousOneTime) }</span>
      }

      {`, затем ${formatPrice(prices.subscription)} ${subscriptionUnits}`}
    </Caption>
  )
}

export default CollectureSpacerMobilePricing
