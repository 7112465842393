module.exports = (string) => {
  if (!string) return

  const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      "/": '&#x2F;',
  }
  const reg = /[&<>"'/]/ig
  return string.replace(reg, (match)=>(map[match]))
}
