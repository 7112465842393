import { Provider } from 'preact-redux'

import Soviet from './soviet'

import { mergeState } from 'reduxStorage'
import { createStore, DEFAULT_STATE } from './sovietStore'
import headerTemplateConfig from './headerTemplateConfig'

{
  const WrappedSoviet = (props) => {
    return (
      <Provider store={ createStore(props) }>
        <Soviet />
      </Provider>
    )
  }

  $('.js__soviet').each(function() {
    const initialState = mergeState(DEFAULT_STATE, window.soviet || {})
    const headerTemplateState = headerTemplateConfig[initialState.forcedHeaderTemplate] || {}
    const state = mergeState(initialState, headerTemplateState)

    $(this).reactify(WrappedSoviet, state)
  })
}
