import { Component } from 'preact'
import cx from 'classnames'
import { birmanize } from 'lib/textHelper'
import { Caption, Heading2 } from '../textNodes'
import NewPaybarNotification from './newPaybarNotification'

export default class NewPaybarBasementSubscriptionCancellation extends Component {
  get originalProduct() {
    const { subscription, product } = this.props

    if (subscription.original_product_id) return subscription.original_product_id.split('-')[0]

    return subscription.product || product
  }

  get title() {
    const { productTitle, subscription } = this.props

    return subscription.original_product_title || productTitle
  }

  get subtitle() {
    const { subscription, isPreorder } = this.props

    if (isPreorder) return this.preorderSubtitle

    if (!subscription || !subscription.valid_until) return ''

    const validUntil = birmanize(subscription.valid_until, { format: 'dateYearOnly' })
    const suffix = isPreorder ? 'Preorder' : ''

    return I18n.t(`subscriptionCancel.validUntil.${this.originalProduct + suffix}`, { validUntil })
  }

  get preorderSubtitle() {
    return I18n.t(`subscriptionCancel.validUntil.${this.originalProduct}Preorder`)
  }

  render() {
    const { onKeep, onCancel, isLoading } = this.props
    const cancelButtonClassNames = cx('button is__thin is__cancel is__enlargedOnMobiles', { is__disabledWithOpacity: isLoading })

    return (
      <div>
        <div className="newPaybarBasement-top">
          <Heading2 className="newPaybarBasement-title">{ this.title }</Heading2>
          <Caption className="newPaybarBasement-subtitle">{ this.subtitle }</Caption>
        </div>

        <div className="newPaybarBasement-bottom">
          <Caption className="newPaybarBasement-text" html={ I18n.t(`subscriptionCancel.disclaimer.${this.originalProduct}`) } />

          <div className="newPaybarBasement-buttons">
            <NewPaybarNotification device="mobile" />

            <div className="newPaybarBasement-button">
              <button
                className="button is__thin is__noBorder is__enlargedOnMobiles"
                type="button"
                onClick={ onKeep }>
                <span className="pseudoLink" dangerouslySetInnerHTML={ { __html: I18n.t('subscriptionCancel.buttonKeep') } }>
                </span>
              </button>
              <Caption className="newPaybarBasement-buttonCaption">
                { I18n.t('subscriptionCancel.buttonKeepCaption') }
              </Caption>
            </div>

            <div className="newPaybarBasement-button">
              <button
                className={ cancelButtonClassNames }
                type="button"
                onClick={ onCancel }
                dangerouslySetInnerHTML={ { __html: I18n.t('subscriptionCancel.buttonCancel') } }>
              </button>
              <Caption className="newPaybarBasement-buttonCaption">
                { I18n.t(`subscriptionCancel.buttonCancelCaption.${this.originalProduct}`) }
              </Caption>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
