import { pluralize } from 'lib/textHelper'
import { formatScore, formatMaxScore } from 'lib/school/classroom/scoreHelper'

export default class SchoolTestResultMessage {
  constructor({ results, test }) {
    this.results = results
    this.test = test
  }

  get score() {
    return this.results.score
  }

  get maxScore() {
    return this.results.max_score
  }

  get correctQuestionsCount() {
    return this.results.correct_questions_count
  }

  get totalQuestionsCount() {
    return this.results.total_questions_count
  }

  get isMissedDeadline() {
    return this.results.missed_deadline
  }

  html() {
    const score = this.score
    const maxScore = this.maxScore

    const testType = this.test.isBonus ? 'bonus' : 'regular'
    const deadlineKey = this.isMissedDeadline ? 'missedDeadline' : 'metDeadline'
    const errorsKey = score === maxScore ? 'hasNoErrors' : 'hasErrors'
    const translationKey = ['schoolTest', 'results', testType, deadlineKey, errorsKey].join('.')

    return I18n.t(
      translationKey,
      {
        score: formatScore(score),
        scorePlural: pluralize(score, 'балл', 'балла', 'баллов'),
        maxScore: formatMaxScore(maxScore),
        correctQuestionsCount: this.correctQuestionsCount,
        correctQuestionsPlural: pluralize(this.correctQuestionsCount, 'вопрос', 'вопроса', 'вопросов'),
        totalQuestionsCount: this.totalQuestionsCount,
      }
    )
  }
}
