import { Component } from 'preact'

export default class NewPaybarNotificationEmailSentPrep extends Component {
  get notificationText() {
    const noAccess = this.props.product === 'school' && this.props.isPreorder
    const suffix = noAccess ? 'NoAccess' : ''

    return I18n.t(
      `newPaybar.notice.${this.props.product}.${this.props.noticeType}.text${suffix}`,
      { email: this.props.userEmail }
    )
  }

  render() {
    return (
      <div className="newPaybarNotificationEmailSent">
        <span
          className="newPaybarNotice-message"
          dangerouslySetInnerHTML={{ __html: this.notificationText }}>
        </span>
      </div>
    )
  }
}
