import { Component } from 'preact'

import { Heading2 } from '../textNodes'
import { Cols } from '../cols'

import CourseSkinCover from './courseSkinCover'
import CourseSkinList from './courseSkinList'

import cx from 'classnames'

class OtherLecturesCols extends Component {
  constructor(props) {
    super(props)

    this.state = { courseCoverShift: 0 }
    this.calculateCoverShift = this.calculateCoverShift.bind(this)
  }

  get style() {
    return `--courseCoverShift: ${this.state.courseCoverShift}px`
  }

  get listEl() {
    return this.el.querySelector('.courseSkinList')
  }

  get listImageEl() {
    return this.listEl.querySelector('.image')
  }

  componentDidMount() {
    this.calculateCoverShift()

    $(document).on('appResize', this.calculateCoverShift)
  }

  calculateCoverShift() {
    const { scrollHeight, offsetHeight } = this.listEl
    const missingHeight = scrollHeight - offsetHeight

    const previewHeight = this.listImageEl.offsetHeight
    const halfPreviewHeight = previewHeight / 2

    const courseCoverShift = Math.max(missingHeight - halfPreviewHeight, 0)

    this.setState({ courseCoverShift })
  }

  render() {
    const { className, divisionProportions, children } = this.props

    return (
      <div style={ this.style } ref={ el => this.el = el }>
        <Cols className={ className } divisionProportions={ divisionProportions }>
          { children }
        </Cols>
      </div>
    )
  }
}

export default class OtherLectures extends Component {
  render() {
    const { courses } = this.props

    if (!courses || !courses.length) return null

    const desktopClasses = cx(
      'device',
      'device__desktop',
    )

    const laptopClasses = cx(
      'device',
      'device__laptop',
    )

    const mobileClasses = cx(
      'device',
      'device__mobile',
    )

    return (
      <div>
        <Heading2>{ I18n.t('lectures.navigation.other.title') }</Heading2>

        <div className="coursesList is__otherLectures">
          <OtherLecturesCols className={ desktopClasses } divisionProportions="6:6:4">
            <CourseSkinCover course={ courses[0] } device="desktop" />
            <CourseSkinCover course={ courses[1] } device="desktop" />
            <CourseSkinList course={ courses[2] } device="desktop" />
          </OtherLecturesCols>

          <OtherLecturesCols className={ laptopClasses } divisionProportions="10:6">
            <CourseSkinCover course={ courses[0] } device="laptop" />
            <CourseSkinList course={ courses[1] } device="laptop" />
          </OtherLecturesCols>

          <OtherLecturesCols className={ mobileClasses } divisionProportions="1:1">
            <CourseSkinCover course={ courses[0] } device="mobile" />
            <CourseSkinList course={ courses[1] } device="mobile" />
          </OtherLecturesCols>
        </div>
      </div>
    )
  }
}
