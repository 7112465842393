const SubscriptionUpdateCard = () => {
  return (
    <a href="/pay/edit/" className="subscriptionUpdateCard">
      <span className="subscriptionUpdateCard-icon"></span>
      <span className="link">{I18n.t('subscription.updateCard')}</span>
    </a>
  )
}

export default SubscriptionUpdateCard


