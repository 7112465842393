import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarSubscriptionStatus from './newPaybarSubscriptionStatus'
import NewPaybarPersonalForm from '../newPaybarPersonalForm'
import NewPaybarLaunchpad from '../newPaybarLaunchpad'
import NewPaybarRequirementsAndMobileVersion from './newPaybarRequirementsAndMobileVersion'
import NewPaybarTabs from '../newPaybarTabs'

class NewPaybarLaunchpadSet extends Component {
  render() {
    return (
      <div className="newPaybar-infoIn module">
        <NewPaybarRequirementsAndMobileVersion
          className="module device device__laptop device__desktop" />

        <NewPaybarTabs
          device="mobile"
          type={ this.props.type }
          product={ this.props.product }
        />

        <div className="newPaybarLaunchpadMobileWrapper module device device__mobile">
          <NewPaybarLaunchpad />

          { this.props.isUserAuthenticated &&
            <NewPaybarRequirementsAndMobileVersion />
          }
        </div>

        <NewPaybarSubscriptionStatus className="device device__laptop device__desktop" />

        <NewPaybarPersonalForm
          subscriptionUrl={ this.props.subscriptionUrl }
          scrollableRef={ this.props.scrollableRef }
          isPreorder={ false }
        />

        <div>{ this.props.children }</div>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
  }
}

export default connect(mapStateToProps)(NewPaybarLaunchpadSet)
