const BaseModule = require('_base/base')

class CourseSkinCover extends BaseModule {
  preInit() {
    this.$image = this.$el.find('.image')
    this.$list = this.$el.closest('.cols').find('.courseSkinList')
  }

  preCalculate() {
    this.imageHeight = this.$image.height()
    this.courseCoverShift = this.calculateCoverShift()
  }

  preRender() {
    this.$el.css({
      '--coverImageHeight': this.imageHeight + 'px',
      '--courseCoverShift': this.courseCoverShift + 'px',
    })
  }

  preReset() {
    this.$el.css({
      '--coverImageHeight': this.imageHeight + 'px',
      '--courseCoverShift': 0,
    })
  }

  calculateCoverShift() {
    if (!this.$list.length) return 0

    const { scrollHeight, offsetHeight } = this.$list[0]
    const missingHeight = scrollHeight - offsetHeight
    const previewHeight = this.$list.find('.image').height()
    const halfPreviewHeight = previewHeight / 2

    return Math.max(missingHeight - halfPreviewHeight, 0)
  }
}

$('.js__courseSkinCover').each((_, el) => new CourseSkinCover($(el)))
