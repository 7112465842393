import { Component } from 'preact'

const handleKeyDown = fn => event => fn(event.key)

export default class Input extends Component {
  constructor(props) {
    super(props)

    this.getRef = this.getRef.bind(this)
  }

  getRef(ref) {
    this.input = ref
    this.props._ref(ref)
  }

  render() {
    const {
      onInput, onKeyDown, value, ...props
    } = this.props

    return (
      <input
        { ...props }
        ref={ this.getRef }
        className="suggestInput-input input"
        onKeyDown={ onKeyDown && handleKeyDown(onKeyDown) }
        onInput={ onInput }
        value={ value }
      />
    )
  }
}

