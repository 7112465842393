const Cookies = require('js-cookie')
const JSON_HEADERS = require('jsonHeaders')
import { RECAPTCHA_KEY } from 'lib/recaptcha'
import { extractMeta } from 'lib/metaHelper'

const EVENT_ID = 1
const DEFAULT_SCHOOL_EVENT_TYPES = {
  demo: 20,
  designers: 8,
  editors: 12,
  managers: 17,
}

const getEmailFromLocalStorage = (subscriptionStatusId) => {
  try {
    return localStorage.getItem(subscriptionStatusId) || Cookies.get(subscriptionStatusId)
  } catch (e) {}
}

const getEmailFromCookies = () => {
  return Cookies.get('email')
}

const getEmailFromUserData = () => {
  return window.application.user && window.application.user.email
}

const getStoredEmail = (courseId, eventId = EVENT_ID) => {
  const subscriptionStatusId = `event_subscribe_${courseId}_${eventId}`

  const emailFromLocalStorage = getEmailFromLocalStorage(subscriptionStatusId)
  if (emailFromLocalStorage) {
    return {
      isSubscribed: true,
      email: emailFromLocalStorage,
    }
  }

  const emailFromCookies = getEmailFromCookies()
  if (emailFromCookies) {
    return {
      isSubscribed: false,
      email: emailFromCookies,
    }
  }

  const emailFromUserData = getEmailFromUserData()
  if (emailFromUserData) {
    return {
      isSubscribed: false,
      email: emailFromUserData,
    }
  }

  return {
    isSubscribed: false,
    email: undefined,
  }
}

const storeEmail = (subscriptionStatusId, email = '') => {
  try {
    localStorage.setItem(subscriptionStatusId, email)
  } catch (e) {
    Cookies.set(subscriptionStatusId, email)
  }
}

const verifyingRecaptcha = () => {
  if (window.application.user && window.application.user.email) return Promise.resolve()

  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(RECAPTCHA_KEY, { action: 'subscribeUser' })
        .then(resolve)
        .catch(reject)
    })
  })
}

const subscribeUser = ({ email, eventType, eventId = EVENT_ID, additionalMeta = '' }) => {
  const subscriptionStatusId = `event_subscribe_${eventType}_${eventId}`
  const meta = extractMeta() + additionalMeta

  return verifyingRecaptcha()
    .then((token) => {
      return fetch('/rp/subscribe.php', {
        method: 'POST',
        headers: JSON_HEADERS,
        credentials: 'include',
        body: JSON.stringify({
          email,
          eventId,
          type: eventType,
          token,
          skipEventCookie: true,
          meta,
        }),
      })
        .then((res) => {
          if (res.ok) storeEmail(subscriptionStatusId, email)

          return res.ok
        })
    })
}

const detectDefaultSchoolEventType = () => {
  const schoolKey = window.application.pageId
    .split('/')
    .filter(part => part)[1] || 'demo'

  return DEFAULT_SCHOOL_EVENT_TYPES[schoolKey]
}

module.exports = {
  getStoredEmail,
  storeEmail,
  subscribeUser,
  detectDefaultSchoolEventType,
}
