const MIN_VIEWPORT = 375

class Viewport {
  constructor() {
    this.$el = $('meta[name=viewport]')
    this.defaultValue = this.$el.attr('content')

    this.setViewport()
    $(document).on('appResize', this.setViewport.bind(this))
  }

  setViewport() {
    const isNarrowScreen = window.screen.width < MIN_VIEWPORT
    const content = isNarrowScreen ? `width=${MIN_VIEWPORT}` : this.defaultValue

    this.$el.attr('content', content)
  }
}

module.exports = Viewport
