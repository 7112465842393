import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarLaunchpadItemCourse from './newPaybarLaunchpadItemCourse'
import { Heading2 } from '../../textNodes'
import { birmanize } from 'lib/textHelper'

class NewPaybarLaunchpadCourse extends Component {
  get title() {
    if (this.props.isUserAuthenticated) {
      return I18n.t(`newPaybar.launchpad.${this.props.product}`)
    }

    return this.noAccessTitle
  }

  get noAccessTitle() {
    const startDate = birmanize(this.props.subscription.startsAt, { format: 'fullMonthDateOnly' })
    const endDate = birmanize(this.props.subscription.endsAt, { format: 'fullMonthDateOnly' })
    return I18n.t(`newPaybar.launchpad.${this.props.product}NoAccess`, { startDate, endDate })
  }

  render() {
    return (
      <section className="newPaybarLaunchpad-section">
        <Heading2 className="newPaybarLaunchpad-title">{ this.title }</Heading2>

        <div className="newPaybarLaunchpad-items">
          <NewPaybarLaunchpadItemCourse
            isUserAuthenticated={ this.props.isUserAuthenticated }
            courseId={ this.props.subscription.product_id } />
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    subscription: newPaybar.subscription,
    product: newPaybar.product,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
  }
}

export default connect(mapStateToProps)(NewPaybarLaunchpadCourse)
