import { combineReducers } from 'redux'

import SovietColumn from 'lib/sovietColumn'

import { defaultKicker, isDefaultKicker } from 'lib/soviets/defaultKicker'

const detectNewColumn = state => (new SovietColumn(state)).value
const detectNewTextOnAuthorChange = ({ state, action }) => {
  const isTextMissing = !(state.text || '').trim()
  const isTemplateText = state.text === state.authorTemplate
  const isTemplateUsable = isTextMissing || isTemplateText

  return isTemplateUsable ? action.authorTemplate : state.text
}

const soviet = (state = {}, action) => {
  switch (action.type) {
    case 'SET_HEADER_TEMPLATE':
      return {
        ...state,
        ...action,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_HEADER_TEXT_COLOR':
      return {
        ...state,
        headerTextColor: action.headerTextColor,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_TITLE':
      return {
        ...state,
        title: action.title,
        mainThink: action.title,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_MAIN_THINK':
      return {
        ...state,
        title: action.mainThink,
        mainThink: action.mainThink,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_KICKER':
      return {
        ...state,
        kicker: action.kicker,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_QUESTION':
      return {
        ...state,
        question: action.question,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_QUESTIONER_NAME':
      return {
        ...state,
        questionerName: action.questionerName,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_SOVIET_TEXT':
      return {
        ...state,
        text: action.text,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_AUTHOR':
      return {
        ...state,
        ...action,
        text: detectNewTextOnAuthorChange({ state, action }),
        column: detectNewColumn({ ...state, author: action.author }),
        isPublishingOrUnpublishing: false,
        kicker: (isDefaultKicker(state.kicker) ||
          !state.kicker) ? defaultKicker(action.authorGenitiveName) : state.kicker,
      }

    case 'SET_TYPE':
      return {
        ...state,
        isImportant: action.isImportant,
        isLecture: action.isLecture,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_CAN_PUBLISH':
      return {
        ...state,
        canPublish: action.canPublish,
      }

    case 'SET_PUBLISH_AT':
      return {
        ...state,
        publishAt: action.publishAt,
        ...(state.isPreview ? { hasPublicationApprovalError: false } : {}),
      }

    case 'SET_TAGS':
      return {
        ...state,
        column: detectNewColumn({ ...state, tags: action.tags }),
        tags: action.tags,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_IS_PROCESSING':
      return {
        ...state,
        isPublishingOrUnpublishing: true,
        hasPublicationApprovalError: false,
      }

    case 'SET_HAS_PUBLICATION_APPROVAL_ERROR':
      return {
        ...state,
        isPublishingOrUnpublishing: false,
        hasPublicationApprovalError: true,
      }

    case 'SET_HEADER_IMAGE':
      return {
        ...state,
        headerImageUrl: action.headerImageUrl,
        headerImageSize: action.headerImageSize,
        isPublishingOrUnpublishing: false,
      }

    case 'SET_HEADER_IMAGE_TO_NONE':
      return {
        ...state,
        headerImageUrl: '',
        headerImageSize: [],
        isPublishingOrUnpublishing: false,
      }

    case 'SET_HEADER_IMAGE_UPLOAD_ERROR':
      return {
        ...state,
        headerImageUrl: '',
        headerImageSize: [],
        hasImageUploadError: true,
      }

    case 'TOGGLE_FILES_PANEL_VISIBILITY':
      return {
        ...state,
        isFilesPanelVisible: !state.isFilesPanelVisible,
      }

    case 'SET_FILES':
      return {
        ...state,
        files: action.files,
      }

    case 'SET_FILES_AND_OPEN_FILES_PANEL':
      return {
        ...state,
        files: action.files,
        isFilesPanelVisible: true,
      }

    case 'SET_FILES_UPLOAD_ERROR':
      return {
        ...state,
        files: [],
        hasFilesUploadError: true,
      }

    case 'SET_PUBLICATION_APPROVED':
      return {
        ...state,
        isPublicationApproved: action.isPublicationApproved,
        isPublishingOrUnpublishing: false,
        hasPublicationApprovalError: false,
      }

    default:
      return state
  }
}

const sovietReducer = combineReducers({ soviet })

export default sovietReducer
