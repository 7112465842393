import JSON_HEADERS from 'jsonHeaders'

const getDummyResponse = () => {
  const tokenValidUntil = new Date()
  tokenValidUntil.setDate(tokenValidUntil.getDate() + 3)

  const response = {
    success: true,
    token: 'e166a78b40adf0129ad149b76acdac8fc650eedc51c42a18f1b3531fc42dc9aa3bbc9b6fa4a6cad8',
    token_valid_until: tokenValidUntil,
  }

  const shouldRespondWithError = Math.random() > 0.7

  return new Promise((resolve) => {
    if (shouldRespondWithError) throw new Error()

    setTimeout(() => { resolve(response) }, 600)
  })
}

module.exports = ({ productId, subscriptionId }) => {
  // if (!process.env.APP_ENV) return getDummyResponse()

  const cancelApiUrl = `/api/products/${productId}/subscription/cancellations/`

  return fetch(cancelApiUrl, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ subscriptionId }),
    headers: JSON_HEADERS,
  })
    .then(res => res.json())
}
