import { Component } from 'preact'
import cx from 'classnames'
import { formatScore } from 'lib/school/classroom/scoreHelper'
import { isIntersectContainer, reduceWeekDisciplinesScore } from './helpers'

const HALF_PIXEL = 0.5

const WeekTop = ({ rank, score }) => (
  <span className="schoolRatingGraphOverlayWeek-top">
    <span className="schoolRatingGraphOverlayWeek-rank">
      { rank }
    </span>

    <span className="schoolRatingGraphOverlayWeek-total">
      { I18n.t('schoolRating.rating') }
      <span className="schoolRatingGraphOverlayWeek-score">{ formatScore(score) }</span>
    </span>
  </span>
)

const WeekForWeek = ({ score }) => (
  <span className="schoolRatingGraphOverlayWeek-week">
    { I18n.t('schoolRating.weekTotal') }
    <span className="schoolRatingGraphOverlayWeek-score">+{ formatScore(score) }</span>
  </span>
)

const WeekDiscipline = ({ course, score, met_deadlines: metDeadlines }) => (
  <span className={ cx('schoolRatingGraphOverlayWeek-item', { is__failed: !metDeadlines || Number(score) === 0 })}>
    { I18n.t(`school.disciplineShortNames.${course}`) }
    <span className="schoolRatingGraphOverlayWeek-score">{ formatScore(score) }</span>
  </span>
)

export default class Week extends Component {
  componentDidMount() {
    if (!this.props.preventIntersectWith) return

    const isIntersect = isIntersectContainer(this.props.preventIntersectWith, this.$content)

    if (isIntersect !== this.state.isIntersect) {
      this.setState({ isIntersect, isCalculated: true })
    }
  }

  render() {
    const {
      props: { isFinal, number, rank, score, details = [], coords: { x, y } },
      state: { isCalculated, isIntersect },
    } = this

    const classNames = cx('schoolRatingGraphOverlayWeek js__schoolRatingOverlayWeek', {
      is__calculated: isCalculated,
      is__left: isIntersect,
      is__final: isFinal,
    })
    const styles = `transform: translate(${x}px, ${y - HALF_PIXEL}px);`

    const hasDetails = details.length > 0
    const totalDisciplinesScore = details.reduce(reduceWeekDisciplinesScore, 0)

    return (
      <div className={ classNames } style={ styles } data-week={ number }>

        <div className="schoolRatingGraphOverlayWeek-content" ref={ el => this.$content = el }>
          <WeekTop rank={ rank } score={ score }/>

          { hasDetails && <WeekForWeek score={ totalDisciplinesScore }/> }
          { details.map(discipline => <WeekDiscipline {...discipline} />) }
        </div>
      </div>
    )
  }
}
