import { Component } from 'preact'

export default class NewPaybarHiddenImages extends Component {
  shouldComponentUpdate() {
    return false
  }

  render(props) {
    const baseUrl = `${window.application.pageId}/files/`
    const baseBookshelfUrl = bookKey => `/projects/book-${bookKey}/files/`
    const isBook = props.product === 'book'
    const isBookshelf = props.product === 'bookshelf'

    return (
      <div className="newPaybarHiddenImages">
        { props.books.map(({ key }) => (
          <div className="newPaybarHiddenImages-in">
            { isBook &&
              <img className="is__preloadable" src={ baseUrl + '_book-cover-face.jpg' }/>
            }

            { isBookshelf &&
              <div>
                <img className="is__preloadable" src={ baseBookshelfUrl(key) + '_book-cover-face.jpg' }/>
                <img className="is__preloadable" src={ baseBookshelfUrl(key) + '_book-cover-3d.png' }/>
              </div>
            }
          </div>
        ))}

        { props.product === 'prep' &&
          <img className="is__preloadable" src={ baseUrl + 'rocket.png' }/>
        }
      </div>
    )
  }
}
