import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import {
  setTypeGift,
  setTypeSelf,
  setTypeUpgrade,
  setTypeCompany,
} from './newPaybarActions'

import getAvailableTabs from './getAvailableTabs'

import NewPaybarTabsSwitcher from './newPaybarTabsSwitcher'

class NewPaybarTabs extends Component {
  render(props) {
    const tabs = getAvailableTabs(props)

    return (
      <div className={ cx('newPaybarTabs', `device ${props.device}`) }>
          <div className="newPaybarTabs-switchers">

            { tabs.includes('upgrade') &&
              <NewPaybarTabsSwitcher
                text={ I18n.t(`newPaybar.tabs.${props.product}.upgrade`) }
                mobileText={ I18n.t(`newPaybar.tabs.${props.product}.upgrade`) }
                isActive={ props.type === 'upgrade' }
                isUpgrade={ true }
                onClick={ props.setTypeUpgrade }
              />
            }

            { tabs.includes('self') &&
              <NewPaybarTabsSwitcher
                text={ I18n.t(`newPaybar.tabs.${props.product}.self`) }
                mobileText={ I18n.t(`newPaybar.tabs.${props.product}.mobileSelf`) }
                isActive={ props.type === 'self' }
                onClick={ props.setTypeSelf }
              />
            }

            { tabs.includes('gift') &&
              <NewPaybarTabsSwitcher
                text={ I18n.t(`newPaybar.tabs.${props.product}.gift`) }
                isActive={ props.type === 'gift' }
                onClick={ props.setTypeGift }
              />
            }

            { tabs.includes('company') &&
              <NewPaybarTabsSwitcher
                text={ I18n.t(`newPaybar.tabs.${props.product}.company`) }
                isActive={ props.type === 'company' }
                onClick={ props.setTypeCompany }
              />
            }

          </div>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    prices: newPaybar.prices,
    product: newPaybar.product,
    type: newPaybar.type,
    subscription: newPaybar.subscription,
    hasCompanyTabOnly: newPaybar.hasCompanyTabOnly,
  }
}

const mapDispatchToProps = {
  setTypeGift,
  setTypeSelf,
  setTypeUpgrade,
  setTypeCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarTabs)
