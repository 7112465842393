import { Component } from 'preact'

export class SovietCommentFormAuthorName extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <input type="hidden" name="name" value={ this.props.name } />
    )
  }
}

export class SovietCommentFormAuthorEmail extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <input type="hidden" name="email" value={ this.props.email } />
    )
  }
}
