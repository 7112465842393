import { toDateParam, CURRENT_DATE_PLACEHOLDER } from 'lib/helpers/linksHelper'

const currentDate = toDateParam(new Date())

const setCurrentDateForLink = (_, el) => {
  const $link = $(el)
  const hrefWithCurrentDate = $link.attr('href').replace(CURRENT_DATE_PLACEHOLDER, currentDate)

  $link.attr('href', hrefWithCurrentDate)
}

module.exports = () => {
  $(`a[href*=${CURRENT_DATE_PLACEHOLDER}]`).each(setCurrentDateForLink)
}
