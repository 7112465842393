import { Component } from 'preact'

export default class NewPaybarLectureViewButton extends Component {
  constructor(props) {
    super(props)

    this.showViewButton = this.showViewButton.bind(this)
  }

  componentDidMount() {
    this.updateViewButton()
  }

  componentDidUpdate() {
    this.updateViewButton()
  }

  updateViewButton() {
    if (!this.props.isSubscribed) return

    this.showViewButton()
  }

  showViewButton() {
    this.changeVisibilityViewButton({ $el: $('.js__lectureViewButtonWrapper') })
  }

  changeVisibilityViewButton({ $el }) {
    $el
      .removeClass('is__hidden')
  }

  render() {
    return (<div />)
  }
}
