const SEARCH_THRESHOLD = 1

const converter = ({
  CATALOGUE: ({ isCatalogueToggled }) => isCatalogueToggled,

  PROPER: ({ currentValue, isInputFocused, properSuggestions }) =>
    properSuggestions.length && currentValue.length > SEARCH_THRESHOLD && isInputFocused,

  RECENT: ({ currentValue, isInputFocused, isCatalogueToggled, properSuggestions }) =>
    (!properSuggestions.length
    || currentValue.length <= SEARCH_THRESHOLD)
    && isInputFocused && !isCatalogueToggled,
})

export default (currentValue, isInputFocused, isCatalogueToggled, properSuggestions) =>
  Object.keys(converter)
    .find(key => converter[key]({
      currentValue,
      isInputFocused,
      isCatalogueToggled,
      properSuggestions,
    }))
