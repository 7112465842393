import AUTH_JSON_HEADERS from 'authJsonHeaders'

import camelize from 'lib/camelize'

export const fetchSovietsUnanswered = (limit = 0, offset = 0) => {
  const limitParam = limit ? `&limit=${limit}` : ''
  const offsetParam = offset ? `&offset=${offset}` : ''
  const url = `/soviet/advices?scope=unreleased${limitParam}${offsetParam}`

  return fetch(url, { headers: AUTH_JSON_HEADERS })
    .then(res => res.json())
    .then(json => json.map(camelize))
    .catch(e => console.error(e))
}
