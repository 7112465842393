import { createStore as createReduxStore } from 'redux'

import sovietCompilationReducer from './sovietCompilationReducer'
import { persistState } from 'reduxStorage'

export const DEFAULT_STATE = {
  isReadOnly: false,
  canPublish: false,
  canEdit: true,
  isPublishing: false,
  hasPublishError: false,
  title: '',
  summary: '',
  description: '',
  links: [],
  soviets: [],
  layout: 'bottom',
  textColor: 'black',
  customThemeLayout: 'bottom',
  customThemeTextColor: 'black',
  coverUrl: '',
  coverId: '',
  categories: [],
}

export const PERSISTABLE_PROPS = [
  'title', 'summary', 'description',
  'links',
  'layout', 'textColor', 'customThemeLayout', 'customThemeTextColor',
  'coverUrl', 'coverId', 'customCoverUrl',
  'categories',
  'theme',
]

export function persistableState(state) {
  return PERSISTABLE_PROPS.reduce((hash, prop) => {
    hash[prop] = state.sovietCompilation[prop]
    return hash
  }, {})
}

export function storeToJsonParams(state) {
  let params = persistableState(state)

  params.text_color = params.textColor
  params.cover_id = params.coverId
  params.advice_date_slugs = params.links

  return params
}

export function createStore(props) {
  const store = createReduxStore(sovietCompilationReducer, { sovietCompilation: props })

  if (props.shouldPersist) {
    store.subscribe(persistState(store, props.scope, (state) => {
      return { sovietCompilation: persistableState(state) }
    }))
  }

  return store
}
