import { Component } from 'preact'

import { Product, ProductMenuItem } from '../product'

export default class SovietCommentsMenu extends Component {
  constructor(props) {
    super(props)

    this.baseUrl = `/soviet/${props.sovietKey}/comments/`
  }

  switchTab(url, e) {
    e.preventDefault()
    this.props.changeTabAction('', url)
  }

  render(props) {
    const allCount = props.count.approved + props.count.new
    const isAllActive = props.activeTab === 'all'
    const isNewActive = props.activeTab === 'new'
    const isSunkActive = props.activeTab === 'sunk'

    return (
      <Product name="Комментарии" mod="h2">
        <ProductMenuItem
          link={ this.baseUrl }
          isActive={ isAllActive }
          isDisabled={ allCount === 0 && !isAllActive }
          count={ allCount }
          onClick={ this.switchTab.bind(this, this.baseUrl) }
        >
          Все
        </ProductMenuItem>

        <ProductMenuItem
          link={ `${this.baseUrl}new/` }
          isActive={ isNewActive }
          isDisabled={ props.count.new === 0 && !isNewActive }
          count={ props.count.new }
          onClick={ this.switchTab.bind(this, `${this.baseUrl}new/`) }
        >
          Неопубликованные
        </ProductMenuItem>

        <ProductMenuItem
          link={ `${this.baseUrl}sunk/` }
          isActive={ isSunkActive }
          isDisabled={ props.count.sunk === 0 && !isSunkActive }
          onClick={ this.switchTab.bind(this, `${this.baseUrl}sunk/`) }
        >
          Вода
        </ProductMenuItem>
      </Product>
    )
  }
}
