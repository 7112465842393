import { Image } from '../image'
import { Caption } from '../textNodes'

export default ({ cover, title, duration, lectureKey: key, courseKey }) => {
  const href = `/lectures/${courseKey}/${key}/`

  return (
    <a className="lecturePreview no-u" href={ href }>
      <Image src={ cover } size={ [94, 52] }/>
      <Caption>
        <span class="lecturePreview-title">{ title }</span>
        <span class="lecturePreview-duration smallcapitals">{ duration }</span>
      </Caption>
    </a>
  )
}
