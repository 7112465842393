const serializeJS = require('serialize-javascript')
const insane = require('insane')

const md = require('./utils/commonMark')
const typografer = require('bureau-typograf/helpers/typograf')
const { birmanize, shortenMonths } = require('./dateHelper')
const truncateLink = require('./truncateLink')
const transliterate = require('./transliterate')

const { isFirstName, isCompanyName } = require('./names')

let SANITIZE_OPTIONS = insane.defaults
SANITIZE_OPTIONS.allowedAttributes.div = ['class']
SANITIZE_OPTIONS.allowedAttributes.span = ['class']
SANITIZE_OPTIONS.allowedAttributes.code = ['class']
SANITIZE_OPTIONS.allowedAttributes.pre = ['class']
SANITIZE_OPTIONS.allowedAttributes.ul = ['class']
SANITIZE_OPTIONS.allowedAttributes.a = ['href', 'name', 'target', 'class']
SANITIZE_OPTIONS.allowedTags.push('liftout')

const MONTHS = [
  'Январь', 'Февраль', 'Март',
  'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь',
  'Октябрь', 'Ноябрь', 'Декабрь',
]

const MONTHS_GENETIVE = [
  'января', 'февраля', 'марта',
  'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября',
  'октября', 'ноября', 'декабря',
]

const fullMonthName = date => MONTHS_GENETIVE[date.getMonth()]

const pluralize = (amount, one, two, five) => {
  let number = amount

  number = Math.abs(number)
  number %= 100

  if (number >= 5 && number <= 20) {
    return five
  }

  number %= 10
  if (number === 1) {
    return one
  }

  if (number >= 2 && number <= 4) {
    return two
  }

  return five
}

const normalize = (text) => {
  return text.replace(/ /g, ' ')
}

const capitalize = (str = '') => {
  return str.charAt(0).toUpperCase() + str.substring(1)
}

const formatDate = (date, options = {}) => {
  if (date.getFullYear() !== (new Date()).getFullYear() && !options.skipYear) {
    return `${date.getDate()} ${fullMonthName(date)} ${date.getFullYear()}`
  }

  return `${date.getDate()} ${fullMonthName(date)}`
}

const formatDateInterval = (from, to) => {
  if (from.getMonth() === to.getMonth()) {
    return `${from.getDate()}—${to.getDate()} ${fullMonthName(from)}`
  }

  return `${formatDate(from, { skipYear: true })} — ${formatDate(to, { skipYear: true })}`
}

const parseNumber = (number) => {
  number = String(number).trim()
    .replace(',', '.')
    .replace('−', '-')

  return parseFloat(number, 10)
}

const formatNumber = (number, options = { precision: 2 }) => {
  if (!['number', 'string'].includes(typeof number)) return null

  let parsedNumber = parseNumber(number).toFixed(options.precision)
  const isParseFailed = Number.isNaN(parsedNumber) || parsedNumber === 'NaN'

  if (isParseFailed) return number

  if (!options.keepInsignificantZeroes) parsedNumber = parseFloat(parsedNumber)

  return String(parsedNumber)
    .replace('.', ',')
    .replace('-', '−')
}

const signNumber = (number) => {
  const parsedNumber = parseNumber(number)

  if (parsedNumber >= 0) return `+${parsedNumber}`
  return number
}

const wrapFractionalPart = number => {
  if (!number) return number

  const [integral, fractional] = number.split(',')

  if (!fractional) return number

  return `${integral}<small>,${fractional}</small>`
}

const monthName = number => MONTHS[number - 1]

const toSentence = (words) => {
  if (words.length <= 1) return words[0]

  const lastWord = words.pop()
  const lastWordConnector = I18n.t('sentence.lastWordConnector')

  return `${words.join(', ')} ${lastWordConnector} ${lastWord}`
}

const preprocessMd = text => text
  .replace(/<\/(h1|h2|h3|h4|h5|h6|div|p)>\n([a-zа-я0-9])/igm, '</$1>\n\n$2')

const formatText = (text) => {
  if (!text) return ''

  return typografer.execute(md.render(preprocessMd(text)))
}

const extractYoutubeVideoId = (text) => {
  let videoId = null

  const youtubeLinkPattern = /(?:^\S*)\/(?:watch)(?:\/|\?v=)([a-zA-Z_0-9-]+)(?:\S*$)/i
  if (youtubeLinkPattern.test(text)) {
    videoId = text.match(youtubeLinkPattern)[1]
  }

  const youtubeShortLinkPattern = /(?:^\S*)(?:youtu\.be)(?:\/)([a-zA-Z_0-9-]+)(?:\S*$)/i
  if (youtubeShortLinkPattern.test(text)) {
    videoId = text.match(youtubeShortLinkPattern)[1]
  }

  return videoId
}

const wrapText = ($, html) => {
  const $html = $('<div>').append($(html))

  $html.find('liftOut').each((_, el) => {
    const $liftOut = $(el)
    const liftOutContent = $liftOut.text()
    const $newLiftOut = $(`<div class="sidenote is__marginal module"><div class="sidenote-text module"><div class="textNode"><p>${liftOutContent}</p></div></div></div>`)

    $liftOut.parent().before($newLiftOut)
  })
  $html.find('liftOut').remove()

  $html.find('p').each((_, el) => {
    const $textEl = $(el)
    const text = $textEl.text()
    const videoId = extractYoutubeVideoId(text)
    if (videoId) {
      const videoSrc = `//www.youtube.com/embed/${videoId}`
      const $videoHtml = $(`<div class="module media"><div class="youtubeVideo module"><iframe data-youtube-id=${videoId} data-src=${videoSrc} width="1280" height="720" frameborder="0" allowfullscreen></iframe></div></div>`)
      $textEl.before($videoHtml)
      $textEl.remove()
    }
  })

  $html.find('p, blockquote').wrap('<div class="textNode" />')
  $html.find('ul, ol').wrap('<div class="textNode is__list" />')
  $html.find('li')
    .filter((_, li) => !$(li).children().is('.textNode'))
    .wrapInner('<div class="textNode"><p></p></div>')
  $html.find('h1').wrap('<div class="heading-1" />')
  $html.find('h2').wrap('<div class="heading-2" />')
  $html.find('h3').wrap('<div class="heading-3" />')
  $html.find('img').wrap(function() {
    return $(`<div class="is__img image media ${$(this).attr('class')}" />`)
  })

  return $html.html()
}

const typograf = (text) => {
  if (!text) return ''

  return typografer.execute(text)
}

const sanitize = (html) => insane(html, SANITIZE_OPTIONS)

const toInitials = (name = '') => name
  .split(/\s/)
  .map(part => part[0] || '')
  .map(letter => letter.toUpperCase())
  .join('')

const toSnakeCase = text => text.replace(/([A-Z])/g, a => `_${a.toLowerCase()}`)

const truncate = (text, options = {}) => {
  const limit = options.limit || 0
  const omission = options.omission || '...'

  if (!text || text.length < limit) return text

  return text.substring(0, limit) + omission
}

const truncateLinks = (text) => {
  const link = /(https?:\/\/[^\s]+)/ig

  return text.replace(link, match => truncateLink(match))
}

const sovietCommentsCount = (count, newCommentsCount = 0) => {
  if (count > 0 && !newCommentsCount) return `<span class="sovietCommentsCount"> 🗩${count}</span>`

  if (newCommentsCount > 0 && !count) {
    return `<span class="sovietCommentsCount is__newCommentsOnly"> 🗩<span class="sovietCommentsCount-new">+${newCommentsCount}</span></span>`
  }

  if (newCommentsCount > 0) {
    return `<span class="sovietCommentsCount"> 🗩${count} <span class="sovietCommentsCount-new">+${newCommentsCount}</span></span>`
  }

  return null
}

const extractExcerptFromPug = (pug) => {
  return String(pug)
    .split('\n')
    .filter(line => /^p /.test(line))
    .map(line => line.replace(/^p /, ''))
    .filter(line => line.trim())
    .slice(0, 2)
    .join(' ')
}

const splitDigits = str => String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

const formatPrice = (price, { currency } = {}) => {
  const shouldSplit = price >= 10000
  const formattedPrice = shouldSplit ? splitDigits(price) : price

  return String(currency ? `${formattedPrice} ${currency}` : formattedPrice).replace(/^-/, '−')
}

const addWeeksToDate = (weeksNumber) => {
  const date = new Date()
  date.setDate(date.getDate() + weeksNumber * 7)

  return birmanize(date, { format: 'fullMonthDateOnly' })
}

const canDowncase = text => {
  const parts = text.split(/\s/)
  const firstWord = parts[0]
  const secondWord = parts[1]
  const firstTwoWords = `${firstWord} ${secondWord}`

  if (isFirstName(firstWord)) return false
  if (isCompanyName(firstWord) || isCompanyName(firstTwoWords)) return false

  return true
}

const ABBRS = Object.fromEntries(['fff', 'ui'].map(word => [word, word.toUpperCase()]))
const mindAbbrs = word => ABBRS[word.toLowerCase()] || word

module.exports = {
  serializeJS,
  pluralize,
  normalize,
  capitalize,
  formatDateInterval,
  formatNumber,
  parseNumber,
  signNumber,
  wrapFractionalPart,
  monthName,
  toSentence,
  formatDate,
  birmanize,
  formatText,
  typograf,
  sanitize,
  wrapText,
  toSnakeCase,
  toInitials,
  truncate,
  truncateLinks,
  transliterate,
  sovietCommentsCount,
  extractExcerptFromPug,
  splitDigits,
  formatPrice,
  addWeeksToDate,
  shortenMonths,
  canDowncase,
  mindAbbrs,
}
