import { Component } from 'preact'

export default class SovietAskFormSpamBotDetector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      summand01: Math.round(Math.random() * 1000),
      summand02: Math.round(Math.random() * 1000),
    }
  }

  get summValue() {
    return this.state.summand01 + this.state.summand02
  }

  render() {
    return (
      <div style="display: none;">
        <input type="text" name="summand-01" value={ this.state.summand01 } id="summand-01" />
        <input type="text" name="summand-02" value={ this.state.summand02 } id="summand-02" />
        <input type="text" name="result" id="result" value={ this.summValue }></input>
      </div>
    )
  }
}
