import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'

import { setIsProcessing, setHasPublicationApprovalError, setIsPublicationApproved } from './sovietActions'
import Calendar from '../calendar/calendar'
import { birmanize } from 'lib/textHelper'
import { datify, moscowifyLocal } from '../calendar/timezoneUtils'
import { saveDraftAnd } from './saveDraft'

import PUBLISH_HEADERS from 'authJsonHeaders'

const DropdownItem = ({ children, ...props }) => {
  const className = cx(
    'sovietCalendarPopupContainer-dropdownItem',
    'dropdown-item',
    { is__disabled: props.disabled }
  )

  return (
    <div className={ className }>
      <button className="button is__noBorder is__thin" { ...props}>
        { children }
      </button>
    </div>
  )
}

class SovietCalendarPopupContainer extends Component {
  constructor(props) {
    super(props)

    this.publishSoviet = this.publishSoviet.bind(this)
    this.previewSoviet = this.previewSoviet.bind(this)
    this.redirectToPublishedSoviet = this.redirectToPublishedSoviet.bind(this)
    this.unpublishSoviet = this.unpublishSoviet.bind(this)
    this.preventParentBlur = this.preventParentBlur.bind(this)
    this.afterClick = this.afterClick.bind(this)
  }

  publishSoviet() {
    this.sendPublicationApprovalRequest('PUT')
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then(this.redirectToPublishedSoviet)
            .catch(this.props.setHasPublicationApprovalError)
        } else {
          this.props.setHasPublicationApprovalError()
        }
      })
      .catch(this.props.setHasPublicationApprovalError)
  }

  unpublishSoviet() {
    this.sendPublicationApprovalRequest('DELETE')
      .then((res) => {
        if (res.status === 204) {
          this.props.setIsPublicationApproved(false)
        } else {
          this.props.setHasPublicationApprovalError()
        }
      })
      .catch(this.props.setHasPublicationApprovalError)
  }

  previewSoviet() {
    this.props.setIsProcessing()

    saveDraftAnd({ context: this.context })
      .then(() => window.location = `/soviet/${this.props.slug}/preview/`)
  }

  sendPublicationApprovalRequest(method) {
    if (!this.props.canPublish) return Promise.reject()

    this.props.setIsProcessing()

    if (method === 'DELETE') return this.fetchPublicationApproval(method)

    return saveDraftAnd({ context: this.context })
      .then(() => this.fetchPublicationApproval(method))
  }

  fetchPublicationApproval(method) {
    return fetch(this.publicationApprovalUrl, {
      method,
      headers: PUBLISH_HEADERS,
      credentials: 'include',
    })
  }

  get publicationApprovalUrl() {
    return `/soviet/advices/${this.props.sovietId}/publication_approval`
  }

  get calendarHeading() {
    if (this.props.publishAt) {
      const date = moscowifyLocal(datify(this.props.publishAt))
      const formattedDate = birmanize(date, { format: 'dateYearOnly' })

      if (this.props.hasPublishDateError) return `Дата занята: ${ formattedDate }`
      return `Дата публикации: ${ formattedDate }`
    }

    return 'Дата публикации'
  }

  redirectToPublishedSoviet(json) {
    window.location = `/soviet/${json.slug}/`
  }

  preventParentBlur(e) {
    e.preventDefault()
  }

  afterClick() {
    if (this.props.afterClick) this.props.afterClick()
  }

  render(props) {
    const { isVerbose = false } = this.props
    const viewText = `Посмотреть ${ isVerbose ? 'совет' : '' }`
    const buttonText = props.isPreview ? 'Опубликовать' : viewText
    const unpublishButtonText = props.isPublicationApproved ? 'Снять с публикации' : 'Не опубликован'
    const handleButtonClick = props.isPreview ? this.publishSoviet : this.previewSoviet
    const className = cx('sovietCalendarPopupContainer', { has__error: props.hasPublishDateError })

    return (
      <div className={ className } onClick={ this.afterClick }>

        <Calendar heading={ this.calendarHeading } />

        <DropdownItem
          onClick={ handleButtonClick }
          onMouseDown={ this.preventParentBlur }
          { ...{ disabled: props.isPublishingOrUnpublishing } }
        >
          { buttonText }
        </DropdownItem>

        <DropdownItem
          onClick={ this.unpublishSoviet }
          onMouseDown={ this.preventParentBlur }
          { ...{ disabled: props.isPublishingOrUnpublishing || !props.isPublicationApproved } }
        >
          { unpublishButtonText }
        </DropdownItem>

      </div>
    )
  }
}

const mapStateToProps = ({ soviet }) => {
  return {
    sovietId: soviet.id,
    slug: soviet.slug,
    isPreview: soviet.isPreview,
    canPublish: soviet.canPublish,
    isPublishingOrUnpublishing: soviet.isPublishingOrUnpublishing,
    isPublicationApproved: soviet.isPublicationApproved,
    publishAt: soviet.publishAt,
  }
}

const mapDispatchToProps = {
  setIsProcessing,
  setHasPublicationApprovalError,
  setIsPublicationApproved,
}

export default connect(mapStateToProps, mapDispatchToProps)(SovietCalendarPopupContainer)
