import CollecturePricingFooter from './collecturePricingFooter'

const productSubscription = window.productSubscription || {}

if (productSubscription) {
  $('.js__collecturePricingFooter').each(function() {
    const productId = productSubscription.subscription.product_id
    const prices = productSubscription.prices || {}

    const options = $(this).data('options') || {}
    const isCompact = options.view === 'compact'

    const props = {
      productId,
      prices,
      isCompact,
    }

    $(this).reactify(CollecturePricingFooter, props)
    $(this).children().unwrap()
  })
}
