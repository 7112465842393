import { Caption } from '../../textNodes'
import { SchoolDevicesIcon } from '../../icons'

export default () => (
  <div className="newPaybarDevices">
    <div className="newPaybarRequirements-icon">
      <SchoolDevicesIcon/>
    </div>
    <div className="newPaybarDevices-text">
      <Caption html={ I18n.t('newPaybar.devices.lecture') }/>
    </div>
  </div>
)
