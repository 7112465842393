import { Component } from 'preact'
import cx from 'classnames'

import { fetchSchoolData, getPrepLink } from './schoolSpacerUtils'
import { pluralize } from 'lib/textHelper'
import { schoolPrepsDeadlineDaysSpacer as config } from './schoolSpacersConfig'

import { Caption, TextNode } from '../textNodes'
import SchoolSpacerTitle from './schoolSpacerTitle'
import TiltedSquareNumber from './tiltedSquareNumber'

const DAY_DURATION = 24 * 60 * 60 * 1000

const calculateDaysLeft = (date) => {
  const now = new Date().getTime()
  const till = new Date(date).getTime()

  return ((till - now) / DAY_DURATION | 0) + 1
}

const SpacerPlot = (props) => {
  const daysLeft = calculateDaysLeft(props.endDate)
  const daysString = pluralize(daysLeft, 'день', 'дня', 'дней')

  return (
    <div className="schoolPrepsDeadlineDaysSpacer-plot">
      <a href={ props.link } className="novisited doubleHover is__noUnderline">
        <TiltedSquareNumber
          number={ daysLeft }
          caption={ `${ daysString }, чтобы успеть` }
          mobileCaption={ `${ daysString }, чтобы<br />успеть` }
        />
      </a>
      <div className="schoolPrepsDeadlineDaysSpacer-text">
        <div className="device laptop desktop">
          <SchoolSpacerTitle href={ props.link } title={ config.title } />
        </div>
        <div className="device mobile">
          <SchoolSpacerTitle href={ props.link } title={ config.titleMobile } />
        </div>
        <TextNode>
          <span className="device laptop desktop" dangerouslySetInnerHTML={ { __html: config.text } } />
          <span className="device mobile" dangerouslySetInnerHTML={ { __html: config.textMobile } } />
          : <a
              href={ props.link }
              className="schoolPrepsDeadlineDaysSpacer-programLink novisited"
            >
              программа
            </a>
        </TextNode>
      </div>
    </div>
  )
}

const EnrollButton = (props) => {
  const actionText = props.isGift ? 'Подарить' : 'Записаться'
  const link = `${props.link}&${props.isGift ? 'gift' : 'self'}`

  return (
    <div className="schoolPrepsDeadlineDaysSpacer-action">
      <a href={ link } className="schoolPrepsDeadlineDaysSpacer-button button is__thin">
        { actionText } за { props.currentPrice } ₽
      </a>
      <Caption>
        <span class="device laptop desktop">Раньше занятия — ниже цена.</span>
        <br class="device laptop desktop" />
        В пн цена повысится до { props.nextPrice } ₽
      </Caption>
    </div>
  )
}

export default class SchoolPrepsDeadlineDaysSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { currentPrice: '', nextPrice: '' }

    this.link = getPrepLink()
    this.isFull = props.size === 'full'
    this.isHalf = props.size === 'half'
  }

  componentDidMount() {
    fetchSchoolData()
      .then(json => this.setState({
        currentPrice: json.currentPrepPrice.price,
        nextPrice: json.currentPrepPrice.nextPrice,
      }))
      .catch(e => console.error(e))
  }

  render(props) {
    const classes = cx(
      'schoolPrepsDeadlineDaysSpacer',
      {
        is__full: this.isFull,
        is__half: this.isHalf,
      }
    )

    return (
      <div className={ classes }>
        <div className="schoolPrepsDeadlineDaysSpacer-cols">
          <SpacerPlot
            link={ this.link }
            endDate={ props.endDate }
          />
          { this.state.currentPrice &&
            <EnrollButton
              link={ this.link }
              currentPrice={ this.state.currentPrice }
              nextPrice={ this.state.nextPrice }
              isGift={ props.isGift }
            />
          }
        </div>
      </div>
    )
  }
}
