import { Component } from 'preact'
import { Caption } from '../textNodes'

class SubscriptionPricingPreorderCaption extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <Caption html={ I18n.t('books.discountPriceCaptionPreorder') } />
    )
  }
}

module.exports = SubscriptionPricingPreorderCaption
