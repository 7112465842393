import { Caption } from '../textNodes'

const TiltedSquareNumber = (props) => {
  return (
    <div className="tiltedSquareNumber-wrapper">
      <div className="tiltedSquareNumber">
        { props.number &&
          <div className="tiltedSquareNumber-numberWrapper">
            <div
              className="tiltedSquareNumber-number"
              dangerouslySetInnerHTML={ { __html: props.number } }
            />
          </div>
        }

        { props.number &&
          <Caption html={ props.caption } />
        }
      </div>
      { props.mobileCaption &&
        <Caption
          className="tiltedSquareNumber-mobileCaption"
          html={ props.mobileCaption }
        />
      }
    </div>
  )
}

export default TiltedSquareNumber
