import { Component } from 'preact'
import cx from 'classnames'

import { pluralize } from 'lib/textHelper'
import JSON_HEADERS from 'jsonHeaders'

import { Heading3, TextNode } from '../textNodes'

const SMALL_TITLE_LENGTH = 30
const MEDIUM_TITLE_LENGTH = 48

const striptags = html => $(`<div>${html}</div>`).text()

const calcDaysLeft = (tillTime) => {
  const HOUR_DURATION = 1000 * 60 * 60
  const DAY_DURATION = HOUR_DURATION * 24

  const now = new Date().getTime()

  return Math.round((tillTime * 1000 - now) / DAY_DURATION) + 1
}

const VacancyTitle = (props) => {
  return (
    <div className="vacanciesSpacer-titleWrapper">
      <div className="vacanciesSpacer-title">
        { props.children }
      </div>
    </div>
  )
}

const DaysRemaining = (props) => {
  return (
    <span className="vacanciesSpacer-remain">
      { `🕑Ещё ${props.days} ${pluralize(props.days, 'день', 'дня', 'дней')}` }
    </span>
  )
}

const VacancyPlaceholder = (props) => {
  const isHalf = props.size === 'half'
  const isFull = props.size === 'full'

  const classes = cx(
    'vacanciesSpacer',
    'is__placeholder',
    {
      is__half: isHalf,
      is__full: isFull,
    }
  )

  return (
    <div className={ classes }>
      <div className="vacanciesSpacer-text">
        <Heading3>
          Вакансия
        </Heading3>

        { isHalf &&
          <TextNode>
            Прямо сейчас мы никого не ищем, но в бюро всегда нужны дизайнеры, редакторы и разработчики
          </TextNode>
        }

        { isFull &&
          <TextNode>
            <span className="device desktop"><b>Вакансия:</b> п</span>
            <span className="device mobile laptop">П</span>рямо сейчас мы никого не ищем, но в бюро всегда нужны дизайнеры, редакторы и разработчики
          </TextNode>
        }
      </div>

      <div className="vacanciesSpacer-buttonWrapper">
        <a
          href="mailto:mail@bureau.ru?Subject=Работа в бюро"
          className="button vacanciesSpacer-button is__thin">
          Написать нам
        </a>
      </div>
    </div>
  )
}

class VacanciesSpacer extends Component {
  constructor(props) {
    super(props)

    this.state = { vacancies: [] }
  }

  componentDidMount() {
    this.fetchVacancies()
  }

  fetchVacancies() {
    fetch('/api/system/vacancies/', { headers: JSON_HEADERS })
      .then(res => res.json())
      .then(json => this.setState({ vacancies: this.decorateVacancies(json) }))
      .catch(e => console.error(e))
  }

  decorateVacancies(vacancies) {
    return vacancies.map((vacancy) => {
      return ({
        title: striptags(vacancy.text),
        href: vacancy.link,
        daysLeft: calcDaysLeft(vacancy.date_end),
      })
    })
  }

  render(props) {
    if (!this.state.vacancies.length) return <VacancyPlaceholder { ...props } />

    const isHalf = props.size === 'half'
    const isFull = props.size === 'full'

    const rndIndex = Math.floor(Math.random() * this.state.vacancies.length)
    const vacancy = this.state.vacancies[rndIndex]

    const isShortTitle = vacancy.title.length <= SMALL_TITLE_LENGTH
    const isMediumTitle = vacancy.title.length > SMALL_TITLE_LENGTH && vacancy.title.length <= MEDIUM_TITLE_LENGTH
    const isLongTitle = vacancy.title.length > MEDIUM_TITLE_LENGTH

    const lowercasedTitle = `${vacancy.title[0].toLowerCase()}${vacancy.title.slice(1)}`
    const uppercasedTitle = `${vacancy.title[0].toUpperCase()}${vacancy.title.slice(1)}`

    const classes = cx(
      'vacanciesSpacer',
      {
        is__shortTitle: isShortTitle,
        is__mediumTitle: isMediumTitle,
        is__longTitle: isLongTitle,
        is__half: isHalf,
        is__full: isFull,
      }
    )

    return (
      <div className={ classes }>
        <div className="vacanciesSpacer-text">
          <div className="device laptop desktop">
            { isFull && isShortTitle &&
              <VacancyTitle>
                <b>Работа: </b>
                { vacancy.title }
              </VacancyTitle>
            }

            { isFull && (isMediumTitle || isLongTitle) &&
              <VacancyTitle>
                <b>Работа: </b>
                { lowercasedTitle }
              </VacancyTitle>
            }

            { isFull &&
              <TextNode>
                <DaysRemaining days={ vacancy.daysLeft } />
              </TextNode>
            }

            { isHalf &&
              <div>
                <Heading3>
                  Работа
                </Heading3>
                <TextNode>
                  { uppercasedTitle }
                  { vacancy.title.length < 60 &&
                    <br />
                  }
                  <DaysRemaining days={ vacancy.daysLeft } />
                </TextNode>
              </div>
            }
          </div>

          <div className="device mobile">
            <Heading3>
              Работа
            </Heading3>
            <TextNode>
              { uppercasedTitle }
              { (isShortTitle || isMediumTitle) &&
                <br />
              }
              <DaysRemaining days={ vacancy.daysLeft } />
            </TextNode>
          </div>
        </div>

        <div className="vacanciesSpacer-buttonWrapper">
          <a
            href={ vacancy.href }
            className="button vacanciesSpacer-button">
            Откликнуться на вакансию
          </a>
        </div>
      </div>
    )
  }
}

export default VacanciesSpacer
