export const myMenuItems = [
  {
    title: 'Бюросфера',
    className: 'burosfera',
    href: '/burosfera/me',
  },
  {
    title: 'Мои книги',
    className: 'myBooks',
    href: '/books/my/',
  },
  {
    title: 'Мои лекции',
    className: 'myLectures',
    href: '/lectures/my/',
  },
  {
    title: 'Школьный кабинет',
    className: 'cabinet',
    href: '/school/classroom/',
  },
  {
    title: 'Курс',
    className: 'myCourse',
  },
]

export const otherMenuItems = [
  {
    title: I18n.t('userPopup.card.default', { locale: 'ru' }),
    className: 'card',
    href: '/pay/edit/',
  },
  {
    title: 'Выход',
    className: 'logout',
    href: '/burosfera/logout/',
  },
]
