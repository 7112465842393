import { Component } from 'preact'

import isBookshelfAll from 'lib/isBookshelfAll.js'
import { Caption, TextNode } from '../textNodes'
import SubscriptionPricing from '../subscriptionPricing/subscriptionPricing'
import PurchasePricing from '../subscriptionPricing/purchasePricing'
import UpgradePricing from '../subscriptionPricing/upgradePricing'

const PUBLISHING_PRICING_MAPPING = {
  gift: PurchasePricing,
  self: SubscriptionPricing,
  upgrade: UpgradePricing,
}

const translations = I18n.translations[I18n.locale].newPaybar

class NewPaybarPricing extends Component {
  isSubscriptionLikePricing() {
    return ['book', 'bookshelf', 'lecture', 'collecture'].includes(this.props.product)
  }

  get period() {
    const { renewalPeriod, subscriptionPeriod } = this.props.prices
    const period = subscriptionPeriod >= 6 ? subscriptionPeriod : renewalPeriod

    return period > 12 ? 12 : period
  }

  get pricingInfo() {
    const {
      product,
      productId,
      type,
      isPreorder,
      pricingBottomCaption,
      prices: { upgradePeriod, subscription, oneTime },
    } = this.props


    if (!translations.pricingInfo[`subscription_${this.period}`][product]) return null

    const isUpgradeToAll = (type === 'upgrade') && isBookshelfAll(productId)
    const isSpecialUpgrade = (type === 'upgrade') && upgradePeriod > 12
    const suffixUpgrade = isUpgradeToAll || isSpecialUpgrade ? 'WithFreeMonths' : ''
    const suffixPreorder = isPreorder ? 'WithPreorder' : ''

    if (pricingBottomCaption) return I18n.t('newPaybar.pricingInfo.customPricingBottomCaption', { startText: pricingBottomCaption })

    return I18n.t(
      `newPaybar.pricingInfo.subscription_${this.period}.${product}.${type}${suffixUpgrade}${suffixPreorder}`,
      { price: subscription, oneTime: oneTime }
    )
  }

  get priceLabel() {
    if (!translations.priceLabel[this.props.product]) return null

    return I18n.t(`newPaybar.priceLabel.${this.props.product}.${this.props.type}_${this.period}`)
  }

  render(props) {
    const isSubscriptionPricing = this.isSubscriptionLikePricing()
    const smallUnits = props.smallUnits || isSubscriptionPricing
    const ProductSubscriptionPricing = PUBLISHING_PRICING_MAPPING[props.type]
    const shouldDisplayDesktopBottomInfo = ['self', 'upgrade'].includes(props.type)

    return (
      <div className="newPaybarPricing">
        { isSubscriptionPricing &&
          <ProductSubscriptionPricing
            label={ this.priceLabel }
            prices={ props.prices }
            smallUnits={ smallUnits }
            size="title"
            product={ props.product }
            isBookshelfAll={ isBookshelfAll(props.productId) }
            hasCompactUnitsView={ props.hasCompactUnitsView }
          />
        }

        { !isSubscriptionPricing &&
          <PurchasePricing
            prices={ props.prices }
            label={ this.priceLabel }
            smallUnits={ smallUnits }
          />
        }

        { this.pricingInfo &&
          <TextNode
            className="newPaybarPricing-info module device device__mobile"
            html={ this.pricingInfo } />
        }

        { this.pricingInfo && shouldDisplayDesktopBottomInfo &&
          <Caption
            className="newPaybarPricing-info module device device__desktop device__laptop"
            html={ this.pricingInfo } />
        }
      </div>
    )
  }
}

export default NewPaybarPricing
