import { render } from 'preact'

export default function(Component, props, callback) {
  const mountedComponent = render(
      <Component {...props} />,
      this[0]
  )

  if (typeof callback === 'function') {
    return callback(mountedComponent)
  }

  return mountedComponent
}
