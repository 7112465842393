import { utmSource } from 'lib/helpers/linksHelper'
import reachGoal from 'lib/reachGoal'

export const LONG_TITLE_LENGTH = 26
export const VERY_LONG_TITLE_LENGTH = 34
export const LONG_LINK_LENGTH = 28
export const VERY_LONG_DESCRIPTION_LENGTH = 45

export const DEFAULT_BOOKSHELF_COVER_SIZE = [334, 342]
export const DEFAUL_HALF_LAPTOP_BOOKSHELF_COVER_SIZE = [157, 342]
export const DEFAULT_MOBILE_COVER_SIZE = [692, 342]

export const HALF_BOOK_COVER_SIZE = [342, 342]
export const FULL_BOOK_COVER_SIZE = [356, 270]

export const isBookshelf = id => /bookshelf-/i.test(id)

export const bookHref = id => {
  const source = utmSource({
    platform: 'Сайт бюро',
    postType: 'Банер',
    productType: 'Книги',
    product: id.replace(/(book|bookshelf)-/, ''),
    postDate: new Date(),
  })

  return `/projects/${id}/?utm_source=${source}`
}

export const trackGoal = reachGoal

export const isUpgradeMode = () => {
  const mode = location.search.match(/mode=([\w-]+)/i)

  return mode && mode[1] === 'upgrade'
}

export const specialOfferStub = {
  oneTime: 110,
  ownedBooks: [
    { key: 'typography', title: 'Типографика и вёрстка' },
    { key: 'ui', title: 'Пользовательский интерфейс' },
    { key: 'text', title: 'Информационный стиль' },
  ],
}

const pickRandomIndex = (arr) => {
  return Math.floor(Math.random() * Math.floor(arr.length))
}

export const pickTitle = ({ nameShort = '', name = '' }) => {
  if (nameShort && Array.isArray(nameShort)) {
    return nameShort[pickRandomIndex(nameShort)]
  }

  if (name && Array.isArray(name)) {
    return name[pickRandomIndex(name)]
  }

  return nameShort || name
}

export const bookshelfCoverSize = (coverSize) => {
  if (coverSize) return coverSize.split(':')

  return DEFAULT_BOOKSHELF_COVER_SIZE
}

export const bookshelfHalfLaptopCoverSize = (coverLaptopSize) => {
  if (coverLaptopSize) return coverLaptopSize.split(':')

  return DEFAUL_HALF_LAPTOP_BOOKSHELF_COVER_SIZE
}

export const mobileCoverSize = (coverMobileSize) => {
  if (coverMobileSize) return coverMobileSize.split(':')

  return DEFAULT_MOBILE_COVER_SIZE
}
