import { Component } from 'preact'
import MenuItem from './menuItem'

export default class BurosferaCpdMenuItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cpdScores: null,
      isFetched: false,
    }
  }

  get currentScore() {
    const { cpdScores } = this.state
    const scores = (cpdScores || {}).scores

    if (!scores || !(scores.current || {}).score_rounded) return null

    return scores.current
  }

  get plainTitle() {
    return this.props.title
  }

  get cpdScoreChange() {
    const { last_change_rounded: lastChangeRounded } = this.currentScore
    const lastChange = String(Math.abs(lastChangeRounded)).replace('.', ',')
    const lastChangeClass = lastChangeRounded > 0 ? 'is__up' : 'is__down'

    if (lastChange) {
      return `<span class="cpdBadge-scoreChange ${lastChangeClass}">${lastChange}</span>`
    }

    return ''
  }

  get cpdBadge() {
    const { score_rounded: score } = this.currentScore

    return `<div class="cpdBadge"><span class="cpdBadge-score">[${score}&#x0020;&#xE099;</span>${this.cpdScoreChange}</div>`
  }

  get title() {
    if (this.currentScore) return [this.plainTitle, this.cpdBadge].join(' ')

    return this.plainTitle
  }

  get href() {
    return '/burosfera/me/'
  }

  componentWillReceiveProps({ isOpen, isAuthenticated }) {
    const { isFetched } = this.state

    if (isOpen && isAuthenticated && !isFetched) {
      this.fetchCpdScores()
        .then(res => this.setState({ cpdScores: res, isFetched: true }))
    }
  }

  fetchCpdScores() {
    return fetch('/api/system/user/cpd/', { credentials: 'include' })
      .then(res => res.json())
      .catch(() => null)
  }

  render() {
    return (
      <MenuItem { ...this.props } title={ this.title } href={ this.href } />
    )
  }
}
