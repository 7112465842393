const toVideoPlayerOptions = require('toVideoPlayerOptions')
const VkVideo = require('./vkVideo')

$(document).on('appReady', () => {
  document
    .querySelectorAll('.vkVideo')
    .forEach(el => {
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      new VkVideo(el, options)
    })
})
