class ImagesList {
  constructor(images) {
    this.images = images
  }

  within({ position, radius }) {
    const viewBoundaries = {
      top: position,
      bottom: position + window.innerHeight,
    }

    return this.images.filter(image => {
      if (image.distanceFrom(viewBoundaries) < radius) {
        return true
      }

      image.cancel()
      return false
    }).sort((a, b) => {
      return a.distanceFrom(viewBoundaries) - b.distanceFrom(viewBoundaries)
    })
  }
}

module.exports = ImagesList
