import { Component } from 'preact'
import { Caption } from '../textNodes'

export default class BookshelfPricingFooter extends Component {
  get isUpgrade() {
    return !!this.props.prices.specialOffer
  }

  get footerCaption() {
    const captionType = this.isUpgrade ? 'captionUpgradeSpecial' : 'caption'

    return I18n.t(`books.bookshelf.subscriptionFooter.${captionType}`)
  }

  render() {
    return (
      <Caption html={ this.footerCaption } />
    )
  }
}
