import { Caption } from '../textNodes'
import { Image } from '../image'

const COVER_SIZE = [1280, 720]
const Cover = ({ cover }) => <Image src={ cover } size={ COVER_SIZE } />

export default ({ item }) => {
  if (!item) return (<div />)

  const { url, title, cover, nonRetinaCover } = item

  return (
    <div className="lectureFollowingNavigation-item textBox">
      <a href={ url } className="media doubleHover no-u">
        <Cover cover={ nonRetinaCover || cover } />
      </a>

      <Caption>
        <a href={ url } className="doubleHover no-u" dangerouslySetInnerHTML={{ __html: title }} />
      </Caption>
    </div>
  )
}
