import { render } from 'preact'
import Cookies from 'js-cookie'

import UserPopupContainer from './userPopupContainer'
import InvalidLoginLinkNotification from './invalidLoginLinkNotification'

const icon = document.querySelector('.header-user')

const USER_LOGIN_MISS_COOKIE = 's_user_login_miss'

const removeInvalidLoginLinkCookie = () => {
  Cookies.remove(USER_LOGIN_MISS_COOKIE)
  Cookies.remove(USER_LOGIN_MISS_COOKIE, { path: '/' })
}

const renderInvalidLoginLinkNotification = ({ loginEmail, body }) => {
  const notificationFragment = document.createDocumentFragment()
  render(<InvalidLoginLinkNotification email={ loginEmail } />, notificationFragment)

  body.insertBefore(notificationFragment, body.firstChild)
}

$(() => {
  const { body } = document
  const loginEmail = Cookies.get('s_user_login_miss')

  if (loginEmail) {
    removeInvalidLoginLinkCookie()
    renderInvalidLoginLinkNotification({ loginEmail, body })
  }

  if (icon) render(<UserPopupContainer handle={ icon } />, body)
})
