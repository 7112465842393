import { Component } from 'preact'
import { typograf } from 'lib/textHelper'
import cx from 'classnames'

const sovietTags = window.sovietTags || {}
const TAGS = sovietTags.all || []
const HYPHENATED_TAGS = sovietTags.allHyphenated || []
let TYPOGRAFED_TAGS = {}

import { Ul, Heading3 } from '../textNodes'
import { Cols } from '../cols'

const MAX_COLUMNS = 6

const byFirstLetter = (a, b) => {
  const letterA = a.charAt(0).toLowerCase()
  const letterB = b.charAt(0).toLowerCase()

  return letterA < letterB ? -1 : (letterA > letterB ? 1 : 0)
}

const alphabetically = (a, b) => {
  return a < b ? -1 : (a > b ? 1 : 0)
}

const inPacks = (objects, { amount }) => {
  const things = objects.slice(0)
  const partitions = [...Array(amount)]
    .map(() => Math.floor(things.length / amount))
    .map((count, index) => index < things.length % amount ? count + 1 : count)

  return partitions
    .map(count => things.splice(0, count))
}

class TagItem extends Component {
  constructor(props) {
    super(props)

    this.onSelect = this.onSelect.bind(this)

    const name = HYPHENATED_TAGS[this.props.name] || this.props.name
    this.typografedName = TYPOGRAFED_TAGS[name] = TYPOGRAFED_TAGS[name] || typograf(name)
  }

  get isSelected() {
    return (this.props.selectedTags || []).includes(this.props.name)
  }

  onSelect() {
    if (this.isSelected || !this.props.onSelect) return

    this.props.onSelect(this.props.name)
  }

  render() {
    const className = cx('catalogue-item', { is__disabled: this.isSelected })

    return (
      <li className={ className } onClick={ this.onSelect }>
        { this.typografedName }
      </li>
    )
  }
}

class LetterWithTags extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const sortedTags = this.props.tags.slice(0).sort(alphabetically)
    const packs = inPacks(sortedTags, { amount: MAX_COLUMNS })

    return (
      <div className="catalogue-row">
        <Heading3 className="catalogue-rowTitle">{ this.props.letter }</Heading3>
        <div className="catalogue-rowContents">
          <Cols>
            {
              packs.map(tags => (
                <Ul className="noIndent">
                  { tags.map(tag => (<TagItem name={ tag } { ...this.props } />)) }
                </Ul>
                )
              )
            }
          </Cols>
        </div>
      </div>
    )
  }
}

class SovietTagsCatalogue extends Component {
  constructor(props) {
    super(props)

    this.preventClickPropagation = this.preventClickPropagation.bind(this)
  }

  preventClickPropagation(e) {
    e.stopPropagation()
  }

  get tagsByFirstMeaningfulLetter() {
    return TAGS.reduce((hash, tag) => {
      const letter = tag.trim().replace(/^[^а-яa-z]+/ig, '').charAt(0).toUpperCase()

      hash[letter] = hash[letter] || []
      hash[letter].push(tag)

      return hash
    }, {})
  }

  get squashedTagsByFirstMeaningfulLetter() {
    const tags = this.tagsByFirstMeaningfulLetter
    const letters = Object.keys(tags).sort(byFirstLetter)
    const squashedTags = {}

    for (var i = 0; i < letters.length; i++) {
      const letter = letters[i]
      const letterTags = tags[letter]
      const nextLetter = letters[i + 1]
      const nextLetterTags = tags[nextLetter]

      if (nextLetterTags && letterTags.length + nextLetterTags.length < MAX_COLUMNS) {
        const squashedLetter = [letter, nextLetter].join(', ')

        squashedTags[squashedLetter] = tags[letter].concat(tags[nextLetter])
        i++
      } else {
        squashedTags[letter] = tags[letter]
      }
    }

    return squashedTags
  }

  render() {
    const className = cx('sovietTagsCatalogue', { is__visible: this.props.isVisible })
    const groupedTags = this.squashedTagsByFirstMeaningfulLetter
    const letters = Object.keys(groupedTags).sort(byFirstLetter)

    return (
      <div className={ className } onClick={ this.preventClickPropagation }>
        <div className="sovietTagsCatalogue-inner catalogue">
          {
            letters
              .map(letter => (
                <LetterWithTags
                  letter={ letter }
                  tags={ groupedTags[letter] }
                  selectedTags={ this.props.selectedTags }
                  onSelect={ this.props.onSelect } />
                )
              )
          }
        </div>
      </div>
    )
  }
}

export default SovietTagsCatalogue
