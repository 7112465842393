import { createStore as createReduxStore } from 'redux'

import sovietReducer from './sovietReducer'
import { saveDraftWithDebounce } from './saveDraft'
import { defaultKicker } from 'lib/soviets/defaultKicker'

export const DEFAULT_STATE = {
  canPublish: true,
  isPublishingOrUnpublishing: false,
  hasPublicationApprovalError: false,
  headerTemplate: 'default',
  headerTextColor: 'black',
  headerTemplateRequiresImage: false,
  column: '',
  title: '',
  mainThink: '',
  kicker: defaultKicker((window.soviet || {}).authorGenitiveName),
  isTitleHidden: false,
  titleSize: '',
  question: '',
  isQuestionHidden: false,
  questionStyle: '',
  hasQuestionCols: false,
  questionerName: '',
  questionDate: '',
  text: '',
  headerImageUrl: '',
  headerImageSize: [],
  files: [],
  isFilesPanelVisible: false,
  isPublicationApproved: false,
  engagedDates: (window.soviet || {}).engagedDates,
  publishAt: (window.soviet || {}).publishAt,
}

export function storeToJsonParams({ soviet }) {
  return {
    sovietId: soviet.id,
    answer_new_draft: soviet.text,
    questioner_name: soviet.questionerName,
    questioner_email: soviet.questionerEmail,
    publish_at: soviet.publishAt,
    tags: soviet.tags,
    meta_draft: {
      title: soviet.title,
      main_think: soviet.mainThink,
      question: soviet.question,
      header_text_color: soviet.headerTextColor,
      header_template: soviet.selectedHeaderTemplate,
      author: soviet.authorName,
      important: soviet.isImportant,
      lecture: soviet.isLecture,
      kicker: soviet.kicker || defaultKicker(soviet.authorGenitiveName),
    },
  }
}

const hasDateChanged = (prevStore, currentStore) =>
  prevStore.soviet.publishAt !== currentStore.soviet.publishAt

export function createStore(props) {
  const { isProduction } = window.application
  const devtoolsExtension = isProduction ? undefined : window.__REDUX_DEVTOOLS_EXTENSION__

  const shouldDisplayDraft = props.isEditor || props.isPreview
  const draftProps = { ...props, ...props.metaDraft }

  const store = createReduxStore(
    sovietReducer,
    { soviet: shouldDisplayDraft ? draftProps : props },
    devtoolsExtension && devtoolsExtension()
  )

  let prevStore = { soviet: DEFAULT_STATE }

  store.subscribe(() => {
    if (props.isEditor || (props.isPreview && hasDateChanged(prevStore, store.getState()))) {
      saveDraftWithDebounce(storeToJsonParams(store.getState()))
      prevStore = store.getState()
    }
  })

  return store
}
