{
  const CLASS_HIDDEN = 'is__hidden'
  const CLASS_LOADED = 'is__loaded'

  class LazyCoverLoader {
    constructor($wrapper) {
      this.$wrapper = $wrapper
      this.$covers = $wrapper.find('.animatedCover a')

      this.$wrapper.addClass(CLASS_HIDDEN)
      this.loadCovers()
    }

    loadCovers() {
      this.$covers.imagesLoaded({ background: true }, () => this.$wrapper.addClass(CLASS_LOADED))
    }
  }

  $('.js__lazyCoverLoading').each(function() {
    new LazyCoverLoader($(this))
  })
}
