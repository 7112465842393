const compilationThemes = require('../current/compilationThemes')

const DEFAULT_ROWS_PATTERNS = {
  0: ['double', 'triple', 'triple-reversed', 'double-reversed'],
  1: ['double', 'triple', 'triple-reversed', 'double-reversed'],
  2: ['double', 'triple', 'triple-reversed', 'double-reversed'],
  3: ['triple', 'triple-reversed', 'double-reversed', 'double'],
  4: ['double-reversed', 'double', 'triple', 'triple-reversed'],
  5: ['double', 'triple', 'triple-reversed', 'double-reversed'],
  6: ['triple', 'triple-reversed', 'double-reversed', 'double'],
  7: ['triple-reversed', 'double-reversed', 'double', 'triple'],
  8: ['double', 'triple', 'triple-reversed', 'double-reversed'],
  9: ['triple', 'triple-reversed', 'double-reversed', 'double'],
}

const defaultNarrowRowsPattern = ['double', 'double-reversed']
const NARROW_ROWS_PATTERNS = {
  0: defaultNarrowRowsPattern,
  1: defaultNarrowRowsPattern,
  2: defaultNarrowRowsPattern,
  3: defaultNarrowRowsPattern,
  4: defaultNarrowRowsPattern,
  5: defaultNarrowRowsPattern,
  6: defaultNarrowRowsPattern,
  7: defaultNarrowRowsPattern,
  8: defaultNarrowRowsPattern,
  9: defaultNarrowRowsPattern,
}

let SOVIET_LINK = /(bureau|artgorbunov)\.ru\/(bb\/)?soviet\/([\d\/]{6,10})\/?/igm

const MIN_COVER_PROPORTION = 0.25
const MAX_COVER_PROPORTION = 8

const MAIN_COLUMN_PERCENTAGE = 0.5
const DEFAULT_COLUMN = 'Дизайн'

const detectMainColumn = (soviets) => {
  if (!soviets || !soviets.length) return DEFAULT_COLUMN

  const minConceptCount = soviets.length * MAIN_COLUMN_PERCENTAGE

  const columns = soviets.reduce((hash, soviet) => {
    hash[soviet.column] = hash[soviet.column] || 0
    hash[soviet.column] += 1
    return hash
  }, {})

  return Object.keys(columns)
    .find(column => columns[column] > minConceptCount) || DEFAULT_COLUMN
}

const _selectPattern = (sovietsAmount, { patternLibrary = DEFAULT_ROWS_PATTERNS }) => {
  return patternLibrary[sovietsAmount % 10]
}

const extractSovietKey = (text) => {
  SOVIET_LINK.lastIndex = 0
  const match = SOVIET_LINK.exec(text)

  if (!match || !match.length) return
  const id = match[3].replace(/\/$/, '')

  return id.length === 6 ? `20${id}` : id
}

const extractSovietKeys = (text) => {
  SOVIET_LINK.lastIndex = 0

  let sovietIds = []
  let match = []

  while ((match = SOVIET_LINK.exec(text)) !== null) {
    let id = match[3].replace(/\/$/, '')
    if (id.length === 6) id = `20${id}`

    sovietIds.push(id)
  }

  return sovietIds
}

const hasProperCover = ({ coverUrl, coverSize }) => {
  if (/_cover\.(png|jpg|jpeg)$/.test(coverUrl)) return true

  if (coverSize && coverSize.every(dimension => dimension)) {
    const proportion = coverSize[0] / coverSize[1]

    return proportion >= MIN_COVER_PROPORTION && proportion <= MAX_COVER_PROPORTION
  }

  return false
}

const cleanUpSoviets = (soviets = []) => {
  return soviets.map(soviet => {
    if (!hasProperCover(soviet)) {
      soviet.coverUrl = null
      soviet.coverSize = []
    }

    return soviet
  })
}

const collectRows = (soviets = [], options = {}) => {
  let rows = []
  let row = []
  let patternIndex = 0
  const pattern = _selectPattern(soviets.length, options)

  cleanUpSoviets(soviets).forEach((soviet, i) => {
    const currentTemplate = pattern[patternIndex % pattern.length]
    const isReversed = /-reversed/i.test(currentTemplate)
    const decoratedSoviet = Object.assign({}, soviet, { isReversed: isReversed })
    const isLastSoviet = i === soviets.length - 1

    row.push(decoratedSoviet)
    if (/double/i.test(currentTemplate) && row.length < 2 && !isLastSoviet) return
    if (/triple/i.test(currentTemplate) && row.length < 3 && !isLastSoviet) return

    rows.push(row)
    patternIndex += 1
    row = []
  })

  return { rows: rows }
}

const themify = (json) => {
  const theme = compilationThemes[json.theme]
  if (!json.theme || !theme) {
    return Object.assign({},
      json,
      {
        customCoverUrl: json.coverUrl,
        customThemeLayout: json.layout,
        customThemeTextColor: json.textColor,
      })
  }

  return Object.assign({}, theme, json, { coverUrl: theme.coverUrl })
}

const compileSoviets = (soviets, options) => {
  return collectRows(soviets.map(soviet => soviet.asJson()), options).rows
}


module.exports = {
  SOVIET_LINK,
  extractSovietKeys,
  extractSovietKey,
  collectRows,
  detectMainColumn,
  themify,
  compileSoviets,
  compileSovietsInNarrowList: (soviets) => {
    return compileSoviets(soviets, { patternLibrary: NARROW_ROWS_PATTERNS })
  },
}
