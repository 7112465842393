import JSON_HEADERS from 'jsonHeaders'
import { utmSource } from 'lib/helpers/linksHelper'

const fetchSchoolData = () => {
  return fetch('/school/enrollData/', {
    method: 'GET',
    headers: JSON_HEADERS,
    credentials: 'include',
  })
    .then(res => res.json())
}

const getSchoolLink = (key) => {
  if (!key.length) return ''

  const source = utmSource({
    platform: 'Сайт бюро',
    postType: 'Банер',
    productType: 'Школа',
    product: key,
    postDate: new Date(),
  })

  return `/school/${key}/enroll/?utm_source=${source}`
}

const getPrepLink = () => {
  const source = utmSource({
    platform: 'Сайт бюро',
    postType: 'Банер',
    productType: 'Школа',
    product: 'prep',
    postDate: new Date(),
  })

  return `/school/prep/?utm_source=${source}`
}


module.exports = {
  fetchSchoolData,
  getSchoolLink,
  getPrepLink,
}
